import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import { ORDER, STATUS, USERSTATUS } from '../../../General';
import { findDayEventName, findLocaleName, formatForServer } from '../../../Utils';
import Filters from './Filters';
import Table from './Table';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        this.state = {
            filters: {
                users: { value: [] },
                units: { value: [] },
                offices: { value: [], },
                leaveTypes: { value: [], },
                approvers: { value: [], },
                startDate: { value: moment().startOf('day') },
                endDate: { value: moment().startOf('day').add(1, 'M') },
                orderBy: { field: 'NAME', order: ORDER.ASC }
            },
            rows: {
                arr: null,
                status: STATUS.LOADING
            },
            days: {
                arr: [],
                month: ''
            },
        };
    }

    componentDidMount() { this.search(); }

    render() {
        return <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <Filters
                        currentTab={this.props.currentTab}
                        onChangeTab={(value: any) => { if (this.props.onChangeTab) { this.props.onChangeTab(value); } }}
                        defaultFilters={this.state.filters}
                        orderBy={this.state.filters.orderBy}
                        onChange={(value: any) => { this.setState({ filters: Object.assign({}, value) }, () => { this.search(); }); }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <Table rows={this.state.rows} days={this.state.days} />
                </div>
            </div>
        </div>
    }

    search() {
        this.setState(
            { rows: { arr: this.state.rows.arr, status: STATUS.LOADING } }, () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getDayEventsGrouped({
                        filters: this.state.filters
                    }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            { days: { arr: this.computeDays(), month: this.state.filters.startDate.value.format('MMMM') } },
                            () => { this.setState({ rows: { arr: this.computeRows(data.data), status: STATUS.READY } }); }
                        );

                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState({ rows: { arr: [], status: STATUS.ERROR } });
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    computeDays() {
        let rows: any = [];
        const today = this.getMomentWithLocale()();
        const startDate = this.state.filters.startDate.value.clone();
        const endDate = this.state.filters.endDate.value.clone();
        const interval = endDate.diff(startDate, 'days') + 1;
        let currentDate = startDate.clone();
        for (let i = 0; i < interval; i++) {
            const cd = currentDate.clone();
            rows.push({
                ISO8601: formatForServer(cd),
                dayOfWeek: cd.format('dd'),
                text: cd.format('D'),
                endOfMonth: currentDate.isSame(startDate.endOf('month'), 'day'),
                today: currentDate.isSame(today, 'day')
            });
            currentDate = currentDate.add(1, 'd');
        }
        return rows;
    }

    computeRows(data: any) {
        const caches: any = this.getCaches().getState();
        const session = (this.getSession().getState() as any);
        let users = caches.users;
        let usersDayEvents: any = [];
        const usersMap = users.map;
        let currentCompanyUserId = 0;
        let currentRow: any = undefined;
        const approvers = this.state.filters.approvers.value;
        data.filter((row: any) => {
            return usersMap[row[0]].status !== USERSTATUS.DISABLED;
        }).filter((user: any) => {
            if (approvers && approvers.length > 0) {
                const approverId = usersMap[user[0]].approverCompanyUser.id;
                for (let i = 0; i < approvers.length; i++) {
                    if (approverId === approvers[i]) {
                        return true;
                    }
                }
                return false;
            }
            return true;
        }).sort((a: any, b: any) => {
            return a[0] - b[0];
        }).forEach((item: any) => {
            const dayEvent = Manager.createDayEvent(item, session);
            if (currentCompanyUserId !== item[0]) {
                currentCompanyUserId = item[0];
                const employee = users.map[item[0]];
                currentRow = {
                    employee: {
                        id: employee.id,
                        fullName: employee.fullName,
                        me: (this.getSession().getState() as any).companyUser.id === employee.id
                    },
                    dayEvents: [].concat(dayEvent)
                };
                usersDayEvents.push(currentRow);
            } else {
                currentRow.dayEvents.push(dayEvent);
            }
        });
        usersDayEvents.forEach((userDayEvent: any) => {
            userDayEvent.dayEvents = this.state.days.arr.map((day: any) => {
                let toReturn = undefined;
                for (let i = 0; i < userDayEvent.dayEvents.length; i++) {
                    if (day.ISO8601 === userDayEvent.dayEvents[i].day) {
                        if (day.endOfMonth) {
                            userDayEvent.dayEvents[i].endOfMonth = true;
                        }
                        if (toReturn === undefined) {
                            toReturn = userDayEvent.dayEvents[i];
                            if (userDayEvent.dayEvents[i].isBLO) {
                                toReturn.jsonNamesBLO = userDayEvent.dayEvents[i].names;
                                continue;
                            }
                        } else {
                            if (userDayEvent.dayEvents[i].isBLO) {
                                toReturn.isBLO = userDayEvent.dayEvents[i].isBLO;
                                toReturn.jsonNamesBLO = userDayEvent.dayEvents[i].names;
                                continue;
                            }
                            const leaveType = userDayEvent?.dayEvents[i]?.leaveTypeId ? caches.leaveTypes.map[userDayEvent.dayEvents[i].leaveTypeId] : null;
                            if (!leaveType?.working && toReturn.status > userDayEvent.dayEvents[i].status) {
                                toReturn = userDayEvent.dayEvents[i];
                            } else {
                                if (!userDayEvent.dayEvents[i].leaveTypeId) {
                                    //toReturn.color = 'rgba(' + Manager.hexToRgb(userDayEvent.dayEvents[i].color.substring(1)) + ', 0.2)';
                                    //else
                                    toReturn.color = 'rgba(' + Manager.hexToRgb(userDayEvent.dayEvents[i].color.substring(1)) + ', 0.5)';
                                    toReturn.names = userDayEvent.dayEvents[i].names;
                                    toReturn.status = userDayEvent.dayEvents[i].status;
                                }
                            }
                            if (leaveType?.working)
                                toReturn = userDayEvent.dayEvents[i];
                        }
                    }
                }
                if (toReturn) {
                    return toReturn;
                }
                return { id: 0, day: day.ISO8601, endOfMonth: (day.endOfMonth ? true : undefined) };
            });
        });
        if (this.state?.filters?.leaveTypes?.value?.length > 0) {
            const leaveTypeIdsSet = new Set(this.state.filters.leaveTypes.value);
            usersDayEvents = usersDayEvents.filter((usersDayEvent: any) => {
                for (let i = 0; i < usersDayEvent.dayEvents.length; i++) {
                    const dayEvent = usersDayEvent.dayEvents[i];
                    if (dayEvent.leaveTypeId) {
                        if (leaveTypeIdsSet.has(dayEvent.leaveTypeId)) {
                            return true;
                        }
                    }
                }
                return false;
            });
        }
        usersDayEvents.forEach((userDayEvent: any) => {
            userDayEvent.dayEvents.forEach((dayEvent: any) => {
                const t = this.translate();
                let requiresApproval: any;
                if (dayEvent.status === 1000) {
                    requiresApproval = (
                        <div className="text-warning">
                            <i className="fa fa-exclamation-circle mr-1" aria-hidden="true" />
                            {t('requires.approval')}
                        </div>
                    );
                }
                let part: any = '';
                switch (dayEvent.dayType) {
                    case 1:
                        part = (<div>{t('first.part.of.the.day')}</div>);
                        break;
                    case 2:
                        part = (<div>{t('second.part.of.the.day')}</div>);
                        break;
                    case 3:
                        if (dayEvent.hours) {
                            if (dayEvent.hours > 1)
                                part = (<div>{dayEvent.hours + ' ' + t('hours')}</div>);
                            if (dayEvent.hours === 1)
                                part = (<div>{t('one.hour')}</div>);
                        }
                        break;
                    case 0:
                        break;
                    default:
                        break;
                }
                dayEvent.toolTip = <>
                    <div>{findDayEventName(dayEvent, this.language())}</div>
                    {requiresApproval}
                    {part}
                    {this.replacement(dayEvent)}
                    {dayEvent.isBLO && dayEvent.isPH ? findLocaleName(dayEvent.jsonNamesBLO, this.language()) : ''}
                </>
            });
        });
        return usersDayEvents.sort((a: any, b: any) => {
            if (this.state.filters.orderBy.order === ORDER.ASC)
                return a.employee.fullName.toLowerCase().localeCompare(b.employee.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
            else
                return b.employee.fullName.toLowerCase().localeCompare(a.employee.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
        })
    }

    replacement(dayEvent: any) {
        if (dayEvent.replacement) {
            const t = this.translate();
            return (<div>{t('replacement') + ' : ' + dayEvent.replacement}</div>);
        }
        return null;
    }

    static createDayEvent(row: any, session: any) {
        var i = 1;
        // please note first element is the company user id
        var dayEvent: any = {};
        dayEvent.day = row[i++];
        dayEvent.status = row[i++];
        dayEvent.color = row[i++];
        dayEvent.dayType = row[i++];
        var jsonNames = row[i++];
        if (jsonNames) {
            dayEvent.names = JSON.parse(jsonNames);
        }
        dayEvent.background = Manager.dayEventStyle(dayEvent).background;
        const leaveTypeId = row[i++];
        if (leaveTypeId) {
            dayEvent.leaveTypeId = leaveTypeId;
        }
        dayEvent.replacement = row[i++];
        dayEvent.companyUserId = row[i++];
        dayEvent.isPH = row[i++];
        dayEvent.isBLO = row[i++];
        if (dayEvent.isBLO) {
            dayEvent.color = '#AA0000';
        }
        dayEvent.hours = row[i++];
        const isCurrentUser = dayEvent.companyUserId === session.companyUser.id;
        dayEvent.anonymize = isCurrentUser ? false : (dayEvent.leaveTypeId ? session.permissions.anonymize : false);
        return dayEvent;
    }

    static dayEventStyle(dayEvent: any) {
        const color = Manager.dayEventColor(dayEvent);
        switch (dayEvent.dayType) {
            case 1:
                return { background: 'linear-gradient(to right, ' + color + ' 50%, #FFFFFF  51%)' };
            case 2:
                return { background: 'linear-gradient(to right, #FFFFFF 50%, ' + color + '  51%)' };
            case 3:
                return { background: 'linear-gradient(to right, #FFFFFF 20%, ' + color + ' 21%  80%, #FFFFFF 20%)' };
            default:
                return { background: color };
        }
    }

    static dayEventColor(dayEvent: any) {
        switch (dayEvent.status) {
            case 0:
                return 'rgba(' + Manager.hexToRgb(dayEvent.color.substring(1)) + ', 1)';
            case 1:
                return 'rgba(' + Manager.hexToRgb(dayEvent.color.substring(1)) + ', 0.3)';
            case 1000:
                return 'rgba(' + Manager.hexToRgb(dayEvent.color.substring(1)) + ', 0.1)';
            case 1001:
                return 'rgba(' + Manager.hexToRgb(dayEvent.color.substring(1)) + ', 0.2)';
            default:
                return undefined;
        }
    }

    static hexToRgb(hex: any) {
        var bigint = parseInt(hex, 16);
        var r = (bigint >> 16) & 255;
        var g = (bigint >> 8) & 255;
        var b = bigint & 255;
        return r + ',' + g + ',' + b;
    }
}