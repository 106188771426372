import * as React from 'react';
import Component from '../../../../Component';

export default class Item extends Component {

    render() {
        const unit = this.props.unit;
        return (
            <tr><td>
                <span>
                    <i className="fas fa-users fa-fw" aria-hidden="true" />
                    {' ' + unit.name}
                </span>
            </td></tr>
        );
    }
}