import * as React from 'react';
import Component from '../../Component';
import {
    Modal,
    ModalBody
} from 'reactstrap';
import Close from '../../Components/Close';
import Line from '../../Components/Line';
import Back from '../../Components/Back';
import { none } from '../../Actions/Modal';

export default abstract class ResponsiveModal extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            modal: true,
            backdrop: true,
        };
    }

    toggle() {
        this.setState({ modal: !this.state.modal }, () => {
            if (this.state.modal === false) {
                none(this.getModal(), {});
            }
        });
    }

    render() {
        return (
            this.isScreenSizeSmall() ?
                (
                    <div className="container-fluid">
                        {this.getHeader()}
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="card w-100">
                                    <div className="card-body">
                                        {this.getContent()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                :
                (
                    <Modal
                        isOpen={this.state.modal}
                        toggle={() => {
                            this.toggle();
                        }}
                        className={this.props.className}
                        backdrop={true}
                    >
                        <ModalBody>
                            <div className="container-fluid">
                                {this.getHeader()}
                                <div className="row">
                                    <div className="col-12">
                                        {this.getContent()}
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                )
        );
    }

    getHeader() {
        return this.isScreenSizeSmall() ?
            <div>
                <div className="row">
                    <div className="col-12 mt-3 mb-3">
                        <Back onClick={() => { this.toggle(); }} />
                    </div>
                </div>
                {this.getTitle() ?
                    <div className="row">
                        <div className="col-12 mb-3">
                            {this.getTitle()}
                        </div>
                    </div>
                    : null}
                <div className="row">
                    <div className="col-12 mb-3">
                        <Line />
                    </div>
                </div>
            </div> : <div className="row">
                <div className="col-10 mb-3">
                    {this.getTitle()}
                </div>
                <div className="col-2 mb-3">
                    <Close onClick={() => { this.toggle(); }} />
                </div>
            </div>
    }

    getTitle(): any {
        return undefined;
    }

    abstract getContent(): any;
}