import { LEAVESTATUS, COMPANYUSERROLES } from './General';
import * as moment from 'moment';

export default class LeaveRules {
    static canBePaperCopy(leave: any, leaveType: any, company: any) {
        if (LEAVESTATUS.CANCELED !== leave.status && LEAVESTATUS.REJECTED !== leave.status) {
            if (leaveType.paperCopy === true && company.country === 'RO') {
                return true;
            }
        }
        return false;
    }

    static canSendReminder(leave: any, companyUser: any) {
        if (LEAVESTATUS.AWAITING_APPROVAL === leave.status) {
            if ((companyUser.id === leave.companyUser.id) ||
                (companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN)) {
                return true;
            }
        }
        return false;
    }

    static canBeCanceled(leave: any, companyUser: any) {
        const startDate = (moment as any)(leave.startDate);
        if (((LEAVESTATUS.AWAITING_APPROVAL === leave.status) && (companyUser.id === leave.companyUser.id) &&
            (moment as any)().isBefore(startDate)) ||
            (((moment as any)().isBefore(startDate)) && (LEAVESTATUS.APPROVED === leave.status) &&
                (companyUser.id === leave.companyUser.id)) ||
            (((LEAVESTATUS.AWAITING_APPROVAL === leave.status) || (LEAVESTATUS.APPROVED === leave.status)) &&
                (companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN))) {
            return true;
        }
        return false;
    }

    static canBeCanceledForMyLeaves(leave: any, companyUser: any) {
        const startDate = (moment as any)(leave.startDate);
        if (((LEAVESTATUS.AWAITING_APPROVAL === leave.status) && (companyUser.id === leave.companyUser.id) &&
            (moment as any)().isBefore(startDate)) ||
            (((moment as any)().isBefore(startDate)) && (LEAVESTATUS.APPROVED === leave.status) &&
                (companyUser.id === leave.companyUser.id))) {
            return true;
        }
        return false;
    }

    static canBeApproved(leave: any, companyUser: any) {
        if (LEAVESTATUS.AWAITING_APPROVAL === leave.status) {
            if ((companyUser.id === leave.approver.id) ||
                (companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN)) {
                return true;
            }
        }
        return false;
    }

    static canBeRejected(leave: any, companyUser: any) {
        if (LEAVESTATUS.AWAITING_APPROVAL === leave.status) {
            if ((companyUser.id === leave.approver.id) ||
                (companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN)) {
                return true;
            }
        }
        return false;
    }

    static isInThePast(leave: any) {
        if (LEAVESTATUS.AWAITING_APPROVAL === leave.status) {
            if ((moment as any)().isAfter((moment as any)(leave.startDate as string))) {
                return true;
            }
        }
        return false;
    }
}