import * as React from 'react';
import Component from '../../../Component';
import Anchor from '../../../Components/Anchor';
import { Popover, PopoverBody } from 'reactstrap';

export default class MoreActions extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            popoverOpen: false
        };
    }

    toggle() {
        this.setState({ popoverOpen: !this.state.popoverOpen });
    }

    render() {
        const t = this.translate();
        return (
            <span>
                <Anchor
                    id={'MoreActions-' + this.cid}
                    className="btn btn-outline-primary border-0 rounded-circle m-0 p-0 pl-1 pr-1"
                    title={t('more.actions')}
                    faIcon="fas fa-ellipsis-h"
                    onClick={() => { this.toggle(); }}
                />
                <Popover
                    placement="bottom"
                    isOpen={this.state.popoverOpen}
                    target={'MoreActions-' + this.cid}
                    toggle={() => { this.toggle(); }}
                >
                    <PopoverBody>
                        {this.getBody()}
                    </PopoverBody>
                </Popover>
            </span>
        );
    }

    getBody() {
        const t = this.translate();
        const steps = (this.props.leaveType.halfADayAllowed ? [0.5, 1] : [1]).concat([5, 10]).map((step) => {
            return (
                <div key={step}>
                    {
                        (this.props.step && this.props.step === step) ?
                            (
                                <div className="ml-1">{t('adjusting.with')}
                                    <span className="float-right text-lowercase">{' ' + step + ' ' + t('days')}</span>
                                </div>
                            )
                            :
                            <Anchor
                                className="p-0 pl-1 pr-1"
                                title={t('unlimited')}
                                // tslint:disable-next-line:max-line-length
                                onClick={() => { if (this.props.changeStep) { this.props.changeStep(step); this.toggle(); } }}
                            >
                                {t('adjust.with')}
                                <span className="float-right text-lowercase">{' ' + step + ' ' + t('days')}</span>
                            </Anchor>
                    }
                </div >
            );
        });
        return (
            <div className="m-1" >
                {this.props.adjustment ?
                    <div>
                        <Anchor
                            className="p-0 pl-1 pr-1"
                            title={t('reset.to.default.allowance')}
                            onClick={() => { if (this.props.resetAllowance) { this.props.resetAllowance(); this.toggle(); } }}
                        >
                            {t('reset.to.default.allowance')}
                        </Anchor>
                    </div>
                    : null}
                <div>
                    {steps}
                </div>
            </div>
        );
    }
}