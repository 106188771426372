import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import { filtersFormUrlFactory, leavesFactory } from '../../../Factories';
import { LEAVESTATUS, ORDER, STATUS, VIEWMODE } from '../../../General';
import Filters from './Filters';
import List from './List';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            filters: this.createFilters(),
            leaves: { arr: [], status: STATUS.LOADING }
        };
    }

    createFilters() {
        const session: any = this.getSession().getState();
        const moment = this.getMomentWithLocale();
        let startDate = null;
        if (session.company.businessYearStartMonth === 1 && session.company.businessYearStartDay === 1)
            startDate = moment().startOf('day').startOf('year').month(session.company.businessYearStartMonth - 1).date(session.company.businessYearStartDay);
        else {
            const startOfThisYear = moment().month(session.company.businessYearStartMonth - 1).date(session.company.businessYearStartDay);
            if (startOfThisYear.isBefore(moment())) {
                startDate = moment().startOf('day').startOf('year').month(session.company.businessYearStartMonth - 1).date(session.company.businessYearStartDay);
            } else {
                startDate = moment().startOf('day').startOf('year').month(session.company.businessYearStartMonth - 1).date(session.company.businessYearStartDay).add(-1, 'year');
            }
        }
        let filters = {
            viewMode: VIEWMODE.GRID,
            orderBy: { field: 'DATE', order: ORDER.DESC },
            leaveStatuses: {
                value: [LEAVESTATUS.AWAITING_APPROVAL, LEAVESTATUS.APPROVED]
            },
            leaveTypes: {
                value: [],
            },
            startDate: {
                value: startDate
            },
            endDate: {
                value: moment(startDate).add(1, 'year').add(-1, 'day')
            },
            users: {
                value: [].concat(this.props.employee.id)
            }
        };
        filters = Object.assign({}, filters, filtersFormUrlFactory());
        return filters;
    }

    componentDidMount() { this.search(); }

    search() {
        this.setState(
            { leaves: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getLeaves({
                        filters: this.state.filters
                    }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            {
                                leaves: {
                                    arr: leavesFactory(data.data, (this.getCaches().getState() as any).users)
                                        .sort((a: any, b: any) => {
                                            const af = (a.startDate);
                                            const bf = (b.startDate);
                                            if (this.state.filters.orderBy.order === ORDER.ASC) {
                                                return (af < bf ? -1 : (af > bf ? 1 : 0));
                                            } else {
                                                return (bf < af ? -1 : (bf > af ? 1 : 0));
                                            }
                                        }),
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                leaves: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        return <div>
            <Filters
                defaultStartDate={this.state.filters.startDate.value}
                defaultEndDate={this.state.filters.endDate.value}
                defaultLeaveStatuses={this.state.filters.leaveStatuses.value}
                defaultLeaveTypes={this.state.filters.leaveTypes.value}
                defaultUsers={this.state.filters.users.value}
                viewMode={this.state.filters.viewMode}
                orderBy={this.state.filters.orderBy}
                onChange={(value: any) => {
                    this.setState({ filters: Object.assign({}, value) }, () => { this.search(); });
                }}
            />
            <div className="row">
                <List
                    onRefresh={() => { this.search(); }}
                    leaves={this.state.leaves}
                    viewMode={this.state.filters.viewMode}
                />
            </div>
        </div>
    }
}