export default class Validator {
    static message(t: any, rule: any, params: any) {
        params = [].concat(params);
        var message = t('Validator.' + rule);
        if (params && params.length > 0) {
            for (var i = 0; i < params.length; i++) {
                message = message.replace(new RegExp('\\{' + i + '\\}', 'g'), params[i]);
            }
        }
        return message;
    }

    static validate(field: any) {
        return ((value, rules) => {
            if (rules) {
                for (let rule of Object.getOwnPropertyNames(rules)) {
                    if (rule === 'func') {
                        if (!rules[rule]().valid) {
                            return rules[rule]();
                        }
                        break;
                    }
                    if (!(Rules as any)[rule](value, rules[rule])) {
                        return false;
                    }
                }
            }
            return true;
        })(field.value, field.rules);
    }

    static validateMessage(field: any, t: any) {
        return ((value: any, rules: any, tr: any) => {
            if (rules) {
                for (let rule of Object.getOwnPropertyNames(rules)) {
                    if (rule === 'func') {
                        if (!rules[rule]().valid) {
                            return rules[rule]();
                        }
                        break;
                    }
                    if (!(Rules as any)[rule](value, rules[rule])) {
                        return Validator.message(tr, rule, rules[rule]);

                    }
                }
            }
            return '';
        })(field.value, field.rules, t);
    }
}

export class Rules {
    static required(value: any, param: any) {
        if ((value && typeof (value) === 'string' && value.length > 0) ||
            (value && typeof (value) === 'number') ||
            (value && Array.isArray(value) && value.length > 0)) {
            return true;
        }
        return false;
    }

    static email(value: any, param: any) {
        // tslint:disable-next-line:max-line-length
        return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(value);
    }

    static url(value: any, param: any) {
        // tslint:disable-next-line:max-line-length
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
    }

    static date(value: any, param: any) {
        return !/Invalid|NaN/.test(new Date(value).toString());
    }

    static dateISO(value: any, param: any) {
        //        return /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(value);
        return /^\d{4}[/-](0?[1-9]|1[012])[/-](0?[1-9]|[12][0-9]|3[01])$/.test(value);
    }

    static number(value: any, param: any) {
        return /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(value);
    }

    static digits(value: any, param: any) {
        return /^\d+$/.test(value);
    }

    static minlength(value: any, param: any) {
        return value.length >= param;
    }

    static maxlength(value: any, param: any) {
        return !value ? true : (value.length <= param);
    }

    static rangelength(value: any, param: any) {
        return (value.length >= param[0] && value.length <= param[1]);
    }

    static min(value: any, param: any) {
        return value >= param;
    }

    static max(value: any, param: any) {
        return value <= param;
    }

    static range(value: any, param: any) {
        return (value >= param[0] && value <= param[1]);
    }

    static BasicEmailRules() {
        return { required: true, email: true, minlength: 5, maxlength: 100 };
    }

    static BasicPasswordRules() {
        return { required: true, minlength: 6, maxlength: 100 };
    }

    static MaxLengthInputRules() {
        return { maxlength: 100 };
    }

    static BasicInputRules() {
        return { required: true, minlength: 6, maxlength: 100 };
    }

    static BasicNameInputRules() {
        return { required: true, minlength: 2, maxlength: 100 };
    }

    static BasicTextareaRules() {
        return { required: true, minlength: 6, maxlength: 1000 };
    }

    static MaxLengthTextareaRules() {
        return { maxlength: 1000 };
    }

    static BasicCcountryIso2Rules() {
        return { required: true, minlength: 2, maxlength: 2 };
    }

    static BasicSelectRules() {
        return { required: true };
    }

    static BasicNameRules() {
        return { required: true, minlength: 2, maxlength: 100 };
    }

    static BasicNumberRules() {
        return { required: true, minlength: 1 };
    }

    static BasicDateISORules() {
        return { required: true, dateISO: 1 };
    }

    static BasicDateISONotRequiredRules() {
        return { required: false, dateISO: 1 };
    }
}