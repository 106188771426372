import * as React from 'react';
import Component from '../Component';
import Anchor from '../Components/Anchor';
import { Popover, PopoverBody } from 'reactstrap';

export default class Confirm extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            popoverOpen: false
        };
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    render() {
        const t = this.translate();
        return (
            <span>
                <Anchor
                    className={this.props.className}
                    id={'Confirm-' + this.cid}
                    faIcon={this.props.faIcon}
                    title={this.props.title}
                    onClick={() => {
                        this.toggle();
                    }}
                >
                    {this.props.children}
                </Anchor>
                <Popover
                    placement="bottom"
                    isOpen={this.state.popoverOpen}
                    target={'Confirm-' + this.cid}
                    toggle={() => {
                        this.toggle();
                    }}
                >
                    <PopoverBody>
                        <Anchor
                            className="text-success"
                            faIcon="fas fa-check"
                            onClick={() => {
                                this.toggle();
                                if (this.props.onConfirm) {
                                    this.props.onConfirm();
                                }
                            }}
                        >
                            {t('yes')}
                        </Anchor>
                        {' '}
                        <Anchor
                            className="text-secondary"
                            faIcon="fas fa-ban"
                            onClick={() => {
                                this.toggle();
                                if (this.props.onCancel) {
                                    this.props.onCancel();
                                }
                            }}
                        >
                            {t('no')}
                            {' '}
                        </Anchor>
                    </PopoverBody>
                </Popover>
            </span>
        );
    }
}