import * as React from 'react';

export default class Fa extends React.PureComponent<any, any> {
    public render() {
        let className = '';
        if (this.props.fa) { className += this.props.fa + ' '; }
        if (this.props.fw) { className += 'fa-fw '; }
        if (this.props.spin) { className += 'fa-spin '; }
        if (this.props.pulse) { className += 'fa-pulse '; }
        if (this.props.x) { className += 'fa-' + this.props.x + 'x '; }
        if (this.props.className) { className += this.props.className + ' '; }
        return (<i className={className} style={this.props.style} data-fa-transform={this.props['data-fa-transform']} />);
    }
}