import { createParamsFromFilters, formatForServer } from './Utils';

export default class Endpoints {
    static getDomain() {
        var url = window.location.href;
        var arr = url.split('/');
        return arr[0] + '//' + arr[2];
        //        return 'http://localhost';
    }

    static getLocalExchange() {
        return Endpoints.getDomain() + '/ceres/r/local/exchange';
    }

    static getLocalActivate() {
        return Endpoints.getDomain() + '/ceres/r/local/activate';
    }

    static getOffice365Exchange() {
        return Endpoints.getDomain() + '/ceres/r/office365/exchange';
    }

    static getGoogleExchange() {
        return Endpoints.getDomain() + '/ceres/r/google/exchange';
    }

    static getSlackExchange(addedToSlack?: any) {
        return Endpoints.getDomain() + '/ceres/r/slack/exchange' + (addedToSlack ? '?addedToSlack=true' : '');
    }

    static getLogin() {
        return Endpoints.getDomain() + '/vulcan/r/user/login';
    }

    static getRegister() {
        return Endpoints.getDomain() + '/vulcan/r/company/register';
    }

    static getForgotPassword() {
        return Endpoints.getDomain() + '/vulcan/r/user/forgotpassword';
    }

    static getResetPassword() {
        return Endpoints.getDomain() + '/vulcan/r/user/resetpassword';
    }

    static getLogged() {
        return Endpoints.getDomain() + '/vulcan/r/user/logged';
    }

    static getRefreshed() {
        return Endpoints.getDomain() + '/vulcan/r/user/refresh';
    }

    static getLogout() {
        return Endpoints.getDomain() + '/vulcan/r/user/logout';
    }

    static getTicketCreate() {
        return Endpoints.getDomain() + '/vulcan/r/ticket/create';
    }

    static getTimezones() {
        return Endpoints.getDomain() + '/vulcan/r/I18N/timezones/all';
    }

    static getCountries(lang: string) {
        return Endpoints.getDomain() + '/vulcan/r/I18N/' + lang + '/countries/all';
    }

    static getEmployeesSearch(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/company/user/search/lite';
        if (options) {
            url = createParamsFromFilters(url, options.filters);
        }
        return url;
    }
    static getEmployeesEmployeesSearch(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/company/user/search/employees';
        if (options) {
            url = createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getCompanyAnalytics(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/company/analytics/get';
        if (options) {
            url = createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getEmployeesYearsOfServiceSearch(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/company/user/yearsofservice/search';
        if (options) {
            url = createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getEmployeesAgeSearch(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/company/user/age/search';
        if (options) {
            url = createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getEmployee(id?: number | string | undefined) {
        return Endpoints.getDomain() + '/vulcan/r/company/user/get?cuid=' + id;
    }

    static getEmployeePersonalDetails(id?: number | string | undefined) {
        return Endpoints.getDomain() + '/vulcan/r/company/user/personal/get?cuid=' + id;
    }

    static getEmployeesBulkInvite() {
        return Endpoints.getDomain() + '/vulcan/r/company/user/bulkinvite';
    }

    static getEmployeeDisable(id?: number | string) {
        return Endpoints.getDomain() + '/vulcan/r/company/user/disable?cuid=' + id;
    }

    static getEmployeeEnable(id?: number | string) {
        return Endpoints.getDomain() + '/vulcan/r/company/user/enable?cuid=' + id;
    }

    static getEmployeeUpdate(id: any) {
        return Endpoints.getDomain() + '/vulcan/r/company/user/update?cuid=' + id;
    }

    static getEmployeePersonalUpdate(id: any) {
        return Endpoints.getDomain() + '/vulcan/r/company/user/personal/update?cuid=' + id;
    }

    static getHoes(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/holidayorevent/search?caid=' + options.filters.office.value.id;
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getUnits(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/company/unit/search';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getUnit(id: number | string | undefined) {
        return Endpoints.getDomain() + '/vulcan/r/company/unit/' + id + '/get';
    }

    static getUnitiCalRegenerate(id: number | string) {
        return Endpoints.getDomain() + '/vulcan/r/company/unit/' + id + '/regenerate/ical';
    }


    static getOfficeiCalRegenerate(id: number | string) {
        return Endpoints.getDomain() + '/vulcan/r/calendar/' + id + '/regenerate/ical';
    }

    static getSubsidiaryiCalRegenerate(id: number | string) {
        return Endpoints.getDomain() + '/vulcan/r/subsidiary/' + id + '/regenerate/ical';
    }

    static deleteUnit(id: number | string | undefined) {
        return Endpoints.getDomain() + '/vulcan/r/company/unit/' + id;
    }

    static getSubsidiaries(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/subsidiary/search';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }


    static getSubsidiary(id: number | string | undefined) {
        return Endpoints.getDomain() + '/vulcan/r/subsidiary/' + id + '/get';
    }

    static deleteSubsidiary(id: number | string | undefined) {
        return Endpoints.getDomain() + '/vulcan/r/subsidiary/' + id;
    }

    static getOffices(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/calendar/search';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getOffice(id: number | string | undefined) {
        return Endpoints.getDomain() + '/vulcan/r/calendar/' + id + '/get';
    }

    static deleteOffice(id: number | string | undefined) {
        return Endpoints.getDomain() + '/vulcan/r/calendar/' + id;
    }

    static getLeaveTypes(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/leavetype/search';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getNotificationRules(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/notificationrule/search';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getNotificationRule(id?: number | string) {
        return Endpoints.getDomain() + '/vulcan/r/notificationrule/' + id + '/get';
    }

    static getLeaveType(id?: number | string) {
        return Endpoints.getDomain() + '/vulcan/r/leavetype/' + id + '/get';
    }

    static getLeaveTypeDisable(id?: number | string) {
        return Endpoints.getDomain() + '/vulcan/r/leavetype/' + id + '/disable';
    }

    static getLeaveTypeEnable(id?: number | string) {
        return Endpoints.getDomain() + '/vulcan/r/leavetype/' + id + '/enable';
    }

    static getLiteUnits() {
        return Endpoints.getDomain() + '/vulcan/r/company/unit/search/lite';
    }

    static getUnitCreate() {
        return Endpoints.getDomain() + '/vulcan/r/company/unit/create';
    }

    static getUnitUpdate(id: any) {
        return Endpoints.getDomain() + '/vulcan/r/company/unit/' + id + '/update';
    }

    static getOfficeCreate() {
        return Endpoints.getDomain() + '/vulcan/r/calendar/create';
    }

    static getOfficeUpdate(id: any) {
        return Endpoints.getDomain() + '/vulcan/r/calendar/' + id + '/update';
    }

    static getSubsidiaryCreate() {
        return Endpoints.getDomain() + '/vulcan/r/subsidiary/create';
    }

    static getSubsidiaryUpdate(id: any) {
        return Endpoints.getDomain() + '/vulcan/r/subsidiary/' + id + '/update';
    }

    static getLeaveTypeCreate() {
        return Endpoints.getDomain() + '/vulcan/r/leavetype/create';
    }

    static getLeaveTypeUpdate(id: any) {
        return Endpoints.getDomain() + '/vulcan/r/leavetype/' + id + '/update';
    }

    static getLeaveTypeRevertTransfer(id: any, year: any, sourceLeaveTypeId: any, offices: any) {
        return Endpoints.getDomain() + '/vulcan/r/leavetype/' + id + '/reverttransfer?year=' + year +
            (sourceLeaveTypeId ? '&sourceLeaveTypeId=' + sourceLeaveTypeId : '') +
            (offices ? '&offices=' + offices : '');
    }

    static getLeaveTypeTransfer(id: any, year: any, maximumTransferableDays: any, destinationLeaveTypeId: any, offices: any) {
        return Endpoints.getDomain() + '/vulcan/r/leavetype/' + id + '/transfer?year=' + year + '&maximumTransferableDays=' + maximumTransferableDays +
            (destinationLeaveTypeId ? '&destinationLeaveTypeId=' + destinationLeaveTypeId : '') +
            (offices ? '&offices=' + offices : '');
    }

    static getNotificationRuleCreate() {
        return Endpoints.getDomain() + '/vulcan/r/notificationrule/create';
    }

    static getNotificationRuleUpdate(id: any) {
        return Endpoints.getDomain() + '/vulcan/r/notificationrule/' + id + '/update';
    }

    static getNotificationRuleDelete(id: any) {
        return Endpoints.getDomain() + '/vulcan/r/notificationrule/' + id + '/delete';
    }

    static getChangeUserPassword() {
        return Endpoints.getDomain() + '/vulcan/r/user/changepassword';
    }

    static getReinviteUser(cid: any) {
        return Endpoints.getDomain() + '/vulcan/r/company/user/reinvite?cuid=' + cid;
    }

    static getReinviteAll() {
        return Endpoints.getDomain() + '/vulcan/r/company/user/reinviteall';
    }

    static getUserInvite() {
        return Endpoints.getDomain() + '/vulcan/r/company/user/invite';
    }

    static getDayEventsGrouped(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/dayevent/group/search';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getDayEventsGroupedWW(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/dayevent/ww/group/search';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getRegisterLeave() {
        return Endpoints.getDomain() + '/vulcan/r/leave/request';
    }

    static getUserSettings() {
        return Endpoints.getDomain() + '/vulcan/r/company/user/settings/get';
    }

    static getDayEventsLite(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/dayevent/search/lite';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getDayEventsFeather(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/dayevent/search/feather';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getCompanyImportExportGetAllCurrentEmployees(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r//company/importexport/search';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getLeaves(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/leave/search';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getLeavesToApprove(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/leave/toapprove/search';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getLeaveTypesLite() {
        return Endpoints.getDomain() + '/vulcan/r/leavetype/search/lite';
    }

    static getReportsLeaves(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/reports/leaves';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getReportsAnnual(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/reports/leavetype/group/year';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getReportsGeneral(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/reports/general';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getReportsPresence(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/reports/presence';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getReportsPresenceDownload(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/reports/presence/download';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getReportsLeavesDownload(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/reports/leaves/download';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getReportsAnnualDownload(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/reports/leavetype/group/year/download';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getReportsGeneralDownload(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/reports/general/download';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getLeaveTypesPerYear(cuid: any, year: any) {
        return Endpoints.getDomain() + '/vulcan/r/company/user/leavetype/group/year?cuid=' +
            cuid + '&year=' + year;
    }

    static getIntegrations() {
        return Endpoints.getDomain() + '/vulcan/r/company/user/settings/integrations/get';
    }

    static getEmployeeUpdateLite() {
        return Endpoints.getDomain() + '/vulcan/r/company/user/update/lite';
    }

    static getOtherLeaves(id?: number | string, otherEmployees?: any) {
        return Endpoints.getDomain() + '/vulcan/r/leave/other?lid=' + id + '&ocuids=' + otherEmployees;
    }

    static createBlackoutEvent() {
        return Endpoints.getDomain() + '/vulcan/r/blackoutevent/create';
    }

    static deleteBlackoutEvent(id?: number | string) {
        return Endpoints.getDomain() + '/vulcan/r/blackoutevent/' + id;
    }

    static getBlackoutEvents(options?: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/blackoutevent/search?caid=' + options.filters.office.value.id;
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static createHoe() {
        return Endpoints.getDomain() + '/vulcan/r/holidayorevent/create';
    }

    static deleteHoe(id?: number | string) {
        return Endpoints.getDomain() + '/vulcan/r/holidayorevent/' + id;
    }

    static createHoes() {
        return Endpoints.getDomain() + '/vulcan/r/holidayorevent/arraycreate';
    }

    static getSubscriptionOrders() {
        return Endpoints.getDomain() + '/vulcan/r/company/subscriptionorder/search/lite?q=';
    }

    static getCurrentSubscription() {
        return Endpoints.getDomain() + '/vulcan/r/company/current/subscription/get';
    }

    static getCurrentSubscriptionCheck() {
        return Endpoints.getDomain() + '/vulcan/r/company/current/subscription/check';
    }

    static getCompanySlackApp() {
        return Endpoints.getDomain() + '/vulcan/r/company/settings/slackapp';
    }

    static getImportFromSlack() {
        return Endpoints.getDomain() + '/vulcan/r/company/importfromslack';
    }

    static getLeavePaperCopy(leaveId: any) {
        return Endpoints.getDomain() + '/vulcan/r/leave/' + leaveId + '/pdf?locale=ro';
    }

    static getLeaveApprove(leaveId: any) {
        return Endpoints.getDomain() + '/vulcan/r/leave/' + leaveId + '/approve';
    }

    static getLeaveReject(leaveId: any) {
        return Endpoints.getDomain() + '/vulcan/r/leave/' + leaveId + '/reject';
    }


    static getLeaveTypePriority() {
        return Endpoints.getDomain() + '/vulcan/r/leavetype/priority';
    }

    static getLeaveCancel(leaveId: any) {
        return Endpoints.getDomain() + '/vulcan/r/leave/' + leaveId + '/cancel';
    }

    static getLeaveSendReminder(leaveId: any) {
        return Endpoints.getDomain() + '/vulcan/r/leave/' + leaveId + '/remind';
    }

    static getLeave(leaveId: any) {
        return Endpoints.getDomain() + '/vulcan/r/leave/' + leaveId + '/get';
    }

    static getHoeImportSearch(country: any, year: any) {
        return Endpoints.getDomain() + '/vulcan/r/holidayorevent/import/search?country=' +
            encodeURIComponent(country) + '&year=' + year;
    }

    static getMyAccountIntegrations() {
        return Endpoints.getDomain() + '/vulcan/r/company/user/settings/integrations';
    }

    static getMeOffice365Sync() {
        return Endpoints.getDomain() + '/vulcan/r/company/user/office365/sync';
    }

    static getCompanyActivation(token: any, email: any) {
        return Endpoints.getDomain() + '/vulcan/r/company/activate?token=' +
            encodeURIComponent(token) + '&email=' + encodeURIComponent(email);
    }

    static getCountryForIP() {
        return Endpoints.getDomain() + '/vulcan/r/ip/country';
    }

    static getCompanySettings() {
        return Endpoints.getDomain() + '/vulcan/r/company/settings/get';
    }

    static getCompanySettingsUpdate() {
        return Endpoints.getDomain() + '/vulcan/r/company/settings/update';
    }

    static getCompanyPermissions() {
        return Endpoints.getDomain() + '/vulcan/r/company/permissions/get';
    }

    static getCompanyPermissionsUpdate() {
        return Endpoints.getDomain() + '/vulcan/r/company/permissions/update';
    }

    static getCompanyActivate() {
        return Endpoints.getDomain() + '/vulcan/r/company/activate';
    }

    static getCompanyUseriCalendarRegenerate() {
        return Endpoints.getDomain() + '/vulcan/r/company/user/regenerate/icalendar';
    }

    static getCompanyiCalendarRegenerate() {
        return Endpoints.getDomain() + '/vulcan/r/company/regenerate/icalendar';
    }

    static getOrderSettings() {
        return Endpoints.getDomain() + '/vulcan/r/order/settings';
    }

    static getSubscriptionOrderDraft() {
        return Endpoints.getDomain() + '/vulcan/r/company/subscriptionorder/draft';
    }

    static getLeaveCheck(options: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/leave/check';
        if (options.cuid) {
            url += '?cuid=' + options.cuid;
        }
        if (options.ltid) {
            url += '&ltid=' + options.ltid;
        }
        if (options.sd) {
            url += '&sd=' + formatForServer(options.sd);
        }
        if (options.ed) {
            url += '&ed=' + formatForServer(options.ed);
        }
        if (options.dt) {
            url += '&dt=' + options.dt;
        }
        if (options.hours) {
            url += '&hours=' + options.hours;
        }
        if (options.ocuids) {
            url += '&ocuids=' + options.ocuids;
        }
        return url;
    }

    static getEmployeeLeaveTypes(cuid: any) {
        return Endpoints.getDomain() + '/vulcan/r/company/user/leavetypes?cuid=' + cuid;
    }

    static getEmployeeLeaveTypesRefix(cuid: any) {
        return Endpoints.getDomain() + '/vulcan/r/company/user/refix?cuid=' + cuid;
    }

    static getUpdateEmployeeLeaveType(id: any) {
        return Endpoints.getDomain() + '/vulcan/r/company/user/leavetype?cuid=' + id;
    }

    static getUpdateEmployeeLeaveTypePerYear(id: any) {
        return Endpoints.getDomain() + '/vulcan/r/company/user/leavetype/year?cuid=' + id;
    }

    static getLeaveTypePerEmployeePerYear(leaveTypesIds: any, year: any) {
        return Endpoints.getDomain() + '/vulcan/r/company/leavetype/group/year?leaveTypeIds=' +
            leaveTypesIds + '&year=' + year;
    }

    static getLeaveTypePerEmployeePerYearFiltered(filters: any) {
        let url = Endpoints.getDomain() + '/vulcan/r/company/leavetype/group/year';
        if (filters.leaveTypes) {
            url += '?leaveTypes=' + filters.leaveTypes;
        }
        if (filters.year) {
            url += '&year=' + filters.year;
        }
        if (filters.units) {
            url += '&units=' + filters.units;
        }
        if (filters.offices) {
            url += '&offices=' + filters.offices;
        }
        if (filters.subsidiaries) {
            url += '&subsidiaries=' + filters.subsidiaries;
        }
        if (filters.q) {
            url += '&q=' + filters.q;
        }
        return url;
    }


    static getBraintreeSettings(currency: any) {
        return Endpoints.getDomain() + '/vulcan/r/braintree/client_token?currency=' + currency;
    }

    static getCompanyInformation() {
        return Endpoints.getDomain() + '/vulcan/r/company/information/update';
    }

    static getSubscriptionOrder() {
        return Endpoints.getDomain() + '/vulcan/r/company/subscriptionorder/order';
    }

    static viesValidate(countryISO: any, vatNumber: any) {
        return Endpoints.getDomain() + '/vulcan/r/vies/validate?countryISO=' +
            countryISO + '&vatNumber=' + vatNumber;
    }

    static getHideNextSteps() {
        return Endpoints.getDomain() + '/vulcan/r/company/user/hideNextSteps';
    }

    static getHideCustomMessage() {
        return Endpoints.getDomain() + '/vulcan/r/company/user/hideCustomMessage';
    }

    static getSupportJson(lang?: any) {
        if (lang === 'ro') {
            return Endpoints.getDomain() + '/ro/support.json';
        }
        return Endpoints.getDomain() + '/support.json';
    }
}