import * as React from 'react';
import ResponsiveModal from './ResponsiveModal';
import Table from '../../Containers/Dashboard/MyNextHoes/Table';

export default class MyNextHoes extends ResponsiveModal {
    getContent() {
        return (
            <Table
                rows={this.props.rows}
                showAll={true}
            />
        );
    }
}