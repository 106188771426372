import * as React from 'react';
import Component from '../../Component';
import * as $ from 'jquery';
import InputField from '../../Components/InputField';
import Submit from '../../Components/Submit';
import Endpoints from '../../Endpoints';

export default class ForgotPassword extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            email: {
                touched: false,
                rules: this.Rules().BasicEmailRules()
            },
            recover: {
                pleaseWait: false
            },
            sent: false
        };
    }

    render() {
        const t = this.translate();
        const form = (
            <form
                onSubmit={(event: any) => { this.submit(event); }}
            >
                <fieldset>
                    <div className="row">
                        <div className="col-12 mb-3 text-left">
                            {t('forgot.your.account.password')}
                        </div>
                        <div className="col-12 mb-3">
                            <InputField
                                touched={this.state.email.touched}
                                rules={this.state.email.rules}
                                focus={this.isScreenSizeSmall() ? false : true}
                                type="text"
                                placeholder={t('your.email.address')}
                                onChange={(value: any) => {
                                    this.setState(
                                        { email: Object.assign({}, this.state.email, { value: value }) }
                                    );
                                }}
                            />
                        </div>
                        <div className="col-12 mb-3">
                            <Submit
                                faIcon="far fa-paper-plane"
                                disabled={!this.isFormValid()}
                                pleaseWait={this.state.recover.pleaseWait}
                            >
                                {t('recover')}
                            </Submit>
                        </div>
                    </div>
                </fieldset>
            </form>
        );
        return (
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className={this.getCardClassNameSize() + ' ml-auto mr-auto'}>
                            <div className="card-body">
                                {!this.state.sent ?
                                    form :
                                    t('in.the.shortest.time.you.will.receive.in.the.email.the.new.password')
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    isFormValid() {
        return this.Validator().validate(this.state.email);
    }
    submit(event: any) {
        event.preventDefault();
        this.setState({ email: Object.assign({}, this.state.email, { touched: true }) }, () => {
            if (this.isFormValid()) {
                this.recoverAction();
            }
        });
    }

    recoverAction() {
        const t = this.translate();
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: Endpoints.getForgotPassword(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                email: this.state.email.value,
                language: this.language()
            }),
            beforeSend: () => {
                this.setState({ recover: Object.assign({}, this.state.recover, { pleaseWait: true }) });
            },
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('your.request.has.been.registered'));
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxGeneralError(jqXHR, textStatus, errorThrown);
            },
            complete: (jqXHR, textStatus) => {
                this.setState({ recover: Object.assign({}, this.state.recover, { pleaseWait: false }) });
                this.setState({ sent: true });
            }
        });
    }
}