import * as React from 'react';
import Component from '../../Component';
import { SortBy } from '../../Components';
import Download from '../../Components/Download';
import FiltersSwitch from '../../Components/FiltersSwitch';
import GridOrListSwitch from '../../Components/GridOrListSwitch';
import InputField from '../../Components/InputField';
import SelectField from '../../Components/SelectField';
import { countriesOptionsFactory, officesOptionsFactory, subsidiariesOptionsFactory, unitsOptionsFactory, usersOptionsFactory } from '../../SelectOptionsFactories';
import { Cookie } from '../../Utils';

export default class Filters extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            expanded: false,
            viewMode: this.props.viewMode,
            sortBy: this.props.sortBy,
            search: {
                touched: false
            },
            units: {
                touched: false,
            },
            offices: {
                touched: false,
            },
            subsidiaries: {
                touched: false,
            },
            countries: {
                touched: false
            },
            approvers: {
                value: [],
                defaultValue: [],
                touched: false,
            },

        };
        this.onChange = this.onChange.bind(this);
        this.handleSortOrderChange = this.handleSortOrderChange.bind(this);
        this.handleSortFieldChange = this.handleSortFieldChange.bind(this);
    }

    onChange() { if (this.props.onChange) { this.props.onChange(this.state); } }

    public handleSortOrderChange() {
        const sortBy: any = Object.assign({}, this.state.sortBy);
        sortBy.order = (sortBy.order === 'ASC') ? 'DESC' : 'ASC';
        this.setState({ sortBy }, this.onChange);
    }

    public handleSortFieldChange(sortField: any) {
        const sortBy: any = Object.assign({}, this.state.sortBy);
        sortBy.field = sortField;
        this.setState({ sortBy }, this.onChange);
    }

    render() {
        const t = this.translate();
        const caches: any = this.getCaches().getState();
        const usersOptions = usersOptionsFactory(caches.users.arr);
        const unitsOptions = unitsOptionsFactory(caches.units.arr);
        const officesOptions = officesOptionsFactory(caches.offices.arr);
        const countriesOptions = countriesOptionsFactory(caches.countries.arr[this.language()]);
        const subsidiariesOptions = subsidiariesOptionsFactory(caches.subsidiaries.arr);
        const session: any = this.getSession().getState();
        return <div>
            <form onSubmit={(event: any) => { event.preventDefault(); }}>
                <div className="row">
                    <div className="col-7 col-sm-8 col-md-7 mb-3">
                        <InputField
                            placeholder={t('search')}
                            defaultValue=""
                            onChange={(value: any) => { this.setState({ search: Object.assign({}, this.state.search, { value: value }) }, this.onChange); }}
                        />
                    </div>
                    <div className="col-5 col-sm-4 col-md-5 mb-3">
                        <div className="float-right mt-2">
                            {this.state.sortBy ? <SortBy sortBy={this.state.sortBy} onSortFieldChangeTrigger={this.handleSortFieldChange} onSortOrderChangeTrigger={this.handleSortOrderChange} /> : null}
                            <Download download={this.props.download} />
                            <GridOrListSwitch
                                viewMode={this.state.viewMode}
                                onChange={(value: any) => { this.setState({ viewMode: value }, () => { this.onChange(); Cookie.create(Cookie.EMPLOYEES_YEARS_OF_SERVICE_VIEWMODE, value); }); }}
                            />
                            <FiltersSwitch
                                expanded={this.state.expanded}
                                onClick={() => { this.setState({ expanded: !this.state.expanded }); }}
                            />
                        </div>
                    </div>
                </div>
                {(this.state.expanded) ?
                    <div className="row">
                        {(unitsOptions && unitsOptions.length > 0) ?
                            <div className="col-12 col-lg-6 mb-3">
                                <SelectField
                                    defaultValue={this.state.units.value}
                                    touched={this.state.units.touched}
                                    options={unitsOptions}
                                    multiple={true}
                                    placeholder={t('teams')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { units: Object.assign({}, this.state.units, { value }) },
                                            this.onChange
                                        );
                                    }}
                                />
                            </div>
                            : null}
                        {(officesOptions?.length > 1) ?
                            <div className="col-12 col-lg-6 mb-3">
                                <SelectField
                                    defaultValue={this.state.offices.value}
                                    touched={this.state.offices.touched}
                                    options={officesOptions}
                                    multiple={true}
                                    placeholder={t('offices')}
                                    onChange={(value: any) => {
                                        this.setState({ offices: Object.assign({}, this.state.offices, { value }) }, this.onChange);
                                    }}
                                />
                            </div> : null}
                        {session.company.subsidiariesEnabled && subsidiariesOptions ?
                            <div className="col-12 col-lg-6 mb-3">
                                <SelectField
                                    defaultValue={this.state.subsidiaries.value}
                                    touched={this.state.subsidiaries.touched}
                                    options={subsidiariesOptions}
                                    multiple={true}
                                    placeholder={t('subsidiaries')}
                                    onChange={(value: any) => {
                                        this.setState({ subsidiaries: Object.assign({}, this.state.subsidiaries, { value }) }, this.onChange);
                                    }}
                                />
                            </div> : null}
                        <div className="col-12 col-lg-6 mb-3">
                            <SelectField
                                defaultValue={this.state.countries.value}
                                touched={this.state.countries.touched}
                                options={countriesOptions}
                                multiple={true}
                                placeholder={t('countries')}
                                onChange={(value: any) => {
                                    this.setState(
                                        { countries: Object.assign({}, this.state.countries, { value: value }) },
                                        () => { this.onChange(); }
                                    );
                                }}
                            />
                        </div>
                        <div className="col-12 col-lg-6 mb-3">
                            <SelectField
                                defaultValue={this.state.approvers.value}
                                touched={this.state.approvers.touched}
                                options={usersOptions}
                                placeholder={t('approvers')}
                                multiple={true}
                                maxDisplayedOptions={3}
                                onChange={(value: any) => {
                                    this.setState({ approvers: Object.assign({}, this.state.approvers, { value }) },
                                        this.onChange
                                    );
                                }}
                            />
                        </div>
                    </div>
                    : null}
            </form>
        </div>
    }
}