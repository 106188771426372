import * as React from 'react';
import Component from '../../../Component';
import * as $ from 'jquery';
import InputField from '../../../Components/InputField';
import Submit from '../../../Components/Submit';
import Close from '../../../Components/Close';

export default class ChangePassword extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            currentPassword: {
                touched: false,
                rules: this.Rules().BasicPasswordRules()
            },
            newPassword: {
                touched: false,
                rules: this.Rules().BasicPasswordRules()
            },
            newRepeatedPassword: {
                touched: false,
                rules: this.Rules().BasicPasswordRules()
            },
            change: {
                pleaseWait: false
            },
        };
    }

    render() {
        const t = this.translate();
        return (
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <form onSubmit={(event: any) => { this.submit(event); }}>
                        <fieldset>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <Close
                                        // tslint:disable-next-line:max-line-length
                                        onClick={() => { if (this.props.onPasswordChanged) { this.props.onPasswordChanged(); } }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <InputField
                                        touched={this.state.currentPassword.touched}
                                        rules={this.state.currentPassword.rules}
                                        focus={this.isScreenSizeSmall() ? false : true}
                                        type="password"
                                        placeholder={t('current.password')}
                                        onChange={(value: any) => {
                                            // tslint:disable-next-line:max-line-length
                                            this.setState({ currentPassword: Object.assign({}, this.state.currentPassword, { value: value }) });
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <InputField
                                        touched={this.state.newPassword.touched}
                                        rules={this.state.newPassword.rules}
                                        type="password"
                                        placeholder={t('new.password')}
                                        onChange={(value: any) => {
                                            // tslint:disable-next-line:max-line-length
                                            this.setState({ newPassword: Object.assign({}, this.state.newPassword, { value: value }) });
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <InputField
                                        touched={this.state.newRepeatedPassword.touched}
                                        rules={this.state.newRepeatedPassword.rules}
                                        type="password"
                                        placeholder={t('repeat.the.new.password')}
                                        onChange={(value: any) => {
                                            // tslint:disable-next-line:max-line-length
                                            this.setState({ newRepeatedPassword: Object.assign({}, this.state.newRepeatedPassword, { value: value }) });
                                        }}
                                    />
                                </div>
                                <div className="col-12">
                                    <Submit
                                        faIcon="fas fa-key"
                                        disabled={!this.isFormValid()}
                                        pleaseWait={this.state.change.pleaseWait}
                                    >
                                        {t('change.password')}
                                    </Submit>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        );
    }

    isFormValid() {
        return this.Validator().validate(this.state.currentPassword) &&
            this.Validator().validate(this.state.newPassword) &&
            this.Validator().validate(this.state.newRepeatedPassword);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                currentPassword: Object.assign({}, this.state.currentPassword, { touched: true }),
                newPassword: Object.assign({}, this.state.newPassword, { touched: true }),
                newRepeatedPassword: Object.assign({}, this.state.newRepeatedPassword, { touched: true })
            },
            () => { if (this.isFormValid()) { this.changePasswordAction(); } });
    }

    changePasswordAction() {
        const t = this.translate();
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getChangeUserPassword(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                currentPassword: this.state.currentPassword.value,
                newPassword: this.state.newPassword.value,
                newRepeatedPassword: this.state.newRepeatedPassword.value
            }),
            beforeSend: () => {
                this.setState({ change: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.setState({
                    currentPassword:
                        Object.assign({}, this.state.currentPassword, { value: '', touched: false }),
                    newPassword:
                        Object.assign({}, this.state.newPassword, { value: '', touched: false }),
                    newRepeatedPassword:
                        Object.assign({}, this.state.newRepeatedPassword, { value: '', touched: false }),
                });
                this.successToastr(t('your.password.was.changed'));
                if (this.props.onPasswordChanged) { this.props.onPasswordChanged(); }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            },
            complete: (jqXHR, textStatus) => {
                this.setState({ change: { pleaseWait: false } });
            }
        });
    }
}