import * as React from 'react';
import Component from '../../../Component';
import Display from './Display';
import Form from './Form';
import Header from '../Header/Header';

export default class Settings extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            leaveType: this.props.leaveType,
            editMode: false
        };
    }

    render() {
        return <div className="w-100">
            <Header
                leaveType={this.state.editMode ? this.state.leaveType : this.props.leaveType}
                currentTab={this.props.currentTab}
                onTabChange={(tab: any) => { if (this.props.onTabChange) { this.props.onTabChange(tab); } }}
            />
            <div className="col-12 mb-3">
                {(this.state.editMode) ? <Form
                    leaveType={this.props.leaveType}
                    onUpdate={(leaveType: any) => { this.setState({ leaveType: leaveType }); }}
                    onRefresh={() => {
                        this.setState({ editMode: !this.state.editMode });
                        if (this.props.onRefresh) { this.props.onRefresh(); }
                    }}
                />
                    : <Display
                        leaveType={this.props.leaveType}
                        onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }}
                        onEdit={() => { this.setState({ editMode: !this.state.editMode }); }}
                    />}
            </div>
        </div>
    }
}