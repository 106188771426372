import * as $ from 'jquery';
import { createParamsFromFilters } from './Utils';

export default class Urls {
    static getDomain() {
        return Urls.getPureDomain() + '/nox';
    }

    static getPureDomain() {
        var url = window.location.href;
        var arr = url.split('/');
        return arr[0] + '//' + arr[2];
    }

    static getLogin(options?: any) {
        return Urls.getDomain() + '/login.html' + (options && options.target ? '?target=' + options.target : '');
    }

    static getLocalOAuth(code?: any) { return Urls.getDomain() + '/localoauth.html' + (code ? '?code=' + code : ''); }

    static getForgotPassword() { return Urls.getDomain() + '/forgotpassword.html'; }

    static getResetPassword() { return Urls.getDomain() + '/resetpassword.html'; }

    static getRegisterCompany() { return Urls.getDomain() + '/registercompany.html'; }

    static getCompanyRegisteredSuccessfully() { return Urls.getDomain() + '/companyregisteredsuccessfully.html'; }

    static getUserNotFound() { return Urls.getDomain() + '/usernotfound.html'; }

    static getUserNotAllowed() { return Urls.getDomain() + '/usernotallowed.html'; }

    static getDashboard() { return Urls.getDomain() + '/dashboard.html'; }

    static getEmployeeFinishProfile() { return Urls.getDomain() + '/employeefinishprofile.html'; }

    static getMyLeaves(options?: any) {
        let url = Urls.getDomain() + '/myleaves.html';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getLeavesToApprove(options?: any) {
        let url = Urls.getDomain() + '/leavestoapprove.html';
        if (options) {
            return createParamsFromFilters(url, options.filters);
        }
        return url;
    }

    static getOverview() { return Urls.getDomain() + '/overview.html'; }

    static getCalendar() { return Urls.getDomain() + '/calendar.html'; }

    static getUnits() { return Urls.getDomain() + '/units.html'; }

    static getUnit(id?: any, tab?: any) {
        return Urls.getDomain() + '/unit.html' + (id ? '?id=' + id : '') + (tab ? '#' + tab : '');
    }

    static getUnitAdd() { return Urls.getDomain() + '/unitadd.html'; }

    static getSubsidiaries() { return Urls.getDomain() + '/subsidiaries.html'; }

    static getSubsidiaryAdd() { return Urls.getDomain() + '/subsidiaryadd.html'; }

    static getSubsidiary(id?: any, tab?: any) {
        return Urls.getDomain() + '/subsidiary.html' + (id ? '?id=' + id : '') + (tab ? '#' + tab : '');
    }

    static getOffices() { return Urls.getDomain() + '/offices.html'; }

    static getOfficeAdd() { return Urls.getDomain() + '/officeadd.html'; }

    static getOffice(id?: any, tab?: any) {
        return Urls.getDomain() + '/office.html' + (id ? '?id=' + id : '') + (tab ? '#' + tab : '');
    }

    static getLeaveTypes() { return Urls.getDomain() + '/leavetypes.html'; }

    static getLeaveTypeAdd() { return Urls.getDomain() + '/leavetypesadd.html'; }

    static getLeaveType(id?: any) {
        return Urls.getDomain() + '/leavetype.html' + (id ? '?id=' + id : '');
    }

    static getNotificationRules() { return Urls.getDomain() + '/notificationrules.html'; }

    static getNotificationRuleAdd() { return Urls.getDomain() + '/notificationruleadd.html'; }

    static getNotificationRule(id?: any) {
        return Urls.getDomain() + '/notificationrule.html' + (id ? '?id=' + id : '');
    }

    static getEmployeesServiceLength() { return Urls.getDomain() + '/employeesservicelength.html'; }

    static getEmployeesReports() { return Urls.getDomain() + '/employeesreports.html'; }

    static getEmployeesAge() { return Urls.getDomain() + '/employeesage.html'; }

    static getEmployees() { return Urls.getDomain() + '/employees.html'; }

    static getEmployeesInvite() { return Urls.getDomain() + '/employeesinvite.html'; }

    static getActivateEmployee() { return Urls.getDomain() + '/activateemployee.html'; }

    static getActivateUserToBeDeleted() { return Urls.getDomain() + '/activateuser.html'; }

    static getEmployeesImportFromSlack() { return Urls.getDomain() + '/employeesimportfromslack.html'; }

    static getEmployee(id?: any, tab?: any) {
        return Urls.getDomain() + '/employee.html' + (id ? '?id=' + id : '') + (tab ? '#' + tab : '');
    }

    static getSupport() { return Urls.getDomain() + '/support.html'; }

    static getMyAccount(options?: any) {
        let anchor = '';
        if (options && options.tab) {
            anchor = anchor + '#' + options.tab;
        }
        return Urls.getDomain() + '/myaccount.html' + anchor;
    }

    static getCompany(options?: any) {
        let anchor = '';
        if (options && options.tab) {
            anchor = anchor + '#' + options.tab;
        }
        return Urls.getDomain() + '/company.html' + anchor;
    }

    static getReports(options?: any) {
        let url = Urls.getDomain() + '/reports.html';
        if (options && options.tab) {
            url = url + '#' + options.tab;
        }
        return url;
    }

    static getImportExport(options?: any) {
        let anchor = '';
        if (options && options.tab) {
            anchor = anchor + '#' + options.tab;
        }
        return Urls.getDomain() + '/importexport.html' + anchor;
    }


    static getLogout() { return Urls.getDomain() + '/logout.html'; }

    static getGoogleOAuth() { return Urls.getDomain() + '/googleoauth.html'; }

    static getGoogleClientAuthUrl() {
        var google = {
            scope: ['https://www.googleapis.com/auth/userinfo.email',
                'https://www.googleapis.com/auth/userinfo.profile']
                .join(' '),
            response_type: 'code',
            client_id: '544789867804-8k2u4h49ido8dcjlsdhrsd7b7q7rrh4p.apps.googleusercontent.com', // prod
            // client_id: '675875848361-qo7h06p6k9aa1jch5kv8l7sjm7hf7d7j.apps.googleusercontent.com', //dev
            access_type: 'offline',
            prompt: 'consent',
            redirect_uri: Urls.getGoogleOAuth()
        };
        return 'https://accounts.google.com/o/oauth2/v2/auth?' + $.param(google);
    }

    static getOffice365OAuth() { return Urls.getDomain() + '/office365oauth.html'; }

    static getOffice365ClientAuthUrl() {
        var office365 = {
            scope: ['openid', 'offline_access', 'profile', 'https://graph.microsoft.com/User.Read',
                'https://graph.microsoft.com/User.ReadBasic.All',
                'https://graph.microsoft.com/Calendars.ReadWrite',
                'https://graph.microsoft.com/Tasks.ReadWrite']
                .join(' '),
            response_type: 'code',
            response_mode: 'query',
            // client_id: '606442e9-2eed-4bff-8867-69f1d3eab91a', //dev 
            client_id: 'dd8d4d44-3416-4d43-b23c-a1dcf200c45a', // prod
            redirect_uri: Urls.getOffice365OAuth()
        };
        return 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' + $.param(office365);
    }

    static getSlackOAuth() { return Urls.getDomain() + '/slackoauth.html'; }

    static getSlackClientAuthUrl() {
        var slack = {
            scope: ['identity.basic', 'identity.email']
                .join(' '),
            response_type: 'code',
            // client_id: '185650446388.195997740098',//dev
            client_id: '185650446388.193628195299', // prod
            redirect_uri: Urls.getSlackOAuth()
        };
        return 'https://slack.com/oauth/authorize?' + $.param(slack);
    }

    static getInvoice(guid: any, locale: any) {
        return Urls.getPureDomain() + '/vulcan/r/invoice/' + guid + '/get/pdf?locale=' + locale;
    }

    static getOrderSubscription(options?: any) {
        let params = '';
        if (options && options.currency) {
            params += 'currency=' + options.currency;
        }
        if (options && options.interval) {
            if (options && options.currency) {
                params += '&';
            }
            params += 'interval=' + options.interval;
        }
        return Urls.getDomain() + '/ordersubscription.html' + (params && params.length > 0 ? '?' + params : '');
    }

    static getActivateCompany() { return Urls.getDomain() + '/activatecompany.html'; }

    static getTerms(language: any) {
        switch (language.toLowerCase()) {
            case 'ro':
                return Urls.getPureDomain() + '/ro/termenii-serviciului/';
            default:
                return Urls.getPureDomain() + '/terms/';
        }
    }

    static getSubscribeNow(trialHasEnded?: any) {
        return Urls.getDomain() + '/subscribenow.html' + (trialHasEnded ? '?trialHasEnded=' + true : '');
    }
}