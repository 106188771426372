import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import { STATUS } from '../../../General';
import CompanyViewManager from '../../Calendar/CompanyView/Manager';
import Table from './Table';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        this.state = {
            filters: {
                startDate: { value: moment().startOf('day') },
                endDate: { value: moment().add(6, 'months') },
                nwhoel: { value: [1] },
                users: {
                    value: [(this.getSession().getState() as any).companyUser.id],
                },
            },
            rows: {
                arr: [],
                status: STATUS.LOADING
            }
        };
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState(
            {
                rows: {
                    arr: [],
                    status: STATUS.LOADING
                }
            },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getDayEventsGrouped({
                        filters: this.state.filters
                    }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        const rows = this.computeDayEvents(data.data);
                        if (rows.length === 0 || !rows[0].day) {
                            if (this.props.onEmpty) { this.props.onEmpty(); }
                        }
                        this.setState(
                            {
                                rows: {
                                    arr: rows,
                                    status: STATUS.READY
                                }
                            }
                        );

                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                rows: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    computeDayEvents(data: any) {
        const moment = this.getMomentWithLocale();
        const session = (this.getSession().getState() as any);
        return data.map((item: any) => {
            return CompanyViewManager.createDayEvent(item, session);
        }).sort((a: any, b: any) => {
            return moment(a.day).valueOf() - moment(b.day).valueOf();
        });
    }

    render() {
        if (this.state.rows.arr.length === 0) {
            return null as any;
        }
        return (
            <div className="card h-100">
                <div className="card-body">
                    <Table rows={this.state.rows} />
                </div>
            </div>
        );
    }
}