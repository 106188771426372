import * as React from 'react';
import Component from '../../Component';
import Anchor from '../Anchor';
import { leaveDecision } from '../../Actions/Modal';
import Fa from '../Fa';

export default class LeaveDecisionActionTiny extends Component {
    render() {
        const t = this.translate();
        return (
            <Anchor
                className="card-link ml-1"
                title={t('approve.or.decline')}
                onClick={() => {
                    leaveDecision(
                        this.getModal(),
                        {
                            leave: this.props.leave,
                            callback: () => { if (this.props.onRefresh) { this.props.onRefresh(); } }
                        }
                    );
                }}
            >
                <span className="text-success">
                    <Fa fa="far fa-thumbs-up fa-fw" />
                </span>
                {' '}
                <span className="text-danger">
                    <Fa fa="far fa-thumbs-down fa-fw" />
                </span>
            </Anchor>
        );
    }
}