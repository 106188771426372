import * as React from 'react';
import ResponsiveModal from './ResponsiveModal';
import Table from '../../Containers/Dashboard/LeaveTypesBalance/Table';

export default class LeaveTypesBalance extends ResponsiveModal {
    getContent() {
        return (
            <Table rows={this.props.rows} showAll={true} />
        );
    }
}