import * as React from 'react';
import Component from '../../../Component';
import A from '../../../Components/A';
import TableLine from '../../../Components/TableLine';

export default class View extends Component {

    render() {
        const t = this.translate();
        const imgAttrs = {
            height: '40',
            width: '139',
            srcSet: 'https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x'
        };
        return (
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="w-100">
                                <strong>Setup your Slack integration</strong>
                                <TableLine />
                                <a href="https://slack.com/oauth/authorize?&client_id=185650446388.193628195299&scope=commands,chat:write:bot,users.profile:read,users:read"
                                    rel="noopener noreferrer">
                                    <img
                                        alt="Add to Slack"
                                        src="https://platform.slack-edge.com/img/add_to_slack.png"
                                        {...imgAttrs}
                                    />
                                </a>
                                <div className="mt-3">
                                    Please note we need to access following permissions
                                    in order to be able to setup
                                    the Slack - LeaveBoard integration:
                                </div>
                                <ul>
                                    <li>Add commands to LeaveBoard</li>
                                    <li>Send messages as LeaveBoard</li>
                                    <li>Access your profile and your team's profile fields</li>
                                    <li>Access your team's profile information</li>
                                </ul>
                            </div>
                            <A faIcon={this.isScreenSizeSmall() ? null : 'fab fa-slack-hash'}
                                href={this.props.slackApp ?
                                    this.Urls().getEmployeesImportFromSlack()
                                    : '##'}
                                className={(this.props.slackApp ?
                                    'btn btn-outline-primary btn-block' : 'btn btn-outline-primary btn-block disabled')}
                            >
                                {t('import.employees.form.slack')}
                            </A>
                            {(!this.props.slackApp) ?
                                <div>
                                    <small>
                                        {t('after.slack.activation.import')}
                                    </small>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}