import * as React from 'react';
import Component from '../../Component';
import Error from '../../Components/Error';
import { STATUS } from '../../General';
import CheckBox from '../../Components/CheckBox';
import Anchor from '../../Components/Anchor';
import Submit from '../../Components/Submit';
import { UncontrolledTooltip } from 'reactstrap';
import { doFetchUnitsFromServer, doFetchUsersFromServer } from '../../Actions/Caches';
import * as $ from 'jquery';
import { trialStarted } from '../../Actions/Modal';
import { doLoadSession } from '../../Actions/Session';

export default class List extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            import: { pleaseWait: false }
        }
    }

    render() {
        const employees = this.props.employees;
        switch (employees.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                if (employees.arr.length === 0) {
                    return (
                        <div className="col-12 text-danger mt-3 mb-3">
                            {t('no.employees.found')}
                        </div>
                    );
                }
                const trs = employees.arr.map((employee: any) => {
                    return (
                        <tr key={'EmployeesImportFromSlack-List-Employee-' + employee.email}>
                            <td style={{ width: 10 }}>
                                <CheckBox
                                    defaultValue={employee.import}
                                    disabled={employee.alreadyExists}
                                    onChange={(value: any) => { employee.import = !employee.import; }}
                                />
                            </td>
                            <td>
                                <div className={employee.alreadyExists ? 'font-weight-light' : ''}>
                                    {employee.fullName}
                                    {
                                        (employee.alreadyExists) ?
                                            <div
                                                className="ml-1 d-inline"
                                                style={{
                                                    zIndex: 4
                                                }}
                                            >
                                                <Anchor
                                                    id={'employeeAlready' + employee.id}
                                                    faIcon="fas fa-exclamation-circle text-warning"
                                                    tabIndex={-1}
                                                />
                                                <UncontrolledTooltip
                                                    delay={{ show: 0, hide: 250 }}
                                                    placement="top"
                                                    target={'employeeAlready' + employee.id}
                                                >
                                                    {t('employee.already.invited')}
                                                </UncontrolledTooltip>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        (employee.justInvited) ?
                                            <div
                                                className="ml-1 d-inline float-right"
                                                style={{
                                                    zIndex: 4
                                                }}
                                            >
                                                <Anchor
                                                    id={'employeeJustInvited' + employee.id}
                                                    faIcon="far fa-check-circle text-success"
                                                    tabIndex={-1}
                                                />
                                                <UncontrolledTooltip
                                                    delay={{ show: 0, hide: 250 }}
                                                    placement="top"
                                                    target={'employeeJustInvited' + employee.id}
                                                >
                                                    {t('employee.invited')}
                                                </UncontrolledTooltip>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </td>
                        </tr>
                    );
                });
                return (
                    <div className={!this.isScreenSizeSmall() ? 'card bg-white w-50' : ''}>
                        <div className={!this.isScreenSizeSmall() ? 'card-body container-fluid' : ''}>
                            <div className="row bg-white">
                                <div className="col-12 mt-3 mb-3">
                                    <table className="table table-sm table-hover w-100 mb-0">
                                        <tbody>
                                            {trs}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colSpan={2} className="border-0">
                                                    <Submit
                                                        faIcon="fa fa-plane"
                                                        pleaseWait={this.state.import.pleaseWait}
                                                        onClick={(event: any) => { event.preventDefault(); this.importFromSlack(); }}
                                                    >
                                                        {t('invite.employees')}
                                                    </Submit>
                                                    <div className="clear-fix" />
                                                    <div>
                                                        <small className="text-muted">
                                                            <span>
                                                                {t('please.note.invitation.emails.will.be.sent')}
                                                            </span>
                                                        </small>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            default:
                return (<Error />);
        }
    }

    importFromSlack() {
        const employees: any = [];
        this.props.employees.arr.forEach((employee: any) => {
            if (employee.import) {
                const toBeImported = Object.assign({}, employee);
                toBeImported.employee = employee;
                employees.push(toBeImported);
            }
        });
        this.setState(
            { import: { pleaseWait: true } },
            () => { this.inviteEmployee(employees, 0); }
        );
    }

    inviteEmployee(employees: any, pos: number) {
        const t = this.translate();
        if (employees.length <= pos) {
            this.successToastr(t('the.employees.were.imported'));
            this.checkSubscription();
            doFetchUnitsFromServer(this.getCaches());
            doFetchUsersFromServer(this.getCaches(), this.getSession().getState());
            this.goToEmployees();
            return;
        }
        const employeeToUpload = Object.assign({}, employees[pos]);
        delete employeeToUpload.fullName;
        delete employeeToUpload.import;
        delete employeeToUpload.employee;
        delete employeeToUpload.justInvited;

        $.ajax({
            type: 'PUT',
            contentType: 'application/json',
            url: this.Endpoints().getEmployeesBulkInvite(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify([employeeToUpload]),
            success: (data, textStatus, jqXHR) => {
                employees[pos].employee.justInvited = true;
                this.forceUpdate();
            },
            complete: (jqXHR, textStatus) => {
                this.inviteEmployee(employees, pos + 1);
            }
        });
    }

    checkSubscription() {
        $.ajax({
            type: 'GET',
            contentType: 'application/json',
            url: this.Endpoints().getCurrentSubscriptionCheck(),
            dataType: 'json',
            cache: false,
            success: (data, textStatus, jqXHR) => {
                if (data && data.trialEndsOn) {
                    doLoadSession(this.getSession());
                    trialStarted(this.getModal(), {
                        trialEndsOn: data.trialEndsOn
                    });
                }
            }
        });
    }
}