import * as React from 'react';
import Component from '../../../Component';
import Display from './Display';
import Form from './Form';
import Header from '../Header/Header';

export default class Settings extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            office: this.props.office,
            editMode: false
        };
    }

    render() {
        return (
            <div className="w-100">
                <Header
                    office={this.state.editMode ? this.state.office : this.props.office}
                    currentTab={this.props.currentTab}
                    onTabChange={(tab: any) => {
                        if (this.props.onTabChange) { this.props.onTabChange(tab); }
                    }}
                />
                <div className="col-12 mb-3">
                    {(this.state.editMode) ?
                        <Form
                            office={this.props.office}
                            onUpdate={(office: any) => { this.setState({ office: office }); }}
                            onCancel={() => { this.setState({ editMode: !this.state.editMode }); }}
                            onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }}
                        />
                        :
                        <Display
                            office={this.props.office}
                            onEdit={() => { this.setState({ editMode: !this.state.editMode }); }}
                            onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }}
                        />}
                </div>
            </div>
        );
    }
}