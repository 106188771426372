import * as React from 'react';
import Component from '../Component';
import Anchor from './Anchor';
import Tooltip from './Tooltip';

export default class ToggleField extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            value: this.props.defaultValue ? this.props.defaultValue : '',
            defaultValue: this.props.defaultValue ? this.props.defaultValue : '',
            touched: false,
            rules: this.props.rules,
            valid: null,
            load: this.props.load ? this.props.load : false
        };

    }

    render() {
        const t = this.translate();
        return (
            <div>
                <label className="w-100 m-0">
                    {this.props.children} {' '}
                    {
                        (this.props.tooltip && this.props.tooltip.length > 0) ?
                            (<Tooltip textColor="info" tooltip={this.props.tooltip} />)
                            :
                            null
                    }
                    <Anchor
                        className={(this.props.disabled ? 'text-muted' : 'text-primary') + ' float-right'}
                        faIcon={
                            // tslint:disable-next-line:max-line-length
                            (this.state.value ? 'fa fa-toggle-on' : 'fa fa-toggle-on fa-rotate-180 text-muted') +
                            ' fa-lg'
                        }
                        title={this.state.value ? t('on') : t('off')}
                        onClick={() => {
                            if (!this.props.disabled) {
                                this.setState(
                                    { value: !this.state.value },
                                    () => {
                                        if (this.props.onChange) {
                                            this.props.onChange(this.state.value);
                                        }
                                    });
                            }
                        }}
                    />
                </label>
            </div>
        );
    }
}