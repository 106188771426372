import * as React from 'react';
import Component from '../../../Component';
import Error from '../../../Components/Error';
import Anchor from '../../../Components/Anchor';
import Date from '../../../Components/Date';
import { STATUS } from '../../../General';
import { myNextHoes } from '../../../Actions/Modal';
import { copyArray } from '../../../Utils';
import { findLocaleName } from '../../../Utils';

export default class Table extends Component {
    static ITEMS = 4;
    render() {
        const moment = this.getMomentWithLocale();
        const rows = this.props.rows;
        switch (rows.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                let i = 0;
                const trs = ((rows.arr.length > Table.ITEMS && !this.props.showAll) ?
                    copyArray(rows.arr, 0, Table.ITEMS - 1) : rows.arr).map((row: any) => {
                        return (
                            <tr key={'DashBoardNextHoesTable-' + row.day + '-' + i++}>
                                <td style={{ width: '65%', maxWidth: '65%' }}>
                                    <span>
                                        {findLocaleName(row.names, this.language())}
                                    </span>
                                </td>
                                <td><span className="float-right"><Date date={moment(row.day)} /></span></td>
                            </tr>
                        );
                    });
                return (
                    <table
                        className="table table-sm w-100 m-0 p-0"
                        style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}
                    >
                        {this.thread()}
                        <tbody>
                            {trs}
                            {
                                (rows.arr.length > Table.ITEMS && !this.props.showAll) ?
                                    (
                                        <tr>
                                            <td colSpan={2}>
                                                <div className="float-right">
                                                    <Anchor
                                                        className="text-muted"
                                                        onClick={() => {
                                                            myNextHoes(
                                                                this.getModal(),
                                                                { rows: rows }
                                                            );
                                                        }}
                                                    >
                                                        {t('more')}
                                                    </Anchor>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                    :
                                    undefined
                            }
                        </tbody>
                    </table>
                );
            }
            default:
                return (<Error />);
        }
    }

    thread() {
        const t = this.translate();
        return (
            <thead>
                <tr>
                    <th className="border-top-0" colSpan={2}>
                        {t('my.next.public.holidays.and.events')}
                    </th>
                </tr>
            </thead>
        );
    }
}