import * as $ from 'jquery';
import * as React from 'react';
import { doFetchUnitsFromServer, doFetchUsersFromServer } from '../../Actions/Caches';
import { trialStarted } from '../../Actions/Modal';
import { doLoadSession } from '../../Actions/Session';
import Component from '../../Component';
import Back from '../../Components/Back';
import CheckBox from '../../Components/CheckBox';
import DatePickerField from '../../Components/DatePickerField';
import InputField from '../../Components/InputField';
import Line from '../../Components/Line';
import Submit from '../../Components/Submit';
import ToggleField from '../../Components/ToggleField';
import { COMPANYUSERROLES } from '../../General';
import { formatForServer } from '../../Utils';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = this.buidInitialState();
    }

    buidInitialState() {
        const moment = this.getMomentWithLocale();
        return {
            email: {
                touched: false,
                rules: this.Rules().BasicEmailRules()
            },
            firstName: {
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            lastName: {
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            employmentStartDate: {
                value: moment(),
                touched: false,
                timeFormat: false
            },
            sendEmail: {
                value: true,
            },
            continueToInvite: {
                value: true,
            },
            invite: {
                pleaseWait: false
            },
        };
    }

    render() {
        const t = this.translate();
        const dateFormat = this.getDateFormat();
        return <div className="container-fluid">
            <div className="row">
                <div className="col-12 mb-3">
                    <Back href={this.Urls().getEmployees()} />
                    <h5 className="mb-0 d-inline">{t('invite.employees')}</h5>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <Line />
                </div>
            </div>
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <form onSubmit={(event: any) => { this.submit(event); }}>
                        <fieldset>
                            <div className="row">
                                <div className="col-12 mb-3 mt-3">
                                    <InputField
                                        touched={this.state.email.touched}
                                        rules={this.state.email.rules}
                                        type="text"
                                        placeholder={t('email')}
                                        focus={this.isScreenSizeSmall() ? false : true}
                                        onChange={(value: any) => { this.setState({ email: Object.assign({}, this.state.email, { value }) }); }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <InputField
                                        touched={this.state.firstName.touched}
                                        rules={this.state.firstName.rules}
                                        type="text"
                                        placeholder={t('first.name')}
                                        onChange={(value: any) => {
                                            this.setState({ firstName: Object.assign({}, this.state.firstName, { value }) });
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <InputField
                                        touched={this.state.lastName.touched}
                                        rules={this.state.lastName.rules}
                                        type="text"
                                        placeholder={t('last.name')}
                                        onChange={(value: any) => { this.setState({ lastName: Object.assign({}, this.state.lastName, { value: value }) }); }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <DatePickerField
                                        value={this.state.employmentStartDate.value}
                                        placeholder={t('employment.date')}
                                        timeFormat={this.state.employmentStartDate.timeFormat}
                                        dateFormat={dateFormat}
                                        onChange={(value: any) => { this.setState({ employmentStartDate: Object.assign({}, this.state.employmentStartDate, { value, isValid: true }) }); }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <ToggleField
                                        defaultValue={this.state.sendEmail.value}
                                        onChange={(value: any) => { this.setState({ sendEmail: Object.assign({}, this.state.sendEmail, { value: value }) }); }}
                                    >
                                        {t('send.invitation.email')}
                                    </ToggleField>
                                </div>
                                <div className="col-12 mb-3">
                                    <Submit
                                        faIcon="fas fa-user-plus"
                                        disabled={!this.isFormValid()}
                                        pleaseWait={this.state.invite.pleaseWait}
                                        onClick={(event: any) => { this.submit(event); }}
                                    >
                                        {t('invite')}
                                    </Submit>
                                </div>
                                <div className="col-12">
                                    <CheckBox
                                        defaultValue={this.state.continueToInvite.value}
                                        // tslint:disable-next-line:max-line-length
                                        onChange={(value: any) => { this.setState({ continueToInvite: Object.assign({}, this.state.continueToInvite, { value: value }) }); }}
                                    >
                                        {t('continue.to.invite')}
                                    </CheckBox>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    }

    isFormValid() {
        return this.Validator().validate(this.state.email) &&
            this.Validator().validate(this.state.firstName) &&
            this.Validator().validate(this.state.lastName);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                email: Object.assign({}, this.state.email, { touched: true }),
                firstName: Object.assign({}, this.state.firstName, { touched: true }),
                lastName: Object.assign({}, this.state.lastName, { touched: true }),
            },
            () => {
                if (this.isFormValid()) {
                    this.addEmployee();
                }
            }
        );
    }

    addEmployee() {
        const moment = this.getMomentWithLocale();
        const t = this.translate();
        const companyUser = Object.assign({}, this.props.creator);
        companyUser.office = undefined;
        companyUser.id = undefined;
        companyUser.email = this.state.email.value;
        companyUser.firstName = this.state.firstName.value;
        companyUser.lastName = this.state.lastName.value;
        companyUser.role = COMPANYUSERROLES.COMPANY_USER;
        companyUser.employmentStartDate = this.state.employmentStartDate.value;
        companyUser.position = '';
        try {
            companyUser.employmentStartDate = formatForServer(this.state.employmentStartDate.value);
        } catch (e) {
            companyUser.employmentStartDate = formatForServer(moment());
        }
        $.ajax({
            type: 'PUT',
            contentType: 'application/json',
            url: this.Endpoints().getUserInvite() + '?sendEmail=' + this.state.sendEmail.value,
            dataType: 'json',
            cache: false,
            data: JSON.stringify(companyUser),
            beforeSend: () => {
                this.setState({ invite: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('the.employee.was.invited'));
                this.checkSubscription();
                doFetchUnitsFromServer(this.getCaches());
                doFetchUsersFromServer(this.getCaches(), this.getSession().getState());
                if (this.state.continueToInvite.value) {
                    this.setState(this.buidInitialState());
                } else {
                    this.goToEmployee(data);
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                if (+jqXHR.status === 400) {
                    var reason = jqXHR.getResponseHeader('REASON');
                    if (reason && reason === 'DUPLICATE') {
                        this.successToastr(t('the.employee.is.already.invited'));
                        return;
                    }
                }
                this.generalErrorToastr();
            },
            complete: (jqXHR, textStatus) => {
                this.setState({ invite: { pleaseWait: false } });
            }
        });
    }

    checkSubscription() {
        $.ajax({
            type: 'GET',
            contentType: 'application/json',
            url: this.Endpoints().getCurrentSubscriptionCheck(),
            dataType: 'json',
            cache: false,
            success: (data, textStatus, jqXHR) => {
                if (data && data.trialEndsOn) {
                    doLoadSession(this.getSession());
                    trialStarted(this.getModal(), {
                        trialEndsOn: data.trialEndsOn
                    });
                }
            }
        });
    }
}
