import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import A from '../../../Components/A';
import Confirm from '../../../Components/Confirm';
import InputICal from '../../../Components/InputICal';
import GoogleButton from '../../../Components/Integrations/GoogleButton';
import OfficeButton from '../../../Components/Integrations/OfficeButton';
import SlackButton from '../../../Components/Integrations/SlackButton';
import Line from '../../../Components/Line';
import Submit from '../../../Components/Submit';
import ToggleField from '../../../Components/ToggleField';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            googleActive: {
                value: this.props.integrations.value.googleActive,
            },
            googleEnabled: {
                value: this.props.integrations.value.googleEnabled,
            },
            googleCalendar: {
                value: this.props.integrations.value.googleCalendar,
            },
            googleTasks: {
                value: this.props.integrations.value.googleTasks,
            },
            office365Active: {
                value: this.props.integrations.value.office365Active,
            },
            office365Enabled: {
                value: this.props.integrations.value.office365Enabled,
            },
            office365Calendar: {
                value: this.props.integrations.value.office365Calendar,
            },
            slackActive: {
                value: this.props.integrations.value.slackActive,
            },
            slackEnabled: {
                value: this.props.integrations.value.slackEnabled,
            },
            iCalendarUrl: {
                value: this.props.integrations.value.iCalendarUrl,
            },
            iCalendarUrlForUnit: {
                value: this.props.integrations.value.iCalendarUrlForUnit,
            },
            iCalendarUrlForOffice: {
                value: this.props.integrations.value.iCalendarUrlForOffice,
            },
            iCalendarUrlForCompanyBirthdays: {
                value: this.props.integrations.value.iCalendarUrlForCompanyBirthdays,
            },
            iCalendarUrlForCompanyWorkAnniversaries: {
                value: this.props.integrations.value.iCalendarUrlForCompanyWorkAnniversaries,
            },
            iCalendarUrlForCompany: {
                value: this.props.integrations.value.iCalendarUrlForCompany,
            },
            iCalendarUrlv2: {
                value: this.props.integrations.value.iCalendarUrlv2,
            },
            iCalendarUrlForUnitv2: {
                value: this.props.integrations.value.iCalendarUrlForUnitv2,
            },
            iCalendarUrlForOfficev2: {
                value: this.props.integrations.value.iCalendarUrlForOfficev2,
            },
            iCalendarUrlForCompanyv2: {
                value: this.props.integrations.value.iCalendarUrlForCompanyv2,
            },
            save: {
                pleaseWait: false
            },
            resynchronizeOffice365: {
                pleaseWait: false
            },
            copyICalendarUrl: false,
            copyICalendarUrlForUnit: false,
            copyICalendarUrlForCompany: false,
            copyICalendarUrlForCompanyBirthdays: false,
            copyICalendarUrlForCompanyWorkAnniversaries: false,
            copyICalendarUrlv2: false,
            copyICalendarUrlForUnitv2: false,
            copyICalendarUrlForCompanyv2: false
        };
        this.resynchronizeOffice365 = this.resynchronizeOffice365.bind(this);
    }

    resynchronizeOffice365(event: any) {
        event.preventDefault();
        const t = this.translate();
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getMeOffice365Sync(),
            dataType: 'json',
            cache: false,
            beforeSend: () => {
                this.setState({ resynchronizeOffice365: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('office365.calendar.resynchronize.started'));
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.generalErrorToastr();
            },
            complete: () => {
                this.setState({ resynchronizeOffice365: { pleaseWait: false } });
            }
        });
    }

    render() {
        const t = this.translate();
        const session = this.getSession().getState() as any;
        return (<div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <form onSubmit={(event: any) => { this.submit(event); }}>
                    <fieldset>
                        <div className="row">
                            <div className="col-12" />
                            {(!this.state.googleActive.value) ?
                                <div>
                                    <div className="col-12 mb-3">
                                        <h6>
                                            {t('the.integration.with.google.is.not.active')}
                                        </h6>
                                        <div className="text-muted font-weight-light">
                                            <small>{t('to.activate.the.integration.with.google')}</small>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <GoogleButton />
                                    </div>
                                </div>
                                :
                                <div className="w-100">
                                    <div className="col-12 mb-3">
                                        <ToggleField
                                            defaultValue={this.state.googleEnabled.value}
                                            onChange={(value: any) => { this.setState({ googleEnabled: Object.assign({}, this.state.googleEnabled, { value }) }); }}
                                        >
                                            {t('google.enabled')}
                                        </ToggleField>
                                    </div>
                                    <div className="col-12 mb-3 d-none">
                                        <ToggleField
                                            defaultValue={this.state.googleCalendar.value}
                                            onChange={(value: any) => { this.setState({ googleCalendar: Object.assign({}, this.state.googleCalendar, { value }) }); }}
                                        >
                                            {t('google.calendar')}
                                        </ToggleField>
                                    </div>
                                    <div className="col-12 mb-3 d-none">
                                        <ToggleField
                                            defaultValue={this.state.googleTasks.value}
                                            onChange={(value: any) => { this.setState({ googleTasks: Object.assign({}, this.state.googleTasks, { value }) }); }}
                                        >
                                            {t('google.tasks')}
                                        </ToggleField>
                                    </div>
                                </div>
                            }
                            <div className="col-12 mb-3"><Line /></div>
                            {(!this.state.office365Active.value) ?
                                <div>
                                    <div className="col-12 mb-3">
                                        <h6>
                                            {t('the.integration.with.office365.is.not.active')}
                                        </h6>
                                        <div className="text-muted font-weight-light">
                                            <small>{t('to.activate.the.integration.with.office365')}</small>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <OfficeButton />
                                    </div>
                                </div>
                                :
                                <div className="w-100">
                                    <div className="col-12 mb-3">
                                        <ToggleField
                                            defaultValue={this.state.office365Enabled.value}
                                            onChange={(value: any) => { this.setState({ office365Enabled: Object.assign({}, this.state.office365Enabled, { value }) }); }}
                                        >
                                            {t('office365.enabled')}
                                        </ToggleField>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <ToggleField
                                            defaultValue={this.state.office365Calendar.value}
                                            onChange={(value: any) => { this.setState({ office365Calendar: Object.assign({}, this.state.office365Calendar, { value }) }); }}
                                        >
                                            {t('office365.calendar')}
                                        </ToggleField>
                                    </div>
                                    {this.state.office365Calendar.value ?
                                        <div className="col-12 mb-3">
                                            <Submit color="secondary" className="btn btn-outline-secondary btn-block" faIcon="fas fa-sync" onClick={this.resynchronizeOffice365}
                                                disabled={this.state.resynchronizeOffice365.blocked} blocked={this.state.resynchronizeOffice365.pleaseWait}>
                                                {t('resynchronize.calendar')}
                                            </Submit>
                                        </div>
                                        : null
                                    }
                                </div>
                            }
                            <div className="col-12 mb-3"><Line /></div>
                            {(!this.state.slackActive.value) ?
                                <div>
                                    <div className="col-12 mb-3">
                                        <h6>{t('the.integration.with.slack.is.not.active')}</h6>
                                        <div className="text-muted font-weight-light">
                                            <small>{t('to.activate.the.integration.with.slack')}</small>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <SlackButton />
                                    </div>
                                </div>
                                :
                                <div className="col-12 mb-3">
                                    <ToggleField
                                        defaultValue={this.state.slackEnabled.value}
                                        onChange={(value: any) => { this.setState({ slackEnabled: Object.assign({}, this.state.slackEnabled, { value }) }); }}
                                    >
                                        {t('slack.enabled')}
                                    </ToggleField>
                                </div>
                            }
                            <div className="col-12 mb-3">
                                <Line />
                            </div>
                            <div className="col-12 mb-3">
                                <div>
                                    <span className="float-right">
                                        <Confirm
                                            title={t('regenerate.icalendar.url')}
                                            faIcon="fas fa-sync fa-fw"
                                            onConfirm={() => {
                                                $.ajax({
                                                    type: 'POST',
                                                    contentType: 'application/json',
                                                    url: this.Endpoints().getCompanyUseriCalendarRegenerate(),
                                                    dataType: 'json',
                                                    cache: false,
                                                    success: (data: any, textStatus: any, jqXHR: any) => {
                                                        this.setState({ iCalendarUrl: { value: data.iCalendarUrl } });
                                                        this.successToastr(t('the.icalendar.url.was.regenerated'));
                                                    },
                                                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                                                        this.ajaxError(jqXHR, textStatus, errorThrown);
                                                    }
                                                });
                                            }}
                                        />
                                    </span>
                                    <h6>{t('my.iCalendar.url')}</h6>
                                </div>
                                <div>
                                    <span className="float-right">
                                        <A
                                            title={t('copy.icalendar.url')}
                                            faIcon="far fa-copy"
                                            href={this.state.iCalendarUrl.value}
                                            onClick={() => { this.setState({ copyICalendarUrl: true }, () => { this.setState({ copyICalendarUrl: false }); }); }}
                                        />
                                    </span>
                                    V1 : <a href={this.state.iCalendarUrl.value} className="card-link" target="_blank" title={t('my.iCalendar.url')} rel="noopener noreferrer">
                                        {this.state.iCalendarUrl.value}
                                    </a>
                                    <InputICal value={this.state.iCalendarUrl.value} copy={this.state.copyICalendarUrl} />
                                </div>
                                <div>
                                    <span className="float-right">
                                        <A
                                            title={t('copy.icalendar.url')}
                                            faIcon="far fa-copy"
                                            href={this.state.iCalendarUrlv2.value}
                                            onClick={() => { this.setState({ copyICalendarUrlv2: true }, () => { this.setState({ copyICalendarUrlv2: false }); }); }}
                                        />
                                    </span>
                                    V2 : <a href={this.state.iCalendarUrlv2.value} className="card-link" target="_blank" title={t('my.iCalendar.url') + ' V2'} rel="noopener noreferrer">
                                        {this.state.iCalendarUrlv2.value}
                                    </a>
                                    <InputICal value={this.state.iCalendarUrlv2.value} copy={this.state.copyICalendarUrlv2} />
                                </div>
                            </div>
                            {(this.state.iCalendarUrlForUnit.value) && !session.permissions.anonymize ?
                                <div className="col-12 mb-3">
                                    <div>
                                        <h6>{t('my.team.iCalendar.url')}</h6>
                                    </div>
                                    <div>
                                        <span className="float-right">
                                            <A
                                                title={t('copy.my.team.iCalendar.url')}
                                                faIcon="far fa-copy"
                                                href={this.state.iCalendarUrlForUnit.value}
                                                onClick={() => { this.setState({ copyICalendarUrlForUnit: true }, () => { this.setState({ copyICalendarUrlForUnit: false }); }); }}
                                            />
                                        </span>
                                        V1 :  <a href={this.state.iCalendarUrlForUnit.value} className="card-link" target="_blank" title={t('my.team.iCalendar.url')} rel="noopener noreferrer">
                                            {this.state.iCalendarUrlForUnit.value}
                                        </a>
                                        <InputICal value={this.state.iCalendarUrlForUnit.value} copy={this.state.copyICalendarUrlForUnit} />
                                    </div>
                                    <div>
                                        <span className="float-right">
                                            <A
                                                title={t('copy.my.team.iCalendar.url') + ' V2'}
                                                faIcon="far fa-copy"
                                                href={this.state.iCalendarUrlForUnitv2.value}
                                                onClick={() => { this.setState({ copyICalendarUrlForUnitv2: true }, () => { this.setState({ copyICalendarUrlForUnitv2: false }); }); }}
                                            />
                                        </span>
                                        V2 : <a href={this.state.iCalendarUrlForUnitv2.value} className="card-link" target="_blank" title={t('my.team.iCalendar.url') + ' V2'} rel="noopener noreferrer">
                                            {this.state.iCalendarUrlForUnitv2.value}
                                        </a>
                                        <InputICal value={this.state.iCalendarUrlForUnitv2.value} copy={this.state.copyICalendarUrlForUnitv2} />
                                    </div>
                                </div>
                                : null}
                            {(this.state.iCalendarUrlForOffice.value) ?
                                <div className="col-12 mb-3">
                                    <div>
                                        <h6>{t('my.office.iCalendar.url')}</h6>
                                    </div>
                                    <div>
                                        <span className="float-right">
                                            <A
                                                title={t('copy.my.office.iCalendar.url')}
                                                faIcon="far fa-copy"
                                                href={this.state.iCalendarUrlForOffice.value}
                                                onClick={() => { this.setState({ copyICalendarUrlForOffice: true }, () => { this.setState({ copyICalendarUrlForOffice: false }); }); }}
                                            />
                                        </span>
                                        V1 :  <a href={this.state.iCalendarUrlForOffice.value} className="card-link" target="_blank" title={t('my.Office.iCalendar.url')} rel="noopener noreferrer">
                                            {this.state.iCalendarUrlForOffice.value}
                                        </a>
                                        <InputICal value={this.state.iCalendarUrlForOffice.value} copy={this.state.copyICalendarUrlForOffice} />
                                    </div>
                                    <div>
                                        <span className="float-right">
                                            <A
                                                title={t('copy.my.office.iCalendar.url') + ' V2'}
                                                faIcon="far fa-copy"
                                                href={this.state.iCalendarUrlForOfficev2.value}
                                                onClick={() => { this.setState({ copyICalendarUrlForOfficev2: true }, () => { this.setState({ copyICalendarUrlForOfficev2: false }); }); }}
                                            />
                                        </span>
                                        V2 : <a href={this.state.iCalendarUrlForOfficev2.value} className="card-link" target="_blank" title={t('my.office.iCalendar.url') + ' V2'} rel="noopener noreferrer">
                                            {this.state.iCalendarUrlForOfficev2.value}
                                        </a>
                                        <InputICal value={this.state.iCalendarUrlForOfficev2.value} copy={this.state.copyICalendarUrlForOfficev2} />
                                    </div>
                                </div>
                                : null}
                            {
                                (this.state.iCalendarUrlForCompany.value) ?
                                    <div className="col-12 mb-3">
                                        <div>
                                            <span className="float-right">
                                                <Confirm
                                                    title={t('regenerate.icalendar.url')}
                                                    faIcon="fas fa-sync fa-fw"
                                                    onConfirm={() => {
                                                        $.ajax({
                                                            type: 'POST',
                                                            contentType: 'application/json',
                                                            url: this.Endpoints().getCompanyiCalendarRegenerate(),
                                                            dataType: 'json',
                                                            cache: false,
                                                            success: (data: any, textStatus: any, jqXHR: any) => {
                                                                this.setState({ iCalendarUrlForCompany: { value: data.iCalendarUrlForCompany } });
                                                                this.successToastr(t('the.icalendar.url.was.regenerated'));
                                                            },
                                                            error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                                                                this.ajaxError(jqXHR, textStatus, errorThrown);
                                                            }
                                                        });
                                                    }}
                                                />
                                            </span>
                                            <h6>{t('my.company.iCalendar.url')}</h6>
                                        </div>
                                        <div>
                                            <span className="float-right">
                                                <A
                                                    title={t('copy.my.company.iCalendar.url')}
                                                    faIcon="far fa-copy"
                                                    href={this.state.iCalendarUrlForCompany.value}
                                                    onClick={() => { this.setState({ copyICalendarUrlForCompany: true }, () => { this.setState({ copyICalendarUrlForCompany: false }); }); }}
                                                />
                                            </span>
                                            V1 : <a href={this.state.iCalendarUrlForCompany.value} className="card-link" target="_blank" title={t('my.team.iCalendar.url')} rel="noopener noreferrer" >
                                                {this.state.iCalendarUrlForCompany.value}
                                            </a>
                                            <InputICal value={this.state.iCalendarUrlForCompany.value} copy={this.state.copyICalendarUrlForCompany} />
                                        </div>
                                        <div>
                                            <span className="float-right">
                                                <A
                                                    title={t('copy.my.company.iCalendar.url')}
                                                    faIcon="far fa-copy"
                                                    href={this.state.iCalendarUrlForCompanyv2.value}
                                                    onClick={() => { this.setState({ copyICalendarUrlForCompanyv2: true }, () => { this.setState({ copyICalendarUrlForCompanyv2: false }); }); }}
                                                />
                                            </span>
                                            V2 : <a href={this.state.iCalendarUrlForCompanyv2.value} className="card-link" target="_blank" title={t('my.company.iCalendar.url') + ' V2'} rel="noopener noreferrer" >
                                                {this.state.iCalendarUrlForCompanyv2.value}
                                            </a>
                                            <InputICal value={this.state.iCalendarUrlForCompanyv2.value} copy={this.state.copyICalendarUrlForCompanyv2} />
                                        </div>
                                        <div className="mt-3">
                                            <span className="float-right">
                                                <A
                                                    title={t('copy.my.company.birthdays.iCalendar.url')}
                                                    faIcon="far fa-copy"
                                                    href={this.state.iCalendarUrlForCompanyBirthdays.value}
                                                    onClick={() => { this.setState({ copyICalendarUrlForCompanyBirthdays: true }, () => { this.setState({ copyICalendarUrlForCompanyBirthdays: false }); }); }}
                                                />
                                            </span>
                                            <div><h6>{t('my.company.birthdays.iCalendar.url')}</h6></div><a href={this.state.iCalendarUrlForCompanyBirthdays.value} className="card-link" target="_blank" title={t('my.company.birthdays.iCalendar.url')} rel="noopener noreferrer" >
                                                {this.state.iCalendarUrlForCompanyBirthdays.value}
                                            </a>
                                            <InputICal value={this.state.iCalendarUrlForCompanyBirthdays.value} copy={this.state.copyICalendarUrlForCompanyBirthdays} />
                                        </div>
                                        <div className="mt-3">
                                            <span className="float-right">
                                                <A
                                                    title={t('copy.my.company.work.anniversaries.iCalendar.url')}
                                                    faIcon="far fa-copy"
                                                    href={this.state.iCalendarUrlForCompanyWorkAnniversaries.value}
                                                    onClick={() => { this.setState({ copyICalendarUrlForCompanyWorkAnniversaries: true }, () => { this.setState({ copyICalendarUrlForCompanyWorkAnniversaries: false }); }); }}
                                                />
                                            </span>
                                            <div><h6>{t('my.company.work.anniversaries.iCalendar.url')}</h6></div> <a href={this.state.iCalendarUrlForCompanyWorkAnniversaries.value} className="card-link" target="_blank" title={t('my.company.work.anniversaries.iCalendar.url')} rel="noopener noreferrer" >
                                                {this.state.iCalendarUrlForCompanyWorkAnniversaries.value}
                                            </a>
                                            <InputICal value={this.state.iCalendarUrlForCompanyWorkAnniversaries.value} copy={this.state.copyICalendarUrlForCompanyWorkAnniversaries} />
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <div className="col-12">
                                <Submit faIcon="far fa-hdd" disabled={this.state.save.blocked} blocked={this.state.save.pleaseWait}>
                                    {t('save')}
                                </Submit>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
        );
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            { name: Object.assign({}, this.state.name, { touched: true }), },
            () => { if (this.Validator().validate(this.state.name)) { this.saveLeaveType(); } }
        );
    }

    saveLeaveType() {
        const t = this.translate();
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getMyAccountIntegrations(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                googleEnabled: this.state.googleEnabled.value,
                googleCalendar: this.state.googleCalendar.value,
                googleTasks: this.state.googleTasks.value,
                slackEnabled: this.state.slackEnabled.value,
                office365Enabled: this.state.office365Enabled.value,
                office365Calendar: this.state.office365Calendar.value,
                office365Tasks: false
            }),
            beforeSend: () => {
                this.setState({ save: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('the.integrations.settings.were.saved'));
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.generalErrorToastr();
            },
            complete: () => {
                this.setState({ save: { pleaseWait: false } });
            }
        });
    }
}