import * as React from 'react';
import Urls from '../../Urls';
import Component from '../../Component';

export default class SlackButton extends Component {
    render() {
        return <a href={Urls.getSlackClientAuthUrl()}
            className={'btn btn-outline-warning btn-block' + (this.isScreenSizeSmall() ? ' btn-sm' : '')}
        ><i className="fab fa-slack-hash fa-fw pr-2" aria-hidden="true" />
            Slack
        </a>
    }
}