import * as React from 'react';
import Component from '../Component';
import { fullNameFactory } from '../Factories';
import Anchor from './Anchor';

export default class Employee extends Component {
    render() {
        const session = this.getSession().getState() as any;
        const name = fullNameFactory(this.props.employee, session.company.nameFormat);
        return <div className="text-truncate" style={{ maxWidth: '150px' }}>
            <Anchor title={name} href="##" className="text-dark">
                {name}
            </Anchor>
        </div>
    }
}