import * as React from 'react';
import Component from '../../../Component';
import LeaveCircle from '../../../Components/Leave/LeaveCircle';
import { findLocaleName } from '../../../Utils';

export default class THead extends Component {

    render() {
        const ths = this.props.days.arr.map((day: any) => {
            const style: any = {};
            if (day.endOfMonth) {
                style.borderRightColor = '#dee2e6';
                style.borderRightWidth = '3px';
            }
            return <th
                key={'CalendarTHead-' + day.ISO8601}
                className={'text-center border-bottom-0' + (day.today ? '' : ' font-weight-normal')}
                style={style}
            >
                <div>{day.dayOfWeek}</div>
                {day.text}
            </th>
        });
        return <thead>
            <tr>
                <th className="border-0 shadow"
                    style={{ borderRight: '1px solid #DEE2E6', maxWidth: '150px', top: 0, left: 0, position: 'sticky', zIndex: 1, background: '#FFFFFF' }}>
                    {this.props.days.month} {' '}
                    {this.props.days.year}
                </th>
                {ths}
                {this.props.leaveTypes.arr.map((leaveType: any) => {
                    return <th
                        className="text-center border-bottom-0"
                        key={'ReportsGeneralTableTHeadTh-' + leaveType.id}
                    >
                        <div>{this.generateAcronym(this.getName(leaveType))}</div>
                        <LeaveCircle leave={{ leaveType: leaveType }} />
                    </th>
                })}
            </tr>
        </thead>
    }


    public getName(leaveType: any) {
        return findLocaleName(leaveType.names, this.language());
    }

    public generateAcronym(name: any) {
        let acronym = '';
        let words = name.split(' ');
        for (let word of words) {
            if (word != null) {
                acronym = acronym + word.substring(0, 1);
            }
        }
        return acronym.toUpperCase();
    }
}