import * as React from 'react';
import Component from '../../../../Component';
import Item from './Item';

export default class Table extends Component {

    render() {
        const t = this.translate();
        return (
            <table className="table table-sm w-100 mb-0">
                <thead>
                    <tr>
                        <th className="border-top-0">
                            {t('approver.for')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.getTrs()}
                </tbody>
            </table>
        );
    }

    getTrs() {
        // tslint:disable-next-line:max-line-length
        return this.props.employees.map((employee: any) => { return (<Item key={employee.id} employee={employee} />); });
    }
}