import * as React from 'react';
import Component from '../../../Component';
import Display from './Display';
import ChangePassword from './ChangePassword';
import Form from './Form';
import { STATUS } from '../../../General';
import * as $ from 'jquery';

export default class Settings extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            employee: {
                value: undefined,
                status: STATUS.LOADING
            },
            editMode: false,
            changePassword: false
        };
    }

    componentDidMount() {
        this.getSettings();
    }

    getSettings() {
        this.setState(
            { employee: { value: undefined, status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getUserSettings(),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        data.office = data.calendar;
                        this.setState(
                            {
                                employee: {
                                    value: data,
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                employees: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.units.status === STATUS.READY &&
            caches.users.status === STATUS.READY &&
            caches.offices.status === STATUS.READY &&
            caches.countries.status === STATUS.READY &&
            caches.timezones.status === STATUS.READY &&
            this.state.employee.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (
            (this.state.editMode) ?
                (<Form employee={this.state.employee.value} onEdit={() => { this.getSettings(); this.setState({ editMode: !this.state.editMode }); }} />)
                :
                (
                    (this.state.changePassword) ?
                        (
                            <ChangePassword onPasswordChanged={() => { this.setState({ changePassword: !this.state.changePassword }); }} />
                        )
                        :
                        (
                            <Display
                                employee={this.state.employee.value}
                                onEdit={() => { this.setState({ editMode: !this.state.editMode }); }}
                                onChangePassword={() => { this.setState({ changePassword: !this.state.changePassword }); }}
                            />
                        )
                )
        );
    }
}