import * as React from 'react';
import ResponsiveModal from '../ResponsiveModal';
import Form from './Form';
import { none } from '../../../Actions/Modal';

export default class CancelLeave extends ResponsiveModal {
    getContent() {
        const leave = this.props.leave;
        return <Form
            leave={leave}
            onClose={() => {
                this.toggle();
                none(this.getModal(), {});
                if (this.props.callback) { this.props.callback(); }
            }}
        />
    }

    getTitle(): any {
        const t = this.translate();
        return (
            <h5 className="m-0 p-0">{t('cancel.leave.request')}</h5>
        );
    }
}