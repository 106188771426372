import * as $ from 'jquery';
import { doLoadSession } from '../../../Actions/Session';
import Component from '../../../Component';
import Close from '../../../Components/Close';
import { COMPANYUSERROLES } from '../../../General';

export default class CustomMessage extends Component {
    constructor(props?: any) {
        super(props);
        this.onClose = this.onClose.bind(this);
        this.onEmpty = this.onEmpty.bind(this);
    }

    render() {
        const session: any = this.getSession().getState();
        if (session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN) {
            return <div className="card h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="float-right">
                                <Close onClick={() => { this.onClose(); }} />
                            </div>
                            <div>{session.company.customMessage}</div>
                        </div>
                    </div>
                </div>
            </div>
        }
        return null;
    }

    onEmpty() { if (this.props.onEmpty) { this.props.onEmpty(); } }

    onClose() {
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getHideCustomMessage(),
            dataType: 'json',
            cache: false,
            success: (data: any, textStatus: any, jqXHR: any) => {
                doLoadSession(this.getSession());
                this.onEmpty();
            },
            error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            }
        });
        return false;
    }
}