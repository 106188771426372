import * as React from 'react';
import { adjustWorkingDayAndHours } from '../../Actions/Modal';
import Component from '../../Component';
import Anchor from '../Anchor';

export default class WorkingDaysAndHours extends Component {
    render() {
        const moment = this.getMomentWithLocale();
        let weekDays = moment.weekdays();
        let firstDayOfWeek = +moment.localeData().firstDayOfWeek();
        let weekDaysAndHours = JSON.parse(JSON.stringify(this.props.weekDaysAndHours));
        if (firstDayOfWeek > 0) {
            weekDays = weekDays.slice(firstDayOfWeek, weekDays.length)
                .concat(weekDays.slice(0, firstDayOfWeek));
            weekDaysAndHours = weekDaysAndHours.slice(firstDayOfWeek, weekDays.length)
                .concat(weekDaysAndHours.slice(0, firstDayOfWeek));
        }
        const items = [];
        for (let i = 0; i < 7; i++) {
            const weekDayAndHours = weekDaysAndHours[i];
            const weekDay = weekDays[i];
            items.push(
                <div key={i} className="col-12 col-lg-6 mt-3">
                    <Anchor
                        className={'btn btn-sm btn-block ' +
                            (this.props.readOnly ? 'disabled ' : '') +
                            (weekDayAndHours.working ? 'btn-outline-info ' : 'btn-outline-secondary')}
                        title={weekDay}
                        onClick={() => {
                            if (this.props.readOnly) { return; }
                            adjustWorkingDayAndHours(
                                this.getModal(),
                                {
                                    weekDayAndHours,
                                    weekDay,
                                    employee: this.props.employee,
                                    callback: (value: any) => {
                                        weekDaysAndHours[i] = value.weekDayAndHours;
                                        let x = weekDaysAndHours.sort((a: any, b: any) => {
                                            if (a.day < b.day) {
                                                return -1;
                                            }
                                            if (a.day > b.day) {
                                                return 1;
                                            }
                                            return 0;
                                        });
                                        if (this.props.onChange) { this.props.onChange({ weekDaysAndHours: x }); }
                                    }
                                }
                            );
                        }}
                    >
                        {weekDay}
                        {weekDayAndHours.working ?
                            <div>{this.getHourAndMinute(weekDayAndHours.first.start) + ' - ' + this.getHourAndMinute(weekDayAndHours.second.end)}</div> : null}
                    </Anchor>
                </div>
            );
        }
        return items;
    }

    getHourAndMinute(period: any) {
        const session: any = this.getSession().getState();
        const momentFunc = this.getMomentWithLocale();
        const moment = momentFunc();
        moment.hour(period.hour);
        moment.minute(period.minute);
        return session.companyUser.timeFormat === 'HH:mm' ? moment.format('HH:mm') : moment.format('hh:mm a');
    }
}