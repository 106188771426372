import * as React from 'react';
import Component from '../../../Component';
import Day from './Day';

export default class Month extends Component {

    render() {
        const month = this.props.month;
        return (
            <div className="card h-100 w-100">
                <div className="card-body">
                    <table className="table table-sm table-bordered m-0 p-0 border-0">
                        <thead>
                            <tr>
                                <th colSpan={7} className="text-center border-0">
                                    {month.monthLiteral}
                                </th>
                            </tr>
                            <tr>
                                {
                                    month.daysLiteral.map((day: any) => {
                                        return (
                                            <th
                                                key={month.year + '-' + month.month + '-' + day}
                                                className="text-center border-0"
                                            >
                                                {day}
                                            </th>
                                        );
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {month.weeks.map((week: any) => {
                                return (
                                    <tr key={month.year + '-' + month.month + '-' + week.weekOfMonth}>
                                        {week.days.map((item: any) => {
                                            return (
                                                <td
                                                    key={item.date}
                                                    style={!item.deleted && item.dayEvent ? item.dayEvent.style : null}
                                                    className="text-center align-middle p-0 border-0"
                                                >
                                                    {
                                                        item.deleted ?
                                                            <small className="text-muted font-weight-lighter">{item.value}</small>
                                                            :
                                                            (<Day className="card-link btn btn-sm" day={item} />)
                                                    }
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}