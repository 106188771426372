import * as React from 'react';
import Component from '../../../Component';
import Cog from '../../../Components/Cog';
import Date from '../../../Components/Date';
import WorkingDaysAndHours from '../../../Components/WorkingDaysAndHours/WorkingDaysAndHours';
import { COMPANYUSERROLES, USERSTATUS } from '../../../General';
import ApproverFor from './ApproverFor/ApproverFor';
import ManagerFor from './ManagerFor/ManagerFor';

export default class Display extends Component {

    render() {
        const moment = this.getMomentWithLocale();
        const now = moment();
        const t = this.translate();
        const employee = this.props.employee;
        const caches: any = this.getCaches().getState();
        const usersMap = caches.users.map;
        const unitsMap = caches.units.map;
        const officesMap = caches.offices.map;
        const subsidiariesMap = caches.subsidiaries.map;
        const countriesMap = caches.countries.map[this.language()];
        const approver = usersMap[employee.approverCompanyUser.id];
        const secondApprover = employee.secondApproverCompanyUser && employee.secondApproverCompanyUser.id ? usersMap[employee.secondApproverCompanyUser.id] : undefined;
        const thirdApprover = employee.thirdApproverCompanyUser && employee.thirdApproverCompanyUser.id ? usersMap[employee.thirdApproverCompanyUser.id] : undefined;
        const session: any = this.getSession().getState();
        const isAdmin = () => {
            return session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN;
        };
        return (
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <div className="row">
                        {isAdmin() ? <div className="col-12 mb-3">
                            <Cog onClick={() => { if (this.props.onEdit) { this.props.onEdit(); } }} />
                        </div> : null}
                        <div className="col-12">
                            <table className="table table-sm w-100 mb-0">
                                <thead>
                                    <tr>
                                        <th className="border-top-0">
                                            {employee.fullName}
                                            {(employee.status !== USERSTATUS.ACTIVE) ? (<span className="float-right">{t('USERSTATUS.' + employee.status)}</span>) : null}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {t('email')}
                                            <span className="float-right">
                                                <a className="card-link" href={'mailto:' + employee.email}>{employee.email}</a>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('Position')}
                                            <span className="float-right">{employee.position}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('approver')}
                                            <span className="float-right">
                                                {approver.fullName}
                                            </span>
                                        </td>
                                    </tr>
                                    {secondApprover ?
                                        <tr>
                                            <td>
                                                {t('second.approver')}
                                                <span className="float-right">
                                                    {secondApprover.fullName}
                                                </span>
                                            </td>
                                        </tr>
                                        : null}
                                    {thirdApprover ?
                                        <tr>
                                            <td>
                                                {t('third.approver')}
                                                <span className="float-right">
                                                    {thirdApprover.fullName}
                                                </span>
                                            </td>
                                        </tr>
                                        :
                                        null
                                    }
                                    {(employee?.companyUnit?.id) ?
                                        <tr>
                                            <td>
                                                {t('team')}
                                                <span className="float-right">
                                                    {unitsMap[employee?.companyUnit?.id]?.name}
                                                </span>
                                            </td>
                                        </tr> : null}
                                    <></>
                                    {(employee?.office?.id) ?
                                        <tr>
                                            <td>
                                                {t('office')}
                                                <span className="float-right">
                                                    {officesMap[employee?.office?.id]?.name}
                                                </span>
                                            </td>
                                        </tr> : null}
                                    {session.company.subsidiariesEnabled ?
                                        <tr>
                                            <td>
                                                {t('subsidiary')}
                                                <span className="float-right">
                                                    {employee?.subsidiary?.id ?
                                                        subsidiariesMap[employee.subsidiary.id].name : null}
                                                </span>
                                            </td>
                                        </tr> : null}
                                    <tr>
                                        <td>
                                            {t('role')}
                                            <span className="float-right">
                                                {t('COMPANYUSERROLES.' + employee.role)}
                                            </span>
                                        </td>
                                    </tr>
                                    {employee.employmentStartDate ?
                                        <tr>
                                            <td>
                                                {t('employment.date')}
                                                <span className="float-right">
                                                    <Date date={moment(employee.employmentStartDate)} />
                                                </span>
                                            </td>
                                        </tr>
                                        : null}
                                    {employee.employmentEndDate ?
                                        <tr>
                                            <td>
                                                {t('employment.end.date')}
                                                <span className="float-right">
                                                    <Date date={moment(employee.employmentEndDate)} />
                                                </span>
                                            </td>
                                        </tr>
                                        : null}
                                    {employee.employmentStartDate && moment.duration(now.startOf('day').diff(moment(employee.employmentStartDate).startOf('day'))) > 0 ?
                                        <tr>
                                            <td>
                                                {t('service.length')}
                                                <span className="float-right">
                                                    {this.humanizeDuration(moment.duration(now.startOf('day').diff(moment(employee.employmentStartDate).startOf('day'), true)))}
                                                </span>
                                            </td>
                                        </tr>
                                        : null}
                                    {employee.birthDate ?
                                        <tr>
                                            <td>
                                                {t('birthday')}
                                                <span className="float-right">
                                                    <Date date={moment(employee.birthDate)} />
                                                </span>
                                            </td>
                                        </tr>
                                        : null}
                                    <tr>
                                        <td>
                                            {t('country')}
                                            <span className="float-right">
                                                {countriesMap[employee.country].text}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('timezone')}
                                            <span className="float-right">{employee.timezone}</span>
                                        </td>
                                    </tr>
                                    {employee.phoneNumber && employee.phoneNumber.length > 0 ?
                                        <tr>
                                            <td>
                                                {t('phone.number')}
                                                <span className="float-right">{employee.phoneNumber}</span>
                                            </td>
                                        </tr>
                                        : null}
                                    <tr>
                                        <td>{t('working.days.and.hours')}</td>
                                    </tr>
                                    <tr>
                                        <td className="mr-0 ml-0 pr-0 pl-0 border-0">
                                            <div className="row">
                                                <WorkingDaysAndHours
                                                    readOnly={true}
                                                    weekDaysAndHours={employee.companyUserSettings.weekDaysAndHours}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <ManagerFor employee={employee} />
                        <ApproverFor employee={employee} />
                    </div>
                </div>
            </div>
        );
    }

    public humanizeDuration(momentDuration: any) {
        const t = this.translate();
        let duration: any = {
            years: momentDuration.years(),
            months: momentDuration.months(),
            days: momentDuration.days(),
        }
        if (duration.years === 1) {
            duration.years = t('one.year');
        }
        if (duration.years > 1 || duration.years < 0) {
            duration.years = duration.years + ' ' + t('years');
        }
        if (duration.months === 1) {
            duration.months = t('one.month');
        }
        if (duration.months > 1 || duration.months < 0) {
            duration.months = duration.months + ' ' + t('months');
        }
        if (duration.days === 1) {
            duration.days = t('one.day');
        }
        if (duration.days > 1 || duration.days < 0) {
            duration.days = duration.days + ' ' + t('days');
        }
        duration.years = duration.years ? duration.years + ' ' : '';
        duration.months = duration.months ? duration.months + ' ' : '';
        duration.days = duration.days ? duration.days + ' ' : '';
        return (duration.years + duration.months + duration.days).toLowerCase();
    }
}