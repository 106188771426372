import * as React from 'react';
import ResponsiveModal from '../ResponsiveModal';
import Form from './Form';
import { none } from '../../../Actions/Modal';
import { STATUS } from '../../../General';

export default class AddTeamMembers extends ResponsiveModal {
    getContent() {
        const unit = this.props.unit;
        const caches: any = this.getCaches().getState();
        const session: any = this.getSession().getState();
        let ready = session.status === STATUS.READY && caches.users.status === STATUS.READY;
        return (
            (ready === false) ?
                this.renderLoading()
                :
                (
                    <Form unit={unit} onClose={() => {
                        this.toggle();
                        none(this.getModal(), {});
                        if (this.props.callback) { this.props.callback(); }
                    }}
                    />
                )
        );
    }

    getTitle(): any {
        const t = this.translate();
        const unit = this.props.unit;
        return (
            <h5 className="m-0 p-0">
                {t('add.members.to.team').replace(/{team}/g, unit.name)}
            </h5>
        );
    }
}