import * as React from 'react';
import Component from '../../Component';
import Date from '../../Components/Date';
import EmployeeLink from '../../Components/EmployeeLink';

export default class Item extends Component {

    render() {
        const t = this.translate();
        const employee = this.props.employee;
        return <div className="card w-100 h-100">
            <div className="card-body">
                <table className="table table-sm w-100 m-0 p-0" style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                    <thead>
                        <tr>
                            <th className="border-top-0">
                                <EmployeeLink employee={employee} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {employee.employmentStartDate ? <tr><td key="Employees-Item-Employment-Start-Date">
                            <i className="mr-1 far fa-calendar-alt" aria-hidden="true" />
                            <Date date={employee.employmentStartDate} />
                        </td></tr> : null}
                        {employee.service && employee.service.valueOf() > 0 ? <tr><td key="Employees-Item-Employment-Years-Of-Service">
                            <i className="mr-1 fas fa-hourglass-half" aria-hidden="true" />
                            {employee.service.humanize()}
                        </td></tr> : null}
                        {(employee.next && employee.next.valueOf() > 0) ||
                            (employee.todayIsNewYearOfService && employee.service && employee.service.valueOf() > 0) ?
                            <tr><td key="Employees-Item-Employment-Next-Year-Of-Service">
                                <i className="mr-1 fas fa-stopwatch" aria-hidden="true" />
                                {employee.todayIsNewYearOfService && employee.service && employee.service.valueOf() > 0 ? t('today') :
                                    (employee.next && employee.next.valueOf() > 0 ?
                                        employee.next.humanize(true) : null)}
                            </td></tr> : null}
                    </tbody>
                </table>
            </div>
        </div>
    }
}

