import * as React from 'react';
import Component from '../../../Component';
import { STATUS } from '../../../General';
import Manager from './Manager';

export default class LeaveTypesBalance extends Component {
    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.leaveTypes.status === STATUS.READY && caches.users.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (
            <Manager year={this.currentBusinessYear()} onEmpty={() => { if (this.props.onEmpty) { this.props.onEmpty(); } }} />
        );
    }
}