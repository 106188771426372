import * as React from 'react';
import Component from '../../Component';
import LeaveAmount from './LeaveAmount';
import LeaveDates from './LeaveDates';
import LeaveRequestInThePast from './LeaveRequestInThePast';
import LeaveRules from '../../LeaveRules';

export default class LeaveInterval extends Component {
    render() {
        const leave = this.props.leave;
        return <span>
            <LeaveDates leave={leave} />
            {' '}
            {this.props.leaveRequestInThePast && LeaveRules.isInThePast(leave) ?
                (<LeaveRequestInThePast />) : null}
            <span className="float-right">
                <LeaveAmount leave={leave} />
            </span>
        </span>
    }
}