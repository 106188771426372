import * as React from 'react';
import Component from '../../../../Component';
import Manager from './Manager';
import { STATUS } from '../../../../General';

export default class Invoices extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            display: false
        };
    }

    render(): any {
        const caches: any = this.getCaches().getState();
        let ready = caches.units.status === STATUS.READY && caches.users.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        } else {
            return (
                <div className={'row' + (this.state.display ? '' : ' d-none')} >
                    <div className="col-12 mb-3">
                        <Manager onDisplay={(value: any) => { this.setState({ display: value }); }} />
                    </div>
                </div>
            );
        }
    }
}