import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import { STATUS } from '../../../General';
import Display from './Display';
import Form from './Form';

export default class Permisssions extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            permissions: {
                value: undefined,
                status: STATUS.LOADING
            },
            editMode: false,
        };
    }

    componentDidMount() {
        this.getCompany();
    }

    getCompany() {
        this.setState(
            { permissions: { value: undefined, status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getCompanyPermissions(),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        data.reports = this.zeroPad(data.reports, 2);
                        data.reportsForManagers = +data.reports[1];
                        data.reportsForOfficeManagers = +data.reports[0];
                        this.setState({ permissions: { value: data, status: STATUS.READY } });
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState({ permissions: { arr: [], status: STATUS.ERROR } });
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.units.status === STATUS.READY &&
            caches.users.status === STATUS.READY &&
            caches.offices.status === STATUS.READY &&
            this.state.permissions.status === STATUS.READY;
        if (ready === false) { return this.renderLoading(); }
        if (this.paymentRequired()) { return null; }
        if (this.trialHasEnded()) { return null; }
        return (
            (this.state.editMode) ? <Form
                permissions={this.state.permissions.value}
                onRefresh={() => { this.setState({ editMode: false }, () => { this.getCompany(); }); }}
            />
                : <Display
                    permissions={this.state.permissions.value}
                    onEdit={() => { this.setState({ editMode: !this.state.editMode }); }}
                />
        );
    }

    zeroPad(num: any, places: any) {
        var zero = places - num.toString().length + 1;
        return Array(+(zero > 0 && zero)).join("0") + num;
    }

}