import * as React from 'react';
import Component from '../../Component';
import Anchor from '../../Components/Anchor';
import A from '../../Components/A';
import { USERSTATUS } from '../../General';
import AEmployee from '../../Components/AEmployee';

export default class Item extends Component {

    render() {
        var employee = this.props.employee;
        const caches: any = this.getCaches().getState();
        const countriesMap = caches.countries.map[this.language()];
        const country = (employee?.country?.length > 0) ?
            countriesMap[employee.country].text : undefined;
        const approver = (employee.approverCompanyUser && employee.approverCompanyUser.id > 0) ?
            (this.getCaches().getState() as any).users.map[employee.approverCompanyUser.id] : undefined;
        const companyUnit = (employee.companyUnit && employee.companyUnit.id) ?
            (this.getCaches().getState() as any).units.map[employee.companyUnit.id] : undefined;
        const office = (employee.office && employee.office.id) ?
            (this.getCaches().getState() as any).offices.map[employee.office.id] : undefined;
        const t = this.translate();
        const trs: any = [];
        if (employee.position && employee.position.length > 0) {
            trs.push(
                <tr key="Employees-Item-Position"><td><div>
                    <i className="far fa-address-card fa-fw" aria-hidden="true" /> {' '}
                    {employee.position}
                </div></td></tr>
            );
        }
        if (approver && approver.id > 0) {
            trs.push(
                <tr key="Employees-Item-Approver"><td><div>
                    <A
                        className="text-dark"
                        faIcon="far fa-user-circle"
                        href={this.Urls().getEmployee(approver.id)}
                        title={t('approver') + ': ' + approver.fullName}
                    >
                        {approver.fullName}
                    </A>
                </div></td></tr>
            );
        }
        if (companyUnit && companyUnit.name && companyUnit.name.length > 0) {
            trs.push(
                <tr key="Employees-Item-Team"><td><div>
                    <A
                        className="text-dark"
                        faIcon="fas fa-users"
                        href={this.Urls().getUnit(companyUnit.id)}
                        title={t('team') + ': ' + companyUnit.name}
                    >
                        {companyUnit.name}
                    </A>
                </div></td></tr>
            );
        }
        if (office?.name?.length > 0) {
            trs.push(<tr key="Employees-Item-Office"><td><div>
                <A
                    className="text-dark"
                    faIcon="fa fa-building"
                    href={this.Urls().getOffice(office.id)}
                    title={t('office') + ': ' + office.name}
                >
                    {office.name}
                </A>
            </div></td></tr>);
        }
        const session: any = this.getSession().getState();
        if (session.company.subsidiariesEnabled) {
            const subsidiary = (employee.subsidiary && employee.subsidiary.id) ?
                (this.getCaches().getState() as any).subsidiaries.map[employee.subsidiary.id] : undefined;
            if (subsidiary?.name?.length > 0) {
                trs.push(<tr key="Employees-Item-Subsidiary"><td><div>
                    <A
                        className="text-dark"
                        faIcon="fas fa-city"
                        href={this.Urls().getSubsidiary(subsidiary.id)}
                        title={t('subsidiary') + ': ' + subsidiary.name}
                    >
                        {subsidiary.name}
                    </A>
                </div></td></tr>);
            }
        }
        if (country && country.length > 0) {
            trs.push(<tr key="Employees-Item-Country"><td><div>
                <i className="fa fa-globe fa-fw mr-1" aria-hidden="true" />{country}
            </div></td></tr>);
        }
        if (employee.status === USERSTATUS.INVITED && this.props.onReinvite) {
            trs.push(
                <tr key="Employees-Item-Reinvite"><td><div>
                    <Anchor
                        faIcon="far fa-paper-plane"
                        className="text-success float-right"
                        title={t('reinvite')}
                        onClick={() => {
                            if (this.props.onReinvite) {
                                this.props.onReinvite();
                            }
                        }}
                    >
                        {t('reinvite')}
                    </Anchor>
                </div></td></tr>
            );
        }
        return (
            <div className="card w-100 h-100">
                <div className="card-body">
                    <table
                        className="table table-sm w-100 m-0 p-0"
                        style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}
                    >
                        <thead>
                            <tr>
                                <th className="border-top-0">
                                    <AEmployee employee={employee} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {trs}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}