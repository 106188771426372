import BaseTabs from '../../Components/BaseTabs';
import { REPORTSTABS } from '../../General';

export default class Tabs extends BaseTabs {
    getTabs(): any[] {
        const t = this.translate();
        return [
            {
                faIcon: 'fas fa-chart-area',
                title: t('general.situation'),
                tab: REPORTSTABS.GENERAL
            },
            {
                faIcon: 'fas fa-list-ul',
                title: t('leaves'),
                tab: REPORTSTABS.LEAVES
            },
            {
                faIcon: 'fas fa-chart-bar',
                title: t('annual.situation'),
                tab: REPORTSTABS.ANNUAL
            },
            {
                faIcon: 'fas fa-table',
                title: t('attendance'),
                tab: REPORTSTABS.ATTENDANCE
            },
        ];
    }

    getName(): string {
        return 'Reports';
    }
}