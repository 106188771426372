import * as React from 'react';

export default class Loading extends React.PureComponent {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center text-secondary mt-3 mb-3">
                        <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                    </div>
                </div>
            </div>
        );
    }
}