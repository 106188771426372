import * as $ from 'jquery';
import A from '../../../Components/A';
import Anchor from '../../../Components/Anchor';
import Date from '../../../Components/Date';
import DateRangeField from '../../../Components/DateRangeField';
import SelectField from '../../../Components/SelectField';
import Submit from '../../../Components/Submit';
import TextareaField from '../../../Components/TextareaField';
import OtherLeaves from '../../../Containers/Modals/OtherLeaves/OtherLeaves';
import { COMPANYUSERROLES, REQUESTDAYTYPE, STATUS } from '../../../General';
import { hoursOptionsFactory, leaveTypesOptionsFactory, markApprovalOptionsFactory, requestDayTypeOptionsFactory } from '../../../SelectOptionsFactories';
import AllowanceTable from './Allowance/Table';
import BaseForm from './BaseForm';
import OtherLeavesTable from './OtherLeaves/Table';
import { formatForServer } from '../../../Utils';

export default class Form extends BaseForm {
    constructor(props: any) {
        super(props);
        this.requestConfirmation = this.requestConfirmation.bind(this);
        this.isMarkApprovalEnabled = this.isMarkApprovalEnabled.bind(this);
    }

    requestConfirmation() {
        this.setState({ requestConfirmation: !this.state.requestConfirmation });
    }

    componentDidMount() {
        this.retriveDayEventsLiteMap(); this.onChange();
        this.changeLeaveType(this.state.leaveType.value);
    }

    render() {
        const t = this.translate();
        const caches: any = this.getCaches().getState();
        const leaveTypesOptions = leaveTypesOptionsFactory(caches.leaveTypes.arr, this.language());
        const session = this.getSession().getState() as any;
        const employeeOptions = this.getEmployeesOptions();
        const requestDayTypeOptions = requestDayTypeOptionsFactory(caches.leaveTypes.map[this.state.leaveType.value[0]], t);
        const markApprovalOptions = markApprovalOptionsFactory(t);
        const moment = this.getMomentWithLocale();
        const hoursOptions = hoursOptionsFactory(t);
        return (<div className="card w-100 h-100">
            <div className="card-body w-100">
                <form >
                    <fieldset>
                        <div className="row">
                            <div className="col-12 mt-3 mb-3">
                                <h5 className="d-inline m-0"><strong>{t('book.time.off')}</strong></h5>
                                {session.companyUser.isManager ||
                                    session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN || session.companyUser.role === COMPANYUSERROLES.COMPANY_MANAGER ?
                                    <Anchor
                                        className="ml-2 float-right"
                                        faIcon={this.state.onBehalf.value ? 'fas fa-user' : 'far fa-user'}
                                        title={t('add.on.behalf')}
                                        onClick={() => {
                                            this.setState(
                                                {
                                                    onBehalf: Object.assign({}, this.state.onBehalf, { value: !this.state.onBehalf.value }),
                                                    employee: Object.assign({}, this.state.employee, { value: [(this.getSession().getState() as any).companyUser.id] })
                                                },
                                                () => { this.retriveDayEventsLiteMap(); this.onChange(); });
                                        }}
                                    />
                                    : null}
                            </div>
                            {this.state.onBehalf.value ?
                                <div className="col-12 mb-3">
                                    <SelectField
                                        defaultValue={this.state.employee.value}
                                        touched={this.state.employee.touched}
                                        options={employeeOptions}
                                        multiple={false}
                                        placeholder={t('employee')}
                                        onChange={(value: any) => {
                                            this.setState(
                                                { employee: Object.assign({}, this.state.employee, { value: value }) },
                                                () => { this.retriveDayEventsLiteMap(); this.onChange(); });
                                        }}
                                    />
                                </div>
                                : null}
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.leaveType.value}
                                    touched={this.state.leaveType.touched}
                                    options={leaveTypesOptions}
                                    multiple={false}
                                    placeholder={t('leave.type')}
                                    onChange={(value: any) => { this.changeLeaveType(value); }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.requestDayType.value}
                                    value={this.state.requestDayType.value}
                                    touched={this.state.requestDayType.touched}
                                    message={this.state.requestDayType.message}
                                    options={requestDayTypeOptions}
                                    multiple={false}
                                    placeholder={t('duration')}
                                    {...this.getOnChangeOpts('requestDayType')}
                                />
                            </div>
                            <div className={'col-12 mb-3' + (!this.isRange() ? '' : ' d-none')}>
                                <DateRangeField
                                    placeholder={t('day')}
                                    range={false}
                                    touched={this.state.startDate.touched}
                                    {...this.getDateRangeFieldOpts(false)}
                                />
                                {this.isInThePast()}
                            </div>
                            <div className={'col-12 mb-3' + (this.isHours() ? '' : ' d-none')}>
                                <SelectField
                                    defaultValue={this.state.hours.value}
                                    touched={this.state.hours.touched}
                                    options={hoursOptions}
                                    multiple={false}
                                    placeholder={t('hours')}
                                    {...this.getOnChangeOpts('hours')}
                                />
                            </div>
                            <div className={'col-12 mb-3' + (this.isRange() ? '' : ' d-none')}>
                                <DateRangeField
                                    placeholder={t('from.to')}
                                    range={true}
                                    touched={this.state.endDate.touched}
                                    {...this.getDateRangeFieldOpts(true)}
                                />
                                {this.isInThePast()}
                                {this.getDaysRequestedCount() ? <div><small className="text-lowercase float-right"><strong>
                                    {AllowanceTable.getDays(this.getDaysRequestedCount(), t)}
                                    {' ' + (t('requested'))}
                                </strong></small></div>
                                    : null}
                            </div>
                            <div className="col-12 mb-3">
                                <TextareaField
                                    placeholder={t('comment')}
                                    touched={this.state.comment.touched}
                                    rules={this.state.comment.rules}
                                    rows={1}
                                    {...this.getOnChangeOpts('comment')}
                                />
                            </div>
                            {caches.leaveTypes.map[this.state.leaveType.value[0]].replacement ?
                                <div className="col-12 mb-3">
                                    <TextareaField
                                        placeholder={t('replacement')}
                                        touched={this.state.replacement.touched}
                                        rules={this.state.replacement.rules}
                                        rows={1}
                                        {...this.getOnChangeOpts('replacement')}
                                    />
                                </div>
                                : null}
                            {this.state.onBehalf.value && this.isMarkApprovalEnabled() ?
                                <div className="col-12 mb-3">
                                    <SelectField
                                        defaultValue={this.state.markApproval.value}
                                        touched={this.state.markApproval.touched}
                                        options={markApprovalOptions}
                                        multiple={false}
                                        placeholder={t('approval')}
                                        {...this.getOnChangeOpts('markApproval')}
                                    />
                                </div>
                                : null}
                            {(this.state.leaveTypeAmountsPerYear.status === STATUS.READY &&
                                this.state.leaveTypeAmountsPerYear?.value?.length > 0) ?
                                <div className="col-12 mb-3">
                                    <AllowanceTable leaveTypeAmountsPerYear={this.state.leaveTypeAmountsPerYear} />
                                </div>
                                : null}
                            {!this.isInUsableinterval() ?
                                <div className="col-12 mb-3">
                                    <small className="text-muted">
                                        <i className="fas fa-exclamation-circle" />
                                        {' ' + t('please.note.your.leave.request.must.be.in.the.interval') + ' : '}
                                        <Date date={moment(caches.leaveTypes.map[this.state.leaveType.value[0]].usableFrom)} /> - <Date date={moment(caches.leaveTypes.map[this.state.leaveType.value[0]].usableTo)} />
                                    </small>
                                </div> : null}
                            {session.permissions.anonymize
                                ? null : (this.state.otherLeaves.status === STATUS.READY &&
                                    this.state.otherLeaves.value.length > 0) ?
                                    <div className="col-12 mb-3">
                                        <OtherLeavesTable otherLeaves={this.state.otherLeaves} />
                                    </div>
                                    : null}
                            {(this.state.dayEventsLiteMap.status === STATUS.READY &&
                                !this.isBookableInterval()) ?
                                <div className="col-12 mb-3">
                                    <div className="alert alert-danger text-dark" role="alert">
                                        {t('other.days.booked.in.selected.interval')}
                                    </div>
                                </div>
                                : null}
                            {(this.state.leaveTypeAmountsPerYear.status === STATUS.READY &&
                                this.state?.leaveTypeAmountsPerYear?.value?.length > 0 &&
                                this.isOverBooked()) ?
                                <div className="col-12 mb-3">
                                    <div className="alert alert-danger text-dark" role="alert">
                                        {t('you.will.exceed.your.allowance')}
                                    </div>
                                </div>
                                : null}
                            {!this.state.requestConfirmation ?
                                <div className="col-12">
                                    <Submit
                                        faIcon="far fa-paper-plane"
                                        disabled={!this.isFormValid()}
                                        pleaseWait={this.state.bookTimeOff.pleaseWait}
                                        onClick={this.requestConfirmation}
                                        color="btn btn-info"
                                    >
                                        {t('book.time.off')}
                                    </Submit>
                                </div>
                                : null}
                            {this.state.requestConfirmation ?
                                <>
                                    <div key="pleaseWait" className="col-12 mb-3">
                                        <Submit
                                            faIcon="far fa-paper-plane"
                                            disabled={!this.isFormValid()}
                                            pleaseWait={this.state.bookTimeOff.pleaseWait}
                                            onClick={(event: any) => { this.submit(event); }}>
                                            {t('confirm.booking')}
                                        </Submit>
                                    </div>
                                    <div key="requestConfirmation" className="col-12">
                                        <A href="##"
                                            faIcon="fas fa-ban"
                                            className="float-right text-danger"
                                            onClick={this.requestConfirmation}>
                                            {t('cancel')}
                                        </A>
                                    </div>
                                </>
                                : null}
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
        );
    }

    getOnChangeOpts(field: any) {
        return {
            onChange: (value: any) => {
                const data: any = {};
                data[field] = Object.assign({}, this.state[field], { value: value });
                if ('requestDayType' === field) {
                    if (value[0] === REQUESTDAYTYPE.HOURS) {
                        data['hours'] = {
                            value: [1],
                            touched: false,
                            rules: this.Rules().BasicInputRules()
                        }
                    } else {
                        data['hours'] = {};
                    }
                }
                this.setState(data, () => { this.onChange(); });
            }
        };
    }

    getDateRangeFieldOpts(range: boolean) {
        const ops = {
            startDate: this.state.startDate.value,
            endDate: this.state.endDate.value,
            minDate: this.state.minDate.value,
            maxDate: this.state.maxDate.value,
            dayEventsMap: this.state.dayEventsLiteMap.value,
            onChange: (value: any) => {
                if (range) {
                    this.setState(
                        {
                            startDate: Object.assign({}, this.state.startDate, { value: value.startDate }),
                            endDate: Object.assign({}, this.state.endDate, { value: value.endDate })
                        },
                        () => { this.onChange(); }
                    );
                } else {
                    this.setState(
                        {
                            startDate: Object.assign({}, this.state.startDate, { value: value.date }),
                            endDate: Object.assign({}, this.state.endDate, { value: value.date })
                        },
                        () => { this.onChange(); }
                    );
                }
            }
        };
        return ops;
    }

    isFormValid() {
        if (this.state.leaveRequestCheckXHR || this.state.retriveDayEventsLiteMapXHR ||
            this.isOverBooked() || !this.isBookableInterval() || !this.isInUsableinterval()) {
            return false;
        }
        if (this.state.comment.rules && !this.Validator().validate(this.state.comment)) {
            return false;
        }
        if (this.state.replacement.rules && !this.Validator().validate(this.state.replacement)) {
            return false;
        }
        return true;
    }

    submit(event: any) {
        event.preventDefault();
        const state = this.state;
        let submitRequest: any = {
            leaveType: Object.assign({}, state.leaveType, { touched: true }),
            requestDayType: Object.assign({}, state.requestDayType, { touched: true }),
            startDate: Object.assign({}, state.startDate, { touched: true }),
            endDate: Object.assign({}, state.endDate, { touched: true }),
            employee: Object.assign({}, state.employee, { touched: true }),
            markApproval: Object.assign({}, state.markApproval, { touched: true }),
            comment: Object.assign({}, state.comment, { touched: true }),
            replacement: Object.assign({}, state.replacement, { touched: true })
        };
        if (submitRequest.requestDayType === REQUESTDAYTYPE.HOURS && state.hours.value) {
            submitRequest.hours = Object.assign({}, state.hours, { touched: true });
        }
        this.setState(submitRequest
            ,
            () => { if (this.isFormValid()) { this.registerLeave(); } }
        );
    }

    onChange() {
        const state = this.state;
        this.setState({ requestConfirmation: false }, () => {
            const request: any = {
                leaveTypeId: state.leaveType.value[0],
                requestDayType: state.requestDayType.value[0],
                startDate: state.startDate.value,
                endDate: state.endDate.value,
                onBehalf: state.onBehalf.value,
                employee: state.employee.value[0],
                markApproval: state.markApproval.value[0],
                comment: state.comment.value,
                replacement: state.replacement.value,
            }
            if (request.requestDayType === REQUESTDAYTYPE.HOURS && state.hours.value) {
                request.hours = state.hours.value[0];
            }
            this.leaveCheck(request);
        });
    }

    leaveCheck(request: any) {
        const caches: any = this.getCaches().getState();
        const session = this.getSession().getState() as any;
        if (session.permissions.anonymize) return;
        this.setState(
            {
                leaveRequestCheck: { value: undefined, status: STATUS.LOADING },
                leaveRequestCheckXHR: (
                    (): any => {
                        let check: any = {
                            sd: request.startDate,
                            ed: request.endDate,
                            cuid: request.employee,
                            dt: request.requestDayType === REQUESTDAYTYPE.RANGE ?
                                REQUESTDAYTYPE.FULL : request.requestDayType,
                            ltid: request.leaveTypeId,
                            ocuids: caches.users.arr.map((user: any) => {
                                return user.id;
                            })
                        };
                        if (request.requestDayType === REQUESTDAYTYPE.HOURS) {
                            check.hours = request.hours;
                        }
                        return $.ajax({
                            type: 'GET',
                            contentType: 'application/json',
                            url: this.Endpoints().getLeaveCheck(check),
                            dataType: 'json',
                            cache: false,
                            success: (data, textStatus, jqXHR) => {
                                data.leaveTypeAmountsPerYear = data.leaveTypeAmountsPerYear.filter((row: any) => {
                                    if (row[2] === 0) {
                                        return false;
                                    }
                                    return true;
                                })
                                this.setState(
                                    {
                                        otherLeaves: { value: OtherLeaves.prepareOtherLeaves(data.otherLeaves, caches), status: STATUS.READY },
                                        leaveTypeAmountsPerYear: { value: data.leaveTypeAmountsPerYear, status: STATUS.READY }
                                    }
                                );
                            },
                            error: (jqXHR, textStatus, errorThrown) => {
                                this.ajaxError(jqXHR, textStatus, errorThrown);
                            },
                            complete: () => {
                                this.setState({ leaveRequestCheckXHR: undefined });
                            }
                        });
                    })(),
            }
        );
    }

    retriveDayEventsLiteMap() {
        this.setState(
            {
                dayEventsLiteMap: { value: {}, status: STATUS.LOADING },
                retriveDayEventsLiteMapXHR: (
                    (): any => {
                        return $.ajax({
                            type: 'GET',
                            contentType: 'application/json',
                            url: this.Endpoints().getDayEventsFeather({
                                filters: { startDate: this.state.minDate, endDate: this.state.maxDate, cuid: { value: this.state.employee.value[0] } }
                            }),
                            dataType: 'json',
                            cache: false,
                            success: (data, textStatus, jqXHR) => {
                                const dayEventsLiteMap: any = {};
                                const caches: any = this.getCaches().getState();
                                const leaveType = caches.leaveTypes.map[this.state.leaveType.value[0]];
                                data.data.forEach((row: any) => {
                                    const dayEvent = {
                                        date: row[0],
                                        status: row[1],
                                        color: row[2],
                                        dayType: row[3]
                                    };
                                    let dayEventFromMap = dayEventsLiteMap[dayEvent.date];
                                    if (dayEventFromMap) {
                                        if ((dayEvent.status === 1000 || dayEvent.status === 1001)) {
                                            dayEventsLiteMap[dayEvent.date] = dayEvent;
                                        }
                                        if ((leaveType.working) && (dayEvent.status === 1 || dayEvent.status === 0)) {
                                            return;
                                        }
                                        if (dayEventFromMap.status === 2) {
                                            dayEventsLiteMap[dayEvent.date] = dayEvent;
                                        }
                                    } else {
                                        if ((leaveType.working) && (dayEvent.status === 1 || dayEvent.status === 0)) {
                                            return;
                                        }
                                        dayEventsLiteMap[dayEvent.date] = dayEvent;
                                    }
                                });
                                this.setState(
                                    { dayEventsLiteMap: { value: dayEventsLiteMap, status: STATUS.READY } },
                                    () => { this.setState(this.findFirstDay(), () => { this.onChange(); }); }
                                );
                            },
                            error: (jqXHR, textStatus, errorThrown) => {
                                this.ajaxError(jqXHR, textStatus, errorThrown);
                            },
                            complete: () => {
                                this.setState({ retriveDayEventsLiteMapXHR: undefined });
                            }
                        });
                    })(),
            }
        );
    }

    changeLeaveType(value: any) {
        const session = this.getSession().getState() as any;
        const moment = this.getMomentWithLocale();
        const caches: any = this.getCaches().getState();
        const newLeaveType = caches.leaveTypes.map[value[0]];
        let newRequestDayType = (!newLeaveType.halfADayAllowed &&
            (this.state.requestDayType.value[0] === REQUESTDAYTYPE.FIRST_PART ||
                this.state.requestDayType.value[0] === REQUESTDAYTYPE.SECOND_PART)) || !(newLeaveType.hoursAllowed && this.state.requestDayType.value[0] === REQUESTDAYTYPE.HOURS) ?
            [REQUESTDAYTYPE.FULL] : this.state.requestDayType.value;
        newRequestDayType = newLeaveType.overtime ? [REQUESTDAYTYPE.HOURS] : this.state.requestDayType.value;
        const comment: any = Object.assign({}, this.state.comment);
        comment.rules = newLeaveType.commentRequired ? this.Rules().BasicNameInputRules() : null;
        comment.touched = !!comment.rules;
        const replacement: any = Object.assign({}, this.state.replacement);
        replacement.rules = newLeaveType.replacement && newLeaveType.replacementRequired ? this.Rules().BasicNameInputRules() : null;
        replacement.touched = !!replacement.rules;
        const leaveType = newLeaveType;
        let minDate = { value: newLeaveType.registerInThePast || session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN ? moment().subtract(13, 'months').startOf('month') : moment().add(newLeaveType.earlier, 'days') };
        if (session.companyUser.role !== COMPANYUSERROLES.COMPANY_ADMIN) {
            if ((leaveType?.effectiveAfter) && (session?.companyUser?.employmentStartDate)) {
                let employmentStartDate = moment(session.companyUser.employmentStartDate);
                employmentStartDate = employmentStartDate.add(leaveType?.effectiveAfter, 'day').startOf('day');
                if (minDate.value.isBefore(employmentStartDate)) {
                    minDate.value = employmentStartDate;
                }
            }
        }
        let maxDate = { value: moment().add(1.1, 'year') };
        if (newLeaveType.usableInterval) {
            minDate = { value: moment(newLeaveType.usableFrom) };
            maxDate = { value: moment(newLeaveType.usableTo) };
        }
        let startDate = moment().add(newLeaveType.earlier ? newLeaveType.earlier + 1 : 1, 'day').startOf('day');
        startDate = startDate.isBefore(minDate.value) ? minDate.value : startDate;
        let endDate = moment().add(newLeaveType.earlier ? newLeaveType.earlier + 1 : 1, 'day').startOf('day');
        endDate = startDate.isBefore(minDate.value) ? minDate.value : endDate;
        const hours = newLeaveType.hoursAllowed && newRequestDayType[0] === REQUESTDAYTYPE.HOURS ? {
            value: [1],
            touched: false,
            rules: this.Rules().BasicInputRules()
        } : {};
        this.setState(
            {
                leaveType: Object.assign({}, this.state.leaveType, { value: value }),
                minDate,
                maxDate,
                startDate: { value: startDate, touched: false },
                endDate: { value: endDate, touched: false },
                requestDayType: Object.assign({}, this.state.requestDayType, { value: newRequestDayType }),
                hours,
                comment,
                replacement
            },
            () => { this.retriveDayEventsLiteMap(); this.onChange(); });
    }
}