import * as React from 'react';
import Component from '../../Component';
import { findLocaleName } from '../../Utils';

export default class LeaveName extends Component {
    render() {
        const leaveType = this.props.leaveType;
        const faStyle = { color: leaveType.color };
        const name = findLocaleName(leaveType.names, this.language());
        return <div className="d-flex flex-row d-inline-block w-100" style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}>
            <div><i className="mr-1 fa fa-circle" style={faStyle} aria-hidden="true" /></div>
            <div>{name}</div>
        </div>
    }

    public static getName(this: any, leaveType: any) {
        return findLocaleName(leaveType.names, this.language());
    }
}