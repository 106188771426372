import Component from '../../../Component';
import A from '../../../Components/A';
import Close from '../../../Components/Close';
import { MYACCOUNTTABS } from '../../../General';

export default class User extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {};
    }

    render() {
        const session: any = this.getSession().getState();
        const isManager = session.companyUser.isManager;
        const t = this.translate();
        return <div className="card h-100">
            <div className="card-body">
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="float-right">
                            <Close onClick={() => { if (this.props.onClose) { this.props.onClose(); } }} />
                        </div>
                        <h5 className="m-0">{t('welcome.to.leaveboard')}</h5>
                        <small>
                            {isManager ?
                                t('as.manager.with.leaveBoard.you.can.simply.manage.your.leaves.and.book.time.off')
                                :
                                t('with.leaveBoard.you.can.simply.manage.your.leaves.and.book.time.off')
                            }
                        </small>
                    </div>
                    <div className="col-12">
                        <strong>{t('more.actions')}</strong>
                    </div>
                    <div className={(this.isScreenSizeSmall() ? 'col-12' : 'col-4')}>
                        {isManager ? <div>
                            <A
                                title={t('approve.leave.requests')}
                                href={this.Urls().getLeavesToApprove()}
                            >
                                {t('approve.leave.requests')}
                            </A>
                            <br />
                        </div>
                            :
                            null
                        }
                        <A
                            title={t('check.your.team.calendar')}
                            href={this.Urls().getCalendar()}
                        >
                            {t('check.your.team.calendar')}
                        </A>
                    </div>
                    <div className={(this.isScreenSizeSmall() ? 'col-12' : 'col-4')} >
                        <A
                            title={t('check.your.profile.details')}
                            href={this.Urls().getMyAccount({ tab: MYACCOUNTTABS.PROFILE })}
                        >
                            {t('check.your.profile.details')}
                        </A>
                    </div>
                </div>
            </div>
        </div>
    }
}