import BaseTabs from '../../Components/BaseTabs';
import { COMPANYUSERROLES, EMPLOYEETABS } from '../../General';

export default class Tabs extends BaseTabs {
    getTabs(): any[] {
        const t = this.translate();
        const session: any = this.getSession().getState();
        const isAdmin = () => {
            return session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN;
        };
        const isManager = () => {
            return session.companyUser.role === COMPANYUSERROLES.COMPANY_MANAGER;
        };
        const tabs = [];
        if (isAdmin() || isManager()) {
            tabs.push({
                faIcon: 'far fa-user',
                title: t('profile'),
                tab: EMPLOYEETABS.PROFILE
            });
            tabs.push({
                faIcon: 'fa fa-history',
                title: t('leaves'),
                tab: EMPLOYEETABS.LEAVES
            })
            tabs.push({
                faIcon: 'fa fa-calendar',
                title: t('calendar'),
                tab: EMPLOYEETABS.CALENDAR
            });
            tabs.push({
                faIcon: 'fa fa-bug',
                title: t('timeoff'),
                tab: EMPLOYEETABS.TIMEOFF
            })
        }
        if (isAdmin()) {
            tabs.push({
                faIcon: 'fas fa-user-tag',
                title: t('personal.details'),
                tab: EMPLOYEETABS.PERSONAL_DETAILS
            });
            tabs.push({
                faIcon: 'fas fa-sliders-h',
                title: t('allowances'),
                tab: EMPLOYEETABS.ALLOWANCES
            });
        }
        return tabs;
    }

    getName(): string {
        return 'Employee';
    }
}