import * as React from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import { A } from '.';
import Component from '../Component';
import SortFieldLink from './SortFieldLink';
import SortOrder from './SortOrder';

export default class SortBy extends Component {
    public static CID = 0;
    private id: any;
    constructor(props: any) {
        super(props);
        this.state = { open: false };
        this.id = 'SortBy' + SortBy.CID++;
        this.toggle = this.toggle.bind(this);
        this.handleSortFieldChange = this.handleSortFieldChange.bind(this);
    }

    public toggle() { if (this.props.sortBy.fields && this.props.sortBy.fields.length > 1) this.setState({ open: !this.state.open }); }

    public handleSortFieldChange(sortField: any) {
        this.props.onSortFieldChangeTrigger(sortField);
        this.toggle();
    }
    public render() {
        const t = this.translate();
        const sortBy = this.props.sortBy;
        return <span className="pr-1">
            <A id={this.id} href="##" title={this.props.sortBy.fields && this.props.sortBy.fields.length > 1 ? t('change.sort.field') : t('sort.field')}
                onClick={(this.props.sortBy.fields && this.props.sortBy.fields.length > 1) ? this.toggle : this.props.onSortOrderChangeTrigger}>
                {sortBy.field.name}
            </A>
            <SortOrder order={sortBy.order} onSortOrderChangeTrigger={this.props.onSortOrderChangeTrigger} />
            <Popover placement="bottom-start" isOpen={this.state.open} target={this.id} toggle={this.toggle}>
                <PopoverBody>
                    {sortBy.fields.map((sortField: any) => <SortFieldLink key={sortField.value} sortField={sortField} onSortFieldChangeTrigger={this.handleSortFieldChange} />)}
                </PopoverBody>
            </Popover>
        </span>
    }
}