import * as React from 'react';
import fabrands400eot from '@fortawesome/fontawesome-free/webfonts/fa-brands-400.eot';
import fabrands400svg from '@fortawesome/fontawesome-free/webfonts/fa-brands-400.svg';
import fabrands400ttf from '@fortawesome/fontawesome-free/webfonts/fa-brands-400.ttf';
import fabrands400woff from '@fortawesome/fontawesome-free/webfonts/fa-brands-400.woff';
import fabrands400woff2 from '@fortawesome/fontawesome-free/webfonts/fa-brands-400.woff2';
import faregular400eot from '@fortawesome/fontawesome-free/webfonts/fa-regular-400.eot';
import faregular400svg from '@fortawesome/fontawesome-free/webfonts/fa-regular-400.svg';
import faregular400ttf from '@fortawesome/fontawesome-free/webfonts/fa-regular-400.ttf';
import faregular400woff from '@fortawesome/fontawesome-free/webfonts/fa-regular-400.woff';
import faregular400woff2 from '@fortawesome/fontawesome-free/webfonts/fa-regular-400.woff2';
import fasolid900eot from '@fortawesome/fontawesome-free/webfonts/fa-solid-900.eot';
import fasolid900svg from '@fortawesome/fontawesome-free/webfonts/fa-solid-900.svg';
import fasolid900ttf from '@fortawesome/fontawesome-free/webfonts/fa-solid-900.ttf';
import fasolid900woff from '@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff';
import fasolid900woff2 from '@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff2';

/*
import OpenSansLighteot from '../../node_modules/npm-font-open-sans/fonts/Light/OpenSans-Light.eot';
import OpenSansLightwoff2 from '../../node_modules/npm-font-open-sans/fonts/Light/OpenSans-Light.woff2';
import OpenSansLightwoff from '../../node_modules/npm-font-open-sans/fonts/Light/OpenSans-Light.woff';
import OpenSansLightttf from '../../node_modules/npm-font-open-sans/fonts/Light/OpenSans-Light.ttf';
import OpenSansLightsvg from '../../node_modules/npm-font-open-sans/fonts/Light/OpenSans-Light.svg';

import OpenSansLightItaliceot from '../../node_modules/npm-font-open-sans/fonts/LightItalic/OpenSans-LightItalic.eot';
import OpenSansLightItalicwoff2 from '../../node_modules/npm-font-open-sans/fonts/LightItalic/OpenSans-LightItalic.woff2';
import OpenSansLightItalicwoff from '../../node_modules/npm-font-open-sans/fonts/LightItalic/OpenSans-LightItalic.woff';
import OpenSansLightItalicttf from '../../node_modules/npm-font-open-sans/fonts/LightItalic/OpenSans-LightItalic.ttf';
import OpenSansLightItalicsvg from '../../node_modules/npm-font-open-sans/fonts/LightItalic/OpenSans-LightItalic.svg';


import OpenSansRegulareot from '../../node_modules/npm-font-open-sans/fonts/Regular/OpenSans-Regular.eot';*/
import OpenSansRegularwoff2 from 'npm-font-open-sans/fonts/Regular/OpenSans-Regular.woff2';
/*import OpenSansRegularwoff from '../../node_modules/npm-font-open-sans/fonts/Regular/OpenSans-Regular.woff';
import OpenSansRegularttf from '../../node_modules/npm-font-open-sans/fonts/Regular/OpenSans-Regular.ttf';
import OpenSansRegularsvg from '../../node_modules/npm-font-open-sans/fonts/Regular/OpenSans-Regular.svg';

import OpenSansItaliceot from '../../node_modules/npm-font-open-sans/fonts/Italic/OpenSans-Italic.eot';
import OpenSansItalicwoff2 from '../../node_modules/npm-font-open-sans/fonts/Italic/OpenSans-Italic.woff2';
import OpenSansItalicwoff from '../../node_modules/npm-font-open-sans/fonts/Italic/OpenSans-Italic.woff';
import OpenSansItalicttf from '../../node_modules/npm-font-open-sans/fonts/Italic/OpenSans-Italic.ttf';
import OpenSansItalicsvg from '../../node_modules/npm-font-open-sans/fonts/Italic/OpenSans-Italic.svg';

import OpenSansSemiboldeot from '../../node_modules/npm-font-open-sans/fonts/Semibold/OpenSans-Semibold.eot';
import OpenSansSemiboldwoff2 from '../../node_modules/npm-font-open-sans/fonts/Semibold/OpenSans-Semibold.woff2';
import OpenSansSemiboldwoff from '../../node_modules/npm-font-open-sans/fonts/Semibold/OpenSans-Semibold.woff';
import OpenSansSemiboldttf from '../../node_modules/npm-font-open-sans/fonts/Semibold/OpenSans-Semibold.ttf';
import OpenSansSemiboldsvg from '../../node_modules/npm-font-open-sans/fonts/Semibold/OpenSans-Semibold.svg';

import OpenSansSemiboldItaliceot from '../../node_modules/npm-font-open-sans/fonts/SemiboldItalic/OpenSans-SemiboldItalic.eot';
import OpenSansSemiboldItalicwoff2 from '../../node_modules/npm-font-open-sans/fonts/SemiboldItalic/OpenSans-SemiboldItalic.woff2';
import OpenSansSemiboldItalicwoff from '../../node_modules/npm-font-open-sans/fonts/SemiboldItalic/OpenSans-SemiboldItalic.woff';
import OpenSansSemiboldItalicttf from '../../node_modules/npm-font-open-sans/fonts/SemiboldItalic/OpenSans-SemiboldItalic.ttf';
import OpenSansSemiboldItalicsvg from '../../node_modules/npm-font-open-sans/fonts/SemiboldItalic/OpenSans-SemiboldItalic.svg';

import OpenSansBoldeot from '../../node_modules/npm-font-open-sans/fonts/Bold/OpenSans-Bold.eot';
import OpenSansBoldwoff2 from '../../node_modules/npm-font-open-sans/fonts/Bold/OpenSans-Bold.woff2';
import OpenSansBoldwoff from '../../node_modules/npm-font-open-sans/fonts/Bold/OpenSans-Bold.woff';
import OpenSansBoldttf from '../../node_modules/npm-font-open-sans/fonts/Bold/OpenSans-Bold.ttf';
import OpenSansBoldsvg from '../../node_modules/npm-font-open-sans/fonts/Bold/OpenSans-Bold.svg';

import OpenSansBoldItaliceot from '../../node_modules/npm-font-open-sans/fonts/BoldItalic/OpenSans-BoldItalic.eot';
import OpenSansBoldItalicwoff2 from '../../node_modules/npm-font-open-sans/fonts/BoldItalic/OpenSans-BoldItalic.woff2';
import OpenSansBoldItalicwoff from '../../node_modules/npm-font-open-sans/fonts/BoldItalic/OpenSans-BoldItalic.woff';
import OpenSansBoldItalicttf from '../../node_modules/npm-font-open-sans/fonts/BoldItalic/OpenSans-BoldItalic.ttf';
import OpenSansBoldItalicsvg from '../../node_modules/npm-font-open-sans/fonts/BoldItalic/OpenSans-BoldItalic.svg';

import OpenSansExtraBoldeot from '../../node_modules/npm-font-open-sans/fonts/ExtraBold/OpenSans-ExtraBold.eot';
import OpenSansExtraBoldwoff2 from '../../node_modules/npm-font-open-sans/fonts/ExtraBold/OpenSans-ExtraBold.woff2';
import OpenSansExtraBoldwoff from '../../node_modules/npm-font-open-sans/fonts/ExtraBold/OpenSans-ExtraBold.woff';
import OpenSansExtraBoldttf from '../../node_modules/npm-font-open-sans/fonts/ExtraBold/OpenSans-ExtraBold.ttf';
import OpenSansExtraBoldsvg from '../../node_modules/npm-font-open-sans/fonts/ExtraBold/OpenSans-ExtraBold.svg';

import OpenSansExtraBoldItaliceot from '../../node_modules/npm-font-open-sans/fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.eot';
import OpenSansExtraBoldItalicwoff2 from '../../node_modules/npm-font-open-sans/fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.woff2';
import OpenSansExtraBoldItalicwoff from '../../node_modules/npm-font-open-sans/fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.woff';
import OpenSansExtraBoldItalicttf from '../../node_modules/npm-font-open-sans/fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.ttf';
import OpenSansExtraBoldItalicsvg from '../../node_modules/npm-font-open-sans/fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.svg';
*/

export const createFontLinks = () => {
  const createFontLink = (font: any) => {
    const elem = document.createElement('link');
    {
      const att = document.createAttribute('rel');
      att.value = 'preload';
      elem.setAttributeNode(att);
    }
    {
      const att = document.createAttribute('as');
      att.value = 'font';
      elem.setAttributeNode(att);
    }
    {
      const att = document.createAttribute('crossOrigin');
      att.value = 'anonymous';
      elem.setAttributeNode(att);
    }
    {
      const att = document.createAttribute('href');
      att.value = font;
      elem.setAttributeNode(att);
    }
    document.head.appendChild(elem);
  }
  [fabrands400woff2 + '?display=swap', faregular400woff2 + '?display=swap', fasolid900woff2 + '?display=swap', OpenSansRegularwoff2].forEach((font: any) => {
    createFontLink(font);
  })
}


export const preloadFonts = () => {
  return [
    /*    <link rel="preload" href={fabrands400eot + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={fabrands400svg + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={fabrands400ttf + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={fabrands400woff + '?display=swap'} as="font" crossOrigin="anonymous" />,*/
    <link rel="preload" href={fabrands400woff2 + '?display=swap'} as="font" crossOrigin="anonymous" />,
    /*    <link rel="preload" href={faregular400eot + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={faregular400svg + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={faregular400ttf + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={faregular400woff + '?display=swap'} as="font" crossOrigin="anonymous" />,*/
    <link rel="preload" href={faregular400woff2 + '?display=swap'} as="font" crossOrigin="anonymous" />,/*
        <link rel="preload" href={fasolid900eot + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={fasolid900svg + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={fasolid900ttf + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={fasolid900woff + '?display=swap'} as="font" crossOrigin="anonymous" />,*/
    <link rel="preload" href={fasolid900woff2 + '?display=swap'} as="font" crossOrigin="anonymous" />,

    /*
    <link rel="preload" href={OpenSansLighteot + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansLightwoff2 + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansLightwoff + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansLightttf + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansLightsvg + '?display=swap'} as="font" crossOrigin="anonymous" />,

    <link rel="preload" href={OpenSansLightItaliceot + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansLightItalicwoff2 + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansLightItalicwoff + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansLightItalicttf + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansLightItalicsvg + '?display=swap'} as="font" crossOrigin="anonymous" />,

    <link rel="preload" href={OpenSansRegulareot + '?display=swap'} as="font" crossOrigin="anonymous" />, */
    <link rel="preload" href={OpenSansRegularwoff2/* + '?display=swap'*/} as="font" crossOrigin="anonymous" />,
    /*    <link rel="preload" href={OpenSansRegularwoff + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={OpenSansRegularttf + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={OpenSansRegularsvg + '?display=swap'} as="font" crossOrigin="anonymous" />,
    
        <link rel="preload" href={OpenSansItaliceot + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={OpenSansItalicwoff2 + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={OpenSansItalicwoff + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={OpenSansItalicttf + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={OpenSansItalicsvg + '?display=swap'} as="font" crossOrigin="anonymous" />,
    
        <link rel="preload" href={OpenSansSemiboldeot + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={OpenSansSemiboldwoff2 + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={OpenSansSemiboldwoff + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={OpenSansSemiboldttf + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={OpenSansSemiboldsvg + '?display=swap'} as="font" crossOrigin="anonymous" />,
    
        <link rel="preload" href={OpenSansSemiboldItaliceot + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={OpenSansSemiboldItalicwoff2 + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={OpenSansSemiboldItalicwoff + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={OpenSansSemiboldItalicttf + '?display=swap'} as="font" crossOrigin="anonymous" />,
        <link rel="preload" href={OpenSansSemiboldItalicsvg + '?display=swap'} as="font" crossOrigin="anonymous" />,
    
        <link rel="preload" href={OpenSansBoldeot + '?display=swap'} as="font" crossOrigin="anonymous" />, 
    <link rel="preload" href={OpenSansBoldwoff2 + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansBoldwoff + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansBoldttf + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansBoldsvg + '?display=swap'} as="font" crossOrigin="anonymous" />,

    <link rel="preload" href={OpenSansBoldItaliceot + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansBoldItalicwoff2 + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansBoldItalicwoff + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansBoldItalicttf + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansBoldItalicsvg + '?display=swap'} as="font" crossOrigin="anonymous" />,

    <link rel="preload" href={OpenSansExtraBoldeot + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansExtraBoldwoff2 + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansExtraBoldwoff + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansExtraBoldttf + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansExtraBoldsvg + '?display=swap'} as="font" crossOrigin="anonymous" />,

    <link rel="preload" href={OpenSansExtraBoldItaliceot + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansExtraBoldItalicwoff2 + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansExtraBoldItalicwoff + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansExtraBoldItalicttf + '?display=swap'} as="font" crossOrigin="anonymous" />,
    <link rel="preload" href={OpenSansExtraBoldItalicsvg + '?display=swap'} as="font" crossOrigin="anonymous" />,
    */
  ];
}

export const writeFontStyle = () => {
  writeFontFaStyle();
  //  writeFontOpenSansStyle();
}

export const writeFontFaStyle = () => {
  const elem = document.createElement('style');
  elem.innerHTML = `
    @font-face {
        font-family: 'Font Awesome 5 Brands';
        font-style: normal;
        font-weight: normal;
        font-display: swap;
        src: url("` + fabrands400eot + `?display=swap");
        src: url("` + fabrands400eot + `?display=swap#iefix") format("embedded-opentype"), url("` + fabrands400woff2 + `?display=swap") format("woff2"), url("` + fabrands400woff + `?display=swap") format("woff"), url("` + fabrands400ttf + `?display=swap") format("truetype"), url("` + fabrands400svg + `?display=swap#fontawesome") format("svg"); }
      
      .fab {
        font-family: 'Font Awesome 5 Brands'; }
      @font-face {
        font-family: 'Font Awesome 5 Free';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url("` + faregular400eot + `?display=swap");
        src: url("` + faregular400eot + `?display=swap#iefix") format("embedded-opentype"), url("` + faregular400woff2 + `?display=swap") format("woff2"), url("` + faregular400woff + `?display=swap") format("woff"), url("` + faregular400ttf + `?display=swap") format("truetype"), url("` + faregular400svg + `?display=swap#fontawesome") format("svg"); }
      
      .far {
        font-family: 'Font Awesome 5 Free';
        font-weight: 400; }
      @font-face {
        font-family: 'Font Awesome 5 Free';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: url("` + fasolid900eot + `?display=swap");
        src: url("` + fasolid900eot + `?display=swap#iefix") format("embedded-opentype"), url("` + fasolid900woff2 + `?display=swap") format("woff2"), url("` + fasolid900woff + `?display=swap") format("woff"), url("` + fasolid900ttf + `?display=swap") format("truetype"), url("` + fasolid900svg + `?display=swap#fontawesome") format("svg"); }
      
      .fa,
      .fas {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900; }
      
    `;
  document.body.appendChild(elem);
}
/*
export const writeFontOpenSansStyle = () => {
  const elem = document.createElement('style');
  elem.innerHTML = `
  @font-face {
    font-family: 'Open Sans';
    font-weight: 300;
    font-style: normal;
    font-display: swap;
    src: url('` + OpenSansLighteot + `?display=swap');
    src: url('` + OpenSansLighteot + `?display=swap#iefix') format('embedded-opentype'),
    url('` + OpenSansLightwoff2 + `?display=swap') format('woff2'),
    url('` + OpenSansLightwoff + `?display=swap') format('woff'),
    url('` + OpenSansLightttf + `?display=swap') format('truetype'),
    url('` + OpenSansLightsvg + `?display=swap#OpenSansLight') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 300;
    font-style: italic;
    font-display: swap;
    src: url('` + OpenSansLightItaliceot + `?display=swap');
    src: url('` + OpenSansLightItaliceot + `?display=swap#iefix') format('embedded-opentype'),
    url('` + OpenSansLightItalicwoff2 + `?display=swap') format('woff2'),
    url('` + OpenSansLightItalicwoff + `?display=swap') format('woff'),
    url('` + OpenSansLightItalicttf + `?display=swap') format('truetype'),
    url('` + OpenSansLightItalicsvg + `?display=swap#OpenSansLight') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url('` + OpenSansRegulareot + `?display=swap');
    src: url('` + OpenSansRegulareot + `?display=swap#iefix') format('embedded-opentype'),
    url('` + OpenSansRegularwoff2 + `?display=swap') format('woff2'),
    url('` + OpenSansRegularwoff + `?display=swap') format('woff'),
    url(` + OpenSansRegularttf + `?display=swap') format('truetype'),
    url('` + OpenSansRegularsvg + `?display=swap#OpenSansRegular') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: normal;
    font-style: italic;
    font-display: swap;
    src: url('`+ OpenSansItaliceot + `?display=swap');
    src: url('`+ OpenSansItaliceot + `?display=swap#iefix') format('embedded-opentype'),
    url('` + OpenSansItalicwoff2 + `?display=swap') format('woff2'),
    url('`+ OpenSansItalicwoff + `?display=swap') format('woff'),
    url('` + OpenSansItalicttf + `?display=swap') format('truetype'),
    url('` + OpenSansItalicsvg + `?display=swap#OpenSansItalic') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    font-style: normal;
    font-display: swap;
    src: url('` + OpenSansSemiboldeot + `?display=swap');
    src: url('` + OpenSansSemiboldeot + `?display=swap#iefix') format('embedded-opentype'),
    url('` + OpenSansSemiboldwoff2 + `?display=swap') format('woff2'),
    url('` + OpenSansSemiboldwoff + `?display=swap') format('woff'),
    url('` + OpenSansSemiboldttf + `?display=swap') format('truetype'),
    url('` + OpenSansSemiboldsvg + `?display=swap#OpenSansSemibold') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    font-style: italic;
    font-display: swap;
    src: url('` + OpenSansSemiboldItaliceot + `?display=swap');
    src: url('` + OpenSansSemiboldItaliceot + `?display=swap#iefix') format('embedded-opentype'),
    url('` + OpenSansSemiboldItalicwoff2 + `?display=swap') format('woff2'),
    url('` + OpenSansSemiboldItalicwoff + `?display=swap') format('woff'),
    url('` + OpenSansSemiboldItalicttf + `?display=swap') format('truetype'),
    url('`+ OpenSansSemiboldItalicsvg + `?display=swap#OpenSansSemiboldItalic') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: bold;
    font-style: normal;
    font-display: swap;
    src: url('`+ OpenSansBoldeot + `?display=swap');
    src: url('` + OpenSansBoldeot + `?display=swap#iefix') format('embedded-opentype'),
    url('`+ OpenSansBoldwoff2 + `?display=swap') format('woff2'),
    url('` + OpenSansBoldwoff + `?display=swap') format('woff'),
    url('` + OpenSansBoldttf + `?display=swap') format('truetype'),
    url('` + OpenSansBoldsvg + `?display=swap#OpenSansBold') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: bold;
    font-style: italic;
    font-display: swap;
    src: url('`+ OpenSansBoldItaliceot + `?display=swap');
    src: url('`+ OpenSansBoldItaliceot + `?display=swap#iefix') format('embedded-opentype'),
    url('`+ OpenSansBoldItalicwoff2 + `?display=swap') format('woff2'),
    url('`+ OpenSansBoldItalicwoff + `?display=swap') format('woff'),
    url('`+ OpenSansBoldItalicttf + `?display=swap') format('truetype'),
    url('`+ OpenSansBoldItalicsvg + `?display=swap#OpenSansBoldItalic') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    src: url('`+ OpenSansExtraBoldeot + `?display=swap');
    src: url('`+ OpenSansExtraBoldeot + `?display=swap#iefix') format('embedded-opentype'),
    url('`+ OpenSansExtraBoldwoff2 + `?display=swap') format('woff2'),
    url('`+ OpenSansExtraBoldwoff + `?display=swap') format('woff'),
    url('`+ OpenSansExtraBoldttf + `?display=swap') format('truetype'),
    url('`+ OpenSansExtraBoldsvg + `?display=swap#OpenSansExtrabold') format('svg');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 800;
    font-style: italic;
    font-display: swap;
    src: url('` + OpenSansExtraBoldItaliceot + `?display=swap');
    src: url('` + OpenSansExtraBoldItaliceot + `?display=swap#iefix') format('embedded-opentype'),
    url('` + OpenSansExtraBoldItalicwoff2 + `?display=swap') format('woff2'),
    url('` + OpenSansExtraBoldItalicwoff + `?display=swap') format('woff'),
    url('` + OpenSansExtraBoldItalicttf + `?display=swap') format('truetype'),
    url('` + OpenSansExtraBoldItalicsvg + `?display=swap#OpenSansExtraboldItalic') format('svg');
}
  `;
  document.body.appendChild(elem);
}
*/