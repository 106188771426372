import * as React from 'react';
import Component from '../../Component';
import Anchor from '../Anchor';
import { LEAVESTATUS } from '../../General';
import LeaveEventTime from './LeaveEventTime';

export default class LeaveDetailedStatus extends Component {
    render() {
        const moment = this.getMomentWithLocale();
        const t = this.translate();
        var leave = this.props.leave;
        const approver = leave.approver;
        const approvedBy = leave.approvedBy;
        switch (leave.status) {
            case LEAVESTATUS.AWAITING_APPROVAL:
                if (this.props.simpleAwaitingApproval)
                    return (<span>{t('LEAVESTATUS.' + LEAVESTATUS.AWAITING_APPROVAL)} </span>);
                return (
                    <span>
                        {t('LEAVESTATUS.' + LEAVESTATUS.AWAITING_APPROVAL + '.from')}
                        {' ' + approver.firstName + ' ' + approver.lastName}
                    </span>
                );
            case LEAVESTATUS.APPROVED:
                return (
                    <span>
                        {t('LEAVESTATUS.' + LEAVESTATUS.APPROVED + '.by')}
                        {' ' + approvedBy.firstName + ' ' + approvedBy.lastName}
                        <span className="float-right">
                            <LeaveEventTime>
                                {t('LEAVESTATUS.' + LEAVESTATUS.APPROVED + '.on')}
                                <div>{moment(leave.lastActionOn).format('LLL')}</div>
                            </LeaveEventTime>
                        </span>
                        {this.getApproversComment()}
                    </span>
                );
            case LEAVESTATUS.REJECTED:
                return (
                    <span>
                        {t('LEAVESTATUS.' + LEAVESTATUS.REJECTED + '.by')}
                        {' ' + approvedBy.firstName + ' ' + approvedBy.lastName}
                        <span className="float-right">
                            <LeaveEventTime>
                                {t('LEAVESTATUS.' + LEAVESTATUS.REJECTED + '.on')}
                                <div>{moment(leave.lastActionOn).format('LLL')}</div>
                            </LeaveEventTime>
                        </span>
                        {this.getApproversComment()}
                    </span>
                );
            case LEAVESTATUS.CANCELED:
                return (
                    <span>
                        {t('LEAVESTATUS.' + LEAVESTATUS.CANCELED)}
                        <span className="float-right">
                            <LeaveEventTime>
                                {t('LEAVESTATUS.' + LEAVESTATUS.CANCELED + '.on')}
                                <div>{moment(leave.lastActionOn).format('LLL')}</div>
                            </LeaveEventTime>
                        </span>
                    </span>
                );
            default:
                return '';
        }
    }

    getApproversComment() {
        const t = this.translate();
        var leave = this.props.leave;
        return leave.approverComment ?
            (
                <div>
                    <Anchor faIcon="far fa-comments" title={t('approvers.comment')} />
                    {' '}
                    {leave.approverComment}
                </div>
            )
            :
            null;
    }
}