import * as React from 'react';
import Component from '../../Component';
import PaymentWarning from '../../Components/PaymentWarning';
import { COMPANYUSERROLES, STATUS } from '../../General';
import Manager from './Manager';

export default class EmployeesAge extends Component {

    render() {
        const session: any = this.getSession().getState();
        const isAdmin = () => {
            return session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN || session.companyUser.role === COMPANYUSERROLES.COMPANY_MANAGER;
        };
        if (!isAdmin()) {
            const location = window.location + '';
            this.goToLogin({ target: location });
            return null;
        }
        const caches: any = this.getCaches().getState();
        let ready = caches.users.status === STATUS.READY &&
            (this.getSession().getState() as any).status === STATUS.READY;
        if (ready === false) { return this.renderLoading(); }
        if (this.paymentRequired()) { return null; }
        if (this.trialHasEnded()) { return null; }
        if (this.paymentWarning()) { return [<PaymentWarning key="PaymentWarning" />, <Manager key='Manager' />] }
        return (<Manager />);
    }
}