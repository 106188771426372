import { TOASTR_ACTIONS } from '../Constants/ActionTypes';

export const error = (toastr: any, message: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: TOASTR_ACTIONS.ERROR,
            message: message,
            active: true,
        });
    })(toastr.dispatch, toastr.getState);
};

export const success = (toastr: any, message: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: TOASTR_ACTIONS.SUCCESS,
            message: message,
            active: true,
        });
    })(toastr.dispatch, toastr.getState);
};

export const info = (toastr: any, message: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: TOASTR_ACTIONS.INFO,
            message: message,
            active: true,
        });
    })(toastr.dispatch, toastr.getState);
};

export const clean = (toastr: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: TOASTR_ACTIONS.CLEAN,
            message: undefined,
            active: false,
        });
    })(toastr.dispatch, toastr.getState);
};