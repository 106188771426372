import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import A from '../../../Components/A';
import Cog from '../../../Components/Cog';
import Confirm from '../../../Components/Confirm';
import InputICal from '../../../Components/InputICal';
import TableLine from '../../../Components/TableLine';

export default class Display extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            iCalendarUrlForOffice: {
                value: this.props.office.iCalUrl,
            },
            iCalendarUrlForOfficev2: {
                value: this.props.office.iCalUrlv2,
            },
            iCalendarUrlForOfficeHoes: {
                value: this.props.office.iCalHoesUrl,
            },
            iCalendarUrlForBirthdays: {
                value: this.props.office.iCalendarUrlForBirthdays,
            },
            iCalendarUrlForWorkAnniversaries: {
                value: this.props.office.iCalendarUrlForWorkAnniversaries,
            },
            copyICalendarUrlForOffice: false,
            copyICalendarUrlForOfficeHoes: false,
            copyICalendarUrlForOfficev2: false,
            copyiCalendarUrlForBirthdays: false,
            copyICalendarUrlForOfficeyWorkAnniversaries: false,
        };
    }

    render() {
        const t = this.translate();
        const office = this.props.office;
        const caches: any = this.getCaches().getState();
        const countriesMap = caches.countries.map[this.language()];
        const country = countriesMap[office.country] ?
            countriesMap[office.country].text : countriesMap.US.text;
        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Cog onClick={() => { if (this.props.onEdit) { this.props.onEdit(); } }} />
                    </div>
                    <div className="col-12">
                        {t('country')}
                        <span className="float-right">
                            {country}
                        </span>
                    </div>
                    {(office?.officeManager?.id) ?
                        <div className="col-12">
                            {t('manager')}
                            <span className="float-right">
                                {office.officeManager.fullName}
                            </span>
                        </div>
                        : null}
                    {(office.description) ?
                        <div className="col-12">
                            {t('description')}
                            <pre className="font-weight-light m-0">
                                {office.description}
                            </pre>
                        </div>
                        : null}
                    <div className="col-12">
                        <TableLine />
                    </div>
                    <div className="col-12">
                        {t('icalendar.url')}
                        <div className="float-right">
                            <Confirm
                                title={t('regenerate.icalendar.url')}
                                faIcon="fas fa-sync"
                                onConfirm={() => {
                                    $.ajax({
                                        type: 'POST',
                                        contentType: 'application/json',
                                        url: this.Endpoints().getOfficeiCalRegenerate(office.id),
                                        dataType: 'json',
                                        cache: false,
                                        success: (data: any, textStatus: any, jqXHR: any) => {
                                            this.successToastr(t('the.icalendar.url.was.regenerated'));
                                            if (this.props.onRefresh) {
                                                this.props.onRefresh();
                                            }
                                        },
                                        error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                                            this.ajaxError(jqXHR, textStatus, errorThrown);
                                        }
                                    });
                                }}
                            />
                        </div>
                        <div>
                            <span className="float-right">
                                <A
                                    title={t('office.iCalendar.url')}
                                    faIcon="far fa-copy"
                                    href={this.state.iCalendarUrlForOffice.value}
                                    onClick={() => { this.setState({ copyICalendarUrlForOffice: true }, () => { this.setState({ copyICalendarUrlForOffice: false }); }); }}
                                />
                            </span>
                            V1 : <a href={this.state.iCalendarUrlForOffice.value} target="_blank" className="card-link" rel="noopener noreferrer">
                                {this.state.iCalendarUrlForOffice.value}
                            </a>
                            <InputICal value={this.state.iCalendarUrlForOffice.value} copy={this.state.copyICalendarUrlForOffice} />
                        </div>
                        <div className="mt-3">
                            <span className="float-right">
                                <A
                                    title={t('office.iCalendar.url') + ' V2'}
                                    faIcon="far fa-copy"
                                    href={this.state.iCalendarUrlForOfficev2.value}
                                    onClick={() => { this.setState({ copyICalendarUrlForOfficev2: true }, () => { this.setState({ copyICalendarUrlForOfficev2: false }); }); }}
                                />
                            </span>
                            V2 : <a href={this.state.iCalendarUrlForOfficev2.value} target="_blank" className="card-link" rel="noopener noreferrer">
                                {this.state.iCalendarUrlForOfficev2.value}
                            </a>
                            <InputICal key={222 + 'ddd'} value={this.state.iCalendarUrlForOfficev2.value} copy={this.state.copyICalendarUrlForOfficev2} />
                        </div>

                        <div className="mt-3">
                            <span className="float-right">
                                <A
                                    title={t('office.iCalendar.holidays.and.events.url')}
                                    faIcon="far fa-copy"
                                    href={this.state.iCalendarUrlForOfficeHoes.value}
                                    onClick={() => { this.setState({ copyICalendarUrlForOfficeHoes: true }, () => { this.setState({ copyICalendarUrlForOfficeHoes: false }); }); }}
                                />
                            </span>
                            {t('holidays.and.events')}: <a href={this.state.iCalendarUrlForOfficeHoes.value} target="_blank" className="card-link" rel="noopener noreferrer">
                                {this.state.iCalendarUrlForOfficeHoes.value}
                            </a>
                            <InputICal key={33 + 'ddd'} value={this.state.iCalendarUrlForOfficeHoes.value} copy={this.state.copyICalendarUrlForOfficeHoes} />
                        </div>

                        <div className="mt-3">
                            <span className="float-right">
                                <A
                                    title={t('office.birthdays.iCalendar.url')}
                                    faIcon="far fa-copy"
                                    href={this.state.iCalendarUrlForBirthdays.value}
                                    onClick={() => { this.setState({ copyiCalendarUrlForBirthdays: true }, () => { this.setState({ copyiCalendarUrlForBirthdays: false }); }); }}
                                />
                            </span>
                            <div><h6>{t('office.birthdays.iCalendar.url')}</h6></div><a href={this.state.iCalendarUrlForBirthdays.value} className="card-link" target="_blank" title={t('office.birthdays.iCalendar.url')} rel="noopener noreferrer" >
                                {this.state.iCalendarUrlForBirthdays.value}
                            </a>
                            <InputICal value={this.state.iCalendarUrlForBirthdays.value} copy={this.state.copyiCalendarUrlForBirthdays} />
                        </div>

                        <div className="mt-3">
                            <span className="float-right">
                                <A
                                    title={t('office.work.anniversaries.iCalendar.url')}
                                    faIcon="far fa-copy"
                                    href={this.state.iCalendarUrlForWorkAnniversaries.value}
                                    onClick={() => { this.setState({ copyiCalendarUrlForWorkAnniversaries: true }, () => { this.setState({ copyiCalendarUrlForWorkAnniversaries: false }); }); }}
                                />
                            </span>
                            <div><h6>{t('office.work.anniversaries.iCalendar.url')}</h6></div><a href={this.state.iCalendarUrlForWorkAnniversaries.value} className="card-link" target="_blank" title={t('office.birthdays.iCalendar.url')} rel="noopener noreferrer" >
                                {this.state.iCalendarUrlForWorkAnniversaries.value}
                            </a>
                            <InputICal value={this.state.iCalendarUrlForWorkAnniversaries.value} copy={this.state.copyiCalendarUrlForWorkAnniversaries} />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    }
}