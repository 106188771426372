import * as React from 'react';
import MyNextHoesTable from '../../../Dashboard/MyNextHoes/Table';

export default class Table extends MyNextHoesTable {
    static ITEMS = 1;
    thread() {
        const t = this.translate();
        return (
            <thead>
                <tr>
                    <th className="border-top-0" colSpan={2}>
                        {t('next.public.holidays.and.events')}
                    </th>
                </tr>
            </thead>
        );
    }
}