import * as React from 'react';
import { doRefreshSession } from '../../Actions/Session';
import Component from '../../Component';

export default class Ordered extends Component {

    public componentDidMount() {
        this.goTo(this.Urls().getCompany());
        doRefreshSession(this.getSession());
    }

    render() {
        const t = this.translate();
        return (
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h5>{t('thank.you.for.subscribing')}</h5>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}