import BaseDownload from '../../Components/BaseDownload';
import { fullNameFactory } from '../../Factories';
import i18n from '../../Reducers/I18n';
import Manager from './Manager';

/* eslint-disable @typescript-eslint/no-unused-expressions */
export default class Download extends BaseDownload {
    constructor(options: any) {
        super(options);
        this.search = Manager.search.bind(this);
    }

    getData() {
        const t = this.t;
        const session = this.getSession().getState() as any;
        const header = [t('employee'), t('age'), t('birthdate'), t('birthday')];
        const arr = [header].concat(this.state.employees.arr.map((item: any) => {
            const ret = [fullNameFactory(item, session.company.nameFormat), item.ageLiteral,
            item.birthDate ? item.birthDate.format(this.getDateFormat()) : null, item.next ? item.next.humanize(true) : null];
            return ret;
        }));
        return arr;
    }

    public isReady() {
        return this.state.employees && this.state.employees.status === 'READY';
    }

    getFileName(this: any): any {
        const t = this.t;
        return this.ansi(t('employees.age')) + this.getSufixFileName();
    }

    translate() {
        return (i18n.getState() as any).translate;
    }
}