import * as $ from 'jquery';
import { NAVIGATE } from '../Constants/ActionTypes';
import ActivateCompany from '../Containers/ActivateCompany/ActivateCompany';
import ActivateEmployee from '../Containers/ActivateEmployee/ActivateEmployee';
import Calendar from '../Containers/Calendar/Calendar';
import Company from '../Containers/Company/Company';
//import ImportExport from '../Containers/Company/ImportExport/ImportExport';
//import ImportExport from '../Containers/Company/ImportExport/ImportExport';
import CompanyRegisteredSuccessfully from '../Containers/CompanyRegisteredSuccessfully/CompanyRegisteredSuccessfully';
import Dashboard from '../Containers/Dashboard/Dashboard';
import Employee from '../Containers/Employee/Employee';
import Employees from '../Containers/Employees/Employees';
import EmployeesAge from '../Containers/EmployeesAge/EmployeesAge';
import EmployeesImportFromSlack from '../Containers/EmployeesImportFromSlack/EmployeesImportFromSlack';
import EmployeesInvite from '../Containers/EmployeesInvite/EmployeesInvite';
import EmployeesReports from '../Containers/EmployeesReports/EmployeesReports';
import EmployeesServiceLength from '../Containers/EmployeesServiceLength/EmployeesYearsOfService';
import ForgotPassword from '../Containers/ForgotPassword/ForgotPassword';
import LeavesToApprove from '../Containers/LeavesToApprove/LeavesToApprove';
import LeaveType from '../Containers/LeaveType/LeaveType';
import LeaveTypeAdd from '../Containers/LeaveTypeAdd/LeaveTypeAdd';
import LeaveTypes from '../Containers/LeaveTypes/LeaveTypes';
import Login from '../Containers/Login/Login';
import Logout from '../Containers/Logout/Logout';
import MyAccount from '../Containers/MyAccount/MyAccount';
import MyLeaves from '../Containers/MyLeaves/MyLeaves';
import NotificationRule from '../Containers/NotificationRule/NotificationRule';
import NotificationRuleAdd from '../Containers/NotificationRuleAdd/NotificationRuleAdd';
import NotificationRules from '../Containers/NotificationRules/NotificationRules';
import OAuth from '../Containers/OAuth/OAuth';
import Office from '../Containers/Office/Office';
import OfficeAdd from '../Containers/OfficeAdd/OfficeAdd';
import Offices from '../Containers/Offices/Offices';
import OrderSubscription from '../Containers/OrderSubscription/OrderSubscription';
import RegisterCompany from '../Containers/RegisterCompany/RegisterCompany';
import Reports from '../Containers/Reports/Reports';
import ResetPassword from '../Containers/ResetPassword/ResetPassword';
import SubscribeNow from '../Containers/SubscribeNow/SubscribeNow';
import Subsidiaries from '../Containers/Subsidiaries/Subsidiaries';
import Subsidiary from '../Containers/Subsidiary/Subsidiary';
import SubsidiaryAdd from '../Containers/SubsidiaryAdd/SubsidiaryAdd';
import Support from '../Containers/Support/Support';
import Unit from '../Containers/Unit/Unit';
import UnitAdd from '../Containers/UnitAdd/UnitAdd';
import Units from '../Containers/Units/Units';
import UserNotAllowed from '../Containers/UserNotAllowed/UserNotAllowed';
import UserNotFound from '../Containers/UserNotFound/UserNotFound';
import Urls from '../Urls';

export const goTo = (router: any, url: string) => {
    ((dispatch, getState) => {
        window.history.pushState({}, 'Aura', url);
        ($ as any)(window).scrollTop(0);
        let component = getComponent(router, url.split('?')[0]);
        if (component === null) {
            goTo(router, Urls.getLogin({ target: url }));
            return;
        }
        dispatch({
            type: NAVIGATE,
            url: url,
            component: component.component,
            logged: component.logged
        });
    })(router.dispatch, router.getState);
};

export const getComponent = (router: any, url: string) => {
    for (let component of Components) {
        if (component.match(url)) {
            return component;
        }
    }
    return null;
    //    return Components[0];
};

export const Components = [
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getLogin()) > -1);
        },
        component: Login,
        logged: false
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getDashboard()) > -1);
        },
        component: Dashboard,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getLocalOAuth()) > -1) || (url.indexOf(Urls.getGoogleOAuth()) > -1) ||
                (url.indexOf(Urls.getOffice365OAuth()) > -1) || (url.indexOf(Urls.getSlackOAuth()) > -1) ||
                (url.indexOf('addedtoslack.html') > -1);
        },
        component: OAuth,
        logged: false
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getUserNotAllowed()) > -1);
        },
        component: UserNotAllowed,
        logged: false
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getUserNotFound()) > -1);
        },
        component: UserNotFound,
        logged: false
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getForgotPassword()) > -1);
        },
        component: ForgotPassword,
        logged: false
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getResetPassword()) > -1);
        },
        component: ResetPassword,
        logged: false
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getLogout()) > -1);
        },
        component: Logout,
        logged: false
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getOverview()) > -1) ||
                (url.indexOf(Urls.getCalendar()) > -1);
        },
        component: Calendar,
        logged: true
    },
    {
        match: (url: string) => { return (url.indexOf(Urls.getEmployees()) > -1); },
        component: Employees,
        logged: true
    },
    {
        match: (url: string) => { return (url.indexOf(Urls.getEmployeesReports()) > -1); },
        component: EmployeesReports,
        logged: true
    },
    {
        match: (url: string) => { return (url.indexOf(Urls.getEmployeesServiceLength()) > -1); },
        component: EmployeesServiceLength,
        logged: true
    },
    {
        match: (url: string) => { return (url.indexOf(Urls.getEmployeesAge()) > -1); },
        component: EmployeesAge,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getEmployeesInvite()) > -1);
        },
        component: EmployeesInvite,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getEmployee()) > -1);
        },
        component: Employee,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getActivateEmployee()) > -1) ||
                (url.indexOf(Urls.getActivateUserToBeDeleted()) > -1);
        },
        component: ActivateEmployee,
        logged: false
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getEmployeesImportFromSlack()) > -1);
        },
        component: EmployeesImportFromSlack,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getUnits()) > -1);
        },
        component: Units,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getUnitAdd()) > -1);
        },
        component: UnitAdd,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getUnit()) > -1);
        },
        component: Unit,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getSubsidiaries()) > -1);
        },
        component: Subsidiaries,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getSubsidiary()) > -1);
        },
        component: Subsidiary,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getSubsidiaryAdd()) > -1);
        },
        component: SubsidiaryAdd,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getOffices()) > -1);
        },
        component: Offices,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getOffice()) > -1);
        },
        component: Office,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getOfficeAdd()) > -1);
        },
        component: OfficeAdd,
        logged: true
    },
    {
        match: (url: string) => { return (url.indexOf(Urls.getLeaveTypes()) > -1); },
        component: LeaveTypes,
        logged: true
    },
    {
        match: (url: string) => { return (url.indexOf(Urls.getLeaveTypeAdd()) > -1); },
        component: LeaveTypeAdd,
        logged: true
    },
    {
        match: (url: string) => { return (url.indexOf(Urls.getLeaveType()) > -1); },
        component: LeaveType,
        logged: true
    },

    {
        match: (url: string) => { return (url.indexOf(Urls.getNotificationRules()) > -1); },
        component: NotificationRules,
        logged: true
    },
    {
        match: (url: string) => { return (url.indexOf(Urls.getNotificationRuleAdd()) > -1); },
        component: NotificationRuleAdd,
        logged: true
    },
    {
        match: (url: string) => { return (url.indexOf(Urls.getNotificationRule()) > -1); },
        component: NotificationRule,
        logged: true
    },

    {
        match: (url: string) => {
            return (url.indexOf(Urls.getRegisterCompany()) > -1);
        },
        component: RegisterCompany,
        logged: false
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getCompanyRegisteredSuccessfully()) > -1);
        },
        component: CompanyRegisteredSuccessfully,
        logged: false
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getReports()) > -1);
        },
        component: Reports,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getCompany()) > -1);
        },
        component: Company,
        logged: true
    },/*
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getImportExport()) > -1);
        },
        component: ImportExport,
        logged: true
    },*/
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getSupport()) > -1);
        },
        component: Support,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getMyAccount()) > -1);
        },
        component: MyAccount,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getSubscribeNow()) > -1);
        },
        component: SubscribeNow,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getMyLeaves()) > -1);
        },
        component: MyLeaves,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getLeavesToApprove()) > -1);
        },
        component: LeavesToApprove,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getOrderSubscription()) > -1);
        },
        component: OrderSubscription,
        logged: true
    },
    {
        match: (url: string) => {
            return (url.indexOf(Urls.getActivateCompany()) > -1);
        },
        component: ActivateCompany,
        logged: false
    },
];