import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import Close from '../../../Components/Close';
import RadioSelectField from '../../../Components/RadioSelectField';
import Submit from '../../../Components/Submit';
import ToggleField from '../../../Components/ToggleField';
import {
    calendarPermissionsOptions,
    reportsPermissionsOptionsForManagers,
    reportsPermissionsOptionsForOfficeManagers
} from '../../../SelectOptionsFactories';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            calendar: { value: this.props.permissions.calendar, },
            reportsForManagers: { value: this.props.permissions.reportsForManagers, },
            reportsForOfficeManagers: { value: this.props.permissions.reportsForOfficeManagers, },
            anonymize: { value: !!this.props.permissions.anonymize, },
            save: { pleaseWait: false }
        };
    }

    render() {
        const t = this.translate();
        const session: any = this.getSession().getState();
        const calendarOptions = calendarPermissionsOptions(t, session);
        const reportsOptionsForManagers = reportsPermissionsOptionsForManagers(t, session);
        const reportsOptionsForOfficeManagers = reportsPermissionsOptionsForOfficeManagers(t);

        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <form onSubmit={(event: any) => { this.submit(event); }}>
                    <fieldset>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <Close onClick={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }} />
                            </div>
                            <div className="col-12 mb-3">
                                <strong>
                                    {t('calendar.access.for.employees')} :
                                </strong>
                                <RadioSelectField
                                    options={calendarOptions}
                                    defaultValue={this.state.calendar.value}
                                    onChange={(value: any) => { this.setState({ calendar: Object.assign({}, this.state.calendar, { value: value }) }); }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <strong>
                                    {t('reports.access.for.managers')} :
                                </strong>
                                <RadioSelectField
                                    options={reportsOptionsForManagers}
                                    defaultValue={this.state.reportsForManagers.value}
                                    onChange={(value: any) => { this.setState({ reportsForManagers: Object.assign({}, this.state.reportsForManagers, { value: value }) }); }}
                                />
                            </div>
                            <div className="col-12 mb-3 d-none">
                                <strong>
                                    {t('reports.access.for.office.managers')} :
                                </strong>
                                <RadioSelectField
                                    options={reportsOptionsForOfficeManagers}
                                    defaultValue={this.state.reportsForOfficeManagers.value}
                                    onChange={(value: any) => { this.setState({ reportsForOfficeManagers: Object.assign({}, this.state.reportsForOfficeManagers, { value: value }) }); }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <ToggleField
                                    defaultValue={this.state.anonymize.value}
                                    tooltip={t('anonymize.leave.types.tip')}
                                    onChange={(value: any) => { this.setState({ anonymize: Object.assign({}, this.state.anonymize, { value: value }) }); }}
                                >
                                    {t('anonymize.leave.types')}
                                </ToggleField>
                            </div>
                            <div className="col-12 mt-3 mb-3">
                                <Submit faIcon="far fa-hdd" pleaseWait={this.state.save.pleaseWait}>
                                    {t('save')}
                                </Submit>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    }

    isFormValid() {
        return true;
    }

    submit(event: any) {
        event.preventDefault();
        if (this.isFormValid()) { this.save(); }
    }

    save() {
        const t = this.translate();
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getCompanyPermissionsUpdate(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                calendar: this.state.calendar.value,
                reports: this.state.reportsForManagers.value,
                anonymize: this.state.anonymize.value,
                //                reportsForOfficeManagers: this.state.reportsForOfficeManagers.value
            }),
            beforeSend: () => {
                this.setState({ save: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('your.company.default.permissions.were.saved'));
                if (this.props.onRefresh) { this.props.onRefresh(); }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.generalErrorToastr();
            },
        });
    }
}