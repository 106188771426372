import * as React from 'react';
import Component from '../../Component';
import DateRangePickerField from '../../Components/DateRangePickerField';
import FiltersSwitch from '../../Components/FiltersSwitch';
import GridOrListSwitch from '../../Components/GridOrListSwitch';
import SelectField from '../../Components/SelectField';
import SortSwitch from '../../Components/SortSwitch';
import { leaveStatusesOptionsFactory, leaveTypesOptionsFactory } from '../../SelectOptionsFactories';
import { Cookie } from '../../Utils';

export default class Filters extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            expanded: false,
            viewMode: this.props.viewMode,
            orderBy: this.props.orderBy,
            leaveStatuses: {
                value: this.props.defaultLeaveStatuses,
                defaultValue: this.props.defaultLeaveStatuses,
                touched: false,
            },
            leaveTypes: {
                value: this.props.defaultLeaveTypes,
                defaultValue: this.props.defaultLeaveTypes,
                touched: false,
            },
            startDate: { value: this.props.defaultStartDate },
            endDate: { value: this.props.defaultEndDate },
            users: {
                value: this.props.defaultUsers
            }
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange() { if (this.props.onChange) { this.props.onChange(this.state); } }

    render() {
        const t = this.translate();
        const moment = this.getMomentWithLocale();
        const leaveStatusOptions = leaveStatusesOptionsFactory(t);
        const caches: any = this.getCaches().getState();
        const leaveTypesOptions = leaveTypesOptionsFactory(
            caches.leaveTypes.arr,
            this.language());
        return (
            <form onSubmit={(event: any) => { event.preventDefault(); }}>
                <div className="row">
                    {
                        this.state.expanded ? null :
                            <div className="col-12 col-lg-6 mb-3">
                                <DateRangePickerField
                                    startDate={this.state.startDate.value}
                                    endDate={this.state.endDate.value}
                                    minimumDate={moment().subtract(10, 'year')}
                                    maximumDate={moment().add(10, 'year')}
                                    touched={this.state.startDate.touched}
                                    placeholder={t('range')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            {
                                                startDate: Object.assign({}, this.state.startDate, { value: value.startDate, touched: true }),
                                                endDate: Object.assign({}, this.state.startDate, { value: value.endDate })
                                            },
                                            this.onChange
                                        );
                                    }}
                                />
                            </div>
                    }
                    <div className={'col-12' + (this.state.expanded ? '' : ' col-lg-6') + ' mb-3'}>
                        <div className="float-right mt-1">
                            <SortSwitch
                                title={t('date')}
                                order={this.state.orderBy.order}
                                onChange={(order: any) => {
                                    this.setState(
                                        { orderBy: { field: 'DATE', order: order } },
                                        this.onChange
                                    );
                                }}
                            />
                            <GridOrListSwitch
                                viewMode={this.state.viewMode}
                                onChange={(value: any) => {
                                    this.setState({ viewMode: value }, () => { this.onChange(); Cookie.create(Cookie.MY_LEAVES_VIEWMODE, value); });
                                }}
                            />
                            <FiltersSwitch
                                expanded={this.state.expanded}
                                onClick={() => { this.setState({ expanded: !this.state.expanded }); }}
                            />
                        </div>
                    </div>
                </div>
                {
                    (this.state.expanded) ?
                        (
                            <div className="row">
                                <div className="col-12 col-lg-6 mb-3">
                                    <DateRangePickerField
                                        startDate={this.state.startDate.value}
                                        endDate={this.state.endDate.value}
                                        minimumDate={moment().subtract(10, 'year')}
                                        maximumDate={moment().add(10, 'year')}
                                        touched={this.state.startDate.touched}
                                        placeholder={t('range')}
                                        onChange={(value: any) => {
                                            this.setState(
                                                {
                                                    startDate: Object.assign({}, this.state.startDate, { value: value.startDate, touched: true }),
                                                    endDate: Object.assign({}, this.state.startDate, { value: value.endDate })
                                                },
                                                this.onChange
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-12 col-lg-6 mb-3">
                                    <SelectField
                                        defaultValue={this.state.leaveStatuses.value}
                                        touched={this.state.leaveStatuses.touched}
                                        options={leaveStatusOptions}
                                        multiple={true}
                                        placeholder={t('status')}
                                        maxDisplayedOptions={3}
                                        onChange={(value: any) => {
                                            this.setState(
                                                { leaveStatuses: Object.assign({}, this.state.leaveStatuses, { value }) },
                                                this.onChange
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-12 col-lg-6 mb-3">
                                    <SelectField
                                        defaultValue={this.state.leaveTypes.value}
                                        touched={this.state.leaveTypes.touched}
                                        options={leaveTypesOptions}
                                        multiple={true}
                                        placeholder={t('leave.types')}
                                        maxDisplayedOptions={3}
                                        onChange={(value: any) => {
                                            this.setState(
                                                { leaveTypes: Object.assign({}, this.state.leaveTypes, { value }) },
                                                this.onChange
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                        )
                        :
                        undefined
                }
            </form>
        );
    }
}