import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../Component';
import Line from '../../Components/Line';
import { fullNameFactory } from '../../Factories';
import { EMPLOYEETABS, STATUS } from '../../General';
import { getURLParameter } from '../../Utils';
import Allowances from './Allowances/Allowances';
import Header from './Header/Header';
import Leaves from './Leaves/Leaves';
import PersonalDetails from './PersonalDetails/PersonalDetails';
import Profile from './Profile/Profile';
import Tabs from './Tabs';
import TimeOff from './TimeOff/TimeOff';
import YearView from './YearView/YearView';

export default class Employee extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            employee: {
                value: undefined,
                status: STATUS.LOADING
            },
            currentTab: this.getCurrentTab(EMPLOYEETABS.PROFILE, EMPLOYEETABS),
        };
    }

    componentDidMount() { this.getEmployee(); }

    getEmployee() {
        this.setState(
            { employee: { value: this.state.employee.value, status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getEmployee(getURLParameter('id')),
                    dataType: 'json',
                    cache: false,
                    success: (data, textStatus, jqXHR) => {
                        data.office = data.calendar;
                        const session = this.getSession().getState() as any;
                        data.fullName = fullNameFactory(data, session.company.nameFormat);
                        this.setState({
                            employee: {
                                value: data,
                                status: STATUS.READY
                            }
                        });
                    },
                    error: (jqXHR, textStatus, errorThrown) => {
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                        this.setState({
                            employee: {
                                value: undefined,
                                status: STATUS.ERROR
                            },
                        });
                    },
                });
            }
        );
    }

    render() {
        const session: any = this.getSession().getState();
        const caches: any = this.getCaches().getState();
        let ready = session.status === STATUS.READY &&
            this.state.employee.status === STATUS.READY &&
            caches.users.status === STATUS.READY &&
            caches.units.status === STATUS.READY &&
            caches.offices.status === STATUS.READY &&
            caches.subsidiaries.status === STATUS.READY &&
            caches.timezones.status === STATUS.READY &&
            caches.countries.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (
            <div className="container-fluid">
                <Header
                    employee={this.state.employee.value}
                    currentTab={this.state.currentTab}
                />
                <div className="row">
                    <div className="col-12 mb-3">
                        <Line />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <Tabs currentTab={this.state.currentTab} onChange={(value: any) => { this.setState({ currentTab: value }); }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        {this.getTab()}
                    </div>
                </div>
            </div>
        );
    }


    getTab() {
        switch (this.state.currentTab) {
            case EMPLOYEETABS.LEAVES:
                return (<Leaves employee={this.state.employee.value} />);
            case EMPLOYEETABS.CALENDAR:
                return (<YearView employee={this.state.employee.value} />);
            case EMPLOYEETABS.TIMEOFF:
                return (<TimeOff employee={this.state.employee.value} />);
            case EMPLOYEETABS.ALLOWANCES:
                return (<Allowances employee={this.state.employee.value} />);
            case EMPLOYEETABS.PERSONAL_DETAILS:
                return (<PersonalDetails employee={this.state.employee.value} />);
            default:
                return (<Profile employee={this.state.employee.value} onRefresh={() => { this.getEmployee(); }} />);
        }
    }
}