import * as React from 'react';
import Component from '../../Component';
import InputField from '../../Components/InputField';

export default class Filters extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            viewMode: this.props.viewMode,
            orderBy: this.props.orderBy,
            search: {
                touched: false
            }
        };
    }

    onChange() {
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }
    }

    render() {
        const t = this.translate();
        return (
            <div>
                <form onSubmit={(event: any) => { event.preventDefault(); }}>
                    <div className="row">
                        <div className="col-7 mb-3">
                            <InputField
                                placeholder={t('search')}
                                onChange={(value: any) => {
                                    this.setState(
                                        { search: Object.assign({}, this.state.search, { value: value }) },
                                        () => { this.onChange(); });
                                }}
                            />
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    /*
                            <div className="col-5 mb-3">
                            <div className="float-right mt-2">
                                <SortSwitch
                                    title={t('name')}
                                    order={this.state.orderBy.order}
                                    onChange={(order: any) => {
                                        this.setState(
                                            { orderBy: { field: 'NAME', order: order } },
                                            () => { this.onChange(); }
                                        );
                                    }}
                                />
                                <GridOrListSwitch
                                    viewMode={this.state.viewMode}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { viewMode: value },
                                            () => { this.onChange(); }
                                        );
                                    }}
                                />
                            </div>
                        </div>

    */
}