import * as React from 'react';
import Component from '../../../Component';
import { filtersFormUrlFactory } from '../../../Factories';
import { ORDER, STATUS } from '../../../General';
import { Jqxhrs } from '../../../Utils';
import EmployeesAgeManger from '../../EmployeesAge/Manager';
import Table from './Table';

export default class Manager extends Component {
    protected jqxhrs = new Jqxhrs();
    protected search: any;
    constructor(props?: any) {
        super(props);
        this.state = {
            filters: this.createFilters(),
            employees: { arr: [], status: STATUS.LOADING },
            status: STATUS.LOADING,
        };
        this.search = EmployeesAgeManger.search.bind(this)
    }

    createFilters() {
        const moment = this.getMomentWithLocale();
        let filters = {
            sortBy: { field: { value: 'BIRTHDAY' }, order: ORDER.ASC },
            startDate: { value: moment().startOf('day') },
            endDate: { value: moment().startOf('day').add(1, 'y') },
        };
        filters = Object.assign({}, filters, filtersFormUrlFactory());
        filters = Object.assign({}, filters, this.props.filters);
        return filters;
    }

    componentDidMount() { this.search(5); }

    render() {
        return <div className="card h-100">
            <div className="card-body" >
                <Table employees={this.state.employees} />
            </div>
        </div>
    }

    public componentWillUnmount() { this.jqxhrs.abortAll(); }
}