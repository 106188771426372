import * as React from 'react';
import Component from '../../../Component';
import Display from './Display';
import Form from './Form';

export default class Profile extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            editMode: false
        };
    }

    render() {
        return <div>
            {this.state.editMode ?
                <Form employee={this.props.employee}
                    onRefresh={() => {
                        if (this.props.onRefresh) { this.props.onRefresh(); }
                        this.setState({ editMode: !this.state.editMode });
                    }}
                />
                : <Display employee={this.props.employee}
                    onEdit={() => { this.setState({ editMode: !this.state.editMode }); }}
                />
            }
        </div>
    }
}