import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../Component';
import A from '../../Components/A';
import ETOL from '../../Components/ETOL';
import Line from '../../Components/Line';
import { fullNameFactory } from '../../Factories';
import { ETOLTABS, ORDER, STATUS, VIEWMODE } from '../../General';
import Filters from './Filters';
import List from './List';

export default class SubsidiariesManager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            filters: {
                search: { value: '' },
                viewMode: VIEWMODE.GRID,
                orderBy: { field: 'NAME', order: ORDER.ASC }
            },
            subsidiaries: {
                arr: [],
                status: STATUS.LOADING
            }
        };
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState(
            { subsidiaries: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getSubsidiaries({ filters: this.state.filters }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            {
                                subsidiaries: {
                                    arr: this.prepareOffices(data.data).sort((a: any, b: any) => {
                                        if (this.state.filters.orderBy.order === ORDER.DESC)
                                            return b.name.toLowerCase().localeCompare(a.name.toLowerCase(), 'en', { sensitivity: 'base' });
                                        else
                                            return a.name.toLowerCase().localeCompare(b.name.toLowerCase(), 'en', { sensitivity: 'base' });
                                    }),
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                offices: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        const t = this.translate();
        return (
            <div className="container-fluid">
                <div className="row mb-3">
                    <div className="col-12">
                        <ETOL currentTab={ETOLTABS.SUBSIDIARIES} />
                        <A
                            faIcon="fa fa-plus"
                            href={this.Urls().getSubsidiaryAdd()}
                            className="btn btn-outline-primary float-right"
                            title={t('add.subsidiary')}
                        >
                            {this.isScreenSizeSmall() ? undefined : t('add.subsidiary')}
                        </A>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <Line />
                    </div>
                </div>
                <Filters
                    viewMode={this.state.filters.viewMode}
                    orderBy={this.state.filters.orderBy}
                    onChange={(value: any) => {
                        this.setState({ filters: Object.assign({}, value) }, () => { this.search(); });
                    }}
                />
                <div className="row">
                    <List subsidiaries={this.state.subsidiaries}
                        viewMode={this.state.filters.viewMode}
                        onRefresh={() => { this.search(); }} />
                </div>
            </div>
        );
    }

    prepareOffices(subsidiaries: any[]) {
        const users = (this.getCaches().getState() as any).users;
        return subsidiaries.map((subsidiary) => {
            subsidiary.members = [];
            subsidiary.members = users.arr.filter((user: any) => {
                return user?.subsidiary?.id && (user.subsidiary.id === subsidiary.id && (user.status === 'ACTIVE' || user.status === 'INVITED')) ? true : false;
            }).sort((a: any, b: any) => {
                return a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
            })
            const session = this.getSession().getState() as any;
            if (subsidiary?.mnager?.id) {
                subsidiary.manager.fullName = fullNameFactory(subsidiary.manager, session.company.nameFormat);
            }
            return subsidiary;
        });
    }
}