import * as React from 'react';

export default class Button extends React.Component<any, any> {
    render() {
        return (
            <button
                type={this.props.type}
                tabIndex={this.props.tabIndex}
                className={this.props.className}
                disabled={this.props.disabled}
                onClick={(event: any) => {
                    if (this.props.onClick) {
                        this.props.onClick(event);
                    }
                }}
            >
                {
                    this.props.faIcon ?
                        (
                            <i
                                className={(this.props.children ? 'mr-1 fa ' : 'fa ') + this.props.faIcon}
                                aria-hidden="true"
                            />
                        )
                        :
                        undefined
                }
                {this.props.children}
                {(this.props.blocked ? <span>...</span> : '')}
            </button >
        );
    }
}