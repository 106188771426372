import * as React from 'react';
import Component from '../../Component';
import { fullNameFactory } from '../../Factories';

export default class LeaveEmployee extends Component {
    render() {
        const employee = this.props.employee;
        const session = this.getSession().getState() as any;
        return <span>
            {fullNameFactory(employee, session.company.nameFormat)}
        </span>
    }
}