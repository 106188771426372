import * as React from 'react';
import Component from '../../../Component';
import A from '../../../Components/A';
import Error from '../../../Components/Error';
import { STATUS } from '../../../General';
import { copyArray } from '../../../Utils';
import Item from './Item';

export default class Table extends Component {
    static ITEMS = 2;
    render() {
        const leaves = this.props.leaves;
        switch (leaves.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                let totalAmout = 0;
                const leavesComps = ((leaves.arr.length > Table.ITEMS) ?
                    copyArray(leaves.arr, 0, Table.ITEMS - 1) : leaves.arr).map((leave: any) => {
                        totalAmout += leave.amount;
                        return (
                            <Item key={'DashBoardMyNextLeavesTable-' + leave.id} leave={leave} />
                        );
                    });
                return (
                    <table
                        className="table table-sm w-100 m-0 p-0"
                        style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}
                    >
                        {this.thead(totalAmout)}
                        <tbody>
                            {leavesComps}
                            {
                                (leaves.arr.length > Table.ITEMS) ?
                                    (
                                        <tr>
                                            <td>
                                                <div className="float-right">
                                                    <A
                                                        className="text-muted"
                                                        href={this.Urls().getMyLeaves({ filters: this.props.filters })}
                                                        title={t('more')}
                                                    >
                                                        {t('more')}
                                                    </A>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                    :
                                    undefined
                            }
                        </tbody>
                    </table>
                );
            }
            default:
                return (<Error />);
        }
    }

    getTextAmount(amount: any) {
        const t = this.translate();
        if (amount === 0.5) {
            return t('half.a.day');

        }
        if (amount === 1) {
            return t('a.day');

        }
        return amount + ' ' + t('days').toLowerCase();
    }

    thead(totalAmout: any) {
        const t = this.translate();
        return (
            <thead>
                <tr>
                    <th className="border-top-0">
                        {t('my.next.leaves')}
                        <span className="float-right font-weight-light text-secondary">
                            {this.getTextAmount(totalAmout)}
                        </span>
                    </th>
                </tr>
            </thead>
        );
    }
}