import * as React from 'react';
import Component from '../../../../Component';

export default class Table extends Component {

    render() {
        const t = this.translate();
        const leaveTypeAmountsPerYear = this.props.leaveTypeAmountsPerYear.value;
        const trs: any = [];
        leaveTypeAmountsPerYear.forEach((row: any) => {
            trs.push((
                <tr
                    key={'AllowanceTableTr-' + row[0]}
                    className={(row[3] - row[1] - row[2]) < 0 ? 'text-danger' : ''}
                >
                    <td className="">
                        {Table.getDays(row[3] - row[1], t)}
                    </td>
                    <td className="text-right" style={{ width: '50%' }}>
                        {Table.getDays(row[3] - row[1] - row[2], t)}
                    </td>
                </tr>
            ));
        });
        return <table
            className="table table-sm w-100 m-0 p-0"
            style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}
        >
            {this.thead()}
            <tbody>
                {trs}
            </tbody>
        </table>
    }

    thead() {
        const t = this.translate();
        return <thead>
            <tr>
                <th className="border-top-0" style={{ width: '50%' }}>
                    {t('allowance')}
                </th>
                <th className="border-top-0 text-right" style={{ width: '50%' }}>
                    {t('if.approved')}
                </th>
            </tr>
        </thead>
    }

    static getDays(days: any, t: any) {
        return (days === 1) ? (<span>{t('one.day')}</span>) : (<span>{days}{' ' + t('days')}</span>);
    }
}