import BaseDownload from '../../Components/BaseDownload';
import { fullNameFactory } from '../../Factories';
import Manager from './Manager';

/* eslint-disable @typescript-eslint/no-unused-expressions */
export default class Download extends BaseDownload {
    constructor(options: any) {
        super(options);
        this.search = Manager.search.bind(this);
    }

    getData() {
        const t = this.t;
        const session = this.getSession().getState() as any;
        const header = [t('employee'), t('employment.date'), t('service.length'), t('next.service.year.in')];
        const arr = [header].concat(this.state.employees.arr.map((item: any) => {
            const ret = [fullNameFactory(item, session.company.nameFormat), item.employmentStartDate ? item.employmentStartDate.format(this.getDateFormat()) : null,
            item.service ? item.service.humanize(false) : '',
            item.todayIsNewYearOfService && item.service && item.service.valueOf() > 0 ? t('today') :
                (item.next && item.next.valueOf() > 0 ?
                    item.next.humanize(true) : null)];
            return ret;
        }));
        return arr;
    }

    public isReady() {
        return this.state.employees && this.state.employees.status === 'READY';
    }

    getFileName(this: any): any {
        const t = this.t;
        return this.ansi(t('employees.service.length')) + this.getSufixFileName();
    }
}