import * as React from 'react';
import * as $ from 'jquery';
import 'moment';
import * as moment from 'moment-timezone';
import { sessionLogin } from '../../Actions/Session';
import Component from '../../Component';
import SelectField from '../../Components/SelectField';
import Submit from '../../Components/Submit';
import {
    countriesOptionsFactory, daysForMonthOptionsFactory, monthsOptionsFactory, timezonesOptionsFactory
} from '../../SelectOptionsFactories';
//import cal from './country_annual_leave.json';
import Welcome from './Welcome';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            country: {
                value: [this.props.activateCompany.companyUser.country],
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            month: {
                value: [0],
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            day: {
                value: [1],
                touched: false,
                rules: this.Rules().BasicSelectRules(),
            },
            activate: {
                pleaseWait: false
            },
            timezone: {
                value: [moment.tz.guess()],
                rules: this.Rules().BasicSelectRules(),
                touched: false,
            },
        };
    }

    render() {
        const caches: any = this.getCaches().getState();
        const t = this.translate();
        const countriesOptions = countriesOptionsFactory(caches.countries.arr[this.language()]);
        const monthsOptions = monthsOptionsFactory();
        const daysOptions = daysForMonthOptionsFactory(this.state.month.value[0]);
        const timezonesOptions = timezonesOptionsFactory(caches.timezones.arr);
        return (
            <div className="card h-100">
                <div className="card-body">
                    <form onSubmit={(event: any) => { this.submit(event); }}>
                        <fieldset>
                            <div className="row">
                                <div className="col-12 mb-3 mt-3">
                                    <Welcome
                                        activateCompany={this.props.activateCompany}
                                    />
                                </div>
                                <div className="col-12 mb-3 d-none">
                                    <SelectField
                                        defaultValue={this.state.country.value}
                                        touched={this.state.country.touched}
                                        options={countriesOptions}
                                        multiple={false}
                                        rules={this.state.country.rules}
                                        placeholder={t('country')}
                                        onChange={(value: any) => {
                                            this.setState(
                                                { country: Object.assign({}, this.state.country, { value: value }) }
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3 d-none">
                                    <h5 className="m-0">{t('business.year.starts.on')}</h5>
                                </div>
                                <div className="col-6 mb-3 d-none">
                                    <SelectField
                                        defaultValue={this.state.month.value}
                                        touched={this.state.month.touched}
                                        options={monthsOptions}
                                        rules={this.state.month.rules}
                                        placeholder={t('month')}
                                        onChange={(value: any) => {
                                            this.setState(
                                                {
                                                    month: Object.assign({}, this.state.month, { value: value }),
                                                    day: Object.assign({}, this.state.day)
                                                }
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-6 mb-3 d-none">
                                    <SelectField
                                        defaultValue={this.state.day.value}
                                        touched={this.state.day.touched}
                                        options={daysOptions}
                                        rules={this.state.day.rules}
                                        placeholder={t('day')}
                                        onChange={(value: any) => {
                                            this.setState(
                                                {
                                                    day: Object.assign({}, this.state.day, { value: value })
                                                }
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3 d-none">
                                    <h5 className="m-0">{t('please.select.your.timezone')}</h5>
                                </div>
                                <div className="col-12 mb-3 d-none">
                                    <SelectField
                                        defaultValue={this.state.timezone.value}
                                        touched={this.state.timezone.touched}
                                        options={timezonesOptions}
                                        rules={this.state.timezone.rules}
                                        placeholder={t('timezone')}
                                        onChange={(value: any) => {
                                            this.setState({ timezone: Object.assign({}, this.state.timezone, { value: value }) });
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <Submit
                                        faIcon="far fa-hdd"
                                        disabled={!this.isFormValid()}
                                        pleaseWait={this.state.activate.pleaseWait}
                                    >
                                        {t('activate.company')}
                                    </Submit>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        );
    }

    isFormValid() {
        return this.Validator().validate(this.state.country)
            && this.Validator().validate(this.state.month)
            && this.Validator().validate(this.state.day)
            && this.Validator().validate(this.state.timezone);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                country: Object.assign({}, this.state.country, { touched: true }),
                month: Object.assign({}, this.state.month, { touched: true }),
                day: Object.assign({}, this.state.day, { touched: true }),
                timezone: Object.assign({}, this.state.timezone, { touched: true })
            },
            () => {
                if (this.isFormValid()) {
                    this.activate();
                }
            }
        );
    }

    activate() {
        const t = this.translate();
        const standardWeekDaysAndHours = [
            {
                day: 0,
                working: false,
                first: { start: { hour: 0, minute: 0 }, end: { hour: 11, minute: 59 } },
                second: { start: { hour: 12, minute: 0 }, end: { hour: 23, minute: 59 } }
            },
            {
                day: 1,
                working: true,
                first: { start: { hour: 9, minute: 0 }, end: { hour: 12, minute: 59 } },
                second: { start: { hour: 14, minute: 0 }, end: { hour: 17, minute: 59 } }
            },
            {
                day: 2,
                working: true,
                first: { start: { hour: 9, minute: 0 }, end: { hour: 12, minute: 59 } },
                second: { start: { hour: 14, minute: 0 }, end: { hour: 17, minute: 59 } }
            },
            {
                day: 3,
                working: true,
                first: { start: { hour: 9, minute: 0 }, end: { hour: 12, minute: 59 } },
                second: { start: { hour: 14, minute: 0 }, end: { hour: 17, minute: 59 } }
            },
            {
                day: 4,
                working: true,
                first: { start: { hour: 9, minute: 0 }, end: { hour: 12, minute: 59 } },
                second: { start: { hour: 14, minute: 0 }, end: { hour: 17, minute: 59 } }
            },
            {
                day: 5,
                working: true,
                first: { start: { hour: 9, minute: 0 }, end: { hour: 12, minute: 59 } },
                second: { start: { hour: 14, minute: 0 }, end: { hour: 17, minute: 59 } }
            },
            {
                day: 6,
                working: false,
                first: { start: { hour: 0, minute: 0 }, end: { hour: 11, minute: 59 } },
                second: { start: { hour: 12, minute: 0 }, end: { hour: 23, minute: 59 } }
            },
        ];
        const sundayThursdayWeekDaysAndHours = [
            {
                day: 0,
                working: true,
                first: { start: { hour: 9, minute: 0 }, end: { hour: 12, minute: 59 } },
                second: { start: { hour: 14, minute: 0 }, end: { hour: 17, minute: 59 } }
            },
            {
                day: 1,
                working: true,
                first: { start: { hour: 9, minute: 0 }, end: { hour: 12, minute: 59 } },
                second: { start: { hour: 14, minute: 0 }, end: { hour: 17, minute: 59 } }
            },
            {
                day: 2,
                working: true,
                first: { start: { hour: 9, minute: 0 }, end: { hour: 12, minute: 59 } },
                second: { start: { hour: 14, minute: 0 }, end: { hour: 17, minute: 59 } }
            },
            {
                day: 3,
                working: true,
                first: { start: { hour: 9, minute: 0 }, end: { hour: 12, minute: 59 } },
                second: { start: { hour: 14, minute: 0 }, end: { hour: 17, minute: 59 } }
            },
            {
                day: 4,
                working: true,
                first: { start: { hour: 9, minute: 0 }, end: { hour: 12, minute: 59 } },
                second: { start: { hour: 14, minute: 0 }, end: { hour: 17, minute: 59 } }
            },
            {
                day: 5,
                working: false,
                first: { start: { hour: 0, minute: 0 }, end: { hour: 11, minute: 59 } },
                second: { start: { hour: 12, minute: 0 }, end: { hour: 23, minute: 59 } }
            },
            {
                day: 6,
                working: false,
                first: { start: { hour: 0, minute: 0 }, end: { hour: 11, minute: 59 } },
                second: { start: { hour: 12, minute: 0 }, end: { hour: 23, minute: 59 } }
            },
        ];
        const sundayThursdayCountries = ['DZ', 'AF', 'BH', 'BD', 'EG', 'IQ', 'JO', 'KW', 'LY', 'MV', 'OM', 'PS', 'QA', 'SA', 'SD', 'SY', 'AE', 'YE'];
        let weekDaysAndHours = sundayThursdayCountries.indexOf(this.state.country.value[0]) > -1 ? sundayThursdayWeekDaysAndHours : standardWeekDaysAndHours;
        let countryISO = Array.isArray(this.state.country.value) ? this.state.country.value[0] : this.state.country.value;
        const activation = {
            holidaysAndEvents: [],
            token: this.props.activateCompany.token,
            email: this.props.activateCompany.companyUser.email,
            country: this.state.country.value,
            countryISO: this.state.country.value,
            name: this.props.activateCompany.name,
            businessYearStart: {
                month: this.state.month.value[0],
                day: this.state.day.value[0]
            },
            companyUsers: [this.props.activateCompany.companyUser],
            weekDaysAndHours,
            leaveTypes: [
                {
                    id: 1,
                    color: '#88B14B',
                    names: [{
                        lang: this.language(),
                        value: (countryISO === 'US' ? t('pto') : (countryISO === 'GB' ? t('holiday') : t('vacation')))
                    }],
                    halfADayAllowed: true,
                    transferOverYearAllowed: true,
                    allocationEnforced: false,
                    active: true,
                    registerInThePast: false,
                    //                  allocation: !(cal as any)[this.state.country.value] ? 10 : (cal as any)[this.state.country.value],
                    allocation: this.getVacationPerCountry(countryISO),
                },
                {
                    id: 2,
                    color: '#EF562D',
                    names: [{
                        lang: this.language(),
                        value: t('sick.leave')
                    }],
                    halfADayAllowed: true,
                    transferOverYearAllowed: false,
                    allocationEnforced: false,
                    active: true,
                    registerInThePast: false,
                    allocation: this.getSickDaysPerCountry(countryISO),
                    attachmentsAllowed: false
                },
                {
                    id: 3,
                    color: '#D1AF94',
                    names: [{
                        lang: this.language(),
                        value: t('unpaid.leave')
                    }],
                    halfADayAllowed: true,
                    transferOverYearAllowed: false,
                    allocationEnforced: false,
                    active: true,
                    registerInThePast: false,
                    allocation: 365
                }
            ]
        };
        delete activation.country;
        delete activation.countryISO;
        delete activation.name;
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getCompanyActivate(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify(activation),
            success: (data: any, textStatus: any, jqXHR: any) => {
                window.setTimeout(
                    () => {
                        this.successToastr(t('your.company.was.activated'));
                        sessionLogin(this.getSession(), data.user, data.companyUser, data.company, data.permissions);
                        this.setState({ add: { pleaseWait: false } });
                        this.goToDashboard();
                    },
                    1000
                );
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            },
            complete: (jqXHR, textStatus) => {
                this.setState({ add: { pleaseWait: false } });
            }
        });
    }
    public getSickDaysPerCountry(countryISO: any) {
        switch (countryISO) {
            case 'AU': return 10;
            case 'US': return 10;
            default: return 60;
        }
    }
    public getVacationPerCountry(countryISO: any) {
        switch (countryISO) {
            case 'AF': return 20;//Afghanistan
            case 'AL': return 20;//Albania
            case 'DZ': return 30;//Algeria
            case 'AD': return 31;//Andorra
            case 'AO': return 22;//Angola
            case 'AG': return 12;//Antigua and Barbuda
            case 'AR': return 10;//Argentina
            case 'AT': return 25;//Austria
            case 'AU': return 20;//Australia
            case 'AM': return 20;//Armenia
            case 'AZ': return 21;//Azerbaijan
            case 'BS': return 10;//Bahamas
            case 'BH': return 30;//Bahrain
            case 'BD': return 10;//Bangladesh
            case 'BB': return 15;//Barbados
            case 'BY': return 24;//Belarus
            case 'BE': return 20;//Belgium
            case 'BZ': return 14;//Belize
            case 'BJ': return 24;//Benin
            case 'BT': return 9;//Bhutan
            case 'BO': return 15;//Bolivia
            case 'BA': return 20;//Bosnia and Herzegovina
            case 'BR': return 22;//Brazil
            case 'BW': return 15;//Botswana
            case 'BG': return 20;//Bulgaria
            case 'BF': return 30;//Burkina Faso
            case 'BI': return 17;//Burundi
            case 'CV': return 22;//Cape Verde
            case 'KH': return 18;//Cambodia
            case 'CM': return 18;//Cameroon
            case 'CA': return 10;//Canada
            case 'CF': return 24;//Central African Republic
            case 'TD': return 24;//Chad
            case 'CL': return 15;//Chile
            case 'CN': return 5;//China
            case 'CO': return 15;//Colombia
            case 'KM': return 25;//Comoros
            case 'CD': return 12;//Democratic Republic of Congo
            case 'CG': return 26;//Republic of Congo
            case 'CR': return 10;//Costa Rica
            case 'HR': return 20;//Croatia
            case 'CU': return 22;//Cuba
            case 'CY': return 20;//Cyprus
            case 'CZ': return 20;//Czech Republic
            case 'DK': return 25;//Denmark
            case 'DJ': return 25;//Djibouti
            case 'DM': return 10;//Dominica
            case 'DO': return 10;//Dominican Republic
            case 'EC': return 11;//Ecuador
            case 'EG': return 21;//Egypt
            case 'SV': return 15;//El Salvador
            case 'GQ': return 30;//Equatorial Guinea
            case 'ER': return 12;//Eritrea
            case 'EE': return 28;//Estonia
            case 'ET': return 12;//Ethiopia
            case 'FJ': return 10;//Fiji
            case 'FI': return 25;//Finland
            case 'FR': return 25;//France
            case 'GA': return 20;//Gabon
            case 'GM': return 21;//Gambia
            case 'GE': return 24;//Georgia
            case 'DE': return 20;//Germany
            case 'GH': return 15;//Ghana
            case 'GR': return 20;//Greece
            case 'GT': return 15;//Guatemala
            case 'GN': return 22;//Guinea
            case 'GW': return 22;//Guinea - Bissau
            case 'GY': return 12;//Guyana
            case 'HT': return 11;//Haiti
            case 'HN': return 8;//Honduras
            case 'HJ': return 7;//Hong Kong
            case 'HU': return 20;//Hungary
            case 'IS': return 24;//Iceland
            case 'IN': return 25;//India
            case 'ID': return 12;//Indonesia
            case 'IR': return 20;//Iran
            case 'IE': return 20;//Ireland
            case 'IL': return 20;//Israel
            case 'IT': return 20;//Italy
            case 'CI': return 20;//Ivory Coast
            case 'JM': return 10;//Jamaica
            case 'JP': return 10;//Japan
            case 'JE': return 10;//Jersey
            case 'JO': return 14;//Jordan
            case 'KZ': return 24;//Kazakhstan
            case 'KE': return 21;//Kenya
            case 'KW': return 30;//Kuwait
            case 'KG': return 20;//Kyrgyzstan
            case 'LA': return 15;//Laos
            case 'LB': return 15;//Lebanon
            case 'LS': return 12;//Lesotho
            case 'LR': return 10;//Liberia
            case 'LY': return 20;//Libya
            case 'LT': return 20;//Lithuania
            case 'LU': return 26;//Luxembourg
            case 'MG': return 22;//Madagascar
            case 'MW': return 18;//Malawi
            case 'MY': return 8;//Malaysia
            case 'MV': return 22;//Maldives
            case 'ML': return 22;//Mali
            case 'MT': return 27;//Malta
            case 'MR': return 15;//Mauritania
            case 'MU': return 22;//Mauritius
            case 'MX': return 6;//Mexico
            case 'MD': return 6;//Moldova
            case 'MN': return 15;//Mongolia
            case 'ME': return 21;//Montenegro
            case 'MA': return 15;//Morocco
            case 'MZ': return 10;//Mozambique
            case 'MM': return 10;//Myanmar
            case 'NA': return 20;//Namibia
            case 'NP': return 15;//Nepal
            case 'NL': return 20;//Netherlands
            case 'NZ': return 20;//New Zealand
            case 'NI': return 11;//Nicaragua
            case 'NE': return 22;//Niger
            case 'NG': return 5;//Nigeria
            case 'MK': return 20;//North Macedonia
            case 'NO': return 25;//Norway
            case 'OM': return 22;//Oman
            case 'PK': return 14;//Pakistan
            case 'PS': return 21;//Palestine
            case 'PA': return 30;//Panama
            case 'PG': return 10;//Papua New Guinea
            case 'PY': return 10;//Paraguay
            case 'PE': return 30;//Peru
            case 'PH': return 5;//Philippines
            case 'PL': return 26;//Poland
            case 'PT': return 22;//Portugal
            case 'PR': return 15;//Puerto Rico
            case 'QA': return 15;//Qatar
            case 'RO': return 21;//Romania
            case 'RU': return 20;//Russia
            case 'RW': return 15;//Rwanda
            case 'WS': return 10;//Samoa
            case 'KN': return 12;//Saint Kitts and Nevis
            case 'LC': return 14;//Saint Lucia
            case 'VC': return 16;//Saint Vincent and the Grenadines
            case 'SM': return 10;//San Marino
            case 'ST': return 25;//São Tomé and Príncipe
            case 'SA': return 21;//Saudi Arabia
            case 'SN': return 20;//Senegal
            case 'RS': return 20;//Serbia
            case 'SC': return 21;//Seychelles
            case 'SL': return 18;//Sierra Leone
            case 'SG': return 7;//Singapore
            case 'SK': return 20;//Slovakia
            case 'SI': return 20;//Slovenia
            case 'SB': return 15;//Solomon Islands
            case 'SO': return 13;//Somalia
            case 'ZA': return 15;//South Africa
            case 'KR': return 15;//South Korea
            case 'SS': return 20;//South Sudan
            case 'LK': return 22;//Sri Lanka
            case 'SD': return 20;//Sudan
            case 'SR': return 12;//Suriname
            case 'SZ': return 10;//Swaziland
            case 'SE': return 25;//Sweden
            case 'CH': return 20;//Switzerland
            case 'SY': return 24;//Syria
            case 'TW': return 10;//Taiwan
            case 'TZ': return 20;//Tanzania
            case 'TH': return 6;//Thailand
            case 'TL': return 12;//East Timor
            case 'TG': return 22;//Togo
            case 'TT': return 10;//Trinidad and Tobago
            case 'TN': return 10;//Tunisia
            case 'TR': return 12;//Turkey
            case 'UA': return 24;//Ukraine
            case 'UG': return 15;//Uganda
            case 'AE': return 30;//United Arab Emirates
            case 'GB': return 28;//United Kingdom
            case 'US': return 10;//United States
            case 'UY': return 20;//Uruguay
            case 'UZ': return 15;//Uzbekistan
            case 'VU': return 15;//Vanuatu
            case 'VE': return 15;//Venezuela
            case 'VN': return 12;//Vietnam
            case 'YE': return 22;//Yemen
            case 'ZM': return 20;//Zambia
            case 'ZW': return 22;//Zimbabwe
        }
        return 20;
    }
}