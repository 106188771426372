import * as React from 'react';
import Component from '../../../Component';
import LeaveName from '../../../Components/Leave/LeaveName';
import LeaveStatus from '../../../Components/Leave/LeaveStatus';
import LeaveInterval from '../../../Components/Leave/LeaveInterval';
import LeaveReplacement from '../../../Components/Leave/LeaveReplacement';
import { LEAVESTATUS } from '../../../General';

export default class Item extends Component {

    render() {
        var leave = this.props.leave;
        return (
            <tr>
                <td>
                    <div>
                        <LeaveName leave={leave} />
                        {(leave.status === LEAVESTATUS.AWAITING_APPROVAL) ?
                            <span className="float-right">
                                <LeaveStatus leave={leave} />
                            </span>
                            : <></>}
                    </div>
                    <div className="clearfix" />
                    <div><LeaveInterval leave={leave} /></div>
                    {leave.replacement && leave.replacement.length > 2 ?
                        <div><LeaveReplacement leave={leave} /></div>
                        : <></>}
                </td>
            </tr>
        );
    }

    getLeaveCardColor(leave: any) {
        switch (leave.status) {
            case LEAVESTATUS.AWAITING_APPROVAL:
                return 'border-warning';
            case LEAVESTATUS.APPROVED:
                return 'border-success';
            case LEAVESTATUS.REJECTED:
                return 'border-danger';
            case LEAVESTATUS.CANCELED:
                return 'border-dark';
            default:
                return 'border-dark';
        }
    }
}