import * as React from 'react';
import Component from '../../../Component';
import Anchor from '../../../Components/Anchor';
import Cog from '../../../Components/Cog';

export default class Display extends Component {

    render() {
        const t = this.translate();
        const employee = this.props.employee;
        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Cog onClick={() => { if (this.props.onEdit) { this.props.onEdit(); } }} />
                    </div>
                    <div className="col-12 mb-3">
                        <table className="table table-sm w-100 m-0 p-0">
                            <tbody>
                                <tr>
                                    <td>
                                        {t('timezone')}
                                        <span className="float-right">{employee.timezone}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {t('language')}
                                        <span className="float-right">{t('language.' + employee.language)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {t('date.format')}
                                        <span className="float-right">{t('date.format.' + employee.dateFormat)}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {t('time.format')}
                                        <span className="float-right">{t('time.format.' + employee.timeFormat)}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col-12">
                        <Anchor
                            faIcon="fas fa-key"
                            className="card-link float-right"
                            onClick={() => { if (this.props.onChangePassword) { this.props.onChangePassword(); } }}
                        >
                            {t('change.password')}
                        </Anchor>
                    </div>
                </div>
            </div>
        </div>
    }
}