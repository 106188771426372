import * as React from 'react';
import Component from '../../../Component';
import Invoices from './Invoices/Invoices';
import Current from './Current/Current';
import { STATUS } from '../../../General';

export default class Subscription extends Component {

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.users.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (
            <div className="container-fluid">
                <Current />
                <Invoices />
            </div>
        );
    }
}