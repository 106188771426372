import * as React from 'react';
import Component from '../../Component';
import { NOTIFICATIONRULEEVENTCLAUSE } from '../../General';

export default class NotificationRuleEventClause extends Component {
    render() {
        const t = this.translate();
        switch (this.props.eventClause) {
            case NOTIFICATIONRULEEVENTCLAUSE.REQUEST:
                return (<span>{t('NOTIFICATIONRULEEVENTCLAUSE.REQUEST')}</span>);
            case NOTIFICATIONRULEEVENTCLAUSE.ACCEPT_DECISION:
                return (<span>{t('NOTIFICATIONRULEEVENTCLAUSE.ACCEPT_DECISION')}</span>);
            case NOTIFICATIONRULEEVENTCLAUSE.REJECT_DECISION:
                return (<span>{t('NOTIFICATIONRULEEVENTCLAUSE.REJECT_DECISION')}</span>);
            case NOTIFICATIONRULEEVENTCLAUSE.REQUEST_CANCELED:
                return (<span>{t('NOTIFICATIONRULEEVENTCLAUSE.REQUEST_CANCELED')}</span>);
            default:
                return '';
        }
    }
}