import * as React from 'react';
import Component from '../../../Component';
import NextHoes from './NextHoes/NextHoes';
import NextLeaves from './NextLeaves/NextLeaves';
import LeaveTypesBalance from './LeaveTypesBalance/LeaveTypesBalance';

export default class TimeOff extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            visibleItems: 3,
            nextHoesVisible: true,
            nextLeavesVisible: true
        };
    }

    render() {
        const defaultArgs: any = { employee: this.props.employee, };
        return (
            <div>
                <div className="row">
                    <div className={'col-12 mb-3 ' + this.getColLgSize()}>
                        <LeaveTypesBalance {...defaultArgs} />
                    </div>
                    {
                        (this.state.nextLeavesVisible) ?
                            <div className={'col-12 mb-3 ' + this.getColLgSize()}>
                                <NextLeaves
                                    {...defaultArgs}
                                    onEmpty={() => {
                                        this.setState(
                                            {
                                                visibleItems: this.state.visibleItems - 1,
                                                nextLeavesVisible: false
                                            });
                                    }}
                                />
                            </div>
                            :
                            null
                    }
                    {
                        (this.state.nextHoesVisible) ?
                            <div className={'col-12 mb-3 ' + this.getColLgSize()}>
                                <NextHoes
                                    {...defaultArgs}
                                    onEmpty={() => {
                                        this.setState(
                                            {
                                                visibleItems: this.state.visibleItems - 1,
                                                nextHoesVisible: false
                                            });
                                    }}
                                />
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        );
    }

    getColLgSize() {
        return 'col-lg-6';
    }
}