import { MODALS } from '../Constants/ActionTypes';

export const none = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.NONE,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const leaveDecision = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.LEAVE_DECISION,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const cancelLeave = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.CANCEL_LEAVE,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const sendLeaveReminder = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.SEND_LEAVE_REMINDER,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const whoIsOffToday = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.WHO_IS_OFF_TODAY,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const leaveTypesBalance = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.LEAVE_TYPES_BALANCE,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const importHoes = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.IMPORT_HOES,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const addHoe = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.ADD_HOE,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const myNextHoes = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.MY_NEXT_HOES,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const addBlackoutDay = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.ADD_BLACKOUT_DAY,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const otherLeaves = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.OTHER_LEAVES,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const addTeamMembers = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.ADD_TEAM_MEMBERS,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const addOfficeMembers = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.ADD_OFFICE_MEMBERS,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const addSubsidiaryMembers = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.ADD_SUBSIDIARY_MEMBERS,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const adjustEmployeeLeaveTypeAllowance = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.ADJUST_EMPLOYEE_LEAVETYPE_ALLOWANCE,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const orderLeaveTypes = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.ORDER_LEAVETYPES,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};


export const adjustWorkingDayAndHours = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.ADJUST_WORKING_DAY_AND_HOURS,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const transferLeaveTypeAllowance = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.TRANSFER_LEAVE_TYPE_ALLOWANCE,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const revertTransferLeaveTypeAllowance = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.REVERT_TRANSFER_LEAVE_TYPE_ALLOWANCE,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};


export const copyICalendarUrl = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.COPY_ICALENDAR_URL,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};

export const trialStarted = (modal: any, options: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: MODALS.TRIAL_STARTED,
            options: options,
        });
    })(modal.dispatch, modal.getState);
};