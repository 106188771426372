import * as React from 'react';
import Component from '../../Component';
import { LEAVESTATUS } from '../../General';

export default class LeaveStatus extends Component {
    render() {
        const t = this.translate();
        let leave = this.props.leave;
        switch (leave.status) {
            case LEAVESTATUS.AWAITING_APPROVAL:
                return (
                    <span className="text-dark">
                        {t('LEAVESTATUS.' + LEAVESTATUS.AWAITING_APPROVAL)}
                    </span>
                );
            case LEAVESTATUS.APPROVED:
                return (
                    <span className="text-dark">
                        {t('LEAVESTATUS.' + LEAVESTATUS.APPROVED)}
                    </span>
                );
            case LEAVESTATUS.REJECTED:
                return (
                    <span className="text-dark">
                        {t('LEAVESTATUS.' + LEAVESTATUS.REJECTED)}
                    </span>
                );
            case LEAVESTATUS.CANCELED:
                return (
                    <span className="text-dark">
                        {t('LEAVESTATUS.' + LEAVESTATUS.CANCELED)}
                    </span>
                );
            default:
                return '';
        }
    }

    static getName(leave: any, t: any) {
        switch (leave.status) {
            case LEAVESTATUS.AWAITING_APPROVAL:
                return t('LEAVESTATUS.' + LEAVESTATUS.AWAITING_APPROVAL);
            case LEAVESTATUS.APPROVED:
                return t('LEAVESTATUS.' + LEAVESTATUS.APPROVED);
            case LEAVESTATUS.REJECTED:
                return t('LEAVESTATUS.' + LEAVESTATUS.REJECTED);
            case LEAVESTATUS.CANCELED:
                return t('LEAVESTATUS.' + LEAVESTATUS.CANCELED);
            default:
                return '';
        }
    }
}