import * as React from 'react';
import MyNextLeavesTable from '../../../Dashboard/MyNextLeaves/Table';

export default class Table extends MyNextLeavesTable {
    thead(totalAmout: any) {
        const t = this.translate();
        return (
            <thead>
                <tr>
                    <th className="border-top-0">
                        {t('next.leaves')}
                        <span
                            className="float-right font-weight-light text-secondary"
                        >
                            {this.getTextAmount(totalAmout)}
                        </span>
                    </th>
                </tr>
            </thead>
        );
    }
}