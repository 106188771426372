import * as React from 'react';
import Component from '../../Component';
import Error from '../../Components/Error';
import { STATUS, VIEWMODE } from '../../General';
import Item from './Item';
import RowItem from './RowItem';

export default class List extends Component {

    render() {
        const employees = this.props.employees;
        switch (employees.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                if (employees.arr.length === 0) {
                    return <div className="col-12 text-danger mb-3">
                        {t('no.employees.found')}
                    </div>
                }
                if (this.props.viewMode === VIEWMODE.LIST) {
                    return <div className="col-12 mb-3">
                        <div className="card w-100">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-sm table-hover w-100 mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="border-top-0">
                                                    <i className="mr-1 far fa-user fa-fw" aria-hidden="true" />
                                                    {t('employee')}
                                                </th>
                                                <th scope="col" className="border-top-0 text-right text-nowrap" style={{ width: '15%' }}>
                                                    <i className="mr-1 far fa-calendar-alt" aria-hidden="true" />
                                                    {t('employment.date')}
                                                </th>
                                                <th scope="col" className="border-top-0 text-right text-nowrap" style={{ width: '15%' }}>
                                                    <i className="mr-1 fas fa-hourglass-half" aria-hidden="true" />
                                                    {t('service.length')}
                                                </th>
                                                <th scope="col" className="border-top-0 text-right text-nowrap" style={{ width: '15%' }}>
                                                    <i className="mr-1 fas fa-stopwatch" aria-hidden="true" />
                                                    {t('next.service.year.in')}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employees.arr.map((employee: any) => <RowItem key={'Employees-' + this.props.viewMode + '-' + employee.id} employee={employee} />)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                return employees.arr.map((employee: any) => {
                    return <div key={'Employees-' + this.props.viewMode + '-' + employee.id} className={this.getListColSize(this.props.viewMode) + ' mb-3'}>
                        <Item employee={employee} />
                    </div>
                });
            }
            default:
                return (<Error />);
        }
    }
}