import * as React from 'react';
import Component from '../../Component';
import Anchor from '../Anchor';
import { leaveDecision } from '../../Actions/Modal';

export default class LeaveDecisionAction extends Component {
    render() {
        const t = this.translate();
        return (
            <Anchor
                className={this.props.noText ? 'card-link' : 'btn-outline-primary btn-block btn'}
                title={t('approve.or.decline')}
                onClick={() => {
                    leaveDecision(
                        this.getModal(),
                        { leave: this.props.leave, callback: () => { if (this.props.onRefresh) { this.props.onRefresh(); } } }
                    );
                }}
            >
                <div className="d-inline-block">
                    <span className="text-success">
                        <i className="far fa-thumbs-up fa-fw" aria-hidden="true" />
                    </span>
                    {this.props.noText ? null : t('approve')}
                </div>
                {this.props.noText ? null :
                    <div className="d-inline-block mr-1 ml-1">
                        {' '}
                        {t('or')}
                        {' '}
                    </div>
                }
                <div className="d-inline-block">
                    <span className="text-danger">
                        <i className="far fa-thumbs-down fa-fw" aria-hidden="true" />
                    </span>
                    {this.props.noText ? null : t('decline')}
                </div>
            </Anchor>
        );
    }
}