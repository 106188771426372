import * as React from 'react';
import Component from '../Component';
import Close from '../Components/Close';
import { TOASTR_ACTIONS } from '../Constants/ActionTypes';

export default class Toastr extends Component {
    toastrSubscription: any;
    constructor(props: any) {
        super(props);
        this.state = {
            type: TOASTR_ACTIONS.CLEAN,
            message: undefined,
        };
    }

    componentDidMount() {
        const toastr = this.getToastr();
        this.toastrSubscription = toastr.subscribe(() => {
            this.setState(
                {
                    message: (toastr as any).getState().message,
                    type: (toastr as any).getState().type
                },
                () => { window.setTimeout(() => { this.clean(); }, 1500); }
            );
        });
    }

    componentWillUnmount() {
        if (this.toastrSubscription) { this.toastrSubscription(); }
    }

    render() {
        if (this.state.type === TOASTR_ACTIONS.CLEAN) {
            return null;
        }
        return (
            <div
                style={{
                    zIndex: 3000,
                    top: '40px',
                    right: '40px',
                    width: '300px',
                    maxWidth: '300px',
                    position: 'fixed'

                }}
            >
                <div className="w-100 border border-mutted rounded bg-light h-100 d-table-row shadow">
                    <div className="d-table-cell h-100 p-2 align-middle">
                        {this.getIcon()}
                    </div>
                    <div className="d-table-cell h-100 p-2 bg-white">
                        {this.state.message}
                    </div>
                    <div className="d-table-cell h-100 p-2 bg-white">
                        <Close onClick={() => { this.clean(); }} />
                    </div>
                </div>
            </div>
        );
    }

    getIcon() {
        switch (this.state.type) {
            case TOASTR_ACTIONS.SUCCESS:
                return (
                    <i className="fa fa-check text-success ml-3 mr-3" aria-hidden="true" />
                );
            case TOASTR_ACTIONS.ERROR:
                return (
                    <i className="fa fa-exclamation text-danger" aria-hidden="true" />
                );
            case TOASTR_ACTIONS.INFO:
                return (
                    <i className="fa fa-info text-info" aria-hidden="true" />
                );
            default:
                return null;
        }
    }

    clean() {
        this.setState({ type: TOASTR_ACTIONS.CLEAN, message: undefined });
    }
}