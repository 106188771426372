import * as $ from 'jquery';
import * as React from 'react';
import { doFetchSubsidiariesFromServer, doFetchUsersFromServer } from '../../../Actions/Caches';
import Component from '../../../Component';
import Close from '../../../Components/Close';
import InputField from '../../../Components/InputField';
import SelectField from '../../../Components/SelectField';
import Submit from '../../../Components/Submit';
import TextareaField from '../../../Components/TextareaField';
import { STATUS } from '../../../General';
import { countriesOptionsFactory, usersOptionsFactory } from '../../../SelectOptionsFactories';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        const subsidiary = this.props.subsidiary;
        this.state = {
            name: {
                value: subsidiary.name,
                touched: false,
                rules: this.Rules().BasicNameRules()
            },
            manager: {
                value: [subsidiary.manager.id],
                rules: this.Rules().BasicSelectRules(),
                touched: false,
            },
            country: {
                value: [subsidiary.country],
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            description: {
                value: subsidiary.description,
                touched: false,
                rules: this.Rules().MaxLengthTextareaRules()
            },
            save: {
                pleaseWait: false
            }
        };
    }

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.countries.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        const countriesOptions = countriesOptionsFactory(caches.countries.arr[this.language()]);
        const usersOptions = usersOptionsFactory(caches.users.arr);
        const t = this.translate();
        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <form onSubmit={(event: any) => { this.submit(event); }}>
                    <fieldset>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="float-right">
                                    <Close onClick={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }} />
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <InputField
                                    defaultValue={this.state.name.value}
                                    touched={this.state.name.touched}
                                    rules={this.state.name.rules}
                                    type="text"
                                    placeholder={t('name')}
                                    focus={this.isScreenSizeSmall() ? false : true}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { name: Object.assign({}, this.state.name, { value: value }) },
                                            () => { this.onUpdate(); }
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.manager.value}
                                    touched={this.state.manager.touched}
                                    options={usersOptions}
                                    rules={this.state.manager.rules}
                                    placeholder={t('manager')}
                                    onChange={(value: any) => { this.setState({ manager: Object.assign({}, this.state.manager, { value }) }); }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.country.value}
                                    touched={this.state.country.touched}
                                    options={countriesOptions}
                                    multiple={false}
                                    rules={this.state.country.rules}
                                    placeholder={t('country')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { country: Object.assign({}, this.state.country, { value: value }) }
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <TextareaField
                                    defaultValue={this.state.description.value}
                                    touched={this.state.description.touched}
                                    rows={2}
                                    placeholder={t('description')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { description: Object.assign({}, this.state.description, { value: value }) }
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <Submit
                                    faIcon="far fa-hdd"
                                    disabled={!this.isFormValid()}
                                    pleaseWait={this.state.save.pleaseWait}
                                >
                                    {t('save')}
                                </Submit>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    }

    onUpdate() {
        if (this.props.onUpdate) {
            this.props.onUpdate({
                id: this.props.subsidiary.id,
                name: this.state.name.value,
                country: this.state.country.value[0],
                description: this.state.description.value
            });
        }
    }

    isFormValid() {
        return this.Validator().validate(this.state.name)
            && this.Validator().validate(this.state.country)
            && this.Validator().validate(this.state.manager)
            && this.Validator().validate(this.state.description);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                name: Object.assign({}, this.state.name, { touched: true }),
                country: Object.assign({}, this.state.country, { touched: true }),
                manager: Object.assign({}, this.state.manager, { touched: true }),
                description: Object.assign({}, this.state.description, { touched: true }),
            },
            () => {
                if (this.isFormValid()) {
                    this.save();
                }
            }
        );
    }

    save() {
        const t = this.translate();
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getSubsidiaryUpdate(this.props.subsidiary.id),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                name: this.state.name.value,
                country: this.state.country.value[0],
                description: this.state.description.value,
                manager: { id: this.state.manager.value[0] }
            }),
            beforeSend: () => {
                this.setState({ save: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('the.subsidiary.was.updated'));
                doFetchSubsidiariesFromServer(this.getCaches());
                doFetchUsersFromServer(this.getCaches(), this.getSession().getState());
                if (this.props.onRefresh) { this.props.onRefresh(); }
                if (this.props.onCancel) { this.props.onCancel(); }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                if (+jqXHR.status === 400) {
                    var reason = jqXHR.getResponseHeader('REASON');
                    if (reason && reason === 'DUPLICATE') {
                        this.successToastr(t('subsidiary.duplicate'));
                        return;
                    }
                }
                this.generalErrorToastr();
            },
            complete: (jqXHR, textStatus) => {
                this.setState({ save: { pleaseWait: false } });
            }
        });
    }
}