import * as React from 'react';
import { none } from '../../../Actions/Modal';
import ResponsiveModal from '../ResponsiveModal';
import Form from './Form';

export default class TransferLeaveTypeAllowance extends ResponsiveModal {
    getContent() {
        const year = this.props.options.year;
        const leaveType = this.props.options.leaveType;
        return <Form
            year={year}
            leaveType={leaveType}
            onClose={() => { this.toggle(); none(this.getModal(), {}); if (this.props.callback) { this.props.callback(); } }}
        />
    }

    getTitle(): any {
        const t = this.translate();
        const year = this.props.options.year;
        return <h5 className="m-0 p-0">{t('transfer.from.y1.to.y2').replace('y1', year).replace('y2', year + 1)}</h5>
    }
}