import * as React from 'react';
import Component from '../../../Component';
import Table from './Table';
import * as $ from 'jquery';
import { STATUS, ORDER, LEAVESTATUS } from '../../../General';
import { leavesFactory, filtersFormUrlFactory } from '../../../Factories';
import { getURLParameter } from '../../../Utils';
import { leaveDecision } from '../../../Actions/Modal';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            filters: this.createFilters(),
            leaves: {
                arr: [],
                status: STATUS.LOADING
            }
        };
    }

    createFilters() {
        const moment = this.getMomentWithLocale();
        let filters = {
            orderBy: { field: 'DATE', order: ORDER.DESC },
            users: {
                value: [],
            },
            leaveStatuses: {
                value: [LEAVESTATUS.AWAITING_APPROVAL]
            },
            leaveTypes: {
                value: [],
            },
            startDate: { value: moment().startOf('day').subtract(1, 'y') },
            endDate: { value: moment().startOf('day').add(1, 'y') }
        };
        filters = Object.assign({}, filters, filtersFormUrlFactory());
        return filters;
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState({ leaves: { arr: [], status: STATUS.LOADING } }, () => {
            $.ajax({
                type: 'GET',
                contentType: 'application/json',
                url: this.Endpoints().getLeavesToApprove({ filters: this.state.filters }),
                dataType: 'json',
                cache: false,
                success: (data: any, textStatus: any, jqXHR: any) => {
                    if (data.data.length === 0) {
                        if (this.props.onEmpty) { this.props.onEmpty(); }
                    }
                    const leaves = leavesFactory(data.data, (this.getCaches().getState() as any).users);
                    this.setState(
                        { leaves: { arr: leaves, status: STATUS.READY } },
                        () => {
                            const ld = getURLParameter('ld');
                            if (ld) {
                                for (let i = 0; i < leaves.length; i++) {
                                    const leave = leaves[i];
                                    if (+ld === leave.id) {
                                        leaveDecision(
                                            this.getModal(),
                                            { leave, callback: () => { this.props.search(); } }
                                        );
                                        break;
                                    }
                                }
                            }
                        }
                    );
                },
                error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                    this.setState({ leaves: { arr: [], status: STATUS.ERROR } });
                    this.ajaxError(jqXHR, textStatus, errorThrown);
                },
            });
        });
    }

    render() {
        if (this.state.leaves.arr.length === 0 || this.state.leaves.status === STATUS.LOADING) {
            return null as any;
        }
        return <div className="card h-100">
            <div className="card-body">
                <Table
                    leaves={this.state.leaves}
                    filters={this.state.filters}
                    onRefresh={() => { this.search(); }}
                />
            </div>
        </div>
    }
}