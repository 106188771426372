import * as React from 'react';
import Component from '../../../Component';
import Anchor from '../../../Components/Anchor';
import { Tooltip } from 'reactstrap';
import { findDayEventName } from '../../../Utils';

export default class DayEvent extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            tooltipOpen: false
        };
    }

    render() {
        const day = this.props.day;
        const dayEvent = this.props.day.dayEvent;
        const t = this.translate();
        if (dayEvent) {
            if (dayEvent.status !== 0) {
                let requiresApproval: any;
                if (dayEvent.status === 1000) {
                    requiresApproval = (
                        <div className="text-warning">
                            <i className="fa fa-exclamation-circle fw" aria-hidden="true" />
                            {' ' + t('requires.approval')}
                        </div>
                    );
                }
                let part: any = '';
                switch (dayEvent.dayType) {
                    case 1:
                        part = (<div>{t('first.part.of.the.day')}</div>);
                        break;
                    case 2:
                        part = (<div>{t('second.part.of.the.day')}</div>);
                        break;
                    case 3:
                        if (dayEvent.hours) {
                            if (dayEvent.hours > 1)
                                part = (<div>{dayEvent.hours + ' ' + t('hours')}</div>);
                            if (dayEvent.hours === 1)
                                part = (<div>{t('one.hour')}</div>);
                        }
                        break;
                    case 0:
                        break;
                    default:
                        break;
                }
                return (
                    <div className="d-block h-100 w-100">
                        <Anchor
                            id={'Day' + this.cid}
                            className={'d-block' + (dayEvent.isBLO ? ' text-danger' : '')}
                        >
                            {this.getDay(day)}
                        </Anchor>
                        <Tooltip
                            delay={{ show: 0, hide: 250 }}
                            placement="top"
                            isOpen={this.state.tooltipOpen}
                            target={'Day' + this.cid}
                            toggle={() => {
                                this.setState({ tooltipOpen: !this.state.tooltipOpen });
                            }}
                        >
                            <div>{findDayEventName(dayEvent, this.language())}</div>
                            {requiresApproval}
                            {part}
                        </Tooltip>
                    </div>
                );

            }
        }
        return (
            <span>{this.getDay(day)}</span>
        );
    }

    getDay(day: any) {
        if (day.today) {
            return (<strong>{day.value}</strong>);
        } else {
            return day.value;
        }
    }
}