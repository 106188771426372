import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import { STATUS } from '../../../General';
import Form from './Form';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = { leaveTypes: { value: [], status: STATUS.LOADING } };
    }

    componentDidMount() {
        $.ajax({
            type: 'GET',
            contentType: 'application/json',
            url: this.Endpoints().getLeaveTypes({ filters: {} }),
            dataType: 'json',
            cache: false,
            success: (data: any, textStatus: any, jqXHR: any) => {
                this.setState({ leaveTypes: { arr: data.data, status: STATUS.READY } });
            },
            error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                this.setState({ leaveTypes: { arr: [], status: STATUS.ERROR } });
                this.ajaxError(jqXHR, textStatus, errorThrown);
            },
        });
    }

    render() {
        const session: any = this.getSession().getState();
        let ready = session.status === STATUS.READY && this.state.leaveTypes.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (<Form leaveTypes={this.state.leaveTypes} onClose={this.props.onClose} />);
    }
}