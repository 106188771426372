import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../Component';
import A from '../../Components/A';
import Confirm from '../../Components/Confirm';

export default class Item extends Component {
    render() {
        const t = this.translate();
        const notificationRule = this.props.notificationRule;
        return (
            <div className="card w-100 h-100">
                <div className="card-body">
                    <table className="table table-sm w-100 m-0 p-0">
                        <thead>
                            <tr>
                                <th className="border-top-0 border-bottom-0">
                                    <A href={this.Urls().getNotificationRule(notificationRule.id)} title={notificationRule.name}>
                                        {notificationRule.name}
                                    </A>
                                    <div className="float-right">
                                        <Confirm
                                            title={t('delete')}
                                            className="text-danger text-nowrap"
                                            faIcon="fas fa-trash-alt"
                                            onConfirm={() => {
                                                $.ajax({
                                                    type: 'DELETE',
                                                    contentType: 'application/json',
                                                    url: this.Endpoints().getNotificationRuleDelete(notificationRule.id),
                                                    dataType: 'json',
                                                    cache: false,
                                                    success: (data: any, textStatus: any, jqXHR: any) => {
                                                        this.successToastr(t('the.notification.rule.was.deleted'));
                                                        if (this.props.onRefresh) {
                                                            this.props.onRefresh();
                                                        }
                                                    },
                                                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                                                        this.ajaxError(jqXHR, textStatus, errorThrown);
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        );
    }
}