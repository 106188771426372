import * as React from 'react';
import Component from '../../../Component';
import DateRangeExtendedPickerField from '../../../Components/DateRangeExtendedPickerField';
import FiltersSwitch from '../../../Components/FiltersSwitch';
import SelectField from '../../../Components/SelectField';
import SortSwitch from '../../../Components/SortSwitch';
import { PERMISSIONS } from '../../../General';
import { officesOptionsFactory, subsidiariesOptionsFactory, unitsOptionsFactory, usersOptionsFactory } from '../../../SelectOptionsFactories';
import Download from '../../../Components/Download';

export default class Filters extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            expanded: false,
            orderBy: this.props.orderBy,
            users: {
                value: [],
                defaultValue: [],
                touched: false,
            },
            units: {
                value: [],
                defaultValue: [],
                touched: false,
            },
            offices: {
                value: [],
                defaultValue: [],
                touched: false,
            },
            subsidiaries: {
                value: [],
                defaultValue: [],
                touched: false,
            },
            approvers: {
                value: [],
                defaultValue: [],
                touched: false,
            },
            startDate: { value: this.props.defaultStartDate },
            endDate: { value: this.props.defaultEndDate }
        };
        this.onChange = this.onChange.bind(this);
    }


    onChange() { if (this.props.onChange) { this.props.onChange(this.state); } }

    render() {
        const t = this.translate();
        const moment = this.getMomentWithLocale();
        const unitsOptions = unitsOptionsFactory(this.getUnitsFilterByPermissions(PERMISSIONS.REPORTS));
        const usersOptions = usersOptionsFactory(this.getUsersFilterByPermissions(PERMISSIONS.REPORTS));
        const officesOptions = officesOptionsFactory(this.getOfficesFilterByPermissions(PERMISSIONS.REPORTS));
        const caches: any = this.getCaches().getState();
        const subsidiariesOptions = subsidiariesOptionsFactory(caches.subsidiaries.arr);
        const session: any = this.getSession().getState();
        return <div>
            <div className="row">
                {
                    this.state.expanded ? null :
                        <div className="col-9 col-lg-6">
                            <DateRangeExtendedPickerField
                                startDate={this.state.startDate.value}
                                endDate={this.state.endDate.value}
                                minimumDate={moment().subtract(10, 'year')}
                                maximumDate={moment().add(10, 'year')}
                                touched={this.state.startDate.touched}
                                placeholder={t('range')}
                                onChange={(value: any) => {
                                    this.setState(
                                        {
                                            startDate: Object.assign({}, this.state.startDate, { value: value.startDate, touched: true }),
                                            endDate: Object.assign({}, this.state.startDate, { value: value.endDate })
                                        },
                                        this.onChange
                                    );
                                }}
                            />
                        </div>
                }
                <div className={(this.state.expanded ? 'col-12 mb-3' : 'col-3 col-lg-6')}>
                    <div className="float-right mt-1">
                        <Download download={this.props.download} />
                        {' '}
                        {(this.isScreenSizeSmall()) ? null : <SortSwitch
                            title={t('name')}
                            order={this.state.orderBy.order}
                            onChange={(order: any) => { this.setState({ orderBy: { field: 'NAME', order: order } }, this.onChange); }}
                        />}
                        <FiltersSwitch expanded={this.state.expanded} onClick={() => { this.setState({ expanded: !this.state.expanded }); }} />
                    </div>
                </div>
            </div>
            {
                (this.state.expanded) ?
                    <form onSubmit={(event: any) => { event.preventDefault(); }}>
                        <div className="row">
                            <div className="col-12 col-lg-6 mb-3">
                                <DateRangeExtendedPickerField
                                    startDate={this.state.startDate.value}
                                    endDate={this.state.endDate.value}
                                    minimumDate={moment().subtract(10, 'year')}
                                    maximumDate={moment().add(10, 'year')}
                                    touched={this.state.startDate.touched}
                                    placeholder={t('range')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            {
                                                startDate: Object.assign({}, this.state.startDate, { value: value.startDate, touched: true }),
                                                endDate: Object.assign({}, this.state.startDate, { value: value.endDate })
                                            },
                                            this.onChange
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 col-lg-6 mb-3">
                                <SelectField
                                    defaultValue={this.state.users.value}
                                    touched={this.state.users.touched}
                                    options={usersOptions}
                                    multiple={true}
                                    placeholder={t('employees')}
                                    maxDisplayedOptions={3}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { users: Object.assign({}, this.state.users, { value }) },
                                            this.onChange
                                        );
                                    }}
                                />
                            </div>
                            {(unitsOptions && unitsOptions.length > 1) ?
                                <div className="col-12 col-lg-6 mb-3">
                                    <SelectField
                                        defaultValue={this.state.units.value}
                                        touched={this.state.units.touched}
                                        options={unitsOptions}
                                        multiple={true}
                                        placeholder={t('teams')}
                                        onChange={(value: any) => {
                                            this.setState(
                                                { units: Object.assign({}, this.state.units, { value: value }) },
                                                this.onChange
                                            );
                                        }}
                                    />
                                </div> : undefined}
                            {(officesOptions?.length > 1) ?
                                <div className="col-12 col-lg-6 mb-3">
                                    <SelectField
                                        defaultValue={this.state.offices.value}
                                        touched={this.state.offices.touched}
                                        options={officesOptions}
                                        multiple={true}
                                        placeholder={t('offices')}
                                        onChange={(value: any) => {
                                            this.setState(
                                                { offices: Object.assign({}, this.state.offices, { value }) },
                                                this.onChange
                                            );
                                        }}
                                    />
                                </div> : null}
                            {session.company.subsidiariesEnabled && subsidiariesOptions ?
                                <div className="col-12 col-lg-6 mb-3">
                                    <SelectField
                                        defaultValue={this.state.subsidiaries.value}
                                        touched={this.state.subsidiaries.touched}
                                        options={subsidiariesOptions}
                                        multiple={true}
                                        placeholder={t('subsidiaries')}
                                        onChange={(value: any) => {
                                            this.setState({ subsidiaries: Object.assign({}, this.state.subsidiaries, { value }) }, this.onChange);
                                        }}
                                    />
                                </div> : null}
                            <div className="col-12 col-lg-6 mb-3">
                                <SelectField
                                    defaultValue={this.state.approvers.value}
                                    touched={this.state.approvers.touched}
                                    options={usersOptions}
                                    placeholder={t('approvers')}
                                    multiple={true}
                                    maxDisplayedOptions={3}
                                    onChange={(value: any) => { this.setState({ approvers: Object.assign({}, this.state.approvers, { value }) }, this.onChange); }}
                                />
                            </div>
                        </div>
                    </form>
                    : null
            }
        </div>
    }
}