import * as React from 'react';
import Component from '../../../Component';
// import Item from './Item';
import Anchor from '../../../Components/Anchor';
import A from '../../../Components/A';
import Error from '../../../Components/Error';
import { STATUS } from '../../../General';
import { addOfficeMembers } from '../../../Actions/Modal';

export default class List extends Component {

    render() {
        const employees = this.props.employees;
        switch (employees.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                let trs: any = undefined;
                if (employees.arr.length === 0) {
                    trs = <tr>
                        <td className="text-danger">
                            {t('no.members.found')}
                        </td>
                    </tr>
                } else {
                    trs = employees.arr.map((employee: any) => {
                        return <tr key={'Office-Members-List' + employee.id}>
                            <td>
                                <A
                                    faIcon="far fa-user"
                                    href={this.Urls().getEmployee(employee.id)}
                                    title={employee.fullName}
                                >
                                    {employee.fullName}
                                </A>
                            </td>
                        </tr>
                    });
                }
                return <div className={this.getCardClassNameSize()}>
                    <div className="card-body">
                        <table className="table table-sm table-hover w-100 mb-0">
                            <thead>
                                <tr>
                                    <th className="border-top-0">
                                        <Anchor
                                            faIcon="fa fa-plus"
                                            title={t('add.office.members')}
                                            onClick={() => {
                                                addOfficeMembers(
                                                    this.getModal(), {
                                                    office: this.props.office,
                                                    callback: () => { if (this.props.onRefresh) { this.props.onRefresh(); } }
                                                }
                                                );
                                            }}
                                        >
                                            {t('add.office.members')}
                                        </Anchor>
                                        <Anchor
                                            className="float-right font-weight-light text-secondary"
                                            title={employees.arr.length + ' ' +
                                                (employees.arr.length === 1 ? t('member') : t('members'))}
                                        >
                                            {employees.arr.length}
                                        </Anchor>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {trs}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            default:
                return (<Error />);
        }
    }
}