import { arrayMoveImmutable } from 'array-move';
import * as $ from 'jquery';
import * as React from 'react';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { doFetchLeaveTypesFromServer } from '../../../Actions/Caches';
import Component from '../../../Component';
import LeaveName from '../../../Components/Leave/LeaveName';
import Submit from '../../../Components/Submit';

export default class Table extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            leaveTypes: this.props.leaveTypes.arr,
            save: {
                pleaseWait: false
            },
        };
    }

    render() {
        const t = this.translate();
        return (
            <form onSubmit={(event: any) => { event.preventDefault(); return false; }}>
                <div className="row">
                    <div className="col-12 mb-3">
                        <SortableComponent items={this.state.leaveTypes} onChange={(items: any) => { this.setState({ leaveTypes: items }) }} />
                    </div>

                    <div className="col-12 mb-3">
                        <Submit
                            color="primary"
                            faIcon="far fa-hdd fa-fw"
                            pleaseWait={this.state.save.pleaseWait}
                            onClick={(event: any) => { this.submit(event); }}
                        >
                            {t('save')}
                        </Submit>
                    </div>
                </div>
            </form>
        );
    }

    submit(event: any) {
        event.preventDefault();
        this.save();
    }

    save() {
        const t = this.translate();
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getLeaveTypePriority(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({ leaveTypes: this.state.leaveTypes.map((leaveType: any) => { return { id: leaveType.id } }) }),
            beforeSend: () => {
                this.setState({ save: { pleaseWait: true } });
            },
            success: (data: any, textStatus: any, jqXHR: any) => {
                this.successToastr(t('the.leave.types.order.was.saved.'));
                doFetchLeaveTypesFromServer(this.getCaches());
                if (this.props.onClose) {
                    this.props.onClose();
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            }
        });
    }
}

const SortableItem = SortableElement(({ value }: any) => <li className="list-group-item"><LeaveName leave={{ leaveType: value }} /></li>) as any;

const SortableList = SortableContainer(({ items }: any) => {
    return <ul className="list-group">
        {items.map((value: any, index: any) => (
            <SortableItem key={`item-${value.id}`} index={index} value={value} />
        ))}
    </ul>
}) as any;

class SortableComponent extends Component {
    state = {
        items: this.props.items,
    };

    onSortEnd = ({ oldIndex, newIndex }: any) => {
        this.setState(({ items }: any) => ({ items: arrayMoveImmutable(items, oldIndex, newIndex), }), () => { this.props.onChange(this.state.items) });
    };

    render() {
        return <SortableList items={this.state.items} onSortEnd={this.onSortEnd} />;
    }
}