import Age from '../../Components/Age';
import BaseDownload from '../../Components/BaseDownload';
import Manager from './Manager';

/* eslint-disable @typescript-eslint/no-unused-expressions */
export default class Download extends BaseDownload {
    constructor(options: any) {
        super(options);
        this.search = Manager.search.bind(this);
    }

    getData() {
        const t = this.t;
        const caches: any = this.getCaches().getState();
        const countriesMap = caches.countries.map[this.language()];
        const session = this.getSession().getState() as any;
        const header = [t('email'), t('employee'), t('approver'), t('position'), t('team'), t('office')];
        const moment = this.getMomentWithLocale();
        if (session.company.subsidiariesEnabled) {
            header.push(t('subsidiary'))
        }
        header.push(t('country'))
        const companyId = session.company.id;
        if (companyId === 8902) {
            header.push(t('phone.number'))
            header.push(t('employment.date'));
            header.push(t('employment.end.date'));
            header.push(t('service.length'));
            header.push(t('birthday'))
            header.push(t('age'))
            header.push(t('gender'))
            header.push(t('personal.phone.number'))
            header.push(t('emergency.phone.number'))
            header.push(t('next.of.kin.name'))
            header.push(t('next.of.kin.relationship'))
            header.push(t('next.of.kin.phone.number'))
        }
        const now = moment();
        const arr = [header].concat(this.state.employees.arr.map((employee: any) => {
            const ret: any = [employee.email, employee.fullName];
            const approver = (employee.approverCompanyUser && employee.approverCompanyUser.id > 0) ?
                (this.getCaches().getState() as any).users.map[employee.approverCompanyUser.id] : undefined;
            ret.push(approver && approver.id > 0 ? approver.fullName : '');
            ret.push(employee.position && employee.position.length > 0 ? employee.position : '');
            const companyUnit = (employee.companyUnit && employee.companyUnit.id) ?
                (this.getCaches().getState() as any).units.map[employee.companyUnit.id] : undefined;
            ret.push(companyUnit && companyUnit.name && companyUnit.name.length > 0 ?
                companyUnit.name : '');
            const office = (employee.office && employee.office.id) ?
                (this.getCaches().getState() as any).offices.map[employee.office.id] : undefined;
            ret.push(office?.name?.length > 0 ? office.name : '');
            if (session.company.subsidiariesEnabled) {
                const subsidiary = (employee.subsidiary && employee.subsidiary.id) ?
                    (this.getCaches().getState() as any).subsidiaries.map[employee.subsidiary.id] : undefined;
                ret.push(subsidiary?.name?.length > 0 ? subsidiary.name : '');
            }
            const country = (employee.country && employee.country.length > 0) ?
                countriesMap[employee.country].text : undefined;
            ret.push(country && country.length > 0 ? country : '');
            if (companyId === 8902) {
                ret.push(employee.phoneNumber);
                ret.push(employee.employmentStartDate ? moment(employee.employmentStartDate).format(this.getDateFormat()) : '');
                ret.push(employee.employmentEndDate ? moment(employee.employmentEndDate).format(this.getDateFormat()) : '');
                ret.push(employee.employmentStartDate && moment.duration(now.startOf('day').diff(moment(employee.employmentStartDate).startOf('day'))) > 0 ?
                    this.humanizeDuration(moment.duration(now.startOf('day').diff(moment(employee.employmentStartDate).startOf('day'), true)))
                    : '');
                ret.push(employee.birthDate ? moment(employee.birthDate).format(this.getDateFormat()) : '');
                ret.push(employee.birthDate ? Age.computedAgeLiteral(moment(employee.birthDate).startOf('day'), now.startOf('day'), t, moment(employee.birthDate).startOf('day').format('MM-DD') === now.startOf('day').format('MM-DD')) : '');
                ret.push(employee.gender ? t('EMPLOYEEGENDER.' + employee.gender) : '');
                ret.push(employee.personalPhoneNumber ? employee.personalPhoneNumber : '');
                ret.push(employee.emergencyPhoneNumber ? employee.emergencyPhoneNumber : '');
                ret.push(employee.nextOfKinName ? employee.nextOfKinName : '');
                ret.push(employee.nextOfKinRelationship ? employee.nextOfKinRelationship : '');
                ret.push(employee.nextOfKinPhoneNumber ? employee.nextOfKinPhoneNumber : '');
            }
            return ret;
        }));
        return arr;
    }

    public isReady() {
        return this.state.employees && this.state.employees.status === 'READY';
    }

    getFileName(this: any): any {
        const t = this.t;
        return this.ansi(t('employees')) + this.getSufixFileName();
    }

    public humanizeDuration(momentDuration: any) {
        const t = this.t;
        let duration: any = {
            years: momentDuration.years(),
            months: momentDuration.months(),
            days: momentDuration.days(),
        }
        if (duration.years === 1) {
            duration.years = t('one.year');
        }
        if (duration.years > 1 || duration.years < 0) {
            duration.years = duration.years + ' ' + t('years');
        }
        if (duration.months === 1) {
            duration.months = t('one.month');
        }
        if (duration.months > 1 || duration.months < 0) {
            duration.months = duration.months + ' ' + t('months');
        }
        if (duration.days === 1) {
            duration.days = t('one.day');
        }
        if (duration.days > 1 || duration.days < 0) {
            duration.days = duration.days + ' ' + t('days');
        }
        duration.years = duration.years ? duration.years + ' ' : '';
        duration.months = duration.months ? duration.months + ' ' : '';
        duration.days = duration.days ? duration.days + ' ' : '';
        return (duration.years + duration.months + duration.days).toLowerCase();
    }
}