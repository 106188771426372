import * as React from 'react';
import Component from '../../Component';
import Manager from './Manager';

export default class ActivateEmployee extends Component {

    render() {
        return (
            <Manager />
        );
    }
}