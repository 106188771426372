import { whoIsOffToday } from '../../../Actions/Modal';
import Component from '../../../Component';
import Anchor from '../../../Components/Anchor';
import Error from '../../../Components/Error';
import { STATUS } from '../../../General';
import { copyArray } from '../../../Utils';
import LeaveName from '../../Reports/LeaveName';

export default class Table extends Component {
    static ITEMS = 4;
    render() {
        const rows = this.props.rows;
        switch (rows.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                const session = (this.getSession().getState() as any);
                const trs = ((rows.arr.length > Table.ITEMS && !this.props.showAll) ?
                    copyArray(rows.arr, 0, Table.ITEMS - 1) : rows.arr).map((row: any) => {
                        return <tr key={'DashBoardWhoIsOffTodayTable-' + row.employee.id}>
                            {session.permissions.anonymize ?
                                <td>{row.employee.fullName}</td>
                                :
                                <td>
                                    {row?.leaveType?.id ? <div className="d-inline-block"><LeaveName leave={row} /></div> : <></>}
                                    <div className="float-right d-inline-block">{row.employee.fullName}</div>
                                </td>
                            }
                        </tr>
                    });
                return <table className="table table-sm table-hover w-100 m-0 p-0">
                    <thead>
                        <tr>
                            <th className="border-top-0">
                                {t('who.is.off.today')}<span className="float-right font-weight-light text-secondary">{rows.arr.length}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {trs}
                        {(rows.arr.length > Table.ITEMS && !this.props.showAll) ?
                            <tr>
                                <td>
                                    <div className="float-right">
                                        <Anchor className="text-muted" onClick={() => { whoIsOffToday(this.getModal(), { rows: rows }); }}>
                                            {t('more')}
                                        </Anchor>
                                    </div>
                                </td>
                            </tr>
                            : <></>}
                    </tbody>
                </table>
            }
            default:
                return (<Error />);
        }
    }
}