import * as React from 'react';
import Component from '../../Component';
import Submit from '../../Components/Submit';
import Line from '../../Components/Line';
import Back from '../../Components/Back';
import InputField from '../../Components/InputField';
import TextareaField from '../../Components/TextareaField';
import SelectField from '../../Components/SelectField';
import * as $ from 'jquery';
import { USERSTATUS } from '../../General';
import { doFetchUnitsFromServer, doFetchUsersFromServer } from '../../Actions/Caches';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            name: {
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            manager: {
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            members: {
                touched: false,
            },
            description: {
                touched: false,
                rules: this.Rules().MaxLengthTextareaRules()
            },
            add: {
                pleaseWait: false
            }
        };
    }

    render() {
        const caches: any = this.getCaches().getState();
        const managerOptions: any = caches.users.arr
            .filter((user: any) => {
                return (user.status === USERSTATUS.DISABLED) ? false : true;
            }).map((user: any) => {
                return { value: user.id, text: user.fullName };
            });
        const membersOptions: any = caches.users.arr
            .filter((user: any) => {
                return (user.status === USERSTATUS.DISABLED) ? false : true;
            }).map((user: any) => {
                return { value: user.id, text: user.fullName };
            });
        const t = this.translate();
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Back href={this.Urls().getUnits()} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <h5 className="mb-0">{t('add.team')}</h5>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mb-3">
                        <Line />
                    </div>
                </div>
                <div className={this.getCardClassNameSize()}>
                    <div className="card-body">
                        <form onSubmit={(event: any) => { this.submit(event); }}>
                            <fieldset>
                                <div className="row">
                                    <div className="col-12 mb-3 mt-3">
                                        <InputField
                                            touched={this.state.name.touched}
                                            rules={this.state.name.rules}
                                            type="text"
                                            placeholder={t('name')}
                                            focus={this.isScreenSizeSmall() ? false : true}
                                            onChange={(value: any) => {
                                                this.setState(
                                                    { name: Object.assign({}, this.state.name, { value: value }) }
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <SelectField
                                            touched={this.state.manager.touched}
                                            options={managerOptions}
                                            rules={this.state.manager.rules}
                                            multiple={false}
                                            placeholder={t('manager')}
                                            onChange={(value: any) => {
                                                this.setState(
                                                    { manager: Object.assign({}, this.state.manager, { value: value }) }
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <SelectField
                                            touched={this.state.members.touched}
                                            options={membersOptions}
                                            multiple={true}
                                            placeholder={t('team.members')}
                                            onChange={(value: any) => {
                                                this.setState(
                                                    { members: Object.assign({}, this.state.members, { value: value }) }
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <TextareaField
                                            touched={this.state.description.touched}
                                            type="text"
                                            rows={2}
                                            placeholder={t('description')}
                                            onChange={(value: any) => {
                                                this.setState(
                                                    // tslint:disable-next-line:max-line-length
                                                    { description: Object.assign({}, this.state.description, { value: value }) }
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <Submit
                                            faIcon="far fa-hdd"
                                            disabled={!this.isFormValid()}
                                            pleaseWait={this.state.add.pleaseWait}
                                        >
                                            {t('add.team')}
                                        </Submit>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    isFormValid() {
        return this.Validator().validate(this.state.name)
            && this.Validator().validate(this.state.manager)
            && this.Validator().validate(this.state.members)
            && this.Validator().validate(this.state.description);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                name: Object.assign({}, this.state.name, { touched: true }),
                manager: Object.assign({}, this.state.manager, { touched: true }),
                members: Object.assign({}, this.state.members, { touched: true }),
                description: Object.assign({}, this.state.description, { touched: true })
            },
            () => {
                if (this.isFormValid()) {
                    this.addTeam();
                }
            }
        );
    }

    addTeam() {
        const t = this.translate();

        var companyUnit: any = {};
        companyUnit.name = this.state.name.value;
        companyUnit.managerCompanyUser = {};
        companyUnit.managerCompanyUser.id = +this.state.manager.value;
        companyUnit.description = this.state.description.value;
        const members = this.state.members.value;
        if (members && members.length > 0) {
            companyUnit.companyUsers = members.map((member: any) => {
                return { id: +member };
            });
        }

        $.ajax({
            type: 'PUT',
            contentType: 'application/json',
            url: this.Endpoints().getUnitCreate(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify(companyUnit),
            beforeSend: () => {
                this.setState({ add: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('the.team.was.added'));
                this.goToUnits();
                doFetchUnitsFromServer(this.getCaches());
                doFetchUsersFromServer(this.getCaches(), this.getSession().getState());
            },
            error: (jqXHR, textStatus, errorThrown) => {
                if (+jqXHR.status === 400) {
                    var reason = jqXHR.getResponseHeader('REASON');
                    if (reason && reason === 'DUPLICATE') {
                        this.successToastr(t('team.duplicate'));
                        return;
                    }
                }
                this.generalErrorToastr();
            },
            complete: (jqXHR, textStatus) => {
                this.setState({ add: { pleaseWait: false } });
            }
        });
    }
}