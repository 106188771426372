import { CHANGE_LANGUAGE } from '../Constants/ActionTypes';
import en from '../Translations/en.json';
import ro from '../Translations/ro.json';
import fr from '../Translations/fr.json';
import de from '../Translations/de.json';
import es from '../Translations/es.json';
let i18ns: any = { en: en, ro: ro, fr: fr, de: de, es: es };

export const changeLanguage = (store: any, lang: any) => {
    ((dispatch, getState, language) => {
        dispatch({
            type: CHANGE_LANGUAGE,
            language: language,
            translate: (key: any) => {
                let zalanguage = language ? language : 'en';
                let translation = i18ns[zalanguage][key];
                return translation ? translation : 'not.found.' + key;
            }
        });
    })(store.dispatch, store.getState, lang);
};
