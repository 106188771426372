import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import Line from '../../../Components/Line';
import { fullNameFactory } from '../../../Factories';
import { ORDER, REPORTSTABS, STATUS, USERSTATUS } from '../../../General';
import Tabs from '../Tabs';
import Download from './Download';
import Filters from './Filters';
import Table from './Table';

export default class Manager extends Component {
    search: any;
    getLeaveTypes: any
    constructor(props?: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        this.state = {
            filters: {
                orderBy: { field: 'NAME', order: ORDER.ASC },
                users: {
                    value: [],
                },
                units: {
                    value: [],
                },
                leaveTypes: {
                    value: [],
                },
                approvers: {
                    value: [],
                },
                startDate: { value: moment().startOf('day').startOf('month') },
                endDate: { value: moment().startOf('day').endOf('month') }
            },
            rows: {
                arr: [],
                status: STATUS.LOADING
            }
        };
        this.search = Manager.search.bind(this);
        this.getLeaveTypes = Manager.getLeaveTypes.bind(this);
    }

    componentDidMount() {
        this.search();
    }

    public static search(this: any) {
        const usersMap = (this.getCaches().getState() as any).users.map;
        const moment = this.getMomentWithLocale();
        const session = this.getSession().getState() as any;
        this.setState(
            { rows: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getReportsGeneral({ filters: this.state.filters }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        const rows = {
                            arr: data.map((row: any) => {
                                row.fullName = fullNameFactory(row, session.company.nameFormat);
                                const employee = usersMap[row.id];
                                row.employmentStartDate = employee.employmentStartDate ? moment(employee.employmentStartDate) : null;
                                row.employmentEndDate = employee.employmentEndDate ? moment(employee.employmentEndDate) : null;
                                row.email = employee.email;
                                return row;
                            }).sort((a: any, b: any) => {
                                if (this.state.filters.orderBy.order === ORDER.ASC)
                                    return a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
                                else
                                    return b.fullName.toLowerCase().localeCompare(a.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
                            }).filter((row: any) => {
                                return usersMap[row.id].status !== USERSTATUS.DISABLED;
                            }),
                            status: STATUS.READY
                        };
                        this.setState({ rows });
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                rows: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        return <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-sm-9 col-xs-12 col-md-7 mb-3">
                    <Tabs currentTab={REPORTSTABS.GENERAL} onChange={(value: any) => { if (this.props.onTabChange) { this.props.onTabChange(value); } }} />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <Line />
                </div>
            </div>
            <Filters
                download={(new Download({ filters: this.state.filters }))}
                defaultStartDate={this.state.filters.startDate.value}
                defaultEndDate={this.state.filters.endDate.value}
                defaultUnits={this.state.filters.units.value}
                defaultLeaveTypes={this.state.filters.leaveTypes.value}
                viewMode={this.state.filters.viewMode}
                orderBy={this.state.filters.orderBy}
                onChange={(value: any) => {
                    this.setState(
                        { filters: Object.assign({}, value) },
                        () => { this.search(); }
                    );
                }}
                onTabChange={(value: any) => {
                    if (this.props.onTabChange) {
                        this.props.onTabChange(value);
                    }
                }}
            />
            <div className="row">
                <div className="col-12 mb-3">
                    <Table
                        rows={this.state.rows}
                        startDate={this.state.filters.startDate.value}
                        endDate={this.state.filters.endDate.value}
                        leaveTypes={this.getLeaveTypes()}
                    />
                </div>
            </div>
        </div>
    }

    static getLeaveTypes(this: any) {
        const caches: any = this.getCaches().getState();
        return (this.state.filters.leaveTypes === undefined ||
            this.state.filters.leaveTypes.value === undefined ||
            this.state.filters.leaveTypes.value.length === 0) ?
            caches.leaveTypes.active.arr
            : this.state.filters.leaveTypes.value.map((id: any) => { return caches.leaveTypes.active.map[id]; });
    }
}