import BaseTabs from '../../Components/BaseTabs';
import { UNITTABS } from '../../General';

export default class Tabs extends BaseTabs {
    getTabs(): any[] {
        const t = this.translate();
        return [
            {
                faIcon: 'fas fa-users',
                title: t('members'),
                tab: UNITTABS.MEMBERS
            },
            {
                faIcon: 'fas fa-wrench',
                title: t('settings'),
                tab: UNITTABS.SETTINGS
            }
        ];
    }
    getName(): string {
        return 'Unit';
    }
}