import BaseDownload from '../../../Components/BaseDownload';
import { findLocaleName } from '../../../Utils';
import Manager from './Manager';

/* eslint-disable @typescript-eslint/no-unused-expressions */
export default class Download extends BaseDownload {
    public search: any;
    public computeDays: any;
    public computeRows: any;
    public getLeaveTypes: any;
    constructor(options: any) {
        super(options);
        this.search = Manager.search.bind(this);
        this.computeDays = Manager.computeDays.bind(this);
        this.computeRows = Manager.computeRows.bind(this);
        this.getLeaveTypes = Manager.getLeaveTypes.bind(this);
    }

    getData() {
        const t = this.t;
        const session = this.getSession().getState() as any;
        const companyId = session.company.id;
        const companyCountry = session.company.country;
        const header = companyId === 9118 ? [t('email'), t('employee')] : [t('employee')];
        this.state.days.arr.forEach((day: any) => { header.push(day.literal + ' ' + day.text); })
        if (session.company.id === 14464) {
        } else {
            header.push(t('hours'));
        }
        const leaveTypeMap = new Map();
        this.state.leaveTypes.arr.forEach((leaveType: any) => {
            leaveTypeMap.set(leaveType.id, leaveType);
            leaveType.acronym = this.generateAcronym(this.getName(leaveType));
        });
        this.state.leaveTypes.arr.forEach((leaveType: any) => {
            header.push(leaveType.acronym + ' ' + this.getName(leaveType));
        });
        const phAcronym = this.generateAcronym(t('public.holidays'))
        header.push(phAcronym + ' ' + t('public.holidays'));
        const result = [header];
        const data: any = [];
        this.state.rows.arr.forEach((row: any) => {
            let totalHours = 0;
            let ph = 0;
            let dataRow = (companyId === 9118 ? [row.employee.email, row.employee.fullName] : [row.employee.fullName]).concat(row.dayEvents.map((dayEvent: any) => {
                if (dayEvent.leaveTypeId) {
                    if (leaveTypeMap.get(dayEvent.leaveTypeId)?.working) {
                        totalHours += dayEvent.workingHours;
                        return dayEvent.scheduled + '/' + dayEvent.hours + '' + leaveTypeMap.get(dayEvent.leaveTypeId).acronym;
                    } else {
                        if (dayEvent.hours) {
                            if (leaveTypeMap.get(dayEvent.leaveTypeId)?.acronym) {
                                totalHours += dayEvent.scheduled - dayEvent.hours;
                                if (dayEvent.dayType === 3) {
                                    return dayEvent.scheduled + '/' + dayEvent.hours + ' ' + leaveTypeMap.get(dayEvent.leaveTypeId).acronym;
                                } else {
                                    return dayEvent.hours + '/' + leaveTypeMap.get(dayEvent.leaveTypeId).acronym;
                                }
                            }
                        }
                        else
                            if (leaveTypeMap.get(dayEvent.leaveTypeId)?.acronym)
                                return leaveTypeMap.get(dayEvent.leaveTypeId).acronym;
                    }
                }
                if (dayEvent.isPH) {
                    ph += 1;
                    return phAcronym;
                }
                totalHours += dayEvent.hours;
                return dayEvent.hours;
            }))
            if (session.company.id === 14464) {
            } else {
                dataRow = dataRow.concat(totalHours);
            }
            data.push(dataRow.concat(
                this.state.leaveTypes.arr.map((leaveType: any) => {
                    return row.leaveTypes[leaveType.id];
                })).concat(ph));
            if (companyId === 7110 || companyId === 2206 || companyId === 2783 || companyId === 2784
                || companyId === 11635
                || companyId === 2924 || (companyId === 42 && companyCountry === 'RO') || (companyCountry === 'RO' && companyId > 13580)) {
                let scheduleRow = [''].concat(row.dayEvents.map((dayEvent: any) => {
                    if (dayEvent.leaveTypeId && leaveTypeMap.has(dayEvent.leaveTypeId) && !leaveTypeMap.get(dayEvent.leaveTypeId).working) {
                        if (dayEvent.hours) {
                            if (dayEvent.dayType === 1) {
                                return dayEvent.scheduleFirst;
                            }
                            if (dayEvent.dayType === 2) {
                                return dayEvent.scheduleSecond;
                            }
                            return '';
                        }
                        else
                            return '';
                    }
                    if (dayEvent.isPH) {
                        return '';
                    }
                    return dayEvent.schedule ? dayEvent.schedule : '';
                }))
                data.push(scheduleRow);
            }
        });
        return result.concat(data).concat(
            [[t('attendance.for') + ' ' + this.options.filters.startDate.value.format(this.getDateFormat()) + ' - ' + this.options.filters.endDate.value.format(this.getDateFormat())]]
        );
    }

    public isReady() {
        return this.state?.rows?.status === 'READY';
    }

    getFileName(this: any): any {
        const t = this.t;
        return this.ansi(t('attendance')) + this.getSufixFileName();
    }

    public getName(leaveType: any) {
        return findLocaleName(leaveType.names, this.language());
    }

    public generateAcronym(name: any) {
        let acronym = '';
        let words = name.split(' ');
        for (let word of words) {
            if (word != null) {
                acronym = acronym + word.substring(0, 1);
            }
        }
        return acronym.toUpperCase();
    }
}