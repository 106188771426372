import * as React from 'react';
import Component from '../../../Component';
import Display from './Display';
import Form from './Form';
import Header from '../Header/Header';

export default class Settings extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            unit: this.props.unit,
            editMode: false
        };
    }

    render() {
        return (
            <div className="w-100">
                <Header
                    unit={this.state.editMode ? this.state.unit : this.props.unit}
                    currentTab={this.props.currentTab}
                    onTabChange={(tab: any) => { if (this.props.onTabChange) { this.props.onTabChange(tab); } }}
                />
                <div className="col-12 mb-3">
                    {
                        (this.state.editMode) ?
                            <Form
                                unit={this.props.unit}
                                onUpdate={(unit: any) => { this.setState({ unit: unit }); }}
                                onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }}
                            /> : <Display
                                unit={this.props.unit}
                                onEdit={() => { this.setState({ editMode: !this.state.editMode }); }}
                                onRefresh={() => { if (this.props.onRefresh()) { this.props.onRefresh(); } }}
                            />
                    }
                </div>
            </div>
        );
    }
}