import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../Component';
import Line from '../../Components/Line';
import { filtersFormUrlFactory, leavesFactory } from '../../Factories';
import { LEAVESTATUS, ORDER, STATUS, VIEWMODE } from '../../General';
import Filters from './Filters';
import List from './List';
import { Cookie } from '../../Utils';

export default class LeavesToApprove extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            filters: this.createFilters(),
            leaves: {
                arr: [],
                status: STATUS.LOADING
            }
        };
    }

    createFilters() {
        const moment = this.getMomentWithLocale();
        let viewMode = Cookie.read(Cookie.LEAVES_TO_APPROVE_VIEWMODE);
        if (!viewMode) {
            viewMode = VIEWMODE.GRID;
            Cookie.create(Cookie.LEAVES_TO_APPROVE_VIEWMODE, viewMode);
        }
        let filters = {
            viewMode,
            orderBy: { field: 'DATE', order: ORDER.ASC },
            users: {
                value: [],
            },
            units: {
                value: [],
            },
            leaveStatuses: {
                value: [LEAVESTATUS.AWAITING_APPROVAL]
            },
            leaveTypes: {
                value: [],
            },
            startDate: { value: moment().startOf('day').add(-1, 'year') },
            endDate: { value: (moment().startOf('day').add(1, 'year')).add(31 - 1, 'days') }
        };
        filters = Object.assign({}, filters, filtersFormUrlFactory());
        return filters;
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState(
            { leaves: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getLeavesToApprove({
                        filters: this.state.filters
                    }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            {
                                leaves: {
                                    arr: leavesFactory(data.data, (this.getCaches().getState() as any).users)
                                        .sort((a: any, b: any) => {
                                            return this.compareString(a.startDate, b.startDate, this.state.filters.orderBy.order);
                                        }),
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                leaves: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        const t = this.translate();
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-3">
                        <h5 className="mb-0">{t('leaves.to.approve')}</h5>
                    </div>
                </div>
                <div className="row"><div className="col-12 mb-3"><Line /></div></div>
                <Filters
                    defaultStartDate={this.state.filters.startDate.value}
                    defaultEndDate={this.state.filters.endDate.value}
                    defaultLeaveStatuses={this.state.filters.leaveStatuses.value}
                    defaultLeaveTypes={this.state.filters.leaveTypes.value}
                    viewMode={this.state.filters.viewMode}
                    orderBy={this.state.filters.orderBy}
                    onChange={(value: any) => {
                        this.setState({ filters: Object.assign({}, value) }, () => { this.search(); });
                    }}
                />
                <div className="row">
                    <List leaves={this.state.leaves} viewMode={this.state.filters.viewMode} onRefresh={() => { this.search(); }} />
                </div>
            </div>
        );
    }
}