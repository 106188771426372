import * as React from 'react';
import Component from '../../../Component';
import LeaveDates from '../../../Components/Leave/LeaveDates';
import LeaveName from '../../../Components/Leave/LeaveName';
import LeaveStatus from '../../../Components/Leave/LeaveStatus';
import { LEAVESTATUS } from '../../../General';
import LeaveEmployee from '../../Reports/LeaveEmployee';

export default class Item extends Component {

    render() {
        var leave = this.props.leave;
        return (
            <tr>
                <td>
                    <div>
                        <LeaveEmployee
                            employee={leave.companyUser}
                        />
                        <span className="float-right">
                            <LeaveName
                                leave={leave}
                            />
                        </span>
                    </div>
                    <div className="clearfix" />
                    <div>
                        <LeaveDates
                            leave={leave}
                        />
                        {
                            (leave.status === LEAVESTATUS.AWAITING_APPROVAL) ?
                                (
                                    <span className="float-right">

                                        <LeaveStatus
                                            leave={leave}
                                        />
                                    </span>
                                )
                                :
                                ''
                        }
                    </div>
                </td>
            </tr>
        );
    }

    getLeaveCardColor(leave: any) {
        switch (leave.status) {
            case LEAVESTATUS.AWAITING_APPROVAL:
                return 'border-warning';
            case LEAVESTATUS.APPROVED:
                return 'border-success';
            case LEAVESTATUS.REJECTED:
                return 'border-danger';
            case LEAVESTATUS.CANCELED:
                return 'border-dark';
            default:
                return 'border-dark';
        }
    }
}