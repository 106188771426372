import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../Component';
import A from '../../Components/A';
import ETOL from '../../Components/ETOL';
import Line from '../../Components/Line';
import { fullNameFactory } from '../../Factories';
import { ETOLTABS, ORDER, STATUS, VIEWMODE } from '../../General';
import Filters from './Filters';
import List from './List';

export default class Units extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            filters: {
                search: { value: '' },
                viewMode: VIEWMODE.GRID,
                orderBy: { field: 'NAME', order: ORDER.ASC }
            },
            units: {
                arr: [],
                status: STATUS.LOADING
            }
        };
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState(
            {
                units: {
                    arr: [],
                    status: STATUS.LOADING
                }
            },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getUnits({ filters: this.state.filters }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            {
                                units: {
                                    arr: this.prepareUnits(data.data).sort((a: any, b: any) => {
                                        return this.compareString((a.name).toLowerCase(), (b.name).toLowerCase(), this.state.filters.orderBy.order);
                                    }),
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                units: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        const t = this.translate();
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-3">
                        <ETOL currentTab={ETOLTABS.UNITS} />
                        <A
                            faIcon="fa fa-plus"
                            href={this.Urls().getUnitAdd()}
                            className="btn btn-outline-primary float-right"
                            title={t('add.team')}
                        >
                            {this.isScreenSizeSmall() ? undefined : t('add.team')}
                        </A>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <Line />
                    </div>
                </div>
                <Filters
                    viewMode={this.state.filters.viewMode}
                    orderBy={this.state.filters.orderBy}
                    onChange={(value: any) => {
                        this.setState(
                            { filters: Object.assign({}, value) },
                            () => { this.search(); }
                        );
                    }}
                />
                <div className="row">
                    <List
                        units={this.state.units}
                        viewMode={this.state.filters.viewMode}
                        onRefresh={() => { this.search(); }}
                    />
                </div>
            </div>
        );
    }

    prepareUnits(units: any[]) {
        const users = (this.getCaches().getState() as any).users;
        return units.map((unit) => {
            unit.manager = (unit.managerCompanyUser && unit.managerCompanyUser.id > 0) ?
                (this.getCaches().getState() as any).users.map[unit.managerCompanyUser.id] : {};
            unit.members = [];
            unit.members = users.arr.filter((user: any) => {
                return (user.companyUnit && user.companyUnit.id === unit.id && (user.status === 'ACTIVE' || user.status === 'INVITED')) ? true : false;
            }).sort((a: any, b: any) => {
                return a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
            })
            const session = this.getSession().getState() as any;
            if (unit.managerCompanyUser?.id) {
                unit.managerCompanyUser.fullName = fullNameFactory(unit.managerCompanyUser, session.company.nameFormat);
            }
            return unit;
        });
    }
}