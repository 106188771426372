import * as React from 'react';
import Datetime from 'react-datetime';

// https://github.com/YouCanBookMe/react-datetime
export default class DatePickerField extends React.Component<any, any> {
    public static CID = 0;
    private id: string;
    constructor(props: any) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.id = 'DateTimePickerField' + DatePickerField.CID++;
    }

    public render() {
        const className = (this.props.isInvalid ? 'is-invalid ' : '') + (this.props.isValid ? 'is-valid ' : '') + 'form-control';
        return (
            <div className="w-100">
                {this.props.placeholder ?
                    <label htmlFor={this.id + ''}>{this.props.placeholder}</label>
                    : null}
                <Datetime {...this.props} inputProps={{ className }} placeholder={this.props.placeholder} closeOnSelect={true} />
                {this.props.invalidMessage ? <div className="date-time-invalid-feedback">{this.props.invalidMessage} </div> : null}
            </div>
        );
    }

    public handleChange(event: any) { this.props.onChange(event.target.value); }
    public handleBlur(event: any) { if (this.props.onBlur) { this.props.onBlur(event.target.value); } }
}