import * as React from 'react';
import Component from '../../Component';
import * as $ from 'jquery';
import { STATUS } from '../../General';
import Line from '../../Components/Line';
import Back from '../../Components/Back';
import { COMPANYTABS } from '../../General';
import CreateOrder from './CreateOrder';
import ReviewAndOrder from './ReviewAndOrder';
import Ordered from './Ordered';

enum WIZZARDSTEPS {
    CREATE_ORDER = 'CREATE_ORDER',
    REVIEW_AND_ORDER = 'REVIEW_AND_ORDER',
    ORDERED = 'ORDERED',
}

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            orderSettings: {
                value: undefined,
                status: STATUS.LOADING
            },
            subscriptionOrder: {
                value: undefined,
                status: STATUS.LOADING
            },
            wizzardStep: WIZZARDSTEPS.CREATE_ORDER
        };
    }

    componentDidMount() {
        this.getOrderSettings();
        this.getSubscriptionOrderDraft();
    }

    getOrderSettings() {
        $.ajax({
            type: 'GET',
            contentType: 'application/json',
            url: this.Endpoints().getOrderSettings(),
            dataType: 'json',
            cache: false,
            success: (data, textStatus, jqXHR) => {
                this.setState({
                    orderSettings: {
                        value: data,
                        status: STATUS.READY
                    }
                });
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
                this.setState({
                    orderSettings: {
                        value: undefined,
                        status: STATUS.ERROR
                    },
                });
            },
        });
    }

    getSubscriptionOrderDraft() {
        $.ajax({
            type: 'GET',
            contentType: 'application/json',
            url: this.Endpoints().getSubscriptionOrderDraft(),
            dataType: 'json',
            cache: false,
            success: (data, textStatus, jqXHR) => {
                this.setState({
                    subscriptionOrder: {
                        value: data,
                        status: STATUS.READY
                    }
                });
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
                this.setState({
                    subscriptionOrder: {
                        value: undefined,
                        status: STATUS.ERROR
                    },
                });
            },
        });
    }

    render() {
        const session: any = this.getSession().getState();
        const caches: any = this.getCaches().getState();
        let ready = session.status === STATUS.READY &&
            caches.users.status === STATUS.READY &&
            this.state.subscriptionOrder.status === STATUS.READY &&
            this.state.orderSettings.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        const t = this.translate();
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Back href={this.Urls().getCompany({ tab: COMPANYTABS.SUBSCRIPTION })} />
                        <h5 className="mb-0 d-inline">{t('order.subscription')}</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <Line />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        {this.getTab()}
                    </div>
                </div>
            </div>
        );
    }

    getTab() {
        switch (this.state.wizzardStep) {
            case WIZZARDSTEPS.REVIEW_AND_ORDER:
                return (
                    <ReviewAndOrder
                        subscriptionOrder={this.state.subscriptionOrder.value}
                        orderSettings={this.state.orderSettings.value}
                        onOrdered={() => { this.setState({ wizzardStep: WIZZARDSTEPS.ORDERED }); }}
                    />
                );
            case WIZZARDSTEPS.ORDERED:
                return (
                    <Ordered />
                );
            default:
                return (
                    <CreateOrder
                        subscriptionOrder={this.state.subscriptionOrder.value}
                        orderSettings={this.state.orderSettings.value}
                        onChange={(subscriptionOrder: any) => {
                            this.setState({ subscriptionOrder: { value: subscriptionOrder, status: STATUS.READY } });
                        }}
                        onOrder={(subscriptionOrder: any) => {
                            this.setState({ subscriptionOrder: { value: subscriptionOrder, status: STATUS.READY }, wizzardStep: WIZZARDSTEPS.REVIEW_AND_ORDER });
                        }}
                    />
                );
        }
    }
}