import * as React from 'react';
import Component from '../../../Component';
import Back from '../../../Components/Back';
import Article from './Article';

export default class KnowledgeBase extends Component {
    constructor(props?: any) {
        super(props);
        this.state = { selectedCategory: null, selectedArticle: null };
    }

    render() {
        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <div className="row">
                    {this.state.selectedCategory === null && this.state.selectedArticle === null ?
                        null : <div className="col-12 mb-3">
                            <Back onClick={() => {
                                if (this.state.selectedArticle !== null) {
                                    this.setState({ selectedArticle: null });
                                } else {
                                    this.setState({ selectedCategory: null });
                                }
                                if (this.state.selectedCategory === null) {
                                    this.setState({ selectedCategory: null });
                                }
                            }}
                            />
                        </div>}
                    <div className="col-12">
                        {this.state.selectedCategory === null && this.state.selectedArticle === null ?
                            this.getCategories() : null}
                        {this.state.selectedCategory !== null && this.state.selectedArticle === null ?
                            this.categoryArticles() : null}
                        {this.state.selectedCategory !== null && this.state.selectedArticle !== null ?
                            <Article article={this.props.support.categories[this.state.selectedCategory].articles[this.state.selectedArticle]} />
                            : null}
                    </div>
                </div>
            </div>
        </div>
    }

    getCategories() {
        let i = 0;
        const categories = this.props.support.categories.map((category: any) => {
            const pos = i++;
            return <li key={category.name}>
                <a href="##" className="card-link" onClick={(event: any) => {
                    event.preventDefault();
                    this.setState({ selectedCategory: pos });
                }}>
                    {category.name}
                </a>
            </li>
        });
        return <div className="list-unstyled">
            {categories}
        </div>
    }

    categoryArticles() {
        let i = 0;
        const articles = this.props.support.categories[this.state.selectedCategory].articles.map((article: any) => {
            const pos = i++;
            return <li key={article.title}>
                <a href="##" className="card-link" onClick={(event: any) => {
                    event.preventDefault();
                    this.setState({ selectedArticle: pos });
                }}>
                    {article.title}
                </a>
            </li>
        });
        return <div className="list-unstyled">
            {articles}
        </div>
    }
}