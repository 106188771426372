import * as React from 'react';
import Component from '../../Component';
import { STATUS } from '../../General';
import Manager from './Manager';
import PaymentWarning from '../../Components/PaymentWarning';

export default class Employees extends Component {

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.units.status === STATUS.READY &&
            caches.countries.status === STATUS.READY &&
            caches.units.status === STATUS.READY &&
            (this.getSession().getState() as any).status === STATUS.READY;
        if (ready === false) { return this.renderLoading(); }
        if (this.paymentRequired()) { return null; }
        if (this.trialHasEnded()) { return null; }
        if (this.paymentWarning()) { return [<PaymentWarning key="PaymentWarning" />, <Manager key='Manager' />] }
        return (<Manager />);
    }
}