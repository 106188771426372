import * as React from 'react';
import Component from '../../Component';
import { LEAVEDAYTYPE } from '../../General';
import Date from '../Date';

export default class LeaveDates extends Component {
    render() {
        const moment = this.getMomentWithLocale();
        const leave = this.props.leave;
        const startDate = moment(leave.startDate);
        const endDate = moment(leave.endDate);
        switch (leave.dayType) {
            case LEAVEDAYTYPE.FULL:
                return <span>{
                    ((leave.amount === 1) || (endDate.diff(startDate, 'd') === 0)) ?
                        <Date date={endDate ? endDate : startDate} /> : <><Date date={startDate} />  - <Date date={endDate} /></>
                }</span>
            case LEAVEDAYTYPE.FIRST_PART:
            case LEAVEDAYTYPE.SECOND_PART:
            case LEAVEDAYTYPE.HOURS:
                return (<Date date={startDate} />);
            default:
                return '';
        }
    }
}