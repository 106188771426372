import * as React from 'react';
import A from './A';

export default class DownloadLink extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    public handleClick() { this.props.onDownloadTrigger(this.props.format) }

    public render() {
        return (<A className="d-block ml-0" href="###" onClick={this.handleClick}>{this.props.format}</A>);
    }
}