import * as React from 'react';
import Component from '../../Component';

export default class UserNotFound extends Component {

    render() {
        const t = this.translate();
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-3">
                        {t('your.account.is.not.associated.with.any.company')}
                        {t('please')}
                        <a className="ml-1 mr-1 card-link" href="/contact/" title={t('contact.us')}>
                            {t('contact.us')}
                        </a>
                        {t('if this is an error')}
                    </div>
                </div>
            </div>
        );
    }
}