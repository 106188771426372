import * as React from 'react';
import Component from '../../../Component';
import LeaveTypeName from '../../../Components/LeaveType/LeaveTypeName';

export default class Item extends Component {

    render() {
        var row = this.props.row;
        var leaveTypePerYear = row;
        //        const year = leaveTypePerYear.data[4];
        const allowance = leaveTypePerYear.data[9];
        const adjustment = leaveTypePerYear.data[10];
        const receivedFromLastYear = leaveTypePerYear.data[11];
        const transferredToNextYear = leaveTypePerYear.data[12];
        return <tr>
            <td style={{ width: '75', overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                <LeaveTypeName leaveType={row.leaveType} />
            </td>
            <td className="text-right text-lowercase">
                {row.data[6] ? row.data[6] : 0}
            </td>
            <td className="text-right text-lowercase">
                {this.getEmployeeAllowanceValue(allowance, adjustment, receivedFromLastYear) - (row.data[6] ? row.data[6] : 0) - transferredToNextYear}
            </td>
            <td className="text-right text-lowercase">
                {this.getEmployeeAllowance(allowance, adjustment, receivedFromLastYear)}
            </td>
        </tr>
    }
    getEmployeeAllowanceValue(allowance: any, adjustment: any, receivedFromLastYear: any) {
        return allowance + adjustment + (receivedFromLastYear ? receivedFromLastYear : 0);
    }

    getEmployeeAllowance(allowance: any, adjustment: any, receivedFromLastYear: any) {
        const value = this.getEmployeeAllowanceValue(allowance, adjustment, receivedFromLastYear)
        return (<span>{value}</span>);
    }
}