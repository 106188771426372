import * as React from 'react';
import { revertTransferLeaveTypeAllowance, transferLeaveTypeAllowance } from '../../../Actions/Modal';
import Anchor from '../../../Components/Anchor';
import ChooseYear from '../../../Components/ChooseYear';
import Error from '../../../Components/Error';
import { STATUS } from '../../../General';
import LeaveTypesBalanceTable from '../../Dashboard/LeaveTypesBalance/Table';
import Item from './Item';

export default class Table extends LeaveTypesBalanceTable {
    constructor(props?: any) {
        super(props);
        this.handleRefresh = this.handleRefresh.bind(this);
        this.revertTransfer = this.revertTransfer.bind(this);
        this.transfer = this.transfer.bind(this);
    }

    handleRefresh() {
        if (this.props.onRefresh) { this.props.onRefresh(); }
    }


    transfer() {
        transferLeaveTypeAllowance(this.getModal(), {
            leaveType: this.props.leaveType,
            year: this.props.currentYear,
            callback: () => { if (this.props.onRefresh) { this.props.onRefresh(); } }
        });
        return;
    }

    revertTransfer() {
        revertTransferLeaveTypeAllowance(this.getModal(), {
            leaveType: this.props.leaveType,
            year: this.props.currentYear,
            callback: () => { if (this.props.onRefresh) { this.props.onRefresh(); } }
        });
        return;
    }

    render() {
        const rows = this.props.rows;
        switch (rows.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                const rowsComps = rows.arr.map((row: any) => {
                    return (
                        <Item
                            key={'EmployeeAllowancesDisplayTable-' +
                                this.props.leaveType.id + '-' + row.employee.id}
                            employeeLeaveTypePerYear={row}
                            leaveType={this.props.leaveType}
                            onRefresh={this.handleRefresh}
                        />
                    );
                });
                const currentYear = this.props.currentYear;
                return (
                    <table className="table table-sm w-100 mb-0">
                        <thead>
                            <tr>
                                <th className="border-top-0">
                                    <ChooseYear
                                        currentYear={currentYear}
                                        years={[currentYear - 1, currentYear, currentYear + 1]}
                                        onChangeYear={(year: any) => { if (this.props.onChangeYear) { this.props.onChangeYear(year); } }}
                                    />
                                    <div>
                                        {currentYear === 2021 ?
                                            <Anchor title={t('transfer.from.2021.to.2022')} onClick={this.transfer} className="d-inline-block mr-2">
                                                {2021}<i className={'fas fa-long-arrow-alt-right fa-fw'} aria-hidden="true" />{2022}
                                            </Anchor>
                                            : null}
                                        {currentYear === 2021 ?
                                            <Anchor title={t('revert.transfer.from.2021.to.2022')} onClick={this.revertTransfer} className="d-inline-block m-0">
                                                {2021}<i className={'fas fa-long-arrow-alt-left fa-fw'} aria-hidden="true" />{2022}
                                            </Anchor>
                                            : null}
                                    </div>
                                    <div>
                                        {currentYear === 2022 ?
                                            <Anchor title={t('transfer.from.2022.to.2023')} onClick={this.transfer} className="d-inline-block mr-2">
                                                {2022}<i className={'fas fa-long-arrow-alt-right fa-fw'} aria-hidden="true" />{2023}
                                            </Anchor>
                                            : null}
                                        {currentYear === 2022 ?
                                            <Anchor title={t('revert.transfer.from.2022.to.2023')} onClick={this.revertTransfer} className="d-inline-block m-0">
                                                {2022}<i className={'fas fa-long-arrow-alt-left fa-fw'} aria-hidden="true" />{2023}
                                            </Anchor>
                                            : null}
                                    </div>
                                    <div>
                                        {currentYear === 2023 ?
                                            <Anchor title={t('transfer.from.2023.to.2024')} onClick={this.transfer} className="d-inline-block mr-2">
                                                {2023}<i className={'fas fa-long-arrow-alt-right fa-fw'} aria-hidden="true" />{2024}
                                            </Anchor>
                                            : null}
                                        {currentYear === 2023 ?
                                            <Anchor title={t('revert.transfer.from.2023.to.2024')} onClick={this.revertTransfer} className="d-inline-block m-0">
                                                {2023}<i className={'fas fa-long-arrow-alt-left fa-fw'} aria-hidden="true" />{2024}
                                            </Anchor>
                                            : null}
                                    </div>
                                </th>
                                <th className="border-top-0 text-right align-text-top" colSpan={2}>
                                    <div className={currentYear === 2019 ? 'mb-1 pb-1' : ''}>{t('allowance')}</div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowsComps}
                        </tbody>
                    </table>
                );
            }
            default:
                return (<Error />);
        }
    }
}