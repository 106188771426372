import * as XLSX from 'xlsx';
import Endpoints from '../Endpoints';
import { ORDER } from '../General';
import caches from '../Reducers/Caches';
import i18n from '../Reducers/I18n';
import session from '../Reducers/Session';
import { Jqxhrs } from '../Utils';

const moment: any = () => {
    return (i18n.getState() as any).moment();
}


/* eslint-disable @typescript-eslint/no-unused-expressions */
export default abstract class BaseDownload {
    public t: any;
    protected jqxhrs = new Jqxhrs();
    protected search: any;
    protected jqxhr: any;
    protected format: any;
    protected state: any;
    private now: any;
    protected options: any;
    protected filters: any;
    protected downloadNow: any;
    protected finishCallback: any;
    constructor(options: any) {
        this.options = options;
        this.format = options.format;
        this.filters = Object.assign({}, options.filters);
        if (this.filters.limit) { delete this.filters.limit; }
        if (this.filters.offset) { delete this.filters.offset; }
        this.state = { data: { arr: null, status: 'LOADING', count: 0 } };
        this.state.filters = this.filters;
        this.now = moment();
        this.t = (i18n.getState() as any).translate;
    }

    setState(state: any, callback: any) {
        this.state = state;
        this.state.filters = this.filters;
        if (this.isReady()) {
            this.generate();
            this.finishCallback();
        }
        if (callback) {
            callback();
        }
    }

    public isReady() {
        return this.state.data && this.state.data.status === 'READY';
    }

    download(options?: any) {
        options && options.format ? this.format = options.format : null;
        options && options.format ? this.finishCallback = options.finishCallback : null;
        if (this.search) this.search();
    }

    generate() {
        const t = this.t;
        let ws_data = this.getData();
        let wb = XLSX.utils.book_new();
        if (!wb.Props) wb.Props = {};
        (wb.Props as any).title = this.getFileName();
        let ws = XLSX.utils.aoa_to_sheet(ws_data);
        XLSX.utils.book_append_sheet(wb, ws, t('data'));
        XLSX.utils.book_append_sheet(wb, this.getWorkSheetDetails(), t('details'));
        XLSX.writeFile(wb, this.getFileName(), { Props: { Author: 'LeaveBoard.com', Title: this.getFileName() } });
    }

    public abstract getData(): any;
    public abstract getFileName(): any;

    protected getWorkSheetDetails(): any {
        const t = this.t;
        return XLSX.utils.aoa_to_sheet([
            ['https://leaveboard.com/'],
            [t('generated.on') + ' : ' + this.now.toISOString()]
        ]);
    }

    protected ansi(str: any) {
        return str.replace(/[^\x20-\x7E]/g, '-').replace(/[<>:"/\\|?*]+/g, '-').replace(/ /g, "-");;
        //        return str.replace(/[^\x20-\x7E]/g, '-').replace(/[<>:"\/\\|?*]+/g, '-').replace(/ /g, "-");;
    }

    protected getSufixFileName() {
        return '-' + this.now.format('YYYY-MM-DD') + '-' + this.now.format('X') + this.getFormatExtension();
    }

    private getFormatExtension() {
        switch (this.format) {
            case 'XLS':
                return '.xls';
            case 'CSV':
                return '.csv';
            case 'HTML':
                return '.html';
            default:
                return '.xlsx'
        }
    }

    public getMomentWithLocale() {
        return (i18n.getState() as any).moment;
    }
    public Endpoints() {
        return Endpoints;
    }

    public componentWillUnmount() { this.jqxhrs.abortAll(); }

    getDateFormat() {
        let dateFormat: any = (this.getSession().getState() as any)?.companyUser?.dateFormat;
        dateFormat = dateFormat === null ? 'L' : dateFormat;
        dateFormat = dateFormat === 'browser.default' ? 'L' : dateFormat;
        return dateFormat;
    }

    getCaches() {
        return caches;
    }

    getSession() {
        return session;
    }

    language() {
        return (i18n.getState() as any).language;
    }

    compareString(a: any, b: any, order: any) {
        if (this.state.filters.orderBy.order === ORDER.ASC) {
            return (a < b ? -1 : (a > b ? 1 : 0));
        } else {
            return (b < a ? -1 : (b > a ? 1 : 0));
        }
    }
}