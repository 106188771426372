import * as $ from 'jquery';
// import { fetchUnitsFromServer, cleanUnits, fetchUsersFromServer, cleanUsers } from '../Actions/Caches';
import {
    doFetchLeaveTypesFromServer, doFetchOfficesFromServer, doFetchSubsidiariesFromServer, doFetchUnitsFromServer, doFetchUsersFromServer
} from '../Actions/Caches';
import { SESSION_ACTIONS } from '../Constants/ActionTypes';
import Endpoints from '../Endpoints';
import { STATUS } from '../General';
import caches from '../Reducers/Caches';

export const sessionLoading = (session: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: SESSION_ACTIONS.LOADING,
            status: STATUS.LOADING
        });
    })(session.dispatch, session.getState);
};

export const sessionError = (session: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: SESSION_ACTIONS.ERROR,
            status: STATUS.ERROR
        });
    })(session.dispatch, session.getState);
};

export const sessionNotLogged = (session: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: SESSION_ACTIONS.NOT_LOGGED,
            logged: false,
            status: STATUS.READY
        });
    })(session.dispatch, session.getState);
    //    fetchUnitsFromServer(store);
    //    fetchUsersFromServer(store);
};

export const sessionLogin = (session: any, user: any, companyUser: any, company: any, permissions: any) => {
    ((dispatch, getState, userX, companyUserX, companyX, permissionsX) => {
        dispatch({
            type: SESSION_ACTIONS.LOGIN,
            user: userX,
            companyUser: companyUserX,
            company: companyX,
            permissions: permissionsX,
            logged: true,
            status: STATUS.READY
        });
    })(session.dispatch, session.getState, user, companyUser, company, permissions);
    doFetchUsersFromServer(caches, { company });
    doFetchUnitsFromServer(caches);
    doFetchLeaveTypesFromServer(caches);
    doFetchOfficesFromServer(caches);
    doFetchSubsidiariesFromServer(caches);
};

export const sessionLogout = (store: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: SESSION_ACTIONS.LOGOUT,
            logged: false,
            status: STATUS.READY
        });
    })(store.dispatch, store.getState);
    //    cleanUnits(store);
    //    cleanUsers(store);
};

export const doLoadSession = (session: any, hideLoading?: any) => {
    if (hideLoading) { sessionLoading(session); }
    $.ajax({
        type: 'GET',
        contentType: 'application/json',
        url: Endpoints.getLogged(),
        dataType: 'json',
        cache: false,
        success: (data, textStatus, jqXHR) => {
            sessionLogin(session, data.user, data.companyUser, data.company, data.permissions);
        },
        error: (jqXHR, textStatus, errorThrown) => {
            if (+jqXHR.status === 401 || +jqXHR.status === 404) {
                sessionNotLogged(session);
                return;
            }
            // errorGeneral(store);
        }
    });
};

export const doRefreshSession = (session: any, hideLoading?: any) => {
    if (hideLoading) { sessionLoading(session); }
    $.ajax({
        type: 'GET',
        contentType: 'application/json',
        url: Endpoints.getRefreshed(),
        dataType: 'json',
        cache: false,
        success: (data, textStatus, jqXHR) => {
            sessionLogin(session, data.user, data.companyUser, data.company, data.permissions);
        },
        error: (jqXHR, textStatus, errorThrown) => {
            if (+jqXHR.status === 401 || +jqXHR.status === 404) {
                sessionNotLogged(session);
                return;
            }
            // errorGeneral(store);
        }
    });
};