import * as React from 'react';
import Component from '../../../Component';
import Submit from '../../../Components/Submit';
import TextareaField from '../../../Components/TextareaField';
import LeaveName from '../../../Components/Leave/LeaveName';
import LeaveDetailedStatus from '../../../Components/Leave/LeaveDetailedStatus';
import LeaveInterval from '../../../Components/Leave/LeaveInterval';
import * as $ from 'jquery';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            note: {
                touched: false,
                rules: this.Rules().MaxLengthTextareaRules()
            },
            send: {
                pleaseWait: false
            },
        };
    }

    render() {
        const t = this.translate();
        const leave = this.props.leave;
        return (
            <form
                onSubmit={(event: any) => { this.submit(event); }}
            >
                <div className="row">
                    <div className="col-12 mb-3">
                        <div>
                            <LeaveName
                                leave={leave}
                            />
                        </div>
                        <div>
                            <LeaveInterval
                                leave={leave}
                            />
                        </div>
                        <div>
                            <LeaveDetailedStatus
                                leave={leave}
                            />
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <TextareaField
                            touched={this.state.note.touched}
                            rules={this.state.note.rules}
                            type="text"
                            rows={2}
                            focus={this.isScreenSizeSmall() ? false : true}
                            placeholder={t('note')}
                            onChange={(value: any) => {
                                this.setState(
                                    {
                                        note: Object.assign(
                                            {},
                                            this.state.note,
                                            { value: value }
                                        )
                                    }
                                );
                            }}
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <Submit
                            faIcon="far fa-paper-plane"
                            disabled={!this.isFormValid()}
                            pleaseWait={this.state.send.pleaseWait}
                        >
                            {t('send.reminder')}
                        </Submit>
                    </div>
                </div>
            </form>
        );
    }

    isFormValid() {
        return this.Validator().validate(this.state.note);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                note: Object.assign({}, this.state.note, { touched: true })
            },
            () => {
                if (this.isFormValid()) {
                    this.sendLeaveReminder();
                }
            }
        );
    }

    sendLeaveReminder() {
        const t = this.translate();
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getLeaveSendReminder(this.props.leave.id),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                comment: this.state.note.value,
            }),
            beforeSend: () => {
                this.setState({ send: { pleaseWait: true } });
            },
            success: (data: any, textStatus: any, jqXHR: any) => {
                this.successToastr(t('the.notification.was.sent'));
                if (this.props.onClose) {
                    this.props.onClose();
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            },
        });
    }
}