import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import { ORDER, STATUS, USERSTATUS } from '../../../General';
import Filters from './Filters';
import Table from './Table';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            filters: {
                search: { value: '' },
                employeeStatuses: { value: [USERSTATUS.ACTIVE, USERSTATUS.INVITED] },
                units: { value: [] },
                offices: { value: [] },
                subsidiaries: { value: [] },
                orderBy: { field: 'NAME', order: ORDER.ASC }
            },
            rows: {
                arr: [],
                status: STATUS.LOADING
            },
            currentYear: { value: this.currentBusinessYear() }
        };
    }


    componentDidMount() { this.search(); }

    search() {
        this.setState({ rows: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getLeaveTypePerEmployeePerYearFiltered(
                        {
                            q: this.state.filters.search.value,
                            leaveTypes: this.props.leaveType.id,
                            year: this.state.currentYear.value,
                            units: this.state.filters.units.value,
                            offices: this.state.filters.offices.value,
                            subsidiaries: this.state.filters.subsidiaries.value
                        }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState({ rows: { arr: this.prepareRows(data), status: STATUS.READY } });
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            { rows: { arr: [], status: STATUS.ERROR } }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    prepareRows(rows: any[]) {
        const caches: any = this.getCaches().getState();
        return rows.map((row) => {
            return {
                employee: caches.users.map[row[0]],
                data: row
            };
        }).sort((a: any, b: any) => {
            if (this.state.filters.orderBy.order === ORDER.ASC)
                return a.employee.fullName.toLowerCase().localeCompare(b.employee.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
            else
                return b.employee.fullName.toLowerCase().localeCompare(a.employee.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
        }).filter((row: any) => {
            return row.employee.status === USERSTATUS.ACTIVE || row.employee.status === USERSTATUS.INVITED;
        });
    }

    render() {
        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <Filters
                    defaultFilters={this.state.filters}
                    orderBy={this.state.filters.orderBy}
                    onChange={(value: any) => { this.setState({ filters: Object.assign({}, value) }, () => { this.search(); }); }}
                />
                {this.renderData()}
            </div>
        </div>
    }

    renderData() {
        let ready = this.state.rows.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return <div className="row">
            <div className="col-12">
                <Table
                    leaveType={this.props.leaveType}
                    rows={this.state.rows}
                    currentYear={this.state.currentYear.value}
                    onRefresh={() => { this.search(); }}
                    onChangeYear={(year: any) => {
                        this.setState({ currentYear: { value: year } }, () => { this.search(); });
                    }}
                />
            </div>
        </div>
    }
}