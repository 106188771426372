import * as $ from 'jquery';
import Component from '../../../Component';
import { COMPANYUSERROLES, REQUESTAPPROVETYPE, REQUESTDAYTYPE, STATUS } from '../../../General';
import { usersOptionsFactory } from '../../../SelectOptionsFactories';
import { formatForServer } from '../../../Utils';

export default abstract class BaseForm extends Component {
    constructor(props: any) {
        super(props);
        const session = this.getSession().getState() as any;
        const caches: any = this.getCaches().getState();
        const moment = this.getMomentWithLocale();
        const leaveType = caches.leaveTypes.map[caches.leaveTypes.active.arr[0].id];
        let minDate = { value: leaveType.registerInThePast || session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN ? moment().subtract(1, 'months').startOf('month') : moment().add(leaveType.earlier, 'days') };
        if (session.companyUser.role !== COMPANYUSERROLES.COMPANY_ADMIN) {
            if ((leaveType?.effectiveAfter) && (session?.companyUser?.employmentStartDate)) {
                let employmentStartDate = moment(session.companyUser.employmentStartDate);
                employmentStartDate = employmentStartDate.add(leaveType?.effectiveAfter, 'day').startOf('day');
                if (minDate.value.isBefore(employmentStartDate)) {
                    minDate.value = employmentStartDate;
                }
            }
        }
        let maxDate = { value: moment().add(1.1, 'year') };
        if (leaveType.usableInterval) {
            minDate = { value: moment(leaveType.usableFrom) };
            maxDate = { value: moment(leaveType.usableTo) };
        }
        let startDate = moment().add(leaveType.earlier ? leaveType.earlier + 1 : 1, 'day').startOf('day');
        startDate = startDate.isBefore(minDate.value) ? minDate.value : startDate;
        let endDate = moment().add(leaveType.earlier ? leaveType.earlier + 1 : 1, 'day').startOf('day');
        endDate = startDate.isBefore(minDate.value) ? minDate.value : endDate;
        this.state = {
            leaveType: {
                value: [leaveType.id],
                touched: false,
            },
            requestDayType: {
                value: [leaveType.overtime ? REQUESTDAYTYPE.HOURS : REQUESTDAYTYPE.FULL]
            },
            hours: {
                value: [1],
                touched: false,
                rules: this.Rules().BasicInputRules()
            },
            startDate: { value: startDate, touched: false },
            endDate: { value: endDate, touched: false },
            minDate,
            maxDate,
            onBehalf: {
                value: false
            },
            employee: {
                value: [(this.getSession().getState() as any).companyUser.id],
                touched: false,
            },
            markApproval: {
                value: [REQUESTAPPROVETYPE.APPROVENOW],
                touched: false,
            },
            comment: {
                value: '',
                touched: false,
            },
            replacement: {
                value: '',
                touched: false,
            },
            bookTimeOff: {
                pleaseWait: false
            },
            leaveRequestCheckXHR: undefined,
            otherLeaves: {
                value: [],
                status: STATUS.LOADING
            },
            leaveTypeAmountsPerYear: {
                value: [],
                status: STATUS.LOADING
            },
            retriveDayEventsLiteMapXHR: undefined,
            dayEventsLiteMap: {
                value: {},
                status: STATUS.LOADING
            }
        };
    }

    isRange() {
        return this.state.requestDayType.value[0] === REQUESTDAYTYPE.RANGE;
    }

    isHours() {
        return this.state.requestDayType.value[0] === REQUESTDAYTYPE.HOURS;
    }

    isInUsableinterval() {
        const moment = this.getMomentWithLocale();
        const caches: any = this.getCaches().getState();
        const leaveType = caches.leaveTypes.map[this.state.leaveType.value[0]];
        if (leaveType.usableInterval) {
            if (this.state.startDate.value.isSameOrAfter(moment(leaveType.usableFrom).startOf('day')) &&
                this.state.endDate.value.isSameOrBefore(moment(leaveType.usableTo).endOf('day'))) {
                return true;
            }
            return false;
        }
        return true;
    }

    isInThePast() {
        const t = this.translate();
        const moment = this.getMomentWithLocale();
        if (this.state.startDate.value.isBefore(moment().startOf('day'))) {
            return (
                <small className="text-muted">
                    <i className="fas fa-exclamation-circle" />
                    {' ' + t('please.note.your.leave.request.is.in.the.past')}
                </small>
            );
        }
        return null;
    }

    isRangeValid() {
        let currentDay = this.state.startDate.value.clone().startOf('day');
        while (currentDay.isSameOrBefore(this.state.endDate.value)) {
            let currentDayText = formatForServer(currentDay);
            const dayEvent = this.state.dayEventsLiteMap.value[currentDayText];
            if (dayEvent && dayEvent.status !== 0 && dayEvent.status !== 1) {
                return false;
            }
            currentDay = currentDay.add(1, 'day');
        }
        return true;
    }

    isBookableInterval() {
        if (this.state.dayEventsLiteMap.status !== STATUS.READY) {
            return false;
        }
        if (!this.isRange()) {
            let currentDay = this.state.startDate.value.clone();
            let currentDayText = formatForServer(currentDay);
            return !this.state.dayEventsLiteMap.value[currentDayText];
        } else {
            let currentDay = this.state.startDate.value.clone().startOf('day');
            while (currentDay.isSameOrBefore(this.state.endDate.value)) {
                let currentDayText = formatForServer(currentDay);
                const dayEvent = this.state.dayEventsLiteMap.value[currentDayText];
                if (dayEvent && dayEvent.status !== 0 && dayEvent.status !== 1) {
                    return false;
                }
                currentDay = currentDay.add(1, 'day');
            }
        }
        return true;
    }

    isMarkApprovalEnabled() {
        const session = this.getSession().getState() as any;
        if (session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN)
            return true;
        if (session.companyUser.role === COMPANYUSERROLES.COMPANY_MANAGER
            && (+session.companyUser.id !== +session.companyUser.approverCompanyUser.id
                || +this.state.employee.value !== +session.companyUser.id)) {
            return true;
        }
        return false;
    }

    getEmployeesOptions() {
        const caches: any = this.getCaches().getState();
        const session = this.getSession().getState() as any;
        let employees = [];
        if (session.companyUser.role === COMPANYUSERROLES.COMPANY_USER && session.companyUser.isManager) {
            employees = caches.users.active.arr.filter((employee: any) => {
                return (employee.approverCompanyUser && employee.approverCompanyUser.id &&
                    session.companyUser.id === employee.approverCompanyUser.id) ||
                    session.companyUser.id === employee.id;
            });
        } else {
            if (session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN || session.companyUser.role === COMPANYUSERROLES.COMPANY_MANAGER) {
                employees = caches.users.arr;
            }
        }
        return usersOptionsFactory(employees);
    }

    findFirstDay() {
        let currentDay = this.state.startDate.value.clone();
        while (true) {
            let currentDayText = formatForServer(currentDay);
            if (!this.state.dayEventsLiteMap.value[currentDayText]) {
                // tslint:disable-next-line:max-line-length
                return { startDate: { value: currentDay.clone() }, endDate: { value: currentDay.clone() } };
            }
            currentDay.add(1, 'day');
        }
    }

    getDaysRequestedCount() {
        switch (this.state.requestDayType.value[0]) {
            case REQUESTDAYTYPE.FIRST_PART:
            case REQUESTDAYTYPE.SECOND_PART:
                return 0.5;
            case REQUESTDAYTYPE.FULL:
                return 1;
            case REQUESTDAYTYPE.RANGE: {
                let days = 0;
                let currentDay = this.state.startDate.value.clone();
                while (currentDay.isSameOrBefore(this.state.endDate.value)) {
                    let currentDayText = formatForServer(currentDay);
                    if (!this.state.dayEventsLiteMap.value[currentDayText]) {
                        days++;
                    }
                    currentDay.add(1, 'day');
                }
                return days;
            }
            default:
                return 0;
        }
    }

    isOverBooked() {
        if (this.state.leaveTypeAmountsPerYear.status !== STATUS.READY) {
            return false;
        }
        const leaveTypeAmountsPerYear = this.state.leaveTypeAmountsPerYear.value;
        if (leaveTypeAmountsPerYear?.length > 0) {
            const caches: any = this.getCaches().getState();
            const leaveType = caches.leaveTypes.map[this.state.leaveType.value[0]];
            if (!leaveType.allocationEnforced) {
                return false;
            }
            let isOverbooked = false;
            leaveTypeAmountsPerYear.forEach((row: any) => {
                if (row[2] === 0) {
                    //                isOverbooked = true;
                    return;
                }
                if (row[3] - row[1] - row[2] < 0) {
                    isOverbooked = true;
                    return;
                }
            });
            return isOverbooked;
        }
        return false;
    }

    registerLeave() {
        const t = this.translate();
        const leaveRequest: any = {};
        if (this.state.onBehalf.value) {
            leaveRequest.onBeHalfOf = { id: this.state.employee.value[0] };
            leaveRequest.markApproved = this.state.markApproval.value[0] === REQUESTAPPROVETYPE.APPROVENOW ? true : false;
        }
        let dayType = 0;
        switch (this.state.requestDayType.value[0]) {
            case REQUESTDAYTYPE.FIRST_PART: dayType = 1; break;
            case REQUESTDAYTYPE.SECOND_PART: dayType = 2; break;
            case REQUESTDAYTYPE.HOURS: dayType = 3; break;
            default: dayType = 0; break;
        }
        leaveRequest.leave = {
            dayType: dayType,
            leaveType: { id: this.state.leaveType.value[0] },
            startDate: formatForServer(this.state.startDate.value),
            endDate: formatForServer(this.state.endDate.value),
            comment: this.state.comment.value,
            replacement: this.state.replacement.value
        };
        if (this.state.requestDayType.value[0] === REQUESTDAYTYPE.HOURS) {
            leaveRequest.leave.amount = this.state.hours.value[0];
        }
        $.ajax({
            type: 'PUT',
            contentType: 'application/json',
            url: this.Endpoints().getRegisterLeave(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify(leaveRequest),
            beforeSend: () => {
                this.setState({ bookTimeOff: { blocked: true } });
            },
            success: (data: any, textStatus: any, jqXHR: any) => {
                this.successToastr(t('the.leave.request.was.registered'));
                if (this.props.onRefresh) { this.props.onRefresh(); }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                if (jqXHR.status === 412) {
                    this.errorToastr(t('please.note.the.leave.request.exceeds.the.yearly.allocation'));
                } else {
                    this.ajaxError(jqXHR, textStatus, errorThrown);
                }
            },
            complete: (jqXHR, textStatus) => {
                this.setState({ bookTimeOff: { blocked: false } });
            }
        });
    }
}