import * as React from 'react';
import Component from '../../../Component';
import Form from './Form';
import { STATUS } from '../../../General';

export default class BookTimeOff extends Component {

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.leaveTypes.status === STATUS.READY && caches.users.status === STATUS.READY;
        if (ready === false) { return this.renderLoading(); }
        return (<Form onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }} />);
    }
}