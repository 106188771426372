import * as React from 'react';
import Component from '../../../Component';
import Form from './Form';
import { STATUS } from '../../../General';
import * as $ from 'jquery';

export default class Integrations extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            integrations: {
                value: undefined,
                status: STATUS.LOADING
            },
            editMode: false
        };
    }

    componentDidMount() {
        $.ajax({
            type: 'GET',
            contentType: 'application/json',
            url: this.Endpoints().getIntegrations(),
            dataType: 'json',
            cache: false,
            success: (data, textStatus, jqXHR) => {
                this.setState({
                    integrations: {
                        value: data,
                        status: STATUS.READY
                    }
                });
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
                this.setState({
                    integrations: {
                        value: undefined,
                        status: STATUS.ERROR
                    },
                });
            },
        });
    }

    render() {
        const session: any = this.getSession().getState();
        let ready = session.status === STATUS.READY &&
            this.state.integrations.status === STATUS.READY;
        if (ready === false) { return this.renderLoading(); }
        return (<Form integrations={this.state.integrations} />);
    }
}