import * as React from 'react';
import Component from '../../Component';
import { STATUS } from '../../General';
import Form from './Form';

export default class NotificationRuleAdd extends Component {

    render() {
        const session: any = this.getSession().getState();
        let ready = session.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (<Form />);
    }
}