import * as dropin from 'braintree-web-drop-in';
import * as braintree from 'braintree-web';
import * as ReactDOM from 'react-dom';
import Component from '../../Component';
import { STATUS } from '../../General';

export default class BraintreeDropIn extends Component {
    containerRef: any;
    braintreeWebDropIn: any;
    threeDSecure: any;
    client: any;
    constructor(props?: any) {
        super(props);
        this.state = {
            status: STATUS.LOADING
        };
    }

    componentDidMount() {
        this.createBraintreeWebDropIn();
    }

    async createBraintreeWebDropIn() {
        const config: any = {
            container: ReactDOM.findDOMNode(this.containerRef),
            authorization: this.props.braintreeSettings.client_token,
            threeDSecure: true,
            card: { cardholderName: true }
        };
        this.props.braintreeWebDropIn.braintreeWebDropIn = this.braintreeWebDropIn = await dropin.create(config);
        this.setState({ status: STATUS.READY }, () => {
            this.props.onBraintreeWebDropInReady();
        });
        this.forceUpdate();

        this.client = await braintree.client.create({
            authorization: this.props.braintreeSettings.client_token,
        });
        this.props.braintreeWebDropIn.threeDSecure = this.threeDSecure = await braintree.threeDSecure.create({
            version: 2, // Will use 3DS2 whenever possible
            client: this.client,
        });
        if (this.props.onNoPaymentMethodRequestable) {
            this.braintreeWebDropIn.on(
                'noPaymentMethodRequestable',
                this.props.onNoPaymentMethodRequestable
            );
        }
    }

    async componentWillUnmount() {
        if (this.braintreeWebDropIn) {
            await this.braintreeWebDropIn.teardown();
        }
    }

    shouldComponentUpdate() {
        // Static
        return false;
    }

    render() {
        return (
            <div>
                {this.state.status === STATUS.LOADING ?
                    this.renderLoading() : null}
                <div className={this.state.status === STATUS.READY ? '' : 'd-none'}>
                    <div ref={ref => (this.containerRef = ref)} />
                </div>
            </div>
        );
    }
}
