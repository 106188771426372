import * as React from 'react';
import Component from '../../../Component';
import Back from '../../../Components/Back';
import Line from '../../../Components/Line';

export default class Header extends Component {
    render() {
        return (
            <div className="w-100">
                <div className="col-12 mb-3">
                    <Back href={this.Urls().getNotificationRules()} />
                    <h5 className="mb-0 d-inline">
                        {this.props.notificationRule.name}
                    </h5>
                </div>
                <div className="col-12 mb-3">
                    <Line />
                </div>
            </div>
        );
    }
}