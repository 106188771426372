import * as React from 'react';
import Component from '../../../Component';
import Submit from '../../../Components/Submit';
import SelectField from '../../../Components/SelectField';
import * as $ from 'jquery';
import { doFetchUsersFromServer } from '../../../Actions/Caches';
import {
    usersOptionsFactory,
} from '../../../SelectOptionsFactories';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            employees: {
                value: [],
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            add: {
                pleaseWait: false
            },
        };
    }

    render() {
        const t = this.translate();
        const office = this.props.office;
        const caches: any = this.getCaches().getState();
        const usersOptions = usersOptionsFactory(caches.users.arr.filter((user: any) => {
            if (user && user.office && user.office.id && user.office.id === office.id) {
                return false;
            }
            return true;
        }));
        return (
            <form onSubmit={(event: any) => { this.submit(event); }}>
                <div className="row">
                    <div className="col-12 mb-3">
                        <SelectField
                            defaultValue={this.state.employees.value}
                            touched={this.state.employees.touched}
                            rules={this.state.employees.rules}
                            options={usersOptions}
                            multiple={true}
                            placeholder={t('employees')}
                            maxDisplayedOptions={3}
                            // tslint:disable-next-line:max-line-length
                            onChange={(value: any) => { this.setState({ employees: Object.assign({}, this.state.employees, { value: value }) }); }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <Submit
                            faIcon="far fa-hdd"
                            disabled={!this.isFormValid()}
                            pleaseWait={this.state.add.pleaseWait}
                        >
                            {t('add.office.members')}
                        </Submit>
                    </div>
                </div>
            </form >
        );
    }

    isFormValid() {
        return this.Validator().validate(this.state.employees);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                employees: Object.assign({}, this.state.employees, { touched: true })
            },
            () => {
                if (this.isFormValid()) {
                    this.addTeamMembers();
                }
            }
        );
    }

    addTeamMembers() {
        const t = this.translate();
        $.ajax({
            type: 'PUT',
            contentType: 'application/json',
            url: '/vulcan/r/calendar/' + this.props.office.id + '/users',
            dataType: 'json',
            cache: false,
            data: JSON.stringify(this.state.employees.value),
            beforeSend: () => {
                this.setState({ add: { pleaseWait: true } });
            },
            success: (data: any, textStatus: any, jqXHR: any) => {
                doFetchUsersFromServer(this.getCaches(), this.getSession().getState());
                if (this.state.employees.value.length > 1) {
                    this.successToastr(t('the.employees.were.added.to.the.office'));
                } else {
                    this.successToastr(t('the.employee.was.added.to.the.office'));
                }
                if (this.props.onClose) {
                    this.props.onClose();
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            },
        });
    }
}