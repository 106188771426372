import Component from '../../Component';
import { COMPANYUSERROLES } from '../../General';
import BookTimeOff from './BookTimeOff/BookTimeOff';
import Calendar from './Calendar/Calendar';
import LeaveTypesBalance from './LeaveTypesBalance/LeaveTypesBalance';
import MyNextHoes from './MyNextHoes/MyNextHoes';
import MyNextLeaves from './MyNextLeaves/MyNextLeaves';
import CustomMessage from './CustomMessage/CustomMessage';
import MyNextSteps from './NextSteps/NextSteps';
import PendingApprovalRequests from './PendingApprovalRequests/PendingApprovalRequests';
import UpcomingBirthdays from './UpcomingBirthdays/UpcomingBirthdays';
import UpcomingNewServiceYear from './UpcomingNewServiceYear/UpcomingNewServiceYear';
import WhoIsOffToday from './WhoIsOffToday/WhoIsOffToday';

export default class Dashboard extends Component {
    constructor(props?: any) {
        super(props);
        this.state = this.createInitalState(true);
    }

    createInitalState(value: any) {
        const metalCompanyNz = (this.getSession().getState() as any).company.id === 763;
        const session = this.getSession().getState() as any;
        const isAdmin = () => {
            return session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN || session.companyUser.role === COMPANYUSERROLES.COMPANY_MANAGER;
        };
        return {
            visibleItems: 5 + (metalCompanyNz ? -1 : 0),
            showMyNextSteps: session.companyUser.showNextSteps,
            showCustomMessage: !!session.company.customMessage,
            showBookTimeOff: value,
            showLeaveTypesBalance: metalCompanyNz ? false : value,
            showWhoIsOffToday: value,
            showMyNextLeaves: value,
            showMyNextHoes: value,
            showPendingApprovalRequests: value,
            showUpcomingBirthdays: isAdmin(),
            showNewServiceYear: isAdmin(),
            showCalendar: value,
        };
    }

    render() {
        return <div className="container-fluid">
            <div className="row">
                {(this.state.showMyNextSteps) ?
                    <div className="col-12 mb-4">
                        <MyNextSteps onEmpty={() => { this.setState({ showMyNextSteps: false }); }} />
                    </div>
                    : null}
                {(this.state.showCustomMessage) ?
                    <div className="col-12 mb-4">
                        <CustomMessage onEmpty={() => { this.setState({ showCustomMessage: false }); }} />
                    </div>
                    : null}
                {(this.state.showBookTimeOff) ?
                    <div className={this.cardClass()}>
                        <BookTimeOff onRefresh={() => { this.setState(this.createInitalState(false), () => { this.setState(this.createInitalState(true)); }); }} />
                    </div>
                    : null}
                {(this.state.showPendingApprovalRequests) ?
                    <div className={this.cardClass()}>
                        <PendingApprovalRequests onEmpty={() => { this.setState({ showPendingApprovalRequests: false, visibleItems: this.state.visibleItems - 1 }); }} />
                    </div>
                    : null}
                {(this.state.showMyNextLeaves) ?
                    <div className={this.cardClass()}>
                        <MyNextLeaves onEmpty={() => { this.setState({ showMyNextLeaves: false, visibleItems: this.state.visibleItems - 1 }); }} />
                    </div>
                    : null}
                {(this.state.showMyNextHoes) ?
                    <div className={this.cardClass()}>
                        <MyNextHoes onEmpty={() => { this.setState({ showMyNextHoes: false, visibleItems: this.state.visibleItems - 1 }); }} />
                    </div>
                    : null}
                {(this.state.showLeaveTypesBalance) ?
                    <div className={this.cardClass()}>
                        <LeaveTypesBalance onEmpty={() => { this.setState({ showLeaveTypesBalance: false, visibleItems: this.state.visibleItems - 1 }); }} />
                    </div>
                    : null}
                {(this.state.showWhoIsOffToday) ?
                    <div className={this.cardClass()}>
                        <WhoIsOffToday onEmpty={() => { this.setState({ showWhoIsOffToday: false, visibleItems: this.state.visibleItems - 1 }); }} />
                    </div>
                    : null}
                {(this.state.showUpcomingBirthdays) ?
                    <div className={this.cardClass()}>
                        <UpcomingBirthdays onEmpty={() => { this.setState({ showUpcomingBirthdays: false, visibleItems: this.state.visibleItems - 1 }); }} />
                    </div>
                    : null}
                {(this.state.showNewServiceYear) ?
                    <div className={this.cardClass()}>
                        <UpcomingNewServiceYear onEmpty={() => { this.setState({ showNewServiceYear: false, visibleItems: this.state.visibleItems - 1 }); }} />
                    </div>
                    : null}
            </div>
            {this.state.showCalendar ? <div className="row"><div className="col-12 mb-3"><Calendar /></div></div> : null}
        </div>
    }

    cardClass() {
        return 'mb-4 ' + this.getColLgSize();
    }

    getColLgSize() {
        switch (this.state.visibleItems) {
            case 7:
            case 6:
            case 5:
            case 4:
            case 3:
            case 1: return 'col-12 col-lg-6';
            case 2: return 'col-12 col-lg-6 col-xl-4';
            default: return 'col-12 col-lg-6';
        }
    }
}