import * as React from 'react';
import Component from '../../Component';
import { findLocaleName } from '../../Utils';
import { Tooltip } from 'reactstrap';

export default class LeaveCircle extends Component {
    constructor(props: any) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            tooltipOpen: false
        };
    }

    toggle() { this.setState({ tooltipOpen: !this.state.tooltipOpen }); }

    render() {
        const leave = this.props.leave;
        const faStyle = { color: leave.leaveType.color };
        const name = findLocaleName(leave.leaveType.names, this.language());
        return (
            <span>
                <a href="##" title={' ' + name} className="card-link" id={'LeaveCircle' + this.cid}>
                    <i className="fa fa-circle fa-fw" style={faStyle} aria-hidden="true" />
                </a>
                <Tooltip
                    placement="top"
                    isOpen={this.state.tooltipOpen}
                    target={'LeaveCircle' + this.cid}
                    toggle={this.toggle}
                >
                    {' ' + name}
                </Tooltip>
            </span>
        );
    }
}