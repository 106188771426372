import * as React from 'react';
import DateRangePicker from 'react-daterange-picker';
import { Popover, PopoverBody } from 'reactstrap';
import Component from '../Component';
import Placeholder from './Placeholder/Placeholder';
import Fa from './Fa';

// http://onefinestay.github.io/react-daterange-picker/
export default class DateRangePickerField extends Component {
    public static CID = 0;
    private id: string;
    textInput: any;

    constructor(props: any) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        const state: any = {
            popoverOpen: false,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            touched: false
        };
        this.state = state;
        this.id = 'DateRangePickerField' + DateRangePickerField.CID++;
        this.isBackDisabled = this.isBackDisabled.bind(this);
        this.isNextDisabled = this.isNextDisabled.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.handleNext = this.handleNext.bind(this);
    }

    public toggle() { this.setState({ popoverOpen: !this.state.popoverOpen }); }

    public handleSelect(value: any) {
        this.toggle();
        if (this.props.onChange) {
            this.props.onChange({
                startDate: value.start,
                endDate: value.end,
                touched: true
            });
        }
    }

    getValue() {
        const dateFormat = this.getDateFormat();
        return this.props.startDate.format(dateFormat) + ' - ' + this.props.endDate.format(dateFormat);
    }

    public render() {
        const value = this.getValue();
        const style = this.isScreenSizeSmall() ? { backgroundImage: 'none' } : {};
        return (
            <div className="w-100">
                <div className="input-group mb-3">
                    <div className="input-group-prepend">
                        {this.isBackDisabled() ?
                            <button className="btn btn-outline-secondary border-0" type="button" disabled><Fa fa="fas fa-play fa-rotate-180" fw={1} /></button>
                            :
                            <button onClick={this.handleBack} className="btn btn-outline-primary border-0" type="button"><Fa fa="fas fa-play fa-rotate-180" fw={1} /></button>
                        }
                    </div>
                    <Placeholder
                        placeholder={this.props.placeholder}
                        placeholderTooltip={this.props.placeholderTooltip}
                        placeholderTooltipCid={'IFPTCid' + this.cid}
                        value={value}
                        onClick={() => { this.focusNow(); }}
                    />
                    <input
                        key="input"
                        type={this.props.type ? this.props.type : 'text'}
                        className={this.getInputClass() + ' pr-4 bg-white' +
                            ((value && value && value.length > 0) ? ' pt-3 pb-1' : '')
                        }
                        value={value}
                        ref={(input) => { this.textInput = input; }}
                        onClick={() => { this.toggle(); }}
                        id={this.id}
                        readOnly={true}
                        style={style}
                    />
                    <div className="input-group-append">
                        {this.isNextDisabled() ?
                            <button className="btn btn-outline-secondary border-0" type="button" disabled><Fa fa="fas fa-play" fw={1} /></button>
                            :
                            <button onClick={this.handleNext} className="btn btn-outline-primary border-0" type="button"><Fa fa="fas fa-play" fw={1} /></button>
                        }
                    </div>
                    <Popover placement="bottom" isOpen={this.state.popoverOpen} target={this.id} toggle={this.toggle} trigger='legacy'>
                        <PopoverBody>
                            <div className="w-100">
                                <DateRangePicker
                                    value={this.getPickerValue()}
                                    onSelect={this.handleSelect}
                                    selectionType="range"
                                    minimumDate={this.props?.minimumDate?.toDate()}
                                    maximumDate={this.props?.maximumDate?.toDate()}
                                />
                            </div>
                        </PopoverBody>
                    </Popover>
                </div>
            </div>
        );
    }

    getPickerValue(): any {
        const moment = this.getMomentWithLocale();
        return moment.range(this.props.startDate, this.props.endDate);
    }

    focusNow() {
        this.textInput.focus();
        this.toggle();
    }

    getInputClass() {
        return 'form-control' + (!this.props.touched ? '' : ' is-valid');
    }

    isBackDisabled() {
        return this.props.startDate.isSameOrBefore(this.props.minimumDate) ? true : false;
    }

    isNextDisabled() {
        return this.props.endDate.isSameOrAfter(this.props.maximumDate) ? true : false;
    }

    public handleBack() {
        if (this.props.mode === 'CALENDAR') {
            this.props.onChange(
                {
                    startDate: this.props.startDate.clone().add(-1, 'month'),
                    endDate: this.props.endDate.clone().add(-1, 'month')
                }
            );
        } else {
            this.props.onChange({
                startDate: this.props.startDate.clone().add(-1, 'month').startOf('month'),
                endDate: this.props.endDate.clone().add(-1, 'month').endOf('month')
            });
        }
    }

    public handleNext() {

        if (this.props.mode === 'CALENDAR') {
            this.props.onChange(
                {
                    startDate: this.props.startDate.clone().add(1, 'month'),
                    endDate: this.props.endDate.clone().add(1, 'month')
                }
            );
        } else {
            this.props.onChange({
                startDate: this.props.startDate.clone().add(1, 'month').startOf('month'),
                endDate: this.props.endDate.clone().add(1, 'month').endOf('month')
            });
        }
    }
}