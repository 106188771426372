import * as React from 'react';
import Component from '../../Component';
import { Tooltip } from 'reactstrap';
import Anchor from '../Anchor';

export default class LeaveEventTime extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            tooltipOpen: false
        };
    }

    render() {
        return (
            <span>
                <Anchor
                    id={'LeaveEventTime' + this.cid}
                    faIcon="far fa-clock"
                    className="text-secondary"
                />
                <Tooltip
                    placement="top"
                    isOpen={this.state.tooltipOpen}
                    target={'LeaveEventTime' + this.cid}
                    toggle={() => {
                        this.setState({ tooltipOpen: !this.state.tooltipOpen });
                    }}
                >
                    {this.props.children}
                </Tooltip>
            </span>
        );
    }
}