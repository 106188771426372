import * as $ from 'jquery';
import { sessionLogin } from '../../Actions/Session';
import Component from '../../Component';
import { Cookie, getURLParameter } from '../../Utils';

export default class OAuth extends Component {
    public static LASTLOCALCODE: any;
    componentDidMount() {
        const url = window.location.pathname.toLowerCase();
        if (url.indexOf('localoauth.html') >= 0) {
            this.loginAction({ code: getURLParameter('code') });
            return;
        }
        if (url.indexOf('googleoauth.html') >= 0) {
            this.exchangeTokenForGoogle();
            return;
        }
        if (url.indexOf('office365oauth.html') >= 0) {
            this.exchangeTokenForOffice365();
            return;
        }
        if (url.indexOf('slackoauth.html') >= 0) {
            this.exchangeTokenForSlack();
            return;
        }
        if (url.indexOf('addedtoslack.html') >= 0) {
            this.exchangeTokenForSlack(true);
            return;
        }
    }

    exchangeTokenForOffice365() {
        const oauthCodeCokie = Cookie.read(Cookie.OAUTH_CODE);
        const code = getURLParameter('code');
        if (oauthCodeCokie) {
            if (oauthCodeCokie === code) {
                Cookie.erase(Cookie.OAUTH_CODE);
                return;
            }
        }
        Cookie.create(Cookie.OAUTH_CODE, code);
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getOffice365Exchange(),
            dataType: 'json',
            cache: false,
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            data: JSON.stringify({ office365: { code } }),
            success: (data, textStatus, jqXHR) => {
                this.loginAction(data);
            },
            error: (jqXHR, textStatus, errorThrown) => {
                if (+jqXHR.status === 401) {
                    this.goToUserNotAllowed();
                } else {
                    this.generalErrorToastr();
                }
            },
        });
    }

    exchangeTokenForSlack(addedToSlack?: any) {
        const oauthCodeCokie = Cookie.read(Cookie.OAUTH_CODE);
        const code = getURLParameter('code');
        if (oauthCodeCokie) {
            if (oauthCodeCokie === code) {
                Cookie.erase(Cookie.OAUTH_CODE);
                return;
            }
        }
        Cookie.create(Cookie.OAUTH_CODE, code);
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getSlackExchange(addedToSlack),
            dataType: 'json',
            cache: false,
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            data: JSON.stringify({ slack: { code } }),
            success: (data, textStatus, jqXHR) => {
                this.loginAction(data);
            },
            error: (jqXHR, textStatus, errorThrown) => {
                switch (+jqXHR.status) {
                    case 401:
                        this.goToUserNotAllowed();
                        break;
                    case 403:
                        this.goToUserNotFound();
                        break;
                    default:
                        this.generalErrorToastr();
                }
            },
        });
    }

    exchangeTokenForGoogle() {
        const oauthCodeCokie = Cookie.read(Cookie.OAUTH_CODE);
        const code = getURLParameter('code');
        if (oauthCodeCokie) {
            if (oauthCodeCokie === code) {
                Cookie.erase(Cookie.OAUTH_CODE);
                return;
            }
        }
        Cookie.create(Cookie.OAUTH_CODE, code);
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getGoogleExchange(),
            dataType: 'json',
            cache: false,
            headers: { 'X-Requested-With': 'XMLHttpRequest' },
            data: JSON.stringify({ google: { code } }),
            success: (data, textStatus, jqXHR) => {
                this.loginAction(data);
            },
            error: (jqXHR, textStatus, errorThrown) => {
                switch (+jqXHR.status) {
                    case 401:
                        this.goToUserNotAllowed();
                        break;
                    case 403:
                        this.goToUserNotFound();
                        break;
                    default:
                        this.generalErrorToastr();
                        return;
                }
            },
        });
    }

    loginAction(options: any) {
        if (OAuth.LASTLOCALCODE) {
            if (OAuth.LASTLOCALCODE === options.code) {
                OAuth.LASTLOCALCODE = undefined;
                return;
            }
        }
        OAuth.LASTLOCALCODE = options.code;
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getLogin(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify(options),
            success: (data, textStatus, jqXHR) => {
                sessionLogin(this.getSession(), data.user, data.companyUser, data.company, data.permissions);
                if (data.justActivated) {
                    this.goToDashboard();
                    return;
                }
                var target = Cookie.read(Cookie.REDIRECT_TARGET);
                if (target) {
                    Cookie.erase(Cookie.REDIRECT_TARGET);
                    const endsWith = (str: any, search: any, thisLen?: any) => {
                        if (thisLen === undefined || thisLen > str.length) {
                            thisLen = str.length;
                        }
                        return str.substring(thisLen - search.length, thisLen) === search;
                    };
                    if (endsWith(target, '/nox/') || endsWith(target, '/nox/login.html')) {
                        this.goToDashboard();
                        return;
                    }
                    if (target.indexOf(this.Urls().getSubscribeNow()) > -1) {
                        this.goToDashboard();
                        return;
                    }
                    this.goTo(target);
                } else {
                    this.goToDashboard();
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                if (+jqXHR.status === 401) {
                    this.goToUserNotFound();
                } else {
                    this.generalErrorToastr();
                }
            },
        });
    }

    render() { return this.renderLoading(); }
}