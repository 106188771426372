import * as React from 'react';
import Component from '../../Component';
import { STATUS } from '../../General';
import Line from '../../Components/Line';
import Header from './Header';
import List from './List';
import * as $ from 'jquery';
import { VIEWMODE } from '../../General';
import { fullNameFactory } from '../../Factories';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            employees: {
                arr: [],
                status: STATUS.LOADING
            }
        };
    }

    componentDidMount() { this.import(); }

    import() {
        this.setState(
            {
                employees: {
                    arr: [],
                    status: STATUS.LOADING
                }
            },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getImportFromSlack(),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            {
                                employees: {
                                    arr: this.prepareEmployees(data).sort((employee: any) => {
                                        return employee.alreadyExists ? true : false;
                                    }),
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                employees: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        let ready = this.state.employees.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Header />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <Line />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <List employees={this.state.employees} viewMode={VIEWMODE.GRID} />
                    </div>
                </div>
            </div>
        );
    }

    prepareEmployees(rows: any) {
        let j = 0;
        return rows.map((row: any) => {
            let i = 0;
            const companyUser: any = {};
            companyUser.id = j++;
            companyUser.email = row[i++];
            companyUser.email = companyUser.email ? companyUser.email.trim() : companyUser.email;
            companyUser.firstName = row[i++];
            companyUser.firstName = companyUser.firstName ? companyUser.firstName.trim() : companyUser.firstName;
            companyUser.lastName = row[i++];
            companyUser.lastName = companyUser.lastName ? companyUser.lastName.trim() : companyUser.lastName;
            companyUser.position = row[i++];
            companyUser.position = companyUser.position ? companyUser.position.trim() : companyUser.position;
            companyUser.fullName = fullNameFactory(companyUser);
            const unitName = row[i++];
            if (unitName) {
                companyUser.unitName = unitName.trim();
            }
            companyUser.country = row[i++];
            companyUser.language = row[i++];
            companyUser.timezone = row[i++];
            let exists = false;
            const caches: any = this.getCaches().getState();
            const currentEmployees = caches.users;
            for (let currentEmployee of currentEmployees.arr) {
                if ((currentEmployee.email + '').toLowerCase() === (companyUser.email + '').toLowerCase()) {
                    exists = true;
                    break;
                }
            }
            if (exists) {
                companyUser.alreadyExists = true;
                companyUser.import = false;
            } else {
                companyUser.import = true;
            }
            return companyUser;
        });
    }
}