import * as React from 'react';
import Component from '../../Component';
import Month from './Month';
import CompanyViewManager from '../../Containers/Calendar/CompanyView/Manager';

export default class Manager extends Component {

    render() {
        const moment = this.getMomentWithLocale();
        let month = this.props.viewDate.month() + 1;
        month = month > 9 ? month : '0' + month;
        let viewDate = moment(this.props.viewDate.year() + '-' + month + '-01');
        const month1 = this.buildMonthData(viewDate);
        let opts: any = {
            onPreviousMonth: () => { if (this.props.onPreviousMonth) { this.props.onPreviousMonth(); } },
            onNextMonth: () => { if (this.props.onNextMonth) { this.props.onNextMonth(); } },
            onSelect: (date: any) => { if (this.props.onSelect) { this.props.onSelect(moment(date)); } },
            onMouseOver: (date: any) => { if (this.props.onMouseOver) { this.props.onMouseOver(moment(date)); } }
        };
        
        if (!this.props.range) {
            return (
                <div className="row">
                    <div className="col-12">
                        <Month
                            startDate={this.props.startDate}
                            endDate={this.props.endDate}
                            month={month1}
                            showPrevious={true}
                            showNext={true}
                            {...opts}
                        />
                    </div>
                </div>
            );
        }
        const month2 = this.buildMonthData(viewDate.clone().add(1, 'months'));
        return (
            <div className="row">
                <div className="col-12 col-lg-6">
                    <Month
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        month={month1}
                        showPrevious={true}
                        showNext={false}
                        {...opts}
                    />
                </div>
                <div className="col-12 col-lg-6">
                    <Month
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        month={month2}
                        showNext={true}
                        {...opts}
                    />
                </div>
            </div>
        );
    }

    buildMonthData(month: any) {
        const moment = this.getMomentWithLocale();
        const today = moment();
        var weekDaysMin = moment.weekdaysMin();
        var firstDayOfWeek = +moment.localeData().firstDayOfWeek();
        if (firstDayOfWeek > 0) {
            weekDaysMin = weekDaysMin.slice(firstDayOfWeek, weekDaysMin.length)
                .concat(weekDaysMin.slice(0, firstDayOfWeek));
        }
        const monthData: any = {};
        monthData.weeks = ([] as any);
        monthData.month = month.month();
        monthData.year = month.format('YYYY');
        monthData.monthLiteral = month.format('MMMM');
        monthData.daysLiteral = weekDaysMin;
        let currentDate = (firstDayOfWeek === month.weekday()) ?
            month.clone().subtract(firstDayOfWeek > 0 ? 1 : 7, 'days')
            :
            month.clone().subtract(month.weekday(), 'days');
        let currentWeek: any = undefined;
        for (let i = 0; i < 42; i++) {
            if (i % 7 === 0) {
                currentWeek = { days: [], weekOfMonth: i / 7 };
                monthData.weeks.push(currentWeek);
            }
            const day: any = {
                value: currentDate.date(),
                date: currentDate.format('YYYY-MM-DD'),
                deleted: currentDate.month() !== month.month(),
                today: currentDate.isSame(today, 'day')
            };
            if (currentDate.isBefore(this.props.minDate) ||
                currentDate.isAfter(this.props.maxDate)) {
                day.disabled = true;
                currentWeek.days.push(day);
                currentDate = currentDate.add(1, 'days');
                continue;
            }

            if (this.props.endDate.isSame(this.props.startDate)) {
                if (this.props.startDate.isSame(currentDate)) {
                    day.selected = true;
                }
            } else {
                if (this.props.startDate.isSameOrBefore(currentDate) &&
                    this.props.endDate.isSameOrAfter(currentDate)) {
                    day.selected = true;
                } else {
                    if (this.props.endDate.isSameOrBefore(currentDate) &&
                        this.props.startDate.isSameOrAfter(currentDate)) {
                        day.disabled = true;
                    }
                }
            }
            if (this.props.dayEventsMap) {
                const dayEvent = this.props.dayEventsMap[day.date];
                if (dayEvent) {
                    day.style = CompanyViewManager.dayEventStyle(dayEvent);
                    day.disabled = true;
                }
            }
            currentWeek.days.push(day);
            currentDate = currentDate.add(1, 'days');
        }
        return monthData;
    }
}