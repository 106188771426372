import Component from '../../Component';
import { sessionLogout } from '../../Actions/Session';
import * as $ from 'jquery';
import { none } from '../../Actions/Modal';

export default class Logout extends Component {
    componentDidMount() {
        const t = this.translate();
        none(this.getModal(), {});
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getLogout(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({}),
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('you.have.logged.out'));
            },
            complete: (jqXHR, textStatus) => {
                this.goToLogin();
                sessionLogout(this.getSession());
            }
        });
    }

    render() {
        return this.renderLoading();
    }
}