import * as React from 'react';
import Component from '../Component';

export default class A extends Component {
    render() {
        let opts: any = {};
        if (this.props.target) { opts.target = this.props.target; }
        if (this.props.onMouseOut) {
            opts.onMouseOut = (event: any) => {
                event.preventDefault();
                this.props.onMouseOut(event);
            };
        }
        if (this.props.onMouseOver) {
            opts.onMouseOver = (event: any) => {
                event.preventDefault();
                this.props.onMouseOver(event);
            };
        }
        if (this.props.onClick) {
            opts.onClick = (event: any) => { event.preventDefault(); this.props.onClick(event); };
        } else {
            if (!this.props.target)
                opts.onClick = (event: any) => {
                    event.preventDefault();
                    this.goTo(this.props.href);
                };
        }
        if (this.props.target) {
            opts.target = this.props.target;
        }
        if (this.props.title) {
            opts.title = this.props.title;
        }
        if (this.props.id) {
            opts.id = this.props.id;
        }
        return <a
            className={'card-link' + (this.props.className ? ' ' + this.props.className : '')}
            href={this.props.href}
            {...opts}
        >
            {
                this.props.faIcon ?
                    (<i className={this.props.faIcon + ' fa-fw'} aria-hidden="true" />)
                    :
                    null
            }
            {(this.props.children && this.props.faIcon) ? '  ' : ''}
            {this.props.children}
        </a >
    }
}