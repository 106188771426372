import { COMPANYUSERROLES, ETOLTABS } from '../General';
import BaseTabs from './BaseTabs';

export default class ETOL extends BaseTabs {

    getTabs(): any[] {
        const t = this.translate();
        const session: any = this.getSession().getState();
        const isAdmin = () => {
            return session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN;
        };
        const isManager = () => {
            return session.companyUser.role === COMPANYUSERROLES.COMPANY_MANAGER;
        };
        const tabs = [];
        if (isAdmin() || isManager()) {
            tabs.push({
                faIcon: 'far fa-user',
                title: t('employees'),
                tab: ETOLTABS.EMPLOYEES,
                url: this.Urls().getEmployees()
            });
        }
        if (isAdmin()) {
            tabs.push({
                faIcon: 'fas fa-users',
                title: t('teams'),
                tab: ETOLTABS.UNITS,
                url: this.Urls().getUnits()
            });
            tabs.push({
                faIcon: 'fa fa-building',
                title: t('offices'),
                tab: ETOLTABS.OFFICES,
                url: this.Urls().getOffices()
            });
            if (session.company.subsidiariesEnabled)
                tabs.push({
                    faIcon: 'fas fa-city',
                    title: t('subsidiaries'),
                    tab: ETOLTABS.SUBSIDIARIES,
                    url: this.Urls().getSubsidiaries()
                });
        }
        if (isAdmin() || isManager()) {
            tabs.push({
                faIcon: 'fa fa-chart-bar',
                title: t('reports'),
                tab: ETOLTABS.REPORTS,
                url: this.Urls().getEmployeesReports()
            });
        }
        return tabs;
    }

    getName(): string {
        return 'ETOL';
    }
}