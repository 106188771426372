import * as React from 'react';
import Component from '../../Component';
import Header from './Header/Header';
import Members from './Members/Members';
import Hoes from './Hoes/Hoes';
import BlackoutEvents from './BlackoutDays/BlackoutDays';
import Settings from './Settings/Settings';
import { STATUS } from '../../General';
import * as $ from 'jquery';
import { getURLParameter } from '../../Utils';
import { OFFICETABS } from '../../General';
import { fullNameFactory } from '../../Factories';

export default class Office extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            office: {
                value: undefined,
                status: STATUS.LOADING
            },
            currentTab: this.getCurrentTab(OFFICETABS.HOES, OFFICETABS),
        };
    }

    componentDidMount() {
        this.getOffice();
    }

    getOffice() {
        this.setState(
            {
                office: {
                    value: this.state.office.value,
                    status: STATUS.LOADING
                }
            },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getOffice(getURLParameter('id')),
                    dataType: 'json',
                    cache: false,
                    success: (data, textStatus, jqXHR) => {
                        const session = this.getSession().getState() as any;
                        data.officeManager.fullName = fullNameFactory(data.officeManager, session.company.nameFormat);
                        this.setState({
                            office: {
                                value: data,
                                status: STATUS.READY
                            }
                        });
                    },
                    error: (jqXHR, textStatus, errorThrown) => {
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                        this.setState({
                            office: {
                                value: undefined,
                                status: STATUS.ERROR
                            },
                        });
                    },
                });
            }
        );
    }

    render() {
        const session: any = this.getSession().getState();
        const caches: any = this.getCaches().getState();
        let ready = session.status === STATUS.READY &&
            caches.users.status === STATUS.READY &&
            caches.countries.status === STATUS.READY &&
            this.state.office.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (
            <div className="container-fluid">
                <div className="row">
                    {this.getTab()}
                </div>
            </div>
        );
    }

    getTab() {
        const defaultArgs: any = {
            office: this.state.office.value,
            currentTab: this.state.currentTab,
            onTabChange: (tab: any) => { this.setState({ currentTab: tab }); }
        };
        switch (this.state.currentTab) {
            case OFFICETABS.HOES:
                return <div className="w-100">
                    <Header {...defaultArgs} />
                    <div className="col-12 mb-3">
                        <Hoes office={this.state.office.value} />
                    </div>
                </div>
            case OFFICETABS.BLACKOUTEVENTS:
                return <div className="w-100">
                    <Header {...defaultArgs} />
                    <div className="col-12 mb-3">
                        <BlackoutEvents office={this.state.office.value} />
                    </div>
                </div>
            case OFFICETABS.MEMBERS:
                return <div className="w-100">
                    <Header {...defaultArgs} />
                    <div className="col-12 mb-3">
                        <Members office={this.state.office.value} />
                    </div>
                </div>
            case OFFICETABS.SETTINGS:
            default:
                return <Settings {...defaultArgs} onRefresh={() => { this.getOffice(); }} />
        }
    }
}