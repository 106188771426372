import * as React from 'react';
import Component from '../Component';
import { UncontrolledTooltip } from 'reactstrap';

export default class Tooltip extends Component {
    render() {
        return (
            <div
                className="d-inline"
                style={{ zIndex: 4 }}
            >
                <a href="##" id={'ToolTip' + this.cid} tabIndex={-1}>
                    <i className={'fas fa-info-circle text-' + this.props.textColor} aria-hidden="true" />
                </a>
                <UncontrolledTooltip
                    delay={{ show: 0, hide: 250 }}
                    placement="top"
                    target={'ToolTip' + this.cid}
                >
                    {this.props.tooltip}
                </UncontrolledTooltip>
            </div>
        );
    }
}