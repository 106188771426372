import * as React from 'react';
import Component from '../../Component';
import Date from '../../Components/Date';
import EmployeeLink from '../../Components/EmployeeLink';

export default class Item extends Component {

    render() {
        const employee = this.props.employee;
        const t = this.translate();
        return <div className="card w-100 h-100">
            <div className="card-body">
                <table className="table table-sm w-100 m-0 p-0" style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                    <thead>
                        <tr>
                            <th className="border-top-0">
                                <EmployeeLink employee={employee} />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {employee.ageLiteral ? <tr><td key="Employees-Item-Age">
                            <i className="mr-1 far fa-calendar-alt" aria-hidden="true" />
                            {employee.ageLiteral}
                        </td></tr> : null}
                        {employee.birthDay ? <tr><td key="Employees-Item-BirthDate">
                            <i className="mr-1 far fa-calendar-alt" aria-hidden="true" />
                            <Date date={employee.birthDate} />
                        </td></tr> : null}
                        {employee.todayIsBirthDate ? <tr><td key="Employees-Item-Employment">{t('today')}</td></tr> :
                            employee.next ?
                                <tr><td key="Employees-Item-Employment">
                                    <i className="mr-1 fas fa-stopwatch" aria-hidden="true" />
                                    {employee.next.humanize(true)}
                                </td></tr> : null}
                    </tbody>
                </table>
            </div>
        </div>
    }
}