import * as React from 'react';
import Component from '../../../Component';
import Submit from '../../../Components/Submit';
import InputField from '../../../Components/InputField';
import TextareaField from '../../../Components/TextareaField';
import SelectField from '../../../Components/SelectField';
import * as $ from 'jquery';
import { USERSTATUS } from '../../../General';
import { doFetchUnitsFromServer, doFetchUsersFromServer } from '../../../Actions/Caches';
import Close from '../../../Components/Close';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            name: {
                value: this.props.unit.name,
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            manager: {
                value: [this.props.unit.managerCompanyUser.id],
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            description: {
                value: this.props.unit.description,
                touched: false,
                rules: this.Rules().MaxLengthTextareaRules()
            },
            save: {
                pleaseWait: false
            }
        };
    }

    render() {
        const caches: any = this.getCaches().getState();
        const managerOptions: any = caches.users.arr
            .filter((user: any) => {
                return (user.status === USERSTATUS.DISABLED) ? false : true;
            }).map((user: any) => {
                return { value: user.id, text: user.fullName };
            });
        const t = this.translate();
        return (
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <form onSubmit={(event: any) => { this.submit(event); }}>
                        <fieldset>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="float-right">
                                        <Close
                                            onClick={() => {
                                                if (this.props.onRefresh) {
                                                    this.props.onRefresh();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <InputField
                                        defaultValue={this.state.name.value}
                                        touched={this.state.name.touched}
                                        rules={this.state.name.rules}
                                        type="text"
                                        placeholder={t('name')}
                                        focus={this.isScreenSizeSmall() ? false : true}
                                        onChange={(value: any) => {
                                            this.setState(
                                                { name: Object.assign({}, this.state.name, { value: value }) },
                                                () => {
                                                    this.onUpdate();
                                                }
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <SelectField
                                        defaultValue={this.state.manager.value}
                                        touched={this.state.manager.touched}
                                        options={managerOptions}
                                        rules={this.state.manager.rules}
                                        multiple={false}
                                        placeholder={t('manager')}
                                        onChange={(value: any) => {
                                            this.setState(
                                                { manager: Object.assign({}, this.state.manager, { value: value }) }
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <TextareaField
                                        defaultValue={this.state.description.value}
                                        touched={this.state.description.touched}
                                        type="text"
                                        rows={2}
                                        placeholder={t('description')}
                                        onChange={(value: any) => {
                                            this.setState(
                                                // tslint:disable-next-line:max-line-length
                                                { description: Object.assign({}, this.state.description, { value: value }) }
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <Submit
                                        faIcon="far fa-hdd"
                                        disabled={!this.isFormValid()}
                                        pleaseWait={this.state.save.pleaseWait}
                                    >
                                        {t('save')}
                                    </Submit>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        );
    }

    onUpdate() {
        if (this.props.onUpdate) {
            this.props.onUpdate({
                id: this.props.unit.id,
                name: this.state.name.value,
                managerCompanyUser: { id: this.state.manager.value[0] },
                description: this.state.description.value
            });
        }
    }

    isFormValid() {
        return this.Validator().validate(this.state.name)
            && this.Validator().validate(this.state.manager)
            && this.Validator().validate(this.state.description);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                name: Object.assign({}, this.state.name, { touched: true }),
                manager: Object.assign({}, this.state.manager, { touched: true }),
                description: Object.assign({}, this.state.description, { touched: true })
            },
            () => {
                if (this.isFormValid()) {
                    this.addTeam();
                }
            }
        );
    }

    addTeam() {
        const t = this.translate();
        var companyUnit: any = {};
        companyUnit.name = this.state.name.value;
        companyUnit.managerCompanyUser = {};
        companyUnit.managerCompanyUser.id = this.state.manager.value[0];
        companyUnit.description = this.state.description.value;
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getUnitUpdate(this.props.unit.id),
            dataType: 'json',
            cache: false,
            data: JSON.stringify(companyUnit),
            beforeSend: () => {
                this.setState({ save: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('the.team.was.updated'));
                doFetchUnitsFromServer(this.getCaches());
                doFetchUsersFromServer(this.getCaches(), this.getSession().getState());
                if (this.props.onRefresh) {
                    this.props.onRefresh();
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                if (+jqXHR.status === 400) {
                    var reason = jqXHR.getResponseHeader('REASON');
                    if (reason && reason === 'DUPLICATE') {
                        this.successToastr(t('team.duplicate'));
                        return;
                    }
                }
                this.generalErrorToastr();
            },
            complete: (jqXHR, textStatus) => {
                this.setState({ save: { pleaseWait: false } });
            }
        });
    }
}