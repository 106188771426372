import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../Component';
import A from '../../Components/A';
import ETOL from '../../Components/ETOL';
import Line from '../../Components/Line';
import { usersFactory } from '../../Factories';
import { COMPANYUSERROLES, ETOLTABS, ORDER, STATUS, USERSTATUS, VIEWMODE } from '../../General';
import { Cookie, Jqxhrs, handleJQXHRError, getURLParameter } from '../../Utils';
import Filters from './Filters';
import List from './List';
import Download from './Download';
import Anchor from '../../Components/Anchor';

export default class Manager extends Component {
    protected jqxhrs = new Jqxhrs();
    private search: any;
    constructor(props?: any) {
        super(props);
        let viewMode = Cookie.read(Cookie.EMPLOYEES_VIEWMODE);
        if (!viewMode) {
            viewMode = VIEWMODE.LIST;
            Cookie.create(Cookie.EMPLOYEES_VIEWMODE, viewMode);
        }
        this.state = {
            filters: {
                search: { value: '' },
                employeeStatuses: { value: [USERSTATUS.ACTIVE, USERSTATUS.INVITED] },
                units: { value: [] },
                countries: { value: [] },
                offices: { value: [] },
                approvers: { value: [] },
                viewMode: viewMode,
                orderBy: { field: 'NAME', order: ORDER.ASC }
            },
            employees: {
                arr: [],
                status: STATUS.LOADING
            },
            status: STATUS.LOADING
        };
        this.search = Manager.search.bind(this);
    }

    componentDidMount() {
        this.search();
    }

    public static search(this: any) {
        this.setState(
            { employees: { arr: [], status: STATUS.LOADING } },
            () => {
                this.jqxhrs.abortAndSet('search', $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getEmployeesEmployeesSearch({ filters: this.state.filters }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            {
                                employees: {
                                    arr: usersFactory(data.data, this.getSession().getState()).sort((a: any, b: any) => {
                                        if (this.state.filters.orderBy.order === ORDER.ASC)
                                            return a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
                                        else
                                            return b.fullName.toLowerCase().localeCompare(a.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
                                    }),
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        handleJQXHRError(jqXHR, textStatus, errorThrown, () => { this.setState({ data: { arr: null, count: 0, status: 'ERROR' } }); });
                    },
                    complete: (jqXHR: any, textStatus: any) => {
                        this.jqxhrs.complete('search');
                    }
                }));
            });
    }

    render() {
        const t = this.translate();
        const session: any = this.getSession().getState();
        const isAdmin = () => {
            return session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN;
        };
        return <div className="container-fluid">
            <div className="row">
                <div className="col-12 mb-3">
                    <ETOL currentTab={ETOLTABS.EMPLOYEES} />
                    {isAdmin() ? <A
                        faIcon="fas fa-user-plus"
                        href={this.Urls().getEmployeesInvite()}
                        className="btn btn-outline-primary float-right"
                        title={t('invite.employees')}
                    >
                        {this.isScreenSizeSmall() ? undefined : ' ' + t('invite.employees')}
                    </A> : null}
                    {isAdmin() && getURLParameter('reinviteall') ? <Anchor
                        title={t('reinvite')}
                        faIcon="far fa-paper-plane"
                        onClick={() => {
                            $.ajax({
                                type: 'POST',
                                contentType: 'application/json',
                                url: this.Endpoints().getReinviteAll(),
                                dataType: 'json',
                                cache: false,
                                data: JSON.stringify({}),
                                success: (data, textStatus, jqXHR) => {
                                    this.successToastr(t('was.reinvited'));
                                },
                                error: (jqXHR, textStatus, errorThrown) => {
                                    this.ajaxError(jqXHR, textStatus, errorThrown);
                                },
                            });
                        }}
                    >
                        {t('reinvite')}
                    </Anchor>
                        : null}
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <Line />
                </div>
            </div>
            <Filters
                download={(new Download({ filters: this.state.filters }))}
                defaultFilters={this.state.filters}
                viewMode={this.state.filters.viewMode}
                orderBy={this.state.filters.orderBy}
                onChange={(value: any) => { this.setState({ filters: Object.assign({}, value) }, () => { this.search(); }); }}
            />
            <div className="row">
                <List
                    employees={this.state.employees}
                    viewMode={this.state.filters.viewMode}
                    onRefresh={() => { this.search(); }}
                    onReinvite={(employee: any) => { this.reinvite(employee); }}
                />
            </div>
        </div>
    }

    reinvite(employee: any) {
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getReinviteUser(employee.id),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({}),
            success: (data, textStatus, jqXHR) => {
                const t = this.translate();
                this.successToastr(employee.firstName + ' ' + employee.lastName + ' ' +
                    t('was.reinvited'));
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            },
        });
    }

    public componentWillUnmount() { this.jqxhrs.abortAll(); }
}