import * as React from 'react';
import Component from '../../Component';
import Error from '../../Components/Error';
import { STATUS } from '../../General';
import Item from './Item';

export default class List extends Component {

    render() {
        const notificationRules = this.props.notificationRules;
        switch (notificationRules.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                if (notificationRules.arr.length === 0) {
                    const t = this.translate();
                    return (
                        <div className="col-12 text-danger mb-3">
                            {t('no.notification.rules.found')}
                        </div>
                    );
                }
                return notificationRules.arr.map((notificationRule: any) => {
                    return (
                        <div
                            key={'NotificationRules' + this.props.viewMode + '-' + notificationRule.id}
                            className={this.getListColSize(this.props.viewMode) + ' mb-3'}
                        >
                            <Item notificationRule={notificationRule} onRefresh={this.props.onRefresh} />
                        </div>
                    );
                });
            }
            default:
                return (<Error />);
        }
    }
}