import * as React from 'react';
import Component from '../../Component';
import Date from '../../Components/Date';
import EmployeeLink from '../../Components/EmployeeLink';

export default class Item extends Component {

    render() {
        const t = this.translate();
        const employee = this.props.employee;
        return <tr><td key="Employees-Item-Employee">
            <EmployeeLink disableFa={true} employee={employee} />
        </td>
            <td key="Employees-Item-Employment-Start-Date" className="text-right">
                {employee.employmentStartDate ? <Date date={employee.employmentStartDate} /> : null}
            </td>
            <td key="Employees-Item-Employment-Years-Of-Service" className="text-right">
                {employee.service && employee.service.valueOf() > 0 ? employee.service.humanize() : null}
            </td>
            <td key="Employees-Item-Employment-Next-Year-Of-Service" className="text-right">
                {employee.todayIsNewYearOfService && employee.service && employee.service.valueOf() > 0 ? t('today') :
                    (employee.next && employee.next.valueOf() > 0 ?
                        employee.next.humanize(true) : null)}
            </td></tr>;
    }
}