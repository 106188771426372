import * as React from 'react';
import Component from '../../../Component';
import Form from './Form';
import Sent from './Sent';

export default class Support extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            ticketSent: false
        };
    }

    render() {
        return (!this.state.ticketSent) ?
            <Form
                currentTab={this.props.currentTab}
                onTabChange={(tab: any) => { if (this.props.onTabChange) { this.props.onTabChange(tab); } }}
                onTicketSent={() => { this.setState({ ticketSent: true }); }}
            />
            :
            <Sent
                currentTab={this.props.currentTab}
                onTabChange={(tab: any) => { if (this.props.onTabChange) { this.props.onTabChange(tab); } }}
            />
    }
}