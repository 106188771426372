import * as React from 'react';
import Component from '../../../Component';
import Anchor from '../../../Components/Anchor';
import { Popover, PopoverBody } from 'reactstrap';
import { USERSTATUS } from '../../../General';
import { doFetchUsersFromServer } from '../../../Actions/Caches';
import * as $ from 'jquery';

export default class MoreActions extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            popoverOpen: false
        };
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    render() {
        const t = this.translate();
        return (
            <span>
                <Anchor
                    id={'MoreActions-' + this.cid}
                    className="btn btn-outline-primary border-0 rounded-circle m-0 p-0 pl-1 pr-1"
                    title={t('more.actions')}
                    faIcon="fas fa-ellipsis-h"
                    onClick={() => {
                        this.toggle();
                    }}
                />
                <Popover
                    placement="bottom"
                    isOpen={this.state.popoverOpen}
                    target={'MoreActions-' + this.cid}
                    toggle={() => {
                        this.toggle();
                    }}
                >
                    <PopoverBody>
                        {this.getEnableDisableReinvite()}
                    </PopoverBody>
                </Popover>
            </span>
        );
    }

    getEnableDisableReinvite() {
        const t = this.translate();
        const employee = this.props.employee;
        return (
            <div className="m-1">
                {(this.props.employee.status === USERSTATUS.ACTIVE) ?
                    this.getDisableAction() : null}
                {(this.props.employee.status === USERSTATUS.DISABLED) ?
                    (
                        <Anchor
                            title={t('enable.employee')}
                            faIcon="fas fa-level-up-alt"
                            onClick={() => {
                                $.ajax({
                                    type: 'POST',
                                    contentType: 'application/json',
                                    url: this.Endpoints().getEmployeeEnable(employee.id),
                                    dataType: 'json',
                                    cache: false,
                                    success: (data: any, textStatus: any, jqXHR: any) => {
                                        // tslint:disable-next-line:max-line-length
                                        this.successToastr(t('the.employee.was.enabled'));
                                        doFetchUsersFromServer(this.getCaches(), this.getSession().getState());
                                        if (this.props.onRefresh) {
                                            this.props.onRefresh();
                                        }
                                        this.toggle();
                                    },
                                    // tslint:disable-next-line:max-line-length
                                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                                        this.ajaxError(jqXHR, textStatus, errorThrown);
                                    }
                                });
                            }}
                        >
                            {t('enable')}
                        </Anchor>)
                    : null}
                {
                    (this.props.employee.status === USERSTATUS.INVITED) ?
                        (
                            <div>
                                <Anchor
                                    title={t('reinvite')}
                                    faIcon="far fa-paper-plane"
                                    onClick={() => {
                                        $.ajax({
                                            type: 'POST',
                                            contentType: 'application/json',
                                            url: this.Endpoints().getReinviteUser(employee.id),
                                            dataType: 'json',
                                            cache: false,
                                            data: JSON.stringify({}),
                                            success: (data, textStatus, jqXHR) => {
                                                this.successToastr(employee.firstName + ' ' + employee.lastName + ' ' +
                                                    t('was.reinvited'));
                                                this.toggle();
                                            },
                                            error: (jqXHR, textStatus, errorThrown) => {
                                                this.ajaxError(jqXHR, textStatus, errorThrown);
                                            },
                                        });
                                    }}
                                >
                                    {t('reinvite')}
                                </Anchor>
                                {this.getDisableAction()}
                            </div>
                        )
                        :
                        null
                }
            </div>
        );
    }

    getDisableAction() {
        const t = this.translate();
        return (
            <Anchor
                title={t('disable.employee')}
                faIcon="fas fa-times"
                onClick={() => {
                    $.ajax({
                        type: 'POST',
                        contentType: 'application/json',
                        url: this.Endpoints().getEmployeeDisable(this.props.employee.id),
                        dataType: 'json',
                        cache: false,
                        success: (data: any, textStatus: any, jqXHR: any) => {
                            this.successToastr(t('the.employee.was.disabled'));
                            doFetchUsersFromServer(this.getCaches(), this.getSession().getState());
                            if (this.props.onRefresh) {
                                this.props.onRefresh();
                            }
                            this.toggle();
                        },
                        // tslint:disable-next-line:max-line-length
                        error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                            this.ajaxError(jqXHR, textStatus, errorThrown);
                        }
                    });
                }}
            >
                {t('disable')}
            </Anchor>
        );
    }
}