import * as React from 'react';
import MenuBase from './MenuBase';
import A from '../../Components/A';

export default class Menu extends MenuBase {

    onResize() {
        if (this.props.onResize) {
            this.props.onResize(this.state.isOpen ? 250 : 78);
        }
    }

    render() {
        if ((this.getSession().getState() as any).logged === false) {
            return null;
        }
        const t = this.translate();
        const menuItems = this.getMenuItemsWitoutLogout();
        const menuLogoutItems = this.getLogoutMenuItem();
        const currentUrl = window.location.href;
        const mapMenuItems = (menuItem: any) => {
            const active = menuItem.match(currentUrl);
            return <A
                key={'Menu' + menuItem.faIcon}
                href={menuItem.href}
                faIcon={menuItem.faIcon}
                title={menuItem.title}
                className={'list-group-item list-group-item-action m-0 w-100' +
                    ' border-top-0 border-right-0 border-left-0 rounded-0' +
                    (active ? ' active' : '') +
                    (this.state.isOpen ? '' : ' text-center')}
            >
                {this.state.isOpen ? ' ' + menuItem.title : undefined}
                {(active) ?
                    (
                        <i
                            className="fa fa-caret-left fa-lg fa-fw float-right mt-1"
                            style={{ marginRight: -30 }}
                        />
                    )
                    :
                    null
                }
            </A>
        };
        const items = menuItems.map(mapMenuItems);
        const mapMenuLogoutItems = (menuItem: any) => {
            const active = menuItem.match(currentUrl);
            return (
                <A
                    key={'Menu' + menuItem.faIcon}
                    href={menuItem.href}
                    faIcon={menuItem.faIcon}
                    title={menuItem.title}
                    className={'list-group-item list-group-item-action m-0 w-100' +
                        ' border-bottom-0 border-right-0 border-left-0 rounded-0' +
                        (active ? ' active' : '') +
                        (this.state.isOpen ? '' : ' text-center')}
                >
                    {this.state.isOpen ? ' ' + menuItem.title : undefined}
                    {(active) ?
                        (
                            <i className="fa fa-caret-left fa-lg fa-fw float-right mt-1" style={{ marginRight: -30 }} />
                        )
                        :
                        null
                    }
                </A>
            );
        };
        const logoutItems = menuLogoutItems.map(mapMenuLogoutItems);
        return <div className="border border-top-0 border-bottom-0 border-left-0 rounded-0 fixed-top bg-white">
            <div
                className="border border-top-0 border-bottom-0 border-left-0 rounded-0 list-group fixed-top"
                style={{
                    width: this.state.isOpen ? 250 : 74,
                    minWidth: this.state.isOpen ? 250 : 74,
                    maxWidth: this.state.isOpen ? 250 : 74,
                    borderColor: 'rgba(0, 0, 0, 0.125)',
                    minHeight: '100vh',
                }}
            >
                {items}
                <A
                    className={'list-group-item list-group-item-action m-0 w-100' +
                        ' border-top-0 border-right-0 border-left-0 rounded-0' +
                        (this.state.isOpen ? '' : ' text-center')}
                    href="##"
                    faIcon={(this.state.isOpen ? 'far fa-arrow-alt-circle-left' : 'far fa-arrow-alt-circle-right')}
                    title={t((this.state.isOpen ? 'collapse' : 'expand') + '.menu')}
                    onClick={(event: any) => { this.toggle(); }}
                >
                    {this.state.isOpen ? ' ' + t('collapse.menu') : null}
                </A>
            </div>
            <div
                className="border border-top-0 border-bottom-0 border-left-0 rounded-0 list-group fixed-bottom"
                style={{
                    width: this.state.isOpen ? 250 : 74,
                    minWidth: this.state.isOpen ? 250 : 74,
                    maxWidth: this.state.isOpen ? 250 : 74,
                    borderColor: 'rgba(0, 0, 0, 0.125)',
                }}
            >
                {logoutItems}
            </div>
        </div>
    }
}

/*
                    <div
                        className={'list-group-item m-0 w-100' +
                            ' border-top-0 border-right-0 border-left-0 rounded-0'
                        }
                    >
                        <br />
                    </div>
*/