import * as React from 'react';
import Component from '../Component';
import A from './A';

export default class Back extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            hover: false
        };
    }

    render() {
        const t = this.translate();
        return <A
            href={this.props.href ? this.props.href : '##'}
            faIcon={(this.state.hover ? 'fas fa-arrow-circle-left' : 'far fa-arrow-alt-circle-right fa-rotate-180') + ' fa-lg fa-fw mr-1'}
            title={t('back')}
            onMouseOut={() => {
                this.setState({ hover: false });
            }}
            onMouseOver={() => {
                this.setState({ hover: true });
            }}
            onClick={this.props.href ?
                null :
                (event: any) => {
                    event.preventDefault();
                    if (this.props.onClick) { this.props.onClick(); }
                }}
        />
    }
}