import * as $ from 'jquery';
import {
    createStore,
    //    applyMiddleware 
} from 'redux';
// import { createLogger } from 'redux-logger';
import { RESIZE } from '../Constants/ActionTypes';
import { doResize } from '../Actions/ScreenSize';

export const initialState = {
    message: undefined,
    type: RESIZE,
};

const screenSize = createStore(
    (state = initialState, action: any) => {
        switch (action.type) {
            case RESIZE:
                return action.message;
            default:
                return action.message;
        }
    },
    //    applyMiddleware(createLogger({}))
);

export default screenSize;
doResize(screenSize);
($ as any)(window).resize(() => {
    doResize(screenSize);
});
window.setTimeout(
    () => {
        doResize(screenSize);
    },
    100
);