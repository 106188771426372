import * as React from 'react';
import Table from './Table';
import MyNextLeavesManager from '../../../Dashboard/MyNextLeaves/Manager';

export default class Manager extends MyNextLeavesManager {

    render() {
        if (this.state.leaves.arr.length === 0) {
            return null as any;
        }
        return (
            <div className="card h-100">
                <div className="card-body">
                    <Table leaves={this.state.leaves} filters={this.state.filters} />
                </div>
            </div>
        );
    }

    getEmployee() {
        return this.props.employee.id;
    }
}