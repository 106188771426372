import * as React from 'react';
import Component from '../Component';
import { ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Placeholder from './Placeholder/Placeholder';
import SelectFieldOptions from './SelectFieldOptions';
import SelectFieldDisplayValue from './SelectFieldDisplayValue';

export default class SelectField extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      value: this.props.defaultValue ? this.props.defaultValue : [],
      defaultValue: this.props.defaultValue ? this.props.defaultValue : [],
      touched: false,
      rules: this.props.rules,
      valid: null,
      optional: this.props && this.props.optional ? true : false,
      multiple: this.props && this.props.multiple ? true : false,
      dropdownOpen: false,
      maxDisplayedOptions: this.props.maxDisplayedOptions ? this.props.maxDisplayedOptions : 5
    };
  }

  toggle() { this.setState({ dropdownOpen: !this.state.dropdownOpen }); }

  render() {
    const t = this.translate();
    const btnddArgs = { direction: 'down' };
    return (
      <div className="w-100" >
        <Placeholder
          placeholder={this.props.placeholder}
          placeholderTooltip={this.props.placeholderTooltip}
          placeholderTooltipCid={'SFPTCid' + this.cid}
          value={this.state.value}
          onClick={() => { this.toggle(); }}
        />
        <ButtonDropdown
          isOpen={this.state.dropdownOpen}
          toggle={() => { this.toggle(); }}
          className="w-100"
        >
          <DropdownToggle
            caret={false}
            {...btnddArgs}
            className={
              ((this.state.dropdownOpen ? 'focus ' : ' ') + 'w-100 btn-select bg-white text-dark rounded')
              + this.getInputGroupAddOnClass()
              + ((this.state.value && this.state.value && this.state.value.length > 0) ? ' pt-2 pb-1' : '')
            }
          >
            <div
              className={((this.props.placeholderTooltip && this.props.placeholderTooltip.length) ? 'mr-3 ' : '') +
                'd-flex justify-content-between'}
              style={{ zIndex: 22100 }}
            >
              {
                (this.state.value &&
                  this.state.value.length >= this.state.maxDisplayedOptions) ?
                  <SelectFieldDisplayValue
                    value={([] as any).concat((this.state.value.length + ' ' + t('items.selected')))}
                    options={this.props.options}
                    parentCid={this.cid}
                    multiple={false}
                    onSelectItem={(event: any, option: any) => { this.onSelectItem(event, option); }}
                  />
                  :
                  <SelectFieldDisplayValue
                    value={([] as any).concat(this.state.value)}
                    options={this.props.options}
                    parentCid={this.cid}
                    multiple={this.state.multiple}
                    onSelectItem={(event: any, option: any) => { this.onSelectItem(event, option); }}
                  />
              }
              <div className="ml-auto">
                <i className="fa fa-caret-down fa-fw text-primary float-right" aria-hidden="true" />
              </div>
            </div>
          </DropdownToggle>
          <DropdownMenu className="w-100">
            {
              this.state.dropdownOpen ?
                <SelectFieldOptions
                  parentCid={this.cid}
                  options={Object.assign([], this.props.options)}
                  selected={this.state.value}
                  onSelectItem={(event: any, option: any) => { this.onSelectItem(event, option); }}
                />
                :
                ''
            }
          </DropdownMenu>
        </ButtonDropdown>
        {
          (this.state.touched && !this.state.valid) ? (
            <small className="float-left invalid-feedback text-left text-danger d-block">
              {this.Validator().validateMessage(this.state, t)}
            </small>
          ) : null
        }
      </div>
    );
  }

  onSelectItem(event: any, selectedOption: any) {
    event.preventDefault();
    if (selectedOption.value === undefined) { return; }
    let value: any[] = [];
    if (!this.state.multiple) {
      value = [selectedOption.value];
    } else {
      let found = false;
      this.state.value.forEach((item: any) => {
        if (item !== selectedOption.value) { value.push(item); } else { found = true; }
      });
      if (!found) { value.push(selectedOption.value); }
    }
    this.setState(
      { value: value, touched: true },
      () => {
        if (this.state.touched === true) {
          this.setState({ valid: this.Validator().validate(this.state) });
        }
        if (this.props.onChange) { this.props.onChange(this.state.value); }
      });
  }

  getInputGroupAddOnClass() {
    return (!this.state.touched ? '' : (this.state.valid ? ' is-valid' : ' is-invalid'));
  }

  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (nextProps.touched) {
      nextState.touched = nextProps.touched;
      nextState.valid = this.Validator().validate(nextState);
    }
    if ((nextProps.value && this.state.value) && (nextProps.value.toString() !== this.state.value.toString())) {
      nextState.value = nextProps.value;
    }
    return true;
  }
}