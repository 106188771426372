import {
    createStore,
    combineReducers,
    //    applyMiddleware
} from 'redux';
// import { createLogger } from 'redux-logger';
import {
    COUNTRIES_CACHE, TIMEZONES_CACHE,
    UNITS_CACHE, USERS_CACHE, LEAVETYPES_CACHE, OFFICES_CACHE, SUBSIDIARIES_CACHE
}
    from '../Constants/ActionTypes';
import { doFetchCountriesFromServer, doFetchTimezonesFromServer } from '../Actions/Caches';
import { STATUS, USERSTATUS } from '../General';

const caches = createStore(
    combineReducers(
        {
            countries: (countries = { arr: {}, map: {}, lang: 'en', status: STATUS.LOADING }, action: any) => {
                switch (action.type) {
                    case COUNTRIES_CACHE.LOADING:
                        return Object.assign({}, countries, {
                            status: STATUS.LOADING
                        });
                    case COUNTRIES_CACHE.READY:
                        const tmp: any = Object.assign({}, countries, {
                            status: STATUS.READY
                        });
                        tmp.arr[action.lang] = action.countries;
                        tmp.map[action.lang] = {};
                        action.countries.forEach((country: any) => {
                            tmp.map[action.lang][country.id] = country;
                        });
                        return tmp;
                    case COUNTRIES_CACHE.ERROR:
                        return Object.assign({}, countries, {
                            status: STATUS.ERROR
                        });
                    default:
                        return countries;
                }
            },
            timezones: (timezones = { arr: [], map: {}, status: STATUS.LOADING }, action: any) => {
                switch (action.type) {
                    case TIMEZONES_CACHE.LOADING:
                        return Object.assign({}, timezones, {
                            status: STATUS.LOADING
                        });
                    case TIMEZONES_CACHE.READY:
                        return {
                            arr: action.timezones,
                            map: {},
                            status: STATUS.READY
                        };
                    case TIMEZONES_CACHE.ERROR:
                        return Object.assign({}, timezones, {
                            status: STATUS.ERROR
                        });
                    default:
                        return timezones;
                }
            },
            units: (units = { arr: [], map: {}, status: STATUS.LOADING }, action: any) => {
                switch (action.type) {
                    case UNITS_CACHE.LOADING:
                        return Object.assign({}, units, {
                            status: STATUS.LOADING
                        });
                    case UNITS_CACHE.READY:
                        const newMap: any = {};
                        action.units.forEach((unit: any) => {
                            newMap[unit.id] = unit;
                        });
                        return {
                            arr: action.units,
                            map: newMap,
                            status: STATUS.READY
                        };
                    case UNITS_CACHE.ERROR:
                        return Object.assign({}, units, {
                            status: STATUS.ERROR
                        });
                    default:
                        return units;
                }
            },
            users: (users: any = { arr: [], map: {}, status: STATUS.LOADING }, action: any) => {
                switch (action.type) {
                    case USERS_CACHE.LOADING:
                        return Object.assign({}, users, {
                            status: STATUS.LOADING
                        });
                    case USERS_CACHE.READY:
                        const newMap: any = {};
                        const newActiveMap: any = {};
                        action.users.forEach((user: any) => {
                            newMap[user.id] = user;
                            if (user.status !== USERSTATUS.DISABLED) {
                                newActiveMap[user.id] = user;
                            }
                        });
                        return {
                            arr: action.users,
                            map: newMap,
                            active: {
                                arr: action.users.filter((user: any) => { return user.status !== USERSTATUS.DISABLED; }),
                                map: newActiveMap
                            },
                            status: STATUS.READY,
                        };
                    case USERS_CACHE.ERROR:
                        return Object.assign({}, users, {
                            status: STATUS.ERROR
                        });
                    default:
                        return users;
                }
            },
            leaveTypes: (leaveTypes: any = { arr: [], map: {}, status: STATUS.LOADING }, action: any) => {
                switch (action.type) {
                    case LEAVETYPES_CACHE.LOADING:
                        return Object.assign({}, leaveTypes, {
                            status: STATUS.LOADING
                        });
                    case LEAVETYPES_CACHE.READY:
                        const newMap: any = {};
                        const newActiveMap: any = {};
                        action.leaveTypes.forEach((leaveType: any) => {
                            newMap[leaveType.id] = leaveType;
                            if (leaveType.active) {
                                newActiveMap[leaveType.id] = leaveType;
                            }
                        });
                        return {
                            arr: action.leaveTypes,
                            map: newMap,
                            active: {
                                arr: action.leaveTypes.filter((leaveType: any) => { return leaveType.active; }),
                                map: newActiveMap
                            },
                            status: STATUS.READY,
                        };
                    case LEAVETYPES_CACHE.ERROR:
                        return Object.assign({}, leaveTypes, {
                            status: STATUS.ERROR
                        });
                    default:
                        return leaveTypes;
                }
            },
            offices: (offices: any = { arr: [], map: {}, status: STATUS.LOADING }, action: any) => {
                switch (action.type) {
                    case OFFICES_CACHE.LOADING:
                        return Object.assign({}, offices, {
                            status: STATUS.LOADING
                        });
                    case OFFICES_CACHE.READY:
                        const newMap: any = {};
                        action.offices.forEach((office: any) => {
                            newMap[office.id] = office;
                        });
                        return {
                            arr: action.offices,
                            map: newMap,
                            status: STATUS.READY
                        };
                    case OFFICES_CACHE.ERROR:
                        return Object.assign({}, offices, {
                            status: STATUS.ERROR
                        });
                    default:
                        return offices;
                }
            },
            subsidiaries: (subsidiaries: any = { arr: [], map: {}, status: STATUS.LOADING }, action: any) => {
                switch (action.type) {
                    case SUBSIDIARIES_CACHE.LOADING:
                        return Object.assign({}, subsidiaries, {
                            status: STATUS.LOADING
                        });
                    case SUBSIDIARIES_CACHE.READY:
                        const newMap: any = {};
                        action.subsidiaries.forEach((subsidiary: any) => {
                            newMap[subsidiary.id] = subsidiary;
                        });
                        return {
                            arr: action.subsidiaries,
                            map: newMap,
                            status: STATUS.READY
                        };
                    case SUBSIDIARIES_CACHE.ERROR:
                        return Object.assign({}, subsidiaries, {
                            status: STATUS.ERROR
                        });
                    default:
                        return subsidiaries;
                }
            }
        }
    ),
    //    applyMiddleware(createLogger({}))
);

export default caches;

doFetchCountriesFromServer(caches, 'en');
doFetchCountriesFromServer(caches, 'ro');
doFetchCountriesFromServer(caches, 'fr');
doFetchCountriesFromServer(caches, 'es');
doFetchCountriesFromServer(caches, 'de');
doFetchTimezonesFromServer(caches);