import * as React from 'react';
import Component from '../../../Component';
import CheckBox from '../../../Components/CheckBox';
import Date from '../../../Components/Date';
import { findLocaleName } from '../../../Utils';

export default class Table extends Component {
    render() {
        const moment = this.getMomentWithLocale();
        const hoes = this.props.hoes;
        let i = 0;
        const trs = hoes.map((hoe: any) => {
            i++;
            const date = moment(hoe.startDate);
            return <tr key={'ModalsImportHoesTable-' + i + '-' + hoe.startDate}>
                <td>
                    <CheckBox
                        defaultValue={hoe.import}
                        onChange={(value: any) => { hoe.import = !hoe.import; }}
                    />
                </td>
                <td style={{ width: '65%', maxWidth: '75%', overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                    <span>
                        {findLocaleName(hoe.names, this.language())}
                    </span>
                </td>
                <td className="text-right">
                    {date.format('dd') + ' '}<Date date={date} />
                </td>
            </tr>
        });
        return <table className="table table-sm table-hover mb-0">
            <tbody>
                {trs}
            </tbody>
        </table>
    }
}