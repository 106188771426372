import * as React from 'react';
import Component from '../../../Component';
import Manager from './Manager';
import * as $ from 'jquery';
import { STATUS } from '../../../General';
import Line from '../../../Components/Line';
import Tabs from '../Tabs';

export default class KnowledgeBase extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            support: {
                value: undefined,
                status: STATUS.LOADING
            },
        };
    }

    componentDidMount() {
        this.getSupportData();
    }

    getSupportData() {
        this.setState(
            {
                support: {
                    value: this.state.support.value,
                    status: STATUS.LOADING
                }
            }, () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getSupportJson(this.language()),
                    dataType: 'json',
                    cache: false,
                    success: (data, textStatus, jqXHR) => {
                        this.setState({ support: { value: data, status: STATUS.READY } });
                    },
                    error: (jqXHR, textStatus, errorThrown) => {
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                        this.setState({ support: { value: undefined, status: STATUS.ERROR } });
                    },
                });
            }
        );
    }
    render() {
        const session: any = this.getSession().getState();
        let ready = session.status === STATUS.READY && this.state.support.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return <div className="container-fluid">
            <div className="row">
                <div className="col-12 mb-3">
                    <Tabs
                        currentTab={this.props.currentTab}
                        onChange={(value: any) => { if (this.props.onTabChange) { this.props.onTabChange(value); } }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <Line />
                </div>
            </div>
            <Manager support={this.state.support.value} />
        </div>
    }
}