import * as React from 'react';
import Component from '../../../../Component';
import * as $ from 'jquery';
import { STATUS } from '../../../../General';
import Table from './Table';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            orders: {
                arr: undefined,
                status: STATUS.LOADING
            }
        };
    }

    componentDidMount() { this.search(); }

    render() {
        let ready = this.state.orders.status === STATUS.READY;
        if (ready === false) { return this.renderLoading(); }
        return <Table orders={this.state.orders} />;
    }

    search() {
        this.setState(
            {
                orders: {
                    arr: [],
                    status: STATUS.LOADING
                }
            },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getSubscriptionOrders(),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            {
                                orders: {
                                    arr: this.computeOrders(data.data),
                                    status: STATUS.READY
                                }
                            },
                            () => {
                                if (this.props.onDisplay) {
                                    this.props.onDisplay(this.state.orders.arr && this.state.orders.arr.length);
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                orders: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    computeOrders(rows: any) {
        return rows.map((row: any) => {
            var i = 0;
            return {
                id: row[i++],
                createdOn: row[i++],
                modifiedOn: row[i++],
                method: row[i++],
                payment: row[i++],
                interval: row[i++],
                users: row[i++],
                tokens: row[i++],
                draftGuid: row[i++],
                invoiceGuid: row[i++],
            };
        });
    }
}