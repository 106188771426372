import * as React from 'react';
import Component from '../Component';
import { MODALS } from '../Constants/ActionTypes';
import AddBlackoutDay from './Modals/AddBlackoutDay/AddBlackoutDay';
import AddHoe from './Modals/AddHoe/AddHoe';
import AddOfficeMembers from './Modals/AddOfficeMembers/AddOfficeMembers';
import AddSubsidiaryMembers from './Modals/AddSubsidiaryMembers/AddSubsidiaryMembers';
import AddTeamMembers from './Modals/AddTeamMembers/AddTeamMembers';
import AdjustWorkingDayAndHours from './Modals/AdjustWorkingDayAndHours/AdjustWorkingDayAndHours';
import AjustEmployeeLeaveTypeAllowance from './Modals/AjustEmployeeLeaveTypeAllowance/AjustEmployeeLeaveTypeAllowance';
import CancelLeave from './Modals/CancelLeave/CancelLeave';
import CopyICalendarUrl from './Modals/CopyICalendarUrl/CopyICalendarUrl';
import ImportHoes from './Modals/ImportHoes/ImportHoes';
import LeaveDecision from './Modals/LeaveDecision/LeaveDecision';
import LeaveTypesBalance from './Modals/LeaveTypesBalance';
import MyNextHoes from './Modals/MyNextHoes';
import None from './Modals/None';
import OrderLeaveTypes from './Modals/OrderLeaveTypes/OrderLeaveTypes';
import OtherLeavesTable from './Modals/OtherLeavesTable';
import RevertTransferLeaveTypeAllowance from './Modals/RevertTransferLeaveTypeAllowance/RevertTransferLeaveTypeAllowance';
import SendLeaveReminder from './Modals/SendLeaveReminder/SendLeaveReminder';
import TransferLeaveTypeAllowance from './Modals/TransferLeaveTypeAllowance/TransferLeaveTypeAllowance';
import TrialStarted from './Modals/TrialStarted/TrialStarted';
import WhoIsOffToday from './Modals/WhoIsOffToday';

export default class Modal extends Component {
    modalSubscription: any;

    componentDidMount() {
        const modal = this.getModal();
        this.modalSubscription = modal.subscribe(() => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        if (this.modalSubscription) {
            this.modalSubscription();
        }
    }

    render() {
        return this.getModalContainer();
    }

    getModalContainer() {
        const modal: any = this.getModal().getState();
        switch ((this.getModal().getState() as any).type) {
            case MODALS.NONE:
                return <None />;
            case MODALS.LEAVE_DECISION:
                return (
                    <LeaveDecision
                        leave={modal.options.leave}
                        callback={modal.options.callback}
                    />
                );
            case MODALS.SEND_LEAVE_REMINDER:
                return (
                    <SendLeaveReminder
                        leave={modal.options.leave}
                        callback={modal.options.callback}
                    />
                );
            case MODALS.CANCEL_LEAVE:
                return (
                    <CancelLeave
                        leave={modal.options.leave}
                        callback={modal.options.callback}
                    />
                );
            case MODALS.WHO_IS_OFF_TODAY:
                return (
                    <WhoIsOffToday
                        rows={modal.options.rows}
                        callback={modal.options.callback}
                    />
                );
            case MODALS.MY_NEXT_HOES:
                return (
                    <MyNextHoes
                        rows={modal.options.rows}
                        callback={modal.options.callback}
                    />
                );
            case MODALS.OTHER_LEAVES:
                return (
                    <OtherLeavesTable
                        leaves={modal.options.leaves}
                        callback={modal.options.callback}
                    />
                );
            case MODALS.LEAVE_TYPES_BALANCE:
                return (
                    <LeaveTypesBalance
                        rows={modal.options.rows}
                        callback={modal.options.callback}
                    />
                );
            case MODALS.IMPORT_HOES:
                return (
                    <ImportHoes
                        office={modal.options.office}
                        callback={modal.options.callback}
                    />
                );
            case MODALS.ADD_HOE:
                return (
                    <AddHoe
                        office={modal.options.office}
                        callback={modal.options.callback}
                    />
                );
            case MODALS.ADD_BLACKOUT_DAY:
                return (
                    <AddBlackoutDay
                        office={modal.options.office}
                        callback={modal.options.callback}
                    />
                );
            case MODALS.ADD_TEAM_MEMBERS:
                return <AddTeamMembers
                    unit={modal.options.unit}
                    callback={modal.options.callback}
                />
            case MODALS.ADD_OFFICE_MEMBERS:
                return <AddOfficeMembers
                    office={modal.options.office}
                    callback={modal.options.callback}
                />
            case MODALS.ADD_SUBSIDIARY_MEMBERS:
                return <AddSubsidiaryMembers
                    subsidiary={modal.options.subsidiary}
                    callback={modal.options.callback}
                />
            case MODALS.ADJUST_EMPLOYEE_LEAVETYPE_ALLOWANCE:
                return <AjustEmployeeLeaveTypeAllowance
                    options={modal.options}
                    callback={modal.options.callback}
                />
            case MODALS.TRANSFER_LEAVE_TYPE_ALLOWANCE:
                return <TransferLeaveTypeAllowance
                    options={modal.options}
                    callback={modal.options.callback}
                />;
            case MODALS.REVERT_TRANSFER_LEAVE_TYPE_ALLOWANCE:
                return <RevertTransferLeaveTypeAllowance
                    options={modal.options}
                    callback={modal.options.callback}
                />
            case MODALS.ADJUST_WORKING_DAY_AND_HOURS:
                return <AdjustWorkingDayAndHours
                    options={modal.options}
                    callback={modal.options.callback}
                />
            case MODALS.COPY_ICALENDAR_URL:
                return <CopyICalendarUrl options={modal.options} callback={modal.options.callback} />;
            case MODALS.TRIAL_STARTED:
                return <TrialStarted options={modal.options} callback={modal.options.callback} />;
            case MODALS.ORDER_LEAVETYPES:
                return <OrderLeaveTypes options={modal.options} callback={modal.options.callback} />;
            default:
                return <None />;
        }
    }
}