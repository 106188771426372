import * as React from 'react'; 
import Component from '../../Component';
import Line from '../../Components/Line';
import { COMPANYTABS } from '../../General';
//import ImportExport from './ImportExport/ImportExport';
import Integrations from './Integrations/Integrations';
import Permisssions from './Permisssions/Permisssions';
import Settings from './Settings/Settings';
import Subscription from './Subscription/Subscription';
import Tabs from './Tabs';

export default class Company extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            currentTab: this.getCurrentTab(COMPANYTABS.SUBSCRIPTION, COMPANYTABS),
        };
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Tabs currentTab={this.state.currentTab} onChange={(value: any) => { this.setState({ currentTab: value }); }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <Line />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        {this.getTab()}
                    </div>
                </div>
            </div>
        );
    }

    getTab() {
        switch (this.state.currentTab) {
            case COMPANYTABS.SETTINGS:
                return <Settings />;
            case COMPANYTABS.INTEGRATIONS:
                return <Integrations />;
            case COMPANYTABS.SUBSCRIPTION:
                return <Subscription />;
            case COMPANYTABS.PERMISSIONS:
                return <Permisssions />;
//            case COMPANYTABS.IMPORTEXPORT:
//                return <ImportExport />;
            default:
                return <Settings />;
        }
    }
}