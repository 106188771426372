import * as React from 'react';
import Component from '../../../Component';
import Employee from '../../../Components/Employee';

export default class Tr extends Component {

    render() {
        const row = this.props.row;
        return <tr>
            <td className="text-truncate border-0 shadow"
                style={{ borderRight: '1px solid #DEE2E6', maxWidth: '150px', top: 0, left: 0, position: 'sticky', zIndex: 1, background: '#FFFFFF' }}
            >
                <Employee employee={row} />
            </td>
            <td className="text-right">
                {this.props.working}
            </td>
            <td className="text-right">
                {this.props.workedAmount}
            </td>
            {
                this.props.leaveTypeIdAmounts.map((leaveTypeIdAmount: any) => {
                    return (
                        <td
                            key={'ReportsGeneralTableTBodyTrTd-' +
                                row.id + '-' + leaveTypeIdAmount.leaveTypeId}
                            className="text-right"
                        >
                            {leaveTypeIdAmount.amount}
                        </td>
                    );
                })
            }
        </tr>
    }
}