import * as React from 'react';
import Component from '../../../Component';
import Back from '../../../Components/Back';
import Line from '../../../Components/Line';
import Tabs from '../Tabs';

export default class Header extends Component {
    render() {
        return <div className="w-100">
            <div className="col-12 mb-3">
                <Back href={this.Urls().getSubsidiaries()} />
                <h5 className="mb-0 d-inline">
                    <i className="fas fa-city mr-1" />
                    {this.props.subsidiary.name}
                </h5>
            </div>
            <div className="col-12 mb-3">
                <Line />
            </div>
            <div className="col-12 mb-3">
                <Tabs currentTab={this.props.currentTab} onChange={(value: any) => { if (this.props.onTabChange) { this.props.onTabChange(value); } }} />
            </div>
        </div>
    }
}