import * as React from 'react';
import Component from '../../../Component';
import DateRangeExtendedPickerField from '../../../Components/DateRangeExtendedPickerField';
import FiltersSwitch from '../../../Components/FiltersSwitch';
import Line from '../../../Components/Line';
import SelectField from '../../../Components/SelectField';
import SortSwitch from '../../../Components/SortSwitch';
import { PERMISSIONS } from '../../../General';
import { leaveTypesOptionsFactory, officesOptionsFactory, subsidiariesOptionsFactory, unitsOptionsFactory, usersOptionsFactory } from '../../../SelectOptionsFactories';
import Tabs from '../Tabs';

export default class Filters extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            expanded: false,
            orderBy: this.props.orderBy,
            users: {
                value: [],
                defaultValue: [],
                touched: false,
            },
            units: {
                value: [],
                defaultValue: [],
                touched: false,
            },
            offices: {
                value: [],
                defaultValue: [],
                touched: false,
            },
            subsidiaries: {
                value: [],
                defaultValue: [],
                touched: false,
            },
            leaveTypes: {
                value: [],
                defaultValue: [],
                touched: false,
            },
            approvers: {
                value: [],
                defaultValue: [],
                touched: false,
            },
            startDate: { value: this.props.defaultFilters.startDate.value },
            endDate: { value: this.props.defaultFilters.endDate.value }
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange() {
        if (this.props.onChange) { this.props.onChange(this.state); }
    }

    render() {
        const session = this.getSession().getState() as any;
        const t = this.translate();
        const moment = this.getMomentWithLocale();
        const caches: any = this.getCaches().getState();
        const usersOptions = usersOptionsFactory(this.getUsersFilterByPermissions(PERMISSIONS.CALENDAR));
        const unitsOptions = unitsOptionsFactory(this.getUnitsFilterByPermissions(PERMISSIONS.CALENDAR));
        const officesOptions = officesOptionsFactory(this.getOfficesFilterByPermissions(PERMISSIONS.CALENDAR));
        const subsidiariesOptions = subsidiariesOptionsFactory(this.getSubsidiariesFilterByPermissions(PERMISSIONS.CALENDAR));
        const leaveTypesOptions = leaveTypesOptionsFactory(caches.leaveTypes.arr, this.language());
        return (
            <div>
                <form onSubmit={(event: any) => { event.preventDefault(); }}>
                    <div className="row">
                        {(this.props.onChangeTab) ?
                            <>
                                <div className="col-12 col-sm-6 mb-3">
                                    <Tabs
                                        currentTab={this.props.currentTab}
                                        onChange={(value: any) => { if (this.props.onChangeTab) { this.props.onChangeTab(value); } }}
                                    />
                                </div>
                                <div className="col-12 mb-3"><Line /></div>
                            </>
                            : null}
                        {this.state.expanded ? null :
                            <div className="col-11 col-lg-6">
                                <DateRangeExtendedPickerField
                                    startDate={this.state.startDate.value}
                                    endDate={this.state.endDate.value}
                                    minimumDate={moment().subtract(10, 'year')}
                                    maximumDate={moment().add(10, 'year')}
                                    touched={this.state.startDate.touched}
                                    placeholder={t('range')}
                                    mode={'CALENDAR'}
                                    onChange={(value: any) => {
                                        this.setState(
                                            {
                                                startDate: Object.assign({}, this.state.startDate, { value: value.startDate, touched: true }),
                                                endDate: Object.assign({}, this.state.startDate, { value: value.endDate })
                                            },
                                            this.onChange
                                        );
                                    }}
                                />
                            </div>}
                        <div className={(this.state.expanded ? 'col-12' : 'col-1 col-lg-6')}>
                            <div className="float-right mt-1">
                                {(this.isScreenSizeSmall()) ?
                                    null : <SortSwitch
                                        title={t('name')}
                                        order={this.state.orderBy.order}
                                        onChange={(order: any) => { this.setState({ orderBy: { field: 'NAME', order: order } }, this.onChange); }}
                                    />}
                                {' '}
                                <FiltersSwitch expanded={this.state.expanded} onClick={() => { this.setState({ expanded: !this.state.expanded }); }} />
                            </div>
                        </div>
                    </div>
                    {(this.state.expanded) ?
                        (<div className="row mt-3">
                            <div className="col-12 col-lg-6 mb-3">
                                <DateRangeExtendedPickerField
                                    startDate={this.state.startDate.value}
                                    endDate={this.state.endDate.value}
                                    minimumDate={moment().subtract(10, 'year')}
                                    maximumDate={moment().add(10, 'year')}
                                    touched={this.state.startDate.touched}
                                    placeholder={t('range')}
                                    mode={'CALENDAR'}
                                    onChange={(value: any) => {
                                        this.setState(
                                            {
                                                startDate: Object.assign({}, this.state.startDate, { value: value.startDate, touched: true }),
                                                endDate: Object.assign({}, this.state.startDate, { value: value.endDate })
                                            },
                                            this.onChange
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 col-lg-6 mb-3">
                                <SelectField
                                    defaultValue={this.state.users.value}
                                    touched={this.state.users.touched}
                                    options={usersOptions}
                                    multiple={true}
                                    placeholder={t('employees')}
                                    maxDisplayedOptions={3}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { users: Object.assign({}, this.state.users, { value }) },
                                            this.onChange
                                        );
                                    }}
                                />
                            </div>
                            {unitsOptions?.length > 1 ?
                                <div className="col-12 col-lg-6 mb-3">
                                    <SelectField
                                        defaultValue={this.state.units.value}
                                        touched={this.state.units.touched}
                                        options={unitsOptions}
                                        multiple={true}
                                        placeholder={t('teams')}
                                        maxDisplayedOptions={3}
                                        onChange={(value: any) => {
                                            this.setState(
                                                { units: Object.assign({}, this.state.units, { value }) },
                                                this.onChange
                                            );
                                        }}
                                    />
                                </div> : null}
                            {officesOptions?.length > 1 ?
                                <div className="col-12 col-lg-6 mb-3">
                                    <SelectField
                                        defaultValue={this.state.offices.value}
                                        touched={this.state.offices.touched}
                                        options={officesOptions}
                                        multiple={true}
                                        placeholder={t('offices')}
                                        onChange={(value: any) => {
                                            this.setState(
                                                { offices: Object.assign({}, this.state.offices, { value }) },
                                                this.onChange
                                            );
                                        }}
                                    />
                                </div> : null}
                            {session.company.subsidiariesEnabled && subsidiariesOptions ?
                                <div className="col-12 col-lg-6 mb-3">
                                    <SelectField
                                        defaultValue={this.state.subsidiaries.value}
                                        touched={this.state.subsidiaries.touched}
                                        options={subsidiariesOptions}
                                        multiple={true}
                                        placeholder={t('subsidiaries')}
                                        onChange={(value: any) => {
                                            this.setState({ subsidiaries: Object.assign({}, this.state.subsidiaries, { value }) }, this.onChange);
                                        }}
                                    />
                                </div> : null}
                            {session.permissions.anonymize ? null :
                                <div className="col-12 col-lg-6 mb-3">
                                    <SelectField
                                        defaultValue={this.state.leaveTypes.value}
                                        touched={this.state.leaveTypes.touched}
                                        options={leaveTypesOptions}
                                        multiple={true}
                                        placeholder={t('leave.types')}
                                        maxDisplayedOptions={3}
                                        onChange={(value: any) => {
                                            this.setState(
                                                { leaveTypes: Object.assign({}, this.state.leaveTypes, { value }) },
                                                this.onChange);
                                        }}
                                    />
                                </div>}
                            <div className={'col-12 col-lg-6 mb-3' + (session.company.id === 629 ? '' : ' d-none')}>
                                <SelectField
                                    defaultValue={this.state.approvers.value}
                                    touched={this.state.approvers.touched}
                                    options={usersOptions}
                                    placeholder={t('approvers')}
                                    multiple={true}
                                    maxDisplayedOptions={3}
                                    onChange={(value: any) => {
                                        this.setState({ approvers: Object.assign({}, this.state.approvers, { value }) },
                                            this.onChange
                                        );
                                    }}
                                />
                            </div>

                        </div>
                        ) : null
                    }
                </form>
            </div>
        );
    }
}