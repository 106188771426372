import * as React from 'react';
import Component from '../../../Component';
import Cog from '../../../Components/Cog';
import NotificationRuleEventClause from '../../../Components/Leave/NotificationRuleEventClause';
import LeaveTypeName from '../../../Components/LeaveType/LeaveTypeName';
import { NOTIFICATIONRULETYPE, NOTIFICATIONRULETYPESCHEDULERWHEN } from '../../../General';

export default class Display extends Component {

    render() {
        const t = this.translate();
        const notificationRule = this.props.notificationRule;
        const caches: any = this.getCaches().getState();
        let filterBy: any = [];
        if (!notificationRule.forCompany) {
            if (notificationRule.notificationRuleFilterClauses) {
                const users = caches.users.map;
                const units = caches.units.map;
                const offices = caches.offices.map;
                notificationRule.notificationRuleFilterClauses.forEach((filterClause: any) => {
                    if (filterClause.companyUserId && users[filterClause.companyUserId]) {
                        filterBy.push({ text: users[filterClause.companyUserId].fullName, id: 'us-' + filterClause.companyUserId });
                    }
                    if (filterClause.companyUnitId && units[filterClause.companyUnitId]) {
                        filterBy.push({ text: units[filterClause.companyUnitId].name, id: 'un-' + filterClause.companyUnitId });
                    }
                    if (filterClause.calendarId && offices[filterClause.calendarId]) {
                        filterBy.push({ text: offices[filterClause.calendarId].name, id: 'of-' + filterClause.calendarId });
                    }
                });
            }
        }
        return (
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <Cog onClick={() => { if (this.props.onEdit) { this.props.onEdit(); } }} />
                        </div>
                    </div>
                    <div className="col-12">
                        <table className="table table-sm w-100 mb-0">
                            <tbody>
                                {notificationRule.type ?
                                    <tr className="d-none">
                                        <td>
                                            {t('type')}
                                            <span className="float-right">
                                                {notificationRule?.type < 2 ? (t('NOTIFICATIONRULETYPE.EVENT_BASED')) : (t('NOTIFICATIONRULETYPE.SCHEDULER_BASED'))}
                                            </span>
                                        </td>
                                    </tr>
                                    : null}
                                {notificationRule.type !== NOTIFICATIONRULETYPE.SCHEDULER_BASED ?
                                    <>
                                        {notificationRule.forAllLeaveTypes ?
                                            <tr>
                                                <td>
                                                    {t('for.all.leave.types')}
                                                    <span className="float-right">
                                                        {this.getBooleanString(notificationRule.forAllLeaveTypes)}
                                                    </span>
                                                </td>
                                            </tr>
                                            : null}
                                        {!notificationRule.forAllLeaveTypes &&
                                            notificationRule?.leaveTypes && notificationRule.leaveTypes.length > 0 ?
                                            <tr>
                                                <td>
                                                    {t('leave.types')}
                                                    <div>
                                                        {notificationRule.leaveTypes.map((leaveTypeId: any) => {
                                                            return <div key={leaveTypeId}><LeaveTypeName leaveType={caches.leaveTypes.map[leaveTypeId]} /></div>

                                                        })}
                                                    </div>
                                                </td>
                                            </tr>
                                            : null}
                                        {notificationRule?.events && notificationRule.events.length > 0 ?
                                            <tr>
                                                <td>
                                                    {t('when')}
                                                    <div>
                                                        {notificationRule.events.map((event: any) => {
                                                            return <div key={event}><NotificationRuleEventClause eventClause={event} /></div>
                                                        })}
                                                    </div>
                                                </td>
                                            </tr>
                                            : null}
                                    </>
                                    :
                                    <>
                                        <tr>
                                            <td>
                                                {t('when')}
                                                <span className="float-right">
                                                    {this.getNotficationRuleWhen(notificationRule)}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {t('content')}
                                                <span className="float-right">
                                                    {t('NOTIFICATIONRULETYPESCHEDULERCONTENT.FULL_DIGEST')}
                                                </span>
                                            </td>
                                        </tr>
                                    </>}
                                {notificationRule.forCompany ?
                                    <tr>
                                        <td>
                                            {t('from.all.company.employees')}
                                            <span className="float-right">
                                                {this.getBooleanString(notificationRule.forCompany)}
                                            </span>
                                        </td>
                                    </tr>
                                    :
                                    <tr>
                                        <td>
                                            {t('from.whom')}
                                            <div>
                                                {filterBy.map((filter: any) => {
                                                    return <div key={filter.id}>{filter.text}</div>
                                                })}
                                            </div>
                                        </td>
                                    </tr>

                                }
                                {notificationRule?.users && notificationRule.users.length > 0 ?
                                    <tr>
                                        <td>
                                            {t('to.whom')}
                                            <div>
                                                {notificationRule.users.map((userId: any) => {
                                                    return <div key={userId}>{caches.users.map[userId].fullName}</div>
                                                })}
                                            </div>
                                        </td>
                                    </tr>
                                    : null}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
        );
    }

    getNotficationRuleWhen(notificationRule: any) {
        const t = this.translate();
        switch (notificationRule.when) {
            case NOTIFICATIONRULETYPESCHEDULERWHEN.MONDAY: return t('NOTIFICATIONRULETYPESCHEDULERWHEN.MONDAY');
            case NOTIFICATIONRULETYPESCHEDULERWHEN.SUNDAY: return t('NOTIFICATIONRULETYPESCHEDULERWHEN.SUNDAY');
            case NOTIFICATIONRULETYPESCHEDULERWHEN.FIRST_DAY_OF_THE_MONTH: return t('NOTIFICATIONRULETYPESCHEDULERWHEN.FIRST_DAY_OF_THE_MONTH');
        }
    }


    getBooleanString(value: any) {
        const t = this.translate();
        return value ? t('yes') : t('no');
    }
}