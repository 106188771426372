import * as React from 'react';
import Urls from '../../Urls';
import Component from '../../Component';
import GoogleIcon from './GoogleIcon';

export default class GoogleButton extends Component {
    render() {
        return (
            <a
                href={Urls.getGoogleClientAuthUrl()}
                className={'btn btn-outline-google btn-block' + (this.isScreenSizeSmall() ? ' btn-sm' : '')}
            >
                <GoogleIcon />
                Google
            </ a>
        );
    }
}