import * as React from 'react';
import Component from '../../../Component';
import Line from '../../../Components/Line';
import Tabs from '../Tabs';

export default class Sent extends Component {

    render() {
        const t = this.translate();
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Tabs
                            currentTab={this.props.currentTab}
                            // tslint:disable-next-line:max-line-length
                            onChange={(value: any) => { if (this.props.onTabChange) { this.props.onTabChange(value); } }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <Line />
                    </div>
                </div>
                <div className={this.getCardClassNameSize()}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 mt-3 mb-3">
                                <h5 className="mb-0">{t('thank.you')}</h5>
                                <div>{t('your.ticket.was.registered')}
                                    {' ' + t('you.will.be.contacted')}</div>
                                <div>{t('for.request.sent.on.weekends')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}