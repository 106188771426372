import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import { usersFactory } from '../../../Factories';
import { STATUS } from '../../../General';
import List from './List';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            employees: {
                arr: [],
                status: STATUS.LOADING
            }
        };
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState(
            { employees: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getEmployeesSearch(
                        { filters: { units: { value: [].concat(this.props.unit.id) } } }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        const session = this.getSession().getState() as any;
                        const employees = usersFactory(data.data, session).filter((uzer: any) => { return uzer.status !== 'DISABLED'; });
                        employees.forEach((employee: any) => { delete employee.companyUnit; })
                        employees.sort((a: any, b: any) => {
                            return a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
                        })
                        this.setState({ employees: { arr: employees, status: STATUS.READY } });
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState({ employees: { arr: [], status: STATUS.ERROR } });
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        return (
            <List unit={this.props.unit} employees={this.state.employees} onRefresh={() => { this.search(); }} />
        );
    }
}