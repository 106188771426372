import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../Component';
import { STATUS } from '../../General';
import { getURLParameter } from '../../Utils';
import Settings from './Settings/Settings';

export default class NotificationRule extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            notificationRule: { value: undefined, status: STATUS.LOADING },
        };
    }

    componentDidMount() {
        this.getNotificationRule();
    }

    getNotificationRule() {
        this.setState(
            { notificationRule: { value: this.state.notificationRule.value, status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getNotificationRule(getURLParameter('id')),
                    dataType: 'json',
                    cache: false,
                    success: (data, textStatus, jqXHR) => {
                        this.setState({ notificationRule: { value: data, status: STATUS.READY } });
                    },
                    error: (jqXHR, textStatus, errorThrown) => {
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                        this.setState({ notificationRule: { value: undefined, status: STATUS.ERROR }, });
                    },
                });
            }
        );
    }

    render() {
        const session: any = this.getSession().getState();
        const caches: any = this.getCaches().getState();
        let ready = session.status === STATUS.READY &&
            caches.users.status === STATUS.READY &&
            caches.offices.status === STATUS.READY &&
            caches.units.status === STATUS.READY &&
            caches.countries.status === STATUS.READY &&
            caches.leaveTypes.status === STATUS.READY &&
            this.state.notificationRule.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        const defaultArgs: any = {
            notificationRule: this.state.notificationRule.value,
            onRefresh: () => { this.getNotificationRule(); },
        };
        return (
            <div className="container-fluid">
                <div className="row">
                    {<Settings {...defaultArgs} />}
                </div>

            </div>
        );
    }
}