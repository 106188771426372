import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import DateRangeField from '../../../Components/DateRangeField';
import InputField from '../../../Components/InputField';
import Submit from '../../../Components/Submit';
import { formatForServer } from '../../../Utils';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        this.state = {
            name: {
                touched: false,
                rules: this.Rules().BasicNameRules()
            },
            date: {
                value: moment(),
                touched: false,
            },
            add: {
                pleaseWait: false
            },
        };
    }

    render() {
        const t = this.translate();
        const moment = this.getMomentWithLocale();
        return (
            <form onSubmit={(event: any) => { this.submit(event); }}>
                <div className="row">
                    <div className="col-12 mb-3">
                        <InputField
                            touched={this.state.name.touched}
                            rules={this.state.name.rules}
                            type="text"
                            placeholder={t('name')}
                            onChange={(value: any) => { this.setState({ name: Object.assign({}, this.state.name, { value: value }) }); }}
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <DateRangeField
                            startDate={this.state.date.value}
                            endDate={this.state.date.value}
                            touched={this.state.date.touched}
                            placeholder={t('date')}
                            range={false}
                            minDate={moment().startOf('day').clone().add(-2, 'years')}
                            maxDate={moment().startOf('day').clone().add(2, 'years')}
                            onChange={(value: any) => { this.setState({ date: Object.assign({}, this.state.date, { value: value.date }) }); }}
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <Submit
                            faIcon="far fa-hdd"
                            disabled={!this.isFormValid()}
                            pleaseWait={this.state.add.pleaseWait}
                        >
                            {t('add')}
                        </Submit>
                    </div>
                </div>
            </form>
        );
    }

    isFormValid() {
        return this.Validator().validate(this.state.name) &&
            this.Validator().validate(this.state.date);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                name: Object.assign({}, this.state.name, { touched: true }),
                date: Object.assign({}, this.state.date, { touched: true })
            },
            () => {
                if (this.isFormValid()) {
                    this.addHoe();
                }
            }
        );
    }

    addHoe() {
        const t = this.translate();
        $.ajax({
            type: 'PUT',
            contentType: 'application/json',
            url: this.Endpoints().createHoe(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                id: 0,
                color: '#7FD5E0',
                startDate: formatForServer(this.state.date.value),
                endDate: formatForServer(this.state.date.value),
                names: [{
                    lang: this.language(),
                    value: this.state.name.value
                }],
                calendar: {
                    id: this.props.office.id
                }
            }),
            beforeSend: () => {
                this.setState({ add: { pleaseWait: true } });
            },
            success: (data: any, textStatus: any, jqXHR: any) => {
                this.successToastr(t('the.public.holiday.or.event.was.added'));
                if (this.props.onClose) {
                    this.props.onClose();
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            },
            complete: (jqXHR, textStatus) => {
                this.setState({ add: { pleaseWait: false } });
            }
        });
    }
}