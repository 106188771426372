import * as React from 'react';
import { goTo } from './Actions/Router';
import Component from './Component';
import { MODALS } from './Constants/ActionTypes';
import Footer from './Containers/Footer';
import Menu from './Containers/Menu/Menu';
import ModalProxy from './Containers/ModalProxy';
import Page from './Containers/Page';
import Toastr from './Containers/Toastr';
import caches from './Reducers/Caches';
import i18n from './Reducers/I18n';
import modal from './Reducers/Modal';
import router from './Reducers/Router';
import screenSize from './Reducers/ScreenSize';
import session from './Reducers/Session';

export default class App extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
    this.state = { paddingLeft: 0 };
  }

  componentDidMount() {
    [router, session, caches, screenSize, i18n, modal].forEach((item) => {
      item.subscribe(() => { this.forceUpdate(); });
    });
    goTo(router, window.location.href);
  }

  render() {
    return (
      <div className="d-flex flex-row w-100 mw-100" style={{ minHeight: '100vh' }}>
        <Menu
          top={false}
          onResize={(value: any) => { this.setState({ paddingLeft: value }); }}
        />
        <div className="w-100 mw-100 bg-light" style={{ minHeight: '100vh' }}>
          <Menu top={true} onResize={(value: any) => { this.setState({ paddingLeft: value }); }} />
          <div
            className={(Component.isScreenSizeSmall(screenSize.getState())) &&
              (modal.getState() as any).type !== MODALS.NONE ?
              'd-none' : 'w-100 mw-100'}
            style={{ paddingLeft: (this.state as any).paddingLeft }}
          >
            <div className="pt-3 pb-3 w-100 mw-100 h-100" style={{ minHeight: '100vh' }}>
              <Page />
              <Footer />
            </div>
          </div>
          <ModalProxy />
          <Toastr />
        </div>
      </div>
    );
  }
}