import * as React from 'react';
import Component from '../../../../Component';

export default class Item extends Component {

    render() {
        const employee = this.props.employee;
        return <tr><td>
            <span>
                <i className="fas fa-user fa-fw" aria-hidden="true" />
                {' ' + employee.fullName}
            </span>
        </td></tr>
    }
}