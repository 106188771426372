import Component from '../../Component';
import Error from '../../Components/Error';
import { STATUS, VIEWMODE } from '../../General';
import Item from './Item';
import RowItem from './RowItem';

export default class List extends Component {
    constructor(props: any) {
        super(props);
        this.onRefresh = this.onRefresh.bind(this);
    }

    render() {
        const leaves = this.props.leaves;
        switch (leaves.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                if (leaves.arr.length === 0) {
                    const t = this.translate();
                    return (
                        <div className="col-12 text-danger mb-3">
                            {t('no.leaves.found')}
                        </div>
                    );
                }
                if (this.props.viewMode === VIEWMODE.LIST) {
                    const t = this.translate();
                    return <div className="col-12 mb-3">
                        <div className="card w-100">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-sm table-hover w-100 mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ width: '30%' }} className="border-top-0">
                                                    <i className="mr-1 fas fa-edit fa-fw" aria-hidden="true" />
                                                    {t('leave.type')}
                                                </th>
                                                <th scope="col" style={{ width: '30%' }} className="border-top-0">
                                                    <i className="mr-1 fas fa-user-clock fa-fw" aria-hidden="true" />
                                                    {t('range')}
                                                </th>
                                                <th scope="col" style={{ width: '30%' }} className="border-top-0">
                                                    <i className="mr-1 fas fa-stream fa-fw" aria-hidden="true" />
                                                    {t('status')}
                                                </th>
                                                <th scope="col" className="text-right border-top-0" style={{ width: '10%' }}>
                                                    <i className="mr-1 fas fa-tools fa-fw" aria-hidden="true" />
                                                    {t('actions')}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {leaves.arr.map((leave: any) => { return this.getRowItem(leave); })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                const leavesComps = leaves.arr.map((leave: any) => {
                    return (
                        <div key={'MyLeaves' + this.props.viewMode + '-' + leave.id} className={this.getListColSize(this.props.viewMode) + ' mb-3'}>
                            {this.getItem(leave)}
                        </div>
                    );
                });
                return leavesComps;
            }
            default:
                return (<Error />);
        }
    }

    getItem(leave: any) { return (<Item onRefresh={this.onRefresh} leave={leave} />); }

    getRowItem(leave: any) { return (<RowItem key={'Leave-' + this.props.viewMode + '-' + leave.id} onRefresh={this.onRefresh} leave={leave} />); }

    onRefresh() { if (this.props.onRefresh) { this.props.onRefresh(); } }
}