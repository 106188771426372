import * as React from 'react';
import Component from '../../Component';
import Anchor from '../../Components/Anchor';
import LeaveCancelAction from '../../Components/Leave/LeaveCancelAction';
import LeaveDetailedStatus from '../../Components/Leave/LeaveDetailedStatus';
import LeaveEventTime from '../../Components/Leave/LeaveEventTime';
import LeaveInterval from '../../Components/Leave/LeaveInterval';
import LeaveReplacement from '../../Components/Leave/LeaveReplacement';
import LeaveSendReminderAction from '../../Components/Leave/LeaveSendReminderAction';
import PaperCopyAction from '../../Components/Leave/PaperCopyAction';
import LeaveRules from '../../LeaveRules';
import LeaveName from '../Reports/LeaveName';

export default class RowItem extends Component {
    constructor(props: any) {
        super(props);
        this.onRefresh = this.onRefresh.bind(this);
    }

    render() {
        const moment = this.getMomentWithLocale();
        const t = this.translate();
        const leave = this.props.leave;
        const canBeCanceled = this.canBeCanceled(leave);
        const canBePaperCopy = this.canBePaperCopy(leave);
        const canSendReminder = LeaveRules.canSendReminder(leave, (this.getSession().getState() as any).companyUser);
        return <tr>
            <td>
                <div>
                    <span className="text-secondary float-right">
                        <LeaveEventTime>
                            {t('registered.on')}
                            <div>{moment(leave.requestedOn).format('LLL')}</div>
                            {
                                (leave.createdBy) ?
                                    (<div>{t('by') + ' '} {leave.createdBy.firstName} {leave.createdBy.lastName}</div>)
                                    : <></>
                            }
                        </LeaveEventTime>
                    </span>
                    <LeaveName leave={leave} />
                </div>
                {leave.comment ? (<div><Anchor faIcon="far fa-comment fa-fw mr-1" title={t('request.comment')} />{leave.comment}</div>) : null}
                {leave.replacement && leave.replacement.length > 2 ? <div><LeaveReplacement leave={leave} /></div> : null}
            </td>
            <td>
                <LeaveInterval leave={leave} />
            </td>
            <td>
                <LeaveDetailedStatus leave={leave} />
            </td>
            <td className="text-right">
                {(canSendReminder) ? <LeaveSendReminderAction leave={leave} noText={true} /> : null}
                {(canBeCanceled) ? <LeaveCancelAction onRefresh={this.onRefresh} leave={leave} noText={true} /> : null}
                {(canBePaperCopy) ? <PaperCopyAction leave={leave} noText={true} /> : null}
            </td>
        </tr>
    }

    canBePaperCopy(leave: any) {
        return LeaveRules.canBePaperCopy(leave,
            (this.getCaches().getState() as any).leaveTypes.map[leave.leaveType.id],
            (this.getSession().getState() as any).company);
    }

    canBeCanceled(leave: any) {
        return LeaveRules.canBeCanceledForMyLeaves(leave, (this.getSession().getState() as any).companyUser);
    }

    onRefresh() { if (this.props.onRefresh) { this.props.onRefresh(); } }
}