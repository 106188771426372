import * as React from 'react';
import Component from '../../../Component';
import Display from './Display';
import Form from './Form';
import { STATUS } from '../../../General';
import * as $ from 'jquery';

export default class Settings extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            companySettings: {
                value: undefined,
                status: STATUS.LOADING
            },
            editMode: false,
        };
    }

    componentDidMount() {
        this.getCompany();
    }

    getCompany() {
        this.setState(
            {
                companySettings: {
                    value: undefined,
                    status: STATUS.LOADING
                }
            },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getCompanySettings(),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        data.office = data.calendar;
                        this.setState(
                            {
                                companySettings: {
                                    value: data,
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                companySettings: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.units.status === STATUS.READY &&
            caches.users.status === STATUS.READY &&
            caches.offices.status === STATUS.READY &&
            caches.countries.status === STATUS.READY &&
            caches.timezones.status === STATUS.READY &&
            this.state.companySettings.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return this.state.editMode ?
            <Form
                companySettings={this.state.companySettings.value}
                onRefresh={() => { this.setState({ editMode: false }); this.getCompany(); }}
            />
            : <Display
                companySettings={this.state.companySettings.value}
                onEdit={() => { this.setState({ editMode: !this.state.editMode }); }}
            />
    }
}