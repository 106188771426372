import * as $ from 'jquery';
import Component from '../../../Component';
import { STATUS, USERSTATUS } from '../../../General';
import CompanyViewManager from '../../Calendar/CompanyView/Manager';
import Table from './Table';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        this.state = {
            filters: {
                startDate: { value: moment().startOf('day') },
                endDate: { value: moment().startOf('day') },
            },
            rows: {
                arr: [],
                status: STATUS.LOADING
            }
        };
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState(
            { rows: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getDayEventsGroupedWW({
                        filters: this.state.filters
                    }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        const computedData = this.computeUsers(data.data);
                        if (computedData.rows.length === 0) {
                            if (this.props.onEmpty) {
                                this.props.onEmpty();
                            }
                        }
                        this.setState(
                            { rows: { arr: computedData.rows, status: STATUS.READY } }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState({ rows: { arr: [], status: STATUS.ERROR } });
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    computeUsers(data: any) {
        const rows: any = [];
        const caches: any = this.getCaches().getState();
        const session = (this.getSession().getState() as any);
        let users = caches.users;
        let leaveTypes = caches.leaveTypes;
        let map = new Map();
        leaveTypes.arr.forEach((item: any) => {
            map.set(item.id, []);
        })
        let pushedAlready: any = {};
        data.forEach((item: any) => {
            if (users.map[item[0]].status === USERSTATUS.DISABLED) { return; }
            if (item[1] != null && !pushedAlready[item[0]]) {
                pushedAlready[item[0]] = true;
                const row = {
                    employee: users.map[item[0]],
                    dayEvent: CompanyViewManager.createDayEvent(item, session)
                };
                rows.push(row);
                map.get(row.dayEvent.leaveTypeId).push(row);
            }
        });
        let zaRows: any = [];
        leaveTypes.arr.forEach((leaveType: any) => {
            const rows = map.get(leaveType.id);
            if (rows?.length) {
                let zaLeaveType = leaveType;
                rows.sort((a: any, b: any) => {
                    return a.employee.fullName.toLowerCase().localeCompare(b.employee.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
                }).forEach((row: any) => {
                    zaRows.push({ leaveType: zaLeaveType, employee: row.employee, dayEvent: row.dayEvent });
                    zaLeaveType = null;
                })
            }
        })

        return { rows: zaRows };
    }

    render() {
        if (this.state.rows.arr.length === 0 || this.state.rows.status === STATUS.LOADING) {
            return <></>;
        }
        return <div className="card h-100">
            <div className="card-body">
                <Table rows={this.state.rows} />
            </div>
        </div>
    }
}