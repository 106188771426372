import * as $ from 'jquery';
import * as React from 'react';
import { doFetchSubsidiariesFromServer, doFetchUsersFromServer } from '../../Actions/Caches';
import Component from '../../Component';
import Back from '../../Components/Back';
import InputField from '../../Components/InputField';
import Line from '../../Components/Line';
import SelectField from '../../Components/SelectField';
import Submit from '../../Components/Submit';
import TextareaField from '../../Components/TextareaField';
import { STATUS } from '../../General';
import { countriesOptionsFactory, usersOptionsFactory } from '../../SelectOptionsFactories';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            name: {
                touched: false,
                rules: this.Rules().BasicNameRules()
            },
            country: {
                value: [this.getCountry()],
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            managerCompanyUser: {
                value: [],
                rules: this.Rules().BasicSelectRules(),
                touched: false,
            },
            description: {
                touched: false,
                rules: this.Rules().MaxLengthTextareaRules()
            },
            add: {
                pleaseWait: false
            }
        };
    }

    getCountry() {
        const session: any = this.getSession().getState();
        const country = session.company.country;
        return country && country.length > 0 ? country : 'US';
    }

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.countries.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        const countriesOptions = countriesOptionsFactory(caches.countries.arr[this.language()]);
        const usersOptions = usersOptionsFactory(caches.users.arr);
        const t = this.translate();
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Back href={this.Urls().getSubsidiaries()} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <h5 className="mb-0">{t('add.subsidiary')}</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <Line />
                    </div>
                </div>
                <div className={this.getCardClassNameSize()}>
                    <div className="card-body">
                        <form onSubmit={(event: any) => { this.submit(event); }}>
                            <fieldset>
                                <div className="row">
                                    <div className="col-12 mb-3 mt-3">
                                        <InputField
                                            touched={this.state.name.touched}
                                            rules={this.state.name.rules}
                                            type="text"
                                            placeholder={t('name')}
                                            focus={this.isScreenSizeSmall() ? false : true}
                                            onChange={(value: any) => {
                                                this.setState(
                                                    { name: Object.assign({}, this.state.name, { value: value }) }
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <SelectField
                                            defaultValue={this.state.managerCompanyUser.value}
                                            touched={this.state.managerCompanyUser.touched}
                                            options={usersOptions}
                                            rules={this.state.managerCompanyUser.rules}
                                            placeholder={t('manager')}
                                            onChange={(value: any) => { this.setState({ managerCompanyUser: Object.assign({}, this.state.managerCompanyUser, { value }) }); }}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <SelectField
                                            cid={this.state.country.cid}
                                            defaultValue={this.state.country.value}
                                            touched={this.state.country.touched}
                                            options={countriesOptions}
                                            multiple={false}
                                            rules={this.state.country.rules}
                                            placeholder={t('country')}
                                            onChange={(value: any) => {
                                                this.setState(
                                                    { country: Object.assign({}, this.state.country, { value: value }) }
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <TextareaField
                                            touched={this.state.description.touched}
                                            type="text"
                                            rows={2}
                                            placeholder={t('description')}
                                            onChange={(value: any) => { this.setState({ description: Object.assign({}, this.state.description, { value: value }) }); }}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <Submit
                                            faIcon="far fa-hdd"
                                            disabled={!this.isFormValid()}
                                            blocked={this.state.add.pleaseWait}
                                        >
                                            {t('add.subsidiary')}
                                        </Submit>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    isFormValid() {
        return this.Validator().validate(this.state.name)
            && this.Validator().validate(this.state.country)
            && this.Validator().validate(this.state.managerCompanyUser)
            && this.Validator().validate(this.state.description);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                name: Object.assign({}, this.state.name, { touched: true }),
                country: Object.assign({}, this.state.country, { touched: true }),
                managerCompanyUser: Object.assign({}, this.state.managerCompanyUser, { touched: true }),
                description: Object.assign({}, this.state.description, { touched: true })
            },
            () => { if (this.isFormValid()) { this.addSubsidiary(); } }
        );
    }

    addSubsidiary() {
        const t = this.translate();
        $.ajax({
            type: 'PUT',
            contentType: 'application/json',
            url: this.Endpoints().getSubsidiaryCreate(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                name: this.state.name.value,
                country: this.state.country.value[0],
                manager: { id: this.state.managerCompanyUser.value[0] },
                description: this.state.description.value,
            }),
            beforeSend: () => {
                this.setState({ add: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('the.subsidiary.was.added'));
                doFetchSubsidiariesFromServer(this.getCaches());
                doFetchUsersFromServer(this.getCaches(), this.getSession().getState());
                this.goTo(this.Urls().getSubsidiary(data));
            },
            error: (jqXHR, textStatus, errorThrown) => {
                if (+jqXHR.status === 400) {
                    var reason = jqXHR.getResponseHeader('REASON');
                    if (reason && reason === 'DUPLICATE') {
                        this.successToastr(t('subsidiary.duplicate'));
                        return;
                    }
                }
                this.generalErrorToastr();
            },
            complete: (jqXHR, textStatus) => {
                this.setState({ add: { pleaseWait: false } });
            }
        });
    }
}