import { SUBSCRIPTIONTYPE } from './General';
// import * as moment from 'moment';

export default class SubscriptionRules {
    static trial(session: any) {
        return (session.company.subscriptionType === SUBSCRIPTIONTYPE.TRIAL);
    }

    static trialExpired(session: any) {
        return (session.company.subscriptionType === SUBSCRIPTIONTYPE.TRIAL);
    }

    static free(session: any) {
        return (session.company.subscriptionType === SUBSCRIPTIONTYPE.FREE);
    }

    static subscription(session: any) {
        return (session.company.subscriptionType === SUBSCRIPTIONTYPE.SUBSCRIPTION);
    }
}