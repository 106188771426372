import * as React from 'react';
import DateRangePicker from 'react-daterange-picker';
import { Popover, PopoverBody } from 'reactstrap';
import Component from '../Component';
import Placeholder from './Placeholder/Placeholder';

// http://onefinestay.github.io/react-daterange-picker/
export default class DateRangePickerField extends Component {
    public static CID = 0;
    private id: string;
    textInput: any;

    constructor(props: any) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        const state: any = {
            popoverOpen: false,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            touched: false
        };
        this.state = state;
        this.id = 'DateRangePickerField' + DateRangePickerField.CID++;
    }

    public toggle() { this.setState({ popoverOpen: !this.state.popoverOpen }); }

    public handleSelect(value: any) {
        this.toggle();
        if (this.props.onChange) {
            this.props.onChange({
                startDate: value.start,
                endDate: value.end,
                touched: true
            });
        }
    }

    getValue() {
        const dateFormat = this.getDateFormat();
        return this.props.startDate.format(dateFormat) + ' - ' + this.props.endDate.format(dateFormat);
    }

    public render() {
        const value = this.getValue();
        return (
            <div className="w-100">
                <Placeholder
                    placeholder={this.props.placeholder}
                    placeholderTooltip={this.props.placeholderTooltip}
                    placeholderTooltipCid={'IFPTCid' + this.cid}
                    value={value}
                    onClick={() => { this.focusNow(); }}
                />
                <input
                    key="input"
                    type={this.props.type ? this.props.type : 'text'}
                    className={this.getInputClass() + ' pr-4 bg-white' +
                        ((value && value && value.length > 0) ? ' pt-3 pb-1' : '')
                    }
                    value={value}
                    ref={(input) => { this.textInput = input; }}
                    onClick={() => { this.toggle(); }}
                    id={this.id}
                    readOnly={true}
                />
                <Popover placement="bottom" isOpen={this.state.popoverOpen} target={this.id} toggle={this.toggle} trigger='legacy'>
                    <PopoverBody>
                        <div className="w-100">
                            <DateRangePicker
                                value={this.getPickerValue()}
                                onSelect={this.handleSelect}
                                selectionType="range"
                                minimumDate={this.props?.minimumDate?.toDate()}
                                maximumDate={this.props?.maximumDate?.toDate()}
                            />
                        </div>
                    </PopoverBody>
                </Popover>
            </div>
        );
    }

    getPickerValue(): any {
        const moment = this.getMomentWithLocale();
        return moment.range(this.props.startDate, this.props.endDate);
    }

    focusNow() {
        this.textInput.focus();
        this.toggle();
    }

    getInputClass() {
        return 'form-control' + (!this.props.touched ? '' : ' is-valid');
    }

}