import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../Component';
import A from '../../Components/A';
import ETOL from '../../Components/ETOL';
import Line from '../../Components/Line';
import { fullNameFactory } from '../../Factories';
import { ETOLTABS, ORDER, STATUS, VIEWMODE } from '../../General';
import Filters from './Filters';
import List from './List';

export default class Offices extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            filters: {
                search: { value: '' },
                viewMode: VIEWMODE.GRID,
                orderBy: { field: 'NAME', order: ORDER.ASC }
            },
            offices: {
                arr: [],
                status: STATUS.LOADING
            }
        };
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState(
            { offices: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getOffices({ filters: this.state.filters }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            {
                                offices: {
                                    arr: this.prepareOffices(data.data).sort((a: any, b: any) => {
                                        // tslint:disable-next-line:max-line-length
                                        return this.compareString((a.name).toLowerCase(), (b.name).toLowerCase(), this.state.filters.orderBy.order);
                                    }),
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                offices: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        const t = this.translate();
        return <div className="container-fluid">
            <div className="row mb-3">
                <div className="col-12">
                    <ETOL currentTab={ETOLTABS.OFFICES} />
                    <A faIcon="fa fa-plus" href={this.Urls().getOfficeAdd()} className="btn btn-outline-primary float-right" title={t('add.office')}>
                        {this.isScreenSizeSmall() ? undefined : t('add.office')}
                    </A>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <Line />
                </div>
            </div>
            <Filters
                viewMode={this.state.filters.viewMode}
                orderBy={this.state.filters.orderBy}
                onChange={(value: any) => { this.setState({ filters: Object.assign({}, value) }, () => { this.search(); }); }}
            />
            <div className="row">
                <List offices={this.state.offices} viewMode={this.state.filters.viewMode}
                    onRefresh={() => { this.search(); }}
                />
            </div>
        </div>
    }

    prepareOffices(offices: any[]) {
        const users = (this.getCaches().getState() as any).users;
        return offices.map((office) => {
            office.members = [];
            office.members = users.arr.filter((user: any) => {
                return (user.office.id === office.id && (user.status === 'ACTIVE' || user.status === 'INVITED')) ? true : false;
            }).sort((a: any, b: any) => {
                return a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
            })
            const session = this.getSession().getState() as any;
            if (office?.officeManager?.id) {
                office.officeManager.fullName = fullNameFactory(office.officeManager, session.company.nameFormat);
            }
            return office;
        });
    }
}