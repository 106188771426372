import * as React from 'react';
import Component from '../../../Component';
import { STATUS } from '../../../General';
import * as $ from 'jquery';
import View from './View';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            slackApp: {
                value: undefined,
                status: STATUS.LOADING
            }
        };
    }

    componentDidMount() { this.load(); }

    load() {
        this.setState(
            {
                slackApp: {
                    value: undefined,
                    status: STATUS.LOADING
                }
            },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getCompanySlackApp(),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            {
                                slackApp: {
                                    value: data.slackApp,
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                slackApp: {
                                    value: undefined,
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        let ready = this.state.slackApp.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (
            <View slackApp={this.state.slackApp.value} />
        );
    }
}