import {
    createStore,
    //    applyMiddleware 
} from 'redux';
// import { createLogger } from 'redux-logger';
import { CHANGE_LANGUAGE, CHANGE_COUNTRY } from '../Constants/ActionTypes';
import { changeLanguage } from '../Actions/I18n';
import * as moment from 'moment';
import 'moment/min/locales';
import { Cookie, getURLParameter } from '../Utils';
const COOKIE_LANG_NAME = 'LANG';

const i18n = createStore(
    (state = { language: 'en', moment: moment, translate: (key: any) => { return ''; } }, action: any) => {
        switch (action.type) {
            case CHANGE_LANGUAGE:
                moment.locale(action.language);
                Cookie.create(COOKIE_LANG_NAME, action.language);
                return {
                    translate: action.translate,
                    language: action.language,
                    moment: moment
                };
            default:
                return state;
        }
    },
    //    applyMiddleware(createLogger({}))
);

export default i18n;

export const country = (state = 'US', action: any) => {
    switch (action.type) {
        case CHANGE_COUNTRY:
            return action.country;
        default:
            return state;
    }
};

const getLanguage = () => {
    let lang: any = getURLParameter('lang');
    if (!lang) {
        lang = Cookie.read(COOKIE_LANG_NAME);
    }
    return lang ? lang : 'en';
};
changeLanguage(i18n, getLanguage());