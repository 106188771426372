import * as React from 'react';
import Table from './Table';
import Component from '../../../../Component';

export default class Manager extends Component {

    render() {
        const caches: any = this.getCaches().getState();
        const units = caches.units.arr.filter((unit: any) => {
            if (unit.managerCompanyUser && unit.managerCompanyUser.id &&
                unit.managerCompanyUser.id === this.props.employee.id) {
                return true;
            }
            return false;
        });
        if (units && units.length > 0) {
            return (
                <div className="col-12 mt-3">
                    {this.getTable(units)}
                </div>
            );
        } else {
            if (this.props.onEmpty) { this.props.onEmpty(); }
            return null;
        }
    }

    getTable(units: any) {
        return (<Table employee={this.props.employee} units={units} />);
    }
}