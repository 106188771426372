import * as React from 'react';
import ResponsiveModal from '../ResponsiveModal';
import Manager from './Manager';
import { none } from '../../../Actions/Modal';

export default class OrderLeaveTypes extends ResponsiveModal {
    getContent() {
        return (
            <Manager
                onClose={() => {
                    this.toggle();
                    none(this.getModal(), {});
                    if (this.props.callback) { this.props.callback(); }
                }}
            />
        );
    }

    getTitle(): any {
        const t = this.translate();
        return (<h5 className="m-0 p-0">{t('order.leave.types')}</h5>);
    }
}