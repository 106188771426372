import * as React from 'react';
import Component from '../../../../Component';
import Manager from './Manager';
import { STATUS } from '../../../../General';

export default class ManagerFor extends Component {
    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.users.status === STATUS.READY && caches.units.status === STATUS.READY;
        if (ready === false) { return null; }
        return (<Manager employee={this.props.employee} onEmpty={() => { if (this.props.onEmpty) { this.props.onEmpty(); } }} />);
    }
}