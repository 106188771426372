import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import { usersFactory } from '../../../Factories';
import { STATUS } from '../../../General';
import List from './List';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = { employees: { arr: [], status: STATUS.LOADING } };
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState(
            { employees: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getEmployeesSearch(
                        { filters: { offices: { value: [].concat(this.props.office.id) } } }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        const employees = usersFactory(data.data, this.getSession().getState());
                        employees.forEach((employee: any) => {
                            delete employee.office;
                        });
                        this.setState(
                            {
                                employees: {
                                    arr: employees.filter((user: any) => {
                                        return (user.status === 'ACTIVE' || user.status === 'INVITED') ? true : false;
                                    }).sort((a: any, b: any) => {
                                        return a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
                                    }),
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            { employees: { arr: [], status: STATUS.ERROR } }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        return <List employees={this.state.employees} office={this.props.office} onRefresh={() => { this.search(); }} />
    }
}