import * as $ from 'jquery';
import { doFetchSubsidiariesFromServer } from '../../Actions/Caches';
import Component from '../../Component';
import A from '../../Components/A';
import Anchor from '../../Components/Anchor';
import ATooltip from '../../Components/ATooltip';
import Confirm from '../../Components/Confirm';
import { SUBSIDIARYTABS } from '../../General';
import { copyArray } from '../../Utils';

export default class Item extends Component {
    static ITEMS = 5;

    render() {
        const t = this.translate();
        var subsidiary = this.props.subsidiary;
        let trs: any = [];
        if (subsidiary.members && subsidiary.members.length > 0) {
            trs = ((subsidiary.members.length > Item.ITEMS) ?
                copyArray(subsidiary.members, 0, Item.ITEMS - 1) : subsidiary.members).map((employee: any) => {
                    if (employee.status === 'DISABLED') {
                        return null;
                    }
                    return <tr key={'OfficeItemTr-' + employee.id}>
                        <td>
                            <A className="text-dark font-weight-light"
                                href={this.Urls().getEmployee(employee.id)}
                                title={employee.fullName}>
                                {employee.fullName}
                            </A>
                        </td>
                    </tr>
                });
        }
        return (
            <div className="card w-100 h-100" >
                <div className="card-body">
                    <table className="table table-sm w-100 m-0 p-0">
                        <thead>
                            <tr>
                                <th className="border-top-0">
                                    <A faIcon="fas fa-city" href={this.Urls().getSubsidiary(subsidiary.id, SUBSIDIARYTABS.MEMBERS)} title={subsidiary.name}>
                                        {subsidiary.name}
                                    </A>
                                    <Anchor
                                        className="float-right font-weight-light text-secondary"
                                        title={subsidiary.members.length + ' ' +
                                            (subsidiary.members.length === 1 ? t('member') : t('members'))}
                                    >
                                        {subsidiary.members.length}
                                    </Anchor>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {subsidiary?.officeManager?.id ?
                                <tr>
                                    <td className="border-top-0">
                                        <A
                                            className="text-dark"
                                            faIcon="fas fa-user-circle"
                                            href={this.Urls().getEmployee(subsidiary.officeManager.id)}
                                            title={t('manager') + ': ' + subsidiary.officeManager.fullName}
                                        >
                                            {subsidiary.officeManager.fullName}
                                        </A>
                                        <ATooltip tooltip={t('manager')} faIcon="fa fa-star" />
                                    </td>
                                </tr>
                                : null
                            }
                            {trs}
                            {(subsidiary.members.length > Item.ITEMS) ?
                                <tr>
                                    <td>
                                        <div className="float-right">
                                            <A
                                                className="text-muted"
                                                href={this.Urls().getOffice(subsidiary.id) + '#MEMBERS'}
                                                title={subsidiary.name}
                                            >
                                                {t('more')}
                                            </A>
                                        </div>
                                    </td>
                                </tr>
                                : undefined}
                            {(subsidiary.members.length === 0) ?
                                <tr>
                                    <td>
                                        <div className="float-right">
                                            <Confirm
                                                title={t('delete.subsidiary')}
                                                className="text-danger"
                                                faIcon="fas fa-trash-alt"
                                                onConfirm={() => {
                                                    $.ajax({
                                                        type: 'DELETE',
                                                        contentType: 'application/json',
                                                        url: this.Endpoints().deleteSubsidiary(subsidiary.id),
                                                        dataType: 'json',
                                                        cache: false,
                                                        success: (data: any, textStatus: any, jqXHR: any) => {
                                                            this.successToastr(t('the.subsidiary.was.deleted'));
                                                            doFetchSubsidiariesFromServer(this.getCaches());
                                                            if (this.props.onRefresh) {
                                                                this.props.onRefresh();
                                                            }
                                                        },
                                                        error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                                                            this.ajaxError(jqXHR, textStatus, errorThrown);
                                                        }
                                                    });
                                                }}
                                            >
                                                {t('delete.subsidiary')}
                                            </Confirm>
                                        </div>
                                    </td>
                                </tr>
                                : null}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}