import * as React from 'react';
import Component from '../../Component';
import A from '../../Components/A';
import Anchor from '../../Components/Anchor';
import Confirm from '../../Components/Confirm';
import { copyArray } from '../../Utils';
import * as $ from 'jquery';
import { doFetchUnitsFromServer } from '../../Actions/Caches';
import ATooltip from '../../Components/ATooltip';

export default class Item extends Component {
    static ITEMS = 5;

    render() {
        const t = this.translate();
        var unit = this.props.unit;
        let trs: any = [];
        if (unit.members && unit.members.length > 0) {
            trs = ((unit.members.length > Item.ITEMS) ?
                copyArray(unit.members, 0, Item.ITEMS - 1) : unit.members).map((employee: any) => {
                    return (
                        <tr key={'UnitItemTr-' + employee.id}>
                            <td>
                                <A
                                    className="text-dark font-weight-light"
                                    href={this.Urls().getEmployee(employee.id)}
                                    title={employee.fullName}
                                >
                                    {employee.fullName}
                                </A>
                            </td>
                        </tr>
                    );
                });
        }
        return (
            <div className="card w-100 h-100">
                <div className="card-body">
                    <table className="table table-sm w-100 m-0 p-0">
                        <thead>
                            <tr>
                                <th className="border-top-0">
                                    <A
                                        faIcon="fas fa-users"
                                        href={this.Urls().getUnit(unit.id)}
                                        title={unit.name}
                                    >
                                        {unit.name}
                                    </A>
                                    <Anchor
                                        className="float-right font-weight-light text-secondary card-link"
                                        title={unit.members.length + ' ' +
                                            (unit.members.length === 1 ? t('member') : t('members'))}
                                    >
                                        {unit.members.length}
                                    </Anchor>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr><td>
                                <A
                                    className="text-dark"
                                    faIcon="fas fa-user-circle"
                                    href={this.Urls().getEmployee(unit.manager.id)}
                                    title={t('manager') + ': ' + unit.manager.fullName}
                                >
                                    {unit.manager.fullName}
                                </A>
                                <ATooltip tooltip={t('team.manager')} faIcon="fa fa-star" />
                            </td></tr>
                            {trs}
                            {
                                (unit.members.length > Item.ITEMS) ?
                                    (
                                        <tr>
                                            <td>
                                                <div className="float-right">
                                                    <A
                                                        className="text-muted"
                                                        href={this.Urls().getUnit(unit.id)}
                                                        title={unit.name}
                                                    >
                                                        {t('more')}
                                                    </A>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                    :
                                    undefined
                            }
                            {
                                (unit.members.length === 0) ?
                                    (
                                        <tr>
                                            <td>
                                                <div className="float-right">
                                                    <Confirm
                                                        title={t('delete.team')}
                                                        className="text-danger"
                                                        faIcon="fas fa-trash-alt"
                                                        onConfirm={() => {
                                                            $.ajax({
                                                                type: 'DELETE',
                                                                contentType: 'application/json',
                                                                url: this.Endpoints().deleteUnit(unit.id),
                                                                dataType: 'json',
                                                                cache: false,
                                                                success: (data: any, textStatus: any, jqXHR: any) => {
                                                                    this.successToastr(t('the.team.was.deleted'));
                                                                    doFetchUnitsFromServer(this.getCaches());
                                                                    if (this.props.onRefresh) {
                                                                        this.props.onRefresh();
                                                                    }
                                                                },
                                                                error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                                                                    this.ajaxError(jqXHR, textStatus, errorThrown);
                                                                }
                                                            });
                                                        }}
                                                    >
                                                        {t('delete.team')}
                                                    </Confirm>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                    :
                                    null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}