import * as React from 'react';
import Component from '../Component';
import Anchor from '../Components/Anchor';
import { Popover, PopoverBody } from 'reactstrap';

export default class ChooseYear extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            popoverOpen: false
        };
    }

    toggle() {
        this.setState({ popoverOpen: !this.state.popoverOpen });
    }

    render() {
        return (
            <span>
                <Anchor
                    faIcon='far fa-calendar-check'
                    id={'ChooseYear-' + this.cid} title={this.props.title} onClick={() => { this.toggle(); }}>
                    {this.props.currentYear}
                </Anchor>
                <Popover
                    placement="bottom"
                    isOpen={this.state.popoverOpen}
                    target={'ChooseYear-' + this.cid}
                    toggle={() => { this.toggle(); }}
                >
                    <PopoverBody>
                        {
                            this.props.years.map((year: any) => {
                                return (
                                    <Anchor
                                        key={'ChooseYear-' + year}
                                        onClick={() => {
                                            this.toggle();
                                            if (this.props.onChangeYear) {
                                                this.props.onChangeYear(year);
                                            }
                                        }}
                                    >
                                        {year}
                                    </Anchor>
                                );
                            })
                        }
                    </PopoverBody>
                </Popover>
            </span>
        );
    }
}