import * as React from 'react';
import Component from '../../Component';
import 'moment';
import * as moment from 'moment-timezone';
import Form from './Form';
import { STATUS } from '../../General';
import * as $ from 'jquery';

export default class RegisterCompany extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            country: {
                value: 'US',
                status: STATUS.LOADING
            },
        };
    }

    componentDidMount() {
        this.getCountryForIp();
    }

    getCountryForIp() {
        $.ajax({
            type: 'GET',
            contentType: 'application/json',
            url: this.Endpoints().getCountryForIP(),
            dataType: 'json',
            cache: false,
            success: (data, textStatus, jqXHR) => {
                const country = data.country === 'ZZ' ? 'US' : data.country;
                this.setState({
                    country: {
                        value: country,
                        status: STATUS.READY
                    }
                });
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
                this.setState({
                    country: {
                        value: undefined,
                        status: STATUS.ERROR
                    },
                });
            },
        });
    }

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.countries.status === STATUS.READY &&
            caches.timezones.status === STATUS.READY &&
            this.state.country.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-6 ml-auto mr-auto mb-3">
                        <div className="card h-100">
                            <div className="card-body">
                                <Form defaultCountry={this.state.country.value} defaultTimezone={moment.tz.guess()} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}