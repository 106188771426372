import * as React from 'react';
import { leaveTypesBalance } from '../../../../Actions/Modal';
import Anchor from '../../../../Components/Anchor';
import ChooseYear from '../../../../Components/ChooseYear';
import Error from '../../../../Components/Error';
import { STATUS } from '../../../../General';
import { copyArray } from '../../../../Utils';
import Item from '../../../Dashboard/LeaveTypesBalance/Item';
import LeaveTypesBalanceTable from '../../../Dashboard/LeaveTypesBalance/Table';

export default class Table extends LeaveTypesBalanceTable {
    static ITEMS = 100;
    render() {
        const rows = this.props.rows;
        switch (rows.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                const rowsComps = ((rows.arr.length > Table.ITEMS && !this.props.showAll) ?
                    copyArray(rows.arr, 0, Table.ITEMS - 1) : rows.arr).map((row: any) => {
                        return (
                            <Item key={'DashBoardBalanceTable-' + row.leaveType.id} row={row} />
                        );
                    });
                return (
                    <table
                        className="table table-sm w-100 m-0 p-0"
                        style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}
                    >
                        <thead>
                            <tr>
                                <th className="border-top-0">
                                    <ChooseYear
                                        currentYear={this.props.currentYear}
                                        years={
                                            [
                                                this.props.currentYear - 1,
                                                this.props.currentYear,
                                                this.props.currentYear + 1
                                            ]
                                        }
                                        onChangeYear={(year: any) => {
                                            if (this.props.onChangeYear) {
                                                this.props.onChangeYear(year);
                                            }
                                        }}
                                    />
                                </th>
                                <th className="border-top-0 text-right">
                                    <span className="font-weight-normal d-md-none" style={{ fontSize: 'x-small' }}>{t('used')}</span>
                                    <span className="font-weight-normal d-none d-md-block">{t('used')}</span>
                                </th>
                                <th className="border-top-0 text-right">
                                    <span className="font-weight-normal d-md-none" style={{ fontSize: 'x-small' }}>{t('available')}</span>
                                    <span className="font-weight-normal d-none d-md-block">{t('available')}</span>
                                </th>
                                <th className="border-top-0 text-right">
                                    <span className="font-weight-normal d-md-none" style={{ fontSize: 'x-small' }}>{t('allowance')}</span>
                                    <span className="font-weight-normal d-none d-md-block">{t('allowance')}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowsComps}
                            {
                                (rows.arr.length > Table.ITEMS && !this.props.showAll) ?
                                    (
                                        <tr>
                                            <td colSpan={3}>
                                                <div className="float-right">
                                                    <Anchor
                                                        title={t('more')}
                                                        onClick={() => {
                                                            leaveTypesBalance(
                                                                this.getModal(),
                                                                { rows: rows }
                                                            );
                                                        }}
                                                    >
                                                        {t('more')}
                                                    </Anchor>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                    :
                                    null
                            }
                        </tbody>
                    </table>
                );
            }
            default:
                return (<Error />);
        }
    }
}