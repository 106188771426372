import * as React from 'react';
import Component from '../../../Component';
import { COMPANYUSERROLES, STATUS } from '../../../General';
import Manager from './Manager';

export default class Allowances extends Component {
    constructor(props?: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        this.state = {
            year: moment().year()
        };
    }

    render() {
        const session: any = this.getSession().getState();
        const isAdmin = () => {
            return session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN;
        };
        if (!isAdmin()) {
            const location = window.location + '';
            this.goToLogin({ target: location });
            return null;
        }
        const caches: any = this.getCaches().getState();
        let ready = caches.leaveTypes.status === STATUS.READY && caches.users.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (
            <Manager employee={this.props.employee} year={this.currentBusinessYear()} />
        );
    }
}