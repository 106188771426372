import * as React from 'react';
import Component from '../Component';
import {
    DropdownItem
} from 'reactstrap';
import * as Sifter from 'sifter';
import InputField from './InputField';
// import * as ReactDOM from 'react-dom';

export default class SelectFieldOptions extends Component {
    static optionKey(parentCid: any, option: any) {
        return 'SelectFieldOptions-' + parentCid + '-' + option.value;
    }

    constructor(props: any) {
        super(props);
        this.state = {
            sifter: new (Sifter as any)(this.props.options),
            search: {
                cid: Component.cid(),
                touched: false,
            },
            searchOptions: this.props.options
        };
    }

    render() {
        const options = (this.state.searchOptions && this.state.searchOptions.length > 0) ?
            (this.state.searchOptions.map((option: any) => {
                let opts: any = {};
                if (option.disabled) {
                    opts.disabled = true;
                }
                if (this.props.selected && this.props.selected.length > 0 && this.props.selected[0] !== undefined) {
                    for (var value of this.props.selected) {
                        if (value === option.value) {
                            opts.active = true;
                            break;
                        }
                    }
                }
                return (
                    <DropdownItem
                        className="pl-2 mr-2 text-truncate"
                        key={SelectFieldOptions.optionKey(this.props.parentCid, option)}
                        onClick={(event: any) => {
                            if (!opts.disabled) {
                                if (this.props.onSelectItem) { this.props.onSelectItem(event, option); }
                            }
                        }}
                        {...opts}
                    >
                        {option.html ? option.html : option.text}
                    </DropdownItem>
                );
            })) : null;
        const t = this.translate();
        return (
            <div>
                {
                    (this.props.options && this.props.options.length < 10) ?
                        null
                        :
                        <div className="dropdown-item pl-2 mr-2">
                            <InputField
                                touched={this.state.search.touched}
                                rules={this.Rules().MaxLengthInputRules()}
                                focus={this.isScreenSizeSmall() ? false : true}
                                className="form-control w-100"
                                onChange={(value: any) => { return this.onSearch(value); }}
                                onKeyDown={(event: any) => { return this.onSearchKeyDown(event); }}
                                placeholder={t('search')}
                            />
                        </div>
                }
                <div style={{ maxHeight: this.isScreenSizeSmall() ? '10em' : '15em', overflowY: 'auto' }}>
                    {options}
                </div>
            </div>
        );
    }

    onSearch(value: any) {
        this.setState(
            {
                search: Object.assign({}, this.state.search, { value: value })
            },
            () => {
                if (!(this.state.search.value && this.state.search.value.length > 0)) {
                    this.setState({ searchOptions: this.props.options });
                    return;
                }
                var result = this.state.sifter.search(this.state.search.value, {
                    fields: ['text'],
                    sort: [{ field: 'text', direction: 'asc' }],
                    limit: 5
                });
                if (result && result.items && result.items.length > 0) {
                    let searchOptions = result.items.map((searchItem: any) => {
                        return this.props.options[searchItem.id];
                    });
                    this.setState({ searchOptions: searchOptions });
                    return;
                }
                const t = this.translate();
                this.setState({ searchOptions: [{ text: t('no.results.found'), value: 0, disabled: true }] });
            });
    }

    onSearchKeyDown(event: any) {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        }
        if (event.keyCode === 40) {
            if (this.state.searchOptions && this.state.searchOptions.length > 0) {
                // dsds
            }
        }
        return false;
    }

    shouldComponentUpdate(nextProps: any, nextState: any) {
        nextState = {
            sifter: new (Sifter as any)(nextProps.options),
            search: {
                cid: Component.cid(),
                touched: false,
            },
            searchOptions: nextProps.options
        };
        return true;
    }
}