import * as React from 'react';
import Component from '../../../Component';
import Date from '../../../Components/Date';
import Submit from '../../../Components/Submit';

export default class Form extends Component {
    render() {
        const t = this.translate();
        const moment = this.getMomentWithLocale();
        return (
            <form onSubmit={(event: any) => { this.submit(event); }}>
                <div className="row">
                    <div className="col-12 mb-3">
                        <p>{t('you.have.registered.over.10.employees')}</p>
                        <div>
                            {t('the.trial.period.ends.on')}
                            <div className="float-right text-primary">
                                <Date date={moment(this.props.trialEndsOn)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <Submit faIcon="fas fa-check">
                            {t('acknowledge')}
                        </Submit>
                    </div>
                </div>
            </form>
        );
    }

    submit(event: any) {
        event.preventDefault();
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
}