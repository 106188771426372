import * as React from 'react';
import Component from '../../../Component';
import SelectField from '../../../Components/SelectField';
import Submit from '../../../Components/Submit';
import ToggleField from '../../../Components/ToggleField';
import { hourAMPMOptionsFactory, hourOptionsFactory, minuteOptionsFactory } from '../../../SelectOptionsFactories';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            working: {
                value: this.props.weekDayAndHours.working,
            },
            firstStartHour: {
                value: [this.props.weekDayAndHours.first.start.hour],
                touched: false,
            },
            firstStartMinute: {
                value: [this.props.weekDayAndHours.first.start.minute],
                touched: false,
            },
            firstEndHour: {
                value: [this.props.weekDayAndHours.first.end.hour],
                touched: false,
            },
            firstEndMinute: {
                value: [this.props.weekDayAndHours.first.end.minute],
                touched: false,
            },
            secondStartHour: {
                value: [this.props.weekDayAndHours.second.start.hour],
                touched: false,
            },
            secondStartMinute: {
                value: [this.props.weekDayAndHours.second.start.minute],
                touched: false,
            },
            secondEndHour: {
                value: [this.props.weekDayAndHours.second.end.hour],
                touched: false,
            },
            secondEndMinute: {
                value: [this.props.weekDayAndHours.second.end.minute],
                touched: false,
            },
            update: {
                pleaseWait: false
            },
        };
    }

    render() {
        const t = this.translate();
        const session: any = this.getSession().getState();
        const firstStartHourOptions: any = session.companyUser.timeFormat === 'HH:mm' ? hourOptionsFactory() : hourAMPMOptionsFactory();
        const firstEndHourOptions: any = session.companyUser.timeFormat === 'HH:mm' ? hourOptionsFactory() : hourAMPMOptionsFactory();
        const secondStartHourOptions: any = session.companyUser.timeFormat === 'HH:mm' ? hourOptionsFactory() : hourAMPMOptionsFactory();
        const secondEndHourOptions: any = session.companyUser.timeFormat === 'HH:mm' ? hourOptionsFactory() : hourAMPMOptionsFactory();
        const minuteOptions: any = minuteOptionsFactory();
        return <form onSubmit={(event: any) => { this.submit(event); }}>
            <div className="row">
                <div className="col-12 mb-3">
                    <ToggleField
                        defaultValue={this.state.working.value}
                        onChange={(value: any) => {
                            this.setState({
                                working: Object.assign({}, this.state.working, { value: value })
                            });
                        }}
                    >
                        <label>{t('working.day')}</label>
                    </ToggleField>
                </div>
                <div className={this.state.working.value ? 'col-12 mb-3' : 'd-none'}>
                    <label>{t('first.part.of.the.day.capitalize')}</label>
                </div>
                <div className={this.state.working.value ? 'col-12 col-sm-2 mb-3' : 'd-none'}>
                    <label>{t('from')}</label>
                </div>
                <div className={this.state.working.value ? 'col-12 col-sm-5 mb-3' : 'd-none'}>
                    <SelectField
                        defaultValue={this.state.firstStartHour.value}
                        touched={this.state.firstStartHour.touched}
                        options={firstStartHourOptions}
                        multiple={false}
                        rules={this.state.firstStartHour.rules}
                        placeholder={t('hour')}
                        onChange={(value: any) => { this.setState({ firstStartHour: Object.assign({}, this.state.firstStartHour, { value: value }) }); }}
                    />
                </div>
                <div className={this.state.working.value ? 'col-12 col-sm-5 mb-3' : 'd-none'}>
                    <SelectField
                        defaultValue={this.state.firstStartMinute.value}
                        touched={this.state.firstStartMinute.touched}
                        options={minuteOptions}
                        multiple={false}
                        rules={this.state.firstStartMinute.rules}
                        placeholder={t('minute')}
                        onChange={(value: any) => { this.setState({ firstStartMinute: Object.assign({}, this.state.firstStartMinute, { value: value }) }); }}
                    />
                </div>
                <div className={this.state.working.value ? 'col-12 col-sm-2 mb-3' : 'd-none'}>
                    <label>{t('to')}</label>
                </div>
                <div className={this.state.working.value ? 'col-12 col-sm-5 mb-3' : 'd-none'}>
                    <SelectField
                        defaultValue={this.state.firstEndHour.value}
                        touched={this.state.firstEndHour.touched}
                        options={firstEndHourOptions}
                        multiple={false}
                        rules={this.state.firstEndHour.rules}
                        placeholder={t('hour')}
                        onChange={(value: any) => { this.setState({ firstEndHour: Object.assign({}, this.state.firstEndHour, { value: value }) }); }}
                    />
                </div>
                <div className={this.state.working.value ? 'col-12 col-sm-5 mb-3' : 'd-none'}>
                    <SelectField
                        defaultValue={this.state.firstEndMinute.value}
                        touched={this.state.firstEndMinute.touched}
                        options={minuteOptions}
                        multiple={false}
                        rules={this.state.firstEndMinute.rules}
                        placeholder={t('minute')}
                        onChange={(value: any) => { this.setState({ firstEndMinute: Object.assign({}, this.state.firstEndMinute, { value: value }) }); }}
                    />
                </div>
                <div className={this.state.working.value ? 'col-12 mb-3' : 'd-none'}>
                    <label>{t('second.part.of.the.day.capitalize')}</label>
                </div>
                <div className={this.state.working.value ? 'col-12 col-sm-2 mb-3' : 'd-none'}>
                    <label>{t('from')}</label>
                </div>
                <div className={this.state.working.value ? 'col-12 col-sm-5 mb-3' : 'd-none'}>
                    <SelectField
                        defaultValue={this.state.secondStartHour.value}
                        touched={this.state.secondStartHour.touched}
                        options={secondStartHourOptions}
                        multiple={false}
                        rules={this.state.secondStartHour.rules}
                        placeholder={t('hour')}
                        // tslint:disable-next-line:max-line-length
                        onChange={(value: any) => { this.setState({ secondStartHour: Object.assign({}, this.state.secondStartHour, { value: value }) }); }}
                    />
                </div>
                <div className={this.state.working.value ? 'col-12 col-sm-5 mb-3' : 'd-none'}>
                    <SelectField
                        defaultValue={this.state.secondStartMinute.value}
                        touched={this.state.secondStartMinute.touched}
                        options={minuteOptions}
                        multiple={false}
                        rules={this.state.secondStartMinute.rules}
                        placeholder={t('minute')}
                        // tslint:disable-next-line:max-line-length
                        onChange={(value: any) => { this.setState({ secondStartMinute: Object.assign({}, this.state.firstStartMinute, { value: value }) }); }}
                    />
                </div>
                <div className={this.state.working.value ? 'col-12 col-sm-2 mb-3' : 'd-none'}>
                    <label>{t('to')}</label>
                </div>
                <div className={this.state.working.value ? 'col-12 col-sm-5 mb-3' : 'd-none'}>
                    <SelectField
                        defaultValue={this.state.secondEndHour.value}
                        touched={this.state.secondEndHour.touched}
                        options={secondEndHourOptions}
                        multiple={false}
                        rules={this.state.secondEndHour.rules}
                        placeholder={t('hour')}
                        // tslint:disable-next-line:max-line-length
                        onChange={(value: any) => { this.setState({ secondEndHour: Object.assign({}, this.state.secondEndHour, { value: value }) }); }}
                    />
                </div>
                <div className={this.state.working.value ? 'col-12 col-sm-5 mb-3' : 'd-none'}>
                    <SelectField
                        defaultValue={this.state.secondEndMinute.value}
                        touched={this.state.secondEndMinute.touched}
                        options={minuteOptions}
                        multiple={false}
                        rules={this.state.secondEndMinute.rules}
                        placeholder={t('minute')}
                        // tslint:disable-next-line:max-line-length
                        onChange={(value: any) => { this.setState({ secondEndMinute: Object.assign({}, this.state.secondEndMinute, { value: value }) }); }}
                    />
                </div>
                <div className="col-12 mb-3">
                    <Submit
                        faIcon="far fa-hdd"
                        disabled={!this.isFormValid()}
                        pleaseWait={this.state.update.pleaseWait}
                    >
                        {t('update')}
                    </Submit>
                </div>
            </div>
        </form>
    }

    isFormValid() {
        return true;
    }

    submit(event: any) {
        event.preventDefault();
        let weekDayAndHours = Object.assign({}, this.props.weekDayAndHours);
        weekDayAndHours.working = this.state.working.value;
        weekDayAndHours.first.start.hour = this.state.firstStartHour.value[0];
        weekDayAndHours.first.start.minute = this.state.firstStartMinute.value[0];
        weekDayAndHours.first.end.hour = this.state.firstEndHour.value[0];
        weekDayAndHours.first.end.minute = this.state.firstEndMinute.value[0];
        weekDayAndHours.second.start.hour = this.state.secondStartHour.value[0];
        weekDayAndHours.second.start.minute = this.state.secondStartMinute.value[0];
        weekDayAndHours.second.end.hour = this.state.secondEndHour.value[0];
        weekDayAndHours.second.end.minute = this.state.secondEndMinute.value[0];
        if (this.props.onClose) {
            this.props.onClose({ weekDayAndHours: weekDayAndHours });
        }
    }
}