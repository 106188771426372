import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import A from '../../../Components/A';
import Cog from '../../../Components/Cog';
import Confirm from '../../../Components/Confirm';
import InputICal from '../../../Components/InputICal';
import TableLine from '../../../Components/TableLine';

export default class Display extends Component {

    constructor(props: any) {
        super(props);
        this.state = {
            iCalendarUrlForUnit: {
                value: this.props.unit.iCalUrl,
            },
            iCalendarUrlForUnitv2: {
                value: this.props.unit.iCalUrlv2,
            },
            iCalendarUrlForBirthdays: {
                value: this.props.unit.iCalendarUrlForBirthdays,
            },
            iCalendarUrlForWorkAnniversaries: {
                value: this.props.unit.iCalendarUrlForWorkAnniversaries,
            },
            copyICalendarUrlForUnit: false,
            copyICalendarUrlForUnitv2: false,
            copyiCalendarUrlForBirthdays: false,
            copyICalendarUrlForOfficeyWorkAnniversaries: false,
        };
    }

    render() {
        const t = this.translate();
        const unit = this.props.unit;
        const managerCompanyUser = unit.managerCompanyUser;
        return (
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <Cog onClick={() => { if (this.props.onEdit) { this.props.onEdit(); } }} />
                        </div>
                        <div className="col-12">
                            {t('manager')}
                            <span className="float-right">
                                {managerCompanyUser.fullName}
                            </span>
                        </div>
                        {
                            (unit.description) ?
                                (<div className="col-12">
                                    {t('description')}
                                    <pre className="font-weight-light m-0">{unit.description}</pre>
                                </div>)
                                :
                                null
                        }
                        <div className="col-12">
                            <TableLine />
                        </div>
                        <div className="col-12">
                            {t('icalendar.url')}
                            <div className="float-right">
                                <Confirm
                                    title={t('regenerate.icalendar.url')}
                                    faIcon="fas fa-sync"
                                    onConfirm={() => {
                                        $.ajax({
                                            type: 'POST',
                                            contentType: 'application/json',
                                            url: this.Endpoints().getUnitiCalRegenerate(unit.id),
                                            dataType: 'json',
                                            cache: false,
                                            success: (data: any, textStatus: any, jqXHR: any) => {
                                                this.successToastr(t('the.icalendar.url.was.regenerated'));
                                                if (this.props.onRefresh) {
                                                    this.props.onRefresh();
                                                }
                                            },
                                            error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                                                this.ajaxError(jqXHR, textStatus, errorThrown);
                                            }
                                        });
                                    }}
                                />
                            </div>
                            <div>
                                <div className="mt-3">
                                    <span className="float-right">
                                        <A
                                            title={t('team.iCalendar.url')}
                                            faIcon="far fa-copy"
                                            href={this.state.iCalendarUrlForUnit.value}
                                            onClick={() => { this.setState({ copyICalendarUrlForUnit: true }, () => { this.setState({ copyICalendarUrlForUnit: false }); }); }}
                                        />
                                    </span>
                                    V1 : <a href={this.state.iCalendarUrlForUnit.value} target="_blank" className="card-link" rel="noopener noreferrer">
                                        {this.state.iCalendarUrlForUnit.value}
                                    </a>
                                    <InputICal value={this.state.iCalendarUrlForUnit.value} copy={this.state.copyICalendarUrlForUnit} />
                                </div>
                                <div className="mt-3">
                                    <span className="float-right">
                                        <A
                                            title={t('team.iCalendar.url') + ' V2'}
                                            faIcon="far fa-copy"
                                            href={this.state.iCalendarUrlForUnitv2.value}
                                            onClick={() => { this.setState({ copyICalendarUrlForUnitv2: true }, () => { this.setState({ copyICalendarUrlForUnitv2: false }); }); }}
                                        />
                                    </span>
                                    V2 : <a href={this.state.iCalendarUrlForUnitv2.value} target="_blank" className="card-link" rel="noopener noreferrer">
                                        {this.state.iCalendarUrlForUnitv2.value}
                                    </a>
                                    <InputICal key={222 + 'ddd'} value={this.state.iCalendarUrlForUnitv2.value} copy={this.state.copyICalendarUrlForUnitv2} />
                                </div>


                                <div className="mt-3">
                                    <span className="float-right">
                                        <A
                                            title={t('team.birthdays.iCalendar.url')}
                                            faIcon="far fa-copy"
                                            href={this.state.iCalendarUrlForBirthdays.value}
                                            onClick={() => { this.setState({ copyiCalendarUrlForBirthdays: true }, () => { this.setState({ copyiCalendarUrlForBirthdays: false }); }); }}
                                        />
                                    </span>
                                    <div><h6>{t('team.birthdays.iCalendar.url')}</h6></div><a href={this.state.iCalendarUrlForBirthdays.value} className="card-link" target="_blank" title={t('team.birthdays.iCalendar.url')} rel="noopener noreferrer" >
                                        {this.state.iCalendarUrlForBirthdays.value}
                                    </a>
                                    <InputICal value={this.state.iCalendarUrlForBirthdays.value} copy={this.state.copyiCalendarUrlForBirthdays} />
                                </div>

                                <div className="mt-3">
                                    <span className="float-right">
                                        <A
                                            title={t('team.work.anniversaries.iCalendar.url')}
                                            faIcon="far fa-copy"
                                            href={this.state.iCalendarUrlForWorkAnniversaries.value}
                                            onClick={() => { this.setState({ copyiCalendarUrlForWorkAnniversaries: true }, () => { this.setState({ copyiCalendarUrlForWorkAnniversaries: false }); }); }}
                                        />
                                    </span>
                                    <div><h6>{t('team.work.anniversaries.iCalendar.url')}</h6></div><a href={this.state.iCalendarUrlForWorkAnniversaries.value} className="card-link" target="_blank" title={t('team.birthdays.iCalendar.url')} rel="noopener noreferrer" >
                                        {this.state.iCalendarUrlForWorkAnniversaries.value}
                                    </a>
                                    <InputICal value={this.state.iCalendarUrlForWorkAnniversaries.value} copy={this.state.copyiCalendarUrlForWorkAnniversaries} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}