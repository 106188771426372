import * as $ from 'jquery';
import { Moment } from 'moment-timezone';

export const getURLParameter: any = (name: string, url?: string): any => {
    if (!url) { url = window.location.href; }
    /*eslint-disable no-useless-escape */
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) { return undefined; }
    if (!results[2]) { return ''; }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export class Cookie {
    static REDIRECT_TARGET = 'redirect-target';
    static EMPLOYEES_VIEWMODE = 'EMPLOYEES_VIEWMODE';
    static EMPLOYEES_UPCOMNING_BIRTHDAYS = 'EMPLOYEES_UPCOMNING_BIRTHDAYS';
    static EMPLOYEES_YEARS_OF_SERVICE_VIEWMODE = 'EMPLOYEES_YEARS_OF_SERVICE_VIEWMODE';
    static EMPLOYEES_AGE_VIEWMODE = 'EMPLOYEES_AGE_VIEWMODE';
    static LEAVES_TO_APPROVE_VIEWMODE = 'LEAVES_TO_APPROVE_VIEWMODE';
    static MY_LEAVES_VIEWMODE = 'MY_LEAVES_VIEWMODE';
    static OAUTH_CODE = 'OAUTH_CODE';

    static create(name?: any, value?: any, days?: any) {
        var expires = '';
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + value + expires + '; path=/'
            + ((name === Cookie.MY_LEAVES_VIEWMODE) ? 'nox/myleaves.html' : '')
            + ((name === Cookie.LEAVES_TO_APPROVE_VIEWMODE) ? 'nox/leavestoapprove.html' : '')
            + ((name === Cookie.EMPLOYEES_VIEWMODE) ? 'nox/employees.html' : '') + "; SameSite=None; Secure";
    }

    static read(name: any) {
        var nameEQ = name + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') { c = c.substring(1, c.length); }
            if (c.indexOf(nameEQ) === 0) { return c.substring(nameEQ.length, c.length); }
        }
        return null;
    }

    static erase(name: any) {
        Cookie.create(name, '', -1);
    }
}

export const moveInArray = (arr: any, from: any, to: any) => {
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
};

const ISO8601_FORMAT = 'YYYY-MM-DD';
export const formatForServer = (moment: Moment) => {
    return moment.format(ISO8601_FORMAT);
};

export const findDayEventName = (dayEvent: any, lang: string) => {
    return findLocaleName(dayEvent.names, lang);
};

/*eslint-disable no-lone-blocks */
export const findLocaleName = (names: any, lang: string) => {
    if (!names || names.length === 0) {
        return '';
    }
    {
        for (var i = 0; i < names.length; i++) {
            if ((lang === names[i].lang) &&
                (names[i].value && names[i].value.length > 0)) {
                return names[i].value;
            }
        }
    }
    {
        for (var ii = 0; ii < names.length; ii++) {
            if ((('en' === names[ii].lang) || ('en-gb' === names[ii].lang)) &&
                (names[ii].value && names[ii].value.length > 0 && names[ii].value.trim().length > 0)) {
                if (names[ii].value) {
                    return names[ii].value;
                }
            }
        }
    }
    {
        for (var j = 0; j < names.length; j++) {
            if (names[j] && names[j].value && names[j].value.length > 0 && names[j].value.trim().length > 0) {
                return names[j].value;
            }
        }
    }
    return '';
};

export const copyArray = (arr: any[], start: number, end: number) => {
    const retarr = arr.filter((item: any, index: any) => {
        if ((index >= start) && (index <= end)) {
            return true;
        }
        return false;
    });
    return retarr;
};

export const createParamsFromFilters = (url: string, filters: any) => {
    if (url.indexOf('?') < 0) {
        url = url + '?';
    }
    let paramAdded = (url.indexOf('&') < 0);
    if (filters.search && filters.search.value && filters.search.value.length > 0) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'q=' + filters.search.value;
        paramAdded = true;
    }
    if (filters.startDate && filters.startDate.value) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'sd=' + formatForServer(filters.startDate.value);
        paramAdded = true;
    }
    if (filters.endDate && filters.endDate.value) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'ed=' + formatForServer(filters.endDate.value);
        paramAdded = true;
    }
    if (filters?.users?.value?.length) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'users=' + filters.users.value;
        paramAdded = true;
    }
    if (filters?.approvers?.value?.length) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'approvers=' + filters.approvers.value;
        paramAdded = true;
    }
    if (filters?.employeeStatuses?.value?.length) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'statuses=' + filters.employeeStatuses.value;
        paramAdded = true;
    }
    if (filters?.leaveTypes?.value?.length) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'leavetypes=' + filters.leaveTypes.value;
        paramAdded = true;
    }
    if (filters?.leaveStatuses?.value?.length) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'leavestatuses=' + filters.leaveStatuses.value;
        paramAdded = true;
    }
    if (filters?.units?.value?.length) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'units=' + filters.units.value;
        paramAdded = true;
    }
    if (filters?.years?.value?.length) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'years=' + filters.years.value;
        paramAdded = true;
    }
    if (filters?.offices?.value?.length) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'offices=' + filters.offices.value;
        paramAdded = true;
    }
    if (filters?.subsidiaries?.value?.length) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'subsidiaries=' + filters.subsidiaries.value;
        paramAdded = true;
    }
    if (filters.cuid && filters.cuid.value) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'cuid=' + filters.cuid.value;
        paramAdded = true;
    }
    if (filters.countries && filters.countries.value) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'countries=' + filters.countries.value;
        paramAdded = true;
    }
    if (filters.onDateEnabled && filters.onDateEnabled.value && filters.toDate && filters.toDate.value) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'toDate=' + formatForServer(filters.toDate.value);
        paramAdded = true;
    }
    if (filters?.nwhoel?.value?.length) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'nwhoel=' + filters.nwhoel.value;
        paramAdded = true;
    }
    if (filters?.limit) {
        if (paramAdded === true) {
            url = url + '&';
        }
        url = url + 'limit=' + filters.limit;
        paramAdded = true;
    }
    if (filters.orderBy) {
        if (filters.orderBy.field) {
            if (paramAdded === true) {
                url = url + '&';
            }
            url = url + 'orderfield=' + filters.orderBy.field;
            paramAdded = true;
        }
        if (filters.orderBy.order) {
            if (paramAdded === true) {
                url = url + '&';
            }
            url = url + 'order=' + filters.orderBy.order;
            paramAdded = true;
        }
    }
    return url;
};

export const random = (start: number, end: number) => {
    return Math.floor(Math.random() * end) + start;
};

export const getAnchor = () => {
    return (($ as any)(window.location).attr('hash') as any).substr(1);
};

export const removeUrlParameter = (url: any, parameter: any) => {
    var urlParts = url.split('?');

    if (urlParts.length >= 2) {
        // Get first part, and remove from array
        var urlBase = urlParts.shift();

        // Join it back up
        var queryString = urlParts.join('?');

        var prefix = encodeURIComponent(parameter) + '=';
        var parts = queryString.split(/[&;]/g);

        // Reverse iteration as may be destructive
        for (var i = parts.length; i-- > 0;) {
            // Idiom for string.startsWith
            if (parts[i].lastIndexOf(prefix, 0) !== -1) {
                parts.splice(i, 1);
            }
        }

        url = urlBase + '?' + parts.join('&');
    }
    return url;
};


export class Jqxhrs {
    private jqxhrs = new Map();

    abort(name: any) {
        if (this.jqxhrs.has(name)) { this.jqxhrs.get(name).abort(); this.jqxhrs.delete(name); }// LoadingBar.loaded(name); };
    }

    abortAndSet(name: any, jqxhr: any) {
        this.abort(name); this.set(name, jqxhr);
    }

    complete(name: any) {
        if (this.jqxhrs.has(name))
            this.jqxhrs.delete(name);
    }

    set(name: any, jqxhr: any) {
        this.jqxhrs.set(name, jqxhr);
        //        LoadingBar.load(name);
    }

    abortAll() {
        for (let key of (this.jqxhrs.keys() as any)) {
            this.abort(key);
        }
    }
}


const handleError = (jqXHR: any, textStatus: any, errorThrown: any, callback?: any) => {
    if (textStatus === 'abort') {
        return;
    }
    if (jqXHR.status === 401) {
        //        toast.error(t('please.login'), { position: toast.POSITION.TOP_RIGHT });
        //        router.goTo(urls.Login({ target: window.location.href }));
        return;
    }
    if (jqXHR.status === 404) {
        //        toast.error(t('resource.not.found'), { position: toast.POSITION.TOP_RIGHT });
    }
    if (jqXHR.status === 403) {
        //        toast.error(t('you.are.not.allowed.to.perform.this.action'), { position: toast.POSITION.TOP_RIGHT });
    }
    if (jqXHR.status === 412) {
        //        toast.error(t('you.are.not.allowed.to.perform.this.action'), { position: toast.POSITION.TOP_RIGHT });
    }
    //    toast.error(t('an.error.has.occured'), {
    //        position: toast.POSITION.TOP_RIGHT
    //});
    if (callback) callback();
}

export const handleJQXHRError = handleError;

