import * as React from 'react';
import { COMPANYUSERROLES, LEAVESTATUS, NOTIFICATIONRULEEVENTCLAUSE, NOTIFICATIONRULETYPE, NOTIFICATIONRULETYPESCHEDULERCONTENT, NOTIFICATIONRULETYPESCHEDULERWHEN, REQUESTAPPROVETYPE, REQUESTDAYTYPE, USERSTATUS } from './General';
import i18n from './Reducers/I18n';
import { findLocaleName } from './Utils';

const getMomentWithLocale = () => {
    return (i18n.getState() as any).moment;
};

export const unitsOptionsFactory = (units: any) => {
    return units.map((unit: any) => {
        return { value: unit.id, text: unit.name };
    });
};

export const usersOptionsFactory = (users: any) => {
    return users.filter((user: any) => {
        return user.status !== USERSTATUS.DISABLED;
    }).map((user: any) => {
        return { value: user.id, text: user.fullName };
    }).sort((a: any, b: any) => {
        return a.text.toLowerCase().localeCompare(b.text.toLowerCase(), 'en', { sensitivity: 'base' });
    });
};

export const leaveTypesOptionsFactory = (leaveTypes: any, language: any) => {
    return leaveTypes.filter((leaveType: any) => {
        return leaveType.active;
    }).map((leaveType: any) => {
        const name = findLocaleName(leaveType.names, language);
        return {
            value: leaveType.id,
            text: name,
        };
    });
};

export const leaveStatusesOptionsFactory = (t: any) => {
    return [
        {
            value: LEAVESTATUS.AWAITING_APPROVAL,
            text: t('LEAVESTATUS.AWAITING_APPROVAL')
        },
        {
            value: LEAVESTATUS.APPROVED,
            text: t('LEAVESTATUS.APPROVED')
        },
        {
            value: LEAVESTATUS.REJECTED,
            text: t('LEAVESTATUS.REJECTED')
        },
        {
            value: LEAVESTATUS.CANCELED,
            text: t('LEAVESTATUS.CANCELED')
        },
    ];
};

export const notificationRuleEventClausesOptionsFactory = (t: any) => {
    return [
        {
            value: NOTIFICATIONRULEEVENTCLAUSE.REQUEST,
            text: t('NOTIFICATIONRULEEVENTCLAUSE.REQUEST')
        },
        {
            value: NOTIFICATIONRULEEVENTCLAUSE.REQUEST_CANCELED,
            text: t('NOTIFICATIONRULEEVENTCLAUSE.REQUEST_CANCELED')
        },
        {
            value: NOTIFICATIONRULEEVENTCLAUSE.ACCEPT_DECISION,
            text: t('NOTIFICATIONRULEEVENTCLAUSE.ACCEPT_DECISION')
        },
        {
            value: NOTIFICATIONRULEEVENTCLAUSE.REJECT_DECISION,
            text: t('NOTIFICATIONRULEEVENTCLAUSE.REJECT_DECISION')
        },
    ];
};

export const notificationRuleTypesOptionsFactory = (t: any) => {
    return [
        {
            value: NOTIFICATIONRULETYPE.EVENT_BASED,
            text: t('NOTIFICATIONRULETYPE.EVENT_BASED')
        },
        /*
        {
            value: NOTIFICATIONRULETYPE.SCHEDULER_BASED,
            text: t('NOTIFICATIONRULETYPE.SCHEDULER_BASED')
        },
        */
    ];
};

export const notificationRuleTypeSchedulerWhenOptionsFactory = (t: any) => {
    return [
        {
            value: NOTIFICATIONRULETYPESCHEDULERWHEN.MONDAY,
            text: t('NOTIFICATIONRULETYPESCHEDULERWHEN.MONDAY')
        },
        {
            value: NOTIFICATIONRULETYPESCHEDULERWHEN.SUNDAY,
            text: t('NOTIFICATIONRULETYPESCHEDULERWHEN.SUNDAY')
        },
        {
            value: NOTIFICATIONRULETYPESCHEDULERWHEN.FIRST_DAY_OF_THE_MONTH,
            text: t('NOTIFICATIONRULETYPESCHEDULERWHEN.FIRST_DAY_OF_THE_MONTH')
        },
    ];
};

export const notificationRuleTypeSchedulerContentOptionsFactory = (t: any) => {
    return [
        {
            value: NOTIFICATIONRULETYPESCHEDULERCONTENT.FULL_DIGEST,
            text: t('NOTIFICATIONRULETYPESCHEDULERCONTENT.FULL_DIGEST')
        },
    ];
};

export const countriesOptionsFactory = (countries: any) => {
    return countries.map((country: any) => {
        return {
            value: country.id,
            text: country.text
        };
    });
};

export const timezonesOptionsFactory = (timezones: any) => {
    return timezones.map((timezone: any) => {
        return { value: timezone, text: timezone };
    });
};

export const allowanceOptionsFactory = (t: any, step: any) => {
    let options: any[] = [];
    options.push({
        value: 0,
        text: 0 + ' ' + t('days.per.year')
    });
    options.push({
        value: 1,
        text: t('one.day.year')
    });
    for (let i = (step === 1 ? step : 1.5); i < 366; i = i + step) {
        options.push({
            value: i,
            text: i + ' ' + t('days.per.year')
        });
    }
    return options;
};


export const hoursPerDayOptionsFactory = (t: any, step: any) => {
    let options: any[] = [];
    options.push({
        value: 1,
        text: t('one.hour')
    });
    for (let i = (step === 1 ? step : 1.5); i < 12; i = i + step) {
        if (i === 1) continue;
        options.push({
            value: i,
            text: i + ' ' + t('hours')
        });
    }
    return options;
};


export const hoursOptionsFactory = (t: any) => {
    let options: any[] = [];
    options.push({
        value: 1,
        text: t('one.hour')
    });
    for (let i = 2; i < 13; i = i + 1) {
        options.push({
            value: i,
            text: i + ' ' + t('hours')
        });
    }
    return options;
};


export const effectiveAfterFactory = (t: any) => {
    let options: any[] = [];
    options.push({
        value: 0,
        text: 0 + ' ' + t('days.of.service')
    });
    options.push({
        value: 1,
        text: t('one.day.of.service')
    });
    for (let i = 2; i < 366; i = i + 1) {
        options.push({
            value: i,
            text: i + ' ' + t('days.of.service')
        });
    }
    return options;
};

export const earlierOptionsFactory = (t: any, step: any) => {
    let options: any[] = [];
    options.push({
        value: 0,
        text: 0 + ' ' + t('days').toLowerCase()
    });
    options.push({
        value: 1,
        text: t('one.day')
    });
    for (let i = (step === 1 ? step : 1.5); i < 60; i = i + step) {
        options.push({
            value: i,
            text: i + ' ' + t('days').toLowerCase()
        });
    }
    for (let i = -60; i < 0; i = i + step) {
        options.push({
            value: i,
            text: i + ' ' + t('days').toLowerCase()
        });
    }
    return options;
};


export const maximumTransferableDaysOptionsFactory = (t: any, step: any) => {
    let options: any[] = [];
    options.push({
        value: 0,
        text: 0 + ' ' + t('days')
    });
    options.push({
        value: 1,
        text: t('one.day')
    });
    for (let i = (step === 1 ? step : 1.5); i < 366; i = i + step) {
        options.push({
            value: i,
            text: i + ' ' + t('days')
        });
    }
    return options;
};
export const allowanceAdjustmentOptionsFactory = (t: any, from: number, to: number) => {
    let options: any[] = [];
    for (let i = from; i < to; i = i + 0.5) {
        if (i === 1) {
            options.push({
                value: 1,
                text: t('one.day.year')
            });
            continue;
        }
        if (i === 0) {
            options.push({
                value: 0,
                text: t('no.change')
            });
            continue;
        }
        options.push({
            value: i,
            text: <span className="text-lowercase">{i + ' ' + t('days.per.year')}</span>
        });
    }
    return options;
};

export const requestDayTypeOptionsFactory = (leaveType: any, t: any) => {
    const requestDayTypeOptions = [
        { value: REQUESTDAYTYPE.FULL, text: t('one.day') },
        { value: REQUESTDAYTYPE.RANGE, text: t('range') },
    ];
    if (leaveType && leaveType.halfADayAllowed) {
        requestDayTypeOptions.push({
            value: REQUESTDAYTYPE.FIRST_PART, text: t('first.part.of.a.day')
        });
        requestDayTypeOptions.push({
            value: REQUESTDAYTYPE.SECOND_PART, text: t('second.part.of.a.day')
        });
    }
    if (leaveType && leaveType.hoursAllowed) {
        requestDayTypeOptions.push({
            value: REQUESTDAYTYPE.HOURS, text: t('hours')
        });
    }
    if (leaveType && leaveType.hoursAllowed && leaveType.overtime) {
        return [{
            value: REQUESTDAYTYPE.HOURS, text: t('hours')
        }];
    }
    return requestDayTypeOptions;
};

export const markApprovalOptionsFactory = (t: any) => {
    return [{
        value: REQUESTAPPROVETYPE.APPROVENOW, text: t('approve.now')
    }, {
        value: REQUESTAPPROVETYPE.SENDFORAPPROVAL, text: t('send.for.approval')
    }];
};

const supportedLanguages = ['en', 'ro', 'fr', 'de', 'es'];

export const languagesOptionsFactory = (t: any) => {
    return supportedLanguages.map((language) => { return { value: language, text: t('language.' + language) }; });
};

const supportedDateFormats = ['browser.default', 'MM/DD/YYYY', 'DD/MM/YYYY', 'YYYY/MM/DD', 'YYYY-MM-DD'];

export const dateFormatOptionsFactory = (t: any) => {
    return supportedDateFormats.map((dateFormat) => { return { value: dateFormat, text: t('date.format.' + dateFormat) }; });
};

const supportedTimeFormats = ['HH:mm', 'hh:mm a'];

export const timeFormatOptionsFactory = (t: any) => {
    return supportedTimeFormats.map((dateFormat) => { return { value: dateFormat, text: t('time.format.' + dateFormat) }; });
};

export const monthsOptionsFactory = () => {
    const moment = getMomentWithLocale();
    let month = moment();
    const months = [];
    for (var i = 0; i < 12; i++) {
        month = month.month(i);
        months.push({ value: i, text: month.format('MMMM') });
    }
    return months;
};

export const daysForMonthOptionsFactory = (monthNumber: any) => {
    const moment = getMomentWithLocale();
    var month = moment().month(monthNumber).date(1);
    const days = [];
    for (var i = 1; i < 32; i++) {
        days.push({ value: i, text: month.format('D') });
        month = month.add(1, 'days');
        if (month.month() !== monthNumber) {
            break;
        }
        if (monthNumber === 1 && month.date() === 29) {
            break;
        }
    }
    return days;
};

export const officesOptionsFactory = (offices: any) => {
    return offices.map((office: any) => { return { value: office.id, text: office.name }; });
};

export const subsidiariesOptionsFactory = (subsidiaries: any) => {
    return subsidiaries.map((subsidiary: any) => { return { value: subsidiary.id, text: subsidiary.name }; });
};

export const currenciesOptionsFactory = () => {
    return [{ value: 'USD', text: 'USD' }, { value: 'EUR', text: 'EUR' }, { value: 'RON', text: 'RON' }];
};

export const currenciesOptionsFactoryRonOnly = () => {
    return [{ value: 'RON', text: 'RON' }];
};

export const nameFormatOptionsFactory = (t: any) => {
    return [{ value: 'FIRSTNAME_LASTNAME', text: t('FIRSTNAME_LASTNAME') }, { value: 'LASTNAME_COMMA_FIRSTNAME', text: t('LASTNAME_COMMA_FIRSTNAME') },
    { value: 'LASTNAME_FIRSTNAME', text: t('LASTNAME_FIRSTNAME') }];
};


export const subscriptionsIntervalOptionsFactory = (t: any) => {
    return [{ value: 1, text: t('monthly.subscription') }, { value: 12, text: t('yearly.subscription') }];
};

export const employeeRoleOptionsFactory = (t: any) => {
    return [{ value: COMPANYUSERROLES.COMPANY_ADMIN, text: t('COMPANYUSERROLES.COMPANY_ADMIN') }, { value: COMPANYUSERROLES.COMPANY_MANAGER, text: t('COMPANYUSERROLES.COMPANY_MANAGER') }, { value: COMPANYUSERROLES.COMPANY_USER, text: t('COMPANYUSERROLES.COMPANY_USER') }];
};

export const employeeGenderOptionsFactory = (t: any) => {
    return [{ value: 'NA', text: t('EMPLOYEEGENDER.NA') }, { value: 'FEMALE', text: t('EMPLOYEEGENDER.FEMALE') }, { value: 'MALE', text: t('EMPLOYEEGENDER.MALE') }];
};


export const hourAMPMOptionsFactory = (start?: any) => {
    const momentFunc = getMomentWithLocale();
    const moment = momentFunc();
    const from = start ? start : 0;
    const hours = [];
    for (var i = from; i < 24; i++) {
        moment.hours(i + '');
        hours.push({ value: i, text: moment.format('hh a') });
    }
    return hours;
};

export const hourOptionsFactory = (start?: any) => {
    const from = start ? start : 0;
    const hours = [];
    for (var i = from; i < 24; i++) {
        hours.push({ value: i, text: i });
    }
    return hours;
};


export const minuteOptionsFactory = (start?: any) => {
    const from = start ? start : 0;
    const minutes = [];
    for (var i = from; i < 60; i++) {
        minutes.push({ value: i, text: i });
    }
    return minutes;
};

export const yearsFactory = (start?: any) => {
    const years = [];
    for (let i = 2020; i < 2025; i++) {
        years.push({ value: i, text: i });
    }
    return years;
};

export const calendarPermissionsOptions = (t: any, session: any) => {
    const options = [
        { value: 0, text: t('none').toLowerCase() },
        { value: 1, text: t('team').toLowerCase() },
        { value: 3, text: t('office').toLowerCase() },
    ];
    if (session.company.subsidiariesEnabled) {
        options.push({ value: 4, text: t('subsidiary').toLowerCase() });
    }
    options.push({ value: 9, text: t('company').toLowerCase() });
    return options;
};

export const reportsPermissionsOptionsForManagers = (t: any, session: any) => {
    let options = [
        { value: 0, text: t('none').toLowerCase() },
        { value: 1, text: t('team').toLowerCase() },
        { value: 3, text: t('office').toLowerCase() }];
    if (session.company.subsidiariesEnabled)
        options.push({ value: 4, text: t('subsidiary').toLowerCase() });
    options.push({ value: 9, text: t('company').toLowerCase() });
    return options;
};


export const reportsPermissionsOptionsForOfficeManagers = (t: any) => {
    return [
        { value: 0, text: t('none').toLowerCase() },
        { value: 3, text: t('office').toLowerCase() },
        { value: 9, text: t('company').toLowerCase() }
    ];
};