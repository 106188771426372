import * as React from 'react';
import Component from '../Component';
import Placeholder from './Placeholder/Placeholder';

export default class TextareaField extends Component {
    textInput: any;
    constructor(props: any) {
        super(props);
        this.state = {
            value: this.props.defaultValue ? this.props.defaultValue : '',
            defaultValue: this.props.defaultValue ? this.props.defaultValue : '',
            touched: false,
            rules: this.props.rules,
            valid: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() { if (this.props.focus) { this.focusNow(); } }

    focusNow() {
        this.textInput.focus();
        let value = this.textInput.value;
        this.textInput.value = '';
        this.textInput.value = value;
    }

    render() {
        let opts: any = {};
        if (this.props.readOnly) {
            opts.readOnly = 'readOnly';
        }
        const t = this.translate();
        return (
            <div className="w-100">
                <Placeholder
                    placeholder={this.props.placeholder}
                    placeholderTooltip={this.props.placeholderTooltip}
                    placeholderTooltipCid={'IFPTCid' + this.cid}
                    value={this.state.value}
                    onClick={() => {
                        this.focusNow();
                    }}
                />
                <textarea
                    className={this.getInputClass() + ' pr-4' +
                        ((this.state.value && this.state.value.length > 0) ?
                            ' pt-3 pb-1' : '')
                    }
                    defaultValue={this.state.defaultValue}
                    ref={(input) => { this.textInput = input; }}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    onKeyDown={this.handleKeyDown}
                    rows={this.props.rows ? this.props.rows : 5}
                    {...opts}
                />
                {
                    (this.state.touched && this.state.valid !== undefined && !this.state.valid) ? (
                        <small className="float-left invalid-feedback text-left">
                            {this.Validator().validateMessage(this.state, t)}
                        </small>
                    ) : ''
                }
            </div>
        );
    }

    handleKeyDown(event: any) { if (this.props.onKeyDown) { this.props.onKeyDown(event); } }

    handleChange(event: any) {
        let newState = Object.assign({}, this.state, { value: event.target.value });
        if (this.state.touched === true) {
            newState = Object.assign(
                {}, this.state, { value: event.target.value, valid: this.Validator().validate(this.state) }
            );
        }
        this.setState(newState, () => {
            if (this.props.onChange) { this.props.onChange(this.state.value); }
        });
    }

    handleBlur(event: any) {
        if (this.props.readOnly) { return; }
        this.setState({ value: event.target.value }, () => {
            if (this.state.value && this.state.value.length > 0) {
                this.setState({ valid: this.Validator().validate(this.state), touched: true });
            }
            //            this.onChange(event);
        });
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    getInputClass() {
        return (!this.state.touched || this.state.valid === undefined ?
            'form-control' :
            (this.state.valid ? 'form-control is-valid' : 'form-control is-invalid'));
    }

    shouldComponentUpdate(nextProps: any, nextState: any) {
        if (nextProps.rules) {
            nextState.rules = nextProps.rules;
        }
        if (nextProps.touched) {
            nextState.touched = nextProps.touched;
            nextState.valid = this.Validator().validate(nextState);
        }
        if (!nextProps.touched) {
            nextState.valid = undefined;
        }
        return true;
    }
}