import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import LeaveInterval from '../../../Components/Leave/LeaveInterval';
import LeaveName from '../../../Components/Leave/LeaveName';
import LeaveReplacement from '../../../Components/Leave/LeaveReplacement';
import Submit from '../../../Components/Submit';
import TextareaField from '../../../Components/TextareaField';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            note: {
                touched: false,
                rules: this.Rules().MaxLengthTextareaRules()
            },
            cancel: {
                pleaseWait: false
            },
        };
    }

    render() {
        const t = this.translate();
        const leave = this.props.leave;
        return <form onSubmit={(event: any) => { this.submit(event); }}>
            <div className="row">
                <div className="col-12 mb-3">
                    <div>
                        <LeaveName leave={leave} />
                    </div>
                    <div>
                        <LeaveInterval leave={leave} />
                    </div>
                </div>
                {leave.replacement && leave.replacement.length > 2 ?
                    <div className="col-12 mb-3"><LeaveReplacement leave={leave} /></div>
                    : <></>}
                <div className="col-12 mb-3">
                    <TextareaField
                        touched={this.state.note.touched}
                        rules={this.state.note.rules}
                        rows={2}
                        focus={this.isScreenSizeSmall() ? false : true}
                        placeholder={t('note')}
                        onChange={(value: any) => {
                            this.setState({ note: Object.assign({}, this.state.note, { value: value }) });
                        }}
                    />
                </div>
                <div className="col-12 mb-3">
                    <Submit
                        faIcon="fas fa-ban"
                        disabled={!this.isFormValid()}
                        pleaseWait={this.state.cancel.pleaseWait}
                    >
                        {t('cancel.request')}
                    </Submit>
                </div>
            </div>
        </form>
    }

    isFormValid() {
        return this.Validator().validate(this.state.note);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            { note: Object.assign({}, this.state.note, { touched: true }) },
            () => {
                if (this.isFormValid()) {
                    this.cancelLeave();
                }
            }
        );
    }

    cancelLeave() {
        const t = this.translate();
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getLeaveCancel(this.props.leave.id),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                comment: this.state.note.value,
            }),
            beforeSend: () => {
                this.setState({ cancel: { pleaseWait: true } });
            },
            success: (data: any, textStatus: any, jqXHR: any) => {
                this.successToastr(t('the.leave.request.was.canceled'));
                if (this.props.onClose) {
                    this.props.onClose();
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            },
        });
    }
}