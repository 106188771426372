import * as React from 'react';
import Component from '../../Component';
import A from '../../Components/A';

export default class Item extends Component {

    render() {
        const employee = this.props.employee;
        const caches: any = this.getCaches().getState();
        const countriesMap = caches.countries.map[this.language()];
        const country = (employee?.country?.length > 0) ?
            countriesMap[employee.country].text : undefined;
        const approver = (employee.approverCompanyUser && employee.approverCompanyUser.id > 0) ?
            (this.getCaches().getState() as any).users.map[employee.approverCompanyUser.id] : undefined;
        const companyUnit = (employee.companyUnit && employee.companyUnit.id) ?
            (this.getCaches().getState() as any).units.map[employee.companyUnit.id] : undefined;
        const office = (employee.office && employee.office.id) ?
            (this.getCaches().getState() as any).offices.map[employee.office.id] : undefined;
        const t = this.translate();
        const tds: any = [];
        tds.push(
            <td key="Employees-Item-Employee">
                <A href={this.Urls().getEmployee(employee.id)} title={employee.fullName}>
                    {employee.fullName}
                </A>
            </td>
        );
        if (approver && approver.id > 0) {
            tds.push(
                <td key="Employees-Item-Approver"><div>
                    <A
                        className="text-dark"
                        href={this.Urls().getEmployee(approver.id)}
                        title={t('approver') + ': ' + approver.fullName}
                    >
                        {approver.fullName}
                    </A>
                </div></td>
            );
        } else {
            tds.push(<td key="Employees-Item-Approver" />);
        }
        if (employee.position && employee.position.length > 0) {
            tds.push(
                <td key="Employees-Item-Position"><div>
                    {employee.position}
                </div></td>
            );
        } else {
            tds.push(<td key="Employees-Item-Position" />);
        }
        if (companyUnit && companyUnit.name && companyUnit.name.length > 0) {
            tds.push(
                <td key="Employees-Item-Team"><div>
                    <A
                        className="text-dark"
                        href={this.Urls().getUnit(companyUnit.id)}
                        title={t('team') + ': ' + companyUnit.name}
                    >
                        {companyUnit.name}
                    </A>
                </div></td>
            );
        } else {
            tds.push(<td key="Employees-Item-Team" />);
        }
        if (office?.name?.length > 0) {
            tds.push(<td key="Employees-Item-Office"><div>
                <A
                    className="text-dark"
                    href={this.Urls().getOffice(office.id)}
                    title={t('office') + ': ' + office.name}
                >
                    {office.name}
                </A>
            </div></td>);
        } else {
            tds.push(<td key="Employees-Item-Office" />);
        }
        const session: any = this.getSession().getState();
        if (session.company.subsidiariesEnabled) {
            const subsidiary = (employee.subsidiary && employee.subsidiary.id) ?
                (this.getCaches().getState() as any).subsidiaries.map[employee.subsidiary.id] : undefined;
            if (subsidiary?.name?.length > 0) {
                tds.push(
                    <td key="Employees-Item-Subsidiary"><div>
                        <A
                            className="text-dark"
                            href={this.Urls().getSubsidiary(subsidiary.id)}
                            title={t('subsidiary') + ': ' + subsidiary.name}
                        >
                            {subsidiary.name}
                        </A>
                    </div></td>
                );
            } else {
                tds.push(<td key="Employees-Item-Subsidiary" />);
            }
        }
        if (country && country.length > 0) {
            tds.push(
                <td key="Employees-Item-Country"><div>{country}</div></td>
            );
        } else {
            tds.push(<td key="Employees-Item-Country" />);
        }
        return (<tr>{tds}</tr>);
    }
}