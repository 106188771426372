import * as React from 'react';
import Component from '../../Component';
import Date from '../../Components/Date';
import EmployeeLink from '../../Components/EmployeeLink';

export default class Item extends Component {

    render() {
        const t = this.translate();
        const employee = this.props.employee;
        return <tr><td key="Employees-Item-Employee">
            <EmployeeLink disableFa={true} employee={employee} />
        </td>
            <td key="Employees-Item-Employment-Age" className="text-right">
                {employee.ageLiteral ? employee.ageLiteral : null}
            </td>
            <td key="Employees-Item-BirthDate" className="text-right">
                {employee.birthDate ? <Date date={employee.birthDate} /> : null}
            </td>
            <td key="Employees-Item-Employment-Next" className="text-right">
                {employee.todayIsBirthDate ? t('today') : (employee.next ? employee.next.humanize(true) : null)}
            </td></tr>;
    }
}