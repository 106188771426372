import React from 'react';
import { none } from '../Actions/Modal';
import Component from '../Component';
import { STATUS } from '../General';

export default class Page extends Component {
    constructor(props: any) {
        super(props);
    }

    render() {
        if ((this.getSession().getState() as any).status === STATUS.LOADING) {
            return this.renderLoading();
        }
        return React.createElement((this.getRouter().getState() as any).component, { key: window.location.href + '' });
    }

    shouldComponentUpdate(nextProps: any, nextState: any) {
        if ((this.getSession().getState() as any).status === STATUS.READY &&
            !(this.getSession().getState() as any).logged &&
            (this.getRouter().getState() as any).logged) {
            none(this.getModal(), {});
            this.goToLogin({ target: window.location });
        }
        return true;
    }
}