import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import Anchor from '../../../Components/Anchor';
import ATooltip from '../../../Components/ATooltip';
import Submit from '../../../Components/Submit';
import TextareaField from '../../../Components/TextareaField';
import MoreActions from './MoreActions';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            allowance: {
                value: this.props.employeeLeaveType.allocation,
            },
            adjustment: {
                value: this.props.employeeLeaveType.supplemental,
            },
            adjustmentPerYear: {
                value: this.props.leaveTypePerYear.supplemental,
            },
            comment: {
                value: '',
                touched: false,
            },
            employeeAllowanceMode: false,
            showMore: false,
            update: { pleaseWait: false },
            step: this.props.leaveType.halfADayAllowed ? 0.5 : 1
        };
        this.getOnChangeOpts = this.getOnChangeOpts.bind(this);
        this.handleChangeEmployeeAllowanceMode = this.handleChangeEmployeeAllowanceMode.bind(this);
    }

    handleChangeEmployeeAllowanceMode() {
        this.setState({ employeeAllowanceMode: !this.state.employeeAllowanceMode });

    }

    render() {
        const t = this.translate();
        const moreArgs = {
            changeStep: (value: any) => { this.setState({ step: value }); },
            step: this.state.step,
            leaveType: this.props.leaveType
        };
        return (
            <form onSubmit={(event: any) => { this.submit(event); }}>
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="btn-group w-100" role="group">
                            <Anchor
                                href={'#'}
                                onClick={() => { if (this.state.employeeAllowanceMode) { this.handleChangeEmployeeAllowanceMode(); } }}
                                className={'w-50 btn ' + (!this.state.employeeAllowanceMode ? 'btn-primary' : 'btn-outline-primary')}>
                                {this.props.leaveTypePerYear.year + ' ' + t('allowance')}
                            </Anchor>
                            <Anchor
                                href={'#'}
                                onClick={() => { if (!this.state.employeeAllowanceMode) { this.handleChangeEmployeeAllowanceMode(); } }}
                                className={'w-50 btn ' + (this.state.employeeAllowanceMode ? 'btn-primary' : 'btn-outline-primary')}>
                                {t('employee.allowance')}
                            </Anchor>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        !this.state.employeeAllowanceMode ?
                            <div className={'col-12 mb-3 text-lowercase' + (this.state.showMore ? ' mb-3' : '')}>
                                <div className="float-right">
                                    <table className="table table-sm mb-0">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '1' }} className="border-0">
                                                    {
                                                        ((this.state.allowance.value + this.state.adjustment.value + this.state.adjustmentPerYear.value === -100) || (this.state.allowance.value + this.state.adjustment.value + this.state.adjustmentPerYear.value - this.state.step < -100)) ?
                                                            <Anchor className="pl-0 text-secondary disabled" faIcon="fa fa-minus-circle fa-lg" />
                                                            :
                                                            <Anchor className="pl-0" faIcon="fa fa-minus-circle fa-lg" onClick={() => { this.minusAdjustment(true); }} />
                                                    }
                                                </td>
                                                <td style={{ width: '1' }} className="border-0">
                                                    {this.getEmployeeAllowanceAdjustedPerYear(
                                                        this.state.allowance.value + this.state.adjustment.value,
                                                        this.state.adjustmentPerYear.value)}
                                                </td>
                                                <td style={{ width: '1' }} className="border-0">
                                                    {
                                                        ((this.state.allowance.value + this.state.adjustment.value + this.state.adjustmentPerYear.value > 365) || (this.state.allowance.value + this.state.adjustment.value + this.state.adjustmentPerYear.value + this.state.step > 365)) ?
                                                            <Anchor className="float-right pr-0 ml-0 text-secondary disabled" faIcon="fa fa-plus-circle fa-lg" />
                                                            :
                                                            <Anchor className="float-right pr-0 ml-0" faIcon="fa fa-plus-circle fa-lg" onClick={() => { this.plusAdjustment(true); }} />
                                                    }
                                                </td>
                                                <td style={{ width: '1' }} className="border-0">
                                                    <MoreActions
                                                        resetAllowance={() => { this.setState({ adjustmentPerYear: { value: 0 } }); }}
                                                        allowance={this.state.allowance.value + this.state.adjustment.value + this.state.adjustmentPerYear.value}
                                                        adjustment={this.state.adjustmentPerYear.value}
                                                        {...moreArgs}
                                                    />
                                                </td>
                                            </tr>
                                            {this.getEmployeeAllowanceAdjustedPerYearDetails(
                                                this.state.allowance.value + this.state.adjustment.value,
                                                this.state.adjustmentPerYear.value)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            :
                            <div className="col-12 mb-3">
                                <div className="float-right">
                                    <table className="table table-sm mb-0">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '1' }} className="border-0">
                                                    {
                                                        ((this.state.allowance.value + this.state.adjustment.value === 0) || (this.state.allowance.value + this.state.adjustment.value - this.state.step < 0)) ?
                                                            <Anchor className="pl-0 text-secondary disabled" faIcon="fa fa-minus-circle fa-lg" />
                                                            :
                                                            <Anchor className="pl-0" faIcon="fa fa-minus-circle fa-lg" onClick={() => { this.minusAdjustment(); }} />
                                                    }
                                                </td>
                                                <td style={{ width: '1' }} className="border-0">
                                                    {this.getEmployeeAllowanceAdjusted(
                                                        this.state.allowance.value, this.state.adjustment.value)}
                                                </td>
                                                <td style={{ width: '1' }} className="border-0">
                                                    {(this.state.allowance.value + this.state.adjustment.value > 365) || (this.state.allowance.value + this.state.adjustment.value + this.state.step > 365) ?
                                                        <Anchor className="float-right pr-0 ml-0 text-secondary disabled" faIcon="fa fa-plus-circle fa-lg" />
                                                        : <Anchor className="float-right pr-0 ml-0" faIcon="fa fa-plus-circle fa-lg" onClick={() => { this.plusAdjustment(); }} />}
                                                </td>
                                                <td style={{ width: '1' }} className="border-0">
                                                    <MoreActions
                                                        resetAllowance={(resetAllowance: any) => { this.setState({ adjustment: { value: 0 } }); }}
                                                        allowance={this.state.allowance.value + this.state.adjustment.value}
                                                        adjustment={this.state.adjustment.value}
                                                        {...moreArgs}
                                                    />
                                                </td>
                                            </tr>
                                            {this.getEmployeeAllowanceAdjustedDetails(
                                                this.state.allowance.value, this.state.adjustment.value)}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                    }
                    <div className="col-12 mb-3 d-none">
                        <TextareaField
                            placeholder={t('comment')}
                            touched={this.state.comment.touched}
                            rules={this.state.comment.rules}
                            {...this.getOnChangeOpts('comment')}
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <Submit
                            faIcon="far fa-hdd"
                            disabled={!this.isFormValid()}
                            pleaseWait={this.state.update.pleaseWait}
                        >
                            {t('update')}
                        </Submit>
                    </div>
                </div>
            </form >
        );
    }

    isFormValid() {
        return true;
    }

    submit(event: any) {
        event.preventDefault();
        this.update();
    }

    getEmployeeAllowanceAdjusted(allowance: any, adjustment: any) {
        const value = allowance + adjustment;
        return (<span className="ml-2 mr-2">{this.state.step === 1 ? value : parseFloat((Math.round(value * 100) / 100) as any).toFixed(1)}</span>);
    }

    getEmployeeAllowanceAdjustedDetails(allowance: any, adjustment: any) {
        const t = this.translate();
        if (adjustment === 0) {
            return null;
        }
        return (
            <tr>
                <td colSpan={4} className="border-0 text-right pt-0 pb-0">
                    {' ('}
                    <ATooltip tooltip={t('employee.allowance')}>{allowance}</ATooltip>
                    <span>{' + '}</span>
                    <ATooltip tooltip={t('allowance.adjustment')}>{adjustment}</ATooltip>
                    {') '}
                    <div className="text-muted float-right ml-1">
                        <small>{t('days.per.year')}</small>
                    </div>
                </td>
            </tr>
        );
    }

    getOnChangeOpts(field: any) {
        return {
            onChange: (value: any) => {
                const data: any = {};
                data[field] = Object.assign({}, this.state[field], { value: value });
                this.setState(data);
            }
        };
    }


    getEmployeeAllowanceAdjustedPerYear(allowance: any, adjustment: any) {
        const value = allowance + adjustment;
        return (<span className="ml-2 mr-2">{this.state.step === 1 ? value : parseFloat((Math.round(value * 100) / 100) as any).toFixed(1)}</span>);
    }

    getEmployeeAllowanceAdjustedPerYearDetails(allowance: any, adjustment: any) {
        if (adjustment === 0) {
            return null;
        }
        const t = this.translate();
        return (
            <tr>
                <td colSpan={4} className="border-0 text-right pt-0 pb-0">
                    {'('}
                    <ATooltip tooltip={t('default.allowance')}>{allowance}</ATooltip>
                    <span>{' + '}</span>
                    <ATooltip tooltip={this.props.leaveTypePerYear.year + ' ' + t('allowance.adjustment')}>
                        {adjustment}
                    </ATooltip>
                    {') '}
                    <div className="text-muted float-right ml-1">
                        <small>{t('days.per.year')}</small>
                    </div>
                </td>
            </tr>
        );
    }

    minusAdjustment(year?: boolean) {
        if (year) {
            this.setState({ adjustmentPerYear: Object.assign({}, this.state.adjustmentPerYear, { value: (this.state.adjustmentPerYear.value - this.state.step) }) });
            return;
        }
        this.setState({ adjustment: Object.assign({}, this.state.adjustment, { value: (this.state.adjustment.value - this.state.step) }) });
    }

    plusAdjustment(year?: boolean) {
        if (year) {
            this.setState({ adjustmentPerYear: Object.assign({}, this.state.adjustmentPerYear, { value: (this.state.adjustmentPerYear.value + this.state.step) }) });
            return;
        }
        this.setState({ adjustment: Object.assign({}, this.state.adjustment, { value: (this.state.adjustment.value + this.state.step) }) });
    }

    update() {
        if (this.state.employeeAllowanceMode) {
            $.ajax({
                'type': 'POST',
                contentType: 'application/json',
                url: this.Endpoints().getUpdateEmployeeLeaveType(this.props.employee.id),
                dataType: 'json',
                cache: false,
                data: JSON.stringify({
                    id: this.props.employeeLeaveType.id,
                    leaveTypeId: this.props.employeeLeaveType.leaveTypeId,
                    supplemental: this.state.adjustment.value,
                    comment: this.state.comment.value
                }),
                beforeSend: () => {
                    this.setState({ update: { pleaseWait: true } });
                },
                success: (data: any, textStatus: any, jqXHR: any) => {
                    this.setState(
                        { ajaxSubmitCalls: this.state.ajaxSubmitCalls - 1 },
                        () => { this.submitFinished(); });
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    this.ajaxError(jqXHR, textStatus, errorThrown);
                },
            });
        } else {
            $.ajax({
                'type': 'POST',
                contentType: 'application/json',
                url: this.Endpoints().getUpdateEmployeeLeaveTypePerYear(this.props.employee.id),
                dataType: 'json',
                cache: false,
                data: JSON.stringify({
                    year: this.props.leaveTypePerYear.year,
                    leaveTypeId: this.props.employeeLeaveType.leaveTypeId,
                    supplemental: this.state.adjustmentPerYear.value,
                    comment: this.state.comment.value
                }),
                beforeSend: () => {
                    this.setState({ update: { pleaseWait: true } });
                },
                success: (data: any, textStatus: any, jqXHR: any) => {
                    this.setState(
                        { ajaxSubmitCalls: this.state.ajaxSubmitCalls - 1 },
                        () => { this.submitFinished(); });
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    this.ajaxError(jqXHR, textStatus, errorThrown);
                },
            });
        }
    }

    submitFinished() {
        const t = this.translate();
        this.successToastr(t('the.allowance.was.updated'));
        if (this.props.onClose) {
            this.props.onClose();
        }
    }
}