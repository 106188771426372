import * as React from 'react';
import Component from '../../Component';
import Anchor from './../Anchor';
import Fa from '../Fa';

export default class Month extends Component {

    render() {
        const month = this.props.month;
        return (
            <table className="table table-sm m-0 p-0 border-0">
                <thead>
                    <tr>
                        <th colSpan={7} className="text-center border-0 align-bottom">
                            {
                                (this.props.showPrevious) ?
                                    <div className="float-left">
                                        <button type="button"
                                            onClick={() => { if (this.props.onPreviousMonth) { this.props.onPreviousMonth(); } }}
                                            className="btn btn-outline-primary border-0">
                                            <Fa fa="fas fa-play fa-rotate-180 fa-fw" />
                                        </button>
                                    </div>
                                    :
                                    null
                            }
                            <span className="d-inline-block m-2 font-weight-bold">{month.monthLiteral} {month.year}</span>
                            {
                                (this.props.showNext) ?
                                    <div className="float-right">
                                        <button type="button"
                                            onClick={() => { if (this.props.onNextMonth) { this.props.onNextMonth(); } }}
                                            className="btn btn-outline-primary border-0">
                                            <Fa fa="fas fa-play fa-fw" />
                                        </button>
                                    </div>
                                    :
                                    null
                            }
                        </th>
                    </tr>
                    <tr>
                        {
                            month.daysLiteral.map((day: any) => {
                                return (
                                    <th
                                        key={month.year + '-' + month.month + '-' + day}
                                        className="text-center border-0"
                                    >
                                        {day}
                                    </th>
                                );
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {month.weeks.map((week: any) => {
                        return (
                            <tr key={month.year + '-' + month.month + '-' + week.weekOfMonth}>
                                {
                                    week.days.map((item: any) => {
                                        return (
                                            <td
                                                key={item.date + '-Disabled-' + item.disabled}
                                                className={
                                                    (!item.deleted && item.disabled ? 'text-muted' :
                                                        (!item.deleted && item.selected ? 'bg-primary' : '')) + ' text-center border-0'}
                                                style={item.style && !item.deleted ? item.style : null}
                                            >
                                                {
                                                    (item.disabled || item.deleted) ?
                                                        this.getItem(item)
                                                        :
                                                        (
                                                            <Anchor
                                                                className={'d-block ' + (item.selected ? 'text-white ' : '')}
                                                                onClick={(event: any) => { if (this.props.onSelect) { this.props.onSelect(item.date); } }}
                                                                onMouseOver={() => { if (this.props.onMouseOver) { this.props.onMouseOver(item.date); } }}
                                                            >
                                                                {this.getItem(item)}
                                                            </Anchor>
                                                        )
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    getItem(item: any): any {
        return item.deleted ? <small className="text-muted font-weight-lighter">{item.value}</small> : (item.today ? (<strong>{item.value}</strong>) : item.value);
    }
    //'\u00a0'
}