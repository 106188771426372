import * as React from 'react';
import BaseTabs from '../../Components/BaseTabs';
import { OFFICETABS } from '../../General';

export default class Tabs extends BaseTabs {
    getTabs(): any[] {
        const t = this.translate();
        return [
            {
                faIcon: 'fa fa-calendar-alt',
                title: [(<span key="xs" className="d-inline-block d-sm-none">{t('public.holidays')}</span>),
                (<span key="-xs" className="d-none d-sm-inline-block">{t('public.holidays.and.events')}</span>)],
                tab: OFFICETABS.HOES
            },
            {
                faIcon: 'fas fa-ban',
                title: t('blackout.days'),
                tab: OFFICETABS.BLACKOUTEVENTS
            },
            {
                faIcon: 'fas fa-users',
                title: t('members'),
                tab: OFFICETABS.MEMBERS
            },
            {
                faIcon: 'fas fa-wrench',
                title: t('settings'),
                tab: OFFICETABS.SETTINGS
            }
        ];
    }
    getName(): string {
        return 'Office';
    }
}