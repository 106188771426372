import * as React from 'react';
import Component from '../../Component';
import Submit from '../../Components/Submit';
import InputField from '../../Components/InputField';
import SelectField from '../../Components/SelectField';
import TextareaField from '../../Components/TextareaField';
import ColorField from '../../Components/ColorField';
import ToggleField from '../../Components/ToggleField';
import Back from '../../Components/Back';
import Line from '../../Components/Line';
import * as $ from 'jquery';
import { DEFAULT_COLORS } from '../../General';
import { random } from '../../Utils';
import { doFetchLeaveTypesFromServer } from '../../Actions/Caches';
import {
    allowanceOptionsFactory
} from '../../SelectOptionsFactories';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            name: {
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            allowance: {
                value: [5.0],
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            color: {
                value: DEFAULT_COLORS[random(0, DEFAULT_COLORS.length)]
            },
            halfADayAllowed: {
                value: true
            },
            registerInThePastAllowed: {
                value: true
            },
            enforceAllowance: {
                value: false
            },
            attachmentsAllowed: {
                value: false
            },
            description: {
                touched: false,
                rules: this.Rules().MaxLengthTextareaRules()
            },
            add: {
                pleaseWait: false
            },
        };
    }

    render() {
        const t = this.translate();
        const allowanceOptions = allowanceOptionsFactory(t, this.state.halfADayAllowed.value ? 0.5 : 1);
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Back href={this.Urls().getLeaveTypes()} />
                        <h5 className="mb-0 d-inline">{t('add.leave.type')}</h5>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <Line />
                    </div>
                </div>
                <div className={this.getCardClassNameSize()}>
                    <div className="card-body">
                        <form onSubmit={(event: any) => { this.submit(event); }}>
                            <fieldset>
                                <div className="row">
                                    <div className="col-12 mb-3 mt-3">
                                        <InputField
                                            touched={this.state.name.touched}
                                            rules={this.state.name.rules}
                                            type="text"
                                            placeholder={t('name')}
                                            focus={this.isScreenSizeSmall() ? false : true}
                                            onChange={(value: any) => { this.setState({ name: Object.assign({}, this.state.name, { value: value }) }); }}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <SelectField
                                            defaultValue={this.state.allowance.value}
                                            touched={this.state.allowance.touched}
                                            options={allowanceOptions}
                                            multiple={false}
                                            placeholder={t('allowance.days.per.year')}
                                            onChange={(value: any) => { this.setState({ allowance: Object.assign({}, this.state.allowance, { value: value }) }); }}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <ColorField
                                            defaultValue={this.state.color.value}
                                            // tslint:disable-next-line:max-line-length
                                            onChange={(value: any) => { this.setState({ color: Object.assign({}, this.state.color, { value: value }) }); }}
                                            colors={DEFAULT_COLORS}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <ToggleField
                                            defaultValue={this.state.halfADayAllowed.value}
                                            tooltip={t('half.day.request.tip')}
                                            // tslint:disable-next-line:max-line-length
                                            onChange={(value: any) => { this.setState({ halfADayAllowed: Object.assign({}, this.state.halfADayAllowed, { value: value }) }); }}
                                        >
                                            {t('half.day.request')}
                                        </ToggleField>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <ToggleField
                                            defaultValue={this.state.registerInThePastAllowed.value}
                                            tooltip={t('register.in.the.past.tip')}
                                            onChange={(value: any) => {
                                                this.setState(
                                                    {
                                                        registerInThePastAllowed:
                                                            Object.assign(
                                                                {},
                                                                this.state.registerInThePastAllowed,
                                                                { value: value }
                                                            )
                                                    }
                                                );
                                            }}
                                        >
                                            {t('register.in.the.past')}
                                        </ToggleField>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <ToggleField
                                            defaultValue={this.state.enforceAllowance.value}
                                            tooltip={t('enforce.allowance.tip')}
                                            onChange={(value: any) => {
                                                this.setState(
                                                    {
                                                        enforceAllowance:
                                                            Object.assign(
                                                                {},
                                                                this.state.enforceAllowance,
                                                                { value: value }
                                                            )
                                                    }
                                                );
                                            }}
                                        >
                                            {t('enforce.allowance')}
                                        </ToggleField>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <TextareaField
                                            touched={this.state.description.touched}
                                            type="text"
                                            rows={2}
                                            placeholder={t('description')}
                                            rules={this.state.description.rules}
                                            onChange={(value: any) => {
                                                this.setState(
                                                    {
                                                        description: Object.assign(
                                                            {},
                                                            this.state.description,
                                                            { value: value })
                                                    }
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <Submit
                                            faIcon="far fa-hdd"
                                            disabled={!this.isFormValid()}
                                            blocked={this.state.add.pleaseWait}
                                        >
                                            {t('add.leave.type')}
                                        </Submit>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    isFormValid() {
        return this.Validator().validate(this.state.name)
            && this.Validator().validate(this.state.color)
            && this.Validator().validate(this.state.description);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                name: Object.assign({}, this.state.name, { touched: true }),
                color: Object.assign({}, this.state.color, { touched: true }),
                description: Object.assign({}, this.state.description, { touched: true })
            },
            () => {
                if (this.isFormValid()) {
                    this.addLeaveType();
                }
            }
        );
    }

    addLeaveType() {
        const t = this.translate();
        $.ajax({
            type: 'PUT',
            contentType: 'application/json',
            url: this.Endpoints().getLeaveTypeCreate(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                names: [{
                    lang: this.language(),
                    value: this.state.name.value
                }],
                allocation: this.state.allowance.value[0],
                color: this.state.color.value,
                description: this.state.description.value,
                halfADayAllowed: this.state.halfADayAllowed.value,
                registerInThePast: this.state.registerInThePastAllowed.value,
                allocationEnforced: this.state.enforceAllowance.value,
                attachmentsAllowed: this.state.attachmentsAllowed.value
            }),
            beforeSend: () => {
                this.setState({ add: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                doFetchLeaveTypesFromServer(this.getCaches());
                this.successToastr(t('the.leave.type.was.added'));
                this.goToLeaveTypes();
            },
            error: (jqXHR, textStatus, errorThrown) => {
                if (+jqXHR.status === 400) {
                    var reason = jqXHR.getResponseHeader('REASON');
                    if (reason && reason === 'DUPLICATE') {
                        this.successToastr(t('leave.type.duplicate'));
                        return;
                    }
                }
                this.generalErrorToastr();
            },
            complete: (jqXHR, textStatus) => {
                this.setState({ add: { pleaseWait: false } });
            }
        });
    }
}