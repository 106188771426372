import * as React from 'react';
import Component from '../../Component';
import Manager from './Manager';
import { STATUS } from '../../General';

export default class Year extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            year: this.props.year
        };
    }

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.units.status === STATUS.READY && caches.users.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return <Manager
            onChangeYear={(value: any) => { if (this.props.onChangeYear) { this.props.onChangeYear(value); } }}
            daysEvents={this.props.daysEvents}
            year={this.state.year}
        />
    }
}