import * as React from 'react';
import ResponsiveModal from '../ResponsiveModal';
import Manager from './Manager';
import { none } from '../../../Actions/Modal';
import LeaveTypeName from '../../../Components/LeaveType/LeaveTypeName';

export default class AjustEmployeeLeaveTypeAllowance extends ResponsiveModal {
    getContent() {
        return (
            <Manager
                leaveType={this.props.options.leaveType}
                employee={this.props.options.employee}
                leaveTypePerYear={this.props.options.leaveTypePerYear}
                onClose={() => {
                    this.toggle();
                    none(this.getModal(), {});
                    if (this.props.callback) { this.props.callback(); }
                }}
            />
        );
    }

    getTitle(): any {
        const t = this.translate();
        return <h5 className="m-0 p-0">
            {t('adjust') + ' '}
            <LeaveTypeName leaveType={this.props.options.leaveType} />
            <span className="text-lowercase">{' ' + t('allowance') + ' '}</span>
        </h5>
    }
}