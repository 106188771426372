import * as moment from 'moment';
import { getURLParameter } from './Utils';

export const usersFactory = (userRows: any, session: any) => {
    return userRows.map((userRow: any) => {
        return userFactory(userRow, session);
    });
};

export const userFactory = (userRow: any, session: any) => {
    let i = 0;
    let companyUser: any = {};
    companyUser.id = userRow[i++];
    companyUser.role = userRow[i++];
    companyUser.status = userRow[i++];
    let approverId = userRow[i++];
    if (approverId) {
        companyUser.approverCompanyUser = {};
        companyUser.approverCompanyUser.id = approverId;
    }
    let unitId = userRow[i++];
    if (unitId) {
        companyUser.companyUnit = {};
        companyUser.companyUnit.id = unitId;
    }
    companyUser.firstName = userRow[i++];
    if (companyUser.firstName) {
        companyUser.firstName = companyUser.firstName.trim();
    }
    companyUser.lastName = userRow[i++];
    if (companyUser.lastName) {
        companyUser.lastName = companyUser.lastName.trim();
    }
    companyUser.email = userRow[i++];
    companyUser.country = userRow[i++];
    companyUser.position = userRow[i++];
    let officeId = userRow[i++];
    if (officeId) {
        companyUser.office = {};
        companyUser.office.id = officeId;
    }
    companyUser.fullName = fullNameFactory(companyUser, session.company.nameFormat);
    companyUser.companyUserSettings = JSON.parse(userRow[i++]);
    companyUser.companyUserSettings.weekDaysAndHours.forEach((item: any) => {
        if (item.working === false) {
            item.hours = 0;
        } else {
            let f = moment.duration({ hours: item.first.end.hour, minutes: item.first.end.minute, seconds: 59 })
                .subtract(moment.duration({ hours: item.first.start.hour, minutes: item.first.start.minute }));
            let s = moment.duration({ hours: item.second.end.hour, minutes: item.second.end.minute, seconds: 59 })
                .subtract(moment.duration({ hours: item.second.start.hour, minutes: item.second.start.minute }));
            item.hours = Math.round(f.add(s).as('hours'));
        }
    });
    companyUser.employmentStartDate = userRow[i++];
    companyUser.employmentEndDate = userRow[i++];
    let subsidiaryId = userRow[i++];
    if (subsidiaryId) {
        companyUser.subsidiary = { id: subsidiaryId };
    }
    const companyId = session.company.id;
    if (companyId === 8902) {
        companyUser.personalIdentificationNumber = userRow[i++];;
        companyUser.phoneNumber = userRow[i++];
        companyUser.birthDate = userRow[i++];
        companyUser.gender = userRow[i++];
        companyUser.personalPhoneNumber = userRow[i++];
        companyUser.emergencyPhoneNumber = userRow[i++];
        companyUser.nextOfKinName = userRow[i++];
        companyUser.nextOfKinRelationship = userRow[i++];
        companyUser.nextOfKinPhoneNumber = userRow[i++];
    }
    return companyUser;
};

export const fullNameFactory = (employee: any, nameFormat?: any) => {
    const lastName = employee.lastName ? employee.lastName.trim() : '';
    const firstName = employee.firstName ? employee.firstName.trim() : ''
    switch (nameFormat) {
        case 'FIRSTNAME_LASTNAME':
            return firstName + ((lastName && lastName.length > 0) ? ' ' : '') + lastName;
        case 'LASTNAME_COMMA_FIRSTNAME':
            return lastName + ((lastName && lastName.length > 0) ? ', ' : '') + firstName;
        case 'LASTNAME_FIRSTNAME':
            return lastName + ((lastName && lastName.length > 0) ? ' ' : '') + firstName;
        default:
            return firstName + ((lastName && lastName.length > 0) ? ' ' : '') + lastName;
    }
}

export const unitsFactory = (unitRows: any) => {
    return unitRows.map((unitRow: any) => {
        return unitFactory(unitRow);
    });
};

export const unitFactory = (unitRow: any) => {
    let i = 0;
    let companyUnit: any = {};
    companyUnit.id = unitRow[i++];
    companyUnit.name = unitRow[i++];
    let managerId = unitRow[i++];
    if (managerId) {
        companyUnit.managerCompanyUser = {};
        companyUnit.managerCompanyUser.id = managerId;
    }
    return companyUnit;
};

export const leaveTypesFactory = (leaveTypeRows: any) => {
    return leaveTypeRows.map((leaveTypeRow: any) => {
        return leaveTypeFactory(leaveTypeRow);
    });
};

export const leaveTypeFactory = (leaveTypeRow: any) => {
    let i = 0;
    let leaveType: any = {};
    leaveType.id = leaveTypeRow[i++];
    leaveType.active = leaveTypeRow[i++];
    leaveType.allocation = leaveTypeRow[i++];
    leaveType.allocationEnforced = leaveTypeRow[i++];
    leaveType.color = leaveTypeRow[i++];
    leaveType.halfADayAllowed = leaveTypeRow[i++];
    leaveType.names = JSON.parse(leaveTypeRow[i++]);
    leaveType.registerInThePast = leaveTypeRow[i++];
    leaveType.transferOverYearAllowed = leaveTypeRow[i++];
    leaveType.attachmentsAllowed = leaveTypeRow[i++];
    leaveType.paperCopy = leaveTypeRow[i++];
    leaveType.commentRequired = leaveTypeRow[i++];
    i++;
    leaveType.replacement = leaveTypeRow[i++];
    leaveType.replacementRequired = leaveTypeRow[i++];
    leaveType.earlier = leaveTypeRow[i++];
    leaveType.usableInterval = leaveTypeRow[i++];
    leaveType.usableFrom = leaveTypeRow[i++];
    leaveType.usableTo = leaveTypeRow[i++];
    leaveType.working = leaveTypeRow[i++];
    leaveType.showEarnedAllowance = leaveTypeRow[i++];
    leaveType.effectiveAfter = leaveTypeRow[i++];
    leaveType.hoursAllowed = leaveTypeRow[i++];
    leaveType.overtime = leaveTypeRow[i++];
    return leaveType;
};

export const leavesFactory = (leaveRows: any, users: any) => {
    return leaveRows.map((leaveRow: any) => {
        return leaveFactory(leaveRow, users);
    });
};

export const leaveFactory = (leaveRow: any, users: any) => {
    let leave: any = {};
    let i = 0;
    leave.id = leaveRow[i++]; // 0
    leave.requestedOn = leaveRow[i++];  // 1
    i++; // leaveRow[i++] //le.modifiedon // 2
    leave.approverComment = leaveRow[i++]; // 3
    leave.cancelComment = leaveRow[i++]; // 4
    let color = leave.color = leaveRow[i++]; // 5
    leave.comment = leaveRow[i++]; // 6
    leave.dayType = leaveRow[i++]; // 7
    leave.endDate = leaveRow[i++]; // 8
    let jsonNames = leaveRow[i++]; // le.jsonnames //9
    leave.lastActionOn = leaveRow[i++]; // 10
    leave.startDate = leaveRow[i++];  // 11
    leave.status = leaveRow[i++]; // 12
    i++; // leaveRow[i++];//le.company_id, //13
    leave.approvedBy = users.map[leaveRow[i++]]; // 14
    leave.approver = users.map[leaveRow[i++]]; // 15
    leave.companyUser = users.map[leaveRow[i++]]; // 16
    leave.createdBy = users.map[leaveRow[i++]]; // 17
    let leaveTypeId = leaveRow[i++]; // le.leave_type_id ctrl.leaveTypesMap[leaveRow[i++]]//18
    leave.leaveType = {};
    leave.leaveType.id = leaveTypeId;
    leave.leaveType.color = color;
    leave.leaveType.names = JSON.parse(jsonNames);
    let attachments: any = leaveRow[i++];
    leave.amount = leaveRow[i++];
    if (attachments) {
        leave.attachments = JSON.parse(attachments);
    }
    leave.replacement = leaveRow[i++];
    leave.hours = leaveRow[i++];
    leave.workingHours = leaveRow[i++];
    leave.overtime = leaveRow[i++];
    return leave;
};

export const filtersFormUrlFactory = () => {
    const filtersFromUrl: any = {};
    if (getURLParameter('sd')) {
        filtersFromUrl.startDate = {
            value: (moment as any)(getURLParameter('sd'))
        };
    }
    if (getURLParameter('ed')) {
        filtersFromUrl.endDate = {
            value: (moment as any)(getURLParameter('ed'))
        };
    }
    let leaveStatusesParam = getURLParameter('leavestatuses');
    if (leaveStatusesParam) {
        let leaveStatuses: any = [];
        if (leaveStatusesParam.indexOf(',') >= 0) {
            leaveStatuses = leaveStatusesParam.split(',');
        } else {
            leaveStatuses = leaveStatuses.concat(leaveStatusesParam);
        }
        filtersFromUrl.leaveStatuses = {
            value: leaveStatuses.map((leaveStatus: any) => {
                return +leaveStatus;
            })
        };
    }
    let leaveTypesParam = getURLParameter('leavetypes');
    if (leaveTypesParam) {
        let leaveTypes: any = [];
        if (leaveTypesParam.indexOf(',') >= 0) {
            leaveTypes = leaveTypesParam.split(',');
        } else {
            leaveTypes = leaveTypes.concat(leaveTypesParam);
        }
        filtersFromUrl.leaveTypes = {
            value: leaveTypes
        };
    }
    return filtersFromUrl;
};