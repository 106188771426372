import Component from '../../Component';
import { COMPANYUSERROLES, STATUS } from '../../General';
import SubscriptionRules from '../../SubscriptionRules';

export default class MenuBase extends Component {
    static readonly forEverybody = () => { return true; };

    constructor(props: any) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { isOpen: this.props.isOpen };
        this.onResize();
    }

    toggle() {
        this.setState({ isOpen: !this.state.isOpen }, () => { this.onResize(); });
    }

    onResize() {
        if (this.props.onResize) { this.props.onResize(0); }
    }

    getNotLoggedMenuItems() {
        const t = this.translate();
        const menuItems = [
            {
                href: this.Urls().getLogin(),
                faIcon: 'fas fa-sign-in-alt',
                title: t('login'),
                match: (url: string) => {
                    return (url.indexOf(this.Urls().getLogin()) > -1);
                },
                filter: () => { return true; }
            },
            {
                href: this.Urls().getRegisterCompany(),
                faIcon: 'fas fa-building',
                title: t('register.company'),
                match: (url: string) => {
                    return (url.indexOf(this.Urls().getRegisterCompany()) > -1);
                },
                filter: () => { return true; }
            },
        ];
        return menuItems.filter((menuItem: any) => { return menuItem.filter(); });
    }

    getMenuItemsWitoutLogout() {
        const t = this.translate();
        const session: any = this.getSession().getState();
        const forAdminOnly = () => {
            return session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN;
        };
        const forManager = () => {
            return session.companyUser.role === COMPANYUSERROLES.COMPANY_MANAGER;
        };
        const forAdminOrManager = () => {
            return forAdminOnly() || forManager();
        }
        const forApprovers = () => {
            return session.companyUser.isManager || session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN;
        };
        const forReportsAccess = () => {
            return forAdminOrManager() || (forApprovers() && session.permissions.reports > 0);
        };
        const subscriptionCheck = () => {
            const caches: any = this.getCaches().getState();
            return forAdminOnly() && (SubscriptionRules.trial(session) || SubscriptionRules.free(session)) && (caches.users.status === STATUS.READY && caches.users.active.arr.length > 9);
        };
        const getSubscribeNowTitle = () => {
            if (SubscriptionRules.trial(session) || SubscriptionRules.free(session)) {
                return t('upgrade.to.pro');
            }
            return t('upgrade.to.pro');
        };
        const menuItems = [
            {
                href: this.Urls().getDashboard(),
                faIcon: 'fa fa-home',
                title: t('dashboard'),
                match: (url: string) => {
                    return (url.indexOf(this.Urls().getDashboard()) > -1);
                },
                filter: MenuBase.forEverybody
            },
            {
                href: this.Urls().getCalendar(),
                faIcon: 'far fa-calendar-alt',
                title: t('calendar'),
                match: (url: string) => {
                    return (url.indexOf(this.Urls().getCalendar()) > -1) ||
                        (url.indexOf(this.Urls().getOverview()) > -1);
                },
                filter: MenuBase.forEverybody
            },
            {
                href: this.Urls().getMyLeaves(),
                faIcon: 'fa fa-history',
                title: t('my.leaves'),
                match: (url: string) => {
                    return (url.indexOf(this.Urls().getMyLeaves()) > -1);
                },
                filter: MenuBase.forEverybody
            },
            {
                href: this.Urls().getLeavesToApprove(),
                faIcon: 'far fa-thumbs-up',
                title: t('leaves.to.approve'),
                match: (url: string) => {
                    return (url.indexOf(this.Urls().getLeavesToApprove()) > -1);
                },
                filter: forApprovers
            },
            {
                href: this.Urls().getEmployees(),
                faIcon: 'fas fa-users',
                title: t('employees'),
                match: (url: string) => {
                    return (url.indexOf(this.Urls().getEmployees()) > -1) ||
                        (url.indexOf(this.Urls().getEmployee()) > -1) ||
                        (url.indexOf(this.Urls().getEmployeesInvite()) > -1) ||
                        (url.indexOf(this.Urls().getUnits()) > -1) ||
                        (url.indexOf(this.Urls().getUnitAdd()) > -1) ||
                        (url.indexOf(this.Urls().getUnit()) > -1) ||
                        (url.indexOf(this.Urls().getOffices()) > -1) ||
                        (url.indexOf(this.Urls().getOfficeAdd()) > -1) ||
                        (url.indexOf(this.Urls().getOffice()) > -1) ||
                        (url.indexOf(this.Urls().getSubsidiaries()) > -1) ||
                        (url.indexOf(this.Urls().getSubsidiaryAdd()) > -1) ||
                        (url.indexOf(this.Urls().getSubsidiary()) > -1) ||
                        (url.indexOf(this.Urls().getEmployeesReports()) > -1) ||
                        (url.indexOf(this.Urls().getEmployeesServiceLength()) > -1) ||
                        (url.indexOf(this.Urls().getEmployeesAge()) > -1);
                },
                filter: forAdminOrManager
            },
            {
                href: this.Urls().getReports(),
                faIcon: 'fa fa-chart-bar',
                title: t('reports'),
                match: (url: string) => {
                    return (url.indexOf(this.Urls().getReports()) > -1);
                },
                filter: forReportsAccess
            },
            {
                href: this.Urls().getSupport(),
                faIcon: 'far fa-question-circle',
                title: t('support'),
                match: (url: string) => {
                    return (url.indexOf(this.Urls().getSupport()) > -1);
                },
                filter: MenuBase.forEverybody
            },
            {
                href: this.Urls().getCompany(),
                faIcon: 'far fa-building',
                title: t('company'),
                match: (url: string) => {
                    return (url.indexOf(this.Urls().getCompany()) > -1 ||
                        url.indexOf(this.Urls().getOrderSubscription()) > -1 ||
                        (url.indexOf(this.Urls().getLeaveTypes()) > -1) ||
                        (url.indexOf(this.Urls().getLeaveTypeAdd()) > -1) ||
                        (url.indexOf(this.Urls().getLeaveType()) > -1) ||
                        (url.indexOf(this.Urls().getNotificationRules()) > -1) ||
                        (url.indexOf(this.Urls().getNotificationRuleAdd()) > -1) ||
                        (url.indexOf(this.Urls().getNotificationRule()) > -1)
                    );
                },
                filter: forAdminOnly
            },
            {
                href: this.Urls().getMyAccount(),
                faIcon: 'far fa-user',
                title: t('my.account'),
                match: (url: string) => {
                    return (url.indexOf(this.Urls().getMyAccount()) > -1);
                },
                filter: MenuBase.forEverybody
            },
            {
                href: this.Urls().getSubscribeNow(),
                faIcon: 'fas fa-exclamation-circle text-warning',
                title: getSubscribeNowTitle(),
                match: (url: string) => {
                    return (url.indexOf(this.Urls().getSubscribeNow()) > -1);
                },
                filter: subscriptionCheck
            },
        ];
        return menuItems.filter((menuItem: any) => {
            return menuItem.filter();
        });
    }

    getLogoutMenuItem() {
        const t = this.translate();
        const menuItems = [
            {
                href: this.Urls().getLogout(),
                faIcon: 'fas fa-sign-out-alt',
                title: t('logout'),
                match: (url: string) => {
                    return (url.indexOf(this.Urls().getLogout()) > -1);
                },
                filter: MenuBase.forEverybody
            }
        ];
        return menuItems;
    }

    getMenuItems() {
        return this.getMenuItemsWitoutLogout().concat(this.getLogoutMenuItem());
    }
}