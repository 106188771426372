import {
    createStore,
    //    applyMiddleware 
} from 'redux';
// import { createLogger } from 'redux-logger';
import { TOASTR_ACTIONS } from '../Constants/ActionTypes';

export const initialState = {
    message: undefined,
    type: TOASTR_ACTIONS.CLEAN,
};

const toastr = createStore(
    (state = initialState, action: any) => {
        switch (action.type) {
            case TOASTR_ACTIONS.SUCCESS:
            case TOASTR_ACTIONS.ERROR:
            case TOASTR_ACTIONS.INFO:
            case TOASTR_ACTIONS.CLEAN:
                return {
                    message: action.message,
                    type: action.type,
                };
            default:
                return {
                    message: action.message,
                    type: action.type,
                };
        }
    },
    //    applyMiddleware(createLogger({}))
);

export default toastr;