import * as React from 'react';
import Component from '../../../Component';
import Manager from './Manager';
import Header from '../Header/Header';
import { STATUS } from '../../../General';

export default class Allowances extends Component {
    constructor(props?: any) {
        super(props);
        this.state = { year: this.currentBusinessYear() };
    }

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.leaveTypes.status === STATUS.READY && caches.users.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return <div className="w-100">
            <Header leaveType={this.state.editMode ? this.state.leaveType : this.props.leaveType}
                currentTab={this.props.currentTab}
                onTabChange={(tab: any) => { if (this.props.onTabChange) { this.props.onTabChange(tab); } }}
            />
            <div className="col-12 mb-3">
                <Manager leaveType={this.props.leaveType} year={this.state.year} />
            </div>
        </div>
    }
}