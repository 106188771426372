import * as React from 'react';
import Component from '../Component';
import Manager from './DateRangeField/Manager';
import Placeholder from './Placeholder/Placeholder';
import { Popover, PopoverBody } from 'reactstrap';

export default class DateRangeField extends Component {
    textInput: any;
    constructor(props: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        this.state = {
            startDate: this.props.startDate,
            endDate: this.props.endDate ? this.props.endDate : this.props.startDate.clone().add(7, 'days'),
            previewStartDate: this.props.startDate,
            previewEndDate: this.props.endDate ? this.props.endDate : this.props.startDate.clone().add(7, 'days'),
            selectStartDate: true,
            viewDate: this.props.startDate.clone().startOf('month'),
            minDate: this.props.minDate ? this.props.minDate : moment().startOf('day').clone().add(-1, 'years'),
            maxDate: this.props.maxDate ? this.props.maxDate : moment().startOf('day').clone().add(1, 'years'),
            touched: false,
            rules: this.props.rules,
            valid: null,
            load: this.props.load ? this.props.load : false,
            popoverOpen: false,
            value: ''
        };
        this.handlePreviousMonth = this.handlePreviousMonth.bind(this);
        this.handleNextMonth = this.handleNextMonth.bind(this);
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleSelectRange = this.handleSelectRange.bind(this);
        this.handleSelectSingle = this.handleSelectSingle.bind(this);
    }

    componentDidMount() {
        this.setState({ value: this.getValue(this.state) });
    }

    focusNow() {
        this.textInput.focus();
        this.toggle();
    }

    getInputClass() {
        return 'form-control' + (!this.state.touched ? '' : (this.state.valid ? ' is-valid' : ' is-invalid'));
    }

    toggle() {
        this.setState({ popoverOpen: !this.state.popoverOpen });
    }

    getValue(state: any) {
        const dateFormat = this.getDateFormat()
        return (this.props.range === false) ?
            state.startDate.format(dateFormat) :
            state.startDate.format(dateFormat) + ' - ' + state.endDate.format(dateFormat);
    }

    onChange() {
        this.toggle();
        if (this.props.onChange) {
            if (this.props.range) {
                this.props.onChange({
                    startDate: this.state.startDate,
                    endDate: this.state.endDate
                });
            } else { this.props.onChange({ date: this.state.startDate }); }
        }
        this.setState({ value: this.getValue(this.state) });
    }

    render() {
        const t = this.translate();
        return (
            <div className="w-100">
                <Placeholder
                    placeholder={this.props.placeholder}
                    placeholderTooltip={this.props.placeholderTooltip}
                    placeholderTooltipCid={'IFPTCid' + this.cid}
                    value={this.state.value}
                    onClick={() => { this.focusNow(); }}
                />
                <input
                    key="input"
                    type={this.props.type ? this.props.type : 'text'}
                    className={this.getInputClass() + ' pr-4 bg-white' +
                        ((this.state.value && this.state.value && this.state.value.length > 0) ? ' pt-3 pb-1' : '')
                    }
                    value={this.state.value}
                    ref={(input) => { this.textInput = input; }}
                    onClick={() => { this.toggle(); }}
                    id={'DateRangeField-Input-' + this.cid}
                    readOnly={true}
                />
                {
                    (this.state.touched && !this.state.valid) ? (
                        <small className="float-left invalid-feedback text-left">
                            {this.Validator().validateMessage(this.state, t)}
                        </small>
                    ) : null
                }
                <Popover
                    className={!this.isScreenSizeSmall() ? ' popover-big' : ''}
                    placement="bottom"
                    isOpen={this.state.popoverOpen}
                    target={'DateRangeField-Input-' + this.cid}
                    toggle={() => { this.toggle(); }}
                    trigger='legacy'
                >
                    <PopoverBody className={!this.isScreenSizeSmall() ? ' popoverbody-big' : ''}>
                        <div className="w-100">
                            {
                                this.props.range ?
                                    <Manager range={true} onSelect={this.handleSelectRange} {...this.getManagerOpts()} />
                                    :
                                    <Manager range={false} onSelect={this.handleSelectSingle} {...this.getManagerOpts()} />
                            }
                        </div>
                    </PopoverBody>
                </Popover>
            </div>
        );
    }

    handleSelectRange(date: any) {
        if (this.state.selectStartDate) {
            this.setState({ previewStartDate: date.clone(), previewEndDate: date.clone(), selectStartDate: false, touched: true },
                () => { this.setState({ valid: this.Validator().validate(this.state) }); }
            );
        } else {
            this.setState({ startDate: this.state.previewStartDate, endDate: date.clone(), previewEndDate: date.clone(), selectStartDate: true },
                () => { this.onChange(); this.setState({ valid: this.Validator().validate(this.state), value: this.getValue(this.state) }); }
            );
        }
    }

    handleSelectSingle(date: any) {
        this.setState({ startDate: date.clone(), endDate: date.clone(), touched: true },
            () => { this.onChange(); this.setState({ valid: this.Validator().validate(this.state), value: this.getValue(this.state) }); }
        );
    }

    handlePreviousMonth() { this.setState({ viewDate: this.state.viewDate.add(-1, 'months') }); }
    handleNextMonth() { this.setState({ viewDate: this.state.viewDate.add(1, 'months') }); }
    handleMouseOver(date: any) {
        if (this.state.selectStartDate) {
            this.setState({ previewStartDate: date.clone(), previewEndDate: date.clone() });
        } else { this.setState({ previewEndDate: date.clone() }); }
    }

    getManagerOpts() {
        return {
            startDate: this.state.previewStartDate,
            endDate: this.state.previewEndDate,
            viewDate: this.state.viewDate,
            minDate: this.state.minDate,
            maxDate: this.state.maxDate,
            dayEventsMap: this.props.dayEventsMap,
            onPreviousMonth: this.handlePreviousMonth,
            onNextMonth: this.handleNextMonth,
            onMouseOver: this.handleMouseOver
        };
    }

    shouldComponentUpdate(nextProps: any, nextState: any) {
        if (nextProps.touched) {
            nextState.touched = nextProps.touched;
            nextState.valid = this.Validator().validate(nextState);
        }
        if (!nextProps.startDate.isSame(this.state.startDate) || !nextProps.endDate.isSame(this.state.endDate)) {
            nextState.startDate = nextProps.startDate;
            nextState.endDate = nextProps.endDate;
            nextState.previewStartDate = nextProps.startDate;
            nextState.previewEndDate = nextProps.endDate;
            nextState.selectStartDate = true;
            nextState.viewDate = nextProps.startDate.clone().startOf('month');
            nextState.value = this.getValue(nextState);
        }
        if ((this.props.minDate && !this.props.minDate.isSame(this.state.minDate)) ||
            (this.props.maxDate && !this.props.maxDate.isSame(this.state.maxDate))) {
            nextState.minDate = nextProps.minDate;
            nextState.maxDate = nextProps.maxDate;
        }
        return true;
    }
}