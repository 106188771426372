import * as $ from 'jquery';
import moment from 'moment';
import Component from '../../../Component';
import Line from '../../../Components/Line';
import { fullNameFactory } from '../../../Factories';
import { ORDER, REPORTSTABS, STATUS, USERSTATUS } from '../../../General';
import { findLocaleName, formatForServer } from '../../../Utils';
import Tabs from '../Tabs';
import Download from './Download';
import Filters from './Filters';
import Table from './Table';

export default class Manager extends Component {
    public search: any;
    public computeDays: any;
    public computeRows: any;
    public getLeaveTypes: any;
    constructor(props?: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        this.state = {
            filters: {
                orderBy: { field: 'NAME', order: ORDER.ASC },
                users: { value: [], },
                units: { value: [], },
                approvers: { value: [], },
                startDate: { value: moment().startOf('day').startOf('month') },
                endDate: { value: moment().startOf('day').endOf('month') }
            },
            rows: {
                arr: [],
                status: STATUS.LOADING
            }
        };
        this.search = Manager.search.bind(this);
        this.computeDays = Manager.computeDays.bind(this);
        this.computeRows = Manager.computeRows.bind(this);
        this.getLeaveTypes = Manager.getLeaveTypes.bind(this);
    }

    componentDidMount() { this.search(); }

    public static search(this: any) {
        this.setState({
            leaveTypes: { arr: [] },
            days: { arr: [], month: '' },
            rows: { arr: [], status: STATUS.LOADING }
        }, () => {
            $.ajax({
                type: 'GET',
                contentType: 'application/json',
                url: this.Endpoints().getReportsPresence({
                    filters: this.state.filters
                }),
                dataType: 'json',
                cache: false,
                success: (data: any, textStatus: any, jqXHR: any) => {
                    const caches: any = this.getCaches().getState();
                    this.setState({
                        leaveTypes: { arr: caches.leaveTypes.active.arr },
                        days: {
                            arr: this.computeDays(),
                            month: this.state.filters.startDate.value.format('MMMM'),
                            year: this.state.filters.startDate.value.format('YYYY'),
                        }
                    }, () => { this.setState({ days: this.state.days, leaveTypes: this.state.leaveTypes, rows: { arr: this.computeRows(data), status: STATUS.READY } }); });
                },
                error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                    this.setState({ rows: { arr: [], status: STATUS.ERROR } });
                    this.ajaxError(jqXHR, textStatus, errorThrown);
                },
            });
        });
    }

    public static computeDays(this: any) {
        let rows: any = [];
        const today = this.getMomentWithLocale()();
        const startDate = this.state.filters.startDate.value.clone();
        const endDate = this.state.filters.endDate.value.clone();
        const interval = endDate.diff(startDate, 'days') + 1;
        let currentDate = startDate.clone();
        for (let i = 0; i < interval; i++) {
            const cd = currentDate.clone();
            rows.push({
                ISO8601: formatForServer(cd),
                text: cd.format('D'),
                literal: cd.format('dd'),
                endOfMonth: currentDate.isSame(startDate.endOf('month'), 'day'),
                isoWeekday: currentDate.isoWeekday(),
                today: currentDate.isSame(today, 'day'),
                dayOfWeek: cd.format('dd'),
            });
            currentDate = currentDate.add(1, 'd');
        }
        return rows;
    }

    public static computeRows(this: any, data: any) {
        const caches: any = this.getCaches().getState();
        let users = caches.users;
        let usersDayEvents: any = [];
        const usersMap = users.map;
        let currentCompanyUserId = 0;
        let currentRow: any = undefined;
        const weekDaysAndHours: any = {};
        const session = this.getSession().getState() as any;
        const leaveTypeMap = new Map();
        this.state.leaveTypes.arr.forEach((leaveType: any) => {
            leaveTypeMap.set(leaveType.id, leaveType);
            leaveType.acronym = this.generateAcronym(this.getName(leaveType));
        });
        const getHourAndMinute = (period: any) => {
            const session: any = this.getSession().getState();
            const momentFunc = this.getMomentWithLocale();
            const moment = momentFunc();
            moment.hour(period.hour);
            moment.minute(period.minute);
            return session.companyUser.timeFormat === 'HH:mm' ? moment.format('HH:mm') : moment.format('hh:mm a');
        }
        users.arr.forEach((user: any) => {
            weekDaysAndHours[user.id] = {
                employmentStartDate: moment(user.employmentStartDate ? user.employmentStartDate : '2010-01-01').valueOf(),
                employmentEndDate: user.employmentEndDate ? moment(user.employmentEndDate).valueOf() : null
            };
            user.companyUserSettings.weekDaysAndHours.forEach((day: any) => {
                day.day = day.day === 0 ? 7 : day.day;
                if (!day.working) {
                    weekDaysAndHours[user.id][day.day] = {};
                    weekDaysAndHours[user.id][day.day].amount = 0;
                } else {
                    const moment = this.getMomentWithLocale();
                    const now = moment();
                    const firstPart = Math.round(moment.duration(
                        (now.clone().hour(day.first.end.hour).minute(day.first.end.minute))
                            .diff(now.clone().hour(day.first.start.hour).minute(day.first.start.minute)))
                        .asHours());
                    const secondPart = Math.round(moment.duration(
                        (now.clone().hour(day.second.end.hour).minute(day.second.end.minute))
                            .diff(now.clone().hour(day.second.start.hour).minute(day.second.start.minute)))
                        .asHours());
                    weekDaysAndHours[user.id][day.day] = {};
                    weekDaysAndHours[user.id][day.day].amount = firstPart + secondPart;
                    weekDaysAndHours[user.id][day.day].firstAmount = firstPart;
                    weekDaysAndHours[user.id][day.day].secondAmount = secondPart;
                    weekDaysAndHours[user.id][day.day].schedule = getHourAndMinute(day.first.start) + ' - ' + getHourAndMinute(day.second.end);
                    weekDaysAndHours[user.id][day.day].scheduleFirst = getHourAndMinute(day.first.start) + ' - ' + getHourAndMinute(day.first.end);
                    weekDaysAndHours[user.id][day.day].scheduleSecond = getHourAndMinute(day.second.start) + ' - ' + getHourAndMinute(day.second.end);
                }
            });
        });
        let leaveTypesMap = caches.leaveTypes.map;
        data.filter((row: any) => {
            return usersMap[row[0]].status !== USERSTATUS.DISABLED;
        }).sort((a: any, b: any) => {
            if (a[0] < b[0]) { return -1; }
            if (a[0] > b[0]) { return 1; }
            return 0;
        }).forEach((item: any) => {
            const dayEvent = Manager.createDayEvent(item, caches);
            const isoWeekday = this.getMomentWithLocale()(dayEvent.day).isoWeekday();
            if (dayEvent.leaveTypeId) {
                let dayHours = weekDaysAndHours[item[0]][isoWeekday];
                if (leaveTypesMap[dayEvent.leaveTypeId]?.working) {
                    if (!dayHours.amount) {
                        dayHours = weekDaysAndHours[item[0]][5];
                    }
                    dayEvent.schedule = dayHours.schedule;
                    dayEvent.workingHours = dayHours.amount;
                    if (dayEvent.dayType === 0) {
                        dayEvent.hours = dayHours.amount;
                    }
                    if (dayEvent.dayType === 1) {
                        dayEvent.hours = dayHours.firstAmount;
                        dayEvent.scheduleFirst = dayHours.scheduleFirst;
                        dayEvent.scheduled = dayHours.amount;
                    }
                    if (dayEvent.dayType === 2) {
                        dayEvent.hours = dayHours.secondAmount;
                        dayEvent.scheduleSecond = dayHours.scheduleSecond;
                        dayEvent.scheduled = dayHours.amount;
                    }
                    if (dayEvent.dayType === 3) {
                        dayEvent.scheduled = dayHours.amount;
                    }
                } else {
                    if (dayEvent.dayType === 1) {
                        dayEvent.hours = dayHours.firstAmount;
                        dayEvent.scheduleFirst = dayHours.scheduleFirst;
                        dayEvent.scheduled = dayHours.amount;
                    }
                    if (dayEvent.dayType === 2) {
                        dayEvent.hours = dayHours.secondAmount;
                        dayEvent.scheduleSecond = dayHours.scheduleSecond;
                        dayEvent.scheduled = dayHours.amount;
                    }
                    if (dayEvent.dayType === 3) {
                        dayEvent.scheduled = dayHours.amount;
                    }
                }
            }
            if (currentCompanyUserId !== item[0]) {
                currentCompanyUserId = item[0];
                const employee = users.map[item[0]];
                const leaveTypes: any = {};
                this.state.leaveTypes.arr.forEach((leaveType: any) => {
                    leaveTypes[leaveType.id] = 0;
                });
                currentRow = {
                    employee: {
                        id: employee.id,
                        firstName: employee.firstName,
                        lastName: employee.lastName,
                        fullName: fullNameFactory(employee, session.company.nameFormat),
                        me: (this.getSession().getState() as any).companyUser.id === employee.id,
                    },
                    dayEvents: [dayEvent],
                    leaveTypes: leaveTypes
                };
                usersDayEvents.push(currentRow);
            } else {
                currentRow.dayEvents.push(dayEvent);
            }
        });
        usersDayEvents.forEach((userDayEvent: any) => {
            userDayEvent.dayEvents = this.state.days.arr.map((day: any) => {
                let toReturn = undefined;
                for (let i = 0; i < userDayEvent.dayEvents.length; i++) {
                    if (day.ISO8601 === userDayEvent.dayEvents[i].day) {
                        if (day.endOfMonth) {
                            userDayEvent.dayEvents[i].endOfMonth = true;
                        }
                        const leaveType = userDayEvent?.dayEvents[i]?.leaveTypeId ? caches.leaveTypes.map[userDayEvent.dayEvents[i].leaveTypeId] : null;
                        if (leaveType?.working) {
                            userDayEvent.dayEvents[i].working = true;
                        }
                        if (!leaveType?.working && ((toReturn === undefined) || (toReturn.status > userDayEvent.dayEvents[i].status))) {
                            toReturn = userDayEvent.dayEvents[i];
                        }
                        if (leaveType?.working)
                            toReturn = userDayEvent.dayEvents[i];
                    }
                }
                if (toReturn && !toReturn.isBLE) {
                    return toReturn;
                }
                let isBeforeEmploymentStartDate = moment(day.ISO8601).valueOf() < (weekDaysAndHours[userDayEvent.employee.id].employmentStartDate);
                let isAfterEmploymentEndDate = false;
                if (weekDaysAndHours[userDayEvent.employee.id].employmentEndDate) {
                    isAfterEmploymentEndDate = moment(day.ISO8601).valueOf() > (weekDaysAndHours[userDayEvent.employee.id].employmentEndDate);
                }
                return {
                    day: day.ISO8601, endOfMonth: (day.endOfMonth ? true : undefined),
                    hours: (isBeforeEmploymentStartDate || isAfterEmploymentEndDate ? 0 : weekDaysAndHours[userDayEvent.employee.id][day.isoWeekday].amount),
                    schedule: weekDaysAndHours[userDayEvent.employee.id][day.isoWeekday].schedule
                };
            });
            userDayEvent.dayEvents.forEach((dayEvent: any) => {
                if ((dayEvent.status !== 0 || dayEvent.status !== 1) && dayEvent.leaveTypeId && dayEvent.amount) {
                    userDayEvent.leaveTypes[dayEvent.leaveTypeId] += dayEvent.amount;
                }
                return dayEvent;
            });
        });
        usersDayEvents = usersDayEvents.sort((a: any, b: any) => {
            if (this.state.filters.orderBy.order === ORDER.ASC)
                return a.employee.fullName.toLowerCase().localeCompare(b.employee.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
            else
                return b.employee.fullName.toLowerCase().localeCompare(a.employee.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
        });
        if (session.company.id === 14464) {
            usersDayEvents.forEach((row: any) => {
                row.dayEvents.forEach((dayEvent: any) => {
                    if (dayEvent.isPH) {
                        dayEvent.hours = 'PH';
                        return;
                    }
                    if (dayEvent.leaveTypeId) {
                        if (dayEvent.hours) {
                            if (leaveTypeMap.get(dayEvent.leaveTypeId)?.acronym)
                                dayEvent.hours = 'HWD/' + leaveTypeMap.get(dayEvent.leaveTypeId).acronym;
                        }
                        else
                            if (leaveTypeMap.get(dayEvent.leaveTypeId)?.acronym)
                                dayEvent.hours = leaveTypeMap.get(dayEvent.leaveTypeId).acronym;
                        return;
                    }
                    if (dayEvent.hours === 8) {
                        dayEvent.hours = 'WD';
                        return;
                    }
                    if (dayEvent?.hours > 0) {
                        dayEvent.hours = 'PWD';
                    }
                    if (dayEvent.hours === 4) {
                        dayEvent.hours = 'HWD';
                    }
                })
            })
        }
        return usersDayEvents;
    }

    static createDayEvent(row: any, caches: any) {
        let i = 1;
        // please note first element is the company user id
        const dayEvent: any = {};
        dayEvent.day = row[i++];
        dayEvent.status = row[i++];
        dayEvent.color = row[i++];
        dayEvent.dayType = row[i++];
        let jsonNames = row[i++];
        if (jsonNames) {
            dayEvent.names = JSON.parse(jsonNames);
        }
        dayEvent.background = Manager.dayEventStyle(dayEvent).background;
        dayEvent.hours = dayEvent.dayType === 1 || dayEvent.dayType === 2 ? 4 : 0;
        dayEvent.leaveTypeId = row[i++];
        dayEvent.amount = row[i++];
        dayEvent.isPH = row[i++] ? true : false;
        dayEvent.isBLE = row[i++] ? true : false;
        if (dayEvent.dayType === 3)
            dayEvent.hours = row[i++];
        return dayEvent;
    }

    static dayEventStyle(dayEvent: any) {
        const color = Manager.dayEventColor(dayEvent);
        switch (dayEvent.dayType) {
            case 1:
                return { background: 'linear-gradient(to right, ' + color + ' 50%, #FFFFFF  51%)' };
            case 2:
                return { background: 'linear-gradient(to right, #FFFFFF 50%, ' + color + '  51%)' };
            case 3:
                return { background: 'linear-gradient(to right, #FFFFFF 20%, ' + color + ' 21%  70%, #FFFFFF 20%)' };
            default:
                return { background: color };
        }
    }

    static dayEventColor(dayEvent: any) {
        switch (dayEvent.status) {
            case 0:
                return 'rgba(' + Manager.hexToRgb(dayEvent.color.substring(1)) + ', 1)';
            case 1:
                return 'rgba(' + Manager.hexToRgb(dayEvent.color.substring(1)) + ', 0.3)';
            case 1000:
                return 'rgba(' + Manager.hexToRgb(dayEvent.color.substring(1)) + ', 0.1)';
            case 1001:
                return 'rgba(' + Manager.hexToRgb(dayEvent.color.substring(1)) + ', 0.2)';
            default:
                return undefined;
        }
    }

    static hexToRgb(hex: any) {
        let bigint = parseInt(hex, 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        return r + ',' + g + ',' + b;
    }

    render() {
        return <div className="container-fluid">
            <div className="row">
                <div className="col-12 mb-3">
                    <Tabs currentTab={REPORTSTABS.ATTENDANCE} onChange={(value: any) => { if (this.props.onTabChange) { this.props.onTabChange(value); } }} />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <Line />
                </div>
            </div>
            <Filters
                download={(new Download({ filters: this.state.filters }))}
                defaultStartDate={this.state.filters.startDate.value}
                defaultEndDate={this.state.filters.endDate.value}
                viewMode={this.state.filters.viewMode}
                orderBy={this.state.filters.orderBy}
                onChange={(value: any) => { this.setState({ filters: Object.assign({}, value) }, () => { this.search(); }); }}
                onTabChange={(value: any) => { if (this.props.onTabChange) { this.props.onTabChange(value); } }}
            />
            <div className="row">
                <div className="col-12 mb-3">
                    <Table rows={this.state.rows} days={this.state.days} leaveTypes={this.state.leaveTypes} />
                </div>
            </div>
        </div>
    }

    static getLeaveTypes(this: any) {
        const caches: any = this.getCaches().getState();
        return (this.state.filters.leaveTypes === undefined ||
            this.state.filters.leaveTypes.value === undefined ||
            this.state.filters.leaveTypes.value.length === 0) ?
            caches.leaveTypes.active.arr
            : this.state.filters.leaveTypes.value.map((id: any) => { return caches.leaveTypes.active.map[id]; });
    }
    public getName(leaveType: any) {
        return findLocaleName(leaveType.names, this.language());
    }
    public generateAcronym(name: any) {
        let acronym = '';
        let words = name.split(' ');
        for (let word of words) {
            if (word != null) {
                acronym = acronym + word.substring(0, 1);
            }
        }
        return acronym.toUpperCase();
    }
}