import * as React from 'react';
import Table from './Table';
import LeaveTypesBalanceManager from '../../Dashboard/LeaveTypesBalance/Manager';

export default class Manager extends LeaveTypesBalanceManager {

    render() {
        if (this.state.rows.arr.length === 0) {
            return null as any;
        }
        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Table
                            employee={this.props.employee}
                            rows={this.state.rows}
                            currentYear={this.state.currentYear.value}
                            onRefresh={() => { this.search(); }}
                            // tslint:disable-next-line:max-line-length
                            onChangeYear={(year: any) => { this.setState({ currentYear: { value: year } }, () => { this.search(); }); }}
                        />
                    </div>
                </div>
            </div>
        </div>
    }

    getEmployee() {
        return this.props.employee.id;
    }
}