import * as React from 'react';
import Component from '../../../Component';
import { STATUS } from '../../../General';
import Manager from './Manager';

export default class Integrations extends Component {

    render() {
        const caches: any = this.getCaches().getState();
        const session: any = this.getSession().getState();
        let ready = session.status === STATUS.READY && caches.users.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (<Manager />);
    }
}