import * as React from 'react';
import Component from '../Component';
import A from './A';

export default class AEmployee extends Component {
    render() {
        const employee = this.props.employee;
        return (
            <A faIcon={this.props.fa ? this.props.fa : 'far fa-user'} href={this.Urls().getEmployee(employee.id)} title={employee.fullName}>
                {employee.fullName}
            </A>
        );
    }
}