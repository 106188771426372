import * as React from 'react';
import Component from '../../../Component';
import Item from './Item';
import Error from '../../../Components/Error';
import { STATUS } from '../../../General';
import Anchor from '../../../Components/Anchor';
import { copyArray } from '../../../Utils';
import { leaveTypesBalance } from '../../../Actions/Modal';

export default class Table extends Component {
    static ITEMS = 4;
    render() {
        const rows = this.props.rows;
        switch (rows.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                const rowsComps = ((rows.arr.length > Table.ITEMS && !this.props.showAll) ?
                    copyArray(rows.arr, 0, Table.ITEMS - 1) : rows.arr).map((row: any) => {
                        return (
                            <Item key={'DashBoardBalanceTable-' + row.leaveType.id} row={row} />
                        );
                    });
                return (
                    <table
                        className="table table-sm w-100 m-0 p-0"
                        style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                        <thead>
                            <tr>
                                <th className="border-top-0">{t('balance')}</th>
                                <th className="border-top-0 text-right">
                                    <span className="font-weight-normal d-md-none" style={{ fontSize: 'x-small' }}>{t('used')}</span>
                                    <span className="font-weight-normal d-none d-md-block">{t('used')}</span>
                                </th>
                                <th className="border-top-0 text-right">
                                    <span className="font-weight-normal d-md-none" style={{ fontSize: 'x-small' }}>{t('available')}</span>
                                    <span className="font-weight-normal d-none d-md-block">{t('available')}</span>
                                </th>
                                <th className="border-top-0 text-right">
                                    <span className="font-weight-normal d-md-none" style={{ fontSize: 'x-small' }}>{t('allowance')}</span>
                                    <span className="font-weight-normal d-none d-md-block">{t('allowance')}</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rowsComps}
                            {
                                (rows.arr.length > Table.ITEMS && !this.props.showAll) ?
                                    (
                                        <tr>
                                            <td colSpan={4}>
                                                <div className="float-right">
                                                    <Anchor
                                                        className="text-muted"
                                                        title={t('more')}
                                                        onClick={() => { leaveTypesBalance(this.getModal(), { rows: rows }); }}
                                                    >
                                                        {t('more')}
                                                    </Anchor>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                    :
                                    null
                            }
                        </tbody>
                    </table>
                );
            }
            default:
                return (<Error />);
        }
    }
}