import * as React from 'react';
import Component from '../Component';
import { NavLink } from 'reactstrap';

export default class NavLinkExt extends Component {
    render() {
        return (
            <NavLink
                href={this.props.href}
                title={this.props.title}
                className={this.props.className + (!this.props.active ? ' font-weight-light' : '')}
                onClick={(event: any) => {
                    event.preventDefault();
                    if (this.props.href) { this.goTo(this.props.href); }
                }}
            >
                {this.props.faIcon ? (<i className={this.props.faIcon + ' mr-1 fa-fw'} aria-hidden="true" />) : null}
                {this.props.children}
            </NavLink >
        );
    }
}