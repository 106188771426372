import * as React from 'react';
import Component from '../../Component';

export default class Display extends Component {

    render() {
        const t = this.translate();
        const activateCompany = this.props.activateCompany;
        return (
            <div>
                <h5>
                    {t('welcome')}
                    <strong className="ml-1">
                        {activateCompany.companyUser.firstName}
                        <span className="ml-1" />
                        {activateCompany.companyUser.lastName}
                    </strong>,
                        </h5>
                <p>
                    {t('thank.you.for.registering.your.company')}
                    {' ' + activateCompany.name + ' '}
                    {t('on.the.platform')}
                </p>
            </div>
        );
    }
}