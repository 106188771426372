import * as React from 'react';
import Component from '../../../../Component';
import Date from '../../../../Components/Date';

export default class Table extends Component {

    render() {
        const moment = this.getMomentWithLocale();
        const t = this.translate();
        const session: any = this.getSession().getState();
        const lang = session?.company?.country === 'RO' ? 'ro' : this.language();
        const items = this.props.orders.arr.map((order: any) => {
            return (
                <tr key={'Subscription-Orders' + order.id}>
                    <td>
                        {(order.draftGuid && !order.invoiceGuid) ?
                            <a className="card-link" href={this.Urls().getInvoice(order.draftGuid, lang)} title={t('draft.invoice')} target="_blank" rel="noopener noreferrer">
                                {t('draft.invoice')}
                            </a>
                            : null}
                        {(order.invoiceGuid) ?
                            <a className="card-link" href={this.Urls().getInvoice(order.invoiceGuid, lang)} title={t('invoice')} target="_blank" rel="noopener noreferrer">
                                {t('invoice')}
                            </a>
                            : null}
                    </td>
                    <td>
                        <span className="float-right"><Date date={moment(order.createdOn)} /></span>
                    </td>
                </tr>
            );
        });
        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <table className="table table-sm table-hover w-100 m-0 p-0">
                    <thead>
                        <tr>
                            <th colSpan={2} className="border-0">
                                {t('invoices')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items}
                    </tbody>
                </table>
            </div>
        </div>
    }
}