import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import Display from './Display';
import Form from './Form';
import { getURLParameter } from '../../../Utils';
import { STATUS } from '../../../General';

export default class Profile extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            editMode: false,
            employee: {
                value: undefined,
                status: STATUS.LOADING
            },
        };
    }

    public componentDidMount() {
        this.getEmployee();
        /*        $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getEmployeeLeaveTypesRefix(getURLParameter('id')),
                    dataType: 'json',
                    cache: false,
                    success: (data, textStatus, jqXHR) => {
                    }
                });*/
    }

    getEmployee() {
        this.setState(
            { employee: { value: this.state.employee.value, status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getEmployeePersonalDetails(getURLParameter('id')),
                    dataType: 'json',
                    cache: false,
                    success: (data, textStatus, jqXHR) => {
                        data.office = data.calendar;
                        this.setState({
                            employee: {
                                value: data,
                                status: STATUS.READY
                            }
                        });
                    },
                    error: (jqXHR, textStatus, errorThrown) => {
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                        this.setState({
                            employee: {
                                value: undefined,
                                status: STATUS.ERROR
                            },
                        });
                    },
                });
            }
        );
    }


    render() {
        const session: any = this.getSession().getState();
        const caches: any = this.getCaches().getState();
        let ready = session.status === STATUS.READY &&
            this.state.employee.status === STATUS.READY &&
            caches.users.status === STATUS.READY &&
            caches.units.status === STATUS.READY &&
            caches.offices.status === STATUS.READY &&
            caches.timezones.status === STATUS.READY &&
            caches.countries.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return <div>
            {this.state.editMode ? <Form
                employee={this.state.employee.value}
                onRefresh={() => {
                    if (this.props.onRefresh) { this.props.onRefresh(); }
                    this.setState({ editMode: !this.state.editMode });
                }}
            />
                : <Display
                    employee={this.state.employee.value}
                    onEdit={() => { this.setState({ editMode: !this.state.editMode }); }}
                />}
        </div>
    }
}