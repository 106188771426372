import * as React from 'react';
import Component from '../../../Component';
import Item from './Item';
import Error from '../../../Components/Error';
import { STATUS } from '../../../General';
import A from '../../../Components/A';
import { copyArray } from '../../../Utils';

export default class Table extends Component {
    static ITEMS = 2;
    render() {
        const leaves = this.props.leaves;
        switch (leaves.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                const leavesComps = ((leaves.arr.length > Table.ITEMS) ?
                    copyArray(leaves.arr, 0, Table.ITEMS - 1) : leaves.arr).map((leave: any) => {
                        return <Item
                            key={'DashBoardLeavesToApproveTable-' + leave.id}
                            leave={leave}
                            onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }}
                        />
                    });
                return <table className="table table-sm w-100 m-0 p-0">
                    <thead>
                        <tr>
                            <th className="border-top-0">
                                {t('pending.approval.requests')}
                                <span className="float-right font-weight-light text-secondary">
                                    {leaves.arr.length}
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {leavesComps}
                        {(leaves.arr.length > Table.ITEMS) ?
                            <tr>
                                <td>
                                    <div className="float-right">
                                        <A
                                            className="text-muted"
                                            href={this.Urls().getLeavesToApprove({
                                                filters: this.props.filters
                                            })}
                                            title={t('more')}
                                        >
                                            {t('more')}
                                        </A>
                                    </div>
                                </td>
                            </tr>
                            : undefined}
                    </tbody>
                </table>
            }
            default:
                return (<Error />);
        }
    }
}