import * as React from 'react';
import Component from '../../../Component';
import Age from '../../../Components/Age';
import Cog from '../../../Components/Cog';
import Date from '../../../Components/Date';
import { COMPANYUSERROLES } from '../../../General';

export default class Display extends Component {

    render() {
        const moment = this.getMomentWithLocale();
        const now = moment();
        const t = this.translate();
        const employee = this.props.employee;
        const session: any = this.getSession().getState();
        const isAdmin = () => {
            return session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN;
        };
        return (
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <div className="row">
                        {isAdmin() ? <div className="col-12 mb-3">
                            <Cog onClick={() => { if (this.props.onEdit) { this.props.onEdit(); } }} />
                        </div> : <></>}
                        <div className="col-12">
                            <table className="table table-sm w-100 mb-0">
                                <tbody>
                                    <tr>
                                        <td>
                                            {t('birthday')}
                                            {employee.birthDate ? <span className="float-right"><Date date={moment(employee.birthDate)} /></span> : null}
                                        </td>
                                    </tr>
                                    {employee.birthDate ?
                                        <tr>
                                            <td>
                                                {t('age')}
                                                <span className="float-right">
                                                    {Age.computedAgeLiteral(moment(employee.birthDate).startOf('day'), now.startOf('day'), t, moment(employee.birthDate).startOf('day').format('MM-DD') === now.startOf('day').format('MM-DD'))}
                                                </span>
                                            </td>
                                        </tr> : null}
                                    <tr>
                                        <td>
                                            {t('gender')}
                                            {employee.gender ? <span className="float-right">{t('EMPLOYEEGENDER.' + employee.gender)}</span> : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('personal.phone.number')}
                                            {employee.personalPhoneNumber && employee.personalPhoneNumber.length > 0 ? <span className="float-right">{employee.personalPhoneNumber}</span> : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('emergency.phone.number')}
                                            {employee.emergencyPhoneNumber && employee.emergencyPhoneNumber.length > 0 ? <span className="float-right">{employee.emergencyPhoneNumber}</span> : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('next.of.kin.name')}
                                            {employee.nextOfKinName && employee.nextOfKinName.length > 0 ? <span className="float-right">{employee.nextOfKinName}</span> : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('next.of.kin.relationship')}
                                            {employee.nextOfKinRelationship && employee.nextOfKinRelationship.length > 0 ? <span className="float-right">{employee.nextOfKinRelationship}</span> : null}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('next.of.kin.phone.number')}
                                            {employee.nextOfKinPhoneNumber && employee.nextOfKinPhoneNumber.length > 0 ? <span className="float-right">{employee.nextOfKinPhoneNumber}</span> : null}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    public humanizeDuration(momentDuration: any) {
        const t = this.translate();
        let duration: any = {
            years: momentDuration.years(),
            months: momentDuration.months(),
            days: momentDuration.days(),
        }
        if (duration.years === 1) {
            duration.years = t('one.year');
        }
        if (duration.years > 1 || duration.years < 0) {
            duration.years = duration.years + ' ' + t('years');
        }
        if (duration.months === 1) {
            duration.months = t('one.month');
        }
        if (duration.months > 1 || duration.months < 0) {
            duration.months = duration.months + ' ' + t('months');
        }
        if (duration.days === 1) {
            duration.days = t('one.day');
        }
        if (duration.days > 1 || duration.days < 0) {
            duration.days = duration.days + ' ' + t('days');
        }
        duration.years = duration.years ? duration.years + ' ' : '';
        duration.months = duration.months ? duration.months + ' ' : '';
        duration.days = duration.days ? duration.days + ' ' : '';
        return (duration.years + duration.months + duration.days).toLowerCase();
    }
}