import * as React from 'react';
import Urls from '../../Urls';
import Component from '../../Component';

export default class OfficeButton extends Component {
    render() {
        return (
            <a
                href={Urls.getOffice365ClientAuthUrl()}
                className={'btn btn-outline-info btn-block' + (this.isScreenSizeSmall() ? ' btn-sm' : '')}
            >
                <i className="fab fa-windows fa-fw pr-2" aria-hidden="true" />
                Office 365
            </a>
        );
    }
}