import * as $ from 'jquery';
import * as React from 'react';
import { doFetchUsersFromServer } from '../../../Actions/Caches';
import { doRefreshSession } from '../../../Actions/Session';
import Component from '../../../Component';
import Close from '../../../Components/Close';
import InputField from '../../../Components/InputField';
import SelectField from '../../../Components/SelectField';
import Submit from '../../../Components/Submit';
import ToggleField from '../../../Components/ToggleField';
import WorkingDaysAndHours from '../../../Components/WorkingDaysAndHours/WorkingDaysAndHours';
import { countriesOptionsFactory, daysForMonthOptionsFactory, monthsOptionsFactory, nameFormatOptionsFactory, officesOptionsFactory } from '../../../SelectOptionsFactories';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            countries: {
                value: this.props.companySettings.countries,
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            office: {
                value: [this.props.companySettings.office.id],
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            nameFormat: {
                value: [this.props.companySettings.nameFormat],
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            subsidiariesEnabled: {
                value: this.props.companySettings.subsidiariesEnabled,
            },
            month: {
                value: [this.props.companySettings.businessYearStart.month],
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            day: {
                value: [this.props.companySettings.businessYearStart.day],
                touched: false,
                rules: this.Rules().BasicSelectRules(),
            },
            email: {
                value: this.props.companySettings.contact.email,
                touched: false,
                rules: this.Rules().BasicEmailRules()
            },
            fullName: {
                value: this.props.companySettings.contact.fullName,
                touched: false,
                rules: this.Rules().BasicNameRules()
            },
            phone: {
                value: this.props.companySettings.contact.phone,
                touched: false,
                rules: this.Rules().BasicNameRules()
            },
            weekDaysAndHours: {
                value: this.props.companySettings.weekDaysAndHours
            },
            save: {
                pleaseWait: false
            }
        };
    }

    render() {
        const t = this.translate();
        const caches: any = this.getCaches().getState();
        const monthsOptions = monthsOptionsFactory();
        const daysOptions = daysForMonthOptionsFactory(this.state.month.value[0]);
        const officesOptions = officesOptionsFactory(caches.offices.arr);
        const countriesOptions = countriesOptionsFactory(caches.countries.arr[this.language()]);
        const company = (this.getSession().getState() as any).company;
        const nameFormatOptions = nameFormatOptionsFactory(t);
        return (
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <form onSubmit={(event: any) => { this.submit(event); }}>
                        <fieldset>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <Close onClick={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }} />
                                </div>
                                <div className="col-12 mb-3">
                                    <h5 className="m-0">{company.name}</h5>
                                </div>
                                <div className="col-12 mb-3">
                                    <SelectField
                                        defaultValue={this.state.countries.value}
                                        touched={this.state.countries.touched}
                                        options={countriesOptions}
                                        multiple={false}
                                        rules={this.state.countries.rules}
                                        placeholder={t('countries')}
                                        onChange={(value: any) => {
                                            this.setState({ countries: Object.assign({}, this.state.countries, { value: value }) });
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <SelectField
                                        defaultValue={this.state.office.value}
                                        touched={this.state.office.touched}
                                        options={officesOptions}
                                        multiple={false}
                                        placeholder={t('headquarters')}
                                        rules={this.state.office.rules}
                                        onChange={(value: any) => {
                                            this.setState({ office: Object.assign({}, this.state.office, { value: value }) });
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <SelectField
                                        defaultValue={this.state.nameFormat.value}
                                        touched={this.state.nameFormat.touched}
                                        options={nameFormatOptions}
                                        multiple={false}
                                        placeholder={t('name.format')}
                                        rules={this.state.office.rules}
                                        onChange={(value: any) => {
                                            this.setState({ nameFormat: Object.assign({}, this.state.nameFormat, { value: value }) });
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <ToggleField
                                        defaultValue={this.state.subsidiariesEnabled.value}
                                        onChange={(value: any) => { this.setState({ subsidiariesEnabled: Object.assign({}, this.state.subsidiariesEnabled, { value: value }) }); }}
                                    >
                                        {t('subsidiaries')}
                                    </ToggleField>
                                </div>
                                <div className="col-12 mb-3">
                                    <div>{t('business.year.starts.on')}</div>
                                </div>
                                <div className="col-12 col-sm-6 mb-3">
                                    <SelectField
                                        defaultValue={this.state.month.value}
                                        touched={this.state.month.touched}
                                        options={monthsOptions}
                                        rules={this.state.month.rules}
                                        placeholder={t('month')}
                                        onChange={(value: any) => {
                                            this.setState({ month: Object.assign({}, this.state.month, { value: value }) });
                                        }}
                                    />
                                </div>
                                <div className="col-12 col-sm-6 mb-3">
                                    <SelectField
                                        defaultValue={this.state.day.value}
                                        touched={this.state.day.touched}
                                        options={daysOptions}
                                        rules={this.state.day.rules}
                                        placeholder={t('day')}
                                        onChange={(value: any) => {
                                            this.setState({ day: Object.assign({}, this.state.day, { value: value }) });
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <div>{t('contact.information')}</div>
                                </div>
                                <div className="col-12 mb-3">
                                    <InputField
                                        defaultValue={this.state.email.value}
                                        touched={this.state.email.touched}
                                        rules={this.state.email.rules}
                                        type="text"
                                        placeholder={t('email')}
                                        onChange={(value: any) => {
                                            // tslint:disable-next-line:max-line-length
                                            this.setState({ email: Object.assign({}, this.state.email, { value: value }) });
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <InputField
                                        defaultValue={this.state.fullName.value}
                                        touched={this.state.fullName.touched}
                                        rules={this.state.fullName.rules}
                                        type="text"
                                        placeholder={t('name')}
                                        onChange={(value: any) => {
                                            // tslint:disable-next-line:max-line-length
                                            this.setState({ fullName: Object.assign({}, this.state.fullName, { value: value }) });
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <InputField
                                        defaultValue={this.state.phone.value}
                                        touched={this.state.phone.touched}
                                        rules={this.state.phone.rules}
                                        type="text"
                                        placeholder={t('phone')}
                                        onChange={(value: any) => {
                                            // tslint:disable-next-line:max-line-length
                                            this.setState({ phone: Object.assign({}, this.state.phone, { value: value }) });
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    {t('working.days.and.hours')}
                                </div>
                                <WorkingDaysAndHours
                                    weekDaysAndHours={this.state.weekDaysAndHours.value}
                                    onChange={(value: any) => {
                                        // tslint:disable-next-line:max-line-length
                                        this.setState({ weekDaysAndHours: Object.assign({}, this.state.weekDaysAndHours, { value: value.weekDaysAndHours }) });
                                    }}
                                />
                                <div className="col-12 mt-3 mb-3">
                                    <Submit
                                        faIcon="far fa-hdd"
                                        pleaseWait={this.state.save.pleaseWait}
                                    >
                                        {t('save')}
                                    </Submit>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        );
    }

    isFormValid() {
        return this.Validator().validate(this.state.email)
            && this.Validator().validate(this.state.fullName)
            && this.Validator().validate(this.state.phone);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                countries: Object.assign({}, this.state.countries, { touched: true }),
                office: Object.assign({}, this.state.office, { touched: true }),
                nameFormat: Object.assign({}, this.state.nameFormat, { touched: true }),
                subsidiariesEnabled: Object.assign({}, this.state.subsidiariesEnabled, { touched: true }),
                month: Object.assign({}, this.state.month, { touched: true }),
                day: Object.assign({}, this.state.day, { touched: true }),
                email: Object.assign({}, this.state.email, { touched: true }),
                fullName: Object.assign({}, this.state.fullName, { touched: true }),
                phone: Object.assign({}, this.state.phone, { touched: true })
            },
            () => {
                if (this.isFormValid()) {
                    this.save();
                }
            }
        );
    }

    save() {
        const t = this.translate();
        const company = Object.assign({}, this.props.companySettings);
        delete company.office;
        company.contact.email = this.state.email.value;
        company.contact.fullName = this.state.fullName.value;
        company.contact.phone = this.state.phone.value;
        company.businessYearStart.month = this.state.month.value[0];
        company.businessYearStart.day = this.state.day.value[0];
        company.calendar.id = this.state.office.value[0];
        company.countries = this.state.countries.value;
        company.nameFormat = this.state.nameFormat.value[0];
        company.subsidiariesEnabled = this.state.subsidiariesEnabled.value;
        company.weekDaysAndHours = this.state.weekDaysAndHours.value;
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getCompanySettingsUpdate(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify(company),
            beforeSend: () => {
                this.setState({ save: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('the.company.settings.were.saved'));
                doRefreshSession(this.getSession());
                doFetchUsersFromServer(this.getCaches(), this.getSession().getState());
                if (this.props.onRefresh) {
                    this.props.onRefresh();
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.generalErrorToastr();
            },
        });
    }
}