import * as React from 'react';
import { adjustEmployeeLeaveTypeAllowance } from '../../../Actions/Modal';
import Component from '../../../Component';
import Anchor from '../../../Components/Anchor';
import Cog from '../../../Components/Cog';
import LeaveTypeName from '../../../Components/LeaveType/LeaveTypeName';

export default class Item extends Component {

    constructor(props?: any) {
        super(props);
        this.state = { isAllowanceExpanded: false, isEarnedAllowanceExpanded: false, };
        this.toggleAllowance = this.toggleAllowance.bind(this);
        this.toggleEarnedAllowance = this.toggleEarnedAllowance.bind(this);
    }

    toggleAllowance() {
        this.setState({ isAllowanceExpanded: !this.state.isAllowanceExpanded });
    }

    toggleEarnedAllowance() {
        this.setState({ isEarnedAllowanceExpanded: !this.state.isEarnedAllowanceExpanded });
    }

    render() {
        const t = this.translate();
        let leaveTypePerYear = this.props.leaveTypePerYear;
        const year = leaveTypePerYear.data[4];
        const allowance = leaveTypePerYear.data[9];
        const adjustment = leaveTypePerYear.data[10];
        const receivedFromLastYear = leaveTypePerYear.data[11];
        const transferredToNextYear = leaveTypePerYear.data[12];
        const totalYearDays = leaveTypePerYear.data[15];
        const pastYearDays = leaveTypePerYear.data[16];
        const isCurrentYear = this.currentBusinessYear() === year;
        return <>
            <tr key="Item">
                <td rowSpan={2}>
                    <LeaveTypeName leaveType={leaveTypePerYear.leaveType} />
                </td>
                <td className="text-right">
                    <div>
                        {adjustment || receivedFromLastYear || transferredToNextYear ?
                            <Anchor onClick={this.toggleAllowance}><strong>{this.getEmployeeTotalAllowance(allowance, adjustment, receivedFromLastYear)}</strong></Anchor>
                            : this.getEmployeeTotalAllowance(allowance, adjustment, receivedFromLastYear)}
                    </div>
                </td>
                <td className="text-right" style={{ width: '1%' }}>
                    <Cog
                        title={t('adjust.allowance')}
                        onClick={() => {
                            adjustEmployeeLeaveTypeAllowance(
                                this.getModal(),
                                {
                                    leaveType: leaveTypePerYear.leaveType,
                                    employee: this.props.employee,
                                    leaveTypePerYear: {
                                        supplemental: leaveTypePerYear.data[10],
                                        year: leaveTypePerYear.data[4]
                                    },
                                    callback: () => { if (this.props.onRefresh) { this.props.onRefresh(); } }
                                },
                            );
                        }}
                    />
                </td>
            </tr>
            <tr key="Days"><td colSpan={2} className="border-0 text-right pt-0 pb-0">
                <small>{t('days.per.year')}</small>
            </td></tr>
            {this.state.isAllowanceExpanded ? <>
                <tr key="default.allowance">
                    <td className="border-0 text-left pt-0 pb-0">
                        {t('default.allowance')}
                    </td>
                    <td className="border-0 text-right pt-0 pb-0">{allowance}</td>
                    <td className="border-0 pt-0 pb-0"></td></tr>
                {adjustment ? <tr key="adjustment">
                    <td className="border-0 text-left pt-0 pb-0">
                        {t('adjustment')}
                    </td>
                    <td className="border-0 text-right pt-0 pb-0">{adjustment}</td>
                    <td className="border-0 pt-0 pb-0"></td></tr>
                    : null}
                {receivedFromLastYear ?
                    <tr key="transferred.from">
                        <td className="border-0 text-left pt-0 pb-0">
                            {t('transferred.from') + ' ' + (year - 1)}
                        </td>
                        <td className="border-0 text-right pt-0 pb-0">{receivedFromLastYear}</td>
                        <td className="border-0 pt-0 pb-0"></td></tr>
                    : null}
                {transferredToNextYear ?
                    <tr key="transferred.to">
                        <td className="border-0 text-left pt-0 pb-0">
                            <small>{t('transferred.to') + ' ' + (year + 1)}</small>
                        </td>
                        <td className="border-0 text-right pt-0 pb-0"><small>{transferredToNextYear ? transferredToNextYear : 0}</small></td>
                        <td className="border-0 pt-0 pb-0"></td></tr>
                    : null}
            </>
                : null}
            {leaveTypePerYear.leaveType.showEarnedAllowance && isCurrentYear && false ?
                <>
                    <tr key="DaysEarned"><td colSpan={4} className="text-right pt-0 pb-0">
                    </td></tr>
                    <tr key="DaysEarned"><td colSpan={2} className="border-0 text-right pt-0 pb-0">
                        {adjustment || receivedFromLastYear || transferredToNextYear ?
                            <Anchor onClick={this.toggleEarnedAllowance}><strong>{this.getEmployeeEarnedTotalAllowance(allowance, adjustment, receivedFromLastYear, pastYearDays, totalYearDays)}</strong></Anchor>
                            : <>{this.getEmployeeEarnedTotalAllowance(allowance, adjustment, receivedFromLastYear, pastYearDays, totalYearDays)}</>
                        }
                    </td></tr>
                    <tr><td colSpan={3} className="border-0 text-right pt-0 pb-0">
                        <small>{t('earned.days')}</small>
                    </td></tr>
                    {this.state.isEarnedAllowanceExpanded ? <>
                        <tr key="from.allowance">
                            <td className="border-0 text-left pt-0 pb-0">
                                {t('from.allowance')}
                            </td>
                            <td className="border-0 text-right pt-0 pb-0">{this.getEmployeeEarnedAllowance(allowance, pastYearDays, totalYearDays)}</td>
                            <td className="border-0 pt-0 pb-0"></td></tr>
                        {adjustment ? <tr key="adjustment">
                            <td className="border-0 text-left pt-0 pb-0">
                                {t('adjustment')}
                            </td>
                            <td className="border-0 text-right pt-0 pb-0">{adjustment}</td>
                            <td className="border-0 pt-0 pb-0"></td></tr>
                            : null}
                        {receivedFromLastYear ?
                            <tr key="transferred.from">
                                <td className="border-0 text-left pt-0 pb-0">
                                    {t('transferred.from') + ' ' + (year - 1)}
                                </td>
                                <td className="border-0 text-right pt-0 pb-0">{receivedFromLastYear}</td>
                                <td className="border-0 pt-0 pb-0"></td></tr>
                            : null}
                        {transferredToNextYear ?
                            <tr key="transferred.to">
                                <td className="border-0 text-left pt-0 pb-0">
                                    <small>{t('transferred.to') + ' ' + (year + 1)}</small>
                                </td>
                                <td className="border-0 text-right pt-0 pb-0"><small>{transferredToNextYear ? transferredToNextYear : 0}</small></td>
                                <td className="border-0 pt-0 pb-0"></td></tr>
                            : null}
                    </>
                        : null}
                </>
                : null}
        </>
    }

    getEmployeeTotalAllowance(allowance: any, adjustment: any, receivedFromLastYear: any) {
        const value = allowance + adjustment + (receivedFromLastYear ? receivedFromLastYear : 0);
        return value;
    }

    getEmployeeEarnedTotalAllowance(yearlyAllowance: any, adjustment: any, receivedFromLastYear: any, pastDaysFromStartOfYear: any, totalYearDays: any) {
        if (pastDaysFromStartOfYear && pastDaysFromStartOfYear < 1) {
            return 0;
        }
        const value = this.getEmployeeEarnedAllowance(totalYearDays < 365 ? yearlyAllowance + adjustment : yearlyAllowance, pastDaysFromStartOfYear, totalYearDays) + (totalYearDays < 365 ? 0 : adjustment) + (receivedFromLastYear ? receivedFromLastYear : 0);
        if (value < 0) return 0;
        return value;
    }

    getEmployeeEarnedAllowance(yearlyAllowance: any, pastDaysFromStartOfYear: any, totalYearDays: any) {
        const value = Math.round(yearlyAllowance * pastDaysFromStartOfYear / totalYearDays);
        return value;
    }
}