import * as React from 'react';
import Component from '../../../Component';
import Item from './Item';

export default class Table extends Component {

    render() {
        //        const t = this.translate();
        const leaves = this.props.otherLeaves.value;
        const leavesComps = leaves.map((leave: any) => {
            return (<Item key={'OtherLeavesTable-' + leave.companyUser.id + '-' + leave.startDate + leave.endDate} leave={leave} />);
        });

        return (
            <table className="table table-sm table-hover w-100 mb-0">
                {this.thead()}
                <tbody>
                    {leavesComps}
                </tbody>
            </table>
        );
    }

    thead() {
        const t = this.translate();
        return (
            <thead>
                <tr>
                    <th className="border-top-0">
                        {t('other.leaves.on.the.same.period')}
                    </th>
                </tr>
            </thead>
        );
    }
}