import * as React from 'react';
import Component from '../../../Component';
import Cog from '../../../Components/Cog';
import { calendarPermissionsOptions, reportsPermissionsOptionsForManagers, reportsPermissionsOptionsForOfficeManagers } from '../../../SelectOptionsFactories';

export default class Display extends Component {

    render() {
        const t = this.translate();
        const calendarMap: any = {};
        const session: any = this.getSession().getState();
        calendarPermissionsOptions(t, session).forEach((option: any) => { calendarMap[option.value] = option.text; });
        const reportsForManagersMap: any = {};
        const reportsForOfficeManagersMap: any = {};
        reportsPermissionsOptionsForManagers(t, session).forEach((option: any) => { reportsForManagersMap[option.value] = option.text; });
        reportsPermissionsOptionsForOfficeManagers(t).forEach((option: any) => { reportsForOfficeManagersMap[option.value] = option.text; });
        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Cog onClick={() => { if (this.props.onEdit) { this.props.onEdit(); } }} />
                    </div>
                    <div className="col-12">
                        <table className="table table-sm w-100 m-0 p-0">
                            <tbody>
                                <tr>
                                    <td className="border-top-0">
                                        <strong>{t('calendar.access.for.employees')}:</strong>
                                    </td>
                                    <td className="border-top-0">
                                        <div className="float-right">
                                            {calendarMap[this.props.permissions.calendar]}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>{t('reports.access.for.managers')}:</strong>
                                    </td>
                                    <td>
                                        <div className="float-right">
                                            {reportsForManagersMap[this.props.permissions.reportsForManagers]}
                                        </div>
                                    </td>
                                </tr>
                                <tr className="d-none">
                                    <td>
                                        <strong>{t('reports.access.for.office.managers')}:</strong>
                                    </td>
                                    <td>
                                        <div className="float-right">
                                            {reportsForOfficeManagersMap[this.props.permissions.reportsForOfficeManagers]}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>{t('anonymize.leave.types')}</strong>
                                    </td>
                                    <td>
                                        <span className="float-right">
                                            {this.getBooleanString(this.props.permissions.anonymize).toLowerCase()}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    }

    getBooleanString(value: any) {
        const t = this.translate();
        return value ? t('yes') : t('no');
    }
}