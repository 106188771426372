import * as React from 'react';
import Component from '../../Component';
import CompanyView from './CompanyView/CompanyView';
import YearView from './YearView/YearView';
import { STATUS } from '../../General';
import { CALENDARTABS } from '../../General';

export default class Calendar extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            currentTab: this.getCurrentTab(CALENDARTABS.COMPANY, CALENDARTABS),
        };
    }

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.units.status === STATUS.READY && caches.users.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return this.getTab();
    }

    getTab() {
        switch (this.state.currentTab) {
            case CALENDARTABS.COMPANY:
                return <CompanyView
                    currentTab={this.state.currentTab}
                    onChangeTab={(value: any) => { this.setState({ currentTab: value }); }}
                />
            default:
                return <YearView
                    currentTab={this.state.currentTab}
                    onChangeTab={(value: any) => { this.setState({ currentTab: value }); }}
                />
        }
    }
}