import * as React from 'react';
import Component from '../../Component';
import Fa from '../Fa';
import Month from './Month/Month';

export default class Year extends Component {
    render() {
        const moment = this.getMomentWithLocale();
        const year = this.props.year;
        const months = [];
        let yearStart = moment(year + '-01-01');
        for (let i = 0; i < 12; i++) {
            months.push(this.buildMonthData(yearStart));
            yearStart = yearStart.add(1, 'months');
        }
        return (
            <div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <h3 className="year float-left m-0">{year}</h3>
                        <span className="float-right">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <button type="button"
                                        onClick={() => { if (this.props.onChangeYear) { this.props.onChangeYear(year - 1); } }}
                                        className="btn btn-outline-primary border-0"
                                    >
                                        <Fa fa="fas fa-play fa-rotate-180 fa-fw" />
                                    </button>
                                </div>
                                <div className="input-group-append">
                                    <button type="button"
                                        onClick={() => { if (this.props.onChangeYear) { this.props.onChangeYear(year + 1); } }}
                                        className="btn btn-outline-primary border-0"
                                    >
                                        <Fa fa="fas fa-play fa-fw" />
                                    </button>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div className="row">
                    {
                        months.map((month) => {
                            return <div key={'Month' + month.year + '-' + month.month} className="col-12 col-md-6 col-lg-6 col-xl-4 mb-3">
                                <Month month={month} />
                            </div>
                        })
                    }
                </div>
            </div>
        );
    }

    buildMonthData(month: any) {
        const moment = this.getMomentWithLocale();
        var weekDaysMin = moment.weekdaysMin();
        var firstDayOfWeek = +moment.localeData().firstDayOfWeek();
        if (firstDayOfWeek > 0) {
            weekDaysMin = weekDaysMin.slice(firstDayOfWeek, weekDaysMin.length)
                .concat(weekDaysMin.slice(0, firstDayOfWeek));
        }
        const monthData: any = {};
        monthData.weeks = ([] as any);
        monthData.year = month.year();
        monthData.month = month.month();
        monthData.monthLiteral = month.format('MMMM');
        monthData.daysLiteral = weekDaysMin;
        const today = moment();
        let workingMonth = (firstDayOfWeek === month.weekday()) ?
            month.clone().subtract(firstDayOfWeek > 0 ? 1 : 7, 'days')
            :
            month.clone().subtract(month.weekday(), 'days');
        let currentWeek: any = undefined;
        for (let i = 0; i < 42; i++) {
            if (i % 7 === 0) {
                currentWeek = { days: [], weekOfMonth: i / 7 };
                monthData.weeks.push(currentWeek);
            }
            const date = workingMonth.format('YYYY-MM-DD');
            const day: any = {
                value: workingMonth.date(),
                date: date,
                dayEvent: this.props.daysEvents.map[date]
            };
            if (today.isSame(workingMonth, 'day')) {
                day.today = true;
            }
            day.deleted = workingMonth.month() !== month.month();
            currentWeek.days.push(day);
            workingMonth = workingMonth.add(1, 'days');
        }
        return monthData;
    }
}