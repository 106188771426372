import * as React from 'react';
import Table from './Table';
import LeaveTypesBalanceManager from '../../Dashboard/LeaveTypesBalance/Manager';

export default class Manager extends LeaveTypesBalanceManager {
    render() {
        if (this.state.rows.arr.length === 0) {
            return null as any;
        }
        return (
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <Table
                        rows={this.state.rows}
                        currentYear={this.state.currentYear.value}
                        onChangeYear={(year: any) => { this.setState({ currentYear: { value: year } }, () => { this.search(); }); }}
                    />
                </div>
            </div>
        );
    }

    prepareRows(rows: any[]) {
        const caches: any = this.getCaches().getState();
        return rows.map((row: any) => {
            return {
                leaveType: caches.leaveTypes.active.map[row[3]],
                data: row
            };
        }).filter((row: any) => { return row.leaveType; });
    }

    getEmployee() {
        return (this.getSession().getState() as any).companyUser.id;
    }
}