import * as React from 'react';
import Component from '../../Component';
import Submit from '../../Components/Submit';
import InputField from '../../Components/InputField';
import SelectField from '../../Components/SelectField';
import * as $ from 'jquery';
import { countriesOptionsFactory, timezonesOptionsFactory } from '../../SelectOptionsFactories';
import CheckBox from '../../Components/CheckBox';
import { getURLParameter } from '../../Utils';

export default class RegisterCompanyForm extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            name: {
                touched: false,
                rules: this.Rules().BasicNameInputRules(),
            },
            size: {
                value: [1],
                touched: false,
                rules: this.Rules().BasicSelectRules(),
            },
            country: {
                value: [this.props.defaultCountry],
                rules: this.Rules().BasicSelectRules(),
                touched: false,
            },
            timezone: {
                value: [this.props.defaultTimezone],
                rules: this.Rules().BasicSelectRules(),
                touched: false,
            },
            firstName: {
                touched: false,
                rules: this.Rules().BasicNameInputRules(),
            },
            lastName: {
                touched: false,
                rules: this.Rules().BasicNameInputRules(),
            },
            email: {
                touched: false,
                rules: this.Rules().BasicEmailRules(),
                value: getURLParameter('email') ? getURLParameter('email') : ''
            },
            password: {
                touched: false,
                rules: this.Rules().BasicPasswordRules()
            },
            tc: {
                value: true,
            },
            register: {
                pleaseWait: false
            },
        };
    }

    render() {
        const t = this.translate();
        const sizes = [
            {
                value: 1,
                text: (
                    <span>
                        <span>{t('1.9.employees')}</span>
                        <span className="ml-1 text-info">{t('free')}</span>
                    </span>
                )
            },
            {
                value: 10,
                text: (
                    <span>
                        <span>{t('10.plus.employees')}</span>
                        <span className="ml-1 text-success">{t('14.days.trial')}</span>
                    </span>
                )
            },
        ];
        const caches: any = this.getCaches().getState();
        const countriesOptions = countriesOptionsFactory(caches.countries.arr[this.language()]);
        const timezonesOptions = timezonesOptionsFactory(caches.timezones.arr);
        return (
            <form onSubmit={(event: any) => { this.submit(event); }} >
                <fieldset>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h5 className="text-center m-0">{t('signup.for.free')}</h5>
                        </div>
                        <div className="col-12 mb-3">
                            <InputField
                                touched={this.state.name.touched}
                                rules={this.state.name.rules}
                                type="text"
                                placeholder={t('company.name')}
                                focus={this.isScreenSizeSmall() ? false : true}
                                // tslint:disable-next-line:max-line-length
                                onChange={(value: any) => { this.setState({ name: Object.assign({}, this.state.name, { value: value }) }); }}
                            />
                        </div>
                        <div className="col-12 mb-3 d-none">
                            <SelectField
                                defaultValue={this.state.size.value}
                                touched={this.state.size.touched}
                                options={sizes}
                                multiple={false}
                                rules={this.state.size.rules}
                                placeholder={t('company.size')}
                                // tslint:disable-next-line:max-line-length
                                onChange={(value: any) => { this.setState({ size: Object.assign({}, this.state.size, { value: value }) }); }}
                            />
                        </div>
                        <div className="col-12 mb-3">
                            <SelectField
                                defaultValue={this.state.country.value}
                                touched={this.state.country.touched}
                                options={countriesOptions}
                                multiple={false}
                                rules={this.state.country.rules}
                                placeholder={t('country')}
                                onChange={(value: any) => { this.setState({ country: Object.assign({}, this.state.country, { value: value }) }); }}
                            />
                        </div>
                        <div className="col-12 mb-3 d-none">
                            <SelectField
                                defaultValue={this.state.timezone.value}
                                touched={this.state.timezone.touched}
                                options={timezonesOptions}
                                rules={this.state.timezone.rules}
                                placeholder={t('timezone')}
                                // tslint:disable-next-line:max-line-length
                                onChange={(value: any) => { this.setState({ timezone: Object.assign({}, this.state.timezone, { value: value }) }); }}
                            />
                        </div>
                        <div className="col-6 mb-3">
                            <InputField
                                touched={this.state.firstName.touched}
                                rules={this.state.firstName.rules}
                                type="text"
                                placeholder={t('first.name')}
                                // tslint:disable-next-line:max-line-length
                                onChange={(value: any) => { this.setState({ firstName: Object.assign({}, this.state.firstName, { value: value }) }); }}
                            />
                        </div>
                        <div className="col-6 mb-3">
                            <InputField
                                touched={this.state.lastName.touched}
                                rules={this.state.lastName.rules}
                                type="text"
                                placeholder={t('last.name')}
                                onChange={(value: any) => {
                                    // tslint:disable-next-line:max-line-length
                                    this.setState({ lastName: Object.assign({}, this.state.lastName, { value: value }) });
                                }}
                            />
                        </div>
                        <div className="col-12 mb-3">
                            <InputField
                                cid={this.state.email.cid}
                                touched={this.state.email.touched}
                                rules={this.state.email.rules}
                                type="text"
                                placeholder={t('email')}
                                defaultValue={this.state.email.value}
                                onChange={(value: any) => { this.setState({ email: Object.assign({}, this.state.email, { value: value }) }); }}
                            />
                        </div>
                        <div className="col-12 mb-3">
                            <InputField
                                cid={this.state.password.cid}
                                touched={this.state.password.touched}
                                rules={this.state.password.rules}
                                type="password"
                                placeholder={t('password')}
                                onChange={(value: any) => { this.setState({ password: Object.assign({}, this.state.password, { value: value }) }); }}
                            />
                        </div>
                        <div className="col-12 mb-3">
                            <CheckBox
                                defaultValue={this.state.tc.value}
                                onChange={(value: any) => { this.setState({ tc: Object.assign({}, this.state.tc, { value: value }) }); }}
                            >
                                <h6 className="m-0 d-inline">{t('i.have.read.and.agree.to.the') + ' '}
                                    <a className="card-link" href={this.Urls().getTerms(this.language())} target="_blank" title={t('terms.of.use')} rel="noopener noreferrer">
                                        {t('terms.of.use')}
                                    </a>.
                                </h6>
                            </CheckBox>
                        </div>
                        <div className="col-12 mb-3">
                            <Submit
                                faIcon="far fa-paper-plane"
                                disabled={!this.state.tc.value}
                                pleaseWait={this.state.register.pleaseWait}
                            >
                                {t('register.company')}
                            </Submit>
                        </div>
                    </div>
                </fieldset>
            </form>
        );
    }

    isFormValid() {
        return this.Validator().validate(this.state.name)
            && this.Validator().validate(this.state.country)
            && this.Validator().validate(this.state.timezone)
            && this.Validator().validate(this.state.firstName)
            && this.Validator().validate(this.state.lastName)
            && this.Validator().validate(this.state.email)
            && this.Validator().validate(this.state.password);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                name: Object.assign({}, this.state.name, { touched: true }),
                country: Object.assign({}, this.state.country, { touched: true }),
                timezone: Object.assign({}, this.state.timezone, { touched: true }),
                firstName: Object.assign({}, this.state.firstName, { touched: true }),
                lastName: Object.assign({}, this.state.lastName, { touched: true }),
                email: Object.assign({}, this.state.email, { touched: true }),
                password: Object.assign({}, this.state.password, { touched: true })
            },
            () => {
                if (this.isFormValid()) {
                    this.registerCompany();
                }
            });
    }

    registerCompany() {
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getRegister(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                name: this.state.name.value,
                size: this.state.size.value[0],
                country: this.state.country.value[0],
                timezone: this.state.timezone.value[0],
                language: this.language(),
                firstName: this.state.firstName.value,
                lastName: this.state.lastName.value,
                email: this.state.email.value,
                password: this.state.password.value,
                numberOfEmployees: 10,
            }),
            beforeSend: () => {
                this.setState({ register: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.setState({ register: { blocked: false } });
                this.goTo(this.Urls().getCompanyRegisteredSuccessfully());
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
                this.setState({ register: { pleaseWait: false } });
            }
        });
    }
}