import * as React from 'react';
import { A } from '.';

export default class SortFieldLink extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    public handleClick() { this.props.onSortFieldChangeTrigger(this.props.sortField); }

    public render() {
        return (<A href="##" className="d-block ml-0" title={this.props.sortField.name} onClick={this.handleClick}>{this.props.sortField.name}</A>);
    }
}