// https://basarat.gitbooks.io/typescript/content/docs/types/lib.d.ts.html#polyfill-for-old-javascript-engines
import 'core-js';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import router from './Reducers/Router';
import caches from './Reducers/Caches';
import i18n from './Reducers/I18n';
import session from './Reducers/Session';
import toastr from './Reducers/Toastr';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import * as FontFaceObserver from 'fontfaceobserver';
import 'react-datetime/css/react-datetime.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'react-daterange-picker/dist/css/react-calendar.css';
import './opensans.css';
import './fontawesome.css';
import { writeFontStyle, createFontLinks } from './fontutils';

createFontLinks();

const fontData: any = { 'Open Sans': { weight: 'normal', style: 'normal' }, 'Font Awesome 5 Brands': { weight: 'normal', style: 'normal' }, 'Font Awesome 5 Free': { weight: 'normal', style: 'normal' } }
let fontsLoaded: any = [];

(() => {
    if (typeof window === 'undefined') {
    } else {
        Object.keys(fontData).forEach((family) => {
            let data = fontData[family];
            let obs = new (FontFaceObserver as any)(family, data);
            obs.load().then(() => {
                fontsLoaded[family] = true;
                if (family === 'Open Sans') {
                    const elem = document.createElement('style');
                    elem.innerHTML = `body { font-family: 'Open Sans';}`;
                    document.body.appendChild(elem);
                    writeFontStyle();
                }
            }, () => {
                fontsLoaded[family] = false;
            });
        });
    }
})();

[router, i18n, session, toastr, caches].forEach((item) => { item.getState(); });

ReactDOM.render(<App />, document.getElementById('nox') as HTMLElement);

registerServiceWorker();