import * as React from 'react';
import Component from '../../../Component';
import InputField from '../../../Components/InputField';
import Submit from '../../../Components/Submit';

export default class Form extends Component {

    render() {
        const t = this.translate();
        return (
            <form onSubmit={(event: any) => { event.preventDefault(); }}>
                <div className="row">
                    <div className="col-12 mb-3">
                        <InputField
                            readOnly={true}
                            type="text"
                            placeholder={t('icalendar.url')}
                            defaultValue={this.props.url}
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <Submit
                            faIcon="fas fa-copy"
                            onClick={() => {
                                try {
                                    document.execCommand('copy');
                                    // tslint:disable-next-line:no-empty
                                } catch (err) { }
                            }}
                        >
                            {t('copy')}
                        </Submit>
                    </div>
                </div>
            </form>
        );
    }
}