import * as React from 'react';
import Component from '../Component';
import A from './A';

export default class Cog extends Component {
    constructor(props?: any) {
        super(props);
        this.state = { hover: false };
    }

    render() {
        const t = this.translate();
        const opts: any = {};
        opts.href = this.props.href ? this.props.href : '##';
        if (!this.props.href) {
            opts.onClick = (e: any) => { e.preventDefault(); if (this.props.onClick) { this.props.onClick(); } }
        };
        return (
            <A
                faIcon={(this.state.hover ? 'fas fa-cog' : 'fas fa-cog') + ' fa-lg fa-fw'}
                title={this.props.title ? this.props.title : t('edit')}
                className="card-link float-right"
                onMouseOut={() => { this.setState({ hover: false }); }}
                onMouseOver={() => { this.setState({ hover: true }); }}
                {...opts}
            />
        );
    }
}