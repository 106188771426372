import * as React from 'react';
import Component from '../../../../Component';
import A from '../../../../Components/A';

export default class Item extends Component {

    render() {
        const unit = this.props.unit;
        return <tr>
            <td>
                <A
                    faIcon="fas fa-users"
                    href={this.Urls().getUnit(unit.id)}
                    title={unit.name}
                >
                    {unit.name}
                </A>
            </td>
        </tr>
    }
}