import * as React from 'react';
import Component from '../../../Component';
import Cog from '../../../Components/Cog';
import WorkingDaysAndHours from '../../../Components/WorkingDaysAndHours/WorkingDaysAndHours';

export default class Display extends Component {

    render() {
        const caches: any = this.getCaches().getState();
        const moment = this.getMomentWithLocale();
        const t = this.translate();
        const companySettings = this.props.companySettings;
        const countriesMap = caches.countries.map[this.language()];
        const startYearMoment = moment().year(2020)
            .month(companySettings.businessYearStart.month)
            .date(companySettings.businessYearStart.day);
        return (
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <Cog onClick={() => { if (this.props.onEdit) { this.props.onEdit(); } }} />
                        </div>
                        <div className="col-12">
                            <table className="table table-sm w-100 m-0 p-0">
                                <thead>
                                    <tr>
                                        <th className="border-top-0">
                                            <strong>
                                                {(this.getSession().getState() as any).company.name}
                                            </strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {t('country')}
                                            <span className="float-right">
                                                {countriesMap[companySettings.countries[0]].text}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('headquarters')}
                                            <span className="float-right">
                                                {companySettings.office.name}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('name.format')}
                                            <span className="float-right">
                                                {t(companySettings.nameFormat)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('subsidiaries')}
                                            <span className="float-right">
                                                {this.getBooleanString(companySettings.subsidiariesEnabled)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('business.year.starts.on')}
                                            <span className="float-right">
                                                {startYearMoment.format('ll').replace('2020', '').replace(',', '')}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="border-top-0">
                                            <div className="mb-3" />
                                            <strong>{t('contact.information')}</strong>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('name')}
                                            <span className="float-right">
                                                {companySettings.contact.fullName}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('email')}
                                            <span className="float-right">
                                                <a
                                                    className="card-link"
                                                    href={'mailto:' + companySettings.contact.email}
                                                >
                                                    {companySettings.contact.email}
                                                </a>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('phone')}
                                            <span className="float-right">
                                                <a
                                                    className="card-link"
                                                    href={'tel:' + companySettings.contact.phone}
                                                >
                                                    {companySettings.contact.phone}
                                                </a>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('working.days.and.hours')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="mr-0 ml-0 pr-0 pl-0 border-0">
                                            <div className="row">
                                                <WorkingDaysAndHours
                                                    readOnly={true}
                                                    weekDaysAndHours={companySettings.weekDaysAndHours}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getBooleanString(value: any) {
        const t = this.translate();
        return value ? t('yes') : t('no');
    }
}