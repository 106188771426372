import * as React from 'react';
import Component from '../../Component';
import Anchor from '../../Components/Anchor';
import { findLocaleName } from '../../Utils';

export default class LeaveName extends Component {
    render() {
        const leave = this.props.leave;
        const faStyle = { color: leave.leaveType.color };
        const name = findLocaleName(leave.leaveType.names, this.language());
        return <div className="text-truncate" style={{ maxWidth: '300px', minWidth: '50px' }}>
            <Anchor title={name} href="##" className="card-link text-dark">
                <i className="mr-1 fa fa-circle fa-fw" style={faStyle} aria-hidden="true" /> {name}
            </Anchor>
        </div>
    }
}