import * as $ from 'jquery';
import { doFetchLeaveTypesFromServer } from '../../../Actions/Caches';
import Component from '../../../Component';
import Close from '../../../Components/Close';
import ColorField from '../../../Components/ColorField';
import DatePickerField from '../../../Components/DatePickerField';
import InputField from '../../../Components/InputField';
import SelectField from '../../../Components/SelectField';
import Submit from '../../../Components/Submit';
import TextareaField from '../../../Components/TextareaField';
import ToggleField from '../../../Components/ToggleField';
import { DEFAULT_COLORS } from '../../../General';
import { allowanceOptionsFactory, earlierOptionsFactory, effectiveAfterFactory, hoursPerDayOptionsFactory } from '../../../SelectOptionsFactories';
import { findLocaleName, formatForServer } from '../../../Utils';
import MoreActions from './MoreActions';

export default class Form extends Component {

    constructor(props: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        this.state = {
            name: {
                defaultValue:
                    findLocaleName(this.props.leaveType.names, this.language()),
                value: findLocaleName(this.props.leaveType.names, this.language()),
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            allowance: {
                value: [this.props.leaveType.allocation],
                touched: false,
                rules: this.Rules().BasicInputRules()
            },
            hoursAllowed: {
                value: this.props.leaveType.hoursAllowed,
            },
            hoursPerDay: {
                value: [this.props?.leaveType?.hoursPerDay ? this.props.leaveType.hoursPerDay : 8],
                touched: false,
                rules: this.Rules().BasicInputRules()
            },
            overtime: {
                value: this.props.leaveType.overtime,
            },
            effectiveAfter: {
                value: [this.props.leaveType.effectiveAfter ? this.props.leaveType.effectiveAfter : 0],
                touched: false,
                rules: this.Rules().BasicInputRules()
            },
            earlier: {
                value: [this.props.leaveType.earlier],
                touched: false,
                rules: this.Rules().BasicInputRules()
            },
            color: {
                value: this.props.leaveType.color,
            },
            halfADayAllowed: {
                value: this.props.leaveType.halfADayAllowed,
            },
            registerInThePastAllowed: {
                value: this.props.leaveType.registerInThePast,
            },
            enforceAllowance: {
                value: this.props.leaveType.allocationEnforced,
            },
            attachmentsAllowed: {
                value: this.props.leaveType.attachmentsAllowed,
            },
            paperCopy: {
                value: this.props.leaveType.paperCopy,
            },
            commentRequired: {
                value: this.props.leaveType.commentRequired,
            },
            autoApprove: {
                value: this.props.leaveType.autoApprove,
            },
            sendNotificationToManager: {
                value: this.props.leaveType.sendNotificationToManager,
            },
            replacement: {
                value: this.props.leaveType.replacement,
            },
            replacementRequired: {
                value: this.props.leaveType.replacementRequired,
            },
            adjustAllowanceOnEmployment: {
                value: this.props.leaveType.adjustAllowanceOnEmployment,
            },
            working: {
                value: this.props.leaveType.working,
            },
            showEarnedAllowance: {
                value: this.props.leaveType.showEarnedAllowance,
            },
            ignoreBlackoutEvents: {
                value: this.props.leaveType.ignoreBlackoutEvents,
            },
            usableInterval: {
                value: this.props.leaveType.usableInterval,
            },
            usableFrom: {
                value: this.props.leaveType.usableFrom ? moment(this.props.leaveType.usableFrom) : null,
                touched: false,
                timeFormat: false,
                rules: this.Rules().BasicSelectRules()
            },
            usableTo: {
                value: this.props.leaveType.usableTo ? moment(this.props.leaveType.usableTo) : null,
                touched: false,
                timeFormat: false,
                rules: this.Rules().BasicSelectRules()
            },
            description: {
                value: this.props.leaveType.description ? this.props.leaveType.description : '',
                touched: false,
                rules: this.Rules().MaxLengthTextareaRules()
            },
            save: {
                pleaseWait: false
            },
        };
    }

    render() {
        const t = this.translate();
        const allowanceOptions = allowanceOptionsFactory(t, this.state.halfADayAllowed.value ? 0.5 : 1);
        const hoursPerDayOptions = hoursPerDayOptionsFactory(t, this.state.halfADayAllowed.value ? 0.5 : 1);
        const effectiveAfterOptions = effectiveAfterFactory(t);
        const earlierOptions = earlierOptionsFactory(t, 1);
        const dateFormat = this.getDateFormat();
        const session = this.getSession().getState() as any;
        const companyId = session.company.id;
        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <form onSubmit={(event: any) => { this.submit(event); }}>
                    <fieldset>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="float-right">
                                    <MoreActions
                                        leaveType={this.props.leaveType}
                                        onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }}
                                    />
                                    <span className="ml-2" />
                                    <Close onClick={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }} />
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <InputField
                                    defaultValue={this.state.name.defaultValue}
                                    touched={this.state.name.touched}
                                    type="text"
                                    placeholder={t('name')}
                                    focus={this.isScreenSizeSmall() ? false : true}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { name: Object.assign({}, this.state.name, { value: value }) },
                                            () => { this.onUpdate(); }
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.allowance.value}
                                    touched={this.state.allowance.touched}
                                    options={allowanceOptions}
                                    multiple={false}
                                    placeholder={t('allowance.days.per.year')}
                                    onChange={(value: any) => { this.setState({ allowance: Object.assign({}, this.state.allowance, { value: value }) }); }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <ColorField
                                    defaultValue={this.state.color.value}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { color: Object.assign({}, this.state.color, { value: value }) },
                                            () => { this.onUpdate(); }
                                        );
                                    }}
                                    colors={DEFAULT_COLORS}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <ToggleField
                                    defaultValue={this.state.hoursAllowed.value}
                                    tooltip={t('hours.request.tooltip')}
                                    onChange={(value: any) => { this.setState({ hoursAllowed: Object.assign({}, this.state.hoursAllowed, { value: value }) }); }}
                                >
                                    {t('hours.request')}
                                </ToggleField>
                            </div>
                            {this.state.hoursAllowed.value ?
                                <div className="col-12 mb-3">
                                    <SelectField
                                        defaultValue={this.state.hoursPerDay.value}
                                        touched={this.state.hoursPerDay.touched}
                                        options={hoursPerDayOptions}
                                        multiple={false}
                                        placeholder={t('working.hours.in.one.business.day')}
                                        onChange={(value: any) => { this.setState({ hoursPerDay: Object.assign({}, this.state.hoursPerDay, { value: value }) }); }}
                                    />
                                </div> : null}
                            <div className="col-12 mb-3">
                                <ToggleField
                                    defaultValue={this.state.halfADayAllowed.value}
                                    tooltip={t('half.day.request.tip')}
                                    // tslint:disable-next-line:max-line-length
                                    onChange={(value: any) => { this.setState({ halfADayAllowed: Object.assign({}, this.state.halfADayAllowed, { value: value }) }); }}
                                >
                                    {t('half.day.request')}
                                </ToggleField>
                            </div>
                            <div className="col-12 mb-3">
                                <ToggleField
                                    defaultValue={this.state.registerInThePastAllowed.value}
                                    tooltip={t('register.in.the.past.tip')}
                                    onChange={(value: any) => {
                                        // tslint:disable-next-line:max-line-length
                                        this.setState({ registerInThePastAllowed: Object.assign({}, this.state.registerInThePastAllowed, { value: value }) });
                                    }}
                                >
                                    {t('register.in.the.past')}
                                </ToggleField>
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.earlier.value}
                                    touched={this.state.earlier.touched}
                                    options={earlierOptions}
                                    multiple={false}
                                    placeholder={t('minimum.leave.notice')}
                                    onChange={(value: any) => { this.setState({ earlier: Object.assign({}, this.state.earlier, { value: value }) }); }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <ToggleField
                                    defaultValue={this.state.enforceAllowance.value}
                                    tooltip={t('enforce.allowance.tip')}
                                    onChange={(value: any) => { this.setState({ enforceAllowance: Object.assign({}, this.state.enforceAllowance, { value: value }) }); }}
                                >
                                    {t('enforce.allowance')}
                                </ToggleField>
                            </div>
                            <div className="col-12 mb-3">
                                <ToggleField
                                    defaultValue={this.state.commentRequired.value}
                                    tooltip={t('comment.required.tip')}
                                    onChange={(value: any) => { this.setState({ commentRequired: Object.assign({}, this.state.commentRequired, { value: value }) }); }}
                                >
                                    {t('comment.required')}
                                </ToggleField>
                            </div>
                            <div className={(this.getSession().getState() as any).company.country === 'RO' ? 'w-100' : 'd-none'}>
                                <div className="col-12 mb-3">
                                    <ToggleField
                                        defaultValue={this.state.paperCopy.value}
                                        tooltip={t('paper.copy.tip')}
                                        onChange={(value: any) => { this.setState({ paperCopy: Object.assign({}, this.state.paperCopy, { value: value }) }); }}
                                    >
                                        {t('paper.copy')}
                                    </ToggleField>
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <ToggleField
                                    defaultValue={this.state.autoApprove.value}
                                    tooltip={t('auto.approve.tip')}
                                    // tslint:disable-next-line:max-line-length
                                    onChange={(value: any) => { this.setState({ autoApprove: Object.assign({}, this.state.autoApprove, { value: value }) }); }}
                                >
                                    {t('auto.approve')}
                                </ToggleField>
                            </div>
                            {this.state.autoApprove.value ?
                                <div className="col-12 mb-3">
                                    <ToggleField
                                        defaultValue={this.state.sendNotificationToManager.value}
                                        tooltip={t('send.notification.to.the.manager.tip')}
                                        // tslint:disable-next-line:max-line-length
                                        onChange={(value: any) => { this.setState({ sendNotificationToManager: Object.assign({}, this.state.sendNotificationToManager, { value: value }) }); }}
                                    >
                                        {t('send.notification.to.the.manager')}
                                    </ToggleField>
                                </div> : <></>}
                            <div className="col-12 mb-3">
                                <ToggleField
                                    defaultValue={this.state.usableInterval.value}
                                    tooltip={t('usable.in.interval.tip')}
                                    onChange={(value: any) => { this.setState({ usableInterval: Object.assign({}, this.state.usableInterval, { value: value }) }); }}
                                >
                                    {t('usable.in.interval')}
                                </ToggleField>
                            </div>
                            {this.state.usableInterval.value ?
                                <>
                                    <div className="col-12 mb-3">
                                        <DatePickerField
                                            value={this.state.usableFrom.value}
                                            placeholder={t('usable.from')}
                                            timeFormat={this.state.usableFrom.timeFormat}
                                            dateFormat={dateFormat}
                                            onChange={(value: any) => { this.setState({ usableFrom: Object.assign({}, this.state.usableFrom, { value, isValid: true }) }); }}
                                        />
                                    </div>
                                    <div className="col-12 mb-3">
                                        <DatePickerField
                                            value={this.state.usableTo.value}
                                            placeholder={t('usable.to')}
                                            timeFormat={this.state.usableTo.timeFormat}
                                            dateFormat={dateFormat}
                                            onChange={(value: any) => { this.setState({ usableTo: Object.assign({}, this.state.usableTo, { value, isValid: true }) }); }}
                                        />
                                    </div>
                                </>
                                : null}
                            <div className="col-12 mb-3">
                                <ToggleField
                                    defaultValue={this.state.replacement.value}
                                    tooltip={t('ask.for.replacement.tip')}
                                    onChange={(value: any) => { this.setState({ replacement: Object.assign({}, this.state.replacement, { value: value }) }); }}
                                >
                                    {t('ask.for.replacement')}
                                </ToggleField>
                            </div>
                            <div className={this.state.replacement.value ? 'w-100' : 'd-none'}>
                                <div className="col-12 mb-3">
                                    <ToggleField
                                        defaultValue={this.state.replacementRequired.value}
                                        tooltip={t('replacement.required.tip')}
                                        onChange={(value: any) => { this.setState({ replacementRequired: Object.assign({}, this.state.replacementRequired, { value: value }) }); }}
                                    >
                                        {t('replacement.required')}
                                    </ToggleField>
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <ToggleField
                                    defaultValue={this.state.adjustAllowanceOnEmployment.value}
                                    tooltip={t('adjust.allowance.on.employment.tip')}
                                    onChange={(value: any) => { this.setState({ adjustAllowanceOnEmployment: Object.assign({}, this.state.adjustAllowanceOnEmployment, { value: value }) }); }}
                                >
                                    {t('adjust.allowance.on.employment')}
                                </ToggleField>
                            </div>
                            <div className="col-12 mb-3">
                                <ToggleField
                                    defaultValue={this.state.working.value}
                                    tooltip={t('is.working.tip')}
                                    onChange={(value: any) => { this.setState({ working: Object.assign({}, this.state.working, { value: value }) }); }}
                                >
                                    {t('is.working')}
                                </ToggleField>
                            </div>
                            <>
                                {this.state.hoursAllowed.value && this.state.working.value ?
                                    <div className="col-12 mb-3">
                                        <ToggleField
                                            defaultValue={this.state.overtime.value}
                                            tooltip={t('overtime.tooltip')}
                                            onChange={(value: any) => { this.setState({ overtime: Object.assign({}, this.state.overtime, { value: value }) }); }}
                                        >
                                            {t('overtime.request')}
                                        </ToggleField>
                                    </div>
                                    : null}
                            </>
                            <div className="col-12 mb-3">
                                <ToggleField
                                    defaultValue={this.state.showEarnedAllowance.value}
                                    tooltip={t('show.earned.allowance.tip')}
                                    onChange={(value: any) => { this.setState({ showEarnedAllowance: Object.assign({}, this.state.showEarnedAllowance, { value: value }) }); }}
                                >
                                    {t('show.earned.allowance')}
                                </ToggleField>
                            </div>
                            <div className="col-12 mb-3">
                                <ToggleField
                                    defaultValue={this.state.ignoreBlackoutEvents.value}
                                    tooltip={t('ignore.blackout.days.tip')}
                                    onChange={(value: any) => { this.setState({ ignoreBlackoutEvents: Object.assign({}, this.state.ignoreBlackoutEvents, { value: value }) }); }}
                                >
                                    {t('ignore.blackout.days')}
                                </ToggleField>
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.effectiveAfter.value}
                                    touched={this.state.effectiveAfter.touched}
                                    options={effectiveAfterOptions}
                                    multiple={false}
                                    placeholder={t('effective.after')}
                                    onChange={(value: any) => { this.setState({ effectiveAfter: Object.assign({}, this.state.effectiveAfter, { value: value }) }); }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <TextareaField
                                    touched={this.state.description.touched}
                                    defaultValue={this.state.description.value}
                                    type="text"
                                    rows={2}
                                    placeholder={t('description')}
                                    onChange={(value: any) => { this.setState({ description: Object.assign({}, this.state.description, { value: value }) }); }}
                                />
                            </div>
                            <div className="col-12">
                                <Submit
                                    faIcon="far fa-hdd"
                                    disabled={!this.isFormValid()}
                                    pleaseWait={this.state.save.pleaseWait}
                                >
                                    {t('save')}
                                </Submit>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    }

    onUpdate() {
        if (this.props.onUpdate) {
            this.props.onUpdate({
                id: this.props.leaveType.id,
                names: [{
                    lang: this.language(),
                    value: this.state.name.value
                }],
                allocation: this.state.allowance.value[0],
                color: this.state.color.value,
                description: this.state.description.value,
                halfADayAllowed: this.state.halfADayAllowed.value,
                registerInThePast: this.state.registerInThePastAllowed.value,
                allocationEnforced: this.state.enforceAllowance.value,
                attachmentsAllowed: this.state.attachmentsAllowed.value
            });
        }
    }

    isFormValid() {
        return this.Validator().validate(this.state.name)
            && this.Validator().validate(this.state.color)
            && this.Validator().validate(this.state.description)
            && (this.state.usableInterval.value ? this.state?.usableFrom?.value ? this.Validator().validate(this.state.usableFrom.value) : false : true)
            && (this.state.usableInterval.value ? this.state?.usableTo?.value ? this.Validator().validate(this.state.usableTo.value) : false : true);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                name: Object.assign({}, this.state.name, { touched: true }),
                color: Object.assign({}, this.state.color, { touched: true }),
                description: Object.assign({}, this.state.description, { touched: true }),
                usableFrom: Object.assign({}, this.state.usableFrom, { touched: true }),
                usableTo: Object.assign({}, this.state.usableTo, { touched: true })
            },
            () => { if (this.isFormValid()) { this.saveLeaveType(); } }
        );
    }

    saveLeaveType() {
        const t = this.translate();
        const leaveType: any = {
            id: this.props.leaveType.id,
            names: [{
                lang: this.language(),
                value: this.state.name.value
            }],
            allocation: this.state.allowance.value[0],
            hoursAllowed: this.state.hoursAllowed.value,
            hoursPerDay: this.state.hoursPerDay.value[0],
            overtime: this.state.overtime.value,
            color: this.state.color.value,
            description: this.state.description.value,
            halfADayAllowed: this.state.halfADayAllowed.value,
            registerInThePast: this.state.registerInThePastAllowed.value,
            earlier: this.state.earlier.value[0],
            allocationEnforced: this.state.enforceAllowance.value,
            attachmentsAllowed: this.state.attachmentsAllowed.value,
            paperCopy: this.state.paperCopy.value,
            commentRequired: this.state.commentRequired.value,
            autoApprove: this.state.autoApprove.value,
            sendNotificationToManager: this.state.sendNotificationToManager.value,
            usableInterval: this.state.usableInterval.value,
            replacement: this.state.replacement.value,
            replacementRequired: this.state.replacementRequired.value,
            adjustAllowanceOnEmployment: this.state.adjustAllowanceOnEmployment.value,
            working: this.state.working.value,
            showEarnedAllowance: this.state.showEarnedAllowance.value,
            ignoreBlackoutEvents: this.state.ignoreBlackoutEvents.value,
            effectiveAfter: this.state.effectiveAfter.value[0],
        }
        if (this.state.usableInterval.value) {
            leaveType.usableFrom = formatForServer(this.state.usableFrom.value);
            leaveType.usableTo = formatForServer(this.state.usableTo.value);
        }
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getLeaveTypeUpdate(this.props.leaveType.id),
            dataType: 'json',
            cache: false,
            data: JSON.stringify(leaveType),
            beforeSend: () => {
                this.setState({ save: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                doFetchLeaveTypesFromServer(this.getCaches());
                this.successToastr(t('the.leave.type.was.saved'));
                if (this.props.onRefresh) { this.props.onRefresh(); }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.generalErrorToastr();
            }
        });
    }
}