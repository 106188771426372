import * as React from 'react';
import Item from './Item';
import Error from '../../../Components/Error';
import { STATUS } from '../../../General';
import LeaveTypesBalanceTable from '../../Dashboard/LeaveTypesBalance/Table';
import ChooseYear from '../../../Components/ChooseYear';

export default class Table extends LeaveTypesBalanceTable {

    render() {
        const rows = this.props.rows;
        switch (rows.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                const rowsComps = rows.arr.map((row: any) => {
                    return (
                        <Item
                            key={'EmployeeAllowancesDisplayTable-' + row.leaveType.id}
                            leaveTypePerYear={row}
                            employee={this.props.employee}
                            onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }}
                        />
                    );
                });
                return <table className="table table-sm w-100 mb-0">
                    <thead>
                        <tr>
                            <th className="border-top-0">
                                <ChooseYear
                                    currentYear={this.props.currentYear}
                                    years={[this.props.currentYear - 1, this.props.currentYear, this.props.currentYear + 1]}
                                    onChangeYear={(year: any) => { if (this.props.onChangeYear) { this.props.onChangeYear(year); } }}
                                />
                            </th>
                            <th className="border-top-0 text-right" colSpan={2}>
                                {t('allowance')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {rowsComps}
                    </tbody>
                </table>
            }
            default:
                return (<Error />);
        }
    }
}