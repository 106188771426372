import BaseDownload from '../../../Components/BaseDownload';
import LeaveStatus from '../../../Components/Leave/LeaveStatus';
import { LEAVESTATUS } from '../../../General';
import { findLocaleName } from '../../../Utils';
import Manager from './Manager';

/* eslint-disable @typescript-eslint/no-unused-expressions */
export default class Download extends BaseDownload {
    computeRows: any;
    getLeaveTypes: any;
    constructor(options: any) {
        super(options);
        this.search = Manager.search.bind(this);
        this.computeRows = Manager.computeRows.bind(this);
    }

    getData() {
        const t = this.t;
        const caches: any = this.getCaches().getState();
        let leaveTypesMap = caches.leaveTypes.map;
        const session = this.getSession().getState() as any;
        const companyId = session.company.id;
        const header = companyId === 9118 ? [t('email'), t('employee'), t('leave'), t('range'), t('days'), t('status'), t('approver')] : [t('employee'), t('leave'), t('range'), t('days'), t('status'), t('approver'), t('comment'), t('approvers.comment')];
        const data: any = [];
        let currentUserId = 0;
        this.state.rows.arr.forEach((row: any) => {
            if (currentUserId !== row[0]) {
                currentUserId = row[0];
                if (companyId !== 9118) data.push([row.employee.fullName]);
            }
            const zarow = companyId === 9118 ? [row.employee.email, row.employee.fullName] : [''];
            zarow.push(this.getName(leaveTypesMap[row[5]]));
            zarow.push(row.interval ? row.interval : '');
            zarow.push(row[6] ? row[6] : '');
            zarow.push(LeaveStatus.getName({ status: row[7] }, t));
            zarow.push(row.approver ? row.approver.fullName : '');
            zarow.push(row[7] === LEAVESTATUS.CANCELED ? (row.cancelComment ? row.cancelComment : '') :
                row.comment ? row.comment : '');
            zarow.push(row.approverComment ? row.approverComment : '');
            data.push(zarow);
        })
        const arr = [header].concat(data);
        return arr;
    }

    public isReady() {
        return this.state.rows && this.state.rows.status === 'READY';
    }

    getFileName(this: any): any {
        const t = this.t;
        return this.ansi(t('leaves')) + this.getSufixFileName();
    }

    public getName(leaveType: any) {
        return findLocaleName(leaveType.names, this.language());
    }
}