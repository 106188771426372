import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import { STATUS } from '../../../General';
import Table from './Table';

export default class Manager extends Component {
    private retries = 2;
    constructor(props?: any) {
        super(props);
        this.state = {
            rows: { arr: [], status: STATUS.LOADING },
            currentYear: { value: this.currentBusinessYear() }
        };
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState({ rows: { arr: [], status: STATUS.LOADING } }, () => {
            $.ajax({
                type: 'GET',
                contentType: 'application/json',
                url: this.Endpoints().getLeaveTypesPerYear(this.getEmployee(), this.state.currentYear.value),
                dataType: 'json',
                cache: false,
                success: (data: any, textStatus: any, jqXHR: any) => {
                    this.setState({ rows: { arr: this.prepareRows(data), status: STATUS.READY } });
                },
                error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                    this.setState({ rows: { arr: [], status: STATUS.ERROR } });
                    if (this.retries--) {
                        window.setTimeout(() => { this.search() }, 500);
                        return;
                    }
                    this.ajaxError(jqXHR, textStatus, errorThrown);
                },
            });
        });
    }

    render() {
        if (this.state.rows.arr.length === 0) {
            return null as any;
        }
        return <div className="card h-100">
            <div className="card-body" >
                <Table rows={this.state.rows} />
            </div>
        </div>
    }

    prepareRows(rows: any[]) {
        const caches: any = this.getCaches().getState();
        return rows.map((row) => {
            return {
                leaveType: caches.leaveTypes.active.map[row[3]],
                data: row
            };
        }).filter((row) => {
            return row.leaveType;
        });
    }

    getEmployee() {
        return (this.getSession().getState() as any).companyUser.id;
    }
}