import * as React from 'react';
import Component from '../Component';
import { fullNameFactory } from '../Factories';
import A from './A';

export default class EmployeeList extends Component {
    render() {
        const employee = this.props.employee;
        const session = this.getSession().getState() as any;
        const fullName = fullNameFactory(this.props.employee, session.company.nameFormat);
        if (this.props.disableFa) {
            return <A href={this.Urls().getEmployee(employee.id)} title={fullName}>{fullName}</A>
        }
        return <A faIcon={this.props.fa ? this.props.fa : 'far fa-user'} href={this.Urls().getEmployee(employee.id)} title={fullName}>
            {fullName}
        </A>;
    }
}