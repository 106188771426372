import * as React from 'react';
import ResponsiveModal from '../ResponsiveModal';
import Form from './Form';
import { none } from '../../../Actions/Modal';

export default class ImportHoes extends ResponsiveModal {
    getContent() {
        const office = this.props.office;
        return (
            <Form
                office={office}
                onClose={() => {
                    this.toggle();
                    none(this.getModal(), {});
                    if (this.props.callback) { this.props.callback(); }
                }}
            />
        );
    }

    getTitle(): any {
        const t = this.translate();
        return (
            <h5 className="m-0 p-0">{t('import.public.holidays')}</h5>
        );
    }
}