import * as React from 'react';
import Component from '../Component';
import Anchor from './Anchor';

export default class Close extends Component {
    constructor(props?: any) {
        super(props);
        this.state = { hover: false };
    }

    render() {
        const t = this.translate();
        return <Anchor
            faIcon={(this.state.hover ? 'fas fa-times-circle' : 'far fa-times-circle') + ' fa-lg fa-fw'}
            className="float-right"
            title={t('close')}
            onMouseOut={() => { this.setState({ hover: false }); }}
            onMouseOver={() => { this.setState({ hover: true }); }}
            onClick={() => { if (this.props.onClick) { this.props.onClick(); } }}
        />
    }
}