import * as React from 'react';
import Component from '../../Component';
import A from '../A';

export default class PaperCopyAction extends Component {
    render() {
        const t = this.translate();
        return (
            <A href={this.Endpoints().getLeavePaperCopy(this.props.leave.id)} target="_blank" faIcon="fas fa-print" title={t('paper.print')} >
                {this.props.noText ? null : t('paper.copy')}
            </A>
        );
    }
}