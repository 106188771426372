export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_COUNTRY = 'CHANGE_COUNTRY';

export enum COUNTRIES_CACHE {
    LOADING = 'LOADING_COUNTRIES',
    READY = 'READY_COUNTRIES',
    ERROR = 'ERROR_COUNTRIES'
}
export enum TIMEZONES_CACHE {
    LOADING = 'LOADING_TIMEZONES',
    READY = 'READY_TIMEZONES',
    ERROR = 'ERROR_TIMEZONES'
}

export enum UNITS_CACHE {
    LOADING = 'LOADING_UNITS',
    READY = 'READY_UNITS',
    ERROR = 'ERROR_UNITS'
}

export enum USERS_CACHE {
    LOADING = 'LOADING_USERS',
    READY = 'READY_USERS',
    ERROR = 'ERROR_USERS'
}

export enum LEAVETYPES_CACHE {
    LOADING = 'LOADING_LEAVETYPES',
    READY = 'READY_LEAVETYPES',
    ERROR = 'ERROR_LEAVETYPES'
}

export enum OFFICES_CACHE {
    LOADING = 'LOADING_OFFICES',
    READY = 'READY_OFFICES',
    ERROR = 'ERROR_OFFICES'
}

export enum SUBSIDIARIES_CACHE {
    LOADING = 'LOADING_SUBSIDIARIES',
    READY = 'READY_SUBSIDIARIES',
    ERROR = 'ERROR_SUBSIDIARIES'
}
export const NAVIGATE: string = 'NAVIGATE';

export const RESIZE: string = 'RESIZE';

export enum TOASTR_ACTIONS {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    INFO = 'INFO',
    CLEAN = 'CLEAN'
}

export enum SESSION_ACTIONS {
    LOADING = 'LOADING',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    REFRESH = 'REFRESH',
    ERROR = 'ERROR',
    NOT_LOGGED = 'NOT_LOGGED',
}

export const CHANGE_OVERVIEW_DATA = 'CHANGE_OVERVIEW_DATA';
export const CHANGE_OVERVIEW_FILTERS = 'CHANGE_OVERVIEW_FILTERS';

export enum MODALS {
    NONE = 'NONE',
    LEAVE_DECISION = 'LEAVE_DECISION',
    CANCEL_LEAVE = 'CANCEL_LEAVE',
    SEND_LEAVE_REMINDER = 'SEND_LEAVE_REMINDER',
    WHO_IS_OFF_TODAY = 'WHO_IS_OFF_TODAY',
    LEAVE_TYPES_BALANCE = 'LEAVE_TYPES_BALANCE',
    IMPORT_HOES = 'IMPORT_HOES',
    ADD_HOE = 'ADD_HOE',
    ADD_BLACKOUT_DAY = 'ADD_BLACKOUT_DAY',
    MY_NEXT_HOES = 'MY_NEXT_HOES',
    OTHER_LEAVES = 'OTHER_LEAVES',
    TRANSFER_LEAVE_TYPE_ALLOWANCE = 'TRANSFER_LEAVE_TYPE_ALLOWANCE',
    REVERT_TRANSFER_LEAVE_TYPE_ALLOWANCE = 'REVERT_TRANSFER_LEAVE_TYPE_ALLOWANCE',
    ADD_TEAM_MEMBERS = 'ADD_TEAM_MEMBERS',
    ADD_OFFICE_MEMBERS = 'ADD_OFFICE_MEMBERS',
    ADD_SUBSIDIARY_MEMBERS = 'ADD_SUBSIDIARY_MEMBERS',
    ADJUST_EMPLOYEE_LEAVETYPE_ALLOWANCE = 'ADJUST_EMPLOYEE_LEAVETYPE_ALLOWANCE',
    ADJUST_WORKING_DAY_AND_HOURS = 'ADJUST_WORKING_DAY_AND_HOURS',
    ORDER_LEAVETYPES = 'ORDER_LEAVETYPES',
    COPY_ICALENDAR_URL = 'COPY_ICALENDAR_URL',
    TRIAL_STARTED = 'TRIAL_STARTED',
}