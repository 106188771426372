import * as React from 'react';
import CompanyViewManager from '../../Calendar/CompanyView/Manager';
import Filters from '../../Calendar/CompanyView/Filters';
import Table from '../../Calendar/CompanyView/Table';

export default class Manager extends CompanyViewManager {
    render() {
        return <div className="container-fluid p-0">
            <div className="row">
                <div className="col-12">
                    <Filters
                        orderBy={this.state.filters.orderBy}
                        defaultFilters={this.state.filters}
                        onChange={(value: any) => { this.setState({ filters: Object.assign({}, value) }, () => { this.search(); }); }}
                    />
                </div>
                <div className="col-12">
                    <Table rows={this.state.rows} days={this.state.days} />
                </div>
            </div>
        </div>
    }
}