import * as React from 'react';
import { otherLeaves } from '../../../../Actions/Modal';
import Component from '../../../../Component';
import Anchor from '../../../../Components/Anchor';
import { copyArray } from '../../../../Utils';
import Item from './Item';

export default class Table extends Component {
    static ITEMS = 4;

    render() {
        const leaves = this.props.otherLeaves.value;
        const t = this.translate();
        const trs = ((leaves.length > Table.ITEMS && !this.props.showAll) ?
            copyArray(leaves, 0, Table.ITEMS - 1) : leaves).map((leave: any) => {
                return <Item key={'BookTimeOffOtherLeavesTable-' + leave.companyUser.id + '-' + leave.startDate + leave.endDate} leave={leave} />
            });
        return (
            <table className="table table-sm w-100 m-0 p-0" style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }} >
                {this.thead()}
                <tbody>
                    {trs}
                    {(leaves.length > Table.ITEMS && !this.props.showAll) ?
                        <tr>
                            <td colSpan={2}>
                                <div className="float-right">
                                    <Anchor
                                        className="text-muted"
                                        onClick={() => { otherLeaves(this.getModal(), { leaves: leaves }); }}
                                    >
                                        {t('more')}
                                    </Anchor>
                                </div>
                            </td>
                        </tr>
                        : <></>}
                </tbody>
            </table>
        );
    }

    thead() {
        const t = this.translate();
        return <thead>
            <tr>
                <th className="border-top-0">
                    {t('other.leaves.on.the.same.period')}
                </th>
            </tr>
        </thead>
    }
}