import * as React from 'react';
import { A, Fa } from '.';
import Component from '../Component';

export default class SortOrder extends Component {
    render() {
        const t = this.translate();
        return <span className="pl-1 pr-1">
            <span className={this.props.order === 'DESC' ? '' : 'd-none'}>
                <A href="##" title={t('order.ascending')} onClick={this.props.onSortOrderChangeTrigger}>
                    <Fa fa="fas fa-long-arrow-alt-down" />
                </A>
            </span>
            <span className={this.props.order === 'ASC' ? '' : 'd-none'}>
                <A href="##" title={t('order.descending')} onClick={this.props.onSortOrderChangeTrigger}>
                    <span><Fa fa="fas fa-long-arrow-alt-up" /></span>
                </A>
            </span>
        </span>
    }
}