import * as React from 'react';
import Component from '../../../Component';
import FiltersSwitch from '../../../Components/FiltersSwitch';
import InputField from '../../../Components/InputField';
import SelectField from '../../../Components/SelectField';
import SortSwitch from '../../../Components/SortSwitch';
import { USERSTATUS } from '../../../General';
import { officesOptionsFactory, subsidiariesOptionsFactory, unitsOptionsFactory } from '../../../SelectOptionsFactories';

export default class Filters extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            expanded: false,
            orderBy: this.props.orderBy,
            search: {
                touched: false
            },
            employeeStatuses: {
                value: [USERSTATUS.ACTIVE, USERSTATUS.INVITED],
                touched: false,
            },
            units: {
                touched: false,
            },
            offices: {
                touched: false,
            },
            subsidiaries: {
                touched: false,
            },
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange() { if (this.props.onChange) { this.props.onChange(this.state); } }

    render() {
        const t = this.translate();
        const caches: any = this.getCaches().getState();
        const unitsOptions = unitsOptionsFactory(caches.units.arr);
        const officesOptions = officesOptionsFactory(caches.offices.arr);
        const subsidiariesOptions = subsidiariesOptionsFactory(caches.subsidiaries.arr);
        const session: any = this.getSession().getState();
        return <div>
            <form onSubmit={(event: any) => { event.preventDefault(); }}>
                <div className="row">
                    <div className="col-7 col-sm-8 col-md-7 mb-3">
                        <InputField
                            placeholder={t('search')}
                            defaultValue=""
                            onChange={(value: any) => { this.setState({ search: Object.assign({}, this.state.search, { value: value }) }, this.onChange); }}
                        />
                    </div>
                    <div className="col-5 col-sm-4 col-md-5 mb-3">
                        <div className="float-right mt-2">
                            <SortSwitch
                                title={t('name')}
                                order={this.state.orderBy.order}
                                onChange={(order: any) => { this.setState({ orderBy: { field: 'NAME', order: order } }, this.onChange); }}
                            />
                            <FiltersSwitch
                                expanded={this.state.expanded}
                                onClick={() => { this.setState({ expanded: !this.state.expanded }); }}
                            />
                        </div>
                    </div>
                </div>
                {(this.state.expanded) ? <div className="row">
                    {(unitsOptions && unitsOptions.length > 0) ?
                        <div className="col-12 col-lg-6 mb-3">
                            <SelectField
                                defaultValue={this.state.units.value}
                                touched={this.state.units.touched}
                                options={unitsOptions}
                                multiple={true}
                                placeholder={t('teams')}
                                onChange={(value: any) => {
                                    this.setState(
                                        { units: Object.assign({}, this.state.units, { value }) },
                                        this.onChange
                                    );
                                }}
                            />
                        </div>
                        : null}
                    {(officesOptions && officesOptions.length > 1) ?
                        <div className="col-12 col-lg-6 mb-3">
                            <SelectField
                                defaultValue={this.state.offices.value}
                                touched={this.state.offices.touched}
                                options={officesOptions}
                                multiple={true}
                                placeholder={t('offices')}
                                onChange={(value: any) => {
                                    this.setState(
                                        { offices: Object.assign({}, this.state.offices, { value }) },
                                        this.onChange
                                    );
                                }}
                            />
                        </div> : null}
                    {session.company.subsidiariesEnabled && subsidiariesOptions ?
                        <div className="col-12 col-lg-6 mb-3">
                            <SelectField
                                defaultValue={this.state.subsidiaries.value}
                                touched={this.state.subsidiaries.touched}
                                options={subsidiariesOptions}
                                multiple={true}
                                placeholder={t('subsidiaries')}
                                onChange={(value: any) => {
                                    this.setState({ subsidiaries: Object.assign({}, this.state.subsidiaries, { value }) }, this.onChange);
                                }}
                            />
                        </div> : null}
                </div>
                    : null}
            </form>
        </div>
    }
}