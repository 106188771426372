import BaseTabs from '../../Components/BaseTabs';
import { CALENDARTABS } from '../../General';

export default class Tabs extends BaseTabs {
    getTabs(): any[] {
        const t = this.translate();
        return [
            {
                faIcon: 'far fa-building',
                title: t('company.calendar'),
                tab: CALENDARTABS.COMPANY
            },
            {
                faIcon: 'far fa-user',
                title: t('my.calendar'),
                tab: CALENDARTABS.MY
            },
        ];
    }

    getName(): string {
        return 'Calendar';
    }
}