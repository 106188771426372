import * as React from 'react';
import Table from './Table';
import LeaveTypesBalanceManager from '../../../Dashboard/LeaveTypesBalance/Manager';

export default class Manager extends LeaveTypesBalanceManager {

    render() {
        if (this.state.rows.arr.length === 0) {
            return null as any;
        }
        return (
            <div className="card h-100">
                <div className="card-body" >
                    <Table
                        rows={this.state.rows}
                        currentYear={this.state.currentYear.value}
                        onChangeYear={(year: any) => { this.setState({ currentYear: { value: year } }, () => { this.search(); }); }}
                    />
                </div>
            </div>
        );
    }

    getEmployee() {
        return this.props.employee.id;
    }
}