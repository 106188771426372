import * as React from 'react';
import Component from '../../../Component';
import Table from './Table';
import { STATUS } from '../../../General';
import * as $ from 'jquery';

export default class OtherLeaves extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            otherLeaves: {
                value: [],
                status: STATUS.LOADING
            }
        };
    }

    componentDidMount() {
        const caches: any = this.getCaches().getState();
        $.ajax({
            type: 'GET',
            contentType: 'application/json',
            url: this.Endpoints().getOtherLeaves(
                this.props.leave.id,
                caches.users.arr.map((user: any) => {
                    return user.id;
                })),
            dataType: 'json',
            cache: false,
            success: (data, textStatus, jqXHR) => {
                if (data.otherLeaves.length === 0) {
                    if (this.props.onEmpty) {
                        this.props.onEmpty();
                        return;
                    }
                }
                this.setState({
                    otherLeaves: {
                        value: OtherLeaves.prepareOtherLeaves(data.otherLeaves, caches),
                        status: STATUS.READY
                    }
                });
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
                this.setState({
                    otherLeaves: {
                        value: undefined,
                        status: STATUS.ERROR
                    },
                });
            },
        });
    }

    render() {
        const session: any = this.getSession().getState();
        let ready = session.status === STATUS.READY && this.state.otherLeaves.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (<Table otherLeaves={this.state.otherLeaves} />);
    }

    static prepareOtherLeaves(rows: any, caches: any) {
        const usersMap = caches.users.map;
        const leaveTypesMap = caches.leaveTypes.map;
        return rows.map((row: any) => {
            const leave: any = {};
            let i = 0;
            leave.companyUser = usersMap[row[i++]];
            leave.leaveType = leaveTypesMap[row[i++]];
            leave.startDate = row[i++];
            leave.endDate = row[i++];
            leave.status = row[i++];
            leave.dayType = row[i++];
            return leave;
        });
    }
}