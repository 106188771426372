import * as $ from 'jquery';
import Component from '../../Component';
import CheckBox from '../../Components/CheckBox';
import Submit from '../../Components/Submit';
import { STATUS } from '../../General';
import BraintreeDropIn from './BraintreeDropIn';
import Table from './Table';

export default class ReviewAndOrder extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            braintreeSettings: {
                value: undefined,
                status: STATUS.LOADING
            },
            tc: {
                value: true,
            },
            braintree: {
                status: STATUS.LOADING
            },
            orderAndPay: {
                pleaseWait: false
            },
            braintreeNonce: undefined,
            braintreeBin: undefined,
            threeDSecureAuthenticationId: undefined,
            paymentMethodValid: false,
            braintreeWebDropIn: {}
        };
    }

    componentDidMount() {
        this.getBraintreeSettings();
    }

    getBraintreeSettings() {
        $.ajax({
            type: 'GET',
            contentType: 'application/json',
            url: this.Endpoints().getBraintreeSettings(this.props.subscriptionOrder.currency),
            dataType: 'json',
            cache: false,
            success: (data, textStatus, jqXHR) => {
                this.setState({
                    braintreeSettings: {
                        value: data,
                        status: STATUS.READY
                    }
                });
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
                this.setState({
                    orderSettings: {
                        value: undefined,
                        status: STATUS.ERROR
                    },
                });
            },
        });
    }

    render() {
        let ready = this.state.braintreeSettings.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        const t = this.translate();
        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <form onSubmit={(event: any) => { this.submit(event); }}>
                    <fieldset>
                        <div className="row">
                            <div className="col-12">
                                <Table subscriptionOrder={this.props.subscriptionOrder} />
                            </div>
                            <div className="col-12 mb-3">
                                <BraintreeDropIn
                                    subscriptionOrder={this.props.subscriptionOrder}
                                    braintreeSettings={this.state.braintreeSettings.value}
                                    braintreeWebDropIn={this.state.braintreeWebDropIn}
                                    onBraintreeWebDropInReady={() => {
                                        this.setState({ paymentMethodValid: true });
                                    }}
                                    onNoPaymentMethodRequestable={() => {
                                        this.setState({ paymentMethodValid: false, braintreeNonce: undefined, threeDSecureAuthenticationId: undefined });
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <CheckBox
                                    defaultValue={this.state.tc.value}
                                    onChange={(value: any) => {
                                        this.setState({ tc: Object.assign({}, this.state.tc, { value: value }) });
                                    }}
                                >
                                    <h6 className="m-0 d-inline">{t('i.have.read.and.agree.to.the') + ' '}
                                        <a className="card-link" href={this.Urls().getTerms(this.language())} target="_blank" title={t('terms.of.use')} rel="noopener noreferrer">
                                            {t('terms.of.use')}
                                        </a>
                                    </h6>
                                </CheckBox>
                            </div>
                            <div className="col-12 mb-3">
                                <Submit
                                    faIcon="fa fa-shopping-cart"
                                    disabled={!this.isFormValid()}
                                    pleaseWait={this.state.orderAndPay.pleaseWait}
                                    color={this.state.braintreeNonce ? 'success btn-lg' : 'outline-primary'}
                                >
                                    {this.state.braintreeNonce ? t('pay') : t('validate.card')}
                                </Submit>
                            </div>
                            {!this.state.braintreeNonce ?
                                <div className="col-12 mb-3">
                                    <small>{t('note.we.accept.only.cards.from.visa.and.mastercard')}</small>
                                </div>
                                : <></>}
                        </div>
                    </fieldset>
                </form>
            </div>
        </div >
    }

    isFormValid() {
        return this.state.paymentMethodValid && this.state.tc.value;
    }

    //https://braintree.github.io/braintree-web/3.86.0/ThreeDSecure.html#on
    validateDropin() {
        const t = this.translate();
        if (!this.state.braintreeNonce && this.state.paymentMethodValid) {
            this.setState({ paymentMethodValid: false }, () => {
                const requestPaymentMethodOptions: any = {};
                const subscriptionOrder = this.props.subscriptionOrder;
                const amount = (+(subscriptionOrder.pricePerUser *
                    (subscriptionOrder.interval - subscriptionOrder.discountInterval) * subscriptionOrder.users).toFixed(2)
                    +
                    +(((+(subscriptionOrder.pricePerUser *
                        (subscriptionOrder.interval - subscriptionOrder.discountInterval) * subscriptionOrder.users).toFixed(2) *
                        (subscriptionOrder.vatEnabled && subscriptionOrder.vatRate ? (subscriptionOrder.vatRate / 100) : 0))).toFixed(2))).toFixed(2);
                const email = this.props.subscriptionOrder.billingInformation.email.substring(0, 255);
                const phone = this.props.subscriptionOrder.billingInformation.phone.replace(/[()\s-]/g, '').substring(0, 25);
                requestPaymentMethodOptions.threeDSecure = {
                    amount: amount,
                    challengeRequested: true,
                    email: email,
                    phone: phone,
                    mobilePhoneNumber: phone,
                    billingAddress: {
                        givenName: this.props.subscriptionOrder.billingInformation.firstName.substring(0, 50),
                        surname: this.props.subscriptionOrder.billingInformation.lastName.substring(0, 50),
                        phoneNumber: phone,
                        streetAddress: this.props.subscriptionOrder.billingInformation.address ? this.props.subscriptionOrder.billingInformation.address.substring(0, 50) : '',
                        locality: this.props.subscriptionOrder.billingInformation.city.substring(0, 50),
                        postalCode: this.props.subscriptionOrder.billingInformation.postalCode.substring(0, 50),
                        countryCodeAlpha2: this.props.subscriptionOrder.billingInformation.country.substring(0, 2),
                    },
                    //https://braintree.github.io/braintree-web/current/ThreeDSecure.html#~additionalInformation
                    additionalInformation: {
                        workPhoneNumber: phone,
                    }
                };
                this.state.braintreeWebDropIn.braintreeWebDropIn.requestPaymentMethod(requestPaymentMethodOptions, (requestPaymentMethodErr: any, payload: any) => {
                    if (requestPaymentMethodErr) {
                        if (requestPaymentMethodErr?.message) {
                            this.errorToastr(requestPaymentMethodErr.message);
                            return;
                        }
                        this.generalErrorToastr();
                        return;
                    }
                    if (payload?.liabilityShiftPossible) {
                        if (!payload?.liabilityShifted) {
                            this.errorToastr(t('3dsecure.validation.failed'));
                            return;
                        }
                    }
                    this.setState({ paymentMethodValid: true, braintreeNonce: payload.nonce, threeDSecureAuthenticationId: payload.threeDSecureInfo.threeDSecureAuthenticationId });
                });
            });
            return false;
        }
        return true;
    }

    submit(event: any) {
        event.preventDefault();
        if (this.isFormValid() || false) {
            if (this.validateDropin() === false) return;
            const subscriptionOrder = Object.assign({}, this.props.subscriptionOrder);
            delete subscriptionOrder.country;
            $.ajax({
                type: 'PUT',
                contentType: 'application/json',
                url: this.Endpoints().getSubscriptionOrder(),
                dataType: 'json',
                cache: false,
                data: JSON.stringify(
                    {
                        subscriptionOrder: subscriptionOrder,
                        braintreeNonce: this.state.braintreeNonce,
                        threeDSecureAuthenticationId: this.state.threeDSecureAuthenticationId
                    }
                ),
                beforeSend: () => {
                    this.setState({ orderAndPay: { pleaseWait: true } });
                },
                success: (data, textStatus, jqXHR) => {
                    this.setState({ braintreeNonce: null });
                    if (this.props.onOrdered) {
                        this.props.onOrdered();
                    }
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    this.ajaxError(jqXHR, textStatus, errorThrown);
                    this.setState({ orderAndPay: { pleaseWait: false } });
                },
            });
        }
    }
}