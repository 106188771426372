import * as React from 'react';

export default class Error extends React.PureComponent {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center text-info">
                        <i className="fa fa-exclamation-triangle fa-3x" aria-hidden="true" />
                        Error
                    </div>
                </div>
            </div>
        );
    }
}