import * as React from 'react';
import Component from '../../../Component';
import Line from '../../../Components/Line';
import Submit from '../../../Components/Submit';
import InputField from '../../../Components/InputField';
import TextareaField from '../../../Components/TextareaField';
import Tabs from '../Tabs';
import * as $ from 'jquery';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            subject: {
                touched: false,
                rules: this.Rules().BasicInputRules()
            },
            message: {
                touched: false,
                rules: this.Rules().BasicTextareaRules()
            },
            register: {
                pleaseWait: false
            },
        };
    }

    render() {
        const t = this.translate();
        return <div className="container-fluid">
            <div className="row">
                <div className="col-12 mb-3">
                    <Tabs
                        currentTab={this.props.currentTab}
                        // tslint:disable-next-line:max-line-length
                        onChange={(value: any) => { if (this.props.onTabChange) { this.props.onTabChange(value); } }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <Line />
                </div>
            </div>
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <form onSubmit={(event: any) => { this.submit(event); }}>
                        <fieldset>
                            <div className="row bg-white">
                                <div className="col-12 mt-3 mb-3">
                                    <InputField
                                        touched={this.state.subject.touched}
                                        rules={this.state.subject.rules}
                                        type="text"
                                        placeholder={t('subject')}
                                        focus={this.isScreenSizeSmall() ? false : true}
                                        onChange={(value: any) => {
                                            this.setState(
                                                // tslint:disable-next-line:max-line-length
                                                { subject: Object.assign({}, this.state.subject, { value: value }) }
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <TextareaField
                                        touched={this.state.message.touched}
                                        rules={this.state.message.rules}
                                        type="text"
                                        rows={4}
                                        placeholder={t('message')}
                                        onChange={(value: any) => {
                                            this.setState(
                                                // tslint:disable-next-line:max-line-length
                                                { message: Object.assign({}, this.state.message, { value: value }) }
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <Submit
                                        faIcon="far fa-paper-plane"
                                        disabled={!this.isFormValid()}
                                        pleaseWait={this.state.register.pleaseWait}
                                    >
                                        {t('register.ticket')}
                                    </Submit>
                                </div>
                                <div className="col-12">
                                    <small className="text-muted">
                                        <span>{[t('also.you.can.contact.us.by.email')]}
                                            <a
                                                className="card-link"
                                                // tslint:disable-next-line:max-line-length
                                                href={'mailto:support@leaveboard.com?subject=' + t('support')}
                                            >
                                                support@leaveboard.com
                                                </a>
                                        </span>
                                    </small>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        </div>
    }

    isFormValid() {
        return this.Validator().validate(this.state.subject)
            && this.Validator().validate(this.state.message);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                subject: Object.assign({}, this.state.subject, { touched: true }),
                message: Object.assign({}, this.state.message, { touched: true })
            },
            () => {
                if (this.isFormValid()) {
                    this.sendTicket();
                }
            }
        );
    }

    sendTicket() {
        const t = this.translate();
        $.ajax({
            type: 'PUT',
            contentType: 'application/json',
            url: this.Endpoints().getTicketCreate(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                subject: this.state.subject.value,
                message: this.state.message.value,
            }),
            beforeSend: () => {
                this.setState({ register: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('your.ticket.was.registered'));
                if (this.props.onTicketSent) {
                    this.props.onTicketSent();
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            },
            complete: (jqXHR, textStatus) => {
                this.setState({ register: { pleaseWait: false } });
            }
        });
    }
}