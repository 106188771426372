import * as React from 'react';
import Component from '../Component';
import Anchor from './Anchor';
import Tooltip from './Tooltip';

export default class CheckBox extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            value: this.props.defaultValue ? this.props.defaultValue : '',
            defaultValue: this.props.defaultValue ? this.props.defaultValue : '',
            touched: false,
            rules: this.props.rules,
            valid: null,
            load: this.props.load ? this.props.load : false
        };

    }

    render() {
        return (
            <div>
                <label>
                    <Anchor
                        href="##"
                        className={(this.props.disabled ? ' text-muted' : '')}
                        onClick={() => {
                            if (!this.props.disabled) {
                                this.setState(
                                    { value: !this.state.value },
                                    () => { if (this.props.onChange) { this.props.onChange(this.state.value); } });
                            }
                        }}
                        faIcon={'mr-1 fa-fw ' + (this.state.value ? 'fas fa-check-square' : 'far fa-square')}
                    />
                    {this.props.children} {' '}
                    {
                        (this.props.tooltip && this.props.tooltip.length > 0) ?
                            (<Tooltip textColor="info" tooltip={this.props.tooltip} />)
                            :
                            null
                    }
                </label>
            </div>
        );
    }
}