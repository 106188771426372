import * as React from 'react';
import Component from '../Component';
import Anchor from './Anchor';
import { ORDER } from '../General';

export default class GridOrList extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            order: this.props.order
        };
    }

    render(): any {
        const t = this.translate();
        return (
            <span>
                <Anchor
                    title={t('sort')}
                    onClick={() => { this.setState({ order: (this.state.order === ORDER.ASC) ? ORDER.DESC : ORDER.ASC }, () => { if (this.props.onChange) { this.props.onChange(this.state.order); } }); }}
                    rightFaIcon={((this.state.order === ORDER.ASC) ? 'fa fa-long-arrow-alt-up' : 'fa fa-long-arrow-alt-down')}
                >
                    {this.props.title}
                </Anchor>
            </span>
        );
    }
}