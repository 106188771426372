import * as React from 'react';
import Component from '../../Component';
import Anchor from '../Anchor';
import { sendLeaveReminder } from '../../Actions/Modal';

export default class LeaveSendReminderAction extends Component {
    render() {
        const t = this.translate();
        return (
            <Anchor
                faIcon="far fa-paper-plane"
                className="text-info"
                title={t('send.a.reminder')}
                onClick={() => {
                    sendLeaveReminder(
                        this.getModal(),
                        {
                            leave: this.props.leave,
                            callback: () => {
                                if (this.props.onClick) {
                                    this.props.onClick();
                                }
                            }
                        }
                    );
                }}
            >
                {this.props.noText ? null : t('send.a.reminder')}
            </Anchor>
        );
    }
}