import * as React from 'react';
import Component from '../../Component';
import Tabs from './Tabs';
import Profile from './Profile/Profile';
import Integrations from './Integrations/Integrations';
import Balance from './Balance/Balance';
import Settings from './Settings/Settings';
import { MYACCOUNTTABS } from '../../General';
import Line from '../../Components/Line';

export default class MyAccount extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            currentTab: this.getCurrentTab(MYACCOUNTTABS.PROFILE, MYACCOUNTTABS),
        };
    }

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Tabs
                            currentTab={this.state.currentTab}
                            onChange={(value: any) => { this.setState({ currentTab: value, editMode: false }); }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <Line />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        {this.getTab()}
                    </div>
                </div>
            </div>
        );
    }

    getTab() {
        switch (this.state.currentTab) {
            case MYACCOUNTTABS.BALANCE:
                return <Balance />;
            case MYACCOUNTTABS.INTEGRATIONS:
                return <Integrations />;
            case MYACCOUNTTABS.SETTINGS:
                return <Settings />;
            case MYACCOUNTTABS.PROFILE:
            default:
                return <Profile />;
        }
    }
}