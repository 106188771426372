import * as React from 'react';
import Component from '../Component';

export default class Footer extends Component {
    render() {
        if ((this.getSession().getState() as any).logged === true || this.isScreenSizeSmall()) {
            return null;
        }
        return (
            <div className="container-fluid fixed-bottom ml-0 pl-0 mr-0 pr-0 bg-white">
                <div className="row">
                    <div className="col-12">
                        <div className="text-center text-dark d-none d-sm-block">&copy;&nbsp;LeaveBoard.com</div>
                    </div>
                </div>
            </div>
        );
    }
}