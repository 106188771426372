import * as React from 'react';
import Component from '../../../Component';
import Anchor from '../../../Components/Anchor';
import { Popover, PopoverBody } from 'reactstrap';
import { doFetchLeaveTypesFromServer } from '../../../Actions/Caches';
import * as $ from 'jquery';

export default class MoreActions extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            popoverOpen: false
        };
    }

    toggle() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }

    render() {
        const t = this.translate();
        return (
            <span>
                <Anchor
                    id={'MoreActions-' + this.cid}
                    className="btn btn-outline-primary border-0 rounded-circle m-0 p-0 pl-1 pr-1"
                    title={t('more.actions')}
                    faIcon="fas fa-ellipsis-h"
                    onClick={() => { this.toggle(); }}
                />
                <Popover
                    placement="bottom"
                    isOpen={this.state.popoverOpen}
                    target={'MoreActions-' + this.cid}
                    toggle={() => {
                        this.toggle();
                    }}
                >
                    <PopoverBody>
                        {this.getEnableDisable()}
                    </PopoverBody>
                </Popover>
            </span>
        );
    }

    getEnableDisable() {
        const t = this.translate();
        return (
            <div className="m-1">
                {
                    (this.props.leaveType.active) ?
                        (
                            <Anchor
                                title={t('disable')}
                                faIcon="fas fa-times"
                                onClick={() => {
                                    $.ajax({
                                        type: 'POST',
                                        contentType: 'application/json',
                                        url: this.Endpoints().getLeaveTypeDisable(this.props.leaveType.id),
                                        dataType: 'json',
                                        cache: false,
                                        success: (data: any, textStatus: any, jqXHR: any) => {
                                            // tslint:disable-next-line:max-line-length
                                            this.successToastr(t('the.leave.type.was.disabled'));
                                            doFetchLeaveTypesFromServer(this.getCaches());
                                            if (this.props.onRefresh) {
                                                this.props.onRefresh();
                                            }
                                            this.toggle();
                                        },
                                        // tslint:disable-next-line:max-line-length
                                        error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                                            this.ajaxError(jqXHR, textStatus, errorThrown);
                                        }
                                    });
                                }}
                            >
                                {t('disable')}
                            </Anchor>
                        )
                        :
                        (
                            <Anchor
                                title={t('enable')}
                                faIcon="fas fa-level-up-alt"
                                onClick={() => {
                                    $.ajax({
                                        type: 'POST',
                                        contentType: 'application/json',
                                        url: this.Endpoints().getLeaveTypeEnable(this.props.leaveType.id),
                                        dataType: 'json',
                                        cache: false,
                                        success: (data: any, textStatus: any, jqXHR: any) => {
                                            // tslint:disable-next-line:max-line-length
                                            this.successToastr(t('the.leave.type.was.enabled'));
                                            doFetchLeaveTypesFromServer(this.getCaches());
                                            if (this.props.onRefresh) {
                                                this.props.onRefresh();
                                            }
                                            this.toggle();
                                        },
                                        // tslint:disable-next-line:max-line-length
                                        error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                                            this.ajaxError(jqXHR, textStatus, errorThrown);
                                        }
                                    });
                                }}
                            >
                                {t('enable')}
                            </Anchor>
                        )
                }
            </div>
        );
    }
}