import { RESIZE } from '../Constants/ActionTypes';
import * as $ from 'jquery';

export const resize = (screenSize: any, message: any) => {
    ((dispatch, getState) => {
        dispatch({
            type: RESIZE,
            message: message,
        });
    })(screenSize.dispatch, screenSize.getState);
};

export const doResize = (screenSize: any) => {
    const state = {
        xs: ($ as any)('#screenSize > .device-xs').is(':visible'),
        sm: ($ as any)('#screenSize > .device-sm').is(':visible'),
        md: ($ as any)('#screenSize > .device-md').is(':visible'),
        lg: ($ as any)('#screenSize > .device-lg').is(':visible'),
        xl: ($ as any)('#screenSize > .device-xl').is(':visible'),
        listColSize: 'col-4'
    };
    if (state.md) {
        state.listColSize = 'col-6';
    }
    if (state.xs || state.sm) {
        state.listColSize = 'col-12';
    }
    resize(screenSize, state);
};