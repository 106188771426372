import * as $ from 'jquery';
import 'moment';
import * as moment from 'moment-timezone';
import {
    COUNTRIES_CACHE, LEAVETYPES_CACHE, OFFICES_CACHE, SUBSIDIARIES_CACHE, TIMEZONES_CACHE,
    UNITS_CACHE, USERS_CACHE
} from '../Constants/ActionTypes';
import Endpoints from '../Endpoints';
import { leaveTypesFactory, unitsFactory, usersFactory } from '../Factories';

// import { errorGeneral } from '../Actions/Toastr';
//import session from '../Reducers/Session';

export const doFetchCountriesFromServer = (caches: any, lang: any) => {
    caches.dispatch({ type: COUNTRIES_CACHE.LOADING });
    $.ajax({
        type: 'GET',
        contentType: 'application/json',
        url: Endpoints.getCountries(lang),
        dataType: 'json',
        cache: false,
        success: (data: any, textStatus: any, jqXHR: any) => {
            caches.dispatch({ type: COUNTRIES_CACHE.READY, countries: data, lang: lang });
        },
        error: (jqXHR: any, textStatus: any, errorThrown: any) => {
            caches.dispatch({ type: COUNTRIES_CACHE.ERROR });
            // errorGeneral(this.store);
        },
    });
};

export const doFetchTimezonesFromServer = (caches: any) => {
    caches.dispatch({ type: TIMEZONES_CACHE.LOADING });
    $.ajax({
        type: 'GET',
        contentType: 'application/json',
        url: Endpoints.getTimezones(),
        dataType: 'json',
        cache: false,
        success: (data: any, textStatus: any, jqXHR: any) => {
            let momentTimezones = moment.tz.names();
            let i18nTimezones = [];
            for (let timezone of data) {
                if (momentTimezones.indexOf(timezone) >= 0) {
                    if (timezone === 'EST')
                        continue;
                    i18nTimezones.push(timezone);
                }
            }
            caches.dispatch({ type: TIMEZONES_CACHE.READY, timezones: i18nTimezones });
        },
        error: (jqXHR: any, textStatus: any, errorThrown: any) => {
            caches.dispatch({ type: TIMEZONES_CACHE.ERROR });
            // errorGeneral(this.store);
        },
    });
};

export const doFetchUsersFromServer = (caches: any, session: any) => {
    caches.dispatch({ type: USERS_CACHE.LOADING });
    $.ajax({
        type: 'GET',
        contentType: 'application/json',
        url: Endpoints.getEmployeesSearch({ filters: { statuses: 'INVITED,ACTIVE,DISABLED' } }),
        dataType: 'json',
        cache: false,
        success: (data, textStatus, jqXHR) => {
            caches.dispatch({
                type: USERS_CACHE.READY,
                users: usersFactory(data.data, session)
            });
        },
        error: (jqXHR, textStatus, errorThrown) => {
            //            errorGeneral(this.store);
            caches.dispatch({ type: USERS_CACHE.ERROR });
        },
    });
};

export const doFetchUnitsFromServer = (caches: any) => {
    caches.dispatch({ type: UNITS_CACHE.LOADING });
    $.ajax({
        type: 'GET',
        contentType: 'application/json',
        url: Endpoints.getLiteUnits(),
        dataType: 'json',
        cache: false,
        success: (data, textStatus, jqXHR) => {
            caches.dispatch({
                type: UNITS_CACHE.READY,
                units: unitsFactory(data.data).sort((a: any, b: any) => {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase(), 'en', { sensitivity: 'base' })
                })
            });
        },
        error: (jqXHR, textStatus, errorThrown) => {
            //            errorGeneral(this.store);
            caches.dispatch({ type: UNITS_CACHE.ERROR });
        },
    });
};

export const doFetchLeaveTypesFromServer = (caches: any) => {
    caches.dispatch({ type: LEAVETYPES_CACHE.LOADING });
    $.ajax({
        type: 'GET',
        contentType: 'application/json',
        url: Endpoints.getLeaveTypesLite(),
        dataType: 'json',
        cache: false,
        success: (data, textStatus, jqXHR) => {
            caches.dispatch({
                type: LEAVETYPES_CACHE.READY,
                leaveTypes: leaveTypesFactory(data.data)
            });
        },
        error: (jqXHR, textStatus, errorThrown) => {
            //            errorGeneral(this.store);
            caches.dispatch({ type: LEAVETYPES_CACHE.ERROR });
        },
    });
};

export const doFetchOfficesFromServer = (caches: any) => {
    caches.dispatch({ type: OFFICES_CACHE.LOADING });
    $.ajax({
        type: 'GET',
        contentType: 'application/json',
        url: Endpoints.getOffices(),
        dataType: 'json',
        cache: false,
        success: (data, textStatus, jqXHR) => {
            caches.dispatch({
                type: OFFICES_CACHE.READY,
                offices: data.data.sort((a: any, b: any) => {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase(), 'en', { sensitivity: 'base' })
                })
            });
        },
        error: (jqXHR, textStatus, errorThrown) => {
            //            errorGeneral(this.store);
            caches.dispatch({ type: OFFICES_CACHE.ERROR });
        },
    });
};

export const doFetchSubsidiariesFromServer = (caches: any) => {
    caches.dispatch({ type: SUBSIDIARIES_CACHE.LOADING });
    $.ajax({
        type: 'GET',
        contentType: 'application/json',
        url: Endpoints.getSubsidiaries(),
        dataType: 'json',
        cache: false,
        success: (data, textStatus, jqXHR) => {
            caches.dispatch({
                type: SUBSIDIARIES_CACHE.READY,
                subsidiaries: data.data.sort((a: any, b: any) => {
                    return a.name.toLowerCase().localeCompare(b.name.toLowerCase(), 'en', { sensitivity: 'base' })
                })
            });
        },
        error: (jqXHR, textStatus, errorThrown) => {
            //            errorGeneral(this.store);
            caches.dispatch({ type: SUBSIDIARIES_CACHE.ERROR });
        },
    });
};