import * as React from 'react';
import { adjustEmployeeLeaveTypeAllowance } from '../../../Actions/Modal';
import Component from '../../../Component';
import AEmployee from '../../../Components/AEmployee';
import Anchor from '../../../Components/Anchor';
import Cog from '../../../Components/Cog';

export default class Item extends Component {
    constructor(props?: any) {
        super(props);
        this.handleAdjustEmployeeLeaveTypeAllowance = this.handleAdjustEmployeeLeaveTypeAllowance.bind(this);
        this.toggle = this.toggle.bind(this);
        this.state = { isAllowanceExpanded: false };
    }

    toggle() {
        this.setState({ isAllowanceExpanded: !this.state.isAllowanceExpanded });
    }

    render() {
        const t = this.translate();
        let employeeLeaveTypePerYear = this.props.employeeLeaveTypePerYear;
        const employee = employeeLeaveTypePerYear.employee;
        let leaveTypePerYear = employeeLeaveTypePerYear;
        const year = leaveTypePerYear.data[4];
        const allowance = leaveTypePerYear.data[9];
        const adjustment = leaveTypePerYear.data[10];
        const receivedFromLastYear = leaveTypePerYear.data[11];
        const transferredToNextYear = leaveTypePerYear.data[12];
        return [
            (
                <tr key="Item">
                    <td rowSpan={2}>
                        <AEmployee employee={employee} />
                    </td>
                    <td className="text-right">
                        {adjustment || receivedFromLastYear || transferredToNextYear ?
                            <Anchor onClick={this.toggle}><strong>{this.getEmployeeAllowance(allowance, adjustment, receivedFromLastYear)}</strong></Anchor>
                            : this.getEmployeeAllowance(allowance, adjustment, receivedFromLastYear)}
                    </td>
                    <td className="text-right" style={{ width: '1%' }}>
                        <Cog title={t('adjust.allowance')} onClick={this.handleAdjustEmployeeLeaveTypeAllowance} />
                    </td>
                </tr>
            ),
            (
                <tr key="Days"><td colSpan={2} className="border-0 text-right pt-0 pb-0">
                    <small>{t('days.per.year')}</small>
                </td></tr>
            ),
            this.state.isAllowanceExpanded ? (
                <tr key="default.allowance">
                    <td className="border-0 text-left pt-0 pb-0">
                        {t('default.allowance')}
                    </td>
                    <td className="border-0 text-right pt-0 pb-0">{allowance}</td>
                    <td className="border-0 pt-0 pb-0"></td></tr>
            ) : null,
            this.state.isAllowanceExpanded && adjustment ? (
                <tr key="adjustment">
                    <td className="border-0 text-left pt-0 pb-0">
                        {t('adjustment')}
                    </td>
                    <td className="border-0 text-right pt-0 pb-0">{adjustment}</td>
                    <td className="border-0 pt-0 pb-0"></td></tr>
            ) : null,
            this.state.isAllowanceExpanded && receivedFromLastYear ? (
                <tr key="transferred.from">
                    <td className="border-0 text-left pt-0 pb-0">
                        {t('transferred.from') + ' ' + (year - 1)}
                    </td>
                    <td className="border-0 text-right pt-0 pb-0">{receivedFromLastYear}</td>
                    <td className="border-0 pt-0 pb-0"></td></tr>
            ) : null,
            this.state.isAllowanceExpanded && transferredToNextYear ? (
                <tr key="transferred.to">
                    <td className="border-0 text-left pt-0 pb-0">
                        <small>{t('transferred.to') + ' ' + (year + 1)}</small>
                    </td>
                    <td className="border-0 text-right pt-0 pb-0"><small>{transferredToNextYear ? transferredToNextYear : 0}</small></td>
                    <td className="border-0 pt-0 pb-0"></td></tr>
            ) : null,
        ];
    }

    handleAdjustEmployeeLeaveTypeAllowance() {
        let employeeLeaveTypePerYear = this.props.employeeLeaveTypePerYear;
        adjustEmployeeLeaveTypeAllowance(
            this.getModal(),
            {
                leaveType: this.props.leaveType,
                employee: employeeLeaveTypePerYear.employee,
                leaveTypePerYear: {
                    supplemental: employeeLeaveTypePerYear.data[10],
                    year: employeeLeaveTypePerYear.data[4]
                },
                callback: () => { if (this.props.onRefresh) { this.props.onRefresh(); } }
            },
        );
    }

    getEmployeeAllowance(allowance: any, adjustment: any, receivedFromLastYear: any) {
        const value = allowance + adjustment + (receivedFromLastYear ? receivedFromLastYear : 0);
        return (<span>{value}</span>);
    }
}