import * as $ from 'jquery';
import Component from '../../../Component';
import { filtersFormUrlFactory } from '../../../Factories';
import { ORDER, STATUS } from '../../../General';
import { handleJQXHRError, Jqxhrs } from '../../../Utils';
import Table from './Table';

export default class Manager extends Component {
    protected jqxhrs = new Jqxhrs();
    constructor(props?: any) {
        super(props);
        this.state = {
            filters: this.createFilters(),
            employees: { arr: [], status: STATUS.LOADING },
        };
    }

    createFilters() {
        const moment = this.getMomentWithLocale();
        let filters = {
            orderBy: { field: 'DATE', order: ORDER.DESC },
            startDate: { value: moment().startOf('day') },
            endDate: { value: moment().startOf('day').add(1, 'y') }
        };
        filters = Object.assign({}, filters, filtersFormUrlFactory());
        filters = Object.assign({}, filters, this.props.filters);
        return filters;
    }

    componentDidMount() { this.search(); }

    search() {
        const moment = this.getMomentWithLocale();
        const now = moment().startOf('day');
        this.setState(
            { employees: { arr: [], status: STATUS.LOADING } },
            () => {
                this.jqxhrs.abortAndSet('search', $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getEmployeesYearsOfServiceSearch({ filters: this.state.filters }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        if (data.list.length === 0) {
                            if (this.props.onEmpty) { this.props.onEmpty(); }
                            return;
                        }
                        const list = data.list.map((row: any) => {
                            const ret = JSON.parse(row[1]);
                            ret.employmentStartDate = ret.employmentStartDate ? moment(ret.employmentStartDate) : null;
                            if (ret.employmentStartDate) {
                                if (now.format('MM-DD') === ret.employmentStartDate.format('MM-DD')) {
                                    ret.todayIsNewYearOfService = true;
                                }
                            }
                            ret.service = ret.employmentStartDate ? moment.duration(now.diff(moment(ret.employmentStartDate))) : null;
                            ret.next = ret.employmentStartDate ?
                                moment(ret.employmentStartDate).year(now.year()).isSameOrBefore(now) ?
                                    moment.duration(moment(ret.employmentStartDate).year(now.year() + 1).diff(now))
                                    : moment.duration(moment(ret.employmentStartDate).year(now.year()).diff(now))
                                : null;
                            if (ret.todayIsNewYearOfService) {
                                ret.next = moment.duration();
                            }
                            if (ret.employmentStartDate && ret.employmentStartDate.isSame(now)) {
                                ret.next = moment.duration(1, 'year');
                            }
                            return ret;
                        }).sort((a: any, b: any) => { return a.next - b.next; }).filter((row: any, pos: any) => { return pos < 10 });
                        this.setState({ employees: { arr: list, status: STATUS.READY } });
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        handleJQXHRError(jqXHR, textStatus, errorThrown, () => { this.setState({ data: { arr: null, count: 0, status: 'ERROR' } }); });
                    },
                    complete: (jqXHR: any, textStatus: any) => {
                        this.jqxhrs.complete('search');
                    }
                }));
            });
    }

    render() {
        return <div className="card h-100">
            <div className="card-body">
                <Table employees={this.state.employees} />
            </div>
        </div>
    }

    public componentWillUnmount() { this.jqxhrs.abortAll(); }
}