import * as React from 'react';
import { Tooltip } from 'reactstrap';
import Component from '../../../Component';
import Anchor from '../../../Components/Anchor';

export default class Comment extends Component {
    constructor(props: any) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { tooltipOpen: false };
    }

    public toggle() { this.setState({ tooltipOpen: !this.state.tooltipOpen }); }

    public render() {
        const config = this.config();
        return (
            <span>
                <Anchor className="ml-1" faIcon={config.fa} title={config.title} id={'CommentLeaves-' + this.cid} />
                <Tooltip
                    placement="top"
                    isOpen={this.state.tooltipOpen}
                    target={'CommentLeaves-' + this.cid}
                    toggle={this.toggle}
                >
                    {this.props.comment}
                </Tooltip>
            </span>
        );
    }

    public config() {
        const t = this.translate();
        switch (this.props.type) {
            case 'REQUEST':
                return { fa: 'fas fa-comment', title: t('request.comment') };
            case 'CANCELED':
                return { fa: 'fas fa-comment-slash', title: t('cancel.comment') };
            case 'APPROVED':
                return { fa: 'fas fa-comment-dots', title: t('comment') };
            case 'REJECTED':
            default:
                return { fa: 'far fa-comment-dots', title: t('comment') };
        }
    }
}