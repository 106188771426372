import * as $ from 'jquery';
import Component from '../../../Component';
import A from '../../../Components/A';
import Close from '../../../Components/Close';
import { COMPANYTABS, STATUS } from '../../../General';

export default class Administrator extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            slackApp: {
                value: undefined,
                status: STATUS.LOADING
            },
        };
    }

    componentDidMount() {
        this.load();
    }

    load() {
        this.setState(
            {
                slackApp: {
                    value: undefined,
                    status: STATUS.LOADING
                }
            },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getCompanySlackApp(),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            {
                                slackApp: {
                                    value: data.slackApp,
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                slackApp: {
                                    value: undefined,
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        let ready = this.state.slackApp.status === STATUS.READY;
        if (ready === false) { return null; }
        const t = this.translate();
        return <div className="card h-100">
            <div className="card-body">
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className="float-right">
                            <Close onClick={() => { if (this.props.onClose) { this.props.onClose(); } }} />
                        </div>
                        <h5 className="m-0">{t('welcome.to.leaveboard')}</h5>
                        <small>
                            {t('we.have.assembled.some.links.to.get.you.started')}
                        </small>
                    </div>
                </div>
                <div className="row">
                    <div className={(this.isScreenSizeSmall() ? 'col-12' : 'col-4') + ' mb-3'}>
                        <A
                            title={t('add.an.office')}
                            href={this.Urls().getEmployeesInvite()}
                        >
                            {t('invite.employees')}
                        </A>
                    </div>
                    <div className={this.isScreenSizeSmall() ? 'col-12  mb-3' : 'col-4'}>
                        <strong>{t('next.steps')}</strong>
                        <br />
                        <A
                            title={t('add.an.office')}
                            href={this.Urls().getOfficeAdd()}
                        >
                            {t('add.an.office')}
                        </A>
                        <br />
                        <A
                            title={t('add.a.team')}
                            href={this.Urls().getUnitAdd()}
                        >
                            {t('add.a.team')}
                        </A>
                    </div>
                    <div className={this.isScreenSizeSmall() ? 'col-12' : 'col-4'}>
                        <strong>{t('more.actions')}</strong>
                        <br />
                        <A
                            title={t('leave.settings')}
                            href={this.Urls().getLeaveTypes()}
                        >
                            {t('leave.settings')}
                        </A>
                        <br />
                        <A
                            title={t('company.settings')}
                            href={this.Urls().getCompany() + '#' + COMPANYTABS.SETTINGS}
                        >
                            {t('company.settings')}
                        </A>
                    </div>
                </div>
            </div>
        </div>
    }
}