import BaseTabs from '../../Components/BaseTabs';
import { MYACCOUNTTABS } from '../../General';

export default class Tabs extends BaseTabs {
    getTabs(): any[] {
        const t = this.translate();
        const metalCompanyNz = (this.getSession().getState() as any).company.id === 763;
        const tabs = [{
            faIcon: 'far fa-user',
            title: t('profile'),
            tab: MYACCOUNTTABS.PROFILE
        }];
        if (!metalCompanyNz)
            tabs.push({
                faIcon: 'fa fa-balance-scale',
                title: t('balance'),
                tab: MYACCOUNTTABS.BALANCE
            });
        tabs.push({
            faIcon: 'fa fa-link',
            title: t('integrations'),
            tab: MYACCOUNTTABS.INTEGRATIONS
        });
        tabs.push({
            faIcon: 'fas fa-wrench',
            title: t('settings'),
            tab: MYACCOUNTTABS.SETTINGS
        });

        return tabs;
    }

    getName(): string {
        return 'MyAccount';
    }
}