import * as React from 'react';
import Component from '../../Component';

export default class Table extends Component {
    render() {
        const subscriptionOrder = this.props.subscriptionOrder;
        const t = this.translate();
        const items: any[] = [];
        items.push(
            <tr key={'product'}>
                <td>
                    {(subscriptionOrder.interval === 12) ?
                        t('leaveboard.yearly.subscription') : t('leaveboard.monthly.subscription')}
                    <div className="text-lowercase">
                        {subscriptionOrder.users} {' ' + t('employees')}
                    </div>
                    <div className="text-muted">
                        <small>
                            {(subscriptionOrder.pricePerUser *
                                (subscriptionOrder.interval - subscriptionOrder.discountInterval)).toFixed(2)}
                            {' ' + subscriptionOrder.currency + ' '}
                            {(subscriptionOrder.interval === 12) ?
                                t('per.employee.yearly') : t('per.employee.monthly')}
                        </small>
                    </div>
                </td>
                <td className="text-right">
                    {(subscriptionOrder.pricePerUser *
                        (subscriptionOrder.interval - subscriptionOrder.discountInterval) *
                        subscriptionOrder.users).toFixed(2)}
                </td>
            </tr>
        );
        if (subscriptionOrder.vatEnabled) {
            items.push(
                <tr key={'vat'}>
                    <td>
                        {t('vat')}
                        <div className="float-right">
                            {subscriptionOrder.vatRate}%
                        </div>
                    </td>
                    <td className="text-right">
                        {((+(subscriptionOrder.pricePerUser *
                            (subscriptionOrder.interval - subscriptionOrder.discountInterval) * subscriptionOrder.users).toFixed(2) *
                            (subscriptionOrder.vatEnabled && subscriptionOrder.vatRate ? subscriptionOrder.vatRate / 100 : 0))).toFixed(2)}
                    </td>
                </tr>
            );
        }
        items.push(<tr key={'total'}>
            <td>
                <strong>{t('total')}</strong>
            </td>
            <td className="text-right">
                <strong className="text-nowrap">
                    {
                        (+(subscriptionOrder.pricePerUser *
                            (subscriptionOrder.interval - subscriptionOrder.discountInterval) * subscriptionOrder.users).toFixed(2)
                            +
                            +(((+(subscriptionOrder.pricePerUser *
                                (subscriptionOrder.interval - subscriptionOrder.discountInterval) * subscriptionOrder.users).toFixed(2) *
                                (subscriptionOrder.vatEnabled && subscriptionOrder.vatRate ? (subscriptionOrder.vatRate / 100) : 0))).toFixed(2))).toFixed(2)
                    }
                    {' ' + subscriptionOrder.currency}
                </strong>
            </td>
        </tr>);
        return <table className="table table-sm w-100 mb-0">
            <thead>
                <tr>
                    <th className="border-top-0">
                        {t('product')}
                    </th>
                    <th className="border-top-0 text-right">
                        {t('amount')}
                    </th>
                </tr>
            </thead>
            <tbody>
                {items}
            </tbody>
        </table>
    }
}