import * as React from 'react';
import Component from '../../../Component';
import Anchor from '../../../Components/Anchor';

export default class TBody extends Component {

    render() {
        const trs = this.props.rows.arr.map((row: any) => {
            const tds = row.dayEvents.map((dayEvent: any) => {
                const style: any = {};
                if (dayEvent.endOfMonth) {
                    style.borderRightColor = '#dee2e6';
                    style.borderRightWidth = '3px';
                }
                if (dayEvent.id === 0) {
                    return <td
                        className="text-center"
                        key={'CalendarTBodyTd-' + row.employee.id + '-' + dayEvent.day}
                        style={style}
                    >
                        {dayEvent.dayType === 3 ?
                            (dayEvent.working ? dayEvent.scheduled + '/' + dayEvent.hours :
                                dayEvent.scheduled - dayEvent.hours + '/' + dayEvent.hours) : dayEvent.hours}
                    </td>
                }
                style.background = dayEvent.background;
                return <td
                    className="text-center align-middle p-0"
                    key={'CalendarTBodyTd-' + row.employee.id + '-' + dayEvent.day}
                    style={style}
                >
                    {dayEvent.dayType === 3 ?
                        (dayEvent.working ? dayEvent.scheduled + '/' + dayEvent.hours :
                            dayEvent.scheduled - dayEvent.hours + '/' + dayEvent.hours) : dayEvent.hours}
                </td>
            });
            return <tr key={'CalendarTBodyTr-' + row.employee.id}>
                <td className="text-truncate border-0 shadow"
                    style={{ borderRight: '1px solid #DEE2E6', maxWidth: '150px', top: 0, left: 0, position: 'sticky', zIndex: 1, background: '#FFFFFF' }}>
                    <Anchor
                        className={'text-dark' + (row.employee.me ? ' font-weight-bold' : '')}
                        title={row.employee.fullName}
                    >
                        {row.employee.fullName}
                    </Anchor>
                </td>
                {tds}
                {this.props.leaveTypes.arr.map((leaveType: any) => {
                    return <th
                        className="text-center border-bottom-0"
                        key={'CalendarTBodyTd-' + row.employee.id + '-' + leaveType.id}
                    >
                        {row.leaveTypes[leaveType.id]}
                    </th>
                })}
            </tr>
        });
        return <tbody>
            {trs}
        </tbody>
    }
}