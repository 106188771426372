import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import Close from '../../../Components/Close';
import SelectField from '../../../Components/SelectField';
import Submit from '../../../Components/Submit';
import { languagesOptionsFactory, dateFormatOptionsFactory, timezonesOptionsFactory, timeFormatOptionsFactory } from '../../../SelectOptionsFactories';
import { doRefreshSession } from '../../../Actions/Session';

export default class Profile extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            timezone: {
                value: [this.props.employee.timezone],
                touched: false,
                //                rules: this.Rules().BasicSelectRules()
            },
            language: {
                value: [this.props.employee.language],
                touched: false,
                //                rules: this.Rules().BasicPasswordRules()
            },
            dateFormat: {
                value: [this.props.employee.dateFormat],
                touched: false,
                //                rules: this.Rules().BasicPasswordRules()
            },
            timeFormat: {
                value: [this.props.employee.timeFormat],
                touched: false,
                //                rules: this.Rules().BasicPasswordRules()
            },
            save: {
                pleaseWait: false
            }
        };
    }

    render() {
        const t = this.translate();
        const caches: any = this.getCaches().getState();
        const timezonesOptions = timezonesOptionsFactory(caches.timezones.arr);
        const languagesOptions = languagesOptionsFactory(t);
        const dateFormatOptions = dateFormatOptionsFactory(t);
        const timeFormatOptions = timeFormatOptionsFactory(t);
        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <form onSubmit={(event: any) => { this.submit(event); }}>
                    <fieldset>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <Close onClick={() => { if (this.props.onEdit) { this.props.onEdit(); } }} />
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.timezone.value}
                                    touched={this.state.timezone.touched}
                                    options={timezonesOptions}
                                    rules={this.state.timezone.rules}
                                    placeholder={t('timezone')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { timezone: Object.assign({}, this.state.timezone, { value: value }) }
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.language.value}
                                    touched={this.state.language.touched}
                                    options={languagesOptions}
                                    rules={this.state.timezone.rules}
                                    placeholder={t('language')}
                                    onChange={(value: any) => {
                                        this.setState(
                                            { language: Object.assign({}, this.state.language, { value: value }) }
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.dateFormat.value}
                                    touched={this.state.dateFormat.touched}
                                    options={dateFormatOptions}
                                    rules={this.state.dateFormat.rules}
                                    placeholder={t('date.format')}
                                    onChange={(value: any) => {
                                        this.setState({ dateFormat: Object.assign({}, this.state.dateFormat, { value: value }) });
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.timeFormat.value}
                                    touched={this.state.timeFormat.touched}
                                    options={timeFormatOptions}
                                    rules={this.state.timeFormat.rules}
                                    placeholder={t('time.format')}
                                    onChange={(value: any) => {
                                        this.setState({ timeFormat: Object.assign({}, this.state.timeFormat, { value: value }) });
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <Submit faIcon="far fa-hdd" disabled={!this.isFormValid()} pleaseWait={this.state.save.pleaseWait}>
                                    {t('save')}
                                </Submit>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    }

    isFormValid() {
        return this.Validator().validate(this.state.language)
            && this.Validator().validate(this.state.timezone);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                language: Object.assign({}, this.state.language, { touched: true }),
                timezone: Object.assign({}, this.state.timezone, { touched: true }),
                dateFormat: Object.assign({}, this.state.dateFormat, { touched: true }),
                timeFormat: Object.assign({}, this.state.timeFormat, { touched: true })
            },
            () => { if (this.isFormValid()) { this.save(); } });
    }

    save() {
        const t = this.translate();
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getEmployeeUpdateLite(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                language: this.state.language.value[0],
                timezone: this.state.timezone.value[0],
                dateFormat: this.state.dateFormat.value[0],
                timeFormat: this.state.timeFormat.value[0],
            }),
            beforeSend: () => {
                this.setState({ save: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('your.settings.were.updated'));
                this.setState({ save: { blocked: false } });
                doRefreshSession(this.getSession());
                if (this.props.onEdit) {
                    this.props.onEdit();
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
                this.setState({ save: { pleaseWait: false } });
            },
        });
    }
}