import BaseDownload from '../../../Components/BaseDownload';
import { fullNameFactory } from '../../../Factories';
import { findLocaleName } from '../../../Utils';
import Manager, { ANNUALROWTYPE } from './Manager';

/* eslint-disable @typescript-eslint/no-unused-expressions */
export default class Download extends BaseDownload {
    computeRows: any;
    getLeaveTypes: any;
    constructor(options: any) {
        super(options);
        this.search = Manager.search.bind(this);
        this.computeRows = Manager.computeRows.bind(this);
        this.getLeaveTypes = Manager.getLeaveTypes.bind(this);
    }

    getData() {
        const t = this.t;
        const session = this.getSession().getState() as any;
        const companyId = session.company.id;
        const header = companyId === 9118 ? [t('email'), t('employee'), t('year')] : [t('employee'), t('year')];
        this.getLeaveTypes().forEach((leaveType: any) => {
            //const name = this.getName(leaveType);
            header.push(this.getName(leaveType) + ' - ' + t('used'));
            header.push(this.getName(leaveType) + ' - ' + t('available'));
            if (leaveType.showEarnedAllowance && this.options.isCurrentYear)
                header.push(this.getName(leaveType) + ' - ' + t('earned'));
            header.push(this.getName(leaveType) + ' - ' + t('allowance'));
        });
        let currentFullName = '';
        const data: any = [];
        this.state.rows.arr.forEach((row: any) => {
            switch (row.type) {
                case ANNUALROWTYPE.EMPLOYEE: {
                    currentFullName = fullNameFactory(row.employee, session.company.nameFormat);
                    break;
                }
                case ANNUALROWTYPE.YEAR: {
                    const arr = companyId === 9118 ? [row.employee.email, currentFullName, row.year] : [currentFullName, row.year];
                    row.leaveTypes.forEach((leaveType: any) => {
                        arr.push(leaveType.amount);
                        arr.push(leaveType.allocation - leaveType.amount - leaveType.transferredToNextYear);
                        if (leaveType.leaveType.showEarnedAllowance && this.options.isCurrentYear) {
                            arr.push(this.getEmployeeEarnedTotalAllowance(leaveType.yearlyAllowance, leaveType.supplemental, leaveType.receivedFromLastYear, leaveType.pastYearDays, leaveType.totalYearDays));
                        }
                        arr.push(leaveType.allocation);
                    });
                    data.push(arr);
                }
            }
        })
        const arr = [header].concat(data);
        return arr;
    }

    public isReady() {
        return this.state.rows && this.state.rows.status === 'READY';
    }

    getFileName(this: any): any {
        const t = this.t;
        return this.ansi(t('annual.situation')) + this.getSufixFileName();
    }

    public getName(leaveType: any) {
        return findLocaleName(leaveType.names, this.language());
    }

    getEmployeeTotalAllowance(allowance: any, adjustment: any, receivedFromLastYear: any) {
        const value = allowance + adjustment + (receivedFromLastYear ? receivedFromLastYear : 0);
        return value;
    }

    getEmployeeEarnedTotalAllowance(yearlyAllowance: any, adjustment: any, receivedFromLastYear: any, pastDaysFromStartOfYear: any, totalYearDays: any) {
        if (pastDaysFromStartOfYear && pastDaysFromStartOfYear < 1) {
            return 0;
        }
        const value = this.getEmployeeEarnedAllowance(totalYearDays < 365 ? yearlyAllowance + adjustment : yearlyAllowance, pastDaysFromStartOfYear, totalYearDays) + (totalYearDays < 365 ? 0 : adjustment) + (receivedFromLastYear ? receivedFromLastYear : 0);
        if (value < 0) return 0;
        return value;
    }

    getEmployeeEarnedAllowance(yearlyAllowance: any, pastDaysFromStartOfYear: any, totalYearDays: any) {
        const value = Math.round(yearlyAllowance * pastDaysFromStartOfYear / totalYearDays);
        return value;
    }

    currentBusinessYear() {
        const businessYearStartMonth = (this.getSession().getState() as any).company.businessYearStartMonth;
        const businessYearStartDay = (this.getSession().getState() as any).company.businessYearStartDay;
        const currentMonth = this.getMomentWithLocale()().month() + 1;
        const currentDate = this.getMomentWithLocale()().date();
        if ((currentMonth < businessYearStartMonth) || (currentMonth === businessYearStartMonth && currentDate < businessYearStartDay)) {
            return this.getMomentWithLocale()().subtract('year', 1)
                .month(businessYearStartMonth)
                .date(businessYearStartDay)
                .startOf('day').year();
        }
        return this.getMomentWithLocale()().year();
    }
}