import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../Component';
import A from '../../Components/A';
import ETOL from '../../Components/ETOL';
import Fa from '../../Components/Fa';
import Line from '../../Components/Line';
import { ETOLTABS, STATUS } from '../../General';
import Filters from './Filters';
import List from './List';
import UpcomingBirthdays from './UpcomingBirthdays/UpcomingBirthdays';
import UpcomingNewServiceYear from './UpcomingNewServiceYear/UpcomingNewServiceYear';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            filters: {
                units: { value: [] },
                countries: { value: [] },
                offices: { value: [] },
                subsidiaries: { value: [] },
            },
            showUpcomingNewServiceYear: true,
            showUpcomingBirthdays: true,
            analytics: { item: null, status: STATUS.LOADING },
            status: STATUS.LOADING
        };
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState(
            { item: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getCompanyAnalytics({ filters: this.state.filters }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        const filters = this.state.filters;
                        if (filters?.offices?.value?.length > 0 || filters?.countries?.value?.length > 0) {
                            data.teamsCount = 0;
                        }
                        this.setState({ analytics: { item: data, status: STATUS.READY } });
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState({ analytics: { item: null, status: STATUS.ERROR } });
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        const t = this.translate();
        return <div className="container-fluid">
            <div className="row"><div className="col-12 mb-3"><ETOL currentTab={ETOLTABS.REPORTS} /></div></div>
            <div className="row"><div className="col-12 mb-3"><Line /></div></div>
            <div className="row">
                <div className="col-12 mb-3">
                    <div className="card w-100">
                        <div className="card-body">
                            <div className="d-flex flex-row bd-highlight">
                                <div className="mr-5 text-center">
                                    <A title={t('age')} href={this.Urls().getEmployeesAge()}><Fa fa="fas fa-birthday-cake" x={3} /><div>{t('age')}</div></A>
                                </div>
                                <div className="text-center">
                                    <A title={t('service.length')} href={this.Urls().getEmployeesServiceLength()}><Fa fa="fas fa-briefcase" x={3} /><div>{t('service.length')}</div></A>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Filters onChange={(value: any) => { this.setState({ filters: Object.assign({}, value), showUpcomingNewServiceYear: false, showUpcomingBirthdays: false }, () => { this.search(); this.setState({ showUpcomingNewServiceYear: true, showUpcomingBirthdays: true }) }); }} />
            <div className="row">
                <List analytics={this.state.analytics} />
            </div>
            <div className="row">
                {this.state.showUpcomingBirthdays ?
                    <div className={this.cardClass()}>
                        <UpcomingBirthdays filters={this.state.filters} onEmpty={() => { this.setState({ showUpcomingBirthdays: false }); }} />
                    </div>
                    : null}
                {this.state.showUpcomingNewServiceYear ?
                    <div className={this.cardClass()}>
                        <UpcomingNewServiceYear filters={this.state.filters} onEmpty={() => { this.setState({ showUpcomingNewServiceYear: false }); }} />
                    </div>
                    : null}
            </div>
        </div>
    }

    cardClass() {
        return 'mb-4 ' + this.getColLgSize();
    }

    getColLgSize() {
        switch (this.state.visibleItems) {
            case 5:
            case 4:
            case 3:
            case 1: return 'col-12 col-lg-6';
            case 2: return 'col-12 col-lg-6';
            default: return 'col-12 col-lg-6';
        }
    }
}