import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import SelectField from '../../../Components/SelectField';
import Submit from '../../../Components/Submit';
import ToggleField from '../../../Components/ToggleField';
import { leaveTypesOptionsFactory, maximumTransferableDaysOptionsFactory, officesOptionsFactory } from '../../../SelectOptionsFactories';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            maximumTransferableDays: {
                value: [30.0],
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            transferToAnotherLeaveType: {
                rules: this.Rules().BasicSelectRules(),
                value: false
            },
            leaveType: {
                value: [],
                defaultValue: [],
                touched: false,
                rules: this.Rules().BasicSelectRules(),
            },
            transferOnlyForTheFollowingOffices: {
                rules: this.Rules().BasicSelectRules(),
                value: false
            },
            offices: {
                value: [],
                defaultValue: [],
                touched: false,
                rules: this.Rules().BasicSelectRules(),
            },
            add: {
                pleaseWait: false
            },
        };
        this.transfer = this.transfer.bind(this);
    }

    render() {
        const t = this.translate();
        const maximumTransferableDaysOptions = maximumTransferableDaysOptionsFactory(t, this.props.leaveType.halfADayAllowed ? 0.5 : 1);
        const caches: any = this.getCaches().getState();
        const leaveTypesOptions = leaveTypesOptionsFactory(caches.leaveTypes.arr, this.language());
        const officesOptions = officesOptionsFactory((this.getCaches().getState() as any).offices.arr);
        return <form onSubmit={(event: any) => { this.submit(event); }}>
            <div className="row">
                <div className="col-12 mb-3">
                    <SelectField
                        defaultValue={this.state.maximumTransferableDays.value}
                        touched={this.state.maximumTransferableDays.touched}
                        options={maximumTransferableDaysOptions}
                        multiple={false}
                        placeholder={t('maximum.transferable.days')}
                        onChange={(value: any) => { this.setState({ maximumTransferableDays: Object.assign({}, this.state.maximumTransferableDays, { value: value }) }); }}
                    />
                </div>
                <div className="col-12 mb-3">
                    <ToggleField
                        defaultValue={this.state.transferToAnotherLeaveType.value}
                        onChange={(value: any) => { this.setState({ transferToAnotherLeaveType: Object.assign({}, this.state.transferToAnotherLeaveType, { value: value }) }); }}
                    >
                        {t('transfer.to.another.leave.type')}
                    </ToggleField>
                </div>
                {this.state.transferToAnotherLeaveType.value ?
                    <div className="col-12 mb-3">
                        <SelectField
                            defaultValue={this.state.leaveType.value}
                            touched={this.state.leaveType.touched}
                            options={leaveTypesOptions}
                            multiple={false}
                            placeholder={t('leave.type')}
                            maxDisplayedOptions={3}
                            onChange={(value: any) => {
                                this.setState({ leaveType: Object.assign({}, this.state.leaveType, { value }) });
                            }}
                        />
                    </div>
                    : null}
                {officesOptions?.length > 1 ? <>
                    <div className="col-12 mb-3">
                        <ToggleField
                            defaultValue={this.state.transferOnlyForTheFollowingOffices.value}
                            onChange={(value: any) => { this.setState({ transferOnlyForTheFollowingOffices: Object.assign({}, this.state.transferOnlyForTheFollowingOffices, { value: value }) }); }}
                        >
                            {t('transfer.only.for.the.following.offices')}
                        </ToggleField>
                    </div>
                    {this.state.transferOnlyForTheFollowingOffices.value ?
                        <div className="col-12 mb-3">
                            <SelectField
                                defaultValue={this.state.offices.value}
                                touched={this.state.offices.touched}
                                options={officesOptions}
                                multiple={true}
                                placeholder={t('offices')}
                                onChange={(value: any) => {
                                    this.setState({ offices: Object.assign({}, this.state.offices, { value }) },);
                                }}
                            />
                        </div> : null}
                </> : null}
                <div className="col-12 mb-3">
                    <Submit
                        faIcon="far fa-hdd"
                        disabled={!this.isFormValid()}
                        pleaseWait={this.state.add.pleaseWait}
                    >
                        {t('transfer')}
                    </Submit>
                </div>
            </div>
        </form>
    }

    isFormValid() {
        return this.Validator().validate(this.state.maximumTransferableDays)
            && (this.state.transferToAnotherLeaveType.value ? this.Validator().validate(this.state.leaveType) : true)
            && (this.state.transferOnlyForTheFollowingOffices.value ? this.Validator().validate(this.state.offices) : true);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            { maximumTransferableDays: Object.assign({}, this.state.maximumTransferableDays, { touched: true }), },
            () => { if (this.isFormValid()) { this.transfer(); } }
        );
    }

    transfer() {
        const maximumTransferableDays = this.state.maximumTransferableDays.value[0];
        const t = this.translate();
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getLeaveTypeTransfer(this.props.leaveType.id, this.props.year, maximumTransferableDays,
                this.state.transferToAnotherLeaveType.value ? this.state.leaveType.value : undefined,
                this.state.transferOnlyForTheFollowingOffices.value ? this.state.offices.value : undefined),
            dataType: 'json',
            cache: false,
            beforeSend: () => {
                this.setState({ add: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('the.transfer.was.executed'));
                if (this.props.onClose) {
                    this.props.onClose();
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            },
            complete: (jqXHR, textStatus) => {
                this.setState({ add: { pleaseWait: false } });
            }
        });
    }
}