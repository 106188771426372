import * as React from 'react';
import Component from '../Component';
import Anchor from './Anchor';
import { VIEWMODE } from '../General';

export default class GridOrListSwitch extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            viewMode: this.props.viewMode
        };
    }

    render(): any {
        const t = this.translate();
        return (this.isScreenSizeSmall()) ?
            null
            :
            (
                <Anchor
                    faIcon={(this.state.viewMode === VIEWMODE.GRID ? 'fas fa-th-list' : 'fas fa-th')}
                    title={this.state.viewMode === VIEWMODE.GRID ?
                        t('list.view') : t('grid.view')}
                    onClick={() => {
                        this.setState(
                            {
                                viewMode: (this.state.viewMode === VIEWMODE.GRID ?
                                    VIEWMODE.LIST : VIEWMODE.GRID)
                            },
                            () => {
                                if (this.props.onChange) {
                                    this.props.onChange(this.state.viewMode);
                                }
                            }
                        );
                    }}
                />
            );
    }
}