import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../Component';
import A from '../../Components/A';
import Line from '../../Components/Line';
import { COMPANYTABS, ORDER, STATUS, VIEWMODE } from '../../General';
import Tabs from '../Company/Tabs';
import Filters from './Filters';
import List from './List';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            filters: {
                search: { value: '' },
                viewMode: VIEWMODE.GRID,
                orderBy: { field: 'NAME', order: ORDER.ASC }
            },
            notificationRules: { arr: [], status: STATUS.LOADING }
        };
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState(
            { notificationRules: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getNotificationRules({ filters: this.state.filters }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState({ notificationRules: { arr: data.data, status: STATUS.READY } });
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            { notificationRules: { arr: [], status: STATUS.ERROR } }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        const t = this.translate();
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-9 mb-3">
                        <Tabs currentTab={COMPANYTABS.NOTIFICATIONRULES} />
                    </div>
                    <div className="col-12 col-lg-3 mb-3">
                        <A
                            faIcon="fa fa-plus"
                            href={this.Urls().getNotificationRuleAdd()}
                            className="btn btn-outline-primary float-right"
                            title={t('add.notification')}
                        >
                            {this.isScreenSizeSmall() ? undefined : t('add.notification.rule')}
                        </A>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <Line />
                    </div>
                </div>
                <Filters
                    viewMode={this.state.filters.viewMode}
                    orderBy={this.state.filters.orderBy}
                    onChange={(value: any) => { this.setState({ filters: Object.assign({}, value) }, () => { this.search(); }); }}
                />
                <div className="row">
                    <List notificationRules={this.state.notificationRules} viewMode={this.state.filters.viewMode} onRefresh={() => { this.search(); }} />
                </div>
            </div>
        );
    }
}