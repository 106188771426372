import * as React from 'react';
import Component from '../../Component';
import Form from './Form';

export default class Manager extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-6 ml-auto mr-auto mb-3">
                        <Form />
                    </div>
                </div>
            </div>
        );
    }
}