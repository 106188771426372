import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../Component';
import Line from '../../Components/Line';
import { filtersFormUrlFactory, leavesFactory } from '../../Factories';
import { LEAVESTATUS, ORDER, STATUS, VIEWMODE } from '../../General';
import { Cookie, formatForServer } from '../../Utils';
import Filters from './Filters';
import List from './List';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            filters: this.createFilters(),
            leaves: {
                arr: [],
                status: STATUS.LOADING
            },
            leavesInThePast: { arr: [], status: STATUS.LOADING },
            leavesInTheFuture: { arr: [], status: STATUS.LOADING }
        };
        this.onRefresh = this.onRefresh.bind(this);
    }

    createFilters() {
        const moment = this.getMomentWithLocale();
        let viewMode = Cookie.read(Cookie.MY_LEAVES_VIEWMODE);
        if (!viewMode) {
            viewMode = VIEWMODE.GRID;
            Cookie.create(Cookie.MY_LEAVES_VIEWMODE, viewMode);
        }
        let filters = {
            viewMode,
            orderBy: { field: 'DATE', order: ORDER.ASC },
            leaveStatuses: {
                value: [LEAVESTATUS.AWAITING_APPROVAL, LEAVESTATUS.APPROVED]
            },
            leaveTypes: {
                value: [],
            },
            startDate: {
                value: moment().startOf('day').startOf('year')
            },
            endDate: {
                value: moment().startOf('day').add(1, 'year')
            },
            users: {
                value: [].concat((this.getSession().getState() as any).companyUser.id)
            }
        };
        filters = Object.assign({}, filters, filtersFormUrlFactory());
        return filters;
    }

    componentDidMount() { this.search(); }

    search() {
        this.setState(
            {
                leaves: { arr: [], status: STATUS.LOADING },
                leavesInThePast: { arr: [], status: STATUS.LOADING },
                leavesInTheFuture: { arr: [], status: STATUS.LOADING }
            },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getLeaves({ filters: this.state.filters }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        const arr = leavesFactory(data.data, (this.getCaches().getState() as any).users)
                            .sort((a: any, b: any) => {
                                return this.compareString(a.startDate, b.startDate, this.state.filters.orderBy.order);
                            });
                        let leavesInThePast: any = [];
                        let leavesInTheFuture: any = [];
                        const moment = this.getMomentWithLocale();
                        const now = formatForServer(moment().startOf('day'));
                        arr.forEach((leave: any) => {
                            if (this.compareString(now, leave.startDate, this.state.filters.orderBy.order) >= 0) {
                                leavesInTheFuture.push(leave);
                            } else {
                                leavesInThePast.push(leave);
                            }
                        });
                        if (this.state.filters.orderBy.order === ORDER.ASC) {
                            let tmp = leavesInTheFuture;
                            leavesInTheFuture = leavesInThePast;
                            leavesInThePast = tmp;
                        }
                        this.setState(
                            {
                                leaves: { arr: arr, status: STATUS.READY },
                                leavesInThePast: { arr: leavesInThePast, status: STATUS.READY },
                                leavesInTheFuture: { arr: leavesInTheFuture, status: STATUS.READY },
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                leaves: { arr: [], status: STATUS.ERROR },
                                leavesInThePast: { arr: [], status: STATUS.ERROR },
                                leavesInTheFuture: { arr: [], status: STATUS.ERROR }
                            },
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        const t = this.translate();
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 mb-3">
                        {this.getTitle()}
                    </div>
                </div>
                <div className="row"><div className="col-12 mb-3"><Line /></div></div>
                <Filters
                    defaultStartDate={this.state.filters.startDate.value}
                    defaultEndDate={this.state.filters.endDate.value}
                    defaultLeaveStatuses={this.state.filters.leaveStatuses.value}
                    defaultLeaveTypes={this.state.filters.leaveTypes.value}
                    viewMode={this.state.filters.viewMode}
                    orderBy={this.state.filters.orderBy}
                    onChange={(value: any) => {
                        this.setState(
                            { filters: Object.assign({}, value) },
                            () => { this.search(); }
                        );
                    }}
                />
                {
                    (this.state.leavesInThePast.arr.length === 0 || this.state.leavesInTheFuture.arr.length === 0) ?
                        (
                            <div className="row">
                                <List leaves={this.state.leaves} viewMode={this.state.filters.viewMode} onRefresh={this.onRefresh} />
                            </div>
                        )
                        :
                        [
                            (<div className="row" key="leavesInTheFuture">
                                <List leaves={this.state.leavesInTheFuture} viewMode={this.state.filters.viewMode} onRefresh={this.onRefresh} />
                            </div>),
                            (<div className="row" key="leavesInThePastTitle">
                                <div className="col-12">
                                    <h5>{t('past.leaves')}</h5>
                                </div>
                            </div>),
                            (<div className="row" key="leavesInThePast">
                                <List leaves={this.state.leavesInThePast} viewMode={this.state.filters.viewMode} onRefresh={this.onRefresh} />
                            </div>),
                        ]
                }
            </div>
        );
    }

    onRefresh() { this.search(); }

    getTitle(): any {
        const t = this.translate();
        return (
            <h5 className="mb-0">{t('my.leaves')}</h5>
        );
    }
}