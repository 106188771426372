import * as React from 'react';
import Component from '../../../Component';
import THead from './THead';
import TBody from './TBody';
import Error from '../../Error';
import { STATUS } from '../../../General';
import Fa from '../../../Components/Fa';

export default class Table extends Component {

    renderTransparentLoading() {
        return <div className="w-100 h-100 position-absolute text-center" style={{ left: -15, zIndex: 1300 }}>
            <div style={{ left: 15, opacity: 0.1 }} className="w-100 h-100 position-absolute bg-secondary" />
            <div className="mt-3"><Fa fa="fas fa-spinner text-info" x={5} fw={1} spin={1} /></div>
        </ div>
    }

    render() {
        const rows = this.props.rows;
        if (rows.status === 'LOADING' && !rows.arr) {
            return this.renderLoading();
        }
        switch (rows.status) {
            default: {
                return <div className="card w-100">
                    <div className="card-body">
                        {rows.status === 'LOADING' && rows.arr ? this.renderTransparentLoading() : null}
                        <div className="table-responsive">
                            <table className="table table-sm table-hover table-bordered w-100 m-0">
                                <THead days={this.props.days} />
                                <TBody rows={rows} />
                            </table>
                        </div>
                    </div>
                </div>
            }
            case STATUS.ERROR:
                return (<Error />);
        }
    }
}