import * as React from 'react';
import Component from '../../../Component';
import Form from './Form';
import * as $ from 'jquery';
import { STATUS } from '../../../General';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            employeeLeaveType: {
                value: undefined,
                status: STATUS.LOADING
            },
        };
    }

    componentDidMount() {
        this.getEmployeeLeaveTypes();
    }

    getEmployeeLeaveTypes() {
        $.ajax({
            type: 'GET',
            contentType: 'application/json',
            url: this.Endpoints().getEmployeeLeaveTypes(this.props.employee.id),
            dataType: 'json',
            cache: false,
            success: (data, textStatus, jqXHR) => {
                var rows = data;
                const employeeLeaveTypes = rows.filter((row: any) => {
                    return row[1] === this.props.leaveType.id;
                }).map((row: any) => {
                    return {
                        id: row[0],
                        leaveTypeId: row[1],
                        allocation: row[2],
                        supplemental: row[3],
                        transferredToNextYear: row[4],
                        receivedFromLastYear: row[5],
                    };
                });
                this.setState({
                    employeeLeaveType: {
                        value: employeeLeaveTypes[0],
                        status: STATUS.READY
                    }
                });
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
                this.setState({
                    employeeLeaveType: { value: undefined, status: STATUS.ERROR }
                });
            },
        });
    }

    render() {
        const session: any = this.getSession().getState();
        let ready = session.status === STATUS.READY && this.state.employeeLeaveType.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (
            <Form
                employeeLeaveType={this.state.employeeLeaveType.value}
                leaveType={this.props.leaveType}
                employee={this.props.employee}
                leaveTypePerYear={this.props.leaveTypePerYear}
                onClose={() => { if (this.props.onClose) { this.props.onClose(); } }}
            />
        );
    }
}