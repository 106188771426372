import * as React from 'react';
import Component from '../../../Component';
import WorkingDaysAndHours from '../../../Components/WorkingDaysAndHours/WorkingDaysAndHours';
import ApproverFor from './ApproverFor/ApproverFor';
import ManagerFor from './ManagerFor/ManagerFor';

export default class Display extends Component {
    render() {
        const t = this.translate();
        const employee = this.props.employee;
        const caches: any = this.getCaches().getState();
        const usersMap = caches.users.map;
        const unitsMap = caches.units.map;
        const officesMap = caches.offices.map;
        const countriesMap = caches.countries.map[this.language()];
        const approver = usersMap[employee.approverCompanyUser.id];
        return (
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <table className="table table-sm w-100 mb-0">
                                <thead>
                                    <tr>
                                        <th className="border-top-0">
                                            {employee.fullName}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            {t('email')}
                                            <span className="float-right">
                                                <a href={'mailto:' + employee.email}>
                                                    {employee.email}
                                                </a>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('Position')}
                                            <span className="float-right">{employee.position}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('approver')}
                                            <span className="float-right">
                                                {approver.fullName}
                                            </span>
                                        </td>
                                    </tr>
                                    {
                                        (employee.companyUnit && employee.companyUnit.id && unitsMap[employee.companyUnit.id]) ?
                                            (<tr>
                                                <td>
                                                    {t('team')}
                                                    <span className="float-right">
                                                        {unitsMap[employee.companyUnit.id].name}
                                                    </span>
                                                </td>
                                            </tr>
                                            )
                                            :
                                            undefined
                                    }
                                    <tr>
                                        <td>
                                            {t('office')}
                                            <span className="float-right">
                                                {officesMap[employee.office.id].name}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('country')}
                                            <span className="float-right">
                                                {countriesMap[employee.country].text}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('working.days.and.hours')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="mr-0 ml-0 pr-0 pl-0 border-0">
                                            <div className="row">
                                                <WorkingDaysAndHours
                                                    readOnly={true}
                                                    weekDaysAndHours={employee.companyUserSettings.weekDaysAndHours}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="mr-0 ml-0 pr-0 pl-0 border-0">
                                            <small className="text-muted">
                                                {t('if.you.spot.any.error.please.contact.your.hr.department.or.your.manager')}
                                            </small>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <ManagerFor employee={employee} />
                        <ApproverFor employee={employee} />
                    </div>
                </div>
            </div>
        );
    }
}