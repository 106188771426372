import * as React from 'react';
import Component from '../../Component';
import { REPORTSTABS } from '../../General';
import GeneralSituation from './GeneralSituation/GeneralSituation';
import AnnualSituation from './AnnualSituation/AnnualSituation';
import Leaves from './Leaves/Leaves';
import Attendance from './Attendance/Attendance';

export default class Reports extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            currentTab: this.getCurrentTab(REPORTSTABS.GENERAL, REPORTSTABS),
        };
    }

    componentDidMount() {
        if (window.location.hash) {
            switch (window.location.hash.substring(1, window.location.hash.length)) {
                case REPORTSTABS.LEAVES:
                    this.setState({ currentTab: REPORTSTABS.LEAVES });
                    break;
                case REPORTSTABS.ANNUAL:
                    this.setState({ currentTab: REPORTSTABS.ANNUAL });
                    break;
                case REPORTSTABS.ATTENDANCE:
                    this.setState({ currentTab: REPORTSTABS.ATTENDANCE });
                    break;
                case REPORTSTABS.GENERAL:
                default:
                    this.setState({ currentTab: REPORTSTABS.GENERAL });
            }
        }
    }

    render() {
        return this.getCurrentTabComponent();
    }

    getCurrentTabComponent() {
        switch (this.state.currentTab) {
            case REPORTSTABS.LEAVES:
                return (<Leaves onTabChange={(value: any) => { this.setState({ currentTab: value }); }} />);
            case REPORTSTABS.ANNUAL:
                return (<AnnualSituation onTabChange={(value: any) => { this.setState({ currentTab: value }); }} />);
            case REPORTSTABS.ATTENDANCE:
                return (<Attendance onTabChange={(value: any) => { this.setState({ currentTab: value }); }} />);
            case REPORTSTABS.GENERAL:
            default:
                return (<GeneralSituation onTabChange={(value: any) => { this.setState({ currentTab: value }); }} />);
        }
    }
}