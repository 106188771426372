import * as React from 'react';
import Component from '../../Component';
import Item from './Item';
import Error from '../../Components/Error';
import { STATUS } from '../../General';

export default class List extends Component {

    render() {
        const units = this.props.units;
        switch (units.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                if (units.arr.length === 0) {
                    const t = this.translate();
                    return (
                        <div className="col-12 text-danger mb-3">
                            {t('no.teams.found')}
                        </div>
                    );
                }
                return units.arr.map((unit: any) => {
                    return (
                        <div
                            key={'Units' + this.props.viewMode + '-' + unit.id}
                            className={this.getListColSize(this.props.viewMode) + ' mb-3'}
                        >
                            <Item
                                unit={unit}
                                onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }}
                            />
                        </div>
                    );
                });
            }
            default:
                return (<Error />);
        }
    }
}