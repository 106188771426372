import * as React from 'react';
import ResponsiveModal from '../ResponsiveModal';
import Form from './Form';
import { none } from '../../../Actions/Modal';

export default class AdjustWorkingDayAndHours extends ResponsiveModal {
    getContent() {
        return <Form
            weekDay={this.props.options.weekDay}
            weekDayAndHours={this.props.options.weekDayAndHours}
            onClose={(value: any) => {
                this.toggle();
                none(this.getModal(), {});
                if (this.props.callback) { this.props.callback(value); }
            }}
        />
    }

    getTitle(): any {
        return <h5 className="m-0 p-0">{this.props.options.weekDay}</h5>
    }
}