import * as React from 'react';
import Component from '../../Component';

export default class LeaveReplacement extends Component {
    render() {
        const leave = this.props.leave;
        const t = this.translate();
        return (<div><i className="fas fa-user-friends fa-fw mr-1" aria-hidden="true" />
            {t('replacement')} <span className="float-right ml-1">{leave.replacement}</span></div>);
    }
}