import * as React from 'react';
import Table from './Table';
import MyNextHoesManager from '../../../Dashboard/MyNextHoes/Manager';
import { STATUS } from '../../../../General';

export default class Manager extends MyNextHoesManager {
    constructor(props?: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        this.state = {
            filters: {
                startDate: { value: moment().startOf('day') },
                endDate: { value: moment().add(6, 'months') },
                nwhoel: { value: [1] },
                users: {
                    value: [this.props.employee.id],
                },
            },
            rows: {
                arr: [],
                status: STATUS.LOADING
            }
        };
    }

    render() {
        if (this.state.rows.arr.length === 0) {
            return null as any;
        }
        return (
            <div className="card h-100">
                <div className="card-body">
                    <Table
                        rows={this.state.rows}
                    />
                </div>
            </div>
        );
    }
}