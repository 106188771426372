import * as React from 'react';
import Anchor from './Anchor';

export default class SelectFieldDisplayValue extends React.Component<any, any> {
    render() {
        let display: any = <div className="mt-2">{'\u00a0'}</div>;
        if (this.props.options && this.props.options.length > 0 &&
            this.props.value && this.props.value.length > 0 && this.props.value[0] !== undefined) {
            let selectedOptions = this.props.options.filter(
                (option: any) => {
                    for (var value of this.props.value) {
                        if (value === option.value) {
                            return true;
                        }
                    }
                    return false;
                });
            selectedOptions = (selectedOptions.length === 0) ? this.props.value.map((value: any) => {
                return { text: value };
            }) : selectedOptions;
            display = (selectedOptions.map((option: any) => {
                return (
                    <div
                        key={'SelectFieldDisplayValue-' + this.props.parentCid + '-' + option.value}
                        className={'mr-1 text-truncate mt-2' + ((this.props.multiple) ?
                            '  bg-light rounded pl-1 pr-1' : '')}
                    >
                        {(option.html ? option.item.html : option.text)}
                        {
                            (this.props.multiple) ?
                                <Anchor
                                    className="text-secondary pl-1 pr-1"
                                    faIcon="fa fa-times"
                                    onClick={
                                        (event: any) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            if (this.props.onSelectItem) { this.props.onSelectItem(event, option); }
                                        }}
                                />
                                :
                                null
                        }
                    </div>
                );
            }));
        }
        return (
            <div className="d-flex flex-wrap w-100 pr-4">
                {display}
            </div>
        );
    }
}