import * as React from 'react';
import Component from '../../Component';

export default class CompanyRegisteredSuccessfully extends Component {

    render() {
        const t = this.translate();
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-6 ml-auto mr-auto mb-3">
                        <div className="card h-100">
                            <div className="card-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-12">
                                            <div><h5 className="m-0">{t('thank.you.for.registering')}</h5></div>
                                            <div>
                                                {t('shortly.you.will.receive.the.activation.email.from.noreply@leaveboard.com')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}