import * as React from 'react';
import Component from '../../Component';
import Anchor from '../../Components/Anchor';
import LeaveDecisionAction from '../../Components/Leave/LeaveDecisionAction';
import LeaveDetailedStatus from '../../Components/Leave/LeaveDetailedStatus';
import LeaveEventTime from '../../Components/Leave/LeaveEventTime';
import LeaveInterval from '../../Components/Leave/LeaveInterval';
import LeaveReplacement from '../../Components/Leave/LeaveReplacement';
import LeaveRules from '../../LeaveRules';
import LeaveEmployee from '../Reports/LeaveEmployee';
import LeaveName from '../Reports/LeaveName';

export default class RowItem extends Component {

    render() {
        const moment = this.getMomentWithLocale();
        const t = this.translate();
        const leave = this.props.leave;
        const canBeRejected = LeaveRules.canBeRejected(leave, (this.getSession().getState() as any).companyUser);
        const canBeApproved = LeaveRules.canBeApproved(leave, (this.getSession().getState() as any).companyUser);
        return (
            <tr>
                <td>
                    <LeaveEmployee employee={leave.companyUser} />
                    {leave.comment ? <div><Anchor faIcon="far fa-comment mr-1 fa-fw" title={t('request.comment')} />{leave.comment}</div> : null}
                </td>
                <td>
                    <div>
                        <span className="ml-1 float-right">
                            <LeaveEventTime>
                                {t('registered.on')}
                                <div>{moment(leave.requestedOn).format('LLL')}</div>
                                {(leave.createdBy) ? (<div>{t('by')}<span className="ml-1"><LeaveEmployee employee={leave.createdBy} /></span></div>) : null}
                            </LeaveEventTime>
                        </span>
                        <LeaveName leave={leave} />
                    </div>
                    {leave.replacement && leave.replacement.length > 2 ? <div><LeaveReplacement leave={leave} /></div> : null}
                </td>
                <td><LeaveInterval leaveRequestInThePast={true} leave={leave} /> </td>
                <td><LeaveDetailedStatus leave={leave} simpleAwaitingApproval={true} /></td>
                <td className="text-right">
                    {(canBeRejected && canBeApproved) ? <LeaveDecisionAction noText={true} leave={leave} onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }} /> : null}
                </td>
            </tr>
        );
    }
}