import * as React from 'react';
import Component from '../Component';

export default class InputICal extends Component {
    textInput: any;
    render() {
        if (this.props.copy) {
            (this.textInput as any).select();
            try { document.execCommand('copy'); } catch (err) { }
        }
        return (
            <input
                ref={(input) => { this.textInput = input; }}
                type="text"
                value={this.props.value}
                readOnly={true}
                className="text-white bg-white m-0 p-0 border-0"
                style={{ width: 2, height: 2, }}
            />
        );
    }
}