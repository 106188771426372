import * as React from 'react';
import Component from '../../Component';
import Anchor from '../../Components/Anchor';
import LeaveCancelAction from '../../Components/Leave/LeaveCancelAction';
import LeaveDetailedStatus from '../../Components/Leave/LeaveDetailedStatus';
import LeaveEventTime from '../../Components/Leave/LeaveEventTime';
import LeaveInterval from '../../Components/Leave/LeaveInterval';
import LeaveName from '../../Components/Leave/LeaveName';
import LeaveReplacement from '../../Components/Leave/LeaveReplacement';
import LeaveSendReminderAction from '../../Components/Leave/LeaveSendReminderAction';
import PaperCopyAction from '../../Components/Leave/PaperCopyAction';
import LeaveRules from '../../LeaveRules';

export default class Item extends Component {
    constructor(props: any) {
        super(props);
        this.onRefresh = this.onRefresh.bind(this);
    }

    render() {
        const moment = this.getMomentWithLocale();
        const t = this.translate();
        const leave = this.props.leave;
        const canBeCanceled = this.canBeCanceled(leave);
        const canBePaperCopy = this.canBePaperCopy(leave);
        const canSendReminder = LeaveRules.canSendReminder(leave, (this.getSession().getState() as any).companyUser);
        return <div className="card w-100 h-100">
            <div className="card-body">
                <table
                    className="table table-sm w-100 m-0 p-0"
                    style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                    <thead>
                        <tr>
                            <th className="border-top-0">
                                <LeaveName leave={leave} />
                                <span className="float-right text-secondary">
                                    <LeaveEventTime>
                                        {t('registered.on')}
                                        <div>{moment(leave.requestedOn).format('LLL')}</div>
                                        {
                                            (leave.createdBy) ?
                                                (<div> {t('by') + ' '} {leave.createdBy.firstName} {leave.createdBy.lastName}</div>) : ''
                                        }
                                    </LeaveEventTime>
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td>
                            <LeaveInterval leave={leave} />
                        </td></tr>
                        {this.getLeaveComment()}
                        <tr><td>
                            <LeaveDetailedStatus leave={leave} />
                        </td></tr>
                        {leave.replacement && leave.replacement.length > 2 ?
                            <tr><td><LeaveReplacement leave={leave} /></td></tr> : null}
                        {(canSendReminder) ?
                            (<tr><td><div className="float-right"><LeaveSendReminderAction leave={leave} /> </div></td></tr>)
                            : null}
                        {(canBeCanceled) ?
                            (<tr><td className="align-bottom"><div className="float-right"><LeaveCancelAction onRefresh={this.onRefresh} leave={leave} /></div></td></tr>) : null}
                        {(canBePaperCopy) ?
                            (<tr><td className="align-bottom"><div className="float-right"><PaperCopyAction leave={leave} /></div></td></tr>)
                            : null}
                    </tbody>
                </table>
            </div>
        </div>
    }

    getLeaveComment() {
        const t = this.translate();
        var leave = this.props.leave;
        return leave.comment ? (<tr><td><Anchor faIcon="far fa-comment fa-fw mr-1" title={t('request.comment')} />{leave.comment}</td></tr>) : null;
    }

    canBePaperCopy(leave: any) {
        return LeaveRules.canBePaperCopy(leave,
            (this.getCaches().getState() as any).leaveTypes.map[leave.leaveType.id],
            (this.getSession().getState() as any).company);
    }

    canBeCanceled(leave: any) {
        return LeaveRules.canBeCanceledForMyLeaves(leave, (this.getSession().getState() as any).companyUser);
    }

    onRefresh() { if (this.props.onRefresh) { this.props.onRefresh(); } }
}