import * as React from 'react';
import Component from '../../Component';
import RegisterTicket from './RegisterTicket/RegisterTicket';
import KnowledgeBase from './KnowledgeBase/KnowledgeBase';
import { SUPPORTTABS } from '../../General';

export default class Support extends Component {
    constructor(props?: any) {
        super(props);
        this.state = { currentTab: this.getCurrentTab(SUPPORTTABS.KB, SUPPORTTABS), };
    }

    render() {
        return this.getTab();
    }

    getTab() {
        switch (this.state.currentTab) {
            case SUPPORTTABS.RT:
                return (<RegisterTicket currentTab={this.state.currentTab} onTabChange={(tab: any) => { this.setState({ currentTab: tab }); }} />);
            default:
                return (<KnowledgeBase currentTab={this.state.currentTab} onTabChange={(tab: any) => { this.setState({ currentTab: tab }); }} />);
        }
    }
}