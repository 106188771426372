import * as React from 'react';
import Component from '../../../Component';
import Date from '../../../Components/Date';
import Error from '../../../Components/Error';
import { STATUS } from '../../../General';

export default class Table extends Component {
    render() {
        const employees = this.props.employees;
        switch (employees.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                return <table className="table table-sm w-100 m-0 p-0" style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                    <thead>
                        <tr>
                            <th scope="col" colSpan={2} className="border-top-0">
                                {t('upcoming.birthdays')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {employees.arr.map((employee: any) => {
                            return <tr key={employee.id}><td key="Employees-Item-Employee">
                                <div>{employee.fullName}</div>
                                {employee.birthDate ? <span> <Date date={employee.birthDate} /></span> : null}
                            </td>
                                <td key="Employees-Item-Employment-Next" className="text-right">
                                    <div>{employee.todayIsBirthDate ? t('today') : (employee.next ? employee.next.humanize(true) : null)}</div>
                                    {employee.ageLiteral ? employee.ageLiteral : null}
                                </td></tr>
                        })}
                    </tbody>
                </table>
            }
            default:
                return (<Error />);
        }
    }
}