import * as React from 'react';

export default class TableLine extends React.PureComponent {
    render() {
        return (
            <table className="table table-sm w-100 mb-0">
                <thead><tr><th className="border-top-0" /></tr></thead><tbody><tr><td /></tr></tbody>
            </table>
        );
    }
}