import * as React from 'react';
import Table from './Table';
import Component from '../../../../Component';
import { USERSTATUS } from '../../../../General';

export default class Manager extends Component {

    render() {
        const caches: any = this.getCaches().getState();
        const employees = caches.users.arr.filter((user: any) => {
            if (user.approverCompanyUser && user.approverCompanyUser.id &&
                user.approverCompanyUser.id === this.props.employee.id && user.status !== USERSTATUS.DISABLED) {
                return true;
            }
            return false;
        }).sort((a: any, b: any) => {
            return a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
        });
        if (employees?.length > 0) {
            return <div className="col-12 mt-3">
                {this.getTable(employees)}
            </div>
        } else {
            if (this.props.onEmpty) { this.props.onEmpty(); }
            return null;
        }
    }

    getTable(employees: any) {
        return (<Table employee={this.props.employee} employees={employees} />);
    }
}