import * as $ from 'jquery';
import * as React from 'react';
import { doLoadSession } from '../../../Actions/Session';
import Component from '../../../Component';
import { COMPANYUSERROLES } from '../../../General';
import Administrator from './Administrator';
import User from './User';

export default class MyNextSteps extends Component {
    render() {
        const session: any = this.getSession().getState();
        if (!session.companyUser.showNextSteps) {
            this.onEmpty();
            return <></>
        }
        if (session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN) {
            return (<Administrator onEmpty={() => { this.onEmpty(); }} onClose={() => { this.onClose(); }} />);
        } else {
            return (<User onEmpty={() => { this.onEmpty(); }} onClose={() => { this.onClose(); }} />);
        }
    }

    onEmpty() { if (this.props.onEmpty) { this.props.onEmpty(); } }

    onClose() {
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getHideNextSteps(),
            dataType: 'json',
            cache: false,
            success: (data: any, textStatus: any, jqXHR: any) => {
                doLoadSession(this.getSession());
                if (this.props.onEmpty) { this.props.onEmpty(); }
            },
            error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            }
        });
    }
}