import * as React from 'react';
import Component from '../Component';
import Anchor from './Anchor';
import Tooltip from './Tooltip';

export default class ColorField extends Component {
    textInput: any;
    constructor(props?: any) {
        super(props);
        this.state = {
            value: this.props.defaultValue ? this.props.defaultValue : '',
            defaultValue: this.props.defaultValue ? this.props.defaultValue : '',
            touched: false,
            rules: this.props.rules,
            valid: null,
        };
    }

    render() {
        const colors = this.props.colors.map((color: any) => {
            return (
                <Anchor
                    key={'ColorField-' + this.cid + '-' + color}
                    className="m-0 mr-1"
                    onClick={() => {
                        this.setState({ value: color }, () => {
                            if (this.props.onChange) {
                                this.props.onChange(this.state.value);
                            }
                        });
                    }
                    }
                >
                    <i
                        className={'fa fa-lg fa-fw ' + (color === this.state.value ? 'fa-check-circle' : 'fa-circle')}
                        aria-hidden="true"
                        style={{ color: color }}
                    />
                </Anchor>
            );
        });
        return (
            <div className="w-100" >
                {colors} {' '}
                {
                    (this.props.tooltip && this.props.tooltip.length > 0) ?
                        (<Tooltip textColor="info" tooltip={this.props.tooltip} />)

                        :
                        null
                }
            </div>
        );
    }
}