import * as React from 'react';
import Component from '../../Component';
import Tooltip from '../Tooltip';

export default class LeaveRequestInThePast extends Component {
    render() {
        const t = this.translate();
        return (
            <Tooltip textColor="info" tooltip={t('please.note.the.leave.request.is.in.the.past')} />
        );
    }
}