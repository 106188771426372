import * as $ from 'jquery';
import * as React from 'react';
import { doFetchLeaveTypesFromServer } from '../../../Actions/Caches';
import Component from '../../../Component';
import Close from '../../../Components/Close';
import InputField from '../../../Components/InputField';
import SelectField from '../../../Components/SelectField';
import Submit from '../../../Components/Submit';
import ToggleField from '../../../Components/ToggleField';
import { STATUS } from '../../../General';
import {
    leaveTypesOptionsFactory, notificationRuleEventClausesOptionsFactory, notificationRuleTypeSchedulerContentOptionsFactory,
    notificationRuleTypeSchedulerWhenOptionsFactory, notificationRuleTypesOptionsFactory, officesOptionsFactory, unitsOptionsFactory, usersOptionsFactory
} from '../../../SelectOptionsFactories';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        const notificationRule = this.props.notificationRule;
        this.state = {
            name: {
                touched: false,
                value: notificationRule.name,
                rules: this.Rules().BasicNameInputRules()
            },
            type: {
                value: [notificationRule.type === 2 ? notificationRule.type : 1],
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            schedulerWhen: {
                value: notificationRule.when,
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            schedulerContent: {
                value: notificationRule.content,
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            leaveTypes: {
                value: notificationRule.leaveTypes,
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            events: {
                value: notificationRule.events,
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            users: {
                value: notificationRule.users,
                touched: false,
                rules: this.Rules().BasicSelectRules()
            },
            forAllLeaveTypes: {
                touched: false,
                value: notificationRule.forAllLeaveTypes
            },
            filterBy: {
                touched: false,
                rules: this.Rules().BasicSelectRules(),
                value: []
            },
            forCompany: {
                touched: false,
                value: notificationRule.forCompany
            },
            save: {
                pleaseWait: false
            },
        };
        if (!notificationRule.forCompany) {
            if (notificationRule.notificationRuleFilterClauses) {
                notificationRule.notificationRuleFilterClauses.forEach((filterClause: any) => {
                    if (filterClause.companyUserId) {
                        this.state.filterBy.value.push('us-' + filterClause.companyUserId);
                    }
                    if (filterClause.companyUnitId) {
                        this.state.filterBy.value.push('un-' + filterClause.companyUnitId);
                    }
                    if (filterClause.calendarId) {
                        this.state.filterBy.value.push('of-' + filterClause.calendarId);
                    }
                });
            }
        }
    }

    render() {
        const t = this.translate();
        const caches: any = this.getCaches().getState();
        let ready = caches.users.status === STATUS.READY && caches.units.status === STATUS.READY && caches.offices.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        const leaveTypesOptions = leaveTypesOptionsFactory(caches.leaveTypes.arr, this.language());
        const usersOptions = usersOptionsFactory(caches.users.arr);
        const unitsOptions = unitsOptionsFactory(caches.units.arr);
        const officesOptions = officesOptionsFactory(caches.offices.arr);
        let usofunOptions: any = [];
        usersOptions.forEach((userOption: any) => {
            usofunOptions.push({ value: 'us-' + userOption.value, text: userOption.text });
        })
        unitsOptions.forEach((unitOption: any) => {
            usofunOptions.push({ value: 'un-' + unitOption.value, text: unitOption.text });
        });
        officesOptions.forEach((officeOption: any) => {
            usofunOptions.push({ value: 'of-' + officeOption.value, text: officeOption.text });
        });
        const notificationRuleEventClausesOptions = notificationRuleEventClausesOptionsFactory(t);
        const notificationRuleEventTypesOptions = notificationRuleTypesOptionsFactory(t);
        const notificationRuleTypeSchedulerWhenOptions = notificationRuleTypeSchedulerWhenOptionsFactory(t);
        const notificationRuleTypeSchedulerContentOptions = notificationRuleTypeSchedulerContentOptionsFactory(t);
        return (
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <form onSubmit={(event: any) => { this.submit(event); }}>
                        <fieldset>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="float-right">
                                        <Close onClick={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }} />
                                    </div>
                                </div>
                                <div className="col-12 mb-3 mt-3">
                                    <InputField
                                        value={this.state.name.value}
                                        defaultValue={this.state.name.value}
                                        touched={this.state.name.touched}
                                        rules={this.state.name.rules}
                                        type="text"
                                        placeholder={t('name')}
                                        focus={this.isScreenSizeSmall() ? false : true}
                                        onChange={(value: any) => { this.setState({ name: Object.assign({}, this.state.name, { value: value }) }); }}
                                    />
                                </div>
                                <div className="col-12 mb-3 d-none">
                                    <SelectField
                                        defaultValue={this.state.type.value}
                                        touched={this.state.type.touched}
                                        options={notificationRuleEventTypesOptions}
                                        multiple={false}
                                        placeholder={t('type')}
                                        onChange={(value: any) => { this.setState({ type: Object.assign({}, this.state.type, { value: value }) }); }}
                                    />
                                </div>
                                {this.state?.type?.value && this.state?.type?.value[0] === 1 ?
                                    <>
                                        <div className="col-12 mb-3">
                                            <ToggleField
                                                defaultValue={this.state.forAllLeaveTypes.value}
                                                tooltip={t('for.all.leave.types')}
                                                onChange={(value: any) => { this.setState({ forAllLeaveTypes: Object.assign({}, this.state.forAllLeaveTypes, { value: value }) }); }}
                                            >
                                                {t('for.all.leave.types')}
                                            </ToggleField>
                                        </div>
                                        {!this.state.forAllLeaveTypes.value ?
                                            <div className="col-12 mb-3">
                                                <SelectField
                                                    defaultValue={this.state.leaveTypes.value}
                                                    touched={this.state.leaveTypes.touched}
                                                    options={leaveTypesOptions}
                                                    multiple={true}
                                                    placeholder={t('leave.types')}
                                                    maxDisplayedOptions={3}
                                                    onChange={(value: any) => { this.setState({ leaveTypes: Object.assign({}, this.state.leaveTypes, { value: value }) }); }}
                                                />
                                            </div>
                                            :
                                            null
                                        }
                                        <div className="col-12 mb-3">
                                            <SelectField
                                                defaultValue={this.state.events.value}
                                                touched={this.state.events.touched}
                                                options={notificationRuleEventClausesOptions}
                                                multiple={true}
                                                placeholder={t('when')}
                                                maxDisplayedOptions={3}
                                                onChange={(value: any) => { this.setState({ events: Object.assign({}, this.state.events, { value: value }) }); }}
                                            />
                                        </div>
                                    </> : null}
                                {this.state?.type?.value && this.state?.type?.value[0] === 2 ?
                                    <>
                                        <div className="col-12 mb-3">
                                            <SelectField
                                                defaultValue={this.state.schedulerWhen.value}
                                                touched={this.state.schedulerWhen.touched}
                                                options={notificationRuleTypeSchedulerWhenOptions}
                                                multiple={false}
                                                placeholder={t('when')}
                                                onChange={(value: any) => { this.setState({ schedulerWhen: Object.assign({}, this.state.schedulerWhen, { value: value }) }); }}
                                            />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <SelectField
                                                defaultValue={this.state.schedulerContent.value}
                                                touched={this.state.schedulerContent.touched}
                                                options={notificationRuleTypeSchedulerContentOptions}
                                                multiple={false}
                                                placeholder={t('content')}
                                                onChange={(value: any) => { this.setState({ schedulerContent: Object.assign({}, this.state.schedulerContent, { value: value }) }); }}
                                            />
                                        </div>
                                    </>
                                    : null}
                                <div
                                    className="col-12 mb-3 border border-top-0 border-right-0 border-left-0 rounded-0"
                                    style={{ borderColor: 'rgba(0, 0, 0, 0.125)!important' }}
                                />
                                <div className="col-12 mb-3">
                                    <ToggleField
                                        defaultValue={this.state.forCompany.value}
                                        tooltip={t('from.all.company.employees')}
                                        onChange={(value: any) => { this.setState({ forCompany: Object.assign({}, this.state.forCompany, { value: value }) }); }}
                                    >
                                        {t('from.all.company.employees')}
                                    </ToggleField>
                                </div>
                                {!this.state.forCompany.value ?
                                    <div className="col-12 mb-3">
                                        <SelectField
                                            defaultValue={this.state.filterBy.value}
                                            touched={this.state.filterBy.touched}
                                            options={usofunOptions}
                                            multiple={true}
                                            placeholder={t('from.whom')}
                                            maxDisplayedOptions={3}
                                            onChange={(value: any) => { this.setState({ filterBy: Object.assign({}, this.state.filterBy, { value: value }) }); }}
                                        />
                                    </div>
                                    : null}
                                <div
                                    className="col-12 mb-3 border border-top-0 border-right-0 border-left-0 rounded-0"
                                    style={{ borderColor: 'rgba(0, 0, 0, 0.125)!important' }}
                                />
                                <div className="col-12 mb-3">
                                    <SelectField
                                        defaultValue={this.state.users.value}
                                        touched={this.state.users.touched}
                                        options={usersOptions}
                                        multiple={true}
                                        placeholder={t('to.whom')}
                                        maxDisplayedOptions={3}
                                        onChange={(value: any) => { this.setState({ users: Object.assign({}, this.state.users, { value: value }) }); }}
                                    />
                                </div>
                                <div className="col-12">
                                    <Submit
                                        faIcon="far fa-hdd"
                                        disabled={!this.isFormValid()}
                                        pleaseWait={this.state.save.pleaseWait}
                                    >
                                        {t('save')}
                                    </Submit>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div >
        );
    }

    onUpdate() {
        if (this.props.onUpdate) {
            this.props.onUpdate({
                id: this.props.notificationRule.id,
                names: this.state.name.value
            });
        }
    }

    isFormValid() {
        if (this.state?.type?.value && this.state?.type?.value[0] === 1) {
            return this.Validator().validate(this.state.name) && (!this.state.forAllLeaveTypes.value ? this.Validator().validate(this.state.leaveTypes) : true)
                && this.Validator().validate(this.state.type) && (!this.state.forCompany ? (this.state.filterBy.value ? this.Validator().validate(this.state.filterBy) : true) : true)
                && this.Validator().validate(this.state.users) && this.Validator().validate(this.state.events);
        }
        if (this.state?.type?.value && this.state?.type?.value[0] === 2) {
            return this.Validator().validate(this.state.name)
                && this.Validator().validate(this.state.type) && this.Validator().validate(this.state.schedulerContent)
                && this.Validator().validate(this.state.users) && this.Validator().validate(this.state.schedulerWhen);
        }
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                name: Object.assign({}, this.state.name, { touched: true }),
                type: Object.assign({}, this.state.type, { touched: true }),
                schedulerContent: Object.assign({}, this.state.schedulerContent, { touched: true }),
                schedulerWhen: Object.assign({}, this.state.schedulerWhen, { touched: true }),
                forAllLeaveTypes: Object.assign({}, this.state.forAllLeaveTypes, { touched: true }),
                leaveTypes: Object.assign({}, this.state.leaveTypes, { touched: true }),
                forCompany: Object.assign({}, this.state.forCompany, { touched: true }),
                users: Object.assign({}, this.state.users, { touched: true }),
                filterBy: Object.assign({}, this.state.filterBy, { touched: true }),
                events: Object.assign({}, this.state.events, { touched: true }),
            },
            () => { if (this.isFormValid()) { this.save(); } }
        );
    }

    save() {
        const t = this.translate();
        let notificationRuleFilterClauses: any = [];
        if (!this.state.forCompany.value) {
            if (this.state.filterBy.value)
                this.state.filterBy.value.forEach((filterBy: any) => {
                    if (filterBy.startsWith('us-')) {
                        notificationRuleFilterClauses.push({ companyUserId: filterBy.replaceAll('us-', '') })
                    }
                    if (filterBy.startsWith('un-')) {
                        notificationRuleFilterClauses.push({ companyUnitId: filterBy.replaceAll('un-', '') })
                    }
                    if (filterBy.startsWith('of-')) {
                        notificationRuleFilterClauses.push({ calendarId: filterBy.replaceAll('of-', '') })
                    }
                })
        }
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getNotificationRuleUpdate(this.props.notificationRule.id),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                id: this.props.notificationRule.id,
                name: this.state.name.value,
                when: this.state?.schedulerWhen?.value && this.state.schedulerWhen.value[0] ? this.state.schedulerWhen.value[0] : null,
                content: this.state?.schedulerContent?.value && this.state.schedulerContent.value[0] ? this.state.schedulerContent.value[0] : null,
                forCompany: this.state.forCompany.value,
                type: this.state.type.value[0],
                forAllLeaveTypes: this.state.forAllLeaveTypes.value,
                leaveTypes: !this.state.forAllLeaveTypes.value ? this.state.leaveTypes.value : null,
                users: this.state.users.value,
                notificationRuleFilterClauses,
                events: this.state.events.value,
            }),
            beforeSend: () => {
                this.setState({ save: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                doFetchLeaveTypesFromServer(this.getCaches());
                this.successToastr(t('the.notification.rule.was.saved'));
                if (this.props.onRefresh) { this.props.onRefresh(); }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.generalErrorToastr();
            }
        });
    }
}