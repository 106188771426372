import * as React from 'react';
import ResponsiveModal from '../ResponsiveModal';
import Form from './Form';
import { none } from '../../../Actions/Modal';
import { STATUS } from '../../../General';

export default class AddOfficeMembers extends ResponsiveModal {
    getContent() {
        const office = this.props.office;
        const caches: any = this.getCaches().getState();
        const session: any = this.getSession().getState();
        let ready = session.status === STATUS.READY && caches.users.status === STATUS.READY;
        return (ready === false) ? this.renderLoading() : <Form
            office={office} onClose={() => {
                this.toggle();
                none(this.getModal(), {});
                if (this.props.callback) {
                    this.props.callback();
                }
            }}
        />
    }

    getTitle(): any {
        const t = this.translate();
        const office = this.props.office;
        return (
            <h5 className="m-0 p-0">
                {t('add.members.to.office').replace(/{office}/g, office.name)}
            </h5>
        );
    }
}