import BaseTabs from '../../Components/BaseTabs';
import { COMPANYTABS } from '../../General';

export default class Tabs extends BaseTabs {
    getTabs(): any[] {
        const t = this.translate();
        return [
            {
                faIcon: 'fa fa-shopping-cart',
                title: t('subscription'),
                tab: COMPANYTABS.SUBSCRIPTION,
                url: this.Urls().getCompany()
            },
            {
                faIcon: 'fas fa-edit',
                title: t('leave.types'),
                tab: COMPANYTABS.LEAVETYPES,
                url: this.Urls().getLeaveTypes()
            },
            /*{
                faIcon: 'fa fa-link',
                title: t('integrations'),
                tab: COMPANYTABS.INTEGRATIONS,
                url: this.Urls().getCompany()
            },*/
            {
                faIcon: 'fas fa-wrench',
                title: t('settings'),
                tab: COMPANYTABS.SETTINGS,
                url: this.Urls().getCompany()
            },
            {
                faIcon: 'fas fa-address-card',
                title: t('permissions'),
                tab: COMPANYTABS.PERMISSIONS,
                url: this.Urls().getCompany()
            },
            /*{
                faIcon: 'fas fa-file-import',
                title: t('import/export'),
                tab: COMPANYTABS.IMPORTEXPORT,
                url: this.Urls().getImportExport()
            },*/
            {
                faIcon: 'far fa-bell',
                title: t('notification.rules'),
                tab: COMPANYTABS.NOTIFICATIONRULES,
                url: this.Urls().getNotificationRules()
            },
        ];
    }

    getName(): string {
        return 'Company';
    }
}