import BaseTabs from '../../Components/BaseTabs';
import { SUPPORTTABS } from '../../General';

export default class Tabs extends BaseTabs {
    getTabs(): any[] {
        const t = this.translate();
        return [
            {
                faIcon: 'fas fa-book',
                title: t('knowledge.base'),
                tab: SUPPORTTABS.KB
            },
            {
                faIcon: 'far fa-envelope',
                title: t('register.ticket'),
                tab: SUPPORTTABS.RT
            }
        ];
    }
    getName(): string {
        return 'Support';
    }
}