import * as $ from 'jquery';
import * as React from 'react';
import { doFetchUnitsFromServer, doFetchUsersFromServer } from '../../../Actions/Caches';
import Component from '../../../Component';
import DatePickerField from '../../../Components/DatePickerField';
import InputField from '../../../Components/InputField';
import SelectField from '../../../Components/SelectField';
import Submit from '../../../Components/Submit';
import { COMPANYUSERROLES } from '../../../General';
import { employeeGenderOptionsFactory } from '../../../SelectOptionsFactories';
import { formatForServer } from '../../../Utils';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        const employee = this.props.employee;
        const moment = this.getMomentWithLocale();
        this.state = {
            birthDate: {
                value: employee.birthDate ? moment(employee.birthDate) : null,
                touched: false,
                timeFormat: false
            },
            gender: {
                value: employee.gender ? [employee.gender] : null,
                touched: false,
            },
            personalPhoneNumber: {
                value: employee.personalPhoneNumber,
                touched: false,
            },
            emergencyPhoneNumber: {
                value: employee.emergencyPhoneNumber,
                touched: false,
            },
            nextOfKinName: {
                value: employee.nextOfKinName,
                touched: false,
            },
            nextOfKinRelationship: {
                value: employee.nextOfKinRelationship,
                touched: false,
            },
            nextOfKinPhoneNumber: {
                value: employee.nextOfKinPhoneNumber,
                touched: false,
            },
            save: {
                pleaseWait: false
            }
        };
    }

    render() {
        const session: any = this.getSession().getState();
        const isAdmin = () => {
            return session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN;
        };
        if (!isAdmin()) {
            const location = window.location + '';
            this.goToLogin({ target: location });
            return null;
        }
        const t = this.translate();
        const genderOptions: any = employeeGenderOptionsFactory(t);
        const dateFormat = this.getDateFormat();
        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <form onSubmit={(event: any) => { this.submit(event); }}>
                    <fieldset>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <DatePickerField
                                    value={this.state.birthDate.value}
                                    placeholder={t('birthday')}
                                    timeFormat={this.state.birthDate.timeFormat}
                                    dateFormat={dateFormat}
                                    onChange={(value: any) => { this.setState({ birthDate: Object.assign({}, this.state.birthDate, { value, isValid: true }) }); }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.gender.value}
                                    touched={this.state.gender.touched}
                                    options={genderOptions}
                                    rules={this.state.gender.rules}
                                    placeholder={t('gender')}
                                    onChange={(value: any) => {
                                        this.setState({ gender: Object.assign({}, this.state.gender, { value: value }) });
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <InputField
                                    defaultValue={this.state.personalPhoneNumber.value}
                                    touched={this.state.personalPhoneNumber.touched}
                                    type="text"
                                    placeholder={t('personal.phone.number')}
                                    onChange={(value: any) => {
                                        this.setState({ personalPhoneNumber: Object.assign({}, this.state.personalPhoneNumber, { value }) });
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <InputField
                                    defaultValue={this.state.emergencyPhoneNumber.value}
                                    touched={this.state.emergencyPhoneNumber.touched}
                                    type="text"
                                    placeholder={t('emergency.phone.number')}
                                    onChange={(value: any) => {
                                        this.setState({ emergencyPhoneNumber: Object.assign({}, this.state.emergencyPhoneNumber, { value }) });
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <InputField
                                    defaultValue={this.state.nextOfKinName.value}
                                    touched={this.state.nextOfKinName.touched}
                                    type="text"
                                    placeholder={t('next.of.kin.name')}
                                    onChange={(value: any) => {
                                        this.setState({ nextOfKinName: Object.assign({}, this.state.nextOfKinName, { value }) });
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <InputField
                                    defaultValue={this.state.nextOfKinRelationship.value}
                                    touched={this.state.nextOfKinRelationship.touched}
                                    type="text"
                                    placeholder={t('next.of.kin.relationship')}
                                    onChange={(value: any) => {
                                        this.setState({ nextOfKinRelationship: Object.assign({}, this.state.nextOfKinRelationship, { value }) });
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <InputField
                                    defaultValue={this.state.nextOfKinPhoneNumber.value}
                                    touched={this.state.nextOfKinPhoneNumber.touched}
                                    type="text"
                                    placeholder={t('next.of.kin.phone.number')}
                                    onChange={(value: any) => {
                                        this.setState({ nextOfKinPhoneNumber: Object.assign({}, this.state.nextOfKinPhoneNumber, { value }) });
                                    }}
                                />
                            </div>
                            <div className="col-12 mt-3 mb-3">
                                <Submit
                                    faIcon="far fa-hdd"
                                    disabled={!this.isFormValid()}
                                    pleaseWait={this.state.save.pleaseWait}
                                >
                                    {t('save')}
                                </Submit>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    }

    isFormValid() {
        return true;
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                gender: Object.assign({}, this.state.gender, { touched: true }),
                personalPhoneNumber: Object.assign({}, this.state.personalPhoneNumber, { touched: true }),
                emergencyPhoneNumber: Object.assign({}, this.state.emergencyPhoneNumber, { touched: true }),
                nextOfKinName: Object.assign({}, this.state.nextOfKinName, { touched: true }),
                nextOfKinRelationship: Object.assign({}, this.state.nextOfKinRelationship, { touched: true }),
                nextOfKinPhoneNumber: Object.assign({}, this.state.nextOfKinPhoneNumber, { touched: true }),
            },
            () => { if (this.isFormValid()) { this.save(); } }
        );
    }

    save() {
        const t = this.translate();
        const companyUser = Object.assign({}, this.props.employee);
        delete companyUser.office;
        delete companyUser.birthDay;
        delete companyUser.age;
        try {
            companyUser.birthDate = formatForServer(this.state.birthDate.value);
        } catch (e) {
            companyUser.birthDate = null;
        }
        companyUser.gender = this.state.gender.value && this.state.gender.value[0] ? this.state.gender.value[0] : null;
        companyUser.personalPhoneNumber = this.state.personalPhoneNumber.value;
        companyUser.emergencyPhoneNumber = this.state.emergencyPhoneNumber.value;
        companyUser.nextOfKinName = this.state.nextOfKinName.value;
        companyUser.nextOfKinRelationship = this.state.nextOfKinRelationship.value;
        companyUser.nextOfKinPhoneNumber = this.state.nextOfKinPhoneNumber.value;
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getEmployeePersonalUpdate(this.props.employee.id),
            dataType: 'json',
            cache: false,
            data: JSON.stringify(companyUser),
            beforeSend: () => {
                this.setState({ save: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('the.employee.personal.details.were.updated'));
                doFetchUnitsFromServer(this.getCaches());
                doFetchUsersFromServer(this.getCaches(), this.getSession().getState());
                if (this.props.onRefresh) {
                    this.props.onRefresh();
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.generalErrorToastr();
            }
        });
    }
}