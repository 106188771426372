import * as React from 'react';
import MenuBase from './MenuBase';
import { Navbar, Nav, DropdownMenu, DropdownItem, Dropdown, DropdownToggle, NavItem } from 'reactstrap';
import Anchor from '../../Components/Anchor';
import NavLinkExt from '../../Components/NavLinkExt';
import { none } from '../../Actions/Modal';
import { MODALS } from '../../Constants/ActionTypes';
import Urls from '../../Urls';

export default class MenuNotLogged extends MenuBase {
    constructor(props: any) {
        super(props);
        this.toggleLang = this.toggleLang.bind(this);
        (this.state as any).isLangOpen = false;
    }

    toggleLang() { this.setState({ isLangOpen: !this.state.isLangOpen }); }

    render() {
        const t = this.translate();
        const menuItems = this.getNotLoggedMenuItems();
        const languages = [
            { iso: 'ro' },
            { iso: 'en' },
            { iso: 'fr' },
            { iso: 'de' },
            { iso: 'es' },
        ].map((language: any) => {
            language.active = (language.iso === this.language());
            return language;
        }).sort((a: any, b: any) => {
            if (a.active === b.active) {
                return (a.iso < b.iso ? -1 : (a.iso > b.iso ? 1 : 0));
            }
            if (a.active === false) {
                return 1;
            }
            return -1;
        });
        const currentUrl = window.location.href;
        const items =
            (currentUrl.indexOf(Urls.getLocalOAuth()) > -1) || (currentUrl.indexOf(Urls.getGoogleOAuth()) > -1) ||
                (currentUrl.indexOf(Urls.getOffice365OAuth()) > -1) ||
                (currentUrl.indexOf(Urls.getSlackOAuth()) > -1) ||
                (currentUrl.indexOf('addedtoslack.html') > -1) ? [] :
                menuItems.map((menuItem: any) => {
                    return (
                        <li
                            key={'Menu' + menuItem.faIcon}
                            className={'nav-item bg-light' + (menuItem.match(currentUrl) ? ' active' : '')}
                            onClick={(event: any) => { this.onClick(); }}
                        >
                            <NavLinkExt
                                active={menuItem.match(currentUrl)}
                                href={menuItem.href}
                                faIcon={menuItem.faIcon + ' ml-2 mr-2'}
                                title={menuItem.title}
                                className="text-dark"
                            >
                                {menuItem.title}
                            </NavLinkExt>
                        </li>
                    );
                });
        items.push(
            (
                <NavItem key="MenuItemChangeLangS" className="d-md-none">
                    <div className="float-right">
                        <i className="fas fa-globe fa-fw text-dark mr-1" />
                        {
                            languages.map((language) => {
                                return (
                                    <a
                                        key={language.iso}
                                        onClick={() => { this.changeLanguage(language.iso); }}
                                        href="##"
                                        className={'nav-link text-dark d-inline pr-2 pl-2' + (language.active ? ' font-weight-bold' : '')}
                                    >
                                        {language.iso}
                                    </a>
                                );
                            })
                        }
                    </div>
                </NavItem>
            )
        );
        items.push(
            (
                <Dropdown
                    key="MenuItemChangeLangMd"
                    nav={true}
                    isOpen={this.state.isLangOpen}
                    toggle={this.toggleLang}
                    className="nav-item d-none d-md-block"
                >
                    <DropdownToggle nav={true} className="nav-link text-dark" title={t('language')}>
                        <i className="fas fa-globe fa-fw" />
                    </DropdownToggle>
                    <DropdownMenu className="lang-dropdown-menu">
                        {
                            languages.map((language) => {
                                return (
                                    <DropdownItem
                                        key={language.iso}
                                        style={{ maxWidth: 60 }}
                                        tag="div"
                                        className="p-0 text-center"
                                    >
                                        <a
                                            onClick={() => { this.changeLanguage(language.iso); }}
                                            href="##"
                                            className={'nav-link p-1 text-dark' + (language.active ? ' font-weight-bold' : '')}
                                        >
                                            {language.iso}
                                        </a>
                                    </DropdownItem>
                                );
                            })
                        }
                    </DropdownMenu>
                </Dropdown>
            )
        );
        return (
            <Navbar
                expand="md"
                className={'w-100 bg-light border-bottom rounded-0' + (this.isScreenSizeSmall() ? ' sticky-top' : '')}
                style={{ borderColor: 'rgba(0, 0, 0, 0.125)!important' }}
            >
                {this.isScreenSizeSmall() ?
                    (
                        <Nav navbar={true} className="w-100">
                            <li className="nav-item">
                                <Anchor
                                    faIcon={(this.state.isOpen ? 'fas fa-bars' : 'fas  fa-bars') + ' ml-2 mr-2 bg-light'}
                                    className="nav-brand text-dark"
                                    onClick={(event: any) => { this.toggle(); }}
                                >
                                    LeaveBoard
                                </Anchor>
                            </li>
                            {this.state.isOpen ? items : null}
                        </Nav>
                    )
                    :
                    (
                        [
                            (<a key="Menu-NavBrand" className="navbar-brand text-dark" href="/">LeaveBoard</a>),
                            (<Nav key="Menu-Nav" className="ml-auto" navbar={true}>{items}</Nav>)
                        ]
                    )
                }
            </Navbar>
        );
    }

    onClick() {
        if ((this.getModal().getState() as any).type !== MODALS.NONE) {
            none(this.getModal(), {});
        }
        this.toggle();
    }
}