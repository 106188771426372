import * as React from 'react';
import Component from '../../Component';
import { findLocaleName } from '../../Utils';

export default class LeaveName extends Component {
    render() {
        const leave = this.props.leave;
        const faStyle = { color: leave.leaveType.color };
        const name = findLocaleName(leave.leaveType.names, this.language());
        return (
            <span>
                <i className="fa fa-circle fa-fw" style={faStyle} aria-hidden="true" />
                {' ' + name}
            </span>
        );
    }
}