import * as React from 'react';
import Component from '../../Component';
import { LEAVEDAYTYPE, LEAVESTATUS } from '../../General';

export default class LeaveAmount extends Component {
    render() {
        const amountForStatus = this.getAmountForStatus();
        if (amountForStatus && amountForStatus.length > 0) {
            return <span>
                {amountForStatus}
            </span>
        }
        return '';
    }

    getAmountForStatus() {
        const t = this.translate();
        var leave = this.props.leave;
        switch (leave.status) {
            case LEAVESTATUS.REJECTED:
                return '';
            case LEAVESTATUS.CANCELED:
                return '';
            default:
                const moment = this.getMomentWithLocale();
                switch (leave.dayType) {
                    case LEAVEDAYTYPE.FULL:
                        if (leave.amount) {
                            return moment.duration(leave.amount, 'days').humanize({ d: 1000 });
                        } else {
                            return moment.duration(
                                moment(leave.endDate).add(1, 'days').diff(moment(leave.startDate)))
                                .humanize({ d: 1000 });
                        }
                    case LEAVEDAYTYPE.FIRST_PART:
                        return t('LEAVEDAYTYPE.' + LEAVEDAYTYPE.FIRST_PART);
                    case LEAVEDAYTYPE.SECOND_PART:
                        return t('LEAVEDAYTYPE.' + LEAVEDAYTYPE.SECOND_PART);
                    case LEAVEDAYTYPE.HOURS:
                        return moment.duration(leave.hours, 'hours').humanize({ d: 1000 });
                    default:
                        return '';
                }
        }
    }
}