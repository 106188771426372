import * as React from 'react';
import Component from '../../Component';
import * as $ from 'jquery';
import InputField from '../../Components/InputField';
import Submit from '../../Components/Submit';
import Endpoints from '../../Endpoints';
import { getURLParameter } from '../../Utils';

export default class ResetPassword extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            email: {
                value: getURLParameter('email'),
                touched: false,
                rules: this.Rules().BasicEmailRules()
            },
            code: {
                value: getURLParameter('token'),
                touched: false,
                rules: this.Rules().BasicInputRules()
            },
            password: {
                touched: false,
                rules: this.Rules().BasicPasswordRules()
            },
            change: {
                pleaseWait: false
            }
        };
    }

    render() {
        const t = this.translate();
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className={this.getCardClassNameSize() + ' ml-auto mr-auto'}>
                            <div className="card-body">
                                <form onSubmit={(event: any) => { this.submit(event); }}>
                                    <fieldset>
                                        <div className="row">
                                            <div className="col-12 mb-3 text-left">
                                                {t('reset.password')}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <InputField
                                                    defaultValue={this.state.email.value}
                                                    touched={this.state.email.touched}
                                                    rules={this.state.email.rules}
                                                    readOnly={true}
                                                    placeholder={t('your.email.address')}
                                                    onChange={(value: any) => {
                                                        // tslint:disable-next-line:max-line-length
                                                        this.setState({ email: Object.assign({}, this.state.email, { value: value }) });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12 mb-3">
                                                <InputField
                                                    defaultValue={this.state.code.value}
                                                    touched={this.state.code.touched}
                                                    rules={this.state.code.rules}
                                                    readOnly={true}
                                                    placeholder={t('code')}
                                                    onChange={(value: any) => {
                                                        // tslint:disable-next-line:max-line-length
                                                        this.setState({ email: Object.assign({}, this.state.email, { value: value }) });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 mb-3">
                                                <InputField
                                                    touched={this.state.password.touched}
                                                    rules={this.state.password.rules}
                                                    type="password"
                                                    focus={this.isScreenSizeSmall() ? false : true}
                                                    placeholder={t('new.password')}
                                                    onChange={(value: any) => {
                                                        // tslint:disable-next-line:max-line-length
                                                        this.setState({ password: Object.assign({}, this.state.password, { value: value }) });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <Submit
                                                    faIcon="fas fa-key"
                                                    disabled={!this.isFormValid()}
                                                    pleaseWait={this.state.change.pleaseWait}
                                                >
                                                    {t('change.password')}
                                                </Submit>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    isFormValid() {
        return this.Validator().validate(this.state.email) &&
            this.Validator().validate(this.state.code) &&
            this.Validator().validate(this.state.password);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                email: Object.assign({}, this.state.email, { touched: true }),
                code: Object.assign({}, this.state.code, { touched: true }),
                password: Object.assign({}, this.state.password, { touched: true }),
            },
            () => {
                if (this.isFormValid()) {
                    this.resetAction();
                }
            });
    }

    resetAction() {
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: Endpoints.getResetPassword(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                email: this.state.email.value,
                code: this.state.code.value,
                password: this.state.password.value,
            }),
            beforeSend: () => {
                this.setState({ recover: Object.assign({}, this.state.recover, { pleaseWait: true }) });
            },
            success: (data, textStatus, jqXHR) => {
                this.loginNow();
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            },
            complete: (jqXHR, textStatus) => {
                this.setState({ recover: Object.assign({}, this.state.recover, { pleaseWait: false }) });
                this.setState({ sent: true });
            }
        });
    }

    loginNow() {
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getLocalExchange(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                local: {
                    email: this.state.email.value,
                    password: this.state.password.value
                }
            }),
            beforeSend: () => {
                this.setState({ login: { blocked: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.goToLocalOAuth(data.code);
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
                this.setState({ login: { blocked: false } });
            },
        });
    }
}