import * as React from 'react';
import Component from '../../../../Component';
import Button from '../../../../Components/Button';
import Date from '../../../../Components/Date';
import { SUBSCRIPTIONTYPE } from '../../../../General';

export default class Display extends Component {

    render() {
        const moment = this.getMomentWithLocale();
        const t = this.translate();
        const currentSubscription = this.props.currentSubscription;
        const caches: any = this.getCaches().getState();
        return (
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <table className="table table-sm w-100 m-0 p-0">
                                <thead>
                                    <tr>
                                        <th className="border-top-0">
                                            {
                                                (currentSubscription.subscriptionType !== SUBSCRIPTIONTYPE.SUBSCRIPTION) ?
                                                    (<strong className="d-inline">
                                                        {t('subscription')}
                                                    </strong>)
                                                    :
                                                    null
                                            }
                                            <strong
                                                className={(currentSubscription.subscriptionType !== SUBSCRIPTIONTYPE.SUBSCRIPTION ?
                                                    'float-right' : 'float-left') + ' text-success  d-inline'}
                                            >
                                                {t('subscription.' + currentSubscription.subscriptionType)}
                                            </strong>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (currentSubscription.subscriptionType === SUBSCRIPTIONTYPE.FREE) ?
                                            <tr>
                                                <td>
                                                    {t('active.employees')}
                                                    <span className="float-right">
                                                        {currentSubscription.currentUsers}
                                                    </span>
                                                </td>
                                            </tr>
                                            :
                                            null
                                    }
                                    {
                                        (currentSubscription.subscriptionType === SUBSCRIPTIONTYPE.TRIAL) ?
                                            (<tr>
                                                <td>
                                                    {(moment(currentSubscription.trialEndsOn).isBefore(moment())) ?
                                                        t('expired.on')
                                                        :
                                                        t('expires.on')
                                                    }
                                                    <span className={'float-right' + (moment(currentSubscription.trialEndsOn).isBefore(moment()) ? ' text-danger font-weight-bold' : '')} >
                                                        <Date date={moment(currentSubscription.trialEndsOn)} />
                                                    </span>
                                                </td>
                                            </tr>)
                                            :
                                            null
                                    }
                                    {
                                        (currentSubscription.subscriptionType === SUBSCRIPTIONTYPE.TRIAL && moment(currentSubscription.trialEndsOn).isBefore(moment())) ?
                                            (<tr>
                                                <td>
                                                    <div><small>{t('your.trial.period.has.ended')}</small></div>
                                                    <div><small>{t('please.subscribe.now')}</small></div>
                                                </td>
                                            </tr>)
                                            :
                                            null
                                    }
                                    {
                                        (currentSubscription.subscriptionType === SUBSCRIPTIONTYPE.SUBSCRIPTION) ?
                                            [
                                                (<tr key={SUBSCRIPTIONTYPE.SUBSCRIPTION + '2'}>
                                                    <td>
                                                        {t('employees.under.subscription')}
                                                        <span className="float-right">
                                                            {currentSubscription.userMonthSubscription}
                                                        </span>
                                                    </td>
                                                </tr>),
                                                (<tr key={SUBSCRIPTIONTYPE.SUBSCRIPTION + '3'}>
                                                    <td>
                                                        {t('next.billing.date')}
                                                        <span className="float-right">
                                                            <Date date={moment(currentSubscription.expiresOnSubscription)} />
                                                        </span>
                                                    </td>
                                                </tr>),
                                            ]
                                            :
                                            null
                                    }
                                    {caches.users.active.arr.length > 9 ?
                                        <tr key={SUBSCRIPTIONTYPE.SUBSCRIPTION + '3'}>
                                            <td className="m-0 p-0">
                                                <Button
                                                    type="button"
                                                    className="mt-3 btn btn-outline-primary btn-block"
                                                    faIcon="fa fa-shopping-cart"
                                                    onClick={() => { this.goTo(this.Urls().getOrderSubscription()); }}
                                                >
                                                    {t('order')}
                                                </Button>
                                            </td>
                                        </tr>
                                        :
                                        null
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}