import * as $ from 'jquery';
import Component from '../../Component';
import Back from '../../Components/Back';
import ETOL from '../../Components/ETOL';
import Line from '../../Components/Line';
import { fullNameFactory } from '../../Factories';
import { ETOLTABS, ORDER, STATUS, USERSTATUS, VIEWMODE } from '../../General';
import { Cookie, getURLParameter, handleJQXHRError, Jqxhrs } from '../../Utils';
import Download from './Download';
import Filters from './Filters';
import List from './List';

export default class Manager extends Component {
    protected jqxhrs = new Jqxhrs();
    public search: any;
    constructor(props?: any) {
        super(props);
        let viewMode = Cookie.read(Cookie.EMPLOYEES_YEARS_OF_SERVICE_VIEWMODE);
        if (!viewMode) {
            viewMode = VIEWMODE.LIST;
            Cookie.create(Cookie.EMPLOYEES_YEARS_OF_SERVICE_VIEWMODE, viewMode);
        }
        const t = this.translate();
        const sortByFields = [
            { name: t("name"), value: 'NAME' },
            { name: t("employment.date"), value: 'EMPLOYMENT_DATE' },
            { name: t("service.length"), value: 'SERVICE_LENGTH' },
            { name: t("next.service.year.in"), value: 'NEXT_SERVICE_YEAR_IN' },];
        this.state = {
            filters: {
                search: { value: '' },
                employeeStatuses: { value: [USERSTATUS.ACTIVE, USERSTATUS.INVITED] },
                units: { value: [] },
                countries: { value: [] },
                offices: { value: [] },
                approvers: { value: [] },
                viewMode: viewMode,
                sortBy: this.getSortBy(sortByFields, 'ASC'),
            },
            employees: {
                arr: [],
                status: STATUS.LOADING
            },
            status: STATUS.LOADING
        };
        this.search = Manager.search.bind(this);
    }

    public getSortBy(fields: any, defaultOrder: any) {
        const map = new Map();
        fields.forEach((field: any) => map.set(field.name, field.value))
        const sortBy = getURLParameter('sortBy');
        const order = getURLParameter('order')
        const field = map.get(sortBy) ? map.get(sortBy) : fields[3];
        return { field, order: order === 'ASC' ? 'ASC' : (order === 'DESC' ? 'DESC' : defaultOrder), fields };
    }

    componentDidMount() {
        this.search();
    }

    public static search(this: any) {
        const moment = this.getMomentWithLocale();
        const now = moment().startOf('day');
        const session = this.getSession().getState() as any;
        this.setState(
            { employees: { arr: [], status: STATUS.LOADING } },
            () => {
                this.jqxhrs.abortAndSet('search', $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getEmployeesYearsOfServiceSearch({ filters: this.state.filters }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        const list = data.list.map((row: any) => {
                            const ret = JSON.parse(row[1]);
                            ret.fullName = fullNameFactory(ret, session.company.nameFormat);
                            ret.employmentStartDate = ret.employmentStartDate ? moment(ret.employmentStartDate) : null;
                            if (ret.employmentStartDate) {
                                if (now.format('MM-DD') === ret.employmentStartDate.format('MM-DD')) {
                                    ret.todayIsNewYearOfService = true;
                                }
                            }
                            ret.service = ret.employmentStartDate ? moment.duration(now.diff(moment(ret.employmentStartDate))) : null;
                            ret.next = ret.employmentStartDate ?
                                moment(ret.employmentStartDate).year(now.year()).isSameOrBefore(now) ?
                                    moment.duration(moment(ret.employmentStartDate).year(now.year() + 1).diff(now))
                                    : moment.duration(moment(ret.employmentStartDate).year(now.year()).diff(now))
                                : null;
                            if (ret.todayIsNewYearOfService) {
                                ret.next = moment.duration();
                            }
                            if (ret.employmentStartDate && ret.employmentStartDate.isSame(now)) {
                                ret.next = moment.duration(1, 'year');
                            }
                            return ret;
                        });
                        const sort = (a: any, b: any) => {
                            switch (this.state.filters.sortBy.field.value) {
                                case 'NAME':
                                    if (this.state.filters.sortBy.order === ORDER.ASC)
                                        return a.fullName.toLowerCase().localeCompare(b.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
                                    else
                                        return b.fullName.toLowerCase().localeCompare(a.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
                                case 'EMPLOYMENT_DATE':
                                    if (this.state.filters.sortBy.order === 'DESC') {
                                        if (a.employmentStartDate < b.employmentStartDate)
                                            return 1;
                                        if (a.employmentStartDate > b.employmentStartDate)
                                            return -1;
                                        return 0;
                                    } else {
                                        if (a.employmentStartDate > b.employmentStartDate)
                                            return 1;
                                        if (a.employmentStartDate < b.employmentStartDate)
                                            return -1;
                                        return 0;
                                    }
                                case 'NEXT_SERVICE_YEAR_IN':
                                    if (this.state.filters.sortBy.order === 'ASC') {
                                        if (a.next < b.next)
                                            return -1;
                                        if (a.next > b.next)
                                            return 1;
                                        return 0;
                                    } else {
                                        if (a.next > b.next)
                                            return -1;
                                        if (a.next < b.next)
                                            return 1;
                                        return 0;
                                    }
                                case 'SERVICE_LENGTH':
                                    if (this.state.filters.sortBy.order === 'ASC') {
                                        if (a.service < b.service)
                                            return -1;
                                        if (a.service > b.service)
                                            return 1;
                                        return 0;
                                    } else {
                                        if (a.service > b.service)
                                            return -1;
                                        if (a.service < b.service)
                                            return 1;
                                        return 0;
                                    }
                                default:
                                    return 0;
                            }
                        }
                        list.sort(sort);
                        this.setState({ employees: { arr: list, status: STATUS.READY } });
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        handleJQXHRError(jqXHR, textStatus, errorThrown, () => { this.setState({ data: { arr: null, count: 0, status: 'ERROR' } }); });
                    },
                    complete: (jqXHR: any, textStatus: any) => {
                        this.jqxhrs.complete('search');
                    }
                }));
            });
    }

    public componentWillUnmount() { this.jqxhrs.abortAll(); }

    render() {
        const t = this.translate();
        return <div className="container-fluid">
            <div className="row"><div className="col-12 mb-3">
                <Back href={this.Urls().getEmployeesReports()} /> {t('service.length')}
            </div></div>
            <div className="row"><div className="col-12 mb-3"><ETOL currentTab={ETOLTABS.REPORTS} /></div></div>
            <div className="row"><div className="col-12 mb-3"><Line /></div></div>
            <Filters
                download={(new Download({ filters: this.state.filters }))}
                defaultFilters={this.state.filters} viewMode={this.state.filters.viewMode} sortBy={this.state.filters.sortBy}
                onChange={(value: any) => { this.setState({ filters: Object.assign({}, value) }, () => { this.search(); }); }}
            />
            <div className="row">
                <List employees={this.state.employees} viewMode={this.state.filters.viewMode} onRefresh={() => { this.search(); }} />
            </div>
        </div>
    }
}