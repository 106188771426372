import * as React from 'react';
import Component from '../../Component';
import Form from './Form';
import { STATUS } from '../../General';
import * as $ from 'jquery';

export default class EmployeesInvite extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            creator: {
                value: undefined,
                status: STATUS.LOADING
            },
        };
    }

    componentDidMount() {
        $.ajax({
            type: 'GET',
            contentType: 'application/json',
            url: this.Endpoints().getEmployee((this.getSession().getState() as any).companyUser.id),
            dataType: 'json',
            cache: false,
            success: (data, textStatus, jqXHR) => {
                data.office = data.calendar;
                this.setState({ creator: { value: data, status: STATUS.READY } });
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
                this.setState({ creator: { value: undefined, status: STATUS.ERROR }, });
            },
        });
    }

    render() {
        let ready = this.state.creator.status === STATUS.READY;
        if (ready === false) { return this.renderLoading(); }
        return (<Form creator={this.state.creator.value} />);
    }
}