import * as React from 'react';
import Component from '../../../Component';
import Anchor from '../../../Components/Anchor';
import A from '../../../Components/A';
import Error from '../../../Components/Error';
import { STATUS } from '../../../General';
import { addTeamMembers } from '../../../Actions/Modal';

export default class List extends Component {

    render() {
        const employees = this.props.employees;
        switch (employees.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                let trs: any = undefined;
                if (employees.arr.length === 0) {
                    trs = (
                        <tr>
                            <td className="text-danger">
                                {t('no.team.members.found')}
                            </td>
                        </tr>
                    );
                } else {
                    trs = employees.arr.map((employee: any) => {
                        return (
                            <tr key={'Unit-Members-List' + employee.id}>
                                <td>
                                    <A
                                        faIcon="far fa-user"
                                        href={this.Urls().getEmployee(employee.id)}
                                        title={employee.fullName}
                                    >
                                        {employee.fullName}
                                    </A>
                                </td>
                            </tr>
                        );
                    });
                }

                return (
                    <div className={this.getCardClassNameSize()}>
                        <div className="card-body">
                            <table className="table table-sm table-hover w-100 mb-0">
                                <thead>
                                    <tr>
                                        <th className="border-top-0">
                                            <Anchor
                                                // tslint:disable-next-line:max-line-length
                                                faIcon="fa fa-plus"
                                                title={t('add.team.members')}
                                                onClick={() => {
                                                    addTeamMembers(
                                                        this.getModal(), {
                                                            unit: this.props.unit,
                                                            callback: () => {
                                                                this.props.onRefresh();
                                                            }
                                                        }
                                                    );
                                                }}
                                            >
                                                {t('add.team.members')}
                                            </Anchor>
                                            <Anchor
                                                className="float-right font-weight-light text-secondary"
                                                title={employees.arr.length + ' ' +
                                                    (employees.arr.length === 1 ? t('member') : t('members'))}
                                            >
                                                {employees.arr.length}
                                            </Anchor>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {trs}
                                </tbody>
                            </table>
                        </div>
                    </div>
                );
            }
            default:
                return (<Error />);
        }
    }
}