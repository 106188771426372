import * as React from 'react';
import Component from '../../Component';
import Anchor from '../Anchor';
import { cancelLeave } from '../../Actions/Modal';

export default class LeaveCancelAction extends Component {
    render() {
        const t = this.translate();
        return (
            <Anchor
                faIcon="fas fa-ban"
                className="text-danger"
                title={t('cancel.request')}
                onClick={() => {
                    cancelLeave(
                        this.getModal(),
                        {
                            leave: this.props.leave,
                            callback: () => {
                                if (this.props.onRefresh) {
                                    this.props.onRefresh();
                                }
                            }
                        }
                    );
                }}
            >
                {this.props.noText ? null : t('cancel.request')}
            </Anchor >
        );
    }
}