import * as React from 'react';
import Component from '../../Component';
import { COMPANYUSERROLES, STATUS } from '../../General';
import Form from './Form';

export default class UnitAdd extends Component {

    render() {
        const session: any = this.getSession().getState();
        const isAdmin = () => {
            return session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN;
        };
        if (!isAdmin()) {
            const location = window.location + '';
            this.goToLogin({ target: location });
            return null;
        }
        const caches: any = this.getCaches().getState();
        let ready = session.status === STATUS.READY &&
            caches.users.status === STATUS.READY &&
            caches.countries.status === STATUS.READY;
        if (ready === false) { return this.renderLoading(); }
        return (<Form />);
    }
}