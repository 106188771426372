import BaseDownload from '../../../Components/BaseDownload';
import { findLocaleName } from '../../../Utils';
import Manager from './Manager';

/* eslint-disable @typescript-eslint/no-unused-expressions */
export default class Download extends BaseDownload {
    computeRows: any;
    getLeaveTypes: any;
    constructor(options: any) {
        super(options);
        this.search = Manager.search.bind(this);
        this.getLeaveTypes = Manager.getLeaveTypes.bind(this);
    }

    getData() {
        const t = this.t;
        const moment = this.getMomentWithLocale();
        let leaveTypes = this.getLeaveTypes(this);
        const session = this.getSession().getState() as any;
        const companyId = session.company.id;
        const header = companyId === 9118 ? [t('email'), t('employee'), t('working'), t('worked')] : [t('employee'), t('working'), t('worked')];
        leaveTypes.forEach((leaveType: any) => {
            header.push(this.getName(leaveType));
        })
        let map: any = {};
        map.working = 0;
        map.worked = 0;
        leaveTypes.forEach((leaveType: any) => {
            map[leaveType.id] = 0;
        });
        const endDate = moment(this.options.filters.endDate.value);
        const startDate = moment(this.options.filters.startDate.value);
        const data: any = this.state.rows.arr.map((row: any) => {
            const employmentEndDate = row.employmentEndDate;
            const employmentStartDate = row.employmentStartDate;
            let totalDaysNumber: any = moment(employmentEndDate && employmentEndDate.valueOf() < endDate.valueOf() ? employmentEndDate : endDate)
                .diff(moment(employmentStartDate && employmentStartDate.valueOf() > startDate.valueOf() ? employmentStartDate : startDate), 'days') + 1;
            if (totalDaysNumber < 0)
                totalDaysNumber = 0;
            let working = (totalDaysNumber - row.notWorkingAmount);
            map.working += working;
            let workedAmount = (totalDaysNumber - row.notWorkingAmount);
            const leaveTypeIdAmounts = leaveTypes.map((leaveType: any) => {
                var amount: number = 0;
                for (let i = 0; i < row.generalReportLeaveTypeAmounts.length; i++) {
                    if (leaveType.id === row.generalReportLeaveTypeAmounts[i].leaveTypeId) {
                        amount = row.generalReportLeaveTypeAmounts[i].amount;
                        if (!leaveType.working) {
                            workedAmount -= row.generalReportLeaveTypeAmounts[i].amount;
                        }
                        break;
                    }
                }
                map[leaveType.id] += amount;
                return {
                    leaveTypeId: leaveType.id,
                    amount: amount
                };
            });
            map.worked += workedAmount;
            return (companyId === 9118 ? [row.email, row.fullName, working, workedAmount] : [row.fullName, working, workedAmount]).concat(leaveTypeIdAmounts.map((leaveTypeIdAmount: any) => {
                return leaveTypeIdAmount.amount;
            }));
        });
        const footer = (companyId === 9118 ? ['', '', map.working,
            map.worked] : ['', map.working,
            map.worked]).concat(leaveTypes.map((leaveType: any) => {
                return map[leaveType.id] ? map[leaveType.id] : '0';
            }));
        const arr = [header].concat(data).concat([footer]);
        return arr;
    }

    public isReady() {
        return this.state.rows && this.state.rows.status === 'READY';
    }

    getFileName(this: any): any {
        const t = this.t;
        return this.ansi(t('general.situation')) + this.getSufixFileName();
    }

    public getName(leaveType: any) {
        return findLocaleName(leaveType.names, this.language());
    }
}