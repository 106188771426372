import * as React from 'react';
import Component from '../../../Component';
import Manager from './Manager';
import { STATUS } from '../../../General';

export default class Members extends Component {
    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.users.status === STATUS.READY &&
            (this.getSession().getState() as any).status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return <Manager office={this.props.office} />
    }
}