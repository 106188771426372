import * as React from 'react';
import Component from '../../Component';
import Anchor from '../../Components/Anchor';
import LeaveEmployee from '../Reports/LeaveEmployee';
import LeaveInterval from '../../Components/Leave/LeaveInterval';
import LeaveReplacement from '../../Components/Leave/LeaveReplacement';
import LeaveDetailedStatus from '../../Components/Leave/LeaveDetailedStatus';
import LeaveEventTime from '../../Components/Leave/LeaveEventTime';
import LeaveDecisionAction from '../../Components/Leave/LeaveDecisionAction';
// import { LEAVESTATUS } from '../../General';
import LeaveRules from '../../LeaveRules';
import { LEAVESTATUS } from '../../General';
import LeaveName from '../../Components/Leave/LeaveName';

export default class Item extends Component {

    render() {
        const moment = this.getMomentWithLocale();
        const t = this.translate();
        const leave = this.props.leave;
        const canBeRejected = LeaveRules.canBeRejected(leave, (this.getSession().getState() as any).companyUser);
        const canBeApproved = LeaveRules.canBeApproved(leave, (this.getSession().getState() as any).companyUser);
        return (
            <div className="card bg-white w-100 h-100">
                <div className="card-body">
                    <table className="table table-sm w-100 h-100 m-0 p-0">
                        <thead>
                            <tr>
                                <th className="border-top-0 border-bottom-0">
                                    <LeaveEmployee employee={leave.companyUser} />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <LeaveName leave={leave} />
                                    <span className="ml-1 float-right">
                                        <LeaveEventTime>
                                            {t('registered.on')}
                                            <div>{moment(leave.requestedOn).format('LLL')}</div>
                                            {
                                                (leave.createdBy) ?
                                                    (<div>{t('by')}<span className="ml-1"><LeaveEmployee employee={leave.createdBy} /></span></div>) : null
                                            }
                                        </LeaveEventTime>
                                    </span>
                                </td>
                            </tr>
                            <tr><td>
                                <LeaveInterval leaveRequestInThePast={true} leave={leave} />
                            </td></tr>
                            {(leave.status !== LEAVESTATUS.AWAITING_APPROVAL) ? <tr><td><LeaveDetailedStatus leave={leave} /></td></tr> : null}
                            {leave.comment ? (<tr><td><Anchor faIcon="far fa-comment mr-1 fa-fw" title={t('request.comment')} />{leave.comment}</td></tr>) : null}
                            {
                                leave.replacement && leave.replacement.length > 2 ?
                                    <tr><td><LeaveReplacement leave={leave} /></td></tr>
                                    :
                                    null
                            }
                            {
                                (canBeRejected && canBeApproved) ?
                                    <tr><td className="m-0 p-0 border-0 align-bottom">
                                        <div className="mb-3" />
                                        <LeaveDecisionAction leave={leave} onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }} />
                                    </td></tr>
                                    :
                                    null
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}