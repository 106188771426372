import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import { STATUS } from '../../../General';
import { findLocaleName } from '../../../Utils';
import Date from '../../../Components/Date';
import List from './List';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        this.state = {
            blackoutEvents: {
                arr: [],
                status: STATUS.LOADING
            },
            currentYear: {
                value: moment().year()
            }
        };
    }

    componentDidMount() {
        this.search();
    }

    search() {
        const moment = this.getMomentWithLocale();
        this.setState(
            { blackoutEvents: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getBlackoutEvents(
                        {
                            filters: {
                                office: { value: this.props.office },
                                startDate: { value: moment(this.state.currentYear.value + '-01-01').startOf('year').startOf('day') },
                                endDate: { value: moment(this.state.currentYear.value + '-01-01').endOf('year').endOf('day') }
                            }
                        }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            {
                                blackoutEvents: {
                                    arr: this.prepareHoes(data.data),
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                blackoutEvents: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        return <List
            office={this.props.office}
            blackoutEvents={this.state.blackoutEvents}
            currentYear={this.state.currentYear.value}
            onChangeYear={(year: any) => { this.setState({ currentYear: { value: year } }, () => { this.search(); }); }}
            onRefresh={() => { this.search(); }}
        />
    }

    prepareHoes(blackoutEvents: any[]) {
        const moment = this.getMomentWithLocale();
        const language = this.language();
        return blackoutEvents.map((blackoutEvent) => {
            var startDate = moment(blackoutEvent.startDate);
            var endDate = moment(blackoutEvent.endDate);
            blackoutEvent.name = findLocaleName(blackoutEvent.names, language);
            blackoutEvent.formatedDate = endDate.diff(startDate, 'days') === 0 ?
                <Date date={startDate} /> : <><Date date={startDate} /> - <Date date={endDate} /></>
            blackoutEvent.literalDay = moment(startDate).format('dd') + ' ';
            return blackoutEvent;
        });
    }
}