import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../Component';
import Date from '../../Components/Date';
import { STATUS } from '../../General';
import { getURLParameter } from '../../Utils';
import Form from './Form';

export default class SubscribeNow extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            orderSettings: {
                value: undefined,
                status: STATUS.LOADING
            },
            currentSubscription: {
                value: undefined,
                status: STATUS.LOADING
            },
            showTrialHasEnded: getURLParameter('trialHasEnded') ? true : false
        };
    }

    componentDidMount() {
        this.getCurrentSubscription();
        this.getOrderSettings();
    }

    getCurrentSubscription() {
        this.setState(
            {
                currentSubscription: {
                    value: undefined,
                    status: STATUS.LOADING
                }
            },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getCurrentSubscription(),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            {
                                currentSubscription: {
                                    value: data,
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                currentSubscription: {
                                    value: undefined,
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    getOrderSettings() {
        $.ajax({
            type: 'GET',
            contentType: 'application/json',
            url: this.Endpoints().getOrderSettings(),
            dataType: 'json',
            cache: false,
            success: (data, textStatus, jqXHR) => {
                this.setState({
                    orderSettings: {
                        value: data,
                        status: STATUS.READY
                    }
                });
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
                this.setState({
                    orderSettings: {
                        value: undefined,
                        status: STATUS.ERROR
                    },
                });
            },
        });
    }

    render() {
        let ready = this.state.currentSubscription.status === STATUS.READY &&
            this.state.orderSettings.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        const moment = this.getMomentWithLocale();
        const showTrialHasEnded = this.state.showTrialHasEnded || (moment((this.getSession().getState() as any).company.endsOn).isBefore(moment()) && (this.getSession().getState() as any).company.subscriptionType === 'TRIAL');
        return (
            <div className="container-fluid">
                {showTrialHasEnded ? this.renderTrialHasEnded() : null}
                {((this.getSession().getState() as any).company.subscriptionType === 'TRIAL' && !showTrialHasEnded) ? this.renderTrial() : null}
                {this.isPaymentRequired() ? this.renderPaymentRequired() : null}
                <Form
                    orderSettings={this.state.orderSettings.value}
                    currentSubscription={this.state.currentSubscription.value}
                />
            </div>
        );
    }

    renderTrial() {
        const t = this.translate();
        const moment = this.getMomentWithLocale();
        return (
            <div className="row">
                <div className="col-12 mb-3">
                    <div className={this.getCardClassNameSize()}>
                        <div className="card-body">
                            <h3>
                                {t('your.trial.period.will.end.on')} <div><Date date={moment((this.getSession().getState() as any).company.endsOn)} /></div>
                            </h3>
                            <div>
                                {t('to.continue.using.all.features.of.leaveboard')}
                            </div>
                            <div>
                                <small>{t('if.you.would.like.to.extend.your.trial')}
                                    <a href={this.getENailUrl()} className="card-link ml-1">
                                        {this.getENail()}
                                    </a>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderTrialHasEnded() {
        const t = this.translate();
        return (
            <div className="row">
                <div className="col-12 mb-3">
                    <div className={this.getCardClassNameSize()}>
                        <div className="card-body">
                            <h3>
                                {t('your.trial.period.has.ended')}
                            </h3>
                            <div>
                                {t('to.continue.using.all.features.of.leaveboard')}
                            </div>
                            <div>
                                <small>{t('if.you.would.like.to.extend.your.trial')}
                                    <a href={this.getENailUrl()} className="card-link ml-1">
                                        {this.getENail()}
                                    </a>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    renderPaymentRequired() {
        const t = this.translate();
        return (
            <div className="row">
                <div className="col-12 mb-3">
                    <div className={this.getCardClassNameSize()}>
                        <div className="card-body">
                            <h3>
                                {t('your.subscription.needs.to.be.renewed')}
                            </h3>
                            <div>
                                {t('to.continue.using.all.features.of.leaveboard')}
                            </div>
                            <div>
                                <small>{t('if.you.have.any.questions.please.contact.us.at')}
                                    <a href={this.getENailUrl()} className="card-link ml-1">
                                        {this.getENail()}
                                    </a>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getENailUrl() {
        return this.getMailTo() + this.getENail();
    }

    getMailTo() {
        return 'mai' + this.getLTO();
    }

    getLTO() {
        return 'lto:';
    }

    getENail() {
        const off = 'office' + ((() => '@')());
        const dom = '.com';
        return off + 'leaveboard' + dom;
    }
}