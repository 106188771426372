import * as React from 'react';
import Component from '../../../../Component';
import * as $ from 'jquery';
import { STATUS } from '../../../../General';
import Display from './Display';

export default class Current extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            currentSubscription: {
                value: undefined,
                status: STATUS.LOADING
            }
        };
    }

    componentDidMount() {
        this.getCurrentSubscription();
    }

    render() {
        let ready = this.state.currentSubscription.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return <div className="row">
            <div className="col-12 mb-3">
                <Display currentSubscription={this.state.currentSubscription.value} />
            </div>
        </div>
    }

    getCurrentSubscription() {
        this.setState(
            {
                currentSubscription: {
                    value: undefined,
                    status: STATUS.LOADING
                }
            },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getCurrentSubscription(),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            {
                                currentSubscription: {
                                    value: data,
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                currentSubscription: {
                                    value: undefined,
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }
}