import * as React from 'react';
import Component from '../../Component';
import InputField from '../../Components/InputField';
import Submit from '../../Components/Submit';
import * as $ from 'jquery';
import { getURLParameter, Cookie } from '../../Utils';
import SlackButton from '../../Components/Integrations/SlackButton';
import OfficeButton from '../../Components/Integrations/OfficeButton';
import GoogleButton from '../../Components/Integrations/GoogleButton';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            email: {
                value: getURLParameter('email'),
                touched: false,
                rules: this.Rules().BasicEmailRules()
            },
            token: {
                value: getURLParameter('token'),
                touched: false,
                rules: this.Rules().BasicNameRules()
            },
            password: {
                touched: false,
                rules: this.Rules().BasicPasswordRules()
            },
            activate: {
                pleaseWait: false
            }
        };
    }

    render() {
        const t = this.translate();
        return (
            <div className="card h-100">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <h5 className="text-center m-0">{t('finish.your.account.activation')}</h5>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-3">
                            <GoogleButton />
                        </div>
                        <div className="col-md-4 mb-3">
                            <OfficeButton />
                        </div>
                        <div className="col-md-4 mb-3">
                            <SlackButton />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className="text-center text-muted m-0">{t('or')}</div>
                        </div>
                    </div>
                    <form onSubmit={(event: any) => { this.submit(event); }}                    >
                        <fieldset>
                            <div className="row">
                                <div className="col-12 mb-3">
                                    <InputField
                                        defaultValue={this.state.email.value}
                                        touched={this.state.email.touched}
                                        rules={this.state.email.rules}
                                        type="text"
                                        placeholder={t('email')}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <InputField
                                        defaultValue={this.state.token.value}
                                        touched={this.state.token.touched}
                                        rules={this.state.token.rules}
                                        type="text"
                                        readOnly={true}
                                        placeholder={t('activation.code')}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <InputField
                                        touched={this.state.password.touched}
                                        rules={this.state.password.rules}
                                        type="password"
                                        placeholder={t('password')}
                                        focus={this.isScreenSizeSmall() ? false : true}
                                        onChange={(value: any) => {
                                            this.setState(
                                                { password: Object.assign({}, this.state.password, { value: value }) }
                                            );
                                        }}
                                    />
                                </div>
                                <div className="col-12 mb-3">
                                    <Submit
                                        faIcon="fas fa-sign-in-alt"
                                        disabled={!this.isFormValid()}
                                        pleaseWait={this.state.activate.pleaseWait}
                                    >
                                        {t('activate')}
                                    </Submit>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
            </div>
        );
    }

    isFormValid() {
        return this.Validator().validate(this.state.email)
            && this.Validator().validate(this.state.token)
            && this.Validator().validate(this.state.password);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState({
            email: Object.assign({}, this.state.email, { touched: true }),
            token: Object.assign({}, this.state.token, { touched: true }),
            password: Object.assign({}, this.state.password, { touched: true })
        },
            // tslint:disable-next-line:align
            () => {
                if (this.isFormValid()) {
                    this.loginAction();
                }
            });
    }

    loginAction() {
        const target = getURLParameter('target');
        if (target) {
            Cookie.create(Cookie.REDIRECT_TARGET, target);
        }
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getLocalActivate(),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                email: this.state.email.value,
                code: this.state.token.value,
                password: this.state.password.value
            }),
            beforeSend: () => {
                this.setState({ activate: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.goToLocalOAuth(data.code);
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
                this.setState({ activate: { pleaseWait: false } });
            },
        });
    }

    getGoogleClientAuthUrl() {
        var google = {
            scope: ['https://www.googleapis.com/auth/calendar',
                'https://www.googleapis.com/auth/userinfo.email',
                'https://www.googleapis.com/auth/userinfo.profile',
                'https://www.googleapis.com/auth/plus.me',
                'https://www.googleapis.com/auth/tasks',
                'https://www.googleapis.com/auth/admin.directory.orgunit',
                'https://www.googleapis.com/auth/admin.directory.orgunit.readonly']
                .join(' '),
            response_type: 'code',
            client_id: '544789867804-8k2u4h49ido8dcjlsdhrsd7b7q7rrh4p.apps.googleusercontent.com', // prod
            // client_id: '675875848361-qo7h06p6k9aa1jch5kv8l7sjm7hf7d7j.apps.googleusercontent.com', //dev
            access_type: 'offline',
            approval_prompt: 'force',
            redirect_uri: this.Urls().getGoogleOAuth()
        };
        return 'https://accounts.google.com/o/oauth2/v2/auth?' + $.param(google);
    }

    getOffice365ClientAuthUrl() {
        var office365 = {
            scope: ['openid', 'offline_access', 'profile', 'https://graph.microsoft.com/User.Read',
                'https://graph.microsoft.com/User.ReadBasic.All',
                'https://graph.microsoft.com/Calendars.ReadWrite',
                'https://graph.microsoft.com/Tasks.ReadWrite']
                .join(' '),
            response_type: 'code',
            response_mode: 'query',
            // client_id: '606442e9-2eed-4bff-8867-69f1d3eab91a', //dev 
            client_id: 'dd8d4d44-3416-4d43-b23c-a1dcf200c45a', // prod
            redirect_uri: this.Urls().getOffice365OAuth()
        };
        return 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' + $.param(office365);
    }

    getSlackClientAuthUrl() {
        var slack = {
            scope: ['identity.basic', 'identity.email']
                .join(' '),
            response_type: 'code',
            // client_id: '185650446388.195997740098',//dev
            client_id: '185650446388.193628195299', // prod
            redirect_uri: this.Urls().getSlackOAuth()
        };
        return 'https://slack.com/oauth/authorize?' + $.param(slack);
    }
}