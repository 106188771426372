import * as $ from 'jquery';
import * as React from 'react';
import { doFetchUnitsFromServer, doFetchUsersFromServer } from '../../../Actions/Caches';
import Component from '../../../Component';
import Anchor from '../../../Components/Anchor';
import Close from '../../../Components/Close';
import DatePickerField from '../../../Components/DatePickerField';
import InputField from '../../../Components/InputField';
import SelectField from '../../../Components/SelectField';
import Submit from '../../../Components/Submit';
import WorkingDaysAndHours from '../../../Components/WorkingDaysAndHours/WorkingDaysAndHours';
import { COMPANYUSERROLES } from '../../../General';
import { countriesOptionsFactory, employeeRoleOptionsFactory, officesOptionsFactory, subsidiariesOptionsFactory, timezonesOptionsFactory, unitsOptionsFactory, usersOptionsFactory } from '../../../SelectOptionsFactories';
import { formatForServer } from '../../../Utils';
import MoreActions from './MoreActions';

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        const employee = this.props.employee;
        const moment = this.getMomentWithLocale();
        this.state = {
            email: {
                value: employee.email,
                touched: false,
                rules: this.Rules().BasicEmailRules()
            },
            firstName: {
                value: employee.firstName,
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            lastName: {
                value: employee.lastName,
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            position: {
                value: employee.position,
                touched: false,
                rules: this.Rules().BasicNameInputRules()
            },
            employmentStartDate: {
                value: moment(employee.employmentStartDate),
                touched: false,
                timeFormat: false
            },
            employmentEndDate: {
                value: employee.employmentEndDate ? moment(employee.employmentEndDate) : null,
                touched: false,
                timeFormat: false
            },
            employmentContract: {
                touched: false,
                rules: this.Rules().BasicInputRules()
            },
            personalIdentificationNumber: {
                touched: false,
                rules: this.Rules().BasicInputRules()
            },
            country: {
                value: [employee.country],
                rules: this.Rules().BasicSelectRules(),
                touched: false,
            },
            timezone: {
                value: [employee.timezone],
                rules: this.Rules().BasicSelectRules(),
                touched: false,
            },
            unit: {
                value: employee.companyUnit && employee.companyUnit.id ? [employee.companyUnit.id] : [],
                rules: this.Rules().BasicSelectRules(),
                touched: false,
            },
            office: {
                value: [employee.calendar.id],
                rules: this.Rules().BasicSelectRules(),
                touched: false,
            },
            subsidiary: {
                value: [employee.subsidiary.id],
                rules: this.Rules().BasicSelectRules(),
                touched: false,
            },
            role: {
                value: [employee.role],
                rules: this.Rules().BasicSelectRules(),
                touched: false,
            },
            approver: {
                value: [employee.approverCompanyUser.id],
                rules: this.Rules().BasicSelectRules(),
                touched: false,
            },
            secondApproverEnabled: false,
            //secondApproverEnabled: employee.secondApproverCompanyUser?.id ? true : false,
            secondApprover: {
                value: employee.secondApproverCompanyUser?.id ? [employee.secondApproverCompanyUser.id] : undefined,
                rules: this.Rules().BasicSelectRules(),
                touched: false,
            },
            thirdApproverEnabled: employee.thirdApproverCompanyUser?.id ? true : false,
            thirdApprover: {
                value: employee.thirdApproverCompanyUser?.id ? [employee.thirdApproverCompanyUser.id] : undefined,
                rules: this.Rules().BasicSelectRules(),
                touched: false,
            },
            weekDaysAndHours: {
                value: employee.companyUserSettings.weekDaysAndHours
            },
            phoneNumber: {
                value: employee.phoneNumber,
                touched: false,
            },
            save: {
                pleaseWait: false
            }
        };
    }

    render() {
        const session: any = this.getSession().getState();
        const isAdmin = () => {
            return session.companyUser.role === COMPANYUSERROLES.COMPANY_ADMIN;
        };
        if (!isAdmin()) {
            const location = window.location + '';
            this.goToLogin({ target: location });
            return null;
        }
        const t = this.translate();
        const caches: any = this.getCaches().getState();
        const countriesOptions = countriesOptionsFactory(caches.countries.arr[this.language()]);
        const timezonesOptions = timezonesOptionsFactory(caches.timezones.arr);
        const unitsOptions = unitsOptionsFactory(caches.units.arr);
        const officesOptions = officesOptionsFactory(caches.offices.arr);
        const subsidiariesOptions = subsidiariesOptionsFactory(caches.subsidiaries.arr);
        const usersOptions = usersOptionsFactory(caches.users.arr);
        const roleOptions = employeeRoleOptionsFactory(t);
        const dateFormat = this.getDateFormat();
        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <form onSubmit={(event: any) => { this.submit(event); }}>
                    <fieldset>
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="float-right">
                                    {
                                        (this.props.employee.id !== (this.getSession().getState() as any).companyUser.id) ?
                                            (<MoreActions
                                                employee={this.props.employee}
                                                onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }}
                                            />)
                                            :
                                            null
                                    }
                                    <span className="ml-2" />
                                    <Close onClick={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }} />
                                </div>
                            </div>
                            <div className="col-12 mb-3">
                                <InputField
                                    defaultValue={this.state.email.value}
                                    touched={this.state.email.touched}
                                    rules={this.state.email.rules}
                                    type="text"
                                    readOnly={!((this.getSession().getState() as any).company.subscriptionType === 'SUBSCRIPTION')}
                                    placeholder={t('email')}
                                    onChange={(value: any) => {
                                        this.setState({ email: Object.assign({}, this.state.email, { value: value }) });
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <InputField
                                    defaultValue={this.state.firstName.value}
                                    touched={this.state.firstName.touched}
                                    rules={this.state.firstName.rules}
                                    type="text"
                                    placeholder={t('first.name')}
                                    focus={this.isScreenSizeSmall() ? false : true}
                                    onChange={(value: any) => { this.setState({ firstName: Object.assign({}, this.state.firstName, { value }) }); }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <InputField
                                    defaultValue={this.state.lastName.value}
                                    touched={this.state.lastName.touched}
                                    rules={this.state.lastName.rules}
                                    type="text"
                                    placeholder={t('last.name')}
                                    onChange={(value: any) => { this.setState({ lastName: Object.assign({}, this.state.lastName, { value }) }); }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <InputField
                                    defaultValue={this.state.position.value}
                                    touched={this.state.position.touched}
                                    rules={this.state.position.rules}
                                    type="text"
                                    placeholder={t('position')}
                                    onChange={(value: any) => { this.setState({ position: Object.assign({}, this.state.position, { value }) }); }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.unit.value}
                                    touched={this.state.unit.touched}
                                    options={unitsOptions}
                                    rules={this.state.unit.rules}
                                    placeholder={t('team')}
                                    onChange={(value: any) => { this.setState({ unit: Object.assign({}, this.state.unit, { value }) }); }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.approver.value}
                                    touched={this.state.approver.touched}
                                    options={usersOptions}
                                    rules={this.state.approver.rules}
                                    placeholder={t('approver')}
                                    onChange={(value: any) => { this.setState({ approver: Object.assign({}, this.state.approver, { value }) }); }}
                                />
                                {!this.state.secondApproverEnabled ?
                                    <div className="float-right d-none"><Anchor
                                        onClick={(event: any) => { event.preventDefault(); this.setState({ secondApproverEnabled: true }); }}>{t('add')}</Anchor></div>
                                    : null
                                }
                            </div>
                            {this.state.secondApproverEnabled ?
                                <div className="col-12 mb-3">
                                    <SelectField
                                        defaultValue={this.state.secondApprover.value}
                                        touched={this.state.secondApprover.touched}
                                        options={usersOptions}
                                        rules={this.state.secondApprover.rules}
                                        placeholder={t('second.approver')}
                                        onChange={(value: any) => { this.setState({ secondApprover: Object.assign({}, this.state.secondApprover, { value }) }); }}
                                    />
                                    {this.state.secondApproverEnabled && !this.state.thirdApproverEnabled ?
                                        <div className="float-right ml-1"><Anchor
                                            onClick={(event: any) => { event.preventDefault(); this.setState({ secondApproverEnabled: false }); }}>{t('delete')}</Anchor></div>
                                        : null
                                    }
                                    {!this.state.thirdApproverEnabled ?
                                        <div className="float-right"><Anchor
                                            onClick={(event: any) => { event.preventDefault(); this.setState({ thirdApproverEnabled: true }); }}>{t('add')}</Anchor></div>
                                        : null
                                    }
                                </div>
                                : null
                            }
                            {this.state.thirdApproverEnabled ?
                                <div className="col-12 mb-3">
                                    <SelectField
                                        defaultValue={this.state.thirdApprover.value}
                                        touched={this.state.thirdApprover.touched}
                                        options={usersOptions}
                                        rules={this.state.thirdApprover.rules}
                                        placeholder={t('third.approver')}
                                        onChange={(value: any) => { this.setState({ thirdApprover: Object.assign({}, this.state.thirdApprover, { value }) }); }}
                                    />
                                    {this.state.thirdApproverEnabled ?
                                        <div className="float-right"><Anchor
                                            onClick={(event: any) => { event.preventDefault(); this.setState({ thirdApproverEnabled: false }); }}>{t('delete')}</Anchor></div>
                                        :
                                        null
                                    }
                                </div>
                                :
                                null
                            }
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.office.value}
                                    touched={this.state.office.touched}
                                    options={officesOptions}
                                    rules={this.state.office.rules}
                                    placeholder={t('office')}
                                    onChange={(value: any) => { this.setState({ office: Object.assign({}, this.state.office, { value }) }); }}
                                />
                            </div>
                            {session.company.subsidiariesEnabled ?
                                <div className="col-12 mb-3">
                                    <SelectField
                                        defaultValue={this.state.subsidiary.value}
                                        touched={this.state.subsidiary.touched}
                                        options={subsidiariesOptions}
                                        rules={this.state.subsidiary.rules}
                                        placeholder={t('subsidiary')}
                                        onChange={(value: any) => { this.setState({ subsidiary: Object.assign({}, this.state.subsidiary, { value }) }); }}
                                    />
                                </div> : null}
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.role.value}
                                    touched={this.state.role.touched}
                                    options={roleOptions}
                                    rules={this.state.role.rules}
                                    placeholder={t('role')}
                                    onChange={(value: any) => { this.setState({ role: Object.assign({}, this.state.role, { value }) }); }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <DatePickerField
                                    value={this.state.employmentStartDate.value}
                                    placeholder={t('employment.date')}
                                    timeFormat={this.state.employmentStartDate.timeFormat}
                                    dateFormat={dateFormat}
                                    onChange={(value: any) => { this.setState({ employmentStartDate: Object.assign({}, this.state.employmentStartDate, { value, isValid: true }) }); }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <DatePickerField
                                    value={this.state.employmentEndDate.value}
                                    placeholder={t('employment.end.date')}
                                    timeFormat={this.state.employmentEndDate.timeFormat}
                                    dateFormat={dateFormat}
                                    onChange={(value: any) => { this.setState({ employmentEndDate: Object.assign({}, this.state.employmentEndDate, { value, isValid: true }) }); }}
                                />
                            </div>
                            <div className="col-12 mb-3 d-none">
                                <InputField
                                    defaultValue={this.state.employmentContract.value}
                                    touched={this.state.employmentContract.touched}
                                    rules={this.state.employmentContract.rules}
                                    type="text"
                                    placeholder={t('employment.contract')}
                                    onChange={(value: any) => {
                                        this.setState({ employmentContract: Object.assign({}, this.state.employmentContract, { value }) });
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3 d-none">
                                <InputField
                                    defaultValue={this.state.personalIdentificationNumber.value}
                                    touched={this.state.personalIdentificationNumber.touched}
                                    rules={this.state.personalIdentificationNumber.rules}
                                    type="text"
                                    placeholder={t('personal.identification.number')}
                                    onChange={(value: any) => {
                                        this.setState({ personalIdentificationNumber: Object.assign({}, this.state.personalIdentificationNumber, { value }) });
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.country.value}
                                    touched={this.state.country.touched}
                                    options={countriesOptions}
                                    multiple={false}
                                    rules={this.state.country.rules}
                                    placeholder={t('country')}
                                    onChange={(value: any) => {
                                        this.setState({ country: Object.assign({}, this.state.country, { value }) });
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <SelectField
                                    defaultValue={this.state.timezone.value}
                                    touched={this.state.timezone.touched}
                                    options={timezonesOptions}
                                    rules={this.state.timezone.rules}
                                    placeholder={t('timezone')}
                                    onChange={(value: any) => {
                                        this.setState({ timezone: Object.assign({}, this.state.timezone, { value }) });
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                <InputField
                                    defaultValue={this.state.phoneNumber.value}
                                    touched={this.state.phoneNumber.touched}
                                    type="text"
                                    placeholder={t('phone.number')}
                                    onChange={(value: any) => {
                                        this.setState({ phoneNumber: Object.assign({}, this.state.phoneNumber, { value }) });
                                    }}
                                />
                            </div>
                            <div className="col-12 mb-3">
                                {t('working.days.and.hours')}
                            </div>
                            <WorkingDaysAndHours
                                weekDaysAndHours={this.state.weekDaysAndHours.value}
                                onChange={(value: any) => {
                                    this.setState({ weekDaysAndHours: Object.assign({}, this.state.weekDaysAndHours, { value: value.weekDaysAndHours }) });
                                }}
                            />
                            <div className="col-12 mt-3 mb-3">
                                <Submit
                                    faIcon="far fa-hdd"
                                    disabled={!this.isFormValid()}
                                    pleaseWait={this.state.save.pleaseWait}
                                >
                                    {t('save')}
                                </Submit>
                            </div>
                        </div>
                    </fieldset>
                </form>
            </div>
        </div>
    }

    isFormValid() {
        return this.Validator().validate(this.state.email)
            && this.Validator().validate(this.state.firstName)
            && this.Validator().validate(this.state.lastName)
            && (this.state.secondApproverEnabled ? this.Validator().validate(this.state.secondApprover) : true)
            && (this.state.thirdApproverEnabled ? this.Validator().validate(this.state.thirdApprover) : true);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            {
                email: Object.assign({}, this.state.email, { touched: true }),
                firstName: Object.assign({}, this.state.firstName, { touched: true }),
                lastName: Object.assign({}, this.state.lastName, { touched: true })
            },
            () => { if (this.isFormValid()) { this.save(); } }
        );
    }

    save() {
        const t = this.translate();
        const companyUser = Object.assign({}, this.props.employee);
        companyUser.email = this.state.email.value;
        companyUser.firstName = this.state.firstName.value;
        companyUser.lastName = this.state.lastName.value;
        companyUser.position = this.state.position.value;
        try {
            companyUser.employmentStartDate = formatForServer(this.state.employmentStartDate.value);
        } catch (e) {
            companyUser.employmentStartDate = null;
        }
        try {
            companyUser.employmentEndDate = formatForServer(this.state.employmentEndDate.value);
        } catch (e) {
            companyUser.employmentEndDate = null;
        }
        //        companyUser.employmentStartDate = formatForServer(this.state.employmentStartDate.value);
        if (this.state.unit.value && this.state.unit.value.length > 0) {
            companyUser.companyUnit = {};
            companyUser.companyUnit.id = this.state.unit.value[0];
        }
        companyUser.timezone = this.state.timezone.value[0];
        companyUser.country = this.state.country.value[0];
        companyUser.role = this.state.role.value[0];
        companyUser.approverCompanyUser = {};
        companyUser.approverCompanyUser.id = this.state.approver.value[0];
        if (this.state.secondApproverEnabled) {
            companyUser.secondApproverCompanyUser = {};
            companyUser.secondApproverCompanyUser.id = this.state.secondApprover.value[0];
        } else {
            companyUser.secondApproverCompanyUser = null;
        }
        if (this.state.thirdApproverEnabled) {
            companyUser.thirdApproverCompanyUser = {};
            companyUser.thirdApproverCompanyUser.id = this.state.thirdApprover.value[0];
        } else {
            companyUser.thirdApproverCompanyUser = null;
        }
        companyUser.calendar = {};
        companyUser.calendar.id = this.state.office.value[0];
        delete companyUser.office;
        companyUser.subsidiary = {};
        companyUser.subsidiary.id = this.state.subsidiary.value[0];
        companyUser.phoneNumber = this.state.phoneNumber.value;
        companyUser.companyUserSettings.weekDaysAndHours = this.state.weekDaysAndHours.value;
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: this.Endpoints().getEmployeeUpdate(this.props.employee.id),
            dataType: 'json',
            cache: false,
            data: JSON.stringify(companyUser),
            beforeSend: () => {
                this.setState({ save: { pleaseWait: true } });
            },
            success: (data, textStatus, jqXHR) => {
                this.successToastr(t('the.employee.profile.was.updated'));
                doFetchUnitsFromServer(this.getCaches());
                doFetchUsersFromServer(this.getCaches(), this.getSession().getState());
                if (this.props.onRefresh) {
                    this.props.onRefresh();
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                if (+jqXHR.status === 400) {
                    var reason = jqXHR.getResponseHeader('REASON');
                    if (reason && reason === 'DUPLICATE') {
                        this.successToastr(t('employee.duplicate'));
                        return;
                    }
                }
                this.generalErrorToastr();
            }
        });
    }
}