import * as React from 'react';
import Component from '../../../Component';
import Cog from '../../../Components/Cog';
import Date from '../../../Components/Date';

export default class Display extends Component {

    render() {
        const t = this.translate();
        const leaveType = this.props.leaveType;
        const moment = this.getMomentWithLocale();
        const session = this.getSession().getState() as any;
        const companyId = session.company.id;
        return (
            <div className={this.getCardClassNameSize()}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <Cog
                                onClick={() => {
                                    if (this.props.onEdit) {
                                        this.props.onEdit();
                                    }
                                }}
                            />
                        </div>
                        <div className="col-12">
                            <table className="table table-sm w-100 mb-0">
                                <tbody>
                                    <tr>
                                        <td>
                                            {t('allowance')}
                                            <span className="float-right">
                                                {leaveType.allocation + ' ' + t('days.per.year')}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('hours.request')}
                                            <span className="float-right">
                                                {this.getBooleanString(leaveType.hoursAllowed)}
                                            </span>
                                        </td>
                                    </tr>
                                    {leaveType.hoursAllowed && leaveType.hoursPerDay ?
                                        <tr>
                                            <td>
                                                {t('working.hours.in.one.business.day')}
                                                <span className="float-right">
                                                    {leaveType.hoursPerDay + ' ' + t('hours')}
                                                </span>
                                            </td>
                                        </tr>
                                        : null}
                                    <tr>
                                        <td>
                                            {t('half.day.request')}
                                            <span className="float-right">
                                                {this.getBooleanString(leaveType.halfADayAllowed)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('register.in.the.past')}
                                            <span className="float-right">
                                                {this.getBooleanString(leaveType.registerInThePast)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('minimum.leave.notice')}
                                            <span className="float-right">
                                                {leaveType.earlier + ' ' + t('days').toLowerCase()}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('enforce.allowance')}
                                            <span className="float-right">
                                                {this.getBooleanString(leaveType.allocationEnforced)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('comment.required')}
                                            <span className="float-right">
                                                {this.getBooleanString(leaveType.commentRequired)}
                                            </span>
                                        </td>
                                    </tr>
                                    {
                                        ((this.getSession().getState() as any).company.country === 'RO') ?
                                            <tr>
                                                <td>
                                                    {t('paper.copy')}
                                                    <span className="float-right">
                                                        {this.getBooleanString(leaveType.paperCopy)}
                                                    </span>
                                                </td>
                                            </tr>
                                            : null
                                    }
                                    <tr>
                                        <td>
                                            {t('auto.approve')}
                                            <span className="float-right">
                                                {this.getBooleanString(leaveType.autoApprove)}
                                            </span>
                                        </td>
                                    </tr>
                                    {leaveType.autoApprove ?
                                        <tr>
                                            <td>
                                                {t('send.notification.to.the.manager')}
                                                <span className="float-right">
                                                    {this.getBooleanString(leaveType.sendNotificationToManager)}
                                                </span>
                                            </td>
                                        </tr> : <></>}
                                    {leaveType.usableInterval ?
                                        <>
                                            <tr>
                                                <td>
                                                    {t('usable.in.interval')}
                                                    <span className="float-right">
                                                        {this.getBooleanString(leaveType.usableInterval)}
                                                    </span>
                                                </td>
                                            </tr>
                                            {leaveType.usableFrom ?
                                                <tr>
                                                    <td>
                                                        {t('usable.from')}
                                                        <span className="float-right">
                                                            <Date date={moment(leaveType.usableFrom)} />
                                                        </span>
                                                    </td>
                                                </tr>
                                                : null}
                                            {leaveType.usableTo ?
                                                <tr>
                                                    <td>
                                                        {t('usable.to')}
                                                        <span className="float-right">
                                                            <Date date={moment(leaveType.usableTo)} />
                                                        </span>
                                                    </td>
                                                </tr>
                                                : null}
                                        </>
                                        : null}

                                    <tr>
                                        <td>
                                            {t('ask.for.replacement')}
                                            <span className="float-right">
                                                {this.getBooleanString(leaveType.replacement)}
                                            </span>
                                        </td>
                                    </tr>
                                    {leaveType.replacement ?
                                        <tr>
                                            <td>
                                                {t('replacement.required') + ' '}
                                                <span className="float-right">
                                                    {this.getBooleanString(leaveType.replacementRequired)}
                                                </span>
                                            </td>
                                        </tr>
                                        : null}
                                    <tr>
                                        <td>
                                            {t('adjust.allowance.on.employment')}
                                            <span className="float-right">
                                                {this.getBooleanString(leaveType.adjustAllowanceOnEmployment)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('is.working')}
                                            <span className="float-right">
                                                {this.getBooleanString(leaveType.working)}
                                            </span>
                                        </td>
                                    </tr>
                                    <>
                                        {leaveType.hoursAllowed && leaveType.working ?
                                            <tr>
                                                <td>
                                                    {t('overtime.request')}
                                                    <span className="float-right">
                                                        {this.getBooleanString(leaveType.overtime)}
                                                    </span>
                                                </td>
                                            </tr> : null}
                                    </>
                                    <tr>
                                        <td>
                                            {t('show.earned.allowance')}
                                            <span className="float-right">
                                                {this.getBooleanString(leaveType.showEarnedAllowance)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {t('ignore.blackout.days')}
                                            <span className="float-right">
                                                {this.getBooleanString(leaveType.ignoreBlackoutEvents)}
                                            </span>
                                        </td>
                                    </tr>
                                    {leaveType.effectiveAfter ?
                                        <tr>
                                            <td>
                                                {t('effective.after')}
                                                <span className="float-right">
                                                    {leaveType.effectiveAfter + ' ' + t('days.of.service')}
                                                </span>
                                            </td>
                                        </tr>
                                        : null}
                                    {(leaveType.description) ?
                                        <tr>
                                            <td>
                                                {t('description')}
                                                <pre className="font-weight-light m-0 text-wrap">
                                                    {leaveType.description}
                                                </pre>
                                            </td>
                                        </tr>
                                        : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    getBooleanString(value: any) {
        const t = this.translate();
        return value ? t('yes') : t('no');
    }
}