import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import Anchor from '../../../Components/Anchor';
import LeaveInterval from '../../../Components/Leave/LeaveInterval';
import LeaveName from '../../../Components/Leave/LeaveName';
import LeaveReplacement from '../../../Components/Leave/LeaveReplacement';
import Submit from '../../../Components/Submit';
import TextareaField from '../../../Components/TextareaField';
import { fullNameFactory } from '../../../Factories';
import { STATUS } from '../../../General';
import OtherLeaves from './../OtherLeaves/OtherLeaves';

enum DECISIONTYPE {
    APPROVE = 'APPROVE',
    REJECT = 'REJECT'
}

export default class Form extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            note: {
                touched: false,
                rules: this.Rules().MaxLengthTextareaRules()
            },
            decisionType: undefined,
            showOtherLeaves: true,
            approve: {
                pleaseWait: false
            },
            reject: {
                pleaseWait: false
            },
            leave: {
                value: undefined,
                status: STATUS.LOADING
            },
        };
    }

    componentDidMount() {
        this.getLeave();
    }

    getLeave() {
        this.setState(
            { leave: { value: this.state.leave.value, status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getLeave(this.props.leave.id),
                    dataType: 'json',
                    cache: false,
                    success: (data, textStatus, jqXHR) => {
                        const session = this.getSession().getState() as any;
                        data.companyUser.fullName = fullNameFactory(data.companyUser, session.company.nameFormat);
                        this.setState({
                            leave: {
                                value: data,
                                status: STATUS.READY
                            }
                        });
                    },
                    error: (jqXHR, textStatus, errorThrown) => {
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                        this.setState({
                            leave: {
                                value: undefined,
                                status: STATUS.ERROR
                            },
                        });
                    },
                });
            }
        );
    }

    render() {
        let ready = this.state.leave.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        const t = this.translate();
        const leave = this.state.leave.value;
        return <form onSubmit={(event: any) => { event.preventDefault(); return false; }}>
            <div className="row">
                <div className="col-12 mb-3">
                    <div><strong>{leave.companyUser.fullName}</strong>
                        <div className="float-right"><LeaveName leave={leave} /></div>
                    </div>
                    <div><LeaveInterval leaveRequestInThePast={true} leave={leave} /></div>
                    {leave?.replacement?.length > 2 ?
                        <div><LeaveReplacement leave={leave} /></div> : null}
                    {leave.comment ?
                        <div>
                            <Anchor faIcon="far fa-comment" title={t('request.comment')} />
                            {' '}{leave.comment}
                        </div>
                        : <></>}
                </div>
            </div>
            {(this.state.showOtherLeaves) ?
                <div className="row">
                    <div className="col-12 mb-3">
                        <OtherLeaves leave={leave} onEmpty={() => { this.setState({ showOtherLeaves: !this.state.showOtherLeaves }); }} />
                    </div>
                </div>
                : <></>}
            <div className="row">
                <div className="col-12 mb-3">
                    <TextareaField
                        touched={this.state.note.touched}
                        rules={this.state.note.rules}
                        type="text"
                        rows={2}
                        placeholder={t('note')}
                        onChange={(value: any) => { this.setState({ note: Object.assign({}, this.state.note, { value: value }) }); }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6 mb-3">
                    <Submit
                        color="success"
                        faIcon="far fa-thumbs-up fa-fw"
                        disabled={!this.isFormValid()}
                        pleaseWait={this.state.approve.pleaseWait}
                        onClick={(event: any) => { this.setState({ decisionType: DECISIONTYPE.APPROVE }, () => { this.submit(event); }); }}
                    >
                        {t('approve')}
                    </Submit>
                </div>
                <div className="col-12 col-lg-6 mb-3">
                    <Submit
                        color="danger"
                        faIcon="far fa-thumbs-down fa-fw"
                        disabled={!this.isFormValid()}
                        pleaseWait={this.state.reject.pleaseWait}
                        onClick={(event: any) => { this.setState({ decisionType: DECISIONTYPE.REJECT }, () => { this.submit(event); }); }}
                    >
                        {t('decline')}
                    </Submit>
                </div>
            </div>
        </form>
    }

    isFormValid() {
        return this.Validator().validate(this.state.note);
    }

    submit(event: any) {
        event.preventDefault();
        this.setState(
            { note: Object.assign({}, this.state.note, { touched: true }) },
            () => { if (this.isFormValid()) { this.leaveDecision(); } }
        );
    }

    leaveDecision() {
        const t = this.translate();
        $.ajax({
            type: 'POST',
            contentType: 'application/json',
            url: ((this.state.decisionType === DECISIONTYPE.APPROVE) ?
                this.Endpoints().getLeaveApprove(this.props.leave.id) :
                this.Endpoints().getLeaveReject(this.props.leave.id)),
            dataType: 'json',
            cache: false,
            data: JSON.stringify({
                comment: this.state.note.value,
            }),
            beforeSend: () => {
                switch (this.state.decisionType) {
                    case DECISIONTYPE.APPROVE:
                        this.setState({ approve: { pleaseWait: true } });
                        break;
                    case DECISIONTYPE.REJECT:
                        this.setState({ reject: { pleaseWait: true } });
                        break;
                    default:
                }
            },
            success: (data: any, textStatus: any, jqXHR: any) => {
                ((this.state.decisionType === DECISIONTYPE.APPROVE) ?
                    this.successToastr(t('the.leave.request.was.approved')) :
                    this.successToastr(t('the.leave.request.was.declined')));
                if (this.props.onClose) {
                    this.props.onClose();
                }
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
            }
        });
    }
}