import * as React from 'react';
import Component from '../../../Component';

export default class Article extends Component {

    render() {
        let article = this.props.article;
/*        const replace = `target="_blank" onClick="function(event){
            event.preventDefault();
            window.location.hash = '` + article.url + `';
            return false;
        })();return false;"  href=`;
        const html = article.html.replace(/ href=/g, replace); */
        const html = article.html.replace(/ href=/g, ' target="_blank" href=');
        return <div className="w-100">
                <h3>{article.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
    }
}