import * as React from 'react';
import { Tooltip } from 'reactstrap';
import Component from '../../../Component';
import Anchor from '../../../Components/Anchor';

export default class DayEvent extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            tooltipOpen: false
        };
    }

    render() {
        const dayEvent = this.props.dayEvent;
        const t = this.translate();
        if (dayEvent) {
            if (dayEvent.status !== 0) {
                let requiresApproval: any;
                if (dayEvent.status === 1000) {
                    requiresApproval = (
                        <div className="text-warning">
                            <i className="fa fa-exclamation-circle mr-1" aria-hidden="true" />
                            {t('requires.approval')}
                        </div>
                    );
                }
                let faIcon = '';
                switch (dayEvent.dayType) {
                    case 1:
                        faIcon = 'fa-adjust';
                        break;
                    case 2:
                        faIcon = 'fa-adjust fa-rotate-180';
                        break;
                    case 3:
                        faIcon = 'fa-clock';
                        break;
                    case 0:
                        faIcon = 'fa-circle';
                        break;
                    default:
                        break;
                }
                return <div className="d-block h-100 w-100">
                    {dayEvent.anonymize ?
                        <Anchor className="d-block" id={'Day' + this.cid}>
                            <span style={{ color: '#DDDDDD', opacity: 1 }}>
                                <i className={'fa ' + faIcon} aria-hidden="true" />
                            </span>
                        </Anchor>
                        : <>
                            <Anchor className="d-block" id={'Day' + this.cid}>
                                <span style={{ color: dayEvent.color, opacity: (requiresApproval ? 0.3 : 1) }}>
                                    <i className={'fa ' + faIcon} aria-hidden="true" />
                                </span>
                            </Anchor>
                            <Tooltip
                                delay={{ show: 0, hide: 250 }}
                                placement="top"
                                isOpen={this.state.tooltipOpen}
                                target={'Day' + this.cid}
                                toggle={() => {
                                    this.setState({ tooltipOpen: !this.state.tooltipOpen });
                                }}
                            >
                                <div>{dayEvent.toolTip}</div>
                            </Tooltip>
                        </>
                    }
                    {dayEvent.isBLO ?
                        <div className="border border-danger"></div> : null}
                </div>
            }
        }
        return null;
    }

    replacement(dayEvent: any) {
        if (dayEvent.replacement) {
            const t = this.translate();
            return (<div>{t('replacement') + ' : ' + dayEvent.replacement}</div>);
        }
        return null;
    }
}