import * as React from 'react';
import Component from '../../Component';
import A from '../../Components/A';
import LeaveTypeName from '../../Components/LeaveType/LeaveTypeName';

export default class Item extends Component {
    render() {
        const leaveType = this.props.leaveType;
        const t = this.translate();
        const trs: any = [];
        trs.push(
            <tr key="LeaveType-Item-Allowance"><td><div>
                {t('allowance')}
                <span className="text-success ml-1 font-weight-bold">
                    {leaveType.allocation} {t('days.per.year')}
                </span>
            </div></td></tr>
        );
        if (leaveType.allocationEnforced) {
            trs.push(
                <tr key="LeaveType-Item-Enforce-Allowance"><td><div>
                    {t('enforce.allowance')}
                </div></td></tr>
            );
        }

        if (leaveType.halfADayAllowed) {
            trs.push(
                <tr key="LeaveType-Item-Half-Day-Request"><td><div>
                    {t('half.day.request')}
                </div></td></tr>
            );
        }
        if (leaveType.registerInThePast) {
            trs.push(
                <tr key="LeaveType-Item-Register-In-The-Past"><td><div>
                    {t('register.in.the.past')}
                </div></td></tr>
            );
        }
        if (!leaveType.active) {
            trs.push(
                <tr key="LeaveType-disabled"><td><div className="text-muted float-right">
                    {t('disabled')}
                </div></td></tr>
            );
        }
        return (
            <div className="card w-100 h-100">
                <div className="card-body">
                    <table className="table table-sm w-100 m-0 p-0">
                        <thead>
                            <tr>
                                <th className="border-top-0">
                                    <A href={this.Urls().getLeaveType(leaveType.id)} title={leaveType.name}>
                                        <LeaveTypeName leaveType={leaveType} />
                                    </A>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {trs}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}