import * as React from 'react';
import MenuBase from './MenuBase';
import { Navbar, Nav } from 'reactstrap';
import Anchor from '../../Components/Anchor';
import NavLinkExt from '../../Components/NavLinkExt';
import { none } from '../../Actions/Modal';
import { MODALS } from '../../Constants/ActionTypes';

export default class Menu extends MenuBase {
    render() {
        if ((this.getSession().getState() as any).logged === false) {
            return null;
        }
        const menuItems = this.getMenuItems();
        const currentUrl = window.location.href;
        const items = menuItems.map((menuItem: any) => {
            if (menuItem.spacer) {
                return (
                    <li
                        key={'Menu' + menuItem.faIcon}
                        className="nav-item bg-light disabled"
                    >
                        <div className="mt-2" />
                    </li>
                );
            }
            return (
                <li
                    key={'Menu' + menuItem.faIcon}
                    className={'nav-item bg-light' + (menuItem.match(currentUrl) ? ' active' : '')}
                    onClick={(event: any) => { this.onClick(); }}
                >
                    <NavLinkExt
                        active={menuItem.match(currentUrl)}
                        href={menuItem.href}
                        faIcon={menuItem.faIcon + ' ml-2 mr-2 fa-fw'}
                        title={menuItem.title}
                        className="text-dark"
                    >
                        {menuItem.title}
                    </NavLinkExt>
                </li>
            );
        });
        return (
            <Navbar
                className="sticky-top bg-light border-bottom rounded-0"
                style={{ borderColor: 'rgba(0, 0, 0, 0.125)!important' }}
            >
                <Nav navbar={true}>
                    <li className="nav-item bg-light">
                        <Anchor
                            faIcon={(this.state.isOpen ? 'fas fa-bars' : 'fas  fa-bars') + ' ml-2 mr-2 bg-light'}
                            className="nav-brand text-dark"
                            onClick={(event: any) => { this.toggle(); }}
                        >
                            LeaveBoard
                        </Anchor>
                    </li>
                    {this.state.isOpen ? items : undefined}
                </Nav>
            </Navbar>
        );
    }

    onClick() {
        if ((this.getModal().getState() as any).type !== MODALS.NONE) {
            none(this.getModal(), {});
        }
        this.toggle();
    }
}