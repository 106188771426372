import * as React from 'react';
import Component from '../../Component';
import Error from '../../Components/Error';
import { STATUS } from '../../General';

export default class List extends Component {
    render() {
        return <div className="col-12 mb-3">
            <div className="card w-100">
                <div className="card-body">
                    {this.renderNow()}
                </div>
            </div>
        </div>
    }

    renderNow() {
        const analytics = this.props.analytics;
        switch (analytics.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY:
                return this.analytics();
            default:
                return (<Error />);
        }
    }

    analytics() {
        const analytics = this.props.analytics;
        const moment = this.getMomentWithLocale();
        const item = analytics.item
        const t = this.translate();
        const session: any = this.getSession().getState();
        return <div className="table-responsive">
            <table className="table table-sm table-hover w-100 mb-0">
                <thead>
                    <tr><th scope="col" colSpan={2} className="border-top-0">{t('analytics')}</th></tr>
                </thead>
                <tbody>
                    <tr><td className="border-0">{t('head.count')}</td><td colSpan={2} className="border-0"><div className="float-right">{item.uzersCount}</div></td></tr>
                    <tr><td>{t('average.age')}</td><td colSpan={2}><div className="float-right">{item.uzersAverageAge ? moment.duration(item.uzersAverageAge).humanize() : ''}</div></td></tr>
                    <tr><td>{t('average.length.in.service')}</td><td colSpan={2}><div className="float-right">{item.uzersAverageServiceAge ? moment.duration(item.uzersAverageServiceAge).humanize() : ''}</div></td></tr>
                    {item.teamsCount ? <tr><td>{t('teams')}</td><td colSpan={2}><div className="float-right">{item.teamsCount}</div></td></tr> : null}
                    {item.officesCount ? <tr><td>{t('offices')}</td><td colSpan={2}><div className="float-right">{item.officesCount}</div></td></tr> : null}
                    {session.company.subsidiariesEnabled && item.subsidiariesCount ? <tr><td>{t('subsidiaries')}</td><td colSpan={2}><div className="float-right">{item.subsidiariesCount}</div></td></tr> : null}
                </tbody>
            </table>
        </div>
    }
}