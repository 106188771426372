import * as React from 'react';
import Component from '../../../Component';

export default class THead extends Component {

    render() {
        const ths = this.props.days.arr.map((day: any) => {
            const style: any = {};
            if (day.endOfMonth) {
                style.borderRightColor = '#dee2e6';
                style.borderRightWidth = '3px';
            }
            return <th
                key={'CalendarTHead-' + day.ISO8601}
                className={'text-center border-bottom-0' + (day.today ? '' : ' font-weight-normal')}
                style={style}
            >
                {day.dayOfWeek + ' '}
                {day.text}
            </th>
        });
        return <thead>
            <tr>
                <th className="border-0 shadow"
                    style={{ borderRight: '1px solid #DEE2E6', maxWidth: '150px', top: 0, left: 0, position: 'sticky', zIndex: 1, background: '#FFFFFF' }}>
                    {this.props.days.month}
                </th>
                {ths}
            </tr>
        </thead>
    }
}