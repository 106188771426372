import {
    createStore,
    //    applyMiddleware 
} from 'redux';
// import { createLogger } from 'redux-logger';
import {
    SESSION_ACTIONS
}
    from '../Constants/ActionTypes';
import { doLoadSession } from '../Actions/Session';
import { STATUS } from '../General';

const session = createStore(
    (state = { logged: false, status: STATUS.LOADING }, action: any) => {
        switch (action.type) {
            case SESSION_ACTIONS.LOGIN:
                return {
                    user: action.user,
                    companyUser: action.companyUser,
                    company: action.company,
                    permissions: action.permissions,
                    logged: action.logged,
                    status: action.status
                };
            case SESSION_ACTIONS.REFRESH:
                return {
                    user: action.user,
                    companyUser: action.companyUser,
                    company: action.company,
                    permissions: action.permissions,
                    logged: action.logged,
                    status: action.status
                };
            case SESSION_ACTIONS.NOT_LOGGED:
                return {
                    logged: action.logged,
                    status: action.status
                };
            case SESSION_ACTIONS.LOADING:
            case SESSION_ACTIONS.ERROR:
                return Object.assign({}, state, { status: action.status });
            case SESSION_ACTIONS.LOGOUT:
                return {
                    logged: false,
                    status: action.status
                };
            default:
                return state;
        }
    },
    //    applyMiddleware(createLogger({}))
);

export default session;

doLoadSession(session);