import * as React from 'react';
import Display from './Display';
import { none } from '../../../Actions/Modal';
import ResponsiveModal from '../ResponsiveModal';

export default class TrialStarted extends ResponsiveModal {
    getContent() {
        return (
            <Display
                trialEndsOn={this.props.options.trialEndsOn}
                onClose={() => {
                    this.toggle();
                    none(this.getModal(), {});
                    if (this.props.callback) { this.props.callback(); }
                }}
            />
        );
    }

    getTitle(): any {
        const t = this.translate();
        return (
            <h5 className="m-0 p-0 text-primary">{t('your.trial.period.has.started')}</h5>
        );
    }
}