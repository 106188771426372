import * as React from 'react';
import Component from '../Component';
import Anchor from './/Anchor';

export default class FiltersSwitch extends Component {
    render() {
        const t = this.translate();
        return (
            <span>
                <Anchor
                    className={'d-inline-block p-1' + (this.props.expanded ? ' bg-light rounded' : '')}
                    onClick={() => { if (this.props.onClick) { this.props.onClick(); } }}
                    rightFaIcon="fas fa-sliders-h fa-fw"
                >
                    {this.isScreenSizeSmall() || this.isScreenSizeMd() ? null : t('filter')}
                </Anchor>
            </span>
        );
    }
}