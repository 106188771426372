import {
    createStore,
    //    applyMiddleware 
} from 'redux';
// import { createLogger } from 'redux-logger';
import { goTo } from '../Actions/Router';
import { NAVIGATE } from '../Constants/ActionTypes';
import Blank from '../Containers/Blank';

const router = createStore(
    (state = { url: window.location.href, component: Blank }, action: any) => {
        switch (action.type) {
            case NAVIGATE:
                return { url: action.url, component: action.component, logged: action.logged };
            default:
                return state;
        }
    },
    // applyMiddleware(createLogger({}))
);

export default router;

window.onpopstate = () => {
    goTo(router, window.location.href);    
};