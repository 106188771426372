import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import Line from '../../../Components/Line';
import { fullNameFactory } from '../../../Factories';
import { ORDER, REPORTSTABS, STATUS, USERSTATUS } from '../../../General';
import Tabs from '../Tabs';
import Download from './Download';
import Filters from './Filters';
import Table from './Table';

export enum ANNUALROWTYPE {
    EMPLOYEE = 'EMPLOYEE',
    YEAR = 'YEAR'
}

export default class Manager extends Component {
    search: any;
    computeRows: any;
    getLeaveTypes: any;
    constructor(props?: any) {
        super(props);
        this.state = {
            filters: {
                orderBy: { field: 'NAME', order: ORDER.ASC },
                users: {
                    value: [],
                },
                units: {
                    value: [],
                },
                offices: {
                    value: []
                },
                leaveTypes: {
                    value: [],
                },
                approvers: {
                    value: [],
                },
                years: {
                    value: [this.currentBusinessYear()]
                }
            },
            rows: {
                arr: [],
                status: STATUS.LOADING
            }
        };
        this.search = Manager.search.bind(this);
        this.computeRows = Manager.computeRows.bind(this);
        this.getLeaveTypes = Manager.getLeaveTypes.bind(this);
    }

    componentDidMount() { this.search(); }

    public static search(this: any) {
        this.setState(
            { rows: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getReportsAnnual({
                        filters: this.state.filters
                    }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState({ rows: { arr: this.computeRows(data), status: STATUS.READY } });
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState({ rows: { arr: [], status: STATUS.ERROR } });
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    public static computeRows(this: any, data: any) {
        const caches: any = this.getCaches().getState();
        const usersMap = caches.users.map;
        const rows: any = [];
        let currentCompanyUserId = 0;
        let map: any = {};
        let colSpan = 2;
        const isCurrentYear = this.state.filters.years.value[0] === this.currentBusinessYear();
        const session = this.getSession().getState() as any;
        this.getLeaveTypes().forEach((leaveType: any) => {
            colSpan = colSpan + 3 + (leaveType.showEarnedAllowance && isCurrentYear ? 1 : 0);
        });
        data.forEach((item: any) => {
            if (currentCompanyUserId !== item[0]) {
                currentCompanyUserId = item[0];
                const employee: any = {
                    id: item[0],
                    firstName: item[1],
                    lastName: item[2],
                    colSpan: colSpan
                };
                employee.fullName = fullNameFactory(employee, session.company.nameFormat);
                rows.push({
                    type: ANNUALROWTYPE.EMPLOYEE,
                    employee
                });
            }
            if (!caches.leaveTypes.active.map[item[3]]) {
                return;
            }
            const leaveType = {
                id: item[3],
                allocation: item[5],
                amount: (item[6] ? item[6] : 0),
                yearlyAllowance: (item[9] ? item[9] : 0),
                supplemental: (item[10] ? item[10] : 0),
                receivedFromLastYear: (item[11] ? item[11] : 0),
                transferredToNextYear: (item[12] ? item[12] : 0),
                leaveType: caches.leaveTypes.active.map[item[3]],
                pastYearDays: item[16],
                totalYearDays: item[15],
            };
            const key = currentCompanyUserId + '-' + item[4];
            if (map[key] === undefined) {
                const employee: any = {
                    id: item[0],
                    firstName: item[1],
                    lastName: item[2],
                    colSpan: colSpan
                };
                employee.fullName = fullNameFactory(employee, session.company.nameFormat);
                employee.email = usersMap[employee.id].email;
                const row = {
                    type: ANNUALROWTYPE.YEAR,
                    id: item[0],
                    year: item[4],
                    leaveTypes: [leaveType],
                    employee
                };
                rows.push(row);
                map[key] = row;
            } else {
                map[key].leaveTypes.push(leaveType);
            }
        });
        const introws =  rows.filter((row: any) => { return usersMap[row.employee.id].status !== USERSTATUS.DISABLED; }).sort((a: any, b: any) => {
            if (this.state.filters.orderBy.order === ORDER.ASC)
                return a.employee.fullName.toLowerCase().localeCompare(b.employee.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
            else {
                return b.employee.fullName.toLowerCase().localeCompare(a.employee.fullName.toLowerCase(), 'en', { sensitivity: 'base' });
            }
        });

        return introws;
    }

    render() {
        const isCurrentYear = this.state.filters.years.value[0] === this.currentBusinessYear();
        return <div className="container-fluid">
            <div className="row">
                <div className="col-12 mb-3">
                    <Tabs currentTab={REPORTSTABS.ANNUAL}
                        onChange={(value: any) => { if (this.props.onTabChange) { this.props.onTabChange(value); } }}
                    />
                </div>
            </div>
            <div className="row"><div className="col-12 mb-3"><Line /></div></div>
            <Filters
                download={(new Download({ filters: this.state.filters, isCurrentYear }))}
                defaultLeaveTypes={this.state.filters.leaveTypes.value}
                defaultUsers={this.state.filters.users.value}
                defaultUnits={this.state.filters.units.value}
                defaultYears={this.state.filters.years.value}
                orderBy={this.state.filters.orderBy}
                onChange={(value: any) => { this.setState({ filters: Object.assign({}, value) }, () => { this.search(); }); }}
                onTabChange={(value: any) => { if (this.props.onTabChange) { this.props.onTabChange(value); } }}
            />
            <div className="row">
                <div className="col-12 mb-3">
                    <Table isCurrentYear={isCurrentYear} leaveTypes={this.getLeaveTypes()} rows={this.state.rows} />
                </div>
            </div>
        </div>
    }

    public static getLeaveTypes(this: any) {
        const caches: any = this.getCaches().getState();
        return (this.state.filters.leaveTypes === undefined ||
            this.state.filters.leaveTypes.value === undefined ||
            this.state.filters.leaveTypes.value.length === 0) ?
            caches.leaveTypes.active.arr
            :
            this.state.filters.leaveTypes.value.map((id: any) => { return caches.leaveTypes.map[id]; });
    }
}