import * as React from 'react';
import Component from '../../Component';
import Form from './Form';
import { STATUS } from '../../General';

export default class Login extends Component {

  render() {
    const caches: any = this.getCaches().getState();
    let ready = caches.countries.status === STATUS.READY &&
      (this.getSession().getState() as any).status === STATUS.READY;
    if (ready === false) {
      return this.renderLoading();
    }
    if ((this.getSession().getState() as any).logged) {
      this.goToDashboard();
      return null;
    }
    return <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-lg-6 mr-auto ml-auto mb-3">
          <Form />
        </div>
      </div>
    </div>
  }
}