import * as React from 'react';
import Component from '../../../../Component';
import LeaveDates from '../../../../Components/Leave/LeaveDates';
import { LEAVESTATUS } from '../../../../General';
import LeaveEmployee from '../../../Reports/LeaveEmployee';

export default class Item extends Component {

    render() {
        var leave = this.props.leave;
        return <tr>
            <td>
                <LeaveEmployee employee={leave.companyUser} />
                <span className="float-right">
                    <LeaveDates leave={leave} />
                </span>
            </td>
        </tr>
    }

    getLeaveCardColor(leave: any) {
        switch (leave.status) {
            case LEAVESTATUS.AWAITING_APPROVAL:
                return 'border-warning';
            case LEAVESTATUS.APPROVED:
                return 'border-success';
            case LEAVESTATUS.REJECTED:
                return 'border-danger';
            case LEAVESTATUS.CANCELED:
                return 'border-dark';
            default:
                return 'border-dark';
        }
    }
}