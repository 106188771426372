import * as React from 'react';
import Item from './Item';
import TableBase from '../../../Employee/Profile/ApproverFor/Table';

export default class Table extends TableBase {

    getTrs() {
        // tslint:disable-next-line:max-line-length
        return this.props.employees.map((employee: any) => { return (<Item key={employee.id} employee={employee} />); });
    }
}