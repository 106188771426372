import * as React from 'react';
import Component from '../Component';
import Anchor from '../Components/Anchor';

export default class SelectField extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            value: this.props.defaultValue,
            touched: false,
            rules: this.props.rules,
            valid: null,
            multiple: this.props && this.props.multiple ? true : false,
        };
    }

    render() {
        const optionsEls = this.props.options.map((option: any) => {
            return (
                <div key={option.value}>
                    <Anchor
                        // tslint:disable-next-line:max-line-length
                        onClick={() => { this.setState({ value: option.value }, () => { if (this.props.onChange) { this.props.onChange(option.value); } }); }}
                        className={option.value === this.state.value ? '' : 'text-dark'}
                    >
                        <i
                            // tslint:disable-next-line:max-line-length
                            className={'mr-1 fa-fw ' + (option.value === this.state.value ? 'far fa-dot-circle' : 'far fa-circle')}
                        />
                        {option.text}
                    </Anchor>
                </div>
            );
        });
        return (
            <div className="w-100" >
                {optionsEls}
            </div>
        );
    }
}