import * as $ from 'jquery';
import * as React from 'react';
import { doFetchOfficesFromServer } from '../../Actions/Caches';
import Component from '../../Component';
import A from '../../Components/A';
import Anchor from '../../Components/Anchor';
import ATooltip from '../../Components/ATooltip';
import Confirm from '../../Components/Confirm';
import { copyArray } from '../../Utils';

export default class Item extends Component {
    static ITEMS = 5;

    render() {
        const t = this.translate();
        var office = this.props.office;
        let trs: any = [];
        if (office.members && office.members.length > 0) {
            trs = ((office.members.length > Item.ITEMS) ?
                copyArray(office.members, 0, Item.ITEMS - 1) : office.members).map((employee: any) => {
                    if (employee.status === 'DISABLED') {
                        return null;
                    }
                    return <tr key={'OfficeItemTr-' + employee.id}>
                        <td>
                            <A className="text-dark font-weight-light"
                                href={this.Urls().getEmployee(employee.id)}
                                title={employee.fullName}>
                                {employee.fullName}
                            </A>
                        </td>
                    </tr>
                });
        }
        return (
            <div className="card w-100 h-100" >
                <div className="card-body">
                    <table className="table table-sm w-100 m-0 p-0">
                        <thead>
                            <tr>
                                <th className="border-top-0">
                                    <A faIcon="fa fa-building" href={this.Urls().getOffice(office.id)} title={office.name}>
                                        {office.name}
                                    </A>
                                    <Anchor
                                        className="float-right font-weight-light text-secondary"
                                        title={office.members.length + ' ' +
                                            (office.members.length === 1 ? t('member') : t('members'))}
                                    >
                                        {office.members.length}
                                    </Anchor>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {office?.officeManager?.id ?
                                <tr>
                                    <td className="border-top-0">
                                        <A
                                            className="text-dark"
                                            faIcon="fas fa-user-circle"
                                            href={this.Urls().getEmployee(office.officeManager.id)}
                                            title={t('manager') + ': ' + office.officeManager.fullName}
                                        >
                                            {office.officeManager.fullName}
                                        </A>
                                        <ATooltip tooltip={t('manager')} faIcon="fa fa-star" />
                                    </td>
                                </tr>
                                : null
                            }
                            {trs}
                            {(office.members.length > Item.ITEMS) ?
                                <tr>
                                    <td>
                                        <div className="float-right">
                                            <A
                                                className="text-muted"
                                                href={this.Urls().getOffice(office.id) + '#MEMBERS'}
                                                title={office.name}
                                            >
                                                {t('more')}
                                            </A>
                                        </div>
                                    </td>
                                </tr>
                                : undefined}
                            {(office.members.length === 0) ?
                                <tr>
                                    <td>
                                        <div className="float-right">
                                            <Confirm
                                                title={t('delete.office')}
                                                className="text-danger"
                                                faIcon="fas fa-trash-alt"
                                                onConfirm={() => {
                                                    $.ajax({
                                                        type: 'DELETE',
                                                        contentType: 'application/json',
                                                        url: this.Endpoints().deleteOffice(office.id),
                                                        dataType: 'json',
                                                        cache: false,
                                                        success: (data: any, textStatus: any, jqXHR: any) => {
                                                            this.successToastr(t('the.office.was.deleted'));
                                                            doFetchOfficesFromServer(this.getCaches());
                                                            if (this.props.onRefresh) {
                                                                this.props.onRefresh();
                                                            }
                                                        },
                                                        error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                                                            this.ajaxError(jqXHR, textStatus, errorThrown);
                                                        }
                                                    });
                                                }}
                                            >
                                                {t('delete.office')}
                                            </Confirm>
                                        </div>
                                    </td>
                                </tr>
                                : null}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}