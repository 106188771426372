import * as React from 'react';
import Component from '../Component';
import Anchor from './Anchor';
import A from './A';

export default abstract class BaseTabs extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            currentTab: this.props.currentTab
        };
    }

    render() {
        return (
            <div className="btn-group" role="group">
                {
                    this.getTabs().map((tab) => {
                        if ((((window.location + '').indexOf(tab.url) > -1) && tab.tab) || (!tab.url && tab.tab)) {
                            return (
                                <Anchor
                                    key={this.getName() + '-Tabs-' + tab.tab}
                                    href={'#' + tab.tab}
                                    faIcon={tab.faIcon}
                                    title={tab.title}
                                    className={'btn ' +
                                        ((this.state.currentTab === tab.tab) ?
                                            'btn-primary' : 'btn-outline-primary')}
                                    onClick={() => {
                                        this.setState(
                                            { currentTab: tab.tab },
                                            () => {
                                                window.location.hash = tab.tab;
                                                if (this.props.onChange) { this.props.onChange(this.state.currentTab); }
                                            }
                                        );
                                    }}
                                >
                                    {this.getTitleToShow(tab)}
                                </Anchor>
                            );
                        }
                        return (
                            <A
                                key={this.getName() + '-Tabs-' + tab.tab}
                                href={tab.url + (tab.tab ? '#' + tab.tab : '')}
                                faIcon={tab.faIcon}
                                title={tab.title}
                                // tslint:disable-next-line:max-line-length
                                className={'btn ' + ((this.state.currentTab === tab.tab) ? 'btn-primary' : 'btn-outline-primary')}
                            >
                                {this.getTitleToShow(tab)}
                            </A>
                        );
                    })
                }
            </div>
        );
    }

    getTitleToShow(tab: any) {
        return ((this.isScreenSizeSmall() || this.isScreenSizeMd()) && (this.state.currentTab !== tab.tab))
            ? null : tab.title;
    }

    abstract getTabs(): any[];

    abstract getName(): string;
}