import * as $ from 'jquery';
import * as React from 'react';
import { orderLeaveTypes } from '../../Actions/Modal';
import Component from '../../Component';
import A from '../../Components/A';
import Anchor from '../../Components/Anchor';
import Line from '../../Components/Line';
import Tabs from '../../Containers/Company/Tabs';
import { COMPANYTABS, ORDER, STATUS, VIEWMODE } from '../../General';
import Filters from './Filters';
import List from './List';


export default class LeaveTypes extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            filters: {
                search: { value: '' },
                viewMode: VIEWMODE.GRID,
                orderBy: { field: 'NAME', order: ORDER.ASC }
            },
            leaveTypes: {
                arr: [],
                status: STATUS.LOADING
            }
        };
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState(
            { leaveTypes: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getLeaveTypes({ filters: this.state.filters }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        this.setState(
                            {
                                leaveTypes: {
                                    arr: data.data,
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                leaveTypes: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        const t = this.translate();
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-lg-9 mb-3">
                        <Tabs currentTab={COMPANYTABS.LEAVETYPES} />
                    </div>
                    <div className="col-12 col-lg-3 mb-3">
                        <Anchor
                            faIcon="fas fa-sort"
                            className="btn btn-outline-primary float-right ml-1"
                            title={t('order.leave.types')}
                            onClick={() => {
                                orderLeaveTypes(
                                    this.getModal(),
                                    { callback: () => { this.search(); } },
                                );
                            }}
                        >
                        </Anchor>
                        <A
                            faIcon="fa fa-plus"
                            href={this.Urls().getLeaveTypeAdd()}
                            className="btn btn-outline-primary float-right"
                            title={t('add.leave.type')}
                        >
                            {this.isScreenSizeSmall() ? undefined : t('add.leave.type')}
                        </A>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 mb-3">
                        <Line />
                    </div>
                </div>
                <Filters
                    viewMode={this.state.filters.viewMode}
                    orderBy={this.state.filters.orderBy}
                    onChange={(value: any) => { this.setState({ filters: Object.assign({}, value) }, () => { this.search(); }); }}
                />
                <div className="row">
                    <List
                        leaveTypes={this.state.leaveTypes}
                        viewMode={this.state.filters.viewMode}
                    />
                </div>
            </div>
        );
    }
}