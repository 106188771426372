import * as React from 'react';
import Component from '../Component';

export default class Age extends Component {
    render() {
        return <span className="d-inline-block"> {Age.computedAgeLiteral(this.props.from, this.props.until, this.translate())}</span>;
    }

    public static computeAge(from: any, until: any, isToday?: any) {
        //from = from.format('x');
        //until = until.format('x');
        //let age = Math.floor((until - from) / 31557600000) - (isToday ? 1 : 0);
        let age = until.diff(from, 'years');
        return age;
    }

    public static computedAgeLiteral(from: any, until: any, t: any, isToday?: any) {
        let age = Age.computeAge(from, until, isToday);
        if (age === 0)
            return '';
        if (age === 1)
            return 1 + ' ' + t('year').toLowerCase();
        return age + ' ' + t('years').toLowerCase()
    }
}