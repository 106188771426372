import * as React from 'react';
import Component from '../../../Component';
import Manager from './Manager';
import { STATUS } from '../../../General';

export default class YearView extends Component {
    constructor(props: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        this.state = {
            year: moment().year()
        };
    }

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.units.status === STATUS.READY && caches.users.status === STATUS.READY && caches.leaveTypes.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (
            <Manager
                currentTab={this.props.currentTab}
                onChangeTab={(value: any) => { if (this.props.onChangeTab) { this.props.onChangeTab(value); } }}
                onChangeYear={(value: any) => { if (this.props.onChangeYear) { this.props.onChangeYear(value); } }}
                year={this.state.year}
            />
        );
    }
}