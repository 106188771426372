import * as React from 'react';
import Component from '../../../Component';
import LeaveDecisionAction from '../../../Components/Leave/LeaveDecisionAction';
import LeaveInterval from '../../../Components/Leave/LeaveInterval';
import LeaveName from '../../../Components/Leave/LeaveName';
import { LEAVESTATUS } from '../../../General';
import LeaveRules from '../../../LeaveRules';
import LeaveEmployee from '../../Reports/LeaveEmployee';

export default class Item extends Component {

    render() {
        var leave = this.props.leave;
        return (
            <tr>
                <td>
                    <div>
                        <LeaveEmployee employee={leave.companyUser} />
                        <span className="float-right">
                            <LeaveName leave={leave} />
                        </span>
                    </div>
                    <div className="clearfix" />
                    <div>
                        <LeaveInterval leaveRequestInThePast={true} leave={leave} />
                    </div>
                    {
                        (LeaveRules.canBeRejected(leave, (this.getSession().getState() as any).companyUser)) ?
                            (
                                <div className="mb-3 mt-3">
                                    <LeaveDecisionAction
                                        leave={leave}
                                        onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }}
                                    />
                                </div>
                            )
                            :
                            null
                    }
                </td>
            </tr>
        );
    }

    getLeaveCardColor(leave: any) {
        switch (leave.status) {
            case LEAVESTATUS.AWAITING_APPROVAL:
                return 'border-warning';
            case LEAVESTATUS.APPROVED:
                return 'border-success';
            case LEAVESTATUS.REJECTED:
                return 'border-danger';
            case LEAVESTATUS.CANCELED:
                return 'border-dark';
            default:
                return 'border-dark';
        }
    }
}