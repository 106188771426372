import BaseTabs from '../../Components/BaseTabs';
import { LEAVETYPETABS } from '../../General';

export default class Tabs extends BaseTabs {
    getTabs(): any[] {
        const t = this.translate();
        return [
            {
                faIcon: 'fas fa-sliders-h',
                title: t('allowances'),
                tab: LEAVETYPETABS.ALLOWANCES
            },
            {
                faIcon: 'fas fa-wrench',
                title: t('settings'),
                tab: LEAVETYPETABS.SETTINGS
            },
        ];
    }

    getName(): string {
        return 'LeaveType';
    }
}