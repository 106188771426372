import * as React from 'react';
import Component from '../../Component';
import MenuXS from './MenuXS';
import MenuMD from './MenuMD';
import MenuLG from './MenuLG';
import MenuNotLogged from './MenuNotLogged';

export default class Menu extends Component {
    onResize(value: any) {
        if (this.props.onResize) { this.props.onResize(value); }
    }

    render() {
        if (this.props.top) {
            if ((this.getSession().getState() as any).logged === false) {
                return (<MenuNotLogged onResize={(value: any) => { this.onResize(0); }} />);
            }
            if (this.isScreenSizeSmall()) {
                return (<MenuXS isOpen={false} onResize={(value: any) => { this.onResize(0); }} />);
            }
        }
        if (!this.props.top && (this.getSession().getState() as any).logged === true && !this.isScreenSizeSmall()) {
            if (this.isScreenSizeMd()) {
                return (<MenuMD isOpen={false} onResize={(value: any) => { this.onResize(value); }} />);
            }
            return (<MenuLG isOpen={true} onResize={(value: any) => { this.onResize(value); }} />);
        }
        return null;
    }
}