import {
    createStore,
    //    applyMiddleware 
} from 'redux';
// import { createLogger } from 'redux-logger';
import { MODALS } from '../Constants/ActionTypes';

export const initialState = {
    type: MODALS.NONE,
};

const modal = createStore(
    (state = initialState, action: any) => {
        switch (action.type) {
            case MODALS.LEAVE_DECISION:
            case MODALS.SEND_LEAVE_REMINDER:
            case MODALS.CANCEL_LEAVE:
            case MODALS.WHO_IS_OFF_TODAY:
            case MODALS.MY_NEXT_HOES:
            case MODALS.OTHER_LEAVES:
            case MODALS.IMPORT_HOES:
            case MODALS.ADD_HOE:
            case MODALS.ADD_BLACKOUT_DAY:
            case MODALS.ADD_OFFICE_MEMBERS:
            case MODALS.ADD_SUBSIDIARY_MEMBERS:
            case MODALS.ADD_TEAM_MEMBERS:
            case MODALS.ADJUST_EMPLOYEE_LEAVETYPE_ALLOWANCE:
            case MODALS.TRANSFER_LEAVE_TYPE_ALLOWANCE:
            case MODALS.REVERT_TRANSFER_LEAVE_TYPE_ALLOWANCE:
            case MODALS.LEAVE_TYPES_BALANCE:
            case MODALS.ADJUST_WORKING_DAY_AND_HOURS:
            case MODALS.COPY_ICALENDAR_URL:
            case MODALS.TRIAL_STARTED:
            case MODALS.ORDER_LEAVETYPES:
            case MODALS.NONE:
                return {
                    options: action.options,
                    type: action.type,
                };
            default:
                return initialState;
        }
    },
    //    applyMiddleware(createLogger({}))
);

export default modal;