import * as React from 'react';
import ResponsiveModal from '../ResponsiveModal';
import Form from './Form';
import { none } from '../../../Actions/Modal';

export default class CopyICalendarUrl extends ResponsiveModal {
    getContent() {
        return (
            <Form
                url={this.props.options.url}
                onClose={() => {
                    this.toggle();
                    none(this.getModal(), {});
                    if (this.props.callback) { this.props.callback(); }
                }}
            />
        );
    }

    getTitle(): any {
        return (<h5 className="m-0 p-0">{this.props.options.title}</h5>);
    }
}