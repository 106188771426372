import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import { fullNameFactory } from '../../../Factories';
import { STATUS } from '../../../General';
import Display from './Display';

export default class Profile extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            employee: {
                value: undefined,
                status: STATUS.LOADING
            },
        };
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState(
            {
                employee: {
                    value: undefined,
                    status: STATUS.LOADING
                }
            },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getUserSettings(),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        data.office = data.calendar;
                        const session = this.getSession().getState() as any;
                        data.fullName = fullNameFactory(data, session.company.nameFormat);
                        this.setState(
                            {
                                employee: {
                                    value: data,
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                employees: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.units.status === STATUS.READY &&
            caches.users.status === STATUS.READY &&
            caches.offices.status === STATUS.READY &&
            caches.countries.status === STATUS.READY &&
            caches.timezones.status === STATUS.READY &&
            this.state.employee.status === STATUS.READY;
        if (ready === false) { return this.renderLoading(); }
        return (
            <Display employee={this.state.employee.value} />
        );
    }
}