import * as React from 'react';
import Component from '../../Component';
import * as $ from 'jquery';
import { getURLParameter } from '../../Utils';
import { STATUS } from '../../General';
import Form from './Form';

export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            activateCompany: {
                value: undefined,
                status: STATUS.LOADING
            },
        };
    }

    componentDidMount() {
        this.getActivationData();
    }

    getActivationData() {
        $.ajax({
            type: 'GET',
            contentType: 'application/json',
            url: this.Endpoints().getCompanyActivation(getURLParameter('token'), getURLParameter('email')),
            dataType: 'json',
            cache: false,
            success: (data, textStatus, jqXHR) => {
                this.setState({
                    activateCompany: {
                        value: data,
                        status: STATUS.READY
                    }
                });
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.ajaxError(jqXHR, textStatus, errorThrown);
                this.setState({
                    employee: {
                        value: undefined,
                        status: STATUS.ERROR
                    },
                });
            },
        });
    }

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.countries.status === STATUS.READY &&
            caches.timezones.status === STATUS.READY &&
            this.state.activateCompany.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (<div className="container-fluid">
            <div className="row">
                <div className="col-12 col-lg-6 ml-auto mr-auto mb-3">
                    <Form activateCompany={this.state.activateCompany.value} />
                </div>
            </div>
        </div>);
    }
}