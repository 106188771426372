import * as React from 'react';
import Component from '../../Component';
import Manager from './Manager';
import { STATUS } from '../../General';

export default class Dashboard extends Component {

    render() {
        const caches: any = this.getCaches().getState();
        let ready = caches.units.status === STATUS.READY && caches.users.status === STATUS.READY;
        if (ready === false) { return this.renderLoading(); }
        return <Manager />;
    }
}