import * as $ from 'jquery';
import * as React from 'react';
import Component from '../../../Component';
import A from '../../../Components/A';
import Cog from '../../../Components/Cog';
import Confirm from '../../../Components/Confirm';
import InputICal from '../../../Components/InputICal';
import TableLine from '../../../Components/TableLine';

export default class Display extends Component {
    constructor(props: any) {
        super(props);
        this.state = {
            iCalendarUrlForsubsidiary: {
                value: this.props.subsidiary.iCalUrl,
            },
            iCalendarUrlForsubsidiaryv2: {
                value: this.props.subsidiary.iCalUrlv2,
            },
            iCalendarUrlForsubsidiaryHoes: {
                value: this.props.subsidiary.iCalHoesUrl,
            },
            iCalendarUrlForBirthdays: {
                value: this.props.subsidiary.iCalendarUrlForBirthdays,
            },
            iCalendarUrlForWorkAnniversaries: {
                value: this.props.subsidiary.iCalendarUrlForWorkAnniversaries,
            },
            copyICalendarUrlForsubsidiary: false,
            copyICalendarUrlForsubsidiaryHoes: false,
            copyICalendarUrlForsubsidiaryv2: false,
            copyiCalendarUrlForBirthdays: false,
            copyICalendarUrlForsubsidiaryyWorkAnniversaries: false,
        };
    }

    render() {
        const t = this.translate();
        const subsidiary = this.props.subsidiary;
        const caches: any = this.getCaches().getState();
        const countriesMap = caches.countries.map[this.language()];
        const country = countriesMap[subsidiary.country] ?
            countriesMap[subsidiary.country].text : countriesMap.US.text;
        return <div className={this.getCardClassNameSize()}>
            <div className="card-body">
                <div className="row">
                    <div className="col-12 mb-3">
                        <Cog onClick={() => { if (this.props.onEdit) { this.props.onEdit(); } }} />
                    </div>
                    <div className="col-12">
                        {t('country')}
                        <span className="float-right">
                            {country}
                        </span>
                    </div>
                    {(subsidiary?.manager?.id) ?
                        <div className="col-12">
                            {t('manager')}
                            <span className="float-right">
                                {subsidiary.manager.fullName}
                            </span>
                        </div>
                        : null}
                    {(subsidiary.description) ?
                        <div className="col-12">
                            {t('description')}
                            <pre className="font-weight-light m-0">
                                {subsidiary.description}
                            </pre>
                        </div>
                        : null}
                    <div className="col-12">
                        <TableLine />
                    </div>
                    <div className="col-12">
                        {t('icalendar.url')}
                        <div className="float-right">
                            <Confirm
                                title={t('regenerate.icalendar.url')}
                                faIcon="fas fa-sync"
                                onConfirm={() => {
                                    $.ajax({
                                        type: 'POST',
                                        contentType: 'application/json',
                                        url: this.Endpoints().getSubsidiaryiCalRegenerate(subsidiary.id),
                                        dataType: 'json',
                                        cache: false,
                                        success: (data: any, textStatus: any, jqXHR: any) => {
                                            this.successToastr(t('the.icalendar.url.was.regenerated'));
                                            if (this.props.onRefresh) {
                                                this.props.onRefresh();
                                            }
                                        },
                                        error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                                            this.ajaxError(jqXHR, textStatus, errorThrown);
                                        }
                                    });
                                }}
                            />
                        </div>
                        <div>
                            <span className="float-right">
                                <A
                                    title={t('subsidiary.iCalendar.url')}
                                    faIcon="far fa-copy"
                                    href={this.state.iCalendarUrlForsubsidiary.value}
                                    onClick={() => { this.setState({ copyICalendarUrlForsubsidiary: true }, () => { this.setState({ copyICalendarUrlForsubsidiary: false }); }); }}
                                />
                            </span>
                            V1 : <a href={this.state.iCalendarUrlForsubsidiary.value} target="_blank" className="card-link" rel="noopener noreferrer">
                                {this.state.iCalendarUrlForsubsidiary.value}
                            </a>
                            <InputICal value={this.state.iCalendarUrlForsubsidiary.value} copy={this.state.copyICalendarUrlForsubsidiary} />
                        </div>
                        <div className="mt-3">
                            <span className="float-right">
                                <A
                                    title={t('subsidiary.iCalendar.url') + ' V2'}
                                    faIcon="far fa-copy"
                                    href={this.state.iCalendarUrlForsubsidiaryv2.value}
                                    onClick={() => { this.setState({ copyICalendarUrlForsubsidiaryv2: true }, () => { this.setState({ copyICalendarUrlForsubsidiaryv2: false }); }); }}
                                />
                            </span>
                            V2 : <a href={this.state.iCalendarUrlForsubsidiaryv2.value} target="_blank" className="card-link" rel="noopener noreferrer">
                                {this.state.iCalendarUrlForsubsidiaryv2.value}
                            </a>
                            <InputICal key={222 + 'ddd'} value={this.state.iCalendarUrlForsubsidiaryv2.value} copy={this.state.copyICalendarUrlForsubsidiaryv2} />
                        </div>

                        <div className="mt-3">
                            <span className="float-right">
                                <A
                                    title={t('subsidiary.birthdays.iCalendar.url')}
                                    faIcon="far fa-copy"
                                    href={this.state.iCalendarUrlForBirthdays.value}
                                    onClick={() => { this.setState({ copyiCalendarUrlForBirthdays: true }, () => { this.setState({ copyiCalendarUrlForBirthdays: false }); }); }}
                                />
                            </span>
                            <div><h6>{t('subsidiary.birthdays.iCalendar.url')}</h6></div><a href={this.state.iCalendarUrlForBirthdays.value} className="card-link" target="_blank" title={t('subsidiary.birthdays.iCalendar.url')} rel="noopener noreferrer" >
                                {this.state.iCalendarUrlForBirthdays.value}
                            </a>
                            <InputICal value={this.state.iCalendarUrlForBirthdays.value} copy={this.state.copyiCalendarUrlForBirthdays} />
                        </div>

                        <div className="mt-3">
                            <span className="float-right">
                                <A
                                    title={t('subsidiary.work.anniversaries.iCalendar.url')}
                                    faIcon="far fa-copy"
                                    href={this.state.iCalendarUrlForWorkAnniversaries.value}
                                    onClick={() => { this.setState({ copyiCalendarUrlForWorkAnniversaries: true }, () => { this.setState({ copyiCalendarUrlForWorkAnniversaries: false }); }); }}
                                />
                            </span>
                            <div><h6>{t('subsidiary.work.anniversaries.iCalendar.url')}</h6></div><a href={this.state.iCalendarUrlForWorkAnniversaries.value} className="card-link" target="_blank" title={t('subsidiary.birthdays.iCalendar.url')} rel="noopener noreferrer" >
                                {this.state.iCalendarUrlForWorkAnniversaries.value}
                            </a>
                            <InputICal value={this.state.iCalendarUrlForWorkAnniversaries.value} copy={this.state.copyiCalendarUrlForWorkAnniversaries} />
                        </div>

                    </div>
                </div>
            </div>
        </div>
    }
}