import * as React from 'react';
import Component from '../Component';

export default class Anchor extends Component {
    render() {
        let opts: any = {};
        if (this.props.target) { opts.target = this.props.target; }
        if (this.props.onMouseOut) {
            opts.onMouseOut = (event: any) => {
                event.preventDefault();
                this.props.onMouseOut(event);
            };
        }
        if (this.props.onMouseOver) {
            opts.onMouseOver = (event: any) => {
                event.preventDefault();
                this.props.onMouseOver(event);
            };
        }
        if (this.props.onClick) {
            opts.onClick = (event: any) => {
                event.preventDefault();
                this.props.onClick(event);
            };
        } else {
            opts.onClick = (event: any) => {
                event.preventDefault();
            };
        }
        if (this.props.target) {
            opts.target = this.props.target;
        }
        return (
            <a
                id={this.props.id ? this.props.id : ''}
                className={'card-link' + (this.props.className ? ' ' + this.props.className : '')}
                href={this.props.href ? this.props.href : '##'}
                title={this.props.title}
                {...opts}
            >
                {
                    this.props.faIcon ?
                        (
                            <i
                                className={(this.props.children ? 'mr-1 ' : '') + this.props.faIcon + ' fa-fw'}
                                aria-hidden="true"
                            />
                        )
                        :
                        null
                }
                {this.props.children}
                {
                    this.props.rightFaIcon ?
                        (
                            <i
                                className={(this.props.children ? 'ml-1 ' : '') + this.props.rightFaIcon + ' fa-fw'}
                                aria-hidden="true"
                            />
                        )
                        :
                        null
                }
            </a>
        );
    }
}