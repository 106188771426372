import * as React from 'react';

export default class Line extends React.PureComponent {
    render() {
        return (
            <div className="row">
                <div
                    className="col-12 border border-top-0 border-right-0 border-left-0 rounded-0"
                    style={{ borderColor: 'rgba(0, 0, 0, 0.125)!important' }}
                />
            </div>
        );
    }
}