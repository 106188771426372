import * as React from 'react';
import Component from '../../../Component';
import Year from '../../../Components/Year/Year';
import { STATUS } from '../../../General';
import { filtersFormUrlFactory } from '../../../Factories';
import * as $ from 'jquery';
import CompanyViewManager from '../../Calendar/CompanyView/Manager';


export default class Manager extends Component {
    constructor(props?: any) {
        super(props);
        const moment = this.getMomentWithLocale();
        const year = moment().year();
        this.state = {
            filters: this.createFilters(year),
            year: year,
            daysEvents: {
                arr: [],
                map: {}
            },
            status: STATUS.LOADING
        };
    }

    createFilters(year: number) {
        const moment = this.getMomentWithLocale();
        let filters = {
            startDate: { value: moment(year + '-01-01').add(-7, 'days') },
            endDate: { value: moment(year + '-12-31').add(7, 'days') },
            cuid: { value: this.props.employee.id }
        };
        filters = Object.assign({}, filters, filtersFormUrlFactory());
        return filters;
    }

    componentDidMount() {
        this.search();
    }

    search() {
        this.setState(
            { daysEvents: { arr: [], status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getDayEventsLite({
                        filters: this.state.filters
                    }),
                    dataType: 'json',
                    cache: false,
                    success: (data: any, textStatus: any, jqXHR: any) => {
                        const daysEvents = this.prepareDayEvents(data.data);
                        this.setState(
                            {
                                daysEvents: {
                                    arr: daysEvents.arr,
                                    map: daysEvents.map,
                                    status: STATUS.READY
                                }
                            }
                        );
                    },
                    error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                        this.setState(
                            {
                                daysEvents: {
                                    arr: [],
                                    status: STATUS.ERROR
                                }
                            }
                        );
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                    },
                });
            });
    }

    onChangeYear(year: any) {
        this.setState(
            { year: year, filters: this.createFilters(year) },
            () => { this.search(); }
        );
    }

    render() {
        let ready = this.state.daysEvents.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (
            <Year
                year={this.state.year}
                daysEvents={this.state.daysEvents}
                onChangeYear={(value: any) => { this.onChangeYear(value); }}
            />
        );
    }

    prepareDayEvents(data: any) {
        const map: any = {};
        const arr: any = data.map((item: any) => {
            let i = 0;
            let dayEvent: any = {};
            dayEvent.day = item[i++];
            dayEvent.status = item[i++];
            dayEvent.color = item[i++];
            dayEvent.dayType = item[i++];
            let json = item[i++];
            if (json) {
                dayEvent.names = JSON.parse(json);
            }
            i++;
            const leaveId = item[i++];
            if (leaveId) {
                dayEvent.leaveId = leaveId;
            }
            dayEvent.hours = item[i++];
            const leaveTypeId = item[i++];
            if (leaveTypeId) {
                dayEvent.leaveTypeId = leaveTypeId;
            }
            /*dayEvent.replacement = item[i++];
            dayEvent.companyUserId = item[i++];
            dayEvent.isPH = item[i++];
            dayEvent.isBLO = item[i++];
            if (dayEvent.isBLO) {
                dayEvent.color = '#AA0000';
            }*/
            dayEvent.style = CompanyViewManager.dayEventStyle(dayEvent);
            return dayEvent;
        });
        const caches: any = this.getCaches().getState();
        arr.forEach((dayEvent: any) => {
            const currentDayEvent = map[dayEvent.day];
            if (currentDayEvent) {
                const leaveType = currentDayEvent.leaveTypeId ? caches.leaveTypes.map[currentDayEvent.leaveTypeId] : null;
                if (!leaveType?.working && currentDayEvent.status > dayEvent.status) {
                    map[dayEvent.day] = dayEvent;
                } else {
                    if (!dayEvent.leaveTypeId) {
                        map[dayEvent.day].color = 'rgba(' + Manager.hexToRgb(dayEvent.color.substring(1)) + ', 0.2)';
                        //else
                        map[dayEvent.day].color = 'rgba(' + Manager.hexToRgb(dayEvent.color.substring(1)) + ', 0.5)';
                        map[dayEvent.day].names = dayEvent.names;
                        map[dayEvent.day].status = dayEvent.status;
                    }
                }
                const leaveType2 = dayEvent.leaveTypeId ? caches.leaveTypes.map[dayEvent.leaveTypeId] : null;
                if (leaveType2?.working) {
                    map[dayEvent.day] = dayEvent;
                }
            } else {
                map[dayEvent.day] = dayEvent;
            }
        });
        return { arr: arr, map: map };
    }

    static hexToRgb(hex: any) {
        var bigint = parseInt(hex, 16);
        var r = (bigint >> 16) & 255;
        var g = (bigint >> 8) & 255;
        var b = bigint & 255;
        return r + ',' + g + ',' + b;
    }
}