import * as React from 'react';
import Component from '../../Component';
import Error from '../../Components/Error';
import { STATUS, VIEWMODE } from '../../General';
import Item from './Item';
import RowItem from './RowItem';

export default class List extends Component {

    render() {
        const employees = this.props.employees;
        switch (employees.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                if (employees.arr.length === 0) {
                    return <div className="col-12 text-danger mb-3">
                        {t('no.employees.found')}
                    </div>
                }
                const session: any = this.getSession().getState();
                if (this.props.viewMode === VIEWMODE.LIST) {
                    return <div className="col-12 mb-3">
                        <div className="card w-100">
                            <div className="card-body">
                                <div className="table-responsive">
                                    <table className="table table-sm table-hover w-100 mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="border-top-0">
                                                    <div className="text-nowrap">
                                                        <i className="mr-1 far fa-user fa-fw" aria-hidden="true" />
                                                        {t('employee')}
                                                    </div>
                                                </th>
                                                <th scope="col" className="border-top-0">
                                                    <div className="text-nowrap">
                                                        <i className="mr-1 far fa-user-circle fa-fw" aria-hidden="true" />
                                                        {t('approver')}
                                                    </div>
                                                </th>
                                                <th scope="col" className="border-top-0">
                                                    <div className="text-nowrap">
                                                        <i className="mr-1 far fa-address-card fa-fw" aria-hidden="true" />
                                                        {t('position')}
                                                    </div>
                                                </th>
                                                <th scope="col" className="border-top-0">
                                                    <div className="text-nowrap">
                                                        <i className="mr-1 fas fa-users fa-fw" aria-hidden="true" />
                                                        {t('team')}
                                                    </div>
                                                </th>
                                                <th scope="col" className="border-top-0">
                                                    <div className="text-nowrap">
                                                        <i className="mr-1 fa fa-building fa-fw" aria-hidden="true" />
                                                        {t('office')}
                                                    </div>
                                                </th>
                                                {session.company.subsidiariesEnabled ?
                                                    <th scope="col" className="border-top-0">
                                                        <div className="text-nowrap">
                                                            <i className="mr-1 fas fa-city fa-fw" aria-hidden="true" />
                                                            {t('subsidiary')}
                                                        </div>
                                                    </th> : null
                                                }
                                                <th scope="col" className="border-top-0">
                                                    <div className="text-nowrap">
                                                        <i className="mr-1 fa fa-globe fa-fw" aria-hidden="true" />
                                                        {t('country')}
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {employees.arr.map((employee: any) => {
                                                return <RowItem
                                                    key={'Employees-' + this.props.viewMode + '-' + employee.id}
                                                    onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }}
                                                    employee={employee}
                                                />
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                return employees.arr.map((employee: any) => {
                    return (
                        <div key={'Employees-' + this.props.viewMode + '-' + employee.id} className={this.getListColSize(this.props.viewMode) + ' mb-3'}>
                            <Item
                                onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }}
                                employee={employee}
                            />
                        </div>
                    );
                });
            }
            default:
                return (<Error />);
        }
    }
}