import * as React from 'react';
import { Transition } from 'react-transition-group';
import { UncontrolledTooltip } from 'reactstrap';

export default class Placeholder extends React.Component<any, any> {
    static readonly duration = 500;

    static readonly defaultStyle = {
        transitionProperty: 'font-size',
        transitionDuration: 500 + 'ms',
        transitionDelay: 0,
        transitionTimingFunction: 'ease-out',
        marginLeft: '0.80rem',
        zIndex: 4
    };

    constructor(props?: any) {
        super(props);
        this.state = { transitionIn: this.hasData(props), withData: this.hasData(props) };
    }

    render() {
        return (
            <div className="position-relative">
                <div
                    className={'text-muted position-absolute w-100 pt-2 d-flex justify-content-between'}
                    onClick={() => { if (this.props.onClick) { this.props.onClick(); } }}
                >
                    <Transition
                        in={this.state.transitionIn}
                        timeout={50}
                        onExited={() => { this.setState({ transitionIn: false }); }}
                    >
                        {(state: any) => {
                            return (
                                <div
                                    onClick={() => { if (this.props.onClick) { this.props.onClick(); } }}
                                    style={{
                                        ...Placeholder.defaultStyle,
                                        ...this.getTransitionStyles(this.state.withData)[state]
                                    }}
                                >
                                    {this.props.placeholder}
                                </div>
                            );
                        }}
                    </Transition>
                    {
                        (this.props.placeholderTooltip && this.props.placeholderTooltip.length > 0) ?
                            <div
                                className="mr-2 pt-0 mt-0 text-info"
                                style={{ zIndex: 4 }}
                            >
                                <a id={this.props.placeholderTooltipCid} tabIndex={-1} href="##">
                                    <i className="fas fa-info-circle text-dark fa-fw" aria-hidden="true" />
                                </a>
                                <UncontrolledTooltip
                                    delay={{ show: 0, hide: 250 }}
                                    placement="top"
                                    target={this.props.placeholderTooltipCid}
                                >
                                    {this.props.placeholderTooltip}
                                </UncontrolledTooltip>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        );
    }

    getTransitionStyles(withData: any): any {
        return {
            entering: {
                fontSize: withData ? '1rem' : '0.80rem',
                marginTop: withData ? '-0.50rem' : '0rem',
            },
            entered: {
                fontSize: withData ? '0.80rem' : '1rem',
                marginTop: withData ? '-0.50rem' : '0rem',
            },
        };
    }

    hasData(props: any) {
        const value = props.value;
        return !!((value && typeof (value) === 'string' && value.length > 0) ||
            (value && typeof (value) === 'number') ||
            (value && Array.isArray(value) && value.length > 0));
    }

    shouldComponentUpdate(nextProps: any, nextState: any) {
        const withData = this.hasData(nextProps);
        if (this.state.withData !== withData) {
            nextState.withData = withData;
            nextState.transitionIn = true;
        }
        return true;
    }
}