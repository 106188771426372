import * as $ from 'jquery';
import * as React from 'react';
import { addBlackoutDay } from '../../../Actions/Modal';
import Component from '../../../Component';
import Anchor from '../../../Components/Anchor';
import ChooseYear from '../../../Components/ChooseYear';
import Confirm from '../../../Components/Confirm';
import Error from '../../../Components/Error';
import { STATUS } from '../../../General';

export default class List extends Component {

    render() {
        const blackoutEvents = this.props.blackoutEvents;
        switch (blackoutEvents.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                let trs: any = undefined;
                if (blackoutEvents.arr.length === 0) {
                    trs = <tr>
                        <td className="text-danger">
                            {t('no.blackout.days.found')}
                        </td>
                    </tr>
                } else {
                    trs = blackoutEvents.arr.map((blackoutEvent: any) => {
                        return <tr key={'Office-Hoe-List' + blackoutEvent.id}>
                            <td style={{ width: "65%", maxWidth: '65%', overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                                {blackoutEvent.name}
                            </td>
                            <td className="text-right">
                                {blackoutEvent.literalDay + ' '} {blackoutEvent.formatedDate}
                            </td>
                            <td>
                                <div className="float-right">
                                    <Confirm
                                        title={t('delete')}
                                        className="text-danger text-nowrap"
                                        faIcon="fas fa-trash-alt"
                                        onConfirm={() => {
                                            $.ajax({
                                                type: 'DELETE',
                                                contentType: 'application/json',
                                                url: this.Endpoints().deleteBlackoutEvent(blackoutEvent.id),
                                                dataType: 'json',
                                                cache: false,
                                                success: (data: any, textStatus: any, jqXHR: any) => {
                                                    this.successToastr(t('the.blackout.day.was.deleted'));
                                                    if (this.props.onRefresh) {
                                                        this.props.onRefresh();
                                                    }
                                                },
                                                error: (jqXHR: any, textStatus: any, errorThrown: any) => {
                                                    this.ajaxError(jqXHR, textStatus, errorThrown);
                                                }
                                            });
                                        }}
                                    />
                                </div>
                            </td>
                        </tr>
                    });
                }
                return <div className={this.getCardClassNameSize()}>
                    <div className="card-body">
                        <table className="table table-sm table-hover w-100 mb-0">
                            <thead>
                                <tr>
                                    <th className="border-top-0" colSpan={3}>
                                        <span className="float-right">
                                            <ChooseYear
                                                currentYear={this.props.currentYear}
                                                years={
                                                    [this.props.currentYear - 1, this.props.currentYear, this.props.currentYear + 1]
                                                }
                                                onChangeYear={(year: any) => {
                                                    if (this.props.onChangeYear) {
                                                        this.props.onChangeYear(year);
                                                    }
                                                }}
                                            />
                                        </span>
                                        <div className="clearfix d-block d-sm-none" />
                                        <Anchor
                                            faIcon="fa fa-plus"
                                            title={t('add.blackout.day')}
                                            onClick={() => {
                                                addBlackoutDay(this.getModal(), {
                                                    office: this.props.office,
                                                    callback: () => {
                                                        if (this.props.onRefresh) { this.props.onRefresh(); }
                                                    }
                                                });
                                            }}
                                        >
                                            {t('add.blackout.day')}
                                        </Anchor>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {trs}
                            </tbody>
                        </table>
                    </div>
                </div>
            }
            default:
                return (<Error />);
        }
    }
}