import * as React from 'react';
import Component from '../../Component';
import Settings from './Settings/Settings';
import Allowances from './Allowances/Allowances';
import { STATUS } from '../../General';
import * as $ from 'jquery';
import { getURLParameter } from '../../Utils';
import { LEAVETYPETABS } from '../../General';

export default class LeaveType extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            leaveType: {
                value: undefined,
                status: STATUS.LOADING
            },
            currentTab: this.getCurrentTab(LEAVETYPETABS.ALLOWANCES, LEAVETYPETABS),
        };
    }

    componentDidMount() {
        this.getLeaveType();
    }

    getLeaveType() {
        this.setState(
            { leaveType: { value: this.state.leaveType.value, status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getLeaveType(getURLParameter('id')),
                    dataType: 'json',
                    cache: false,
                    success: (data, textStatus, jqXHR) => {
                        this.setState({
                            leaveType: {
                                value: data,
                                status: STATUS.READY
                            }
                        });
                    },
                    error: (jqXHR, textStatus, errorThrown) => {
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                        this.setState({
                            leaveType: {
                                value: undefined,
                                status: STATUS.ERROR
                            },
                        });
                    },
                });
            }
        );
    }

    render() {
        const session: any = this.getSession().getState();
        const caches: any = this.getCaches().getState();
        let ready = session.status === STATUS.READY &&
            caches.users.status === STATUS.READY &&
            caches.countries.status === STATUS.READY &&
            this.state.leaveType.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return <div className="container-fluid">
            <div className="row">
                {this.getTab()}
            </div>
        </div>
    }

    getTab() {
        const defaultArgs: any = {
            currentTab: this.state.currentTab,
            onTabChange: (tab: any) => { this.setState({ currentTab: tab }); },
            leaveType: this.state.leaveType.value,
            onRefresh: () => { this.getLeaveType(); },
        };
        switch (this.state.currentTab) {
            case LEAVETYPETABS.ALLOWANCES:
                return (<Allowances {...defaultArgs} />);
            case LEAVETYPETABS.SETTINGS:
            default:
                return (<Settings {...defaultArgs} />);
        }
    }
}