import * as React from 'react';
import { Popover, PopoverBody } from 'reactstrap';
import Component from '../Component';
import A from './A';
import DownloadLink from './DownloadLink';
import Fa from './Fa';

export default class Download extends Component {
    public static CID = 0;
    private id: any;
    constructor(props: any) {
        super(props);
        this.state = { open: false, downloading: false };
        this.id = 'DOWNLOAD' + Download.CID++;
        this.toggle = this.toggle.bind(this);
        this.handleDownload = this.handleDownload.bind(this);
    }

    public toggle() {
        if (this.state.downloading) return;
        this.setState({ open: !this.state.open });
    }

    public handleDownload(format: any) {
        this.setState({ open: false }, () => {
            const download = this.props.download;
            this.setState({ downloading: true }, () => {
                download.download({ format, finishCallback: () => { this.setState({ downloading: false }) } });
            });
        });
    }

    public render() {
        const t = this.translate();
        return <span className="pr-1 mr-1">
            <A id={this.id} onClick={this.toggle} href="##">
                <span className="pl-1 pr-1 d-none d-md-inline">{t('download')}</span>
                <span className={this.state.downloading ? '' : 'd-none'}><Fa fa="fas fa-spinner fa-spin" /></span>
                <span className={this.state.downloading ? 'd-none' : ''}><Fa fa="fas fa-download" /></span>
            </A>
            <Popover placement="bottom" isOpen={this.state.open} target={this.id} toggle={this.toggle}>
                <PopoverBody style={{ width: '6rem' }}>
                    <div>
                        <A className="float-right" href="####" onClick={this.toggle}><Fa fa="fas fa-times" /></A>
                    </div>
                    <div>
                        {['XLSX', 'XLS', 'CSV', 'HTML'].map((format: any) => <DownloadLink key={format} format={format} onDownloadTrigger={this.handleDownload} />)}
                    </div>
                </PopoverBody>
            </Popover>
        </span>
    }
}