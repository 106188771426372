import * as React from 'react';
import { addSubsidiaryMembers } from '../../../Actions/Modal';
import Component from '../../../Component';
import A from '../../../Components/A';
// import Item from './Item';
import Anchor from '../../../Components/Anchor';
import Error from '../../../Components/Error';
import { STATUS } from '../../../General';

export default class List extends Component {

    render() {
        const employees = this.props.employees;
        switch (employees.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                let trs: any = undefined;
                if (employees.arr.length === 0) {
                    trs = <tr>
                        <td className="text-danger">
                            {t('no.members.found')}
                        </td>
                    </tr>
                } else {
                    trs = employees.arr.map((employee: any) => {
                        return (
                            <tr key={'Subsidiary-Members-List' + employee.id}>
                                <td>
                                    <A faIcon="far fa-user" href={this.Urls().getEmployee(employee.id)} title={employee.fullName}>
                                        {employee.fullName}
                                    </A>
                                </td>
                            </tr>
                        );
                    });
                }
                return <div className={this.getCardClassNameSize()}>
                    <div className="card-body">
                        <table className="table table-sm table-hover w-100 mb-0">
                            <thead>
                                <tr>
                                    <th className="border-top-0">
                                        <Anchor
                                            faIcon="fa fa-plus"
                                            title={t('add.subsidiary.members')}
                                            onClick={() => {
                                                addSubsidiaryMembers(
                                                    this.getModal(), {
                                                    subsidiary: this.props.subsidiary,
                                                    callback: () => { if (this.props.onRefresh) { this.props.onRefresh(); } }
                                                });
                                                return false;
                                            }}
                                        >
                                            {t('add.subsidiary.members')}
                                        </Anchor>
                                        <Anchor
                                            className="float-right font-weight-light text-secondary"
                                            title={employees.arr.length + ' ' +
                                                (employees.arr.length === 1 ? t('member') : t('members'))}
                                        >
                                            {employees.arr.length}
                                        </Anchor>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {trs}
                            </tbody>
                        </table>
                    </div>
                </div >
            }
            default:
                return (<Error />);
        }
    }
}