import * as React from 'react';
import Modal from './Modal';

export default class ModalProxy extends React.Component {
    /* componentDidMount() {
        [modal].forEach((item) => {
            item.subscribe(() => { this.forceUpdate(); });
        });
    } */

    render() { return (<Modal />); }
}