import * as React from 'react';
import Component from '../../../Component';
import Header from '../Header/Header';
import Display from './Display';
import Form from './Form';

export default class Settings extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            notificationRule: this.props.notificationRule,
            editMode: false
        };
    }

    render() {
        return (
            <div className="w-100">
                <Header notificationRule={this.props.notificationRule} />
                <div className="col-12 mb-3">
                    {(this.state.editMode) ?
                        (<Form
                            notificationRule={this.props.notificationRule}
                            onUpdate={(notificationRule: any) => { this.setState({ notificationRule }); }}
                            onRefresh={() => {
                                this.setState({ editMode: !this.state.editMode });
                                if (this.props.onRefresh) { this.props.onRefresh(); }
                            }}
                        />)
                        :
                        (<Display
                            notificationRule={this.props.notificationRule}
                            onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }}
                            onEdit={() => { this.setState({ editMode: !this.state.editMode }); }}
                        />)}
                </div>
            </div>
        );
    }
}