import * as React from 'react';
import Component from '../Component';
import Line from './Line';

export default class PaymentWarning extends Component {
    render() {
        const t = this.translate();
        const protocol = 'mai' + ((() => 'to')()) + ((() => ':')());
        const email = 'office' + ((() => '@')()) + ((() => 'leaveboard.com')());
        return <div className="container-fluid">
            <div className="row">
                <div className="col-11 mb-3">
                    <div><i className="fas text-danger fa-exclamation-circle fa-fw fa-lg mr-1" />{t('your.subscription.needs.to.be.renewed')}</div>
                    <div>{t('if.you.have.any.questions.please.contact.us.at')}<a className="card-link ml-1" href={protocol + email}>{email}</a>.</div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mb-3">
                    <Line />
                </div>
            </div>
        </div>
    }
}