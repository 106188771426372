import * as React from 'react';
import Component from '../../Component';
import A from '../../Components/A';
import InputField from '../../Components/InputField';
import GoogleButton from '../../Components/Integrations/GoogleButton';
import OfficeButton from '../../Components/Integrations/OfficeButton';
import SlackButton from '../../Components/Integrations/SlackButton';
import Submit from '../../Components/Submit';
import * as $ from 'jquery';
import { getURLParameter, Cookie } from '../../Utils';

export default class Login extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      email: {
        touched: false,
        rules: this.Rules().BasicEmailRules()
      },
      password: {
        touched: false,
        rules: this.Rules().BasicPasswordRules()
      },
      login: {
        pleaseWait: false
      }
    };
  }

  render() {
    const t = this.translate();
    return (
      <div className="card h-100">
        <div className="card-body">
          <div className="row">
            <div className="col-12 mb-3">
              <h5 className="text-center m-0">{t('sign.in.with')}</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-3">
              <GoogleButton />
            </div>
            <div className="col-md-4 mb-3">
              <OfficeButton />
            </div>
            <div className="col-md-4 mb-3">
              <SlackButton />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mb-3">
              <div className="text-center text-muted m-0">{t('or')}</div>
            </div>
          </div>
          <form onSubmit={(event: any) => { this.submit(event); }}          >
            <fieldset>
              <div className="row">
                <div className="col-12 mb-3">
                  <InputField
                    touched={this.state.email.touched}
                    rules={this.state.email.rules}
                    type="text"
                    placeholder={t('email')}
                    focus={this.isScreenSizeSmall() ? false : true}
                    onChange={(value: any) => {
                      this.setState(
                        { email: Object.assign({}, this.state.email, { value: value }) }
                      );
                    }}
                  />
                </div>
                <div className="col-12 mb-3">
                  <InputField
                    touched={this.state.password.touched}
                    rules={this.state.password.rules}
                    type="password"
                    placeholder={t('password')}
                    // tslint:disable-next-line:max-line-length
                    onChange={(value: any) => { this.setState({ password: Object.assign({}, this.state.password, { value: value }) }); }}
                  />
                </div>
                <div className="col-12 mb-3">
                  <Submit
                    faIcon="fas fa-sign-in-alt"
                    pleaseWait={this.state.login.pleaseWait}
                  >
                    {t('login')}
                  </Submit>
                </div>
                <div className="col-12">
                  <A
                    href={this.Urls().getForgotPassword()}
                    className="text-muted float-left"
                  >
                    {t('forgot.password')}
                  </A>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
    );
  }

  isFormValid() {
    return this.Validator().validate(this.state.email) && this.Validator().validate(this.state.password);
  }

  submit(event: any) {
    event.preventDefault();
    this.setState({
      email: Object.assign({}, this.state.email, { touched: true }),
      password: Object.assign({}, this.state.password, { touched: true })
    },
      // tslint:disable-next-line:align
      () => {
        if (this.isFormValid()) {
          this.loginAction();
        }
      });
  }

  loginAction() {
    const target = getURLParameter('target');
    if (target) {
      Cookie.create(Cookie.REDIRECT_TARGET, target);
    }
    $.ajax({
      type: 'POST',
      contentType: 'application/json',
      url: this.Endpoints().getLocalExchange(),
      dataType: 'json',
      cache: false,
      data: JSON.stringify({
        local: {
          email: this.state.email.value,
          password: this.state.password.value
        }
      }),
      beforeSend: () => {
        this.setState({ login: { pleaseWait: true } });
      },
      success: (data, textStatus, jqXHR) => {
        this.goToLocalOAuth(data.code);
      },
      error: (jqXHR, textStatus, errorThrown) => {
        this.ajaxGeneralError(jqXHR, textStatus, errorThrown);
        this.setState({ login: { pleaseWait: false } });
      },
    });
  }

  componentDidMount() {
    this.fastSSO();
  }

  fastSSO() {
    var sso = getURLParameter('sso');
    if (sso) {
      switch (sso.toLowerCase()) {
        case 'google':
          window.location.href = this.Urls().getGoogleClientAuthUrl();
          break;
        case 'office':
          window.location.href = this.Urls().getOffice365ClientAuthUrl();
          break;
        case 'slack':
          window.location.href = this.Urls().getSlackClientAuthUrl();
          break;
        default:
          return;
      }
    }
    var target = getURLParameter('target');
    if (target) {
      Cookie.create(Cookie.REDIRECT_TARGET, target);
      if (target.indexOf('oa=g') >= 0) {
        window.location.href = this.Urls().getGoogleClientAuthUrl();
      }
      if (target.indexOf('oa=o') >= 0) {
        window.location.href = this.Urls().getOffice365ClientAuthUrl();
      }
      if (target.indexOf('oa=s') >= 0) {
        window.location.href = this.Urls().getSlackClientAuthUrl();
      }
    }
  }
}