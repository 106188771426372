import * as React from 'react';
import Component from '../../Component';
import Header from './Header/Header';
import Members from './Members/Members';
import Settings from './Settings/Settings';
import { STATUS } from '../../General';
import * as $ from 'jquery';
import { getURLParameter } from '../../Utils';
import { UNITTABS } from '../../General';
import { fullNameFactory } from '../../Factories';

export default class Unit extends Component {
    constructor(props?: any) {
        super(props);
        this.state = {
            unit: { value: undefined, status: STATUS.LOADING },
            currentTab: this.getCurrentTab(UNITTABS.MEMBERS, UNITTABS),
        };
    }

    componentDidMount() {
        this.getUnit();
    }

    getUnit() {
        this.setState({ unit: { value: this.state.unit.value, status: STATUS.LOADING } },
            () => {
                $.ajax({
                    type: 'GET',
                    contentType: 'application/json',
                    url: this.Endpoints().getUnit(getURLParameter('id')),
                    dataType: 'json',
                    cache: false,
                    success: (data, textStatus, jqXHR) => {
                        const session = this.getSession().getState() as any;
                        data.managerCompanyUser.fullName = fullNameFactory(data.managerCompanyUser, session.company.nameFormat);
                        this.setState({
                            unit: {
                                value: data,
                                status: STATUS.READY
                            }
                        });
                    },
                    error: (jqXHR, textStatus, errorThrown) => {
                        this.ajaxError(jqXHR, textStatus, errorThrown);
                        this.setState({
                            unit: {
                                value: undefined,
                                status: STATUS.ERROR
                            },
                        });
                    },
                });
            }
        );
    }

    render() {
        const session: any = this.getSession().getState();
        const caches: any = this.getCaches().getState();
        let ready = session.status === STATUS.READY &&
            caches.users.status === STATUS.READY &&
            this.state.unit.status === STATUS.READY;
        if (ready === false) {
            return this.renderLoading();
        }
        return (
            <div className="container-fluid"><div className="row">{this.getTab()}</div></div>
        );
    }

    getTab() {
        const defaultArgs: any = {
            unit: this.state.unit.value,
            currentTab: this.state.currentTab,
            onTabChange: (tab: any) => { this.setState({ currentTab: tab }); }
        };
        switch (this.state.currentTab) {
            case UNITTABS.MEMBERS:
                return (
                    <div className="w-100">
                        <Header {...defaultArgs} />
                        <div className="col-12 mb-3">
                            <Members unit={this.state.unit.value} />
                        </div>
                    </div>
                );
            case UNITTABS.SETTINGS:
            default:
                return (
                    <Settings
                        {...defaultArgs}
                        onRefresh={() => { this.getUnit(); }}
                    />
                );
        }
    }
}