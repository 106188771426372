import * as React from 'react';
import Component from '../../../Component';
import Back from '../../../Components/Back';

export default class Header extends Component {

    constructor(props?: any) {
        super(props);
        this.state = {
            editMode: this.props.editMode
        };
    }

    render() {
        const employee = this.props.employee;
        return <div>
            <div className="row">
                <div className="col-12 mb-3">
                    <Back href={this.Urls().getEmployees()} />
                    <h5 className="mb-0 d-inline">
                        <i className="far fa-user mr-1" />
                        {employee.fullName}
                    </h5>
                </div>
            </div>
        </div>
    }
}