export enum STATUS {
    LOADING = 'LOADING',
    READY = 'READY',
    ERROR = 'ERROR',
    NONE = 'NONE'
}
export enum VIEWMODE {
    LIST = 'LIST',
    GRID = 'GRID',
}

export enum USERSTATUS {
    ACTIVE = 'ACTIVE',
    DISABLED = 'DISABLED',
    INVITED = 'INVITED'
}

export enum ORDER {
    ASC = 'ASC',
    DESC = 'DESC',
}
export enum ETOLTABS {
    EMPLOYEES = 'EMPLOYEES',
    UNITS = 'UNITS',
    OFFICES = 'OFFICES',
    SUBSIDIARIES = 'SUBSIDIARIES',
    REPORTS = 'REPORTS',
    LEAVETYPES = 'LEAVETYPES'
}
export enum REPORTSTABS {
    GENERAL = 'GENERAL',
    LEAVES = 'LEAVES',
    ANNUAL = 'ANNUAL',
    ATTENDANCE = 'ATTENDANCE'
}
export enum LEAVESTATUS {
    AWAITING_APPROVAL = 1000,
    APPROVED = 1001,
    REJECTED = 1002,
    CANCELED = 1003
}

export enum NOTIFICATIONRULETYPE {
    EVENT_BASED = 1,
    SCHEDULER_BASED = 2,
}

export enum NOTIFICATIONRULETYPESCHEDULERWHEN {
    MONDAY = 1,
    SUNDAY = 2,
    FIRST_DAY_OF_THE_MONTH = 10,
}


export enum NOTIFICATIONRULETYPESCHEDULERCONTENT {
    FULL_DIGEST = 1,
}

export enum NOTIFICATIONRULEEVENTCLAUSE {
    REQUEST = 1,
    REQUEST_CANCELED = 2,
    ACCEPT_DECISION = 3,
    REJECT_DECISION = 4,
}
export enum LEAVEDAYTYPE {
    FULL = 0,
    FIRST_PART = 1,
    SECOND_PART = 2,
    HOURS = 3,
}

export enum COMPANYUSERROLES {
    COMPANY_ADMIN = 'COMPANY_ADMIN',
    COMPANY_MANAGER = 'COMPANY_MANAGER',
    COMPANY_USER = 'COMPANY_USER'
}

export enum SUBSCRIPTIONTYPE {
    FREE = 'FREE',
    TRIAL = 'TRIAL',
    SUBSCRIPTION = 'SUBSCRIPTION',
}

export const DEFAULT_COLORS: string[] = [
    '#FF0000', '#55CCD9', '#0000FF', '#0000A0', '#ADD8E6',
    '#800080', '#E5CE6D', '#32C804', '#FF00FF', '#C0C0C0', '#808080', '#FFA500', '#DD613C',
    '#800000', '#008000', '#808000', '#88B14B', '#EF562D', '#D1AF94'
];
export enum MYACCOUNTTABS {
    INTEGRATIONS = 'INTEGRATIONS',
    PROFILE = 'PROFILE',
    BALANCE = 'BALANCE',
    SETTINGS = 'SETTINGS'
}
export enum EMPLOYEETABS {
    CALENDAR = 'CALENDAR',
    LEAVES = 'LEAVES',
    PROFILE = 'PROFILE',
    TIMEOFF = 'TIMEOFF',
    ALLOWANCES = 'ALLOWANCES',
    PERSONAL_DETAILS = 'PERSONAL_DETAILS'
}
export enum REQUESTDAYTYPE {
    FULL = 'FULL',
    RANGE = 'RANGE',
    FIRST_PART = 'FIRST_PART',
    SECOND_PART = 'SECOND_PART',
    HOURS = 'HOURS',
}
export enum COMPANYTABS {
    SETTINGS = 'SETTINGS',
    IMPORTEXPORT = 'IMPORTEXPORT',
    LEAVETYPES = 'LEAVETYPES',
    SUBSCRIPTION = 'SUBSCRIPTION',
    INTEGRATIONS = 'INTEGRATIONS',
    PERMISSIONS = 'PERMISSIONS',
    NOTIFICATIONRULES = 'NOTIFICATIONRULES',
}
export enum OFFICETABS {
    MEMBERS = 'MEMBERS',
    BLACKOUTEVENTS = 'BLACKOUTEVENTS',
    HOES = 'HOES',
    SETTINGS = 'SETTINGS',
}
export enum SUBSIDIARYTABS {
    MEMBERS = 'MEMBERS',
    SETTINGS = 'SETTINGS',
}
export enum UNITTABS {
    MEMBERS = 'MEMBERS',
    SETTINGS = 'SETTINGS',
}
export enum REQUESTAPPROVETYPE {
    APPROVENOW = 'APPROVENOW',
    SENDFORAPPROVAL = 'SENDFORAPPROVAL'
}
export enum LEAVETYPETABS {
    SETTINGS = 'SETTINGS',
    ALLOWANCES = 'ALLOWANCES',
}
export enum CALENDARTABS {
    COMPANY = 'COMPANY',
    MY = 'MY',
}
export enum SUPPORTTABS {
    KB = 'KB',
    RT = 'RT',
}
export enum PERMISSIONS {
    CALENDAR = 'calendar',
    REPORTS = 'REPORTS',
}