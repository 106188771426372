import * as React from 'react';
import Component from '../Component';
import { UncontrolledTooltip } from 'reactstrap';

export default class ATooltip extends Component {
    render() {
        return (
            <div className="d-inline" style={{ zIndex: 4 }}>
                <a href="##" id={'ATooltip' + this.cid} tabIndex={-1} className="card-link">
                    {
                        this.props.faIcon ?
                            (<i className={(this.props.children ? 'mr-1 ' : '') + this.props.faIcon + ' fa-fw'} aria-hidden="true" />)
                            :
                            null
                    }
                    {this.props.children}
                </a>
                <UncontrolledTooltip delay={{ show: 0, hide: 250 }} placement="top" target={'ATooltip' + this.cid}>
                    {this.props.tooltip}
                </UncontrolledTooltip>
            </div>
        );
    }
}