import * as React from 'react';
import Component from '../../Component';
import Item from './Item';
import Error from '../../Components/Error';
import { STATUS } from '../../General';

export default class List extends Component {

    render() {
        const subsidiaries = this.props.subsidiaries;
        switch (subsidiaries.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                if (subsidiaries.arr.length === 0) {
                    const t = this.translate();
                    return <div className="col-12 text-danger mb-3">
                        {t('no.subsidiaries.found')}
                    </div>
                }
                return subsidiaries.arr.map((subsidiary: any) => {
                    return <div key={'subsidiaries' + this.props.viewMode + '-' + subsidiary.id} className={this.getListColSize(this.props.viewMode) + ' mb-3'}>
                        <Item
                            subsidiary={subsidiary}
                            onRefresh={() => { if (this.props.onRefresh) { this.props.onRefresh(); } }}
                        />
                    </div>
                });
            }
            default:
                return (<Error />);
        }
    }
}