import * as React from 'react';

export default class Submit extends React.Component<any, any> {
    render() {
        const color = this.props.color ? 'btn-' + this.props.color : 'btn-primary';
        return (
            <button
                type={this.props.type && this.props.type === 'button' ? 'button' : 'submit'}
                className={'btn btn-block ' +
                    (this.props.disabled ? 'btn-outline-secondary' : color)
                    + (this.props.className ? this.props.className : '')
                }
                disabled={this.props.pleaseWait || this.props.disabled}
                onClick={(event: any) => { if (this.props.onClick) { this.props.onClick(event); } }}
            >
                <div>
                    {
                        this.props.faIcon ?
                            (<span><i className={'fa-fw ' + this.props.faIcon} aria-hidden="true" />{' '}</span>)
                            :
                            null
                    }
                    {this.props.children}
                    {
                        this.props.faIconRight ?
                            (<span>{' '}<i className={'fa-fw ' + this.props.faIconRight} aria-hidden="true" /></span>)
                            :
                            null
                    }
                    {(this.props.pleaseWait ?
                        (<span className="float-right">
                            <i className="fa fa-spinner fa-pulse fa-fw" aria-hidden="true" />
                        </span>)
                        :
                        null
                    )}
                </div>
            </button>
        );
    }
}