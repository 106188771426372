import * as React from 'react';
import Component from '../../../Component';
import Date from '../../../Components/Date';
import Error from '../../../Components/Error';
import { STATUS } from '../../../General';

export default class Table extends Component {
    render() {
        const employees = this.props.employees;
        switch (employees.status) {
            case STATUS.LOADING:
                return this.renderLoading();
            case STATUS.READY: {
                const t = this.translate();
                return <table className="table table-sm w-100 m-0 p-0" style={{ overflowWrap: 'break-word', wordBreak: 'break-word' }}>
                    <thead>
                        <tr>
                            <th scope="col" colSpan={2} className="border-top-0">
                                {t('upcoming.new.year.of.service')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {employees.arr.map((employee: any) => {
                            return <tr key={employee.id}>
                                <td key="Employees-Item-Employee">
                                    <div>{employee.fullName}</div>
                                    {employee.service ? employee.service.humanize() + ' ' + t('from').toLowerCase() : null}
                                    {employee.employmentStartDate ? <span> <Date date={employee.employmentStartDate} /></span> : null}
                                </td>
                                <td key="Employees-Item-Employment-Next-Year-Of-Service" className="text-right">
                                    <div>
                                        {employee.todayIsNewYearOfService && employee.service && employee.service.valueOf() > 0 ? t('today') :
                                            (employee.next && employee.next.valueOf() > 0 ?
                                                employee.next.humanize(true) : null)}</div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            }
            default:
                return (<Error />);
        }
    }
}